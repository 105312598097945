const COLOR_CODES = [
  "#FFD600",
  "#FFAB00",
  "#FF6D00",
  "#B600D6",
  "#0097A7",
  "#AA00FF",
  "#00796B",
  "#0288D1",
  "#512DA8",
  "#303F9F",
];

export interface IGetColorParams {
  color?: string;
  pictureUrl?: string;
  name?: string;
  fallbackBackgroundColor?: string;
}

function randomColorFromText(text: string) {
  const charCodes = text
    .substring(0, text.length > 3 ? 3 : text.length)
    .split("")
    .map((char) => char.charCodeAt(0))
    .join("");

  return COLOR_CODES[parseInt(charCodes, 10) % COLOR_CODES.length];
}

function randomColor() {
  return COLOR_CODES[Math.floor(Math.random() * COLOR_CODES.length)];
}

export const getColor = ({
  pictureUrl,
  color,
  name = "",
  fallbackBackgroundColor = "#fff",
}: IGetColorParams) => {
  if (pictureUrl && pictureUrl !== "default") {
    return fallbackBackgroundColor;
  }

  if (color) {
    return color;
  }

  return name.length === 0 ? randomColor() : randomColorFromText(name);
};

export const parseInitials = (fullName: string) => {
  const partsOfName = fullName.split(" ");
  const [firstName] = partsOfName;
  let lastName = "";

  if (partsOfName.length > 1) {
    lastName = partsOfName[partsOfName.length - 1];
  }

  return [firstName, lastName]
    .map((name) => name.charAt(0).toUpperCase())
    .join("");
};
