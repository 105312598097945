import { Box } from "@remo-co/ui-core/src/components/Box";
import { Tooltip } from "@remo-co/ui-core/src/components/Tooltip";
import { Typography } from "@remo-co/ui-core/src/components/Typography";
import { Button } from "@remo-co/ui-core/src/components/Button";
import moment from "moment";
import { getCurrentTimezone } from "helpers/time/timezone";
import { useI18n } from "i18n";
import { formatDuration } from "helpers/time/format";
import { Delete } from "@remo-co/ui-core/src/icons/Delete";
import { SaveAlt } from "@remo-co/ui-core/src/icons/SaveAlt";
import { AvTimer } from "@remo-co/ui-core/src/icons/AvTimer";
import { getRecordingName } from "modules/recording";
import { RecordingObject } from "@remo-co/types";
import { useStyles } from "./styles";

interface Props {
  eventCode: string;
  recording: RecordingObject;
  onDownload(recording: RecordingObject): void;
  onDelete(recording: RecordingObject): void;
}

const RecordingItem = ({
  eventCode,
  onDownload,
  onDelete,
  recording,
}: Props): JSX.Element => {
  const styles = useStyles();
  const isAvailable = recording.status === "finished";
  const { t } = useI18n();
  const recordedAt = moment(recording.start_ts * 1000).format(
    "YYYY-MM-DD HH:mm:ss",
  );

  const recordingTitle = getRecordingName(eventCode, recording);

  return (
    <Box className={styles.recordingItem}>
      <Box>
        <Tooltip title={recordingTitle}>
          <Typography noWrap data-testid="recordingName">
            {recordingTitle}
          </Typography>
        </Tooltip>
        <Typography data-testid="recordedAt">
          {recordedAt} {getCurrentTimezone()}
        </Typography>
      </Box>
      {isAvailable ? (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          width={160}
        >
          <Typography noWrap data-testid="recordingDuration">
            {formatDuration(recording.duration)}
          </Typography>
          <Box sx={{ display: "flex" }}>
            <Button
              onClick={() => onDownload(recording)}
              variant="ghost"
              color="blue"
              size="md"
              data-testid="downloadRecordingButton"
              isIconButton
            >
              <SaveAlt />
            </Button>
            <Button
              onClick={() => onDelete(recording)}
              variant="ghost"
              color="blue"
              size="md"
              data-testid="deleteRecordingButton"
              isIconButton
            >
              <Delete />
            </Button>
          </Box>
        </Box>
      ) : (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          width={100}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              marginRight: "xs",
            }}
          >
            <AvTimer color="disabled" />
          </Box>
          <Typography color="textSecondary">
            {recording.status === "in-progress" && t("ongoing")}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default RecordingItem;
