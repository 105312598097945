import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";

const useSVGWrapperStyles = makeStyles(() => ({
  svgWrap: {
    display: "flex",
    justifyContent: "center",
    borderRadius: "10px",
    border: "1px solid #eaeef2",
    width: "100%",
    height: "100%",
    position: "absolute",

    "& > svg": {
      width: "100%",
      height: "100%",
    },
  },
}));

export default useSVGWrapperStyles;
