import { useDaily, useMeetingState } from "@daily-co/daily-react";
import {
  selectProfileImage,
  selectOriginalAspectRatio,
  selectConversationId,
  selectNetworkQuality,
} from "modules/audioVideo/redux/selectors";
import { updateUserData } from "modules/audioVideo/redux/slice";
import { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "store/hooks";
import { UserData } from "./types";

export const useUserData = () => {
  const dailyCallObject = useDaily();
  const dispatch = useAppDispatch();

  const conversationId = useSelector(selectConversationId);
  const profileImage = useSelector(selectProfileImage);
  const originalAspectRatio = useSelector(selectOriginalAspectRatio);
  const networkQuality = useSelector(selectNetworkQuality);
  const meetingState = useMeetingState();

  const setUserName = useCallback(
    (name: string) => {
      dailyCallObject?.setUserName(name);
    },
    [dailyCallObject],
  );

  const userData: UserData = useMemo(
    () => ({
      conversationId: conversationId || null,
      image: profileImage || null,
      originalAspectRatio: originalAspectRatio || null,
      networkQuality: networkQuality || null,
    }),
    [conversationId, profileImage, originalAspectRatio, networkQuality],
  );

  const setUserData = useCallback(
    async (payload: Partial<UserData>) => {
      dispatch(updateUserData(payload));
      if (meetingState === "joined-meeting") {
        await dailyCallObject?.setUserData({
          ...userData,
          ...payload,
        });
      }
    },
    [dailyCallObject, userData, dispatch, meetingState],
  );

  const setConversationId = useCallback(
    async ({ conversationId }: { conversationId: string }) => {
      await setUserData({
        conversationId,
      });
    },
    [setUserData],
  );

  return { userData, setUserName, setUserData, setConversationId };
};
