import React from "react";
import "./DurationInput.scss";
import { AccessTime } from "@remo-co/ui-core/src/icons/AccessTime";

interface IDurationInputProps {
  value: number;
  name: string;
  onFieldChange: (name: string, value: number) => void;
}

const DurationInput = ({
  value = 30,
  name,
  onFieldChange,
}: IDurationInputProps) => {
  const [hours, setHours] = React.useState("00");
  const [mins, setMins] = React.useState("00");

  React.useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    setHours(pad(`${Math.floor(value / 60)}`));
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    setMins(pad(`${value % 60}`));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    // eslint-disable-next-line radix
    const hoursInt = parseInt(hours);
    // eslint-disable-next-line radix
    const minsInt = parseInt(mins);

    if (hoursInt >= 0 && minsInt >= 0) {
      onFieldChange(name, hoursInt * 60 + minsInt);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hours, mins]);

  const pad = (num: number | string) => String(num).padStart(2, "0");

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { target } = e;

    let textVal = target.value;

    // Get only digits
    // eslint-disable-next-line radix
    textVal = `${parseInt(textVal)}`.slice(-2);

    if (target.name === "hours") {
      setHours(pad(textVal));
    }

    if (target.name === "mins") {
      setMins(pad(textVal));
    }
  };

  return (
    <div className="duration-input">
      <AccessTime />
      <input
        type="text"
        pattern="[0-9]{2}"
        onChange={onChange}
        value={hours}
        name="hours"
        maxLength={3}
      />
      :
      <input
        type="text"
        pattern="[0-9]{2}"
        onChange={onChange}
        value={mins}
        name="mins"
        maxLength={3}
      />
    </div>
  );
};

export default DurationInput;
