import { ref, set } from "firebase/database";
import { database, serverValue } from "services/firebaseService/firebaseConfig";
import logger from "logging/logger";

const getRoomWhiteboardDocRef = (roomId: string) =>
  ref(database, `Room/${roomId}/Whiteboard`);

const openWhiteboardFirebase = (
  roomId: string,
  data: { [key: string]: unknown },
): void => {
  logger.info(
    `[openWhiteboard] params: ${JSON.stringify({ roomId, ...data })}`,
  );

  set(getRoomWhiteboardDocRef(roomId), {
    ...data,
    startedAt: serverValue.TIMESTAMP,
  });
};

export default {
  getRoomWhiteboardDocRef,
  openWhiteboardFirebase,
};
