import { Theme } from "@remo-co/ui-core/src/types";
import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";

const useStyles = makeStyles<Theme, { isBroadcasting?: boolean }>((theme) => ({
  header: {
    color: `${theme.palette.blue.dark} !important`,
    padding: "6px",
    position: "relative",
    boxSizing: "border-box",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    lineHeight: "2.5rem",
    height: "48px",
    borderTop: ({ isBroadcasting }) =>
      isBroadcasting ? `1px solid ${theme.palette.blue.darkShade2}` : "",
    borderBottom: ({ isBroadcasting }) =>
      isBroadcasting ? `1px solid ${theme.palette.blue.darkShade2}` : "",
    borderRadius: "2px 2px 0px 0",
    [theme.breakpoints.down("sm")]: {
      borderRadius: "4px 4px 0px 0",
    },
  },
  button: {
    color: theme.palette.text.primary,
    width: "40px",
    height: "40px",
    padding: "0px",
  },
  teamName: ({ isBroadcasting }) => ({
    alignSelf: "center",
    padding: "6px",
    flex: 1,
    userSelect: "none",
    borderRadius: "5px",
    maxWidth: "100%",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
    color: isBroadcasting ? theme.palette.white : theme.palette.blue.dark,
  }),
  exportButton: {
    display: "inline-flex",
    justifyContent: "center",
    alignItems: "center",
    width: "40px",
    height: "40px",
  },
  redDot: {
    background: "var(--LightRed)",
    width: "10px",
    height: "10px",
    borderRadius: "50%",
    position: "absolute",
    left: "7px",
  },
}));

export default useStyles;
