import { useEffect, useMemo, useState } from "react";
import { useClient } from "@splitsoftware/splitio-react";
import useCompany from "modules/company/hooks/useCompany";
import { useSelector } from "react-redux";
import { selectUser } from "modules/auth/redux/selectors";
import { useCompanyPlanSettings } from "modules/companyPlanSettings";
import { updateFeatureFlagInContext } from "modules/monitoring";
import { CompanyFeatures } from "services/splitService/features";

/**
 *
 * @param feature string: feature key, see CompanyFeatures
 * @returns Split treatment object
 *
 * Allows Split targeting rules for the following: company code, company service
 * plan id, current user email (NOT RECOMMENDED, use sparingly and/or for test purposes)
 */
const useCompanySplitTreatment = (feature: CompanyFeatures) => {
  const { company } = useCompany();
  const { myCompanyPlan } = useCompanyPlanSettings();
  const user = useSelector(selectUser);
  const companyClient = useClient(company?.code ?? undefined, "Company");
  const [client, setClient] = useState<SplitIO.IClient>();

  useEffect(() => {
    // eslint-disable-next-line promise/always-return, promise/prefer-await-to-then
    companyClient?.ready().then(() => {
      setClient(companyClient);
    });
  }, [companyClient]);

  const treatment = useMemo(() => {
    if (!client) {
      return "pending";
    }

    const resolvedTreatment = client.getTreatment(feature, {
      plan: myCompanyPlan?.planId ?? "",
      code: company?.code ?? "",
      userEmail: user?.email ?? "",
    });

    updateFeatureFlagInContext(feature, resolvedTreatment);

    return resolvedTreatment;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client, company?.code, myCompanyPlan?.planId, user?.email]);

  return {
    treatment: treatment ?? "pending",
    featureEnabled: treatment === "on",
  };
};

export default useCompanySplitTreatment;
