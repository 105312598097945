import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";

export const useStyles = makeStyles((theme) => ({
  networkingRecommendationToolTipContainer: {
    padding: theme.spacing(1.25),
  },
  networkingRecommendationToolTipDescription: {
    fontWeight: 800,
  },
  networkingRecommendationToolTipList: {
    margin: 0,
    paddingLeft: theme.spacing(2.5),
  },
  networkingRecommendationsRequestContactInfo: {
    paddingTop: theme.spacing(1.3125),
    paddingBottom: theme.spacing(1.3125),
    paddingLeft: theme.spacing(2.5),
    cursor: "pointer",
    textDecoration: "underline",
  },
  tooltip: {
    backgroundColor: theme.palette.yellow.lightShade1,
    width: theme.spacing(37.5),
    gap: 0,
    opacity: 1,
    textAlign: "left",
    color: theme.palette.black,
    "& .MuiTooltip-arrow": {
      color: theme.palette.yellow.lightShade1,
    },
  },
  recommendedTooltipTitleContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
  recommendedTooltipTitle: {
    zIndex: 9999,
    position: "absolute",
    bottom: theme.spacing(1.25),
    padding: `0px ${theme.spacing(1.25)} 0px ${theme.spacing(1.25)}`,
    gap: theme.spacing(1.25),
    borderRadius: theme.spacing(0.625),
    background: theme.palette.yellow.dark,
  },
}));
