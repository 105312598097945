import { useCallback, useEffect, useRef, useState } from "react";
import { SVGSourceTypes } from "modules/customFloorPlan/constants";
import CustomFloorPlanOverlay, {
  ICFPOverlayHandle,
} from "modules/customFloorPlan/CustomFloorPlanOverlay";
import { getFullScreenScaleVector } from "modules/customFloorPlan/utils";
import useNotificationActions from "modules/notification/hooks/useNotificationActions";
import { ISVGWrapperHandle } from "modules/customFloorPlan/validator/svgWrapper/SVGWrapper";
import { useI18n } from "i18n";
import { IMapTemplate } from "types/theater";
import {
  computeForOverlap,
  getNewSvgSource,
  parseFloorPlanConfig,
  shouldUseDifferentsSvgSource,
} from "./utils";
import SVGWrapper from "../../validator/svgWrapper";

interface IFloorPlanParserProps {
  fileUrl: string;
  setFloorPlanConfig: (config: Partial<IMapTemplate>) => void;
  setOverlapCheckResult: (rooms: string[]) => void;
}
/**
 * This component is responsible for
 *  - validating floor plans
 *  - parse the floor plans
 *  - generate the floor plan config
 *  - validate overlaps
 */
const FloorPlanParser = ({
  fileUrl,
  setFloorPlanConfig,
  setOverlapCheckResult,
}: IFloorPlanParserProps): JSX.Element => {
  const [isParsing, setIsParsing] = useState(false);
  const { addErrorNotification } = useNotificationActions();
  const [config, setConfig] = useState<Partial<IMapTemplate> | null>(null);
  const [checkedMode, setCheckedMode] = useState("");
  const [svgSource, setSvgSource] = useState(SVGSourceTypes.ABSOLUTE);
  const configWrapRef = useRef<null | HTMLDivElement>(null);
  const svgWrapRef = useRef<ISVGWrapperHandle>(null);
  const transformScale = config ? getFullScreenScaleVector(config) : "";
  const roomRefs = useRef<ICFPOverlayHandle>({ refs: [] });
  const { t } = useI18n(["customFloorPlan"]);

  const generatePlanConfig = useCallback(
    (svgSrc: SVGSourceTypes, mode?: string) => {
      if (!svgWrapRef.current?.ref) {
        return;
      }
      const result = parseFloorPlanConfig(svgWrapRef.current.ref, svgSrc, mode);

      if (!result || !result.newDisplayConfig) {
        addErrorNotification({
          message: t("customFloorPlan:error.template.validation.failed"),
        });
        setIsParsing(false);

        return;
      }

      setConfig(result.newDisplayConfig);
      setSvgSource(result.svgSource);
      setCheckedMode(result.checkedMode || "");
    },
    [addErrorNotification, t],
  );

  // Check overlaps
  const handleOverlapCalculation = useCallback(() => {
    const { overlappingRoomIds, areElementsVisible } = computeForOverlap(
      roomRefs.current.refs,
    );

    // if elements weren't found to be visible, do not update overlap check result
    if (areElementsVisible) {
      setOverlapCheckResult(overlappingRoomIds);
    }
  }, [setOverlapCheckResult]);

  useEffect(() => {
    // STEP 1: If file url is set and div is built, start parsing
    if (fileUrl && !isParsing && !config && svgWrapRef.current?.ref) {
      setIsParsing(true);
      generatePlanConfig(svgSource);

      return;
    }

    if (!config || !isParsing) {
      return;
    }
    // STEP 2: After first parsing, check if it is isometric
    // and see if we have to do parsing again for relative co ordinates

    // check if map is isometric and parsing is not correct and the other mode has not been tried already
    if (shouldUseDifferentsSvgSource(config, svgSource, checkedMode)) {
      generatePlanConfig(getNewSvgSource(svgSource), svgSource);

      return;
    }
    // Complete parsing
    setIsParsing(false);

    // STEP 3: Parsing is completed, validate overlaps and call props method
    if (configWrapRef.current) {
      handleOverlapCalculation();
      setFloorPlanConfig(config);
    }
  }, [
    fileUrl,
    isParsing,
    config,
    svgSource,
    generatePlanConfig,
    checkedMode,
    setFloorPlanConfig,
    handleOverlapCalculation,
  ]);

  const cfArea = config?.conferenceArea;

  return (
    <div className="svg-container" style={{ transform: transformScale }}>
      <SVGWrapper localFileUrl={fileUrl} ref={svgWrapRef} disableSanitize />
      {config && (
        <div
          className="svg-config-wrap"
          ref={configWrapRef}
          data-testid="cfp-svg-wrap"
        >
          <div
            className="svg-conf-area"
            style={{
              width: cfArea?.width,
              height: cfArea?.height,
              left: cfArea?.x,
              top: cfArea?.y,
            }}
            data-testid="cfp-results-overlay"
          >
            <CustomFloorPlanOverlay
              config={config}
              overlapCheckResult={null}
              ref={roomRefs}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default FloorPlanParser;
