import { makeSelectDeviation } from "modules/serverTime/redux/selectors";
import { useSelector } from "react-redux";

// @TODO remove this hook and use selectors
// for serverTime and getCurrentTime [rc-4039]
const useSystemClock = () => {
  const deviation = Number(useSelector(makeSelectDeviation()) ?? 0);

  const localTime = Date.now();

  const serverTime = localTime + deviation;

  const getCurrentTime = () => Date.now() + deviation;

  return {
    serverTime,
    localTime,
    getCurrentTime,
    deviation,
  };
};

export default useSystemClock;
