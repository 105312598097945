import { Typography } from "@remo-co/ui-core/src/components/Typography";
import { CircularProgress } from "@remo-co/ui-core/src/components/CircularProgress";
import { useI18n } from "i18n";
import useStyles from "./styles";

export const LoadingState = () => {
  const { t } = useI18n("common");
  const styles = useStyles();

  return (
    <div className={styles.container} data-testid="ticket-loading-state">
      <CircularProgress size={15} className={styles.progress} />
      <Typography variant="body2">{t("loading")}</Typography>
    </div>
  );
};
