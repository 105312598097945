import { useI18n } from "i18n";
import { MouseEvent, useCallback, useMemo } from "react";
import { IExportAttendeesColumnsMeta } from "modules/eventForm/types";
import { Autorenew as AutorenewIcon } from "@remo-co/ui-core/src/icons/Autorenew";
import { Block as BlockIcon } from "@remo-co/ui-core/src/icons/Block";
import { Delete as DeleteIcon } from "@remo-co/ui-core/src/icons/Delete";
import { EmailOutlined as EmailIcon } from "@remo-co/ui-core/src/icons/EmailOutlined";
import { MTableToolbar } from "material-table";
import moment from "moment";
import useNotificationActions from "modules/notification/hooks/useNotificationActions";
import useEventInviteActions from "modules/eventInvite/hooks/useEventInviteActions";
import logger from "logging/logger";
import { IEvent } from "modules/event/types";
import { useTheme } from "@remo-co/ui-core/src/hooks/useTheme";
import { Box } from "@remo-co/ui-core/src/components/Box";
import { Tooltip } from "@remo-co/ui-core/src/components/Tooltip";
import { IconButton } from "@remo-co/ui-core/src/components/IconButton";
import { useCustomRegistrationQuestions } from "modules/registration";
import { addDialogNotification } from "modules/dialogNotification/redux/dialogNotificationSlice";
import { useAppDispatch } from "store/hooks";
import { DataTable } from "./components";
import useAttendeeActions from "./hooks/useAttendeeActions";
import exportAttendeeHelper from "./exportAttendeeHelper";
import EmailStatus from "./components/EmailStatus";
import { IEventMemberDataResults } from "./types";

interface Props {
  attendeeData: IEventMemberDataResults[];
  getAttendeeData: () => void;
  isFetchingAttendeeData: boolean;
  eventData: IEvent;
  setEmails: (args: string[]) => void;
}

const headerStyle = { whiteSpace: "nowrap" };

const AttendeeList = ({
  attendeeData,
  getAttendeeData,
  isFetchingAttendeeData,
  eventData,
  setEmails,
}: Props): JSX.Element => {
  const { t } = useI18n(["common", "event", "eventForm"]);
  const theme = useTheme();
  const timeZone = moment.tz.guess();
  const dispatch = useAppDispatch();
  const { updateEventMemberAccess } = useAttendeeActions();
  const { sendAttendeeInvite, removeAttendee } = useEventInviteActions();
  const { addErrorNotification, addSuccessNotification } =
    useNotificationActions();
  const { questions } = useCustomRegistrationQuestions(eventData.id);

  const handleResendEmails = useCallback(
    async (item: IEventMemberDataResults) => {
      if (!eventData || eventData.theaters?.length === 0) {
        addErrorNotification({ message: t("event:event.not.found") });

        return;
      }

      if (!item.email) {
        addErrorNotification({ message: t("event:email.not.found") });

        return;
      }

      // force resend
      try {
        const resp = await sendAttendeeInvite({
          eventId: eventData.id,
          emails: [item.email],
          force: true,
        });
        if (resp.isSuccess) {
          addSuccessNotification({
            message: t("email.message.sent"),
            position: "tc",
            autoDismiss: 5,
          });
          setEmails([]);
          getAttendeeData();
        } else {
          throw new Error(resp.message);
        }
      } catch (error) {
        const errorMessage =
          error instanceof Error ? error.message : "something.wrong";
        addErrorNotification({
          message: t(errorMessage),
          position: "tc",
          autoDismiss: 5,
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [eventData],
  );

  const handleAttendeeRemove = useCallback(
    (item: IEventMemberDataResults) => {
      dispatch(
        addDialogNotification({
          confirmTestId: "remove-confirm-button",
          hideCloseButton: true,
          message: t("event:remove.email.guest", {
            key: `${item.email}`,
          }),
          onConfirm: async () => {
            const resp = await removeAttendee(
              eventData.id,
              item.attendeeId,
              item.inviteId,
            );

            if (resp && resp.isSuccess) {
              getAttendeeData();
            }
          },
        }),
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [eventData],
  );

  const handleToggleUserAccess = useCallback(
    (item: IEventMemberDataResults) => {
      dispatch(
        addDialogNotification({
          confirmTestId: "block-confirm-button",
          hideCloseButton: true,
          message: t("event:block.unblock.event", {
            blockLogic: item.isBlocked ? t("event:unblock") : t("event:block"),
            itemName: item.name,
          }),
          onConfirm: async () => {
            if (typeof item.userId !== "string") {
              logger.error(
                "[AttendeeList] no user id found while updating user access",
              );

              return;
            }

            const resp = await updateEventMemberAccess(
              eventData.id,
              eventData.theaters && eventData.theaters[0].id,
              item.userId,
              !item.isBlocked,
            );

            if (resp.isSuccess) {
              getAttendeeData();
            }
          },
        }),
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [eventData],
  );

  const questionColumns = useMemo(
    () =>
      questions.map((question) => ({
        title: question.question,
        field: `question-${question._id}`,
        cellStyle: {
          maxWidth: "150px",
          overflow: "hidden",
          textOverflow: "ellipsis",
        },
        export: true,
        headerStyle,
      })),
    [questions],
  );

  return (
    <DataTable
      title={t("event:attendees")}
      columns={[
        {
          title: t("full.name"),
          field: "name",
          export: true,
          cellStyle: { width: "calc(20% - 0px)" },
          headerStyle,
        },
        {
          title: t("email"),
          field: "email",
          export: true,
          cellStyle: { width: "calc(30% - 0px)" },
          headerStyle,
        },
        {
          title: t("event:invited"),
          field: "invitedText",
          export: true,
          cellStyle: { width: "calc(20% - 0px)" },
          render: ({ invitedText }: IEventMemberDataResults) => t(invitedText),
          headerStyle,
        },
        {
          title: t("event:email.status"),
          field: "emailStatus",
          render: ({ emailStatus }: IEventMemberDataResults) => (
            <EmailStatus emailStatus={emailStatus} />
          ),
          export: true,
          cellStyle: { width: "calc(20% - 0px)" },
          headerStyle,
        },
        {
          title: t("event:registered"),
          field: "registeredText",
          export: true,
          cellStyle: { width: "calc(10% - 0px)" },
          render: ({ registeredText }: IEventMemberDataResults) =>
            t(registeredText),
          headerStyle,
        },
        {
          title: t("event:attended"),
          field: "attendedText",
          export: true,
          cellStyle: { width: "calc(10% - 0px)" },
          render: ({ attendedText }: IEventMemberDataResults) =>
            t(attendedText),
          headerStyle,
        },
        {
          title: t("eventForm:registered.time.zone", {
            key: `${timeZone}`,
          }),
          field: "registeredAt",
          hidden: true,
          export: true,
        },
        ...questionColumns,
      ]}
      data={attendeeData}
      actions={[
        (rowData: IEventMemberDataResults) => ({
          // eslint-disable-next-line react/no-unstable-nested-components
          icon: () => <EmailIcon data-testid="email-button" />,
          tooltip: !rowData.userId ? t("event:resend.email") : false,
          onClick: (
            evt: MouseEvent<HTMLButtonElement>,
            item: IEventMemberDataResults,
          ) => handleResendEmails(item),
          disabled: !!rowData.userId,
        }),
        (rowData: IEventMemberDataResults) => ({
          // eslint-disable-next-line react/no-unstable-nested-components
          icon: () => (
            <BlockIcon
              data-testid="block-button"
              style={{ color: rowData.isBlocked ? "red" : "unset" }}
            />
          ),
          tooltip: rowData.userId
            ? `${rowData.isBlocked ? t("unblock.user") : t("block.user")}`
            : false,
          onClick: (
            evt: MouseEvent<HTMLButtonElement>,
            item: IEventMemberDataResults,
          ) => handleToggleUserAccess(item),
          disabled: !rowData.userId,
        }),
        (rowData: IEventMemberDataResults) => ({
          // eslint-disable-next-line react/no-unstable-nested-components
          icon: () => <DeleteIcon data-testid="delete-button" />,
          tooltip: !rowData.userId
            ? t("event:remove.guest")
            : t("event:already.event.in"),
          onClick: (
            evt: MouseEvent<HTMLButtonElement>,
            item: IEventMemberDataResults,
          ) => handleAttendeeRemove(item),
          disabled: !!rowData.userId,
        }),
      ]}
      options={{
        exportButton: true,
        exportAllData: true,
        showTitle: false,
        pageSize: 5,
        pageSizeOptions: [],
        padding: "dense",
        actionsColumnIndex: 0,
        exportCsv: (
          columns: IExportAttendeesColumnsMeta[],
          records: IEventMemberDataResults[],
        ) => {
          exportAttendeeHelper.downloadCSV(
            `${eventData.code}.csv`,
            columns,
            records,
          );
        },
      }}
      components={{
        // eslint-disable-next-line react/no-unstable-nested-components
        Toolbar: (props: unknown) => (
          <Box>
            <MTableToolbar {...(props as object)} />
            <Box
              style={{
                position: "absolute",
                left: "10px",
                top: "10px",
              }}
            >
              <Tooltip title={t("event:reload.data") as string}>
                <IconButton
                  disabled={isFetchingAttendeeData}
                  size="small"
                  onClick={getAttendeeData}
                  color="primary"
                >
                  <AutorenewIcon />
                </IconButton>
              </Tooltip>
            </Box>
          </Box>
        ),
      }}
      style={{ fontFamily: theme.typography.fontFamily }}
    />
  );
};

export default AttendeeList;
