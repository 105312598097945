import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";

export const useStyles = makeStyles((theme) => ({
  container: {
    position: "absolute",
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  icon: {
    width: "24px",
    height: "24px",
  },
  text: {
    marginTop: "10px",
    color: theme.palette.gray.disabled,
  },
}));

export default useStyles;
