import { MutableRefObject, useEffect } from "react";

interface Props {
  onIframeClick: () => void;
  iframeRef: MutableRefObject<HTMLIFrameElement | null>;
}

export const useIframeController = ({ onIframeClick, iframeRef }: Props) => {
  useEffect(() => {
    const onBlur = () => {
      if (
        document.activeElement &&
        document.activeElement.nodeName.toLowerCase() === "iframe" &&
        iframeRef.current &&
        iframeRef.current === document.activeElement
      ) {
        onIframeClick();
      }
    };

    window.addEventListener("blur", onBlur);

    return () => {
      window.removeEventListener("blur", onBlur);
    };
  }, [onIframeClick, iframeRef]);
};
