import { getCurrentUserId } from "helpers/reduxHelper";
import logger from "logging/logger";
import { addUserPreferencesInSpace } from "modules/userPreferences/redux/actions";
import { onBoard } from "modules/appSetting/redux/actions";
import store from "store";
import { IUserPreferences } from "modules/userPreferences/redux/types";
import { collection, doc, setDoc, onSnapshot } from "firebase/firestore";
import { firestore } from "services/firebaseService/firebaseConfig";

export interface IGlobalUserPreference {
  isInHostOnboarding?: boolean;
  cameraSettings?: CameraSetting;
  disableSwitchFloorNotification?: boolean;
  tutorial?: { done: boolean; step: number };
  hasClosedRemojiModal?: boolean;
  disableSwitchEventNotification?: boolean;
  hasClosedFirstTimeEventDirectoryNudge?: boolean;
  layoutChangeToolTip?: { visible: number };
}

export type CameraSetting = {
  originalAspectRatio: boolean;
};

const globalUserPreferencesDB = collection(firestore, "UserPreferences");

let listener: () => void;

export const updateGlobalUserPreference = (data: IGlobalUserPreference) => {
  const userId = getCurrentUserId();

  if (userId) {
    const userPreferencesDocRef = doc(globalUserPreferencesDB, userId);
    setDoc(userPreferencesDocRef, data, { merge: true }).catch((error) => {
      logger.error(
        `[updateGlobalUserPreference] adding document: error: ${error.message} userId: ${userId}`,
      );
    });
  }
};

export const unsubscribeToGlobalUserPreference = () => {
  if (listener) {
    listener();
  }
};

export const subscribeToGlobalUserPreference = () => {
  const userId = getCurrentUserId();

  if (userId) {
    const userPreferencesDocRef = doc(globalUserPreferencesDB, userId);
    listener = onSnapshot(userPreferencesDocRef, (snap) => {
      const userPrefsData = (snap.data() || {}) as unknown as IUserPreferences;

      userPrefsData.isLoaded = true;
      store.dispatch(addUserPreferencesInSpace(userId, userPrefsData));

      // If user is in host onboarding flow, then no need to show guest onboarding
      if (userPrefsData?.isInHostOnboarding) {
        store.dispatch(onBoard(true));
      }
    });
  }

  return null;
};
