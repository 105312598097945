import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";

export const useStyles = makeStyles((theme) => ({
  note: {
    border: `1px solid ${theme.palette.gray.muted1}`,
    maxWidth: "22rem",
  },
  noteIcon: {
    marginRight: "1rem",
  },
}));
