import { useMemo } from "react";
import { Typography } from "@remo-co/ui-core/src/components/Typography";
import { Select } from "@remo-co/ui-core/src/components/Select";
import { Tooltip } from "@remo-co/ui-core/src/components/Tooltip";
import { Button } from "@remo-co/ui-core/src/components/Button";
import { Add } from "@remo-co/ui-core/src/icons/Add";
import { Delete } from "@remo-co/ui-core/src/icons/Delete";
import { useI18n } from "i18n";
import useStyles from "./styles";

interface Props {
  presets?: string[];
  selectedPreset: string;
  handlePresetChange: (preset: string) => void;
  isLoading: boolean;
  createPreset: () => void;
  deletePreset: (presetId: string) => void;
  isInEvent?: boolean;
  disabled?: boolean;
}

export const PresetSelect = ({
  presets,
  selectedPreset,
  handlePresetChange,
  isLoading,
  createPreset,
  deletePreset,
  isInEvent,
  disabled,
}: Props) => {
  const { t } = useI18n(["common", "manageEvent"]);
  const styles = useStyles({
    isInEvent,
  });

  const defaultPreset = {
    [t("manageEvent:assign.tables.presets.pre.event")]: [
      {
        value: "default",
        label: t("manageEvent:preset.number", { number: 1 }),
      },
    ],
  };

  const presetOptions = useMemo(
    () =>
      presets && presets.length > 0
        ? {
            [t("manageEvent:assign.tables.presets.in.event")]: presets.map(
              (preset, index) => ({
                value: preset,
                label: t("manageEvent:preset.number", {
                  number: index + 2,
                }),
              }),
            ),
          }
        : {},
    [presets, t],
  );

  return (
    <div className={styles.presetSelectContainer}>
      {!isInEvent && (
        <Typography variant="h5">
          {t("manageEvent:assign.tables.presets")}
        </Typography>
      )}
      <div className={styles.presetSelect} data-testid="preset-select-div">
        <Select
          grouped
          fullWidth
          size="small"
          value={selectedPreset}
          onChange={(selectedOption) => {
            handlePresetChange(selectedOption);
          }}
          disabled={
            isLoading ||
            disabled ||
            (!presets?.includes(selectedPreset) && selectedPreset !== "default")
          }
          optionGroups={{
            ...defaultPreset,
            ...presetOptions,
          }}
          inModal
          // eslint-disable-next-line i18next/no-literal-string
          inputTestId="preset-select"
          data-testid="preset-select-container"
        />
      </div>
      <Tooltip
        title={`${t("manageEvent:delete.preset")}`}
        placement="bottom"
        PopperProps={{
          className: styles.tooltip,
        }}
      >
        <Button
          variant="secondary"
          color="dark"
          size="md"
          isIconButton
          onClick={() => {
            deletePreset(selectedPreset);
          }}
          data-testid="delete-preset"
          disabled={selectedPreset === "default" || disabled}
          loading={isLoading}
        >
          <Delete />
        </Button>
      </Tooltip>
      {(!presets || presets.length < 5) && (
        <Tooltip
          title={`${t("manageEvent:create.preset")}`}
          placement="bottom"
          PopperProps={{
            className: styles.tooltip,
          }}
        >
          <Button
            variant="secondary"
            color="dark"
            size="md"
            isIconButton
            onClick={createPreset}
            data-testid="create-preset"
            loading={isLoading}
            disabled={disabled}
          >
            <Add />
          </Button>
        </Tooltip>
      )}
    </div>
  );
};
