import { useI18n } from "i18n";
import { Typography } from "@remo-co/ui-core/src/components/Typography";
import { useEffect, useState } from "react";
import { useStyles } from "./styles";

interface Props {
  topics: string[];
}

export const DiscussionContent = ({ topics = [] }: Props) => {
  const { t } = useI18n("event");
  const [seeMore, setSeeMore] = useState(true);
  const [commonTopics, setCommonTopics] = useState<string[]>(topics);
  const styles = useStyles();

  useEffect(() => {
    setCommonTopics(seeMore ? topics.slice(0, 2) : topics);
  }, [seeMore, topics]);

  const toggleSeeMore = () => {
    setSeeMore(!seeMore);
  };

  return (
    <>
      <ul className={styles.list} data-testid="networking-discussion-list">
        {commonTopics.map((topic) => (
          <li key={topic}>
            <Typography variant="body1">{topic}</Typography>
          </li>
        ))}
      </ul>
      {seeMore && (
        <Typography
          variant="body1"
          className={styles.seeMore}
          onClick={toggleSeeMore}
        >
          {t("see.more")}
        </Typography>
      )}
    </>
  );
};
