import { startCase } from "lodash";
import { IRoom, ISeatPosition } from "types";
import logger from "logging/logger";
import { IMapTemplate } from "types/theater";
import { ISlot } from "modules/theater/types";
import { MAX_ALLOWED_PER_FLOOR } from "@remo-co/constants";
import { ConfigRoomNames, SVGRoomNames, SVGSourceTypes } from "../constants";
import {
  configHasRoomName,
  convertSVGPointListToString,
  convertSvgPathToRelative,
  getRoomName,
  getRoomSlug,
} from "../utils";

export const generatePlanConfig = (
  el: SVGGraphicsElement | null,
  svgSource: SVGSourceTypes,
) => {
  if (!el) {
    return null;
  }

  try {
    let configData: Partial<IMapTemplate> = {
      zoom: {
        min: 0.5,
        max: 3,
      },
      origin: { x: 0, y: 0 },
      rooms: [],
      width: parseFloat(el.getAttribute("width") || ""),
      height: parseFloat(el.getAttribute("height") || ""),
      seatRadius: 22.5,
      sponsors: [],
      backgroundColor: "#ffffff",
      folderName: "",
      code: "",
    };

    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    configData = addConferenceArea(el, configData);
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    configData = addRooms(el, configData, svgSource);
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    configData = addSponsors(el, configData);
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    configData = addHasConferenceArea(el, configData);

    const seatCount: number =
      configData.rooms?.reduce((acc: number, room: IRoom) => {
        if (room.positions && room.order) {
          return acc + room.positions.length;
        }

        return acc;
      }, 0) ?? 0;

    configData.seats = seatCount;
    configData.maxCapacity = Math.min(
      MAX_ALLOWED_PER_FLOOR,
      Math.ceil(seatCount - seatCount * 0.2),
    ); // reduce 20% seats for allowing users to move

    logger.info(`
    [CustomFloorPlans][generatePlanConfig] ${seatCount} seats added in ${configData?.rooms?.length} tables. Found ${configData.sponsors?.length} sponsors
  `);

    if (!configHasRoomName(configData, ConfigRoomNames.STAGE)) {
      logger.info("[CustomFloorPlans][generatePlanConfig] Stage missing");
    }

    return configData;
  } catch (error) {
    logger.error(
      "[CustomFloorPlans][generatePlanConfig] An error occurred during template validation.",
    );

    return null;
  }
};

const getRoomNameParams = (
  nameEl: SVGGraphicsElement | null,
  roomY: number,
) => {
  if (!nameEl) {
    return null;
  }
  const { y } = nameEl.getBoundingClientRect();
  // eslint-disable-next-line no-unsafe-optional-chaining
  const { height } = nameEl?.getBBox();

  const compStyles = window.getComputedStyle(nameEl);

  return {
    x: 0,
    y: y - roomY + height,
    color:
      compStyles?.getPropertyValue("fill") ||
      compStyles?.getPropertyValue("color"),
    // eslint-disable-next-line radix
    fontSize: parseInt(compStyles?.getPropertyValue("font-size")),
  };
};

const addConferenceArea = (
  el: SVGGraphicsElement,
  configData: Partial<IMapTemplate>,
) => {
  const newConfigData = { ...configData };
  const area: SVGGraphicsElement | null =
    el.querySelector("[id^=conference_area]") ||
    el.querySelector("[id^=conference-area]");

  if (area) {
    const { width, height } = area.getBBox();
    const { x, y } = area.getBoundingClientRect();

    newConfigData.conferenceArea = { x, y, width, height };

    logger.info(
      "[CustomFloorPlans][addConferenceArea] plan has conference area",
    );
  } else {
    logger.error(
      "[CustomFloorPlans][addConferenceArea] Floor plan missing conference area. Using SVG height and width",
    );

    const { width, height } = el.getBBox();
    const { x, y } = el.getBoundingClientRect();

    newConfigData.conferenceArea = { x, y, width, height };
  }

  return newConfigData;
};

const addRooms = (
  el: SVGGraphicsElement,
  configData: Partial<IMapTemplate>,
  svgSource: SVGSourceTypes,
) => {
  const rooms: NodeListOf<SVGGraphicsElement> =
    el.querySelectorAll("[id^=table-]");
  const roomNodes = Array.prototype.slice.call(rooms, 0);

  roomNodes.reverse();
  let order = 1;

  roomNodes.forEach((roomEl: SVGGraphicsElement) => {
    const roomNameEl: SVGGraphicsElement | null =
      roomEl.querySelector("[id^=name]");

    const roomName = getRoomName(roomEl.getAttribute("id"));

    if (roomName) {
      const { width, height } = roomEl.getBBox();
      const { x, y } = roomEl.getBoundingClientRect();

      const roomBoundary =
        roomEl.querySelector<SVGPolygonElement>("[id^=clickable-area]") ??
        undefined;

      const roomNameTextPathEl =
        roomEl.querySelector<SVGPathElement>("[id^=text-path-name]") ??
        undefined;

      let svgPath;

      if (roomNameTextPathEl) {
        svgPath = roomNameTextPathEl?.getAttribute("d") ?? undefined;

        if (svgSource === SVGSourceTypes.ABSOLUTE && svgPath) {
          svgPath = convertSvgPathToRelative(svgPath, x, y);
        }
      }

      const roomConfig: Partial<IRoom> = {
        name: startCase(roomName),
        x,
        y,
        width,
        height,
        alwaysOpen: true,
        positions: [],
        nameParams: getRoomNameParams(roomNameEl, y),
        nameTextPath: roomNameTextPathEl && svgPath,
        code: `${getRoomSlug(roomName)}`,
        boundaryPolygon:
          roomBoundary?.points &&
          convertSVGPointListToString(roomBoundary.points, x, y, svgSource),
      };

      const seats: NodeListOf<SVGGraphicsElement> =
        roomEl.querySelectorAll("[id^=seat]");

      seats.forEach((seat: SVGGraphicsElement) => {
        const { width: seatW, height: seatH } = seat.getBBox();
        const { x: seatX, y: seatY } = seat.getBoundingClientRect();

        if (configData && configData.seatRadius) {
          roomConfig?.positions?.push({
            // eslint-disable-next-line no-unsafe-optional-chaining
            x: seatX - x + seatW / 2 - configData?.seatRadius,
            // eslint-disable-next-line no-unsafe-optional-chaining
            y: seatY - y + seatH / 2 - configData?.seatRadius,
          });
        }
      });

      if (roomConfig.positions && roomConfig.positions.length) {
        roomConfig.positions = roomConfig.positions.reverse();
      }

      const adminSeats: NodeListOf<SVGGraphicsElement> =
        roomEl.querySelectorAll("[id^=admin-seat]");

      adminSeats.forEach((seat: SVGGraphicsElement) => {
        const { width: seatW, height: seatH } = seat.getBBox();
        const { x: seatX, y: seatY } = seat.getBoundingClientRect();

        roomConfig.adminSeats = roomConfig.adminSeats || [];

        if (configData && configData.seatRadius) {
          roomConfig.adminSeats.push({
            // eslint-disable-next-line no-unsafe-optional-chaining
            x: seatX - x + seatW / 2 - configData?.seatRadius,
            // eslint-disable-next-line no-unsafe-optional-chaining
            y: seatY - y + seatH / 2 - configData?.seatRadius,
          } as ISeatPosition);
        }
      });

      if (roomConfig.adminSeats && roomConfig.adminSeats.length) {
        // Take only one seat as of now
        roomConfig.adminSeats = [roomConfig.adminSeats[0]];
      }

      if (
        !(
          roomName === SVGRoomNames.STAGE ||
          !roomConfig.positions ||
          !roomConfig.positions.length
        )
      ) {
        roomConfig.order = order;
        order += 1;
      }

      // If other than stage, if no posistions, dont create rooms
      if (
        (!roomConfig.positions || !roomConfig.positions.length) &&
        roomName !== SVGRoomNames.STAGE
      ) {
        logger.info(
          `[CustomFloorPlans][addRooms] Skipping table ${roomName} because of no seats.`,
        );
      } else {
        logger.info(
          `[CustomFloorPlans][addRooms] Added table ${roomName} with ${roomConfig?.positions?.length} seats.`,
        );

        if (configData && configData.rooms) {
          configData.rooms.push(roomConfig as IRoom);
        }
      }
    } else {
      logger.info(
        `[CustomFloorPlans][addRooms] Skipping table because of no name: ${roomEl.getAttribute(
          "id",
        )}`,
      );
    }
  });

  return configData;
};

const addSponsors = (el: Element, configData: Partial<IMapTemplate>) => {
  const newConfigData = { ...configData };
  const sponsors: NodeListOf<SVGGraphicsElement> =
    el.querySelectorAll("[id^=sponsor]");

  sponsors.forEach((sponsor: SVGGraphicsElement) => {
    const { width, height } = sponsor.getBBox();
    const { x, y } = sponsor.getBoundingClientRect();

    if (newConfigData && newConfigData.sponsors) {
      const sponsorSlot = {
        x,
        y,
        width: Math.ceil(width),
        height: Math.ceil(height),
      } as unknown as ISlot;

      newConfigData?.sponsors?.push(sponsorSlot);
    }
  });

  return newConfigData;
};

const addHasConferenceArea = (
  el: Element,
  configData: Partial<IMapTemplate>,
) => {
  const newConfigData = { ...configData };
  const confArea: HTMLElement | null =
    el?.querySelector("[id^=conference_area]") ||
    el?.querySelector("[id^=conference-area]") ||
    null;

  newConfigData.hasConferenceArea = !!confArea;

  return newConfigData;
};

export default {
  generatePlanConfig,
  getRoomNameParams,
  addConferenceArea,
  addRooms,
  addSponsors,
  addHasConferenceArea,
};
