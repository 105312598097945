import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";
import zIndexes from "zIndexes.module.scss";

export const useStyles = makeStyles((theme) => ({
  tableSeatContainer: {
    display: "flex",
    flexDirection: "column",
    borderRadius: "5px",
    padding: "20px",
    marginTop: "15px",
    width: "100%",
    minWidth: "315px",
    boxShadow: "2px 4px 20px rgba(0, 0, 0, 0.1)",
    position: "absolute",
    background: theme.palette.white,
    zIndex: zIndexes.announcementNotificationZIndex,
    textAlign: "left",
  },
  tableSeat: {
    display: "flex",
    justifyContent: "space-between",
  },
  heading: {
    color: theme.palette.black,
  },
  content: {
    marginTop: "10px",
    color: theme.palette.present.greyBackground,
    fontSize: "13px",
  },
}));
