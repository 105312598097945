import { Typography } from "@remo-co/ui-core/src/components/Typography";
import moment from "moment";
import { selectIsInBroadcast } from "modules/broadcast/redux/selectors";
import { useSelector } from "react-redux";
import { useStyles } from "./styles";

interface IMessageTimeProps {
  time: number;
}

const MessageTime = ({ time }: IMessageTimeProps) => {
  const timeText = moment(time).calendar(new Date(), {
    sameDay: "LT [| Today] ",
    nextDay: "LT [| Tomorrow]",
    nextWeek: "LT [| ] dddd",
    lastDay: "LT [| Yesterday]",
    lastWeek: "LT [| Last] dddd",
    sameElse: "llll",
  });
  const isInBroadcast = useSelector(selectIsInBroadcast);
  const classes = useStyles({ isInBroadcast: Boolean(isInBroadcast) });

  return (
    <Typography component="span" className={classes.time}>
      {" "}
      {timeText}
    </Typography>
  );
};

export default MessageTime;
