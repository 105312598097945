import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "store";
import { compareVersions } from "./utils";
import { VersionComparisonResult } from "./types";

export const selectAppVersionLoadingState = (state: RootState) =>
  state.appVersion.loadingState;

export const selectDeployedVersion = (state: RootState) =>
  state.appVersion.deployedVersion;

export const selectCurrentVersion = (state: RootState) =>
  state.appVersion.currentVersion;

export const selectVersionComparisonResult = createSelector(
  [selectDeployedVersion, selectCurrentVersion],
  (deployedVersion, currentVersion) => {
    if (!deployedVersion || !currentVersion) {
      return undefined;
    }
    return compareVersions(deployedVersion, currentVersion);
  },
);

export const selectIsVersionLowerThanDeployed = createSelector(
  [selectVersionComparisonResult],
  (versionComparisonResult) => {
    if (!versionComparisonResult) {
      return false;
    }
    return (
      versionComparisonResult ===
      VersionComparisonResult.CURRENT_VERSION_LOWER_THAN_DEPLOYED
    );
  },
);
