import {
  sendDeleteRequest,
  sendGetRequest,
  sendPostRequest,
  sendPutRequest,
} from "services/apiService";
import { IEventcubeTicket } from "modules/ticketing/types";

export const getEventTickets = (
  eventId: string,
): Promise<APIResponse<{ tickets: IEventcubeTicket[] }>> =>
  sendGetRequest<APIResponse<{ tickets: IEventcubeTicket[] }>>(
    `/event/${eventId}/tickets`,
  );

export const createEventTicket = (
  eventId: string,
  data: Partial<IEventcubeTicket>,
): Promise<APIResponse<{ ticket: IEventcubeTicket }>> =>
  sendPostRequest<
    Partial<IEventcubeTicket>,
    APIResponse<{ ticket: IEventcubeTicket }>
  >(`/event/${eventId}/tickets`, data);

export const updateEventTicket = (
  eventId: string,
  ticketId: string,
  data: Partial<IEventcubeTicket>,
): Promise<APIResponse<{ ticket: IEventcubeTicket }>> =>
  sendPutRequest<
    Partial<IEventcubeTicket>,
    APIResponse<{ ticket: IEventcubeTicket }>
  >(`/event/${eventId}/tickets/${ticketId}`, data);

export const deleteEventTicket = (
  eventId: string,
  ticketId: string,
): Promise<APIResponse> =>
  sendDeleteRequest<Partial<IEventcubeTicket>, APIResponse>(
    `/event/${eventId}/tickets/${ticketId}`,
  );
