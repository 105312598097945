import {
  memo,
  useCallback,
  useEffect,
  useRef,
  useState,
  ChangeEvent,
  forwardRef,
  ForwardedRef,
} from "react";
import { List } from "@remo-co/ui-core/src/components/List";
import { GroupedVirtuoso } from "react-virtuoso";
import { ParticipantGroup } from "modules/participants/hooks/useParticipantGroups/useParticipantGroups";
import { Search, UserRow } from "../../components";
import useStyles from "./styles";
import { useParticipantGroups } from "../../hooks/useParticipantGroups";
import { GroupHeader } from "../GroupHeader";

interface Props {
  switchChatTab?: () => void;
}

const ListComponent = forwardRef(
  (props, ref: ForwardedRef<HTMLUListElement>) => (
    <List dense disablePadding {...props} ref={ref} />
  ),
);

const ParticipantsContainer = ({ switchChatTab }: Props): JSX.Element => {
  const styles = useStyles();
  const input = useRef<HTMLInputElement | null>(null);
  const [query, setQuery] = useState("");
  const { groups, headings, groupLengths, participants, actions } =
    useParticipantGroups(query);

  useEffect(() => {
    // Use input ref with setTimeout for focusing input because autoFocus attribute creating issues when we use transition css or animation.
    const timerId = window.setTimeout(() => {
      if (input.current) {
        input.current.focus();
      }
    }, 300);

    return () => clearTimeout(timerId);
  }, []);

  // debounce change for filter
  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setQuery(e.target.value);
    },
    [setQuery],
  );

  return (
    <div className={styles.container}>
      <Search query={query} inputRef={input} onChange={handleChange} />
      <div
        style={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {Boolean(participants.length) && (
          <GroupedVirtuoso
            data-testid="sc-chat-list"
            components={{
              // @ts-expect-error https://github.com/petyosi/react-virtuoso/issues/864
              List: ListComponent,
            }}
            // eslint-disable-next-line react/no-unstable-nested-components
            groupContent={(index) => (
              <GroupHeader
                heading={headings[index]}
                groupLength={groupLengths[index]}
                moreActions={actions[index]}
              />
            )}
            groupCounts={groupLengths}
            // eslint-disable-next-line react/no-unstable-nested-components
            itemContent={(index, groupIndex) => {
              const user = participants[index];

              return (
                <UserRow
                  key={user.id}
                  group={groups[groupIndex] as ParticipantGroup}
                  user={user}
                  switchChatTab={switchChatTab}
                />
              );
            }}
          />
        )}
      </div>
    </div>
  );
};

export default memo(ParticipantsContainer);
