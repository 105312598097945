import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";
import zIndexes from "zIndexes.module.scss";

export const useStyles = makeStyles(() => ({
  root: {
    position: "absolute",
    bottom: "20px",
    left: "20px",
    zIndex: zIndexes.helpChatZIndex,
  },
}));
