import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export type SpaceState = {
  spaceId: string | null;
  spaceChangeInProgress: boolean;
};

export const initialState: SpaceState = {
  spaceId: null,
  spaceChangeInProgress: false,
};

const spaceReducerSlice = createSlice({
  name: "space",
  initialState,
  reducers: {
    changeSpace: (state) => {
      state.spaceChangeInProgress = true;
    },
    changeSpaceCompleted: (state) => {
      state.spaceChangeInProgress = false;
    },
    setSpaceId: (state, action: PayloadAction<string | null>) => {
      state.spaceId = action.payload;
    },
    resetSpace: () => initialState,
  },
});

export const { changeSpace, changeSpaceCompleted, setSpaceId, resetSpace } =
  spaceReducerSlice.actions;

export default spaceReducerSlice.reducer;
