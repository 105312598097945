import { ReactElement } from "react";
import { Container } from "@remo-co/ui-core/src/components/Container";
import { Typography } from "@remo-co/ui-core/src/components/Typography";
import { Poll as PollIcon } from "@remo-co/ui-core/src/icons/Poll";
import { EmojiEvents as QuizIcon } from "@remo-co/ui-core/src/icons/EmojiEvents";
import { formatTimeAgo } from "helpers/time/format";
import { IUser } from "modules/app/types";
import { getUserName } from "modules/userProfile";
import { EventPollStatus } from "graphql/generated";
import { useStyles } from "./styles";
import StatusPill from "./StatusPill";

interface Props {
  isHost: boolean;
  status: EventPollStatus;
  timestamp: number;
  type: "poll" | "quiz";
  user: IUser;
}

const Metadata = ({
  isHost,
  status,
  timestamp,
  type,
  user,
}: Props): ReactElement => {
  const divider = " • ";
  const classes = useStyles();

  return (
    <Container flex justifyContent="flex-start" className={classes.metadata}>
      {type === "poll" ? (
        <PollIcon color="primary" />
      ) : (
        <QuizIcon color="primary" />
      )}
      <span className={classes.createdData}>
        <Typography variant="caption">
          {getUserName(user)}
          {divider}
          <span data-happo-hide>{formatTimeAgo(timestamp)}</span>
        </Typography>
      </span>
      {isHost && <StatusPill status={status} />}
    </Container>
  );
};

export default Metadata;
