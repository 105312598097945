import { EventPollType } from "graphql/generated";

export enum CustomAppEvents {
  HOST_SEAT_REQUEST = "host-seat-request",
  JOIN_STAGE_REQUEST = "join_stage_request",
  MUTE_USER = "mute_user",
  POLL_QUIZ_LAUNCHED = "poll_quiz_launched",
  REMOVE_FROM_STAGE = "remove_from_stage",
  STOP_SCREEN_SHARE = "stop_screen_share",
}

export type CustomAppEvent =
  | {
      event: CustomAppEvents.JOIN_STAGE_REQUEST;
      joinStage: boolean;
      withMic: boolean;
      withCam: boolean;
    }
  | {
      event: CustomAppEvents.HOST_SEAT_REQUEST;
      tableId: string;
    }
  | {
      event: CustomAppEvents.MUTE_USER;
      setAudio: boolean;
    }
  | {
      event: CustomAppEvents.STOP_SCREEN_SHARE;
    }
  | {
      event: CustomAppEvents.REMOVE_FROM_STAGE;
    }
  | {
      event: CustomAppEvents.POLL_QUIZ_LAUNCHED;
      pollType: EventPollType;
    };
