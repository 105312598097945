import { ChangeEvent } from "react";
import { useI18n } from "i18n";
import { Tooltip } from "@remo-co/ui-core/src/components/Tooltip";
import { Box } from "@remo-co/ui-core/src/components/Box";
import { Info } from "@remo-co/ui-core/src/icons/Info";
import { SwitchInput } from "@remo-co/ui-core/src/components/SwitchInput";
import { EventContentMediaType, EventContentType } from "graphql/generated";
import { useStyles } from "./styles";

interface Props {
  checked: boolean;
  handleChange: (hideOpenInNewTabButton: boolean) => void;
  displayType: EventContentType | undefined;
  mediaType: EventContentMediaType | undefined;
}

export const HideOpenInNewTabButtonCheckbox = ({
  checked,
  handleChange,
  displayType,
  mediaType,
}: Props) => {
  const styles = useStyles();
  const { t } = useI18n(["event"]);

  const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
    handleChange(!event.target.checked);
  };

  if (
    mediaType === EventContentMediaType.Iframe ||
    (displayType === EventContentType.FullScreen &&
      mediaType !== EventContentMediaType.Text)
  ) {
    return (
      <div className={styles.container}>
        <SwitchInput
          onChange={handleCheckboxChange}
          data-testid="hide-open-in-new-tab-button"
          name="hideOpenInNewTabButton"
          checked={checked}
          label={
            <Box display="flex" alignItems="center">
              {t("event:show.option.content.new.tab")}
              <Tooltip
                placement="top"
                title={t("event:show.option.content.new.tab.tooltip")}
              >
                <Info className={styles.tooltipIcon} color="primary" />
              </Tooltip>
            </Box>
          }
        />
      </div>
    );
  }

  return null;
};
