import { useCallback, useEffect, useMemo, useState } from "react";
import { Grid } from "@remo-co/ui-core/src/components/Grid";
import { Paper } from "@remo-co/ui-core/src/components/Paper";
import { Typography } from "@remo-co/ui-core/src/components/Typography";
import { useI18n } from "i18n";
import { getMapTemplateByType } from "modules/event/template";
import {
  ACTIVE_THEMES,
  DEFAULT_FLOOR_PLAN,
  FLOOR_PLAN_THEMES,
} from "modules/customFloorPlan";
import { IMapTemplate, ITheater } from "types/theater";
import { getDefaultTheme, isClassicTheme } from "../../utils";
import { useStyles } from "./styles";
import { ThemeButton } from "../ThemeButton";

interface Props {
  onChange: (template: IMapTemplate, theme: string) => void;
  setDisplayedTheme: (theme: string) => void;
  currentTheme: string;
  displayedTheme: string;
  theaterData: ITheater;
  templates: IMapTemplate[];
}

const EventThemeSelector = ({
  onChange,
  setDisplayedTheme,
  displayedTheme,
  currentTheme,
  theaterData,
  templates,
}: Props): JSX.Element | null => {
  const [isInitialThemeLoaded, setThemeLoaded] = useState(false);
  const theaterTemplateData = getMapTemplateByType(theaterData.template);

  const getSelectedTemplate = (templateCode: string) =>
    templates.find((template) => template.code === templateCode);
  const theaterCode = theaterTemplateData
    ? theaterTemplateData.code
    : DEFAULT_FLOOR_PLAN;
  const selectedTemplate = getSelectedTemplate(theaterCode);
  const { t } = useI18n(["common", "eventForm"]);
  const styles = useStyles();

  // If user comes from custom floor tab to remo plan, dont show map image
  const anyRemoTemplateSelected = useMemo(
    () =>
      templates.some(
        (template) =>
          template.code === (selectedTemplate && selectedTemplate.code),
      ),
    [templates, selectedTemplate],
  );

  const getDefaultSelectTheme = () => {
    if (!currentTheme) {
      setThemeLoaded(true);

      return getDefaultTheme(selectedTemplate);
    }

    return currentTheme;
  };

  useEffect(() => {
    const template = selectedTemplate;
    const selectedTheme = getDefaultSelectTheme();

    if (template && !template.isPrivate) {
      onChange(template, selectedTheme);
      setDisplayedTheme(selectedTheme);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInitialThemeLoaded, selectedTemplate]);

  const isThemeSelected = useCallback(
    (themeToCheck: string) => {
      if (!anyRemoTemplateSelected) return false;

      if (themeToCheck === FLOOR_PLAN_THEMES.CLASSIC) {
        return isClassicTheme(displayedTheme);
      }

      return displayedTheme === themeToCheck;
    },
    [anyRemoTemplateSelected, displayedTheme],
  );

  const countTemplatesInTheme = useCallback(
    (themeToCheck: string) => {
      const filteredTemplates = templates.filter(
        (template) => template.floorPlanType === themeToCheck,
      );

      if (themeToCheck === FLOOR_PLAN_THEMES.CLASSIC) {
        return filteredTemplates.reduce(
          (runningCount, template) =>
            runningCount +
            (template.themes ? Object.keys(template.themes).length : 1),
          0,
        );
      }

      return filteredTemplates.length;
    },
    [templates],
  );

  return (
    <Grid container>
      <Grid item xs={12}>
        <Paper className={styles.container}>
          <Typography variant="h5" className="mar-bottom-20">
            {t("eventForm:floor.plan.theme")}
          </Typography>
          <ul className={styles.list}>
            {ACTIVE_THEMES.map((theme: string) => (
              <ThemeButton
                key={theme}
                theme={theme}
                count={countTemplatesInTheme(theme)}
                isSelected={isThemeSelected(theme)}
                onClick={setDisplayedTheme}
              />
            ))}
          </ul>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default EventThemeSelector;
