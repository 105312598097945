import {
  ContentData,
  EventEmbedContent,
  GetEmbedContentQuery,
  OnEmbedContentCreatedSubscription,
  OnEventEmbedContentDeletedSubscription,
} from "graphql/generated";

export const extractContentData = (
  content: EventEmbedContent,
): ContentData => ({
  __typename: "ContentData",
  id: content.id,
  url: content.url,
  createdAt: content.createdAt,
  mode: content.mode,
});

interface SubscriptionDataCreate {
  subscriptionData: {
    data: OnEmbedContentCreatedSubscription;
  };
}

interface SubscriptionDataDelete {
  subscriptionData: {
    data: OnEventEmbedContentDeletedSubscription;
  };
}

export const updateEmbedContentQuery = (
  prev: GetEmbedContentQuery,
  { subscriptionData }: SubscriptionDataCreate,
): GetEmbedContentQuery => {
  if (prev.embedContent.__typename === "GQLError") {
    return prev;
  }

  const newContent = subscriptionData.data.onEventEmbedContentChange;

  if (!newContent || newContent.__typename === "GQLError") {
    return prev;
  }

  return {
    embedContent: {
      ...prev.embedContent,
      content: [...prev.embedContent.content, extractContentData(newContent)],
    },
  };
};

export const deleteEventEmbedContentQuery = (
  prev: GetEmbedContentQuery,
  { subscriptionData }: SubscriptionDataDelete,
): GetEmbedContentQuery => {
  if (prev.embedContent.__typename === "GQLError") {
    return prev;
  }

  const deletedContent = subscriptionData.data.onEventEmbedContentDelete;

  if (!deletedContent || deletedContent.__typename === "GQLError") {
    return prev;
  }

  return {
    embedContent: {
      ...prev.embedContent,
      content: prev.embedContent.content.filter(
        (content) => content.id !== deletedContent.id,
      ),
    },
  };
};
