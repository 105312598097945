import { PageParam, EventArrayResponse } from "services/apiService/apis";
import { sendGetRequest } from "services/apiService";

export const getRequestURL = (
  pageParam: PageParam = {
    id: "",
    endTime: new Date().toISOString(),
    countEndTime: new Date().toISOString(),
  },
): string =>
  `/event/past-joined-events?endTime=${pageParam.endTime}&pageCursor=${
    pageParam.id
  }&countEndTime=${pageParam.countEndTime || ""}`;

export const getPastJoinedEvents = (
  pageParam: PageParam = {
    id: "",
    endTime: new Date().toISOString(),
    countEndTime: new Date().toISOString(),
  },
): Promise<EventArrayResponse> => sendGetRequest(getRequestURL(pageParam));
