import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export interface IEventLandingReducerState {
  showRegisterDialog: boolean;
}

export const eventLandingInitialState: IEventLandingReducerState = {
  showRegisterDialog: false,
};

export const eventLandingSlice = createSlice({
  name: "eventLanding",
  initialState: eventLandingInitialState,
  reducers: {
    setShowRegisterDialog: (state, action: PayloadAction<boolean>) => {
      state.showRegisterDialog = action.payload;
    },
  },
});

export const { setShowRegisterDialog } = eventLandingSlice.actions;

export default eventLandingSlice.reducer;
