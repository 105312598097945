import { useI18n } from "i18n";
import { Typography } from "@remo-co/ui-core/src/components/Typography";
import { Button } from "@remo-co/ui-core/src/components/Button";
import { Dispatch, SetStateAction, useCallback } from "react";
import { AvatarGroup } from "@remo-co/ui-core/src/components/AvatarGroup";
import { useSelector } from "react-redux";
import { selectRecommendedUsers } from "modules/networkingRecommendations/redux/selectors";
import { useStyles } from "./styles";
import { ConnectionsToolTipAvatar } from "../ConnectionsToolTipAvatar";

interface Props {
  setTooltipOpen: Dispatch<SetStateAction<boolean>>;
  handleClick: () => void;
}

export const ConnectionsToolTip = ({ setTooltipOpen, handleClick }: Props) => {
  const { t } = useI18n(["event"]);
  const styles = useStyles();

  const users = useSelector(selectRecommendedUsers);

  const handleCloseTooltip = useCallback(() => {
    setTooltipOpen(false);
  }, [setTooltipOpen]);

  const handleTooltipClick = useCallback(
    (
      e: React.MouseEvent<HTMLDivElement> | React.KeyboardEvent<HTMLDivElement>,
    ) => {
      e.stopPropagation();
    },
    [],
  );

  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLDivElement>) => {
      if (e.key === "Enter" || e.key === " ") {
        handleTooltipClick(e);
      }
    },
    [handleTooltipClick],
  );

  return (
    <div
      onClick={handleTooltipClick}
      onKeyDown={handleKeyDown}
      role="button"
      tabIndex={0}
      aria-hidden={false}
    >
      <Typography variant="body1" className={styles.tooltipText}>
        {t("event:not.sure.who.to.speak.with")}
      </Typography>
      <Typography variant="body1" className={styles.tooltipText}>
        {t("event:check.out.some.people.we.think.you.will.hit.it.off.with")}
      </Typography>
      {users.length > 0 && (
        <AvatarGroup max={3} className={styles.avatarGroup}>
          {users.map((user) => (
            <ConnectionsToolTipAvatar user={user} />
          ))}
        </AvatarGroup>
      )}
      <div className={styles.tooltipButtons}>
        <Button
          variant="primary"
          fullWidth
          color="white"
          className={styles.tooltipButton}
          onClick={() => {
            handleCloseTooltip();
            handleClick();
          }}
        >
          {t("event:view.connections")}
        </Button>
        <Button
          variant="text"
          fullWidth
          color="white"
          className={styles.tooltipButton}
          onClick={handleCloseTooltip}
        >
          {t("event:maybe.later")}
        </Button>
      </div>
    </div>
  );
};
