import { useMemo, useCallback, useEffect } from "react";
import {
  useDevices as useDailyDevices,
  useDaily,
  useLocalSessionId,
  useParticipantProperty,
} from "@daily-co/daily-react";
import { useAppDispatch } from "store/hooks";
import { SerializedDevice } from "modules/deviceInfo";
import {
  setMicrophoneActive as setUserSessionMicrophoneActive,
  setCameraActive as setUserSessionCameraActive,
} from "modules/session/redux/slice";
import { selectIsInBroadcast } from "modules/broadcast/redux/selectors";
import {
  selectMicrophoneActive,
  selectCameraActive,
} from "modules/session/redux/selectors";
import { useSelector } from "react-redux";
import {
  DAILY_PLAYABLE_TRACK_STATES,
  LOCAL_CAM_KEY,
  LOCAL_MIC_KEY,
  LOCAL_SPEAKER_KEY,
} from "modules/audioVideo/constants";
import { Errors, trackError } from "modules/monitoring";
import { selectRole, selectUser } from "modules/auth/redux/selectors";
import logger from "logging/logger";
import { toSerializedDevice } from "./transformer";
import { useActivePresenterActions } from "../usePresenters";

export const useDevices = () => {
  const devices = useDailyDevices();
  const daily = useDaily();

  const dispatch = useAppDispatch();
  const { addActivePresenter } = useActivePresenterActions();
  const isInBroadcast = useSelector(selectIsInBroadcast);
  const user = useSelector(selectUser);
  const role = useSelector(selectRole);
  const localSessionid = useLocalSessionId();
  const didUserSetMicrophoneActive = useSelector(selectMicrophoneActive);
  const didUserSetCameraActive = useSelector(selectCameraActive);
  const [
    hasPublishingPermissions,
    microphoneState,
    cameraState,
    screenShareState,
  ] = useParticipantProperty(localSessionid, [
    "permissions.canSend",
    "tracks.audio.state",
    "tracks.video.state",
    "tracks.screenVideo.state",
  ]);

  const microphones = useMemo(
    () => devices.microphones.map(toSerializedDevice),
    [devices.microphones],
  );
  const cameras = useMemo(
    () => devices.cameras.map(toSerializedDevice),
    [devices.cameras],
  );
  const speakers = useMemo(
    () => devices.speakers.map(toSerializedDevice),
    [devices.speakers],
  );

  const isCameraErrorType = useMemo(() => {
    if (devices?.cameraError) {
      logger.error(
        `[Camera Microphone Daily Error] ${devices?.cameraError?.msg} for user ${user?.id} `,
      );
      trackError(devices.cameraError?.type, {
        label: Errors.MIC_CAM_ERROR,
        userId: user?.id,
        email: user?.email,
        role,
        ...devices?.cameraError,
      });
      return devices.cameraError?.type;
    }
    return null;
  }, [devices?.cameraError, role, user]);

  const activeDevices = useMemo(() => {
    const videoInput = devices.currentCam;
    const audioOutput = devices.currentSpeaker;
    const audioInput = devices.currentMic;

    return {
      videoInput: videoInput ? toSerializedDevice(videoInput) : null,
      audioInput: audioInput ? toSerializedDevice(audioInput) : null,
      audioOutput: audioOutput ? toSerializedDevice(audioOutput) : null,
    };
  }, [devices.currentCam, devices.currentSpeaker, devices.currentMic]);

  const setCamera = useCallback(
    (serializedDevice: SerializedDevice) => {
      devices.setCamera(serializedDevice.deviceId);
      localStorage.setItem(LOCAL_CAM_KEY, serializedDevice.deviceId);
    },
    [devices],
  );

  const setMicrophone = useCallback(
    (serializedDevice: SerializedDevice) => {
      devices.setMicrophone(serializedDevice.deviceId);
      localStorage.setItem(LOCAL_MIC_KEY, serializedDevice.deviceId);
    },
    [devices],
  );

  const setSpeaker = useCallback(
    (serializedDevice: SerializedDevice) => {
      devices.setSpeaker(serializedDevice.deviceId);
      localStorage.setItem(LOCAL_SPEAKER_KEY, serializedDevice.deviceId);
    },
    [devices],
  );

  const setMicrophoneActive = useCallback(
    async (active: boolean) => {
      daily?.setLocalAudio(active);

      dispatch(setUserSessionMicrophoneActive(active));

      if (!isInBroadcast) {
        return;
      }

      if (active) {
        await addActivePresenter();
      }
    },
    [daily, isInBroadcast, dispatch, addActivePresenter],
  );

  const setCameraActive = useCallback(
    async (active: boolean) => {
      daily?.setLocalVideo(active);

      dispatch(setUserSessionCameraActive(active));

      if (!isInBroadcast) {
        return;
      }

      if (active) {
        await addActivePresenter();
      }
    },
    [daily, isInBroadcast, dispatch, addActivePresenter],
  );

  const refreshDevices = useCallback(() => devices.refreshDevices(), [devices]);

  const microphoneActive =
    DAILY_PLAYABLE_TRACK_STATES.includes(microphoneState);
  const cameraActive = DAILY_PLAYABLE_TRACK_STATES.includes(cameraState);
  const screenShareActive =
    DAILY_PLAYABLE_TRACK_STATES.includes(screenShareState);

  useEffect(() => {
    if (!isInBroadcast && hasPublishingPermissions) {
      if (
        didUserSetMicrophoneActive !== null &&
        microphoneActive !== didUserSetMicrophoneActive
      ) {
        setMicrophoneActive(didUserSetMicrophoneActive);
      }
      if (
        didUserSetCameraActive !== null &&
        cameraActive !== didUserSetCameraActive
      ) {
        setCameraActive(didUserSetCameraActive);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInBroadcast, hasPublishingPermissions]);

  return {
    microphoneActive,
    cameraActive,
    screenShareActive,
    microphones,
    cameras,
    speakers,
    activeDevices,
    setCamera,
    setMicrophone,
    setSpeaker,
    setMicrophoneActive,
    setCameraActive,
    refreshDevices,
    hasPublishingPermissions,
    isCameraErrorType,
  };
};
