import Avatar from "modules/userAvatar/Avatar";
import { makeSelectUserById } from "modules/event/usersInEvent/selectors";
import { NetworkingUserDetails } from "modules/networkingRecommendations/redux/slice";
import { useSelector } from "react-redux";
import { useMemo } from "react";

interface Props {
  user: Record<string, NetworkingUserDetails>;
}

export const ConnectionsToolTipAvatar = ({ user }: Props) => {
  const userId = useMemo(() => Object.keys(user)[0], [user]);
  const userDetails = useSelector(makeSelectUserById(userId));
  return <Avatar key={userId} size="md" user={userDetails} />;
};
