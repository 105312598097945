import { useMeetingState } from "@daily-co/daily-react";
import { selectConversationId } from "modules/audioVideo/redux/selectors";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useUserData } from "modules/audioVideo/hooks/useUserData";

export const ManageJoinedMeeting = (): null => {
  const { setConversationId } = useUserData();
  const meetingState = useMeetingState();
  const conversationId = useSelector(selectConversationId);

  useEffect(() => {
    if (conversationId && meetingState === "joined-meeting") {
      setConversationId({ conversationId });
    }
  }, [conversationId, meetingState, setConversationId]);

  return null;
};
