export const HELP_CHAT_ELEMENT_ID = "remo_hc_icon";
export const FRESH_CHAT_ELEMENT_ID = "freshchat-js-sdk";
export const FRESH_CHAT_SETTINGS = {
  open: false,
  config: {
    headerProperty: {
      hideChatButton: true,
      direction: "ltr",
    },
  },
};
