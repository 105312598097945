import { sendPostRequest } from "services/apiService";

export type InviteToTablePayload = {
  eventId: string;
  spaceId: string;
  targetTableId: string;
  targetUserId: string;
  notifyDialogType?: string;
};

type FailureResponse = {
  status: false;
  reason?: string;
  message?: string;
};

type InviteToTableResponse =
  | FailureResponse
  | {
      status: true;
    };

type AcceptInviteToTableResponse =
  | FailureResponse
  | {
      status: true;
      tableId: string;
    };

export const inviteToTableRequest = async (
  data: InviteToTablePayload,
): Promise<InviteToTableResponse> => {
  const { eventId, ...payload } = data;
  const response = await sendPostRequest<
    Omit<InviteToTablePayload, "eventId">,
    InviteToTableResponse
  >(`/event/${eventId}/table/invite`, payload);

  if (!response.status) {
    throw new Error(response?.reason ?? response?.message);
  }

  return response;
};

interface AcceptInvitePayload {
  eventId: string;
  inviteId: string;
  currentFloorId: string;
  currentTableId: string;
}

export const acceptInviteToTableRequest = async (data: AcceptInvitePayload) => {
  const { eventId, ...payload } = data;
  const response = await sendPostRequest<
    Omit<AcceptInvitePayload, "eventId">,
    AcceptInviteToTableResponse
  >(`/event/${eventId}/table/invite/accept`, payload);

  if (!response.status) {
    throw new Error(response?.reason ?? response?.message);
  }

  return response;
};
