import { createExpandedSlots } from "./createExpandedSlots";
import { createNarrowedSlots } from "./createNarrowedSlots";
import { populateSlots } from "./populateSlots";
import { AudienceViewAction } from "./actions";
import { Constants } from "./constants";

export type State = {
  isExpanded: boolean;
  isContainerMounted: boolean;
  slots: (string | null)[];
};

export const initialState: State = {
  isContainerMounted: false,
  isExpanded: true,
  slots: [],
};

const reducer = (state = initialState, action: AudienceViewAction) => {
  switch (action.type) {
    case Constants.SET_IS_CONTAINER_MOUNTED:
      return {
        ...state,
        isContainerMounted: action.isContainerMounted,
      };
    case Constants.TOGGLE_EXPAND:
      return {
        ...state,
        isExpanded: action.isExpanded,
      };
    case Constants.SET_ITEMS_PER_ROW:
      if (state.slots.length === action.itemsPerRow * 2) {
        return state;
      }

      if (action.itemsPerRow * 2 < state.slots.length) {
        return {
          ...state,
          slots: populateSlots(
            createNarrowedSlots(state.slots, action.itemsPerRow),
            action.liveUserIds,
          ),
        };
      }

      return {
        ...state,
        slots: populateSlots(
          createExpandedSlots(state.slots, action.itemsPerRow),
          action.liveUserIds,
        ),
      };
    case Constants.UPDATE_SLOTS:
      return {
        ...state,
        slots: populateSlots(state.slots, action.liveUserIds),
      };
    case Constants.RESET_AUDIENCE_VIEW_STATE:
      return initialState;
    default:
      return state;
  }
};

export default reducer;
