/** @deprecated Use https://react-query.tanstack.com/guides/query-cancellation#manual-cancellation instead */
export const makeCancellable = <T>(promise: Promise<T>) => {
  let hasCanceled = false;

  const wrappedPromise = new Promise<T>((resolve, reject) => {
    // eslint-disable-next-line promise/catch-or-return, promise/prefer-await-to-then
    promise.then(
      // eslint-disable-next-line prefer-promise-reject-errors
      (val) => (hasCanceled ? reject({ isCanceled: true }) : resolve(val)),
      // eslint-disable-next-line prefer-promise-reject-errors
      (error) => (hasCanceled ? reject({ isCanceled: true }) : reject(error)),
    );
  });

  return {
    promise: wrappedPromise,
    cancel() {
      hasCanceled = true;
    },
  };
};
