import { Theme } from "@remo-co/ui-core/src/types";
import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";

export const useStyles = makeStyles<Theme>((theme) => ({
  box: {
    border: "1px solid",
    borderColor: theme.palette.grey[300],
    borderRadius: theme.shape.borderRadius * 2.5,
    padding: "20px 30px",
    marginBottom: "1.25rem",
  },
}));
