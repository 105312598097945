import { useDaily } from "@daily-co/daily-react";
import { useCallback } from "react";

export const useFindParticipant = () => {
  const daily = useDaily();

  const findParticipant = useCallback(
    (userId: string) => {
      if (!daily) {
        return "";
      }

      const participants = Object.values(daily?.participants());

      const participant = participants.find(
        (participant) => participant.user_id === userId,
      );

      return participant?.session_id ?? "";
    },
    [daily],
  );

  return { findParticipant };
};
