import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ICompanyCoupon } from "../types";

export const companyCouponReducerInitialState: ICompanyCoupon = {
  discount_percentage: 0,
  id: "",
  name: "",
};

export const companyCoupon = createSlice({
  name: "companyUsageAlert",
  initialState: companyCouponReducerInitialState,
  reducers: {
    updateCoupon: (state, action: PayloadAction<ICompanyCoupon>) =>
      action.payload,
    clearCoupon: () => companyCouponReducerInitialState,
  },
});

export const { updateCoupon, clearCoupon } = companyCoupon.actions;

export default companyCoupon.reducer;
