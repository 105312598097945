import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";

export const useStyles = makeStyles(() => ({
  container: {
    marginBottom: "2.5rem !important",
    position: "relative",
  },
  pagination: {
    display: "flex",
    maxWidth: "40%",
    marginLeft: "25%",
    justifyContent: "space-between",
    marginTop: "-1.75rem",
  },
  centeredPagination: {
    display: "flex",
    maxWidth: "40%",
    marginLeft: "25%",
    justifyContent: "space-between",
    marginTop: "-1.75rem",
  },
}));
