import { gql } from "@apollo/client";

export const GET_EVENT_CONTENT = gql`
  query GetEventContent($event: ObjectID!) {
    eventContent(event: $event) {
      ... on EventContentList {
        __typename
        event
        content {
          company
          event
          floor
          slot
          ctaLink
          ctaText
          floorImage
          hideOpenInNewTabButton
          id
          media
          mediaType
          name
          type
        }
      }
      ... on GQLError {
        __typename
        message
      }
    }
  }
`;

export const GET_EVENT_FLOOR_CONTENT = gql`
  query GetEventFloorContent($event: ObjectID!, $floor: ObjectID!) {
    eventFloorContent(event: $event, floor: $floor) {
      ... on EventFloorContentList {
        __typename
        event
        floor
        content {
          company
          event
          floor
          slot
          ctaLink
          ctaText
          floorImage
          hideOpenInNewTabButton
          id
          media
          mediaType
          name
          type
        }
      }
      ... on GQLError {
        __typename
        message
      }
    }
  }
`;
