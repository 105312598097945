import { useEffect } from "react";
import { useQuery } from "@apollo/client";
import {
  GetEventContentQuery,
  GetEventContentQueryVariables,
  OnEventContentChangedSubscription,
  OnEventContentChangedSubscriptionVariables,
  OnEventContentDeletedSubscription,
  OnEventContentDeletedSubscriptionVariables,
} from "graphql/generated";
import { Errors, trackError } from "modules/monitoring";
import { GET_EVENT_CONTENT } from "../../graphql/queries";
import {
  ON_EVENT_CONTENT_CHANGED,
  ON_EVENT_CONTENT_DELETED,
} from "../../graphql/subscriptions";
import { updateEventContentList, removeDeletedEventContent } from "../../utils";

interface SubscriptionParams {
  eventId?: string;
}

export const useEventContentSubscription = ({
  eventId,
}: SubscriptionParams) => {
  const { subscribeToMore, refetch, loading, data, error } = useQuery<
    GetEventContentQuery,
    GetEventContentQueryVariables
  >(GET_EVENT_CONTENT, {
    skip: !eventId,
  });

  if (error) {
    trackError(error, { label: Errors.EVENT_CONTENT_QUERY });
  }

  useEffect(() => {
    if (!eventId) {
      return undefined;
    }

    refetch({ event: eventId });

    const unsubscribeFromChanges = subscribeToMore<
      OnEventContentChangedSubscription,
      OnEventContentChangedSubscriptionVariables
    >({
      document: ON_EVENT_CONTENT_CHANGED,
      variables: { event: eventId },
      onError: (error) => {
        trackError(error, { label: Errors.EVENT_CONTENT_CHANGED_SUBSCRIPTION });
      },
      updateQuery: updateEventContentList,
    });

    const unsubscribeFromDeletions = subscribeToMore<
      OnEventContentDeletedSubscription,
      OnEventContentDeletedSubscriptionVariables
    >({
      document: ON_EVENT_CONTENT_DELETED,
      variables: { event: eventId },
      onError: (error) => {
        trackError(error, {
          label: Errors.EVENT_CONTENT_DELETED_SUBSCRIPTION,
        });
      },
      updateQuery: removeDeletedEventContent,
    });

    return () => {
      unsubscribeFromChanges();
      unsubscribeFromDeletions();
    };
  }, [eventId, subscribeToMore, refetch]);

  return {
    data,
    loading,
    error,
  };
};
