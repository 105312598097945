import { Theme } from "@remo-co/ui-core/src/types";
import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";

export const useStyles = makeStyles<Theme>((theme) => ({
  container: {
    padding: "10px 10px 5px",
    borderRadius: "15px",
    display: "flex",
    alignItems: "flex-start",
    backgroundColor: theme.palette.white,
    marginBottom: "10px",
    boxShadow: "2px 4px 20px rgba(0, 0, 0, 0.1)",
    pointerEvents: "all",
  },
  divider: {
    height: "46px",
    margin: "0 10px",
    alignSelf: "flex-start",
  },
}));
