import { useCallback, useEffect, useState } from "react";
import { useAppDispatch } from "store/hooks";
import { getVar } from "config";
import { WhiteboardDataStatus } from "modules/whiteboard/constants";
import { IMiroBoardPicker, IMiroResult } from "types/miroBoardsPicker";
import {
  updateRoomWhiteboard,
  updateRoomWhiteboardState,
} from "modules/roomWhiteboard/redux/slice";
import { updateBroadcastWhiteboard } from "modules/broadcastWhiteboard/redux/slice";
import logger from "logging/logger";
import { useSelector } from "react-redux";
import { selectRoomWhiteboard } from "modules/roomWhiteboard/redux/selectors";
import { off, onValue } from "firebase/database";
import miroBoardFirebase from "../miroBoard.firebase";
import { MAX_CREATE_ATTEMPTS } from "../constants";
import { createMiroWhiteboard } from "../miroBoard.api";

// loaded from <script>
declare const miroBoardsPicker: IMiroBoardPicker;

const useMiroBoard = (usageId: string, isRoomWhiteboard: boolean) => {
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const meta = { type: isRoomWhiteboard ? "room" : "presentation" };
  const { startedAt, startedBy } = useSelector(selectRoomWhiteboard);

  const createWhiteboard = (name: string) =>
    createMiroWhiteboard(
      {
        usageId,
        name,
        meta,
      },
      MAX_CREATE_ATTEMPTS,
    );

  const getMiroBoardAccessLinkToken = async (name: string) => {
    const whiteboard = await miroBoardFirebase.getMiroBoard(usageId);

    if (whiteboard && whiteboard.boardCode) {
      return { code: whiteboard.boardCode, id: whiteboard.miroBoardId };
    }

    // Start get board userId
    await miroBoardFirebase.updateMiroBoardStatus(
      usageId,
      WhiteboardDataStatus.IN_PROGRESS,
    );
    try {
      const resp = await createMiroWhiteboard(
        { usageId, name, meta },
        MAX_CREATE_ATTEMPTS,
      );

      if (resp.accessLinkToken) {
        await miroBoardFirebase.updateMiroBoardCode(
          usageId,
          resp.accessLinkToken,
          resp.miroBoardId,
          meta,
        );
      }

      return { code: resp.accessLinkToken, id: resp.miroBoardId };
    } catch (error) {
      await miroBoardFirebase.updateMiroBoardStatus(
        usageId,
        WhiteboardDataStatus.ERROR,
      );

      const errorMessage = error instanceof Error ? error.message : "";
      logger.error(`[createWhiteboard] Error: ${errorMessage}`);

      return null;
    }
  };

  const subscribeToMiroBoardChanged = () => {
    setIsLoading(true);
    const ref = miroBoardFirebase.getMiroBoardDocRef(usageId);

    off(ref);
    onValue(ref, (snap) => {
      const snapshotValue = snap.val();

      if (snapshotValue) {
        dispatch(
          isRoomWhiteboard
            ? updateRoomWhiteboard(snapshotValue)
            : updateBroadcastWhiteboard(snapshotValue),
        );
        dispatch(
          updateRoomWhiteboardState({
            startedAt,
            startedBy,
            boardUrlStatus:
              snapshotValue.status || WhiteboardDataStatus.NOT_STARTED,
          }),
        );
      }
      setIsLoading(false);
    });
  };

  const unsubscribeFromMiroBoardChanged = () => {
    off(miroBoardFirebase.getMiroBoardDocRef(usageId));
  };

  useEffect(() => {
    if (usageId) {
      subscribeToMiroBoardChanged();
    }

    return () => {
      if (usageId) {
        unsubscribeFromMiroBoardChanged();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [usageId]);

  // @usageId - roomId or theaterId, depends on usage
  const selectBoardFromPicker = () =>
    new Promise<{ code: string; id: string }>((resolve, reject) => {
      miroBoardsPicker.open({
        clientId: getVar("REACT_APP_MIRO_CLIENT_ID"),
        action: "access-link",
        success: (result: IMiroResult) => {
          if (result?.accessLink) {
            const code = new URL(result.accessLink).searchParams.get(
              "boardAccessToken",
            );

            if (!code) {
              logger.error(
                "[miro][selectBoardFromPicker] missing board token",
                {
                  result,
                },
              );
              reject(new Error("Missing board token"));
            } else {
              miroBoardFirebase
                .updateMiroBoardCode(usageId, code, result?.id)
                .then(() => {
                  // eslint-disable-next-line promise/always-return
                  resolve({ code, id: result?.id });
                })
                .catch(() => {
                  reject(new Error("Can't update miro board code in firebase"));
                });
            }
          } else {
            logger.error("[miro][selectBoardFromPicker] unexpected response", {
              result,
            });
            reject(new Error("Missing board information"));
          }
        },
        error: (error: Error) => {
          logger.error("[miro][selectBoardFromPicker] error", { error });
          reject(error);
        },
      });
    });

  const setMiroBoardDetails = useCallback(async () => {
    const { boardCode = undefined, miroBoardId = undefined } =
      (await miroBoardFirebase.getMiroBoard(usageId)) || {};

    dispatch(updateRoomWhiteboard({ boardCode, miroBoardId }));
  }, [dispatch, usageId]);

  return {
    createWhiteboard,
    getMiroBoardAccessLinkToken,
    selectBoardFromPicker,
    setMiroBoardDetails,
    isLoading,
  };
};

export default useMiroBoard;
