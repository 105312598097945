import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export interface DailyReduxState {
  activeSpeakerId: string | null;
}

export const initialState: DailyReduxState = {
  activeSpeakerId: null,
};

const dailySlice = createSlice({
  name: "daily",
  initialState,
  reducers: {
    setActiveSpeaker: (
      state: DailyReduxState,
      action: PayloadAction<string | null>,
    ) => {
      state.activeSpeakerId = action.payload;
    },
  },
});

export const { setActiveSpeaker } = dailySlice.actions;

export default dailySlice.reducer;
