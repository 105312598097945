import { gql } from "@apollo/client";

export const GET_EMBED_CONTENT = gql`
  query GetEmbedContent($eventId: ID!, $subscribedAt: String) {
    embedContent(eventId: $eventId, subscribedAt: $subscribedAt) {
      ... on EventEmbedContentList {
        __typename
        eventId
        content {
          createdAt
          id
          url
          mode
        }
      }
      ... on GQLError {
        __typename
        message
      }
    }
  }
`;
