import { gql } from "@apollo/client";

export const ON_EMBED_CONTENT_CREATED = gql`
  subscription OnEmbedContentCreated($eventId: ID!) {
    onEventEmbedContentChange(eventId: $eventId) {
      ... on EventEmbedContent {
        __typename
        id
        createdAt
        eventId
        url
        mode
      }
      ... on GQLError {
        __typename
        message
      }
    }
  }
`;

export const ON_EVENT_EMBED_CONTENT_DELETED = gql`
  subscription OnEventEmbedContentDeleted($eventId: ID!) {
    onEventEmbedContentDelete(eventId: $eventId) {
      ... on EventEmbedContent {
        __typename
        id
        createdAt
        eventId
        url
        mode
      }
      ... on GQLError {
        __typename
        message
      }
    }
  }
`;
