import { ReactNode } from "react";
import { Typography } from "@remo-co/ui-core/src/components/Typography";
import { Button } from "@remo-co/ui-core/src/components/Button";
import { Container } from "@remo-co/ui-core/src/components/Container";
import { RecordingObject } from "@remo-co/types";
import { useI18n } from "i18n";
import { useStyles } from "./styles";
import { RecordingItem } from "../RecordingItem";

const VisualFeedback = ({
  children,
  refetch,
}: {
  refetch?(): void;
  children?: ReactNode;
}) => {
  const { t } = useI18n();

  return (
    <Container
      flex
      column
      justifyContent="center"
      alignItems="center"
      size="lg"
      border
      rounded="lg"
    >
      <Typography color="textSecondary">{children}</Typography>
      {refetch && (
        <Container top="xs" fullWidth={false}>
          <Button variant="ghost" color="dark" size="sm" onClick={refetch}>
            {t("please.try.again")}
          </Button>
        </Container>
      )}
    </Container>
  );
};

interface Props {
  eventCode: string;
  recordings?: RecordingObject[];
  status: "error" | "pending" | "success";
  handleDownload(recording: RecordingObject): void;
  handleDelete(recording: RecordingObject): void;
  refetch(): void;
}

const RecordingsContent = ({
  eventCode,
  recordings,
  status,
  handleDownload,
  handleDelete,
  refetch,
}: Props): JSX.Element | null => {
  const styles = useStyles();
  const { t } = useI18n(["common", "eventForm", "server"]);

  if (recordings) {
    if (recordings.length === 0) {
      return (
        <VisualFeedback>{t("eventForm:recording.unavailable")}</VisualFeedback>
      );
    }

    return (
      <Container
        data-testid="recordingList"
        className={styles.broadcastRecordingsList}
        bottom="sm"
      >
        {recordings.map((recording) => (
          <RecordingItem
            eventCode={eventCode}
            key={recording.id}
            recording={recording}
            onDownload={handleDownload}
            onDelete={handleDelete}
          />
        ))}
      </Container>
    );
  }

  if (status === "pending") {
    return <VisualFeedback>{t("loading")}</VisualFeedback>;
  }

  if (status === "error") {
    return (
      <VisualFeedback refetch={refetch}>{t("something.wrong")}</VisualFeedback>
    );
  }

  return null;
};

export default RecordingsContent;
