import { Theme } from "@remo-co/ui-core/src/types";
import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";

const useStyles = makeStyles<Theme>(() => ({
  fileInput: {
    top: "48px",
  },
}));

export default useStyles;
