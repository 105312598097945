import React from "react";
import * as moment from "moment-timezone";
import { Box } from "@remo-co/ui-core/src/components/Box";
import { Button } from "@remo-co/ui-core/src/components/Button";
import { CircularProgress } from "@remo-co/ui-core/src/components/CircularProgress";
import { Tooltip } from "@remo-co/ui-core/src/components/Tooltip";
import { Typography } from "@remo-co/ui-core/src/components/Typography";
import useNotificationActions from "modules/notification/hooks/useNotificationActions";
import exportAttendeeHelper from "modules/eventMemberList/exportAttendeeHelper";
import { useI18n } from "i18n";
import { useCompanyPlanSettings } from "modules/companyPlanSettings";
import eventMemberApi from "modules/eventMemberList/eventMember.api";
import { IExportAttendeesColumnsMeta } from "../types";
import TextContent from "../components/TextContent";

interface IExportAttendeesProps {
  eventId?: string;
  eventName?: string;
}

const DOUBLE_QUOTE_SANITATION_REGEX = /(?!^)"(?=.*["])/g;

const ExportAttendees = ({
  eventName,
  eventId,
}: IExportAttendeesProps): JSX.Element => {
  const { t } = useI18n(["common", "eventForm", "server", "manageEvent"]);
  const [inProgress, setInProgress] = React.useState(false);
  const { myCompanyPlan, isFreePlan } = useCompanyPlanSettings();
  const { addInfoNotification, addErrorNotification } =
    useNotificationActions();

  // eslint-disable-next-line consistent-return
  const handleExport = async () => {
    setInProgress(true);
    addInfoNotification({
      message: t("eventForm:download.event.text"),
      position: "tc",
      autoDismiss: 5,
    });

    if (!eventId) {
      return addErrorNotification({
        message: t("eventForm:download.event.fail"),
        position: "tc",
        autoDismiss: 5,
      });
    }

    const { isSuccess, attendees, message } =
      await eventMemberApi.getEventAttendeeList(eventId, true);

    if (!isSuccess) {
      addErrorNotification({
        message: t(`${message || "something.wrong"}`),
        position: "tc",
        autoDismiss: 5,
      });
      setInProgress(false);

      // eslint-disable-next-line consistent-return
      return;
    }
    const result = exportAttendeeHelper.convertEventMemberData(
      { name: eventName },
      attendees ?? [],
    );
    const timeZone = moment.tz.guess();
    const columns: IExportAttendeesColumnsMeta[] = [
      {
        title: "ID",
        field: "userId",
        export: true,
      },
      {
        title: t("first.name"),
        field: "name",
        export: true,
        renderExport: ({ key }) =>
          `"${
            JSON.parse(key.replace(DOUBLE_QUOTE_SANITATION_REGEX, "'")).split(
              " ",
            )[0]
          }"`,
      },
      {
        title: t("last.name"),
        field: "name",
        export: true,
        renderExport: ({ key }) =>
          `"${JSON.parse(key.replace(DOUBLE_QUOTE_SANITATION_REGEX, "'"))
            .split(" ")
            .slice(1)
            .join(" ")}"`,
      },
      {
        title: t("eventForm:column.email.title"),
        field: "email",
        export: true,
      },
      {
        title: t("eventForm:column.invite.title"),
        field: "invitedText",
        export: true,
        renderExport: ({ key }) => t(key),
      },
      {
        title: t("eventForm:column.registered.title"),
        field: "registeredText",
        export: true,
      },
      {
        title: t("eventForm:column.attended.title"),
        field: "attendedText",
        export: true,
      },
      {
        title: t("eventForm:column.event.title"),
        field: "eventName",
        hidden: true,
        export: true,
      },
      {
        title: t("eventForm:registered.time.zone", {
          key: timeZone,
        }),
        field: "registeredAt",
        hidden: true,
        export: true,
      },
      {
        title: t("eventForm:login.time.zone", {
          key: timeZone,
        }),
        field: "enteredEventAt",
        hidden: true,
        export: true,
      },
      {
        title: t("eventForm:logout.time.zone", {
          key: timeZone,
        }),
        field: "leftEventAt",
        hidden: true,
        export: true,
      },
      {
        title: t("eventForm:column.time.spent.title"),
        field: "timeSpent",
        hidden: true,
        export: true,
      },
    ];

    exportAttendeeHelper.downloadCSV("attendee-list.csv", columns, result);
    setInProgress(false);
  };

  const getTooltipContent = () => {
    if (isFreePlan(myCompanyPlan)) {
      return t("manageEvent:feature.disabled");
    }

    return t("eventForm:export.guest.list.info");
  };

  return (
    <Box display="flex" justifyContent="space-between">
      <Box mr={1} maxWidth="60%">
        <Typography variant="h4">{t("eventForm:export.guest.list")}</Typography>
        <TextContent>
          <>
            <Typography component="span" color="inherit">
              {t("eventForm:export.guest.list.info")}
            </Typography>
            <br />
            <br />
            <Typography component="span" color="secondary">
              {t("eventForm:time.spent.info")}
            </Typography>
          </>
        </TextContent>
      </Box>
      <Tooltip title={getTooltipContent()} placement="top">
        <span>
          <Button
            id="edit-event-modal_primary-cta-export-csv-file"
            disabled={inProgress || !eventId || isFreePlan(myCompanyPlan)}
            onClick={handleExport}
            variant="secondary"
            color="blue"
            data-testid="export-list"
          >
            {inProgress ? <CircularProgress size={24} /> : t("export.list")}
          </Button>
        </span>
      </Tooltip>
    </Box>
  );
};

export default ExportAttendees;
