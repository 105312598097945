import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Menu } from "@remo-co/ui-core/src/components/Menu";
import { selectIsEventManager } from "modules/event/selectors";
import { CONTEXT_MENU_TYPES } from "modules/event";
import { selectUser } from "modules/auth/redux/selectors";
import { makeSelectUserById } from "modules/event/usersInEvent/selectors";
import { OtherUserContextMenu, RoomContextMenu } from "..";
import { useStyles } from "./styles";

interface Props {
  type: string;
  mouse: { x: number; y: number };
  userId?: string;
  roomId: string;
}

const ContextMenu = (props: Props): JSX.Element | null => {
  const { type, mouse, userId, roomId } = props;
  const isEventManager = useSelector(selectIsEventManager);
  const [isOpen, setIsOpen] = useState(true);
  const fromUser = useSelector(selectUser);
  const userIdOrFallback = userId || "";
  const userInSpace = useSelector(makeSelectUserById(userIdOrFallback));
  const classes = useStyles();

  useEffect(() => {
    setIsOpen(true);
  }, [props]);

  if (!isOpen) {
    return null;
  }

  if (!isEventManager && type === CONTEXT_MENU_TYPES.USER) {
    return null;
  }

  const onClose = () => {
    setIsOpen(false);
  };

  const clickOnOtherUser =
    roomId && fromUser && userInSpace && fromUser.id !== userInSpace.id;

  if (type === CONTEXT_MENU_TYPES.USER && !clickOnOtherUser) return null;

  return (
    <Menu
      anchorPosition={{ left: mouse.x, top: mouse.y }}
      anchorReference="anchorPosition"
      id="re-contextMenu"
      open={isOpen}
      disableAutoFocusItem
      onClose={onClose}
      className={classes.contextMenu}
    >
      {type === CONTEXT_MENU_TYPES.USER && (
        <OtherUserContextMenu
          userId={userId}
          roomId={roomId}
          onClose={onClose}
        />
      )}
      {type === CONTEXT_MENU_TYPES.ROOM && (
        <RoomContextMenu id={roomId} onClose={onClose} />
      )}
    </Menu>
  );
};

export default ContextMenu;
