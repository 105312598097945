import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";

export const useStyles = makeStyles(() => ({
  contextMenuItem: {
    padding: "6px 10px !important",
    "& h6": {
      fontSize: "1rem",
      fontWeight: 500,
      color: "#000",
    },
  },
}));
