import { getLatestStableBrowsers } from "caniuse-api";
import semverDecrement from "semver-decrement";
import semver from "semver";
import { BrowserSupport } from "modules/systemCheck/types";
import { BROWSER_NAMES, SUPPORT_STATUS } from "../../../constants";
import {
  PARTIALLY_SUPPORTED_BROWSERS,
  SUPPORTED_BEFORE_LATEST,
  SUPPORTED_BROWSERS_NAMES_MAPPING,
} from "./constants";

const extractVersionFromRange = (version: string): string => {
  if (!version.includes("-")) {
    return version;
  }

  const [firstVersionInRange] = version.split("-");

  return firstVersionInRange;
};

const decrementSemanticVersionBy = (version: string, times: number) =>
  new Array(times)
    .fill(null)
    .reduce(
      (decrementedVersion) => semverDecrement(decrementedVersion, "major"),
      version,
    );

export const getSupportedVersions = (): { [key in BROWSER_NAMES]?: string } =>
  getLatestStableBrowsers().reduce((accumulator, browserInfo) => {
    const [browserName, browserVersion] = browserInfo.split(" ");

    const supportedBrowserName = SUPPORTED_BROWSERS_NAMES_MAPPING[browserName];
    const supportedMajorVersionsBeforeCurrent =
      SUPPORTED_BEFORE_LATEST[supportedBrowserName];
    const currentSemanticVersion = semver.coerce(
      extractVersionFromRange(browserVersion),
    );

    if (
      !supportedBrowserName ||
      !supportedMajorVersionsBeforeCurrent ||
      !currentSemanticVersion
    ) {
      return { ...accumulator };
    }

    return {
      ...accumulator,
      [supportedBrowserName]: decrementSemanticVersionBy(
        currentSemanticVersion.version,
        supportedMajorVersionsBeforeCurrent,
      ),
    };
  }, {});

const supportedVersions = getSupportedVersions();

const unsupported: BrowserSupport = {
  status: SUPPORT_STATUS.UNSUPPORTED,
};

const supported: BrowserSupport = {
  status: SUPPORT_STATUS.SUPPORTED,
};

const partiallySupported: BrowserSupport = {
  status: SUPPORT_STATUS.PARTIALLY_SUPPORTED,
};

export const checkBrowserSupport = (
  browserName: BROWSER_NAMES,
  browserVersion: string,
): BrowserSupport => {
  const supportedVersion = supportedVersions[browserName];

  if (!supportedVersion) {
    return unsupported;
  }
  const parsedVersion = semver.coerce(browserVersion)?.version;
  if (parsedVersion && semver.gte(parsedVersion, supportedVersion)) {
    return PARTIALLY_SUPPORTED_BROWSERS.includes(browserName)
      ? partiallySupported
      : supported;
  }

  return unsupported;
};
