import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";

export const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    backgroundColor: theme.palette.white,
    overflow: "hidden",
  },
  dialog: {
    padding: theme.spacing(3),
  },
  text: {
    textAlign: "center",
  },
}));
