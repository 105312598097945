import { Box } from "@remo-co/ui-core/src/components/Box";
import { Grid } from "@remo-co/ui-core/src/components/Grid";
import { Typography } from "@remo-co/ui-core/src/components/Typography";
import { Redeem } from "@remo-co/ui-core/src/icons/Redeem";
import { MonetizationOn } from "@remo-co/ui-core/src/icons/MonetizationOn";
import { useI18n } from "i18n";
import classNames from "classnames";
import { useStyles } from "./styles";
import { TICKET_PRICE } from "../../constants";

interface Props {
  priceType: TICKET_PRICE;
  handleChange: (type: TICKET_PRICE) => void;
}

export const TicketPriceType = ({ priceType, handleChange }: Props) => {
  const { t } = useI18n(["eventForm"]);
  const styles = useStyles();

  const options = [
    {
      type: TICKET_PRICE.FREE,
      icon: <Redeem />,
      caption: t("eventForm:free"),
    },
    {
      type: TICKET_PRICE.PAID,
      icon: <MonetizationOn />,
      caption: t("eventForm:paid"),
    },
  ];

  return (
    <Box display="flex" flexDirection="column" className={styles.root}>
      <Typography variant="body1" className={styles.label}>
        {t("eventForm:ticket.price")}
      </Typography>
      <Grid container spacing={2}>
        {options.map((option) => (
          <Grid item xs={6} key={option.type}>
            <div
              role="button"
              onClick={() => handleChange(option.type)}
              onKeyPress={({ key }) => {
                if (key === "Enter") {
                  handleChange(option.type);
                }
              }}
              className={classNames(styles.type, {
                [styles.selectedType]: priceType === option.type,
              })}
              tabIndex={0}
            >
              {option.icon}
              <Typography className={styles.typeLabel}>
                {option.caption}
              </Typography>
            </div>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};
