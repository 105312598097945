import { TranscriptionActions } from "./constants";
import { ITranscriptionAction } from "./types";

export const clearWebSocket = (): ITranscriptionAction => ({
  type: TranscriptionActions.CLEAR_WEBSOCKET,
});

export const clearProcessor = (): ITranscriptionAction => ({
  type: TranscriptionActions.CLEAR_PROCESSOR,
});

export const updateWebSocket = (
  newWebSocket: WebSocket,
  isSpeaker: boolean,
): ITranscriptionAction => ({
  type: TranscriptionActions.UPDATE_WEBSOCKET,
  payload: { websocket: newWebSocket, isSpeaker },
});

export const updateTranscript = (
  transcript: RealtimeSpeechData,
): ITranscriptionAction => ({
  type: TranscriptionActions.UPDATE_TRANSCRIPT,
  payload: { transcript },
});

export const updateFinalizedTranscript = (
  finalizedTranscript: RealtimeSpeechData,
): ITranscriptionAction => ({
  type: TranscriptionActions.UPDATE_FINALIZED_TRANSCRIPT,
  payload: { finalizedTranscript },
});

export const updateToken = (token: string): ITranscriptionAction => ({
  type: TranscriptionActions.UPDATE_TOKEN,
  payload: { token },
});
export const updateProcessor = (
  processor: ScriptProcessorNode,
): ITranscriptionAction => ({
  type: TranscriptionActions.UPDATE_PROCESSOR,
  payload: { processor },
});

export const updateSampleRate = (
  sampleRate?: number,
): ITranscriptionAction => ({
  type: TranscriptionActions.UPDATE_SAMPLE_RATE,
  payload: { sampleRate },
});

export const updateAudioContext = (
  audioContext: AudioContext,
): ITranscriptionAction => ({
  type: TranscriptionActions.UPDATE_AUDIO_CONTEXT,
  payload: { audioContext },
});

export const clearAudioContext = (): ITranscriptionAction => ({
  type: TranscriptionActions.CLEAR_AUDIO_CONTEXT,
});

export const clearFinalizedTranscript = (): ITranscriptionAction => ({
  type: TranscriptionActions.CLEAR_FINALIZED_TRANSCRIPT,
});
