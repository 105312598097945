import { Box } from "@remo-co/ui-core/src/components/Box";
import { Grid } from "@remo-co/ui-core/src/components/Grid";
import { Typography } from "@remo-co/ui-core/src/components/Typography";
import { getCurrentTimezone } from "helpers/time/timezone";
import { useI18n } from "i18n";
import moment from "moment";
import "./EventValidationErrorPopup.scss";
import { IEvent } from "modules/event/types";

enum EventValidationErrorTypes {
  CONCURRENT_OVERLIMIT = 1,
}

interface IEventValidationErrorPopupProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any;
  message: string;
}

const EventValidationErrorPopup = ({
  data,
  message,
}: IEventValidationErrorPopupProps) => {
  // eslint-disable-next-line react/no-unstable-nested-components, react/jsx-no-useless-fragment
  let ValidationDetails = () => <></>;
  const timezone = getCurrentTimezone();
  const { t } = useI18n(["common", "settings"]);

  let type: EventValidationErrorTypes | null = null;

  if (!data) {
    return null;
  }

  // Convert to Switch when introducing more types
  if (data.validationError === "CONCURRENT_OVERLIMIT") {
    type = EventValidationErrorTypes.CONCURRENT_OVERLIMIT;
  }

  // Convert to Switch when introducing more types
  if (type === EventValidationErrorTypes.CONCURRENT_OVERLIMIT) {
    // eslint-disable-next-line react/no-unstable-nested-components
    ValidationDetails = () => (
      <Box>
        <Box className="eve-head">
          <Typography variant="subtitle1">
            {t("eventForm:current.events")}:
          </Typography>
        </Box>
        <Grid container spacing={1}>
          <Grid item xs={4}>
            <Typography>
              <strong>{t("eventForm:event.title")}</strong>
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography>
              <strong>{t("eventForm:start.time")}</strong>
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography>
              <strong>{t("eventForm:end.time")}</strong>
            </Typography>
          </Grid>
        </Grid>
        {data.concurrentEvents.map((e: IEvent) => (
          <Grid container key={e.id} spacing={1}>
            <Grid item xs={4}>
              <Typography variant="body1">{e.name}</Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="body1">
                {moment(e.startTime).format("MMM. Do, hh:mma")} ({timezone})
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="body1">
                {moment(e.endTime).format("MMM. Do, hh:mma")} ({timezone})
              </Typography>
            </Grid>
          </Grid>
        ))}
      </Box>
    );
  }

  return (
    <Box className="eve-box">
      {message}
      <ValidationDetails />
    </Box>
  );
};

export default EventValidationErrorPopup;
