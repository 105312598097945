import { ref, onValue, off } from "firebase/database";
import { database } from "services/firebaseService/firebaseConfig";

export const getTimeDeviation = async (): Promise<number> => {
  const timeOffsetRef = ref(database, ".info/serverTimeOffset");

  return new Promise((resolve, reject) => {
    const handleValue = (snapshot: { val: () => number }) => {
      const deviation = snapshot.val();
      resolve(deviation);
      off(timeOffsetRef, "value", handleValue);
    };

    onValue(timeOffsetRef, handleValue, (error) => {
      reject(error);
      off(timeOffsetRef, "value", handleValue);
    });
  });
};

export const getCurrentClientTime = async (): Promise<number> => {
  const deviation = await getTimeDeviation();
  return Date.now() + deviation;
};
