import logger from "logging/logger";
import { parseURL, validateURL } from "./urlHelper";

export const parseVideo = (url: string) => {
  // - Supported YouTube URL formats:
  //   - http://www.youtube.com/watch?v=My2FRPA3Gf8
  //   - http://youtu.be/My2FRPA3Gf8
  //   - https://youtube.googleapis.com/v/My2FRPA3Gf8
  // - Supported Vimeo URL formats:
  //   - http://vimeo.com/25451551
  //   - http://player.vimeo.com/video/25451551
  // - Also supports relative URLs:
  //   - //player.vimeo.com/video/25451551

  url.match(
    /(http:|https:|)\/\/(player.|www.)?(vimeo\.com|youtu(be\.com|\.be|be\.googleapis\.com))\/(video\/|event\/|embed\/|watch\?v=|v\/)?([A-Za-z0-9._%-]*)(&\S+)?/,
  );

  let type = "unknown";

  if (RegExp.$3.indexOf("youtu") > -1) {
    type = "youtube";
  } else if (RegExp.$3.indexOf("vimeo") > -1) {
    type = "vimeo";
  }

  return {
    type,
    id: RegExp.$6,
  };
};
const parseTwitchURL = (url: string, forceAutoplay?: boolean) => {
  const parentHostName = window.location.hostname;

  try {
    if (!validateURL(url)) {
      return null;
    }

    const { protocol, host, path } = parseURL(url);

    if (host === "player.twitch.tv") {
      return url;
    }

    if (host === "www.twitch.tv") {
      // the path will start with /, so first one is empty string
      const parts = path ? path.split("/") : [];

      if (parts.length <= 1) {
        return null;
      }

      if (parts[1] === "videos" && parts.length >= 3) {
        const videoId = parts[2];

        return `${protocol}://player.twitch.tv?video=${videoId}&autoplay=${forceAutoplay}&parent=${parentHostName}`;
      }

      return `${protocol}://player.twitch.tv?channel=${parts[1]}&parent=${parentHostName}`;
    }
  } catch (err) {
    return null;
  }

  return null;
};

export const getEmbeddedVideoURL = (
  url: string,
  forceAutoplay = false,
  noControls = false,
  loopPlayback = false,
): string | null => {
  // Returns the video with the specified URL.
  if (!url) {
    return null;
  }
  try {
    const urlParts = parseURL(url);

    if (urlParts) {
      const { protocol, params } = urlParts;
      const autoplay = forceAutoplay || !!params.get("autoplay");
      const isLive = !!params.get("isLive");
      const videoObj = parseVideo(url);

      if (urlParts.host === "www.youtube-nocookie.com") {
        // url is already mutated, return the url for now
        // PR to fix this and emulate the ideal behavior will follow
        return url;
      }

      if (videoObj.type === "youtube") {
        let ytUrl = `${protocol}://www.youtube-nocookie.com/embed/${videoObj.id}?rel=0`;

        if (autoplay) {
          ytUrl += "&autoplay=1";
        }

        if (loopPlayback) {
          ytUrl += `&loop=1&playlist=${videoObj.id}`;
        }

        if (isLive) {
          ytUrl += "&isLive=1";
        }

        ytUrl += noControls ? "&controls=0&disablekb=1" : "&controls=1";

        return ytUrl;
      }

      if (videoObj.type === "vimeo") {
        let vimeoUrl = `${protocol}://player.vimeo.com/video/${
          videoObj.id
        }?autoplay=${autoplay ? 1 : 0}`;

        if (loopPlayback) {
          vimeoUrl += `&loop=1`;
        }

        return vimeoUrl;
      }

      const twitchURL = parseTwitchURL(url, autoplay);

      return twitchURL;
    }

    return null;
  } catch (error) {
    const errorMessage = error instanceof Error ? error.message : "";
    logger.error(`[getEmbeddedVideoURL] ${url} message: ${errorMessage}`);

    return url;
  }
};

export const validateVideoURL = (url: string): boolean =>
  url.trim().length > 0 &&
  !!getEmbeddedVideoURL(url.trim()) &&
  url.startsWith("https://");

export const getMaskedUrl = (url: string) =>
  // RC-2136
  // If the url has (), then regex tested is blocking the thread, so replacing it to test
  // ex: https://example.com/Remo_Conference%20(7).png
  url.replace(/\(/g, "").replace(/\)/g, "");
