import { RootState } from "store";
import { createSelector } from "@reduxjs/toolkit";

export const selectUserForceMoveState = (state: RootState) =>
  state.userForceMove;

export const selectUserForceMovePopup = createSelector(
  selectUserForceMoveState,
  (userForceMove) => userForceMove.showUserForceMovePopup,
);

export const selectUserForceMoveDialog = createSelector(
  selectUserForceMoveState,
  (userForceMove) => userForceMove.showUserForceMoveDialog,
);

export const selectMovedUser = createSelector(
  selectUserForceMoveState,
  (userForceMove) => userForceMove.user,
);

export const selectUserForceMoveData = createSelector(
  selectUserForceMoveState,
  (userForceMove) => userForceMove.userForceMoveData,
);

export const selectIsUserMoving = createSelector(
  selectUserForceMoveState,
  (userForceMove) => userForceMove.isUserMoving,
);

export const selectUserForceMove = createSelector(
  selectUserForceMoveState,
  (userForceMove) => userForceMove.shouldUserForceMove,
);

export const selectDelayThrottleFunction = createSelector(
  selectUserForceMoveState,
  (userForceMove) => userForceMove.delayThrottleFunction,
);
