import { useSelector } from "react-redux";
import classNames from "classnames";
import { Switch } from "@remo-co/ui-core/src/components/Switch";
import { Typography } from "@remo-co/ui-core/src/components/Typography";
import { useAppDispatch } from "store/hooks";
import { useTranscriptionSidebar } from "modules/transcription";
import { useI18n } from "i18n";
import { useTranscriptionRequests } from "../../hooks/useTranscriptionRequests";
import { TranscriptionSidebarMessage } from "../TranscriptionSidebarMessage";
import {
  selectIsLoading,
  selectShowCaptions,
  selectShowLiveCaptions,
} from "../../redux/selectors";
import { toggleShowCaptions } from "../../redux/transcriptionSlice";
import TranscriptionSidebarStyles from "./styles";

export interface TimedSpeechData extends RealtimeSpeechData {
  time: Date;
}

interface TranscriptionSidebarProps {
  transcriptionMessages: TimedSpeechData[] | null;
}

export const TranscriptionSidebar = ({
  transcriptionMessages,
}: TranscriptionSidebarProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const { t } = useI18n(["transcription", "common"]);
  const { registerTranscriptionRequest, revokeTranscriptionRequest } =
    useTranscriptionRequests();
  const showCaptions = useSelector(selectShowCaptions);
  const showLiveCaptions = useSelector(selectShowLiveCaptions);
  const isLoading = useSelector(selectIsLoading);

  const classes = TranscriptionSidebarStyles();

  const clickHandler = async () => {
    if (!showLiveCaptions) {
      if (!showCaptions) {
        await registerTranscriptionRequest();
      } else {
        await revokeTranscriptionRequest();
      }
    }

    dispatch(toggleShowCaptions());
  };

  const hasMessages =
    transcriptionMessages !== null && transcriptionMessages.length > 0;
  const showLoading = showCaptions && isLoading;
  const showMessages = hasMessages && showCaptions && !isLoading;

  return (
    <div className={classes.root} data-testid="transcription-sidebar">
      <div className={classes.switchContainer}>
        <Typography variant="h6">
          {t("transcription:sidebar.show.closed.captions")}
        </Typography>
        <Switch
          onClick={clickHandler}
          checked={showCaptions}
          // eslint-disable-next-line i18next/no-literal-string
          testId="sidebar-toggle"
          darkMode
        />
      </div>
      <div
        className={classNames(classes.messagesContainer, {
          [classes.withoutMessages]: !showMessages,
        })}
      >
        {showMessages && (
          <span>
            {transcriptionMessages?.map((message, index) => (
              <TranscriptionSidebarMessage
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                message={message}
              />
            ))}
          </span>
        )}
        {!showCaptions && (
          <Typography
            className={classNames(classes.captionOffText)}
            variant="h6"
          >
            {t("transcription:captioning.is.off.message")}
          </Typography>
        )}
        {showLoading && (
          <Typography
            className={classNames(classes.captionOffText)}
            variant="h6"
          >
            {t("common:loading")}
          </Typography>
        )}
      </div>
    </div>
  );
};

const TranscriptionSidebarContainer = (): React.ReactElement => {
  const { finalizedTranscript } = useTranscriptionSidebar();
  return <TranscriptionSidebar transcriptionMessages={finalizedTranscript} />;
};
export default TranscriptionSidebarContainer;
