import actionTypes from "./constants";

export const initialState = {
  isConnected: true,
  networkQuality: 0,
  networkSpeed: 0,
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any,  @typescript-eslint/default-param-last
const networkReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case actionTypes.SET_NETWORK_INFO:
      return {
        ...state,
        ...action.payload,
      };
    case actionTypes.CONNECT:
      return {
        ...state,
        isConnected: true,
      };
    case actionTypes.DISCONNECT:
      return {
        ...state,
        isConnected: false,
      };
    case actionTypes.RESET:
      return initialState;
    default:
      return state;
  }
};

export default networkReducer;
