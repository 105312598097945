import { createSelector } from "@reduxjs/toolkit";
import { DisplayItemConfig } from "modules/audioVideo";
import {
  selectIsInBroadcast,
  selectVideoURL,
} from "modules/broadcast/redux/selectors";
import { selectIsWhiteboardOpen as selectIsPresentationWhiteBoardOpen } from "modules/broadcastWhiteboard/redux/selectors";
import { selectPresentationModeContent } from "modules/eventEmbedContent/redux";
import { selectIsRoomWhiteboardOpen } from "modules/roomWhiteboard/redux/selectors";
import { useMemo } from "react";
import { useSelector } from "react-redux";

const selectWhiteboardOpen = createSelector(
  selectIsRoomWhiteboardOpen,
  selectIsPresentationWhiteBoardOpen,
  selectIsInBroadcast,
  (
    roomWhiteboardOpen,
    presentatationModeWhiteboardOpen,
    isInPresentationMode,
  ) =>
    isInPresentationMode
      ? presentatationModeWhiteboardOpen
      : roomWhiteboardOpen,
);

interface Props {
  screens: string[];
  cameras: string[];
}

export const useAvailableStreamIds = ({
  screens,
  cameras,
}: Props): {
  contentStreamIds: string[];
  availableStreamIds: string[];
} => {
  const isWhiteboardOpen = useSelector(selectWhiteboardOpen);
  const isYoutubeContentTileOpen = Boolean(useSelector(selectVideoURL));
  const iFrameContent = useSelector(selectPresentationModeContent);

  const userStreamIds = useMemo(
    () => [...cameras, ...screens].sort(),
    [cameras, screens],
  );

  const contentStreamIds = useMemo(() => {
    const streams = [];
    if (isWhiteboardOpen) {
      streams.push(DisplayItemConfig.whiteboardDisplayId);
    }
    if (isYoutubeContentTileOpen) {
      streams.push(DisplayItemConfig.externalVideoDisplayId);
    }
    if (iFrameContent) {
      streams.push(DisplayItemConfig.presentContentDisplayId);
    }

    return streams;
  }, [isWhiteboardOpen, isYoutubeContentTileOpen, iFrameContent]);

  const availableStreamIds = useMemo(
    () => [...userStreamIds, ...contentStreamIds],
    [userStreamIds, contentStreamIds],
  );

  return { contentStreamIds, availableStreamIds };
};
