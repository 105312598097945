import { Theme } from "@remo-co/ui-core/src/types";
import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";

export const useStyles = makeStyles<Theme>((theme) => ({
  container: {
    marginBottom: theme.spacing(5),
  },
  imageContainer: {
    display: "flex",
    flexDirection: "column",
  },
  imageForm: {
    display: "flex",
    "align-items": "center",
    marginBottom: "10px",
  },
  imageDetails: {
    marginLeft: "1rem",
    marginRight: "1rem",
    display: "flex",
    "align-items": "center",
  },
  badgeBackground: {
    width: "160px",
    height: "90px",
    position: "relative",
    marginLeft: "1rem",
    marginTop: "1rem",
  },
  badgeBackgroundImage: {
    width: "160px",
    height: "90px",
    position: "relative",
    background: "#ffffff",
    "border-radius": "5px",
  },
  badgePreview: {
    "box-sizing": "border-box",
    display: "flex",
    position: "relative",
    "align-items": "center",
    "justify-content": "center",
    cursor: "pointer",
    background: "#ffffff",
    border: "1px solid",
    borderColor: theme.palette.grey[400],
  },
  badgePreviewSM: {
    width: "100%",
    height: "100%",
    borderRadius: "10px",
    bottom: "3px",
  },
  badgePreviewMD: {
    "border-radius": "30px",
    width: "130px",
    height: "60px",
  },
  uploadedImageSM: {
    width: "100%",
    height: "100%",
    "object-fit": "cover",
    "border-radius": "10px",
  },
  uploadedImageMD: {
    width: "100%",
    height: "100%",
    "object-fit": "cover",
    "border-radius": "30px",
  },
  badgePreviewAvatar: {
    height: "50% !important",
    display: "flex !important",
    position: "absolute !important" as "absolute",
    width: "30% !important",
    right: "48px !important",
    top: "15px",
  },
}));
