import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "store";

const selectQa = (state: RootState) => state.broadcastQA;

const selectQuestions = createSelector(
  selectQa,
  (substate) => substate.questions,
);

export { selectQuestions };
