import {
  useState,
  useCallback,
  useContext,
  ReactElement,
  MouseEvent as ReactMouseEvent,
} from "react";
import { useSelector } from "react-redux";
import {
  useFloating,
  autoUpdate,
  offset,
} from "@floating-ui/react-dom-interactions";
import { ClickAwayListener } from "@remo-co/ui-core/src/components/ClickAwayListener";
import { List } from "@remo-co/ui-core/src/components/List";
import { ListItem } from "@remo-co/ui-core/src/components/ListItem";
import { Paper } from "@remo-co/ui-core/src/components/Paper";
import { MoreVert as MoreVertIcon } from "@remo-co/ui-core/src/icons/MoreVert";
import { ActionButton } from "modules/actionButton";
import { selectUserId } from "modules/auth/redux/selectors";
import { Events, TRACKING_CONTEXT } from "modules/tracking";
import { selectCurrentEventId } from "modules/event/selectors";
import {
  AnnouncementButton,
  ShuffleButton,
  TimerButton,
  WhiteboardButton,
  LobbyButton,
} from "modules/eventButtons";
import { UnassignMenuButton } from "modules/assignTables";
import { useI18n } from "i18n";
import LockAllTablesButton from "modules/lockTables/components/LockAllTablesButton/LockAllTablesButton";
import { LaunchEmbedContentButton } from "modules/eventEmbedContent";
import { InEventSeatManagementButton } from "modules/assignTables/components/InEventSeatManagementButton";
import { useStyles } from "./styles";

interface Props {
  showLobbyButton?: boolean;
}

const ManagerMenu = ({ showLobbyButton }: Props): ReactElement => {
  const { t } = useI18n();
  const { track } = useContext(TRACKING_CONTEXT);
  const userId = useSelector(selectUserId);
  const currentEventId = useSelector(selectCurrentEventId);
  const styles = useStyles();
  const [open, setOpen] = useState(false);
  const [isClickAwayListenerDisabled, setIsClickAwayListenerDisabled] =
    useState(false);

  const { x, y, reference, floating, strategy, refs } =
    useFloating<HTMLButtonElement>({
      whileElementsMounted: autoUpdate,
      placement: "top",
      middleware: [offset({ mainAxis: 55, crossAxis: 0 })],
    });

  const handleMenuToggle = useCallback(() => {
    if (userId && currentEventId) {
      track(Events.MORE_OPTIONS_VIEWED, {
        eventId: currentEventId,
        userId,
      });
    }
    setOpen((prev) => !prev);
  }, [userId, currentEventId, track]);

  const handleClose = useCallback(
    (e: MouseEvent | TouchEvent | ReactMouseEvent) => {
      if (
        !isClickAwayListenerDisabled &&
        e.target instanceof Node &&
        !refs.reference.current?.contains(e.target)
      ) {
        setOpen(false);
      }
    },
    [refs.reference, isClickAwayListenerDisabled],
  );

  return (
    <>
      <ActionButton
        ref={reference}
        data-testid="ctrl-more"
        onClick={handleMenuToggle}
        label={t("more")}
        isActive={open}
        icon={<MoreVertIcon />}
      />
      {open && (
        <div
          ref={floating}
          style={{
            position: strategy,
            top: y ?? "",
            left: x ?? "",
          }}
          data-testid="manager-menu-ctn"
          className={styles.popper}
        >
          <ClickAwayListener onClickAway={handleClose} mouseEvent="onMouseUp">
            <Paper elevation={8} className={styles.popoverContainer}>
              <List disablePadding>
                <ListItem className={styles.popoverItem} onClick={handleClose}>
                  <AnnouncementButton />
                </ListItem>

                <ListItem className={styles.popoverItem} onClick={handleClose}>
                  <WhiteboardButton isSmallButton />
                </ListItem>

                <ListItem className={styles.popoverItem} onClick={handleClose}>
                  <TimerButton />
                </ListItem>
                <ListItem className={styles.popoverItem} onClick={handleClose}>
                  <ShuffleButton />
                </ListItem>
                <ListItem className={styles.popoverItem}>
                  <UnassignMenuButton
                    disableClickAway={() =>
                      setIsClickAwayListenerDisabled(true)
                    }
                    enableClickAway={() =>
                      setIsClickAwayListenerDisabled(false)
                    }
                  />
                </ListItem>
                <ListItem className={styles.popoverItem} onClick={handleClose}>
                  <LockAllTablesButton
                    setClickAwayListenerDisabled={
                      setIsClickAwayListenerDisabled
                    }
                  />
                </ListItem>
                <ListItem className={styles.popoverItem}>
                  <LaunchEmbedContentButton
                    closePopover={() => setOpen(false)}
                    disableClickAway={() =>
                      setIsClickAwayListenerDisabled(true)
                    }
                    enableClickAway={() =>
                      setIsClickAwayListenerDisabled(false)
                    }
                  />
                </ListItem>
                {showLobbyButton && (
                  <ListItem
                    className={styles.popoverItem}
                    onClick={handleClose}
                  >
                    <LobbyButton isSmallButton />
                  </ListItem>
                )}
                <ListItem className={styles.popoverItem}>
                  <InEventSeatManagementButton
                    closePopover={() => setOpen(false)}
                  />
                </ListItem>
              </List>
            </Paper>
          </ClickAwayListener>
        </div>
      )}
    </>
  );
};

export default ManagerMenu;
