import { IUserLoginDetails, IUser, IUserProfile } from "modules/app/types";
import { ISignUpParams } from "modules/auth/types";
import {
  sendGetRequest,
  sendPostRequest,
  sendPutRequest,
} from "services/apiService";

const getMyProfile = () =>
  sendGetRequest<APIResponse<IUserLoginDetails>>("/user/me");
const updateMyProfile = (data: Partial<IUser> | Partial<IUserProfile>) =>
  sendPutRequest<
    Partial<IUser> | Partial<IUserProfile>,
    { isSuccess: true; user: IUser } | { isSuccess: false; message: string }
  >("/user/me", data);
const createProfile = (data: Omit<ISignUpParams, "password">) =>
  sendPostRequest<
    Omit<ISignUpParams, "password">,
    { isSuccess: true; user: IUser } | { isSuccess: false; message: string }
  >("/user/signup", data);
const getUserByEmail = (email: string) =>
  sendGetRequest<APIResponse<IUserLoginDetails>>(`/user/email/${email}`);
const getUserClaims = (token: string) =>
  sendGetRequest<APIResponse<IUserLoginDetails>>(
    `/user/sso/user-claims?idToken=${token}`,
  );
const syncUseridInClaims = () =>
  sendGetRequest<APIResponse<IUserLoginDetails>>("/user/sync-userid");
const createAnonymousUser = (data: { name: string }) =>
  sendPostRequest<{ name: string }, { user?: IUser; message?: string }>(
    "/user/signup/anonymous",
    data,
  );

export default {
  getMyProfile,
  updateMyProfile,
  createProfile,
  getUserByEmail,
  getUserClaims,
  syncUseridInClaims,
  createAnonymousUser,
};
