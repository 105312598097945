import { Close } from "@remo-co/ui-core/src/icons/Close";
import { useAppDispatch } from "store/hooks";
import React from "react";
import { Dialog } from "@remo-co/ui-core/src/components/Dialog";
import { DialogContent } from "@remo-co/ui-core/src/components/DialogContent";
import { IconButton } from "@remo-co/ui-core/src/components/IconButton";
import { useStyles } from "./withDialog.styles";
import { setShowRegisterDialog } from "../redux/eventLandingSlice";

function withDialog<T>(Component: React.ComponentType<T>) {
  return (props: T & { onClose?: () => void }) => {
    const { onClose } = props;
    const styles = useStyles();
    const dispatch = useAppDispatch();

    const handleClose = () => {
      if (typeof onClose === "function") {
        onClose();
        return;
      }

      dispatch(setShowRegisterDialog(false));
    };

    return (
      <Dialog
        className={styles.dialog}
        fullWidth
        classes={{ paperScrollPaper: styles.paperScrollPaper }}
        maxWidth="sm"
        onClose={handleClose}
        open
        disableEscapeKeyDown
      >
        <DialogContent classes={{ root: styles.dialogContent }}>
          <IconButton
            className={styles.closeButton}
            data-testid="rte-close"
            onClick={handleClose}
            size="large"
          >
            <Close />
          </IconButton>
          <Component {...props} />
        </DialogContent>
      </Dialog>
    );
  };
}

export default withDialog;
