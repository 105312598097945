import {
  ChangeEventHandler,
  ReactNode,
  MouseEvent as ReactMouseEvent,
  ComponentType,
  ElementType,
  FocusEventHandler,
  HTMLInputTypeAttribute,
  MouseEventHandler,
  forwardRef,
} from "react";
import classnames from "classnames";
import InputBase, { InputBaseComponentProps } from "@mui/material/InputBase";
import { Theme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import FormHelperText from "@mui/material/FormHelperText";
import InputLabel from "@mui/material/InputLabel";
import { LG, SM } from "../../types";
import { styles } from "./styles";
import { InputHelpText } from "../InputHelpText";

interface Props {
  id?: string;
  name?: string;
  className?: string;
  size?: SM | LG | "multi";
  label?: string;
  disabled?: boolean;
  labelComponent?: ComponentType;
  description?: string;
  component?: ElementType<InputBaseComponentProps>;
  fullWidth?: boolean;
  multiline?: boolean;
  rows?: number;
  error?: string | boolean;
  defaultValue?: unknown;
  value?: unknown;
  required?: boolean;
  autoFocus?: boolean;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  inputProps?: InputBaseComponentProps;
  placeholder?: string;
  readOnly?: boolean;
  inputClassName?: string;
  inputEndAdornment?: ReactNode;
  inputStartAdornment?: ReactNode;
  onInputLabelClick?(event: ReactMouseEvent): void;
  inputFocused?: boolean;
  onClick?: MouseEventHandler<HTMLInputElement>;
  onFocus?: FocusEventHandler<HTMLInputElement>;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  type?: HTMLInputTypeAttribute;
  getRemainingCharsMessage?: (remainingChars: string) => string;
  descriptionClassName?: string;
}

const useStyles = makeStyles<Theme, Props>(styles);

const Input = forwardRef<HTMLInputElement, Props>((props, ref) => {
  const {
    id,
    className,
    description,
    component = "input",
    size = "lg",
    fullWidth = false,
    multiline = false,
    rows,
    error = false,
    defaultValue,
    required = false,
    autoFocus = false,
    label,
    labelComponent: LabelComponent,
    inputProps,
    onChange,
    placeholder,
    value,
    readOnly,
    inputClassName = "",
    inputEndAdornment,
    inputStartAdornment,
    onInputLabelClick,
    inputFocused = false,
    disabled = false,
    getRemainingCharsMessage,
    descriptionClassName,
    ...rest
  } = props;

  const classes = useStyles(props);
  const currentLength = value ? `${value}`.length : 0;

  const rootClassName = classnames(
    {
      [classes.fullWidth]: fullWidth,
      [classes.error]: error,
      [classes.value]: value,
    },
    classes.root,
    classes[size],
    className,
  );

  return (
    <div className={classes.container}>
      <InputLabel htmlFor={id} onClick={onInputLabelClick}>
        <div className={classes.label}>
          {label && required ? `${label}*` : label}
          {LabelComponent && <LabelComponent />}
        </div>
      </InputLabel>
      <InputBase
        id={id}
        ref={ref}
        classes={{
          root: classnames(rootClassName, {
            [classes.inputFocused]: inputFocused,
            [classes.disabled]: disabled,
          }),
          focused: classes.inputFocused,
          input: classnames(classes.input, {
            [classes.inputDisabled]: disabled,
            [inputClassName]: inputClassName,
          }),
        }}
        autoFocus={autoFocus}
        fullWidth={fullWidth}
        defaultValue={defaultValue}
        disabled={disabled}
        error={!!error}
        inputComponent={component}
        readOnly={readOnly}
        required={required}
        onChange={onChange}
        placeholder={placeholder}
        startAdornment={inputStartAdornment}
        endAdornment={inputEndAdornment}
        inputProps={inputProps}
        multiline={multiline}
        rows={rows}
        value={value}
        {...rest}
      />
      {getRemainingCharsMessage && inputProps?.maxLength && (
        <FormHelperText className={classes.description}>
          {getRemainingCharsMessage?.(
            `${inputProps.maxLength - currentLength}`,
          )}
        </FormHelperText>
      )}
      <InputHelpText className={descriptionClassName} isError={!!error}>
        {error || description}
      </InputHelpText>
    </div>
  );
});

export default Input;
