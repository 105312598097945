import { EventStatus } from "../constants";
import { IEvent } from "../types";

export const ALLOW_TO_JOIN_LOBBY_BEFORE_EVENT_STARTED_TIME = 20 * 60 * 1000;

export const checkIsAllowedToJoinEventLobby = (
  { startTime, endTime, status }: IEvent,
  serverTimeDeviation = 0,
) => {
  const nowTime = Date.now() + serverTimeDeviation;

  return (
    status === EventStatus.active &&
    startTime <= nowTime + ALLOW_TO_JOIN_LOBBY_BEFORE_EVENT_STARTED_TIME &&
    endTime >= nowTime
  );
};
