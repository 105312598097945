import React, { ReactNode } from "react";
import reducer, { initialPageSettingsState } from "./pageSettingReducer";

export interface IPageSettingState {
  pageTitle?: string | null;
}

interface IAction {
  type: string;
  payload?: {
    pageTitle?: string;
  };
}

type IDispatch = (action: IAction) => void;

export interface IPageSettingContext {
  state: IPageSettingState;
  dispatch?: IDispatch;
}

export const PageSettingContext = React.createContext<IPageSettingContext>({
  state: {},
});

const PageSettingProvider = ({ children }: { children?: ReactNode }) => {
  const [state, dispatch] = React.useReducer(reducer, initialPageSettingsState);

  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <PageSettingContext.Provider value={{ state, dispatch }}>
      {children}
    </PageSettingContext.Provider>
  );
};

export default PageSettingProvider;
