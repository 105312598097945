import { EnforceAssignmentErrors } from "modules/enforceTableAssignment/constants";
import { sendPostRequest } from "services/apiService";

interface ConfirmFloorResponse extends IGenericAPIResponse {
  status?: boolean;
  floorId?: string | null;
  tableId?: string | null;
}

export const getRequestURL = (eventId: string, presetId?: string) =>
  `/event/${eventId}/enforce-assignments/confirm-floor${
    presetId ? `?presetId=${presetId}` : ""
  }`;

const request = async (eventId: string, presetId?: string) => {
  const resp = await sendPostRequest<
    Record<string, never>,
    ConfirmFloorResponse
  >(getRequestURL(eventId, presetId), {});

  if (!resp.status) {
    throw new Error(resp.message ?? resp.error);
  }

  if (!resp.floorId) {
    throw new Error(EnforceAssignmentErrors.NO_FLOOR_FOUND_FOR_ATTENDEE);
  }

  return { floorId: resp.floorId, tableId: resp.tableId };
};

export default request;
