import { SystemInfoWithTroubleShootLinks } from "modules/systemCheck/types";
import { checkSystemInfo } from "../checkSystemInfo";
import { getTroubleShootLinks } from "../getTroubleShootLinks";

const systemInfo = checkSystemInfo();
const troubleShootLinks = getTroubleShootLinks(systemInfo.osName);

export const getSystemInfo = (): SystemInfoWithTroubleShootLinks => ({
  ...systemInfo,
  troubleShootLinks,
});
