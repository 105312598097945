import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";
import { Theme } from "@remo-co/ui-core/src/types";

export const useStyles = makeStyles<Theme>((theme) => ({
  createEventButtonsDiv: {
    display: "flex",
  },
  createButtonPopover: {
    width: "380px",
    height: "142px",
    borderRadius: "5px",
    background: theme.palette.white,
    border: `1px solid ${theme.palette.gray.light}`,
  },
  createEventList: {
    padding: 0,
  },
  createEventItem: {
    width: "380px",
    height: "71px",
    padding: "15px 20px 15px 20px",
    gap: "0px",
    justify: "space-between",
    opacity: "0px",
  },
  createEventHeading: {
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "21.86px",
    textAlign: "left",
  },
  createEventDescription: {
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "19.12px",
    textAlign: "left",
  },
  arrowButton: {
    fontSize: "14px",
  },
}));
