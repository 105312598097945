// eslint-disable-next-line you-dont-need-lodash-underscore/is-string
import { isString } from "lodash";
import templatesService from "services/firestoreService/templates";
import { IMapTemplate } from "types/theater";
import { IEvent } from "./types";

export const loadTemplate = async (event: IEvent): Promise<void> => {
  if (!(event && event.theaters)) return;

  const { theaters } = event;

  if (theaters[0]) {
    const code = theaters[0].template;

    if (isString(code)) {
      const template = await templatesService.getTemplateByCode(code);

      if (template) {
        // eslint-disable-next-line no-param-reassign
        event.theaters[0].template = template;
      }
    }
  }
};

export const isTypeOfIMapTemplate = (object: unknown): object is IMapTemplate =>
  !!object && !isString(object) && !!(object as IMapTemplate).seatRadius;

export const updateTemplateObjByCode = (
  event?: Pick<IEvent, "theaters">,
): void => {
  if (!(event && event.theaters)) return;
  const { theaters } = event;

  if (theaters[0]) {
    const { template } = theaters[0];

    if (isTypeOfIMapTemplate(template)) {
      // eslint-disable-next-line no-param-reassign
      event.theaters[0].template = template.code;
    }
  }
};

export const getMapTemplateByType = (
  object: IMapTemplate | string | undefined | null,
): IMapTemplate | null => {
  if (!isString(object) && isTypeOfIMapTemplate(object)) {
    return object;
  }

  return null;
};
