import { useMutation, useQueryClient } from "@tanstack/react-query";
import useEventInviteActions from "modules/eventInvite/hooks/useEventInviteActions";
import { ATTENDEE_LIST_QUERY_KEY } from "modules/manageEvent/constants";

type AttendeeInvitePayload = {
  eventId: string;
  emails: string[];
  force: boolean;
  isEmailInvitation: boolean;
};
export const ATTENDEE_INVITE_QUERY_KEY = "event.attendeeinvite";

export const useAttendeeInvite = () => {
  const { sendAttendeeInvite } = useEventInviteActions();
  const queryClient = useQueryClient();

  const {
    isPending,
    error,
    mutateAsync: sendInviteAsync,
    mutate: sendInvite,
    isSuccess,
  } = useMutation({
    mutationFn: async (params: AttendeeInvitePayload) => {
      const response = await sendAttendeeInvite(params);
      if (!response?.isSuccess) {
        const errorMessage = response.message || "something.wrong";
        throw new Error(errorMessage);
      }
      return response;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [ATTENDEE_LIST_QUERY_KEY] });
    },
  });

  return {
    sendInvite,
    sendInviteAsync,
    isPending,
    isSuccess,
    error: error as Error,
  };
};
