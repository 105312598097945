import { useState } from "react";
import { useSelector } from "react-redux";
import { Dialog } from "@remo-co/ui-core/src/components/Dialog";
import { Divider } from "@remo-co/ui-core/src/components/Divider";
import { Settings } from "@remo-co/ui-core/src/icons/Settings";
import { Lock } from "@remo-co/ui-core/src/icons/Lock";
import { LockOpen } from "@remo-co/ui-core/src/icons/LockOpen";
import { Share } from "@remo-co/ui-core/src/icons/Share";
import { useI18n } from "i18n";
import { SVGRoomNames } from "modules/customFloorPlan/constants";
import {
  selectCurrentEventId,
  selectIsEventManager,
  selectIsLockTablesByGuestsEnabled,
} from "modules/event/selectors";
import {
  SettingUser,
  TableData,
  UnassignTableContextMenuItem,
  useEventTableSettings,
} from "modules/assignTables";
import { selectCurrentRoomId, selectRooms } from "store/rooms/selectors";
import { TableEditMenu } from "modules/svgMapView/templateParts/TableEditMenu";
import { useLockTable, useLockTableMutation } from "modules/lockTables/hooks";
import { selectCurrentSpaceId } from "modules/space/redux/selectors";
import { selectIsMobile } from "modules/deviceInfo";
import { selectUserId } from "modules/auth/redux/selectors";
import { useCopyTableLink } from "modules/conversation/hooks/useCopyTableLink";
import { ContextMenuItem, ContextMenuTitle } from "..";

const getAssignedAttendees = (
  assignedAttendees: SettingUser[],
  floorId: string | null,
  roomId: string,
  tableData: TableData,
) => {
  if (!floorId) {
    return [];
  }
  return assignedAttendees
    ? assignedAttendees.filter((attendee) =>
        tableData[floorId][roomId]?.users
          ?.map((user) => user.email)
          ?.includes(attendee.email),
      )
    : [];
};

interface Props {
  id: string;
  onClose: () => void;
}

const RoomContextMenu = ({ id, onClose }: Props): JSX.Element => {
  const { t } = useI18n(["common"]);
  const rooms = useSelector(selectRooms);
  const currentEventId = useSelector(selectCurrentEventId);
  const floorId = useSelector(selectCurrentSpaceId);
  const currentTableId = useSelector(selectCurrentRoomId);
  const currentUserId = useSelector(selectUserId);
  const isMobileView = useSelector(selectIsMobile);
  const isEventManager = useSelector(selectIsEventManager);
  const isLockTablesByGuestsEnabled = useSelector(
    selectIsLockTablesByGuestsEnabled,
  );
  const { lockedTables } = useLockTable();
  const { lockTable, lockTableAsync, isPending } = useLockTableMutation();
  const targetRoom = rooms[id];
  const tableId = targetRoom.id;
  const { copyTableLink } = useCopyTableLink();

  const [formOpen, setFormOpen] = useState(false);
  const handleClose = () => {
    setFormOpen(false);
    onClose();
  };

  const handleTableSettings = () => {
    setFormOpen(true);
  };

  const lockTableInfo = lockedTables.find(({ table }) => table === id);
  const denyEntry = lockTableInfo?.denyEntry ?? false;
  const denyExit = lockTableInfo?.denyExit ?? false;
  const handleLockTable = () => {
    lockTable({
      tableId,
      floorId: floorId as string,
      denyEntry: !denyEntry,
      tableName: targetRoom.name,
    });
  };

  const { assignedAttendees, tableData } = useEventTableSettings(
    currentEventId ?? "",
  );

  const assignedUsersAtTable = getAssignedAttendees(
    assignedAttendees,
    floorId,
    tableId,
    tableData,
  );

  const notStageRoom = targetRoom.code !== SVGRoomNames.STAGE;
  const shownTableName = targetRoom.name.length
    ? targetRoom.name
    : t("table.unnamed");

  const showLockTables = isEventManager
    ? true
    : isLockTablesByGuestsEnabled && currentTableId === tableId;

  const lockedByMe = lockTableInfo?.lockedByGuest === currentUserId;
  const lockUnlockDisabled = denyEntry && !(isEventManager || lockedByMe);

  let lockTableText = t("table.settings.lock");
  if (denyEntry) {
    if (isEventManager && lockTableInfo?.lockedByGuest) {
      lockTableText = t("table.settings.unlock.locked.by.guest");
    } else if (lockUnlockDisabled) {
      lockTableText = t("table.settings.unlock.locked.by.someone");
    } else {
      lockTableText = t("table.settings.unlock");
    }
  }

  return (
    <>
      {targetRoom && !isMobileView && (
        <>
          <ContextMenuTitle
            data-testid="rcm-title"
            id="rcm-title"
            text={shownTableName}
          />
          {notStageRoom && (
            <>
              <Divider />
              {isEventManager && (
                <ContextMenuItem
                  id="rcm-open-table-settings"
                  data-testid="rcm-open-table-settings"
                  onClick={handleTableSettings}
                  text={t("table.settings")}
                  icon={<Settings color="secondary" />}
                />
              )}
              {showLockTables && (
                <ContextMenuItem
                  id="rcm-change-table-lock"
                  data-testid="rcm-change-table-lock"
                  onClick={handleLockTable}
                  disabled={lockUnlockDisabled}
                  text={lockTableText}
                  icon={
                    denyEntry ? (
                      <LockOpen color="secondary" />
                    ) : (
                      <Lock color="secondary" />
                    )
                  }
                />
              )}
              <ContextMenuItem
                id="rcm-copy-table-link"
                data-testid="rcm-copy-table-link"
                onClick={() => {
                  copyTableLink(tableId);
                }}
                text={t("conversation:copy.table.link")}
                icon={<Share color="secondary" />}
              />
            </>
          )}

          {notStageRoom && (
            <UnassignTableContextMenuItem tableId={id} name={targetRoom.name} />
          )}
        </>
      )}
      <Dialog open={formOpen} onClose={handleClose}>
        <TableEditMenu
          initialForm={{
            tableName: targetRoom.name,
            assignedGuests: assignedUsersAtTable.map((user) => user.email),
            lockExit: denyExit,
            lockEntry: denyEntry,
            updateAllFloors: false,
          }}
          onCancel={handleClose}
          onSubmit={async (form) => {
            await lockTableAsync({
              tableId,
              floorId: floorId as string,
              denyEntry: form.lockEntry,
              denyExit: form.lockExit,
              users: form.assignedGuests,
              tableName: form.tableName,
              updateAllFloors: form.updateAllFloors,
              roomCode: targetRoom.code,
            });
            setFormOpen(false);
          }}
          isLoading={isPending}
        />
      </Dialog>
    </>
  );
};

export default RoomContextMenu;
