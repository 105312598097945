import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "store";
import { EventUser } from "./reducer";

export const selectEventUsers = (state: RootState) => state.presence.eventUsers;

export const selectLiveUsers = createSelector(selectEventUsers, (eventUsers) =>
  eventUsers.map((user: EventUser) => user.userId),
);

export const selectUsersPerSpace = createSelector(
  selectEventUsers,
  (eventUsers) => {
    const usersPerSpace: Record<string, string[]> = {};
    eventUsers.forEach((user: EventUser) => {
      if (!usersPerSpace[user.spaceId]) {
        usersPerSpace[user.spaceId] = [];
      }
      usersPerSpace[user.spaceId].push(user.userId);
    });
    return usersPerSpace;
  },
);

export const selectLiveUserCount = createSelector(
  selectEventUsers,
  (eventUsers) => eventUsers.length,
);
