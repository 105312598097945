import { useSelector } from "react-redux";
import { selectStreamingState } from "modules/streaming/redux/selectors";
import { useCallback, useEffect, useState } from "react";
import { useAppDispatch } from "store/hooks";
import {
  increaseMicOnDurationBy,
  resetMicOnDuration,
} from "modules/streaming/redux/streamingSlice";
import { useDevices } from "modules/audioVideo/hooks";

const useMicTracking = () => {
  const dispatch = useAppDispatch();
  const { micOnDuration } = useSelector(selectStreamingState);
  const { microphoneActive } = useDevices();
  const [startTime, setStartTime] = useState(0);
  const [instantMicOnDuration, setInstantMicOnDuration] = useState(0);

  const setMicOnDuration = () => {
    const duration = Date.now() - startTime;
    dispatch(increaseMicOnDurationBy(duration));
    setInstantMicOnDuration((prevDuration) => prevDuration + duration);
  };

  const getAndResetInstantMicOnDuration = useCallback(() => {
    if (startTime > 0) {
      if (microphoneActive) {
        const duration = Date.now() - startTime;
        setStartTime(Date.now());
        dispatch(increaseMicOnDurationBy(duration));
        const instantDuration = instantMicOnDuration + duration;
        setInstantMicOnDuration(0);
        return instantDuration;
      }

      if (!microphoneActive) {
        const instantDuration = instantMicOnDuration;
        setInstantMicOnDuration(0);
        return instantDuration;
      }
    }

    return undefined;
  }, [startTime, microphoneActive, instantMicOnDuration, dispatch]);

  useEffect(() => {
    if (microphoneActive) {
      setStartTime(Date.now());
    } else if (startTime > 0) {
      setMicOnDuration();
    }

    return () => {
      dispatch(resetMicOnDuration());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, microphoneActive]);

  return {
    getAndResetInstantMicOnDuration,
    micOnDuration,
  };
};

export default useMicTracking;
