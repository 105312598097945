import React from "react";
import classNames from "classnames";
import { LG, LogoVariants, SM } from "../../types";
import smallLogo from "./assets/small-logo.svg";
import smallLogoPresentation from "./assets/small-logo-presentation.svg";
import largeLogo from "./assets/large-logo.svg";
import largeLogoWhite from "./assets/large-logo-white.svg";
import largeLogoBeta from "./assets/large-logo-beta.svg";
import largeLogoPresentation from "./assets/large-logo-presentation.svg";
import smallLogoPoweredBySidebar from "./assets/small-logo-powered-by-sidebar.svg";
import smallLogoPoweredByConv from "./assets/small-logo-powered-by-conv.svg";
import smallLogoPoweredByPres from "./assets/small-logo-powered-by-pres.svg";
import { useStyles } from "./styles";

export interface IRemoLogoProps {
  className?: string;
  logo?: string;
  width?: number;
  size?: SM | LG;
  style?: React.CSSProperties;
  variant?: LogoVariants;
}

const useRemoLogo = ({ variant, logo, size = "lg" }: IRemoLogoProps) => {
  if (logo) {
    return logo;
  }

  if (size === "sm") {
    if (variant === "presentation") {
      return smallLogoPresentation;
    }

    return smallLogo;
  }

  if (variant === "beta") {
    return largeLogoBeta;
  }

  if (variant === "presentation") {
    return largeLogoPresentation;
  }

  if (variant === "powered-by-presentation") {
    return smallLogoPoweredByPres;
  }

  if (variant === "powered-by-conversation") {
    return smallLogoPoweredByConv;
  }

  if (variant === "powered-by-sidebar") {
    return smallLogoPoweredBySidebar;
  }

  if (variant === "large-logo-white") {
    return largeLogoWhite;
  }

  return largeLogo;
};

const RemoLogo = React.memo((props: IRemoLogoProps) => {
  const { className, size = "lg", variant, logo, width, ...rest } = props;
  const styles = useStyles(props);
  const chosenLogo = useRemoLogo({
    variant,
    size,
    logo,
  });

  return (
    <img
      alt="Remo Logo"
      src={chosenLogo}
      className={classNames(className, styles.root, {
        [styles.customWidth]: width,
      })}
      {...rest}
    />
  );
});

export default RemoLogo;
