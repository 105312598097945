import { ComponentProps } from "react";
import { Theme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import Typography from "./Typography";

export const useStyles = makeStyles<Theme, ComponentProps<typeof Typography>>(
  () => ({
    underline: ({ underline }) => ({
      textDecoration: underline ? "underline" : "initial",
    }),
  }),
);
