import { IEventcubeTicket } from "modules/ticketing/types";
import { IEvent } from "modules/event/types";
import { TICKET_STATUS } from "../../constants";

export const getTicketStatus = (
  ticket: IEventcubeTicket,
  eventData: IEvent,
) => {
  if (ticket.sales.sold === ticket.quantity) {
    return TICKET_STATUS.SOLD_OUT;
  }
  // technically unused branch until eventcube implements it
  if (!ticket.sale_schedule) {
    if (new Date() > new Date(eventData.startTime)) {
      return TICKET_STATUS.INACTIVE;
    }

    return TICKET_STATUS.ACTIVE;
  }
  if (new Date() > new Date(ticket.sale_schedule.ends_on)) {
    return TICKET_STATUS.INACTIVE;
  }
  if (new Date() < new Date(ticket.sale_schedule.starts_on)) {
    return TICKET_STATUS.UPCOMING;
  }

  return TICKET_STATUS.ACTIVE;
};
