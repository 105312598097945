import { Theme } from "@remo-co/ui-core/src/types";
import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";

export const useStyles = makeStyles<Theme>((theme) => ({
  displayScreenExpandControls: {
    top: "0",
    cursor: "pointer",
    position: "absolute",
    width: "100%",
    height: "100%",
    zIndex: 9,
    textAlign: "center",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    justifySelf: "center",
    fontSize: "16px",
    lineHeight: "24px",
    borderRadius: "5px",
    "&:hover $displayScreenExpandText": {
      display: "flex",
    },
    "&:hover": {
      background: "rgba(52, 55, 74, 0.6)",
      display: "flex",
    },
  },
  displayScreenExpandText: {
    display: "none",
    width: "fit-content",
    backgroundColor: "var(--CornflowerBlue)",
    color: "var(--White-op-090)",
    margin: "2px 10px",
    padding: theme.spacing(2),
    borderRadius: "4px",
    fontSize: "14px",
  },
}));
