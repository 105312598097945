import MUITypography, { TypographyProps } from "@mui/material/Typography";
import classnames from "classnames";
import { ElementType, forwardRef } from "react";
import { useStyles } from "./styles";

interface Props extends TypographyProps {
  underline?: boolean;
  component?: ElementType | undefined;
}

const Typography = forwardRef<HTMLElement, Props>((props, ref) => {
  const { children, underline, className, ...rest } = props;

  const classes = useStyles({ underline });

  const rootClassName = classnames(
    {
      [classes.underline]: underline,
    },
    className,
  );

  return (
    <MUITypography
      ref={ref}
      classes={{
        root: rootClassName,
      }}
      {...rest}
    >
      {children}
    </MUITypography>
  );
});

export default Typography;
