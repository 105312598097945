import { Map } from "immutable";
import { createSelector } from "@reduxjs/toolkit";
import { selectUser, selectUserId } from "modules/auth/redux/selectors";
import { RootState } from "store";
import { IUserStatus, UserMicCamStatus } from "types";
import { IUser } from "modules/app/types";
import {
  selectCurrentRoomId,
  selectUserRoomStatus,
} from "modules/room/redux/selectors";
import { IUserRoomState } from "modules/room/redux/roomSlice";

const selectUserStatus = (state: RootState) => state.userStatus;

const makeSelectUserStatusByUserId = (userId: string) =>
  createSelector(selectUserStatus, (userStatuses: Map<string, IUserStatus>) =>
    userStatuses.get(userId),
  );

const makeSelectIsUserInTutorial = (userId: string) =>
  createSelector(selectUserStatus, (userStatuses: Map<string, IUserStatus>) =>
    userStatuses.getIn([userId, "isInTutorial"], false),
  );

const selectUsersMicCamStatusInCurrentRoom = createSelector(
  selectUserStatus,
  selectUserRoomStatus,
  selectCurrentRoomId,
  selectUserId,
  (
    userStatuses: Map<string, IUserStatus>,
    userRoomStatus: IUserRoomState,
    currentRoomId,
    currentUserId,
  ): Record<string, UserMicCamStatus> | null => {
    if (currentRoomId) {
      return userStatuses
        .filter(
          (_, userId) =>
            userId !== undefined &&
            userId !== currentUserId &&
            userRoomStatus[currentRoomId] !== undefined &&
            userRoomStatus[currentRoomId][userId] !== undefined,
        )
        .map((userStatus, _) => ({
          isMicrophoneOn: userStatus?.get("isMicrophoneOn"),
          isCameraOn: userStatus?.get("isCameraOn"),
        }))
        .toObject();
    }

    return null;
  },
);

const selectIsCurrentUserInTutorial = createSelector(
  selectUser,
  selectUserStatus,
  (loginUser: IUser | undefined, userStatuses: Map<string, IUserStatus>) => {
    const loginUserId = loginUser && loginUser.id;

    return userStatuses.getIn([loginUserId, "isInTutorial"], false);
  },
);

export {
  makeSelectUserStatusByUserId,
  makeSelectIsUserInTutorial,
  selectIsCurrentUserInTutorial,
  selectUsersMicCamStatusInCurrentRoom,
};
