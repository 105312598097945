import { Alert, AlertTitle } from "@remo-co/ui-core/src/components/Alert";
import { Info } from "@remo-co/ui-core/src/icons/Info";
import { useI18n } from "i18n";
import { useStyles } from "./styles";

interface Props {
  className?: string;
}

const RecordPresentationMessage = ({ className }: Props): JSX.Element => {
  const { t } = useI18n(["event"]);
  const classes = useStyles();

  return (
    <div
      className={classes.messageContainer}
      data-testid="record-presentation-message"
    >
      <Alert
        severity="info"
        icon={<Info className={classes.icon} />}
        classes={{
          standardInfo: className || classes.message,
        }}
      >
        <AlertTitle className={classes.title}>
          {t("event:record.presentation.explanation.title")}
        </AlertTitle>
        {t("event:record.presentation.explanation.message")}
      </Alert>
    </div>
  );
};

export default RecordPresentationMessage;
