import { useSelector } from "react-redux";
import { useAppMessage, useDaily } from "@daily-co/daily-react";
import { selectIsEventManager } from "modules/event/selectors";
import {
  selectManagers,
  selectSpeakers,
} from "modules/event/usersInEvent/selectors";
import { useCallback } from "react";
import { selectCurrentTheaterId } from "modules/theater/selectors";
import { selectUserId } from "modules/auth/redux/selectors";
import {
  CustomAppEvent,
  CustomAppEvents,
} from "modules/audioVideo/DailyEvents";
import {
  useActivePresenterActions,
  useActivePresenters,
} from "../usePresenters";

export const usePermissions = () => {
  const daily = useDaily();
  const isEventManager = useSelector(selectIsEventManager);
  const sendMessage = useAppMessage<CustomAppEvent>();

  const speakers = useSelector(selectSpeakers);
  const managers = useSelector(selectManagers);
  const theaterId = useSelector(selectCurrentTheaterId);
  const userId = useSelector(selectUserId);
  const { presenters } = useActivePresenters();
  const { addActivePresenter, clearActivePresenters } =
    useActivePresenterActions();

  const setPresentationPermissions = useCallback(
    ({ hostDevicesEnabled }: { hostDevicesEnabled: boolean }) => {
      if (!daily) {
        throw new Error("no daily call object");
      }
      if (!isEventManager) {
        throw new Error("insufficient permissions");
      }

      if (!theaterId || !userId) {
        throw new Error("insufficent data");
      }

      const participantInfo = Object.entries(daily.participants())
        .filter(
          ([id, { user_id: userId }]) =>
            id !== "local" && !presenters.includes(userId),
        )
        .map(([id, participant]) => ({
          userId: participant.user_id,
          sessionId: id,
        }));

      const hostPermissions = {
        local: {
          setVideo: hostDevicesEnabled,
          setAudio: hostDevicesEnabled,
          updatePermissions: {
            canSend: true,
          },
        },
      };

      const presentationPermissions = Object.fromEntries(
        participantInfo.map(({ sessionId, userId }) => {
          const hasPublishingPermissions =
            speakers.includes(userId) || managers.includes(userId);
          return [
            sessionId,
            {
              setVideo: false,
              setAudio: false,
              updatePermissions: {
                canSend: hasPublishingPermissions,
              },
            },
          ];
        }),
      );

      if (hostDevicesEnabled) {
        addActivePresenter();
      }

      daily.updateParticipants({
        ...presentationPermissions,
        ...hostPermissions,
      });
    },
    [
      daily,
      isEventManager,
      speakers,
      presenters,
      managers,
      theaterId,
      userId,
      addActivePresenter,
    ],
  );

  const setConversationPermissions = useCallback(() => {
    if (!daily) {
      throw new Error("no daily call object");
    }
    if (!isEventManager) {
      throw new Error("insufficient permissions");
    }

    if (!theaterId) {
      throw new Error("insufficent data");
    }

    clearActivePresenters();

    const conversationPermissions = Object.fromEntries(
      Object.keys(daily.participants())
        .filter((id) => id !== "local")
        .map((participantId) => [
          participantId,
          {
            updatePermissions: {
              hasPresence: true,
              canSend: true,
            },
          },
        ]),
    );

    daily.updateParticipants(conversationPermissions);
  }, [daily, isEventManager, theaterId, clearActivePresenters]);

  const setCameraPermissions = useCallback(
    (userId: string, video: boolean) => {
      if (!daily) {
        throw new Error("no daily call object");
      }
      const dailyUser = Object.values(daily.participants()).find(
        (participant) => participant.user_id === userId,
      );
      if (dailyUser) {
        daily.updateParticipant(dailyUser.session_id, {
          setVideo: video,
        });
      }
    },
    [daily],
  );

  const setMicrophonePermissions = useCallback(
    (userId: string, audio: boolean) => {
      if (!daily) {
        throw new Error("no daily call object");
      }
      const dailyUser = Object.values(daily.participants()).find(
        (participant) => participant.user_id === userId,
      );
      if (dailyUser) {
        daily.updateParticipant(dailyUser.session_id, {
          setAudio: audio,
        });
      }
    },
    [daily],
  );

  const promptActivateMicrophone = useCallback(
    (userId: string) => {
      if (!daily) {
        throw new Error("no daily call object");
      }
      const dailyUser = Object.values(daily.participants()).find(
        (participant) => participant.user_id === userId,
      );
      if (dailyUser) {
        const { session_id: sessionId } = dailyUser;

        sendMessage(
          {
            event: CustomAppEvents.MUTE_USER,
            setAudio: true,
          },
          sessionId,
        );
      }
    },
    [daily, sendMessage],
  );

  const stopScreenShare = useCallback(
    (userId: string) => {
      if (!daily) {
        throw new Error("no daily call object");
      }
      const dailyUser = Object.values(daily.participants()).find(
        (participant) => participant.user_id === userId,
      );
      if (dailyUser) {
        const { session_id: sessionId } = dailyUser;

        sendMessage(
          {
            event: CustomAppEvents.STOP_SCREEN_SHARE,
          },
          sessionId,
        );
      }
    },
    [daily, sendMessage],
  );

  const revokeSpeakerPermission = useCallback(
    (userId: string) => {
      if (!daily) {
        throw new Error("no daily call object");
      }
      const dailyUser = Object.values(daily.participants()).find(
        (participant) => participant.user_id === userId,
      );
      if (dailyUser) {
        daily.updateParticipant(dailyUser.session_id, {
          updatePermissions: {
            canSend: false,
          },
        });
        sendMessage(
          {
            event: CustomAppEvents.REMOVE_FROM_STAGE,
          },
          dailyUser.session_id,
        );
      }
    },
    [daily, sendMessage],
  );

  return {
    setPresentationPermissions,
    setConversationPermissions,
    revokeSpeakerPermission,
    setCameraPermissions,
    setMicrophonePermissions,
    promptActivateMicrophone,
    stopScreenShare,
  };
};
