import { useSymblToken } from "modules/transcription/services/getSymblToken/getSymblToken";
import React from "react";
import { useSelector } from "react-redux";
import { selectUser } from "modules/auth/redux/selectors";
import { selectIsMeBroadcaster } from "modules/broadcaster";
import {
  selectCurrentEventId,
  selectIsEventManager,
  selectIsEventSpeaker,
} from "modules/event/selectors";
import { usePrevious } from "helpers/reactHooksUtils";
import { registerWebSocketHandlers } from "../../components/TranscriptionContext/context/handlers";
import { initWebSocket } from "../../components/TranscriptionContext/context/helpers";
import {
  clearWebSocket,
  clearFinalizedTranscript,
  updateFinalizedTranscript,
  updateToken,
  updateTranscript,
  updateWebSocket,
} from "../../components/TranscriptionContext/context/actions";
import { ITranscriptionAction } from "../../components/TranscriptionContext/context/types";
import {
  selectShowCaptions,
  selectShowLiveCaptions,
} from "../../redux/selectors";

interface TranscriptionSubscriber {
  subscribeToWebSocket: (sampleRate: number) => WebSocket | undefined;
}

const useTranscriptionSubscriber = (
  dispatch: React.Dispatch<ITranscriptionAction> | undefined,
): TranscriptionSubscriber => {
  const { data, status } = useSymblToken();
  const eventId = useSelector(selectCurrentEventId);
  const user = useSelector(selectUser);

  const isMeBroadcaster = useSelector(selectIsMeBroadcaster);
  const isEventSpeaker = useSelector(selectIsEventSpeaker);
  const isEventManager = useSelector(selectIsEventManager);
  const showCaptions = useSelector(selectShowCaptions);
  const showLiveCaptions = useSelector(selectShowLiveCaptions);
  const prevShowCaptions = usePrevious(showCaptions);
  const prevShowLiveCaptions = usePrevious(showLiveCaptions);

  const isSpeaker = isMeBroadcaster || isEventSpeaker || isEventManager;

  const subscribeToWebSocket = React.useCallback<
    (sampleRate: number) => WebSocket | undefined
  >(
    (sampleRate: number) => {
      if (status !== "success" || !data || !eventId || !dispatch || !user) {
        return undefined;
      }

      dispatch(updateToken(data));

      dispatch(clearWebSocket());
      if (!prevShowCaptions && !prevShowLiveCaptions) {
        dispatch(clearFinalizedTranscript());
      }

      const websocket = initWebSocket(data, eventId, isSpeaker);

      dispatch(updateWebSocket(websocket, isSpeaker));
      registerWebSocketHandlers(
        websocket,
        user,
        sampleRate,
        (message) => {
          dispatch(updateTranscript(message));
        },
        (message) => {
          dispatch(updateFinalizedTranscript(message));
        },
        isSpeaker,
      );

      return websocket;
    },
    [
      eventId,
      status,
      data,
      dispatch,
      user,
      isSpeaker,
      prevShowCaptions,
      prevShowLiveCaptions,
    ],
  );
  return { subscribeToWebSocket };
};

export default useTranscriptionSubscriber;
