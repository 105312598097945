import { ChangeEvent } from "react";
import classnames from "classnames";
import { useI18n } from "i18n";
import { Container } from "@remo-co/ui-core/src/components/Container";
import { RadioGroup } from "@remo-co/ui-core/src/components/RadioGroup";
import { Radio } from "@remo-co/ui-core/src/components/Radio";
import { Typography } from "@remo-co/ui-core/src/components/Typography";
import { FormControlLabel } from "@remo-co/ui-core/src/components/FormControlLabel";
import useStyles from "./styles";

export enum UNASSIGN_OPTIONS {
  EVENT = "event",
  FLOOR = "floor",
}

interface Props {
  selectedOption: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
}

export const LockAllTablesDialog = ({
  selectedOption,
  onChange,
}: Props): JSX.Element | null => {
  const { t } = useI18n(["event"]);
  const styles = useStyles();

  return (
    <Container>
      <Typography variant="h5" className={styles.title}>
        {t("manageEvent:unlock.all.tables")}
      </Typography>
      <RadioGroup value={selectedOption} onChange={onChange}>
        <FormControlLabel
          className={classnames({
            [styles.activeOption]: selectedOption === UNASSIGN_OPTIONS.FLOOR,
          })}
          value={UNASSIGN_OPTIONS.FLOOR}
          control={<Radio color="primary" />}
          label={t("event:current.floor")}
        />
        <FormControlLabel
          className={classnames({
            [styles.activeOption]: selectedOption === UNASSIGN_OPTIONS.EVENT,
          })}
          value={UNASSIGN_OPTIONS.EVENT}
          control={<Radio color="primary" />}
          label={t("event:entire.event")}
        />
      </RadioGroup>
    </Container>
  );
};
