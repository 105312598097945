import { Badge } from "@remo-co/ui-core/src/components/Badge";
import { useI18n } from "i18n";
import { useSelector } from "react-redux";
import { ActionButton } from "modules/actionButton";
import { useAppDispatch } from "store/hooks";
import { selectUnreadCount } from "modules/chat/redux/selectors";
import { getNewMessagesCount } from "modules/chat/chatUtils";
import { Chat as ChatIcon } from "@remo-co/ui-core/src/icons/Chat";
import { TABS, selectRightPanelState, setPanelState } from "modules/rightPanel";
import { updateActiveChannel } from "modules/chat/redux/actions";

const ChatButton = (): JSX.Element => {
  const unreadCountMessages = useSelector(selectUnreadCount);
  const { t } = useI18n();
  const dispatch = useAppDispatch();
  const { isOpen, tabId } = useSelector(selectRightPanelState);

  const isButtonActive = isOpen && tabId === TABS.CHAT;

  const handleClick = () => {
    dispatch(
      setPanelState({
        isOpen: !isOpen || (isOpen && tabId !== TABS.CHAT),
        tabId: TABS.CHAT,
      }),
    );
    if (isOpen) {
      dispatch(updateActiveChannel(null));
    }
  };

  const unreadCount = getNewMessagesCount(unreadCountMessages);

  const unreadCountComponent = (
    <span data-testid="unreadCount">{unreadCount}</span>
  );

  const getDDActionName = () => {
    if (isOpen && tabId === TABS.CHAT) {
      return "Chat Button - Close right panel";
    }
    if (isOpen && tabId !== TABS.CHAT) {
      return "Chat Button - Switch to Chat tab";
    }
    return "Chat Button - Open right panel";
  };

  return (
    <ActionButton
      title={`${
        isButtonActive ? t("chat.button.close") : t("chat.button.open")
      }`}
      onClick={handleClick}
      data-dd-action-name={getDDActionName()}
      label={t("chat.button")}
      isActive={isButtonActive}
      icon={
        <Badge
          badgeContent={unreadCount ? unreadCountComponent : 0}
          color="error"
        >
          <ChatIcon />
        </Badge>
      }
    />
  );
};

export default ChatButton;
