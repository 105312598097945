import { Button } from "@remo-co/ui-core/src/components/Button";
import { PersonAdd } from "@remo-co/ui-core/src/icons/PersonAdd";
import { isRoomFull } from "helpers/roomHelper";
import { useI18n } from "i18n";
import logger from "logging/logger";
import { selectUser } from "modules/auth/redux/selectors";
import { DialogType } from "modules/dialogNotification/constants";
import {
  selectCurrentEventCompanyPlan,
  selectCurrentEventId,
} from "modules/event/selectors";
import { makeSelectUserById } from "modules/event/usersInEvent/selectors";
import { useInviteToTable } from "modules/inviteToTable";
import { addWarningNotification } from "modules/notification/redux/notificationSlice";
import { Events, TRACKING_CONTEXT } from "modules/tracking";
import { useContext } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "store/hooks";
import { selectCurrentRoom } from "store/rooms";
import { IRoom } from "types";

interface Props {
  userId: string;
}

export const InviteRecommendedGuest = ({ userId }: Props) => {
  const { t } = useI18n(["common", "eventForm", "conversation", "event"]);
  const userInSpace = useSelector(makeSelectUserById(userId));
  const toRoom = useSelector(selectCurrentRoom);
  const eventCompanyPlan = useSelector(selectCurrentEventCompanyPlan);
  const { inviteToTable } = useInviteToTable();
  const { track } = useContext(TRACKING_CONTEXT);
  const currentEventId = useSelector(selectCurrentEventId);
  const currentUser = useSelector(selectUser);
  const dispatch = useAppDispatch();

  const inviteUserToRoom = (toId: string, room: IRoom) => {
    if (!room) {
      logger.warn("[inviteUserToRoom] No Room");
      return;
    }

    const { isFull } = isRoomFull(room, eventCompanyPlan);

    if (isFull) {
      dispatch(
        addWarningNotification({
          message: t("table.full.message"),
        }),
      );
    } else {
      inviteToTable({
        targetUserId: toId,
        targetTableId: room.id,
        notifyDialogType: DialogType.JOIN_ROOM_NETWORKING,
      });
    }
  };

  const handleInviteUser = () => {
    if (userInSpace && toRoom) {
      track(Events.RECOMMENDED_CONNECTION_INVITE_PARTICIPANT, {
        eventId: currentEventId,
        recommendedUser: userInSpace?.email,
        currentUser: currentUser?.email,
      });
      inviteUserToRoom(userInSpace.id, toRoom);
    }
  };

  return (
    <Button
      variant="text"
      onClick={handleInviteUser}
      data-testid="invite-user-in-connections"
    >
      <PersonAdd fontSize="medium" />
    </Button>
  );
};
