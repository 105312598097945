import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "store";

export const selectAudioVideo = (state: RootState) => state.audioVideo;

export const selectCurrentBackground = createSelector(
  selectAudioVideo,
  (substate) => substate.currentBackground,
);

const selectUserData = createSelector(
  selectAudioVideo,
  (substate) => substate.userData,
);

export const selectConversationId = createSelector(
  selectUserData,
  (userData) => userData.conversationId,
);

export const selectProfileImage = createSelector(
  selectUserData,
  (userData) => userData.image,
);

export const selectOriginalAspectRatio = createSelector(
  selectUserData,
  (userData) => userData.originalAspectRatio,
);

export const selectNetworkQuality = createSelector(
  selectUserData,
  (userData) => userData.networkQuality,
);

export const selectDevicePromptPopupState = createSelector(
  selectAudioVideo,
  (substate) => substate.devicePromptPopup,
);

export const selectLayout = createSelector(
  selectAudioVideo,
  (substate) => substate.layout,
);

export const selectActiveTile = createSelector(
  selectAudioVideo,
  (substate) => substate.activeTile,
);

export const selectTileSelected = createSelector(
  selectAudioVideo,
  (substate) => substate.isTileSelected,
);

export const selectIsFocusLayoutManually = createSelector(
  selectAudioVideo,
  (substate) => substate.isLayoutSetManually,
);

export const selectPresentationSpeakersSessionIds = createSelector(
  selectAudioVideo,
  (substate) => substate.presentationSpeakersSessionIds,
);
