import React from "react";
import "./AgendaItemPublic.scss";
import moment from "moment";
import { useI18n } from "i18n";
import { Button } from "@remo-co/ui-core/src/components/Button";
import { Container } from "@remo-co/ui-core/src/components/Container";
import { Typography } from "@remo-co/ui-core/src/components/Typography";
import { AccessTime } from "@remo-co/ui-core/src/icons/AccessTime";
import { IEvent } from "modules/event/types";
import { IEventAgenda } from "modules/eventAgenda/types";
import { EventAgendaColor } from "../eventAgenda/constants";

interface IAgendaItemProps {
  item: IEventAgenda;
  offset: number;
  event: IEvent;
  nonExpandableText?: boolean;
}
const AgendaItem = ({
  item,
  offset,
  event,
  nonExpandableText,
}: IAgendaItemProps) => {
  const { t } = useI18n();
  const [moreTextVisible, setMoreTextVisible] =
    React.useState(nonExpandableText);
  const { color } = EventAgendaColor[item.color];
  const bgColor = `${color}2E`; // 2E = 0.18 opacity

  const moreClass = moreTextVisible ? "more" : "";

  const handleClickMore = (e: React.MouseEvent) => {
    e.stopPropagation();
    setMoreTextVisible(true);
  };

  if (!item) {
    return null;
  }

  return (
    <div className="agenda-item-public-ctn">
      <div style={{ backgroundColor: bgColor }} className="agenda-item-left">
        <div className="start-time">
          <Typography>
            {moment(event.startTime).add(offset, "minutes").format("hh:mm A")}
          </Typography>
        </div>
        <div className="end-time">
          <Typography>
            {moment(event.startTime)
              .add(offset + item.duration, "minutes")
              .format("hh:mm A")}
          </Typography>
        </div>
        <div className="duration">
          <AccessTime />{" "}
          <Typography>
            {t("minutes.time.duration", {
              key: item.duration,
            })}
          </Typography>
        </div>
      </div>
      <div style={{ backgroundColor: color }} className="separator" />
      <div className="agenda-item-right">
        <Typography variant="h4">{item.title}</Typography>
        <div className={`item-text ${moreClass}`}>
          <Typography variant="body1">{item.description}</Typography>
        </div>
        {!moreTextVisible &&
          item.description &&
          item.description.length > 100 && (
            <Container fullWidth flex justifyContent="flex-end">
              <Button
                size="sm"
                variant="secondary"
                color="blue"
                onClick={handleClickMore}
              >
                {t("button.see.more")}
              </Button>
            </Container>
          )}
      </div>
    </div>
  );
};

export default AgendaItem;
