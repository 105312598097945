import PlayerStates from "youtube-player/dist/constants/PlayerStates";
import logger from "logging/logger";
import {
  VIDEO_ALLOWED_DELAY_SECONDS,
  VIDEO_ENDING_SOON_BUFFER_SECONDS,
  VIDEO_JUST_STARTED_BUFFER_SECONDS,
  VIDEO_MAX_SYNC_COUNT_PER_MINUTE,
} from "modules/broadcast/redux/constants";
import { Events, TRACKING_CONTEXT } from "modules/tracking";
import { Actions, trackAction } from "modules/monitoring";
import { useContext } from "react";

interface IIsVideoSeekableParams {
  timeCurrent: number;
  duration: number;
  timeElapsed: number;
  playerState: PlayerStates;
  recentSeeks: number[];
}

interface IIsNotAggresivelySyncingParams {
  recentSeeks: number[];
}

export const useVideoPlayerActions = () => {
  const { track } = useContext(TRACKING_CONTEXT);

  // Check if user has not been forced to seek a lot in the last minute
  const isNotAggresivelySyncing = ({
    recentSeeks,
  }: IIsNotAggresivelySyncingParams) => {
    const potentialRecentSeeks = [...recentSeeks];
    const seekDifferencesForLogging: number[] = [];

    // Also add current time as we need to check if 'right now' is seekable
    // We will add the current time (Date.now()) eventually if we actually need a seek, but this check is just for pre-verification
    potentialRecentSeeks.push(Date.now());

    // Not reached as many seeks yet, return happy path
    if (potentialRecentSeeks.length < VIDEO_MAX_SYNC_COUNT_PER_MINUTE) {
      logger.info("[useVideoPlayerActions][isNotAggresivelySyncing]", {
        maxSyncAllowedPerMinute: VIDEO_MAX_SYNC_COUNT_PER_MINUTE,
        numberOfSeeksSofar: potentialRecentSeeks.length,
        potentialRecentSeeks,
        isNotAggresivelySyncing: true,
      });

      return true;
    }

    const latestFewSeeks = potentialRecentSeeks.slice(
      -VIDEO_MAX_SYNC_COUNT_PER_MINUTE,
    );

    const recentSeeksTotalTimeInSeconds =
      Math.abs(latestFewSeeks[0] - latestFewSeeks[latestFewSeeks.length - 1]) /
      1000;

    logger.info("[useVideoPlayerActions][isNotAggresivelySyncing]", {
      maxSyncAllowedPerMinute: VIDEO_MAX_SYNC_COUNT_PER_MINUTE,
      lastSyncedSecondsAgo: seekDifferencesForLogging,
      recentSeeksTotalTimeInSeconds,
      isNotAggresivelySyncing: recentSeeksTotalTimeInSeconds >= 60,
    });

    return recentSeeksTotalTimeInSeconds >= 60;
  };

  const isVideoSeekable = ({
    duration,
    timeCurrent,
    timeElapsed,
    playerState,
    recentSeeks,
  }: IIsVideoSeekableParams) => {
    logger.info("[useVideoPlayerActions][isVideoSeekable] inputs", {
      duration,
      timeCurrent,
      timeElapsed,
      playerState,
      recentSeeks,
    });
    const delay = Math.abs(timeElapsed - timeCurrent);
    const isVideoPlaying = playerState === PlayerStates.PLAYING;
    const isPlayingFirstTime = timeElapsed < duration;
    const isNotJustStarted = timeElapsed > VIDEO_JUST_STARTED_BUFFER_SECONDS;
    const isNotEndingSoon =
      timeElapsed < duration - VIDEO_ENDING_SOON_BUFFER_SECONDS;
    const isHugeDelay = delay > VIDEO_ALLOWED_DELAY_SECONDS;
    let isNotTooManySeeks = true;
    let result =
      isVideoPlaying &&
      isPlayingFirstTime &&
      isNotJustStarted &&
      isNotEndingSoon &&
      isHugeDelay;

    // We need to seek, then let's also check how much we have already seeked
    // One more condition only if needed
    if (result) {
      isNotTooManySeeks = isNotAggresivelySyncing({ recentSeeks });
      result = result && isNotTooManySeeks;
    }

    // Log for scenarios where we had to seek a lot of times
    if (!isNotTooManySeeks) {
      const logData = {
        recentSeeks: recentSeeks.map((seek) => new Date(seek)),
      };

      logger.info("[useVideoPlayerActions][useEffect] Too many seeks", logData);
      track(Events.SHARE_VIDEO_DELAY_MANY_SEEKS, logData);
      trackAction(Actions.SHARE_VIDEO_DELAY_MANY_SEEKS, logData);
    }

    const aheadBySeconds = result ? timeCurrent - timeElapsed : null;

    return {
      shouldSeek: result,
      aheadBySeconds,
    };
  };

  return {
    isVideoSeekable,
  };
};

export default useVideoPlayerActions;
