import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";

export const useStyles = makeStyles((theme) => ({
  inputFields: {
    paddingTop: "1.5rem",
  },
  eventTypeRadio: {
    paddingTop: "0.75rem",
  },
  startTime: {
    flex: "1",
    paddingRight: "1.25rem",
  },
  endTime: {
    flex: "1",
  },
  dateTimePickerRow: {
    display: "flex",
  },
  dateTimePicker: {
    paddingBottom: "0",
    marginTop: "5px",
    width: "100%",
    "& fieldset": {
      border: `1px solid ${theme.palette.blue.darkShade1}`,
    },
    "& input": {
      padding: "10px",
    },
  },
  eventImageContainer: {
    maxWidth: "400px",
    marginTop: "2.5rem",
    paddingBottom: "1rem",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    alignItems: "center",
    background: `${theme.palette.white} 0% 0% no-repeat padding-box`,
    boxShadow: "-2px 2px 20px #0000001a",
    borderRadius: "10px",
  },
  eventImage: {
    width: "100%",
    flex: "1 0 100%",
    maxWidth: "780px",
    borderRadius: "10px 10px 0 0",
  },
  uploader: {
    flex: "1 0 45%",
    maxWidth: "45%",
    width: "120px",
    textAlign: "left",
    marginTop: "1.25rem",
    marginLeft: "1.25rem",
  },
  uploadCaption: {
    flex: "1 0 45%",
    width: "120px",
    textAlign: "left",
    marginTop: "1.25rem",
    marginLeft: "1.25rem",
    maxWidth: "120px",
    marginRight: "1.25rem",
  },
}));

export default useStyles;
