import { BroadcasterUser } from "../types";
import ActionTypes from "./constants";

const updateBroadcasters = (
  broadcasters: Partial<Record<string, BroadcasterUser>>,
) => ({
  payload: { broadcasters },
  type: ActionTypes.UPDATE_BROADCASTERS,
});

const reset = () => ({
  type: ActionTypes.RESET,
});

export default {
  updateBroadcasters,
  reset,
};
