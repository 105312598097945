import {
  PROCEED_TO_EVENT_QUERY_PARAMETER,
  PROCEED_TO_EVENT_QUERY_PARAMETER_VALUE,
} from "modules/eventLanding/constants";
import { ParsedQuery } from "query-string";

export const hasAutoJoin = (params: ParsedQuery<string>): boolean =>
  params.autojoin === "1";

export const hasAnonLogin = (params: ParsedQuery<string>): boolean =>
  params.anon_login === "1";

export const hasAuthenticated = (params: ParsedQuery<string>): boolean =>
  params.authState === "authenticated";

export const hasShouldProceedToEvent = (params: ParsedQuery<string>): boolean =>
  params[PROCEED_TO_EVENT_QUERY_PARAMETER] ===
  PROCEED_TO_EVENT_QUERY_PARAMETER_VALUE;

export const parseEventCode = (
  params: ParsedQuery<string | null>,
): string | null => {
  const queryParam = params.event;
  if (!queryParam) {
    return null;
  }
  if (Array.isArray(queryParam)) {
    const [eventCode] = queryParam;
    return eventCode;
  }
  return queryParam;
};

export const getDirectLinkToTable = (
  params: ParsedQuery<string>,
): { tableId?: string; floorId?: string } => {
  const { table, floor } = params;
  if (Array.isArray(table) || Array.isArray(floor)) {
    return { tableId: undefined, floorId: undefined };
  }
  if (table === null || floor === null) {
    return { tableId: undefined, floorId: undefined };
  }
  return { tableId: table, floorId: floor };
};
