import { createSelector } from "@reduxjs/toolkit";
import { selectUserId } from "modules/auth/redux/selectors";
import { selectCurrentRoomId } from "modules/room/redux/selectors";
import { IRoom } from "types";
import { RootState } from "../index";
import { JoinRoomStatus } from "./roomsSlice";

export const selectRoomsState = (state: RootState) => state.rooms;

// current room
// Needed for accessing this in some firebase functions
const selectCurrentRoom = createSelector(
  selectCurrentRoomId,
  selectRoomsState,
  (currentRoomId, { rooms }) => {
    if (
      currentRoomId &&
      rooms[currentRoomId] &&
      rooms[currentRoomId].name !== undefined
    ) {
      return rooms[currentRoomId];
    }

    return null;
  },
);

const selectJoiningRoomId = createSelector(
  selectRoomsState,
  (substate) => substate?.joiningRoomId,
);

const selectJoiningRoom = createSelector(selectRoomsState, (substate) => {
  const room = substate?.rooms[substate?.joiningRoomId || ""];
  if (room) {
    return room;
  }

  return null;
});

const selectIsChangingRoom = createSelector(
  selectRoomsState,
  (substate) => substate.status === JoinRoomStatus.IN_PROGRESS,
);

const createSelectIsJoiningRoomById = (roomId: string) =>
  createSelector(
    selectJoiningRoomId,
    (joiningRoomId) => joiningRoomId === roomId,
  );

const selectRooms = createSelector(
  selectRoomsState,
  (substate) => substate.rooms,
);

const selectRoomArray = createSelector(selectRoomsState, (substate) =>
  Object.keys(substate.rooms),
);

// eslint-disable-next-line consistent-return
const selectStage = createSelector(selectRoomsState, ({ rooms }) => {
  // eslint-disable-next-line no-restricted-syntax
  for (const id in rooms) {
    if (
      Object.prototype.hasOwnProperty.call(rooms, id) &&
      rooms[id].code === "stage"
    ) {
      return rooms[id];
    }
  }
});

const selectRoomStatus = createSelector(
  selectRoomsState,
  (substate) => substate.status,
);

export const createIsUserChangingRoomByUserId = (userId: string) =>
  createSelector(
    selectRoomStatus,
    selectUserId,
    (status, currentUserId) =>
      userId === currentUserId && status === JoinRoomStatus.IN_PROGRESS,
  );

export const createSelectRoomById = (roomId: string) =>
  createSelector(selectRooms, (rooms) => rooms[roomId]);

export const createSelectIsRoomOccupiedByCurrentUserByRoomId = (
  roomId: string,
) =>
  createSelector(
    selectCurrentRoomId,
    (currentRoomId) => currentRoomId === roomId,
  );

export const selectMaxSeatCountForEvent = createSelector(
  selectRoomsState,
  (rooms): number =>
    Object.values(rooms.rooms).reduce((mostSeats: number, room: IRoom) => {
      const numberOfSeats =
        room.positions.length + (room?.adminSeats?.length ?? 0);
      if (numberOfSeats > mostSeats) {
        return numberOfSeats;
      }

      return mostSeats;
    }, 0),
);

export {
  selectCurrentRoomId,
  selectCurrentRoom,
  selectJoiningRoomId,
  createSelectIsJoiningRoomById,
  selectRooms,
  selectStage,
  selectRoomStatus,
  selectIsChangingRoom,
  selectJoiningRoom,
  selectRoomArray,
};
