import RoomMiroBoard from "modules/roomWhiteboard/RoomMiroBoard";
import Screen from "modules/audioVideo/components/Tile/Screen/Screen";
import { FullScreenContentDialog } from "modules/eventContent";
import BroadcastMiroBoard from "modules/broadcastWhiteboard/BroadcastMiroWhiteBoard";
import { PresentContentWindow } from "modules/eventEmbedContent";
import ExternalVideoDisplayItem from "modules/broadcastVideoView/ExternalVideoDisplayItem";
import { LocalVideo } from "../../Tile/Video/LocalVideo";
import { RemoteVideo } from "../../Tile/Video/RemoteVideo";
import { DisplayItem, TileDraft } from "../Layouts/types";

export const TileSelector = ({
  tile,
  isInBroadcast,
}: {
  tile: TileDraft;
  isInBroadcast: boolean;
}) => {
  switch (tile.type) {
    case DisplayItem.camera: {
      return tile.local ? (
        <LocalVideo isClickable />
      ) : (
        <RemoteVideo isClickable id={tile.streamId} />
      );
    }
    case DisplayItem.screenShare: {
      return <Screen isClickable id={tile.streamId} />;
    }
    case DisplayItem.whiteboard: {
      return isInBroadcast ? <BroadcastMiroBoard /> : <RoomMiroBoard />;
    }
    case DisplayItem.eventContent: {
      return <FullScreenContentDialog />;
    }
    case DisplayItem.presentContent: {
      return <PresentContentWindow />;
    }
    case DisplayItem.externalVideo: {
      return <ExternalVideoDisplayItem />;
    }
    default: {
      throw new Error("unrecognized tile");
    }
  }
};
