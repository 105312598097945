import { useTranscription } from "@daily-co/daily-react";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { Errors, trackError } from "modules/monitoring";
import logger from "logging/logger";
import { useAppDispatch } from "store/hooks";
import { DEFAULT_TRANSCRIPTION_CONFIG } from "../../constants";
import { selectIsTranscriptionActive } from "../../redux/selectors";
import { setTranscriptions } from "../../redux";

export const DailyTranscription = () => {
  const dispatch = useAppDispatch();
  const isActive = useSelector(selectIsTranscriptionActive);

  const { startTranscription, stopTranscription, transcriptions } =
    useTranscription({
      onTranscriptionError({ errorMsg, callClientId }) {
        logger.error("[transcription-v2] Daily transcription error:", {
          errorMsg,
        });
        trackError(errorMsg, {
          callClientId,
          label: Errors.TRANSCRIPTION_V2,
        });
      },
    });

  useEffect(() => {
    dispatch(setTranscriptions(transcriptions));
  }, [dispatch, transcriptions]);

  useEffect(() => {
    if (isActive) {
      startTranscription(DEFAULT_TRANSCRIPTION_CONFIG);
    } else {
      stopTranscription();
    }
  }, [isActive, startTranscription, stopTranscription]);

  return null;
};
