import { useI18n } from "i18n";
import { Button } from "@remo-co/ui-core/src/components/Button";
import { useCallback } from "react";
import { useAppDispatch } from "store/hooks";
import { setIsLoading } from "modules/helpChat";
import { Close as CloseIcon } from "@remo-co/ui-core/src/icons/Close";
import { Loader } from "@remo-co/ui-core/src/components/Loader";
import { useSelector } from "react-redux";
import { selectEnableAgencyWhiteLabelling } from "modules/settings/redux/selectors";
import { useStyles } from "./styles";

const LoadingChat = () => {
  const enableAgencyWhiteLabelling = useSelector(
    selectEnableAgencyWhiteLabelling,
  );
  const styles = useStyles();
  const { t } = useI18n("common");
  const dispatch = useAppDispatch();

  const handleClose = useCallback(() => {
    dispatch(setIsLoading(false));
  }, [dispatch]);

  return (
    <div className={styles.container}>
      <Button
        size="xs"
        variant="ghost"
        onClick={handleClose}
        className={styles.closeButton}
      >
        <CloseIcon />
      </Button>
      <div className={styles.logo}>
        <Loader />
      </div>
      <div>{t("hang.on")}</div>
      {!enableAgencyWhiteLabelling ? (
        <div>{t("we.re.connecting.you.to.support")}</div>
      ) : (
        <div>{t("we.re.connecting.you.to.support.white.label")}</div>
      )}
    </div>
  );
};

export default LoadingChat;
