import { NetworkingUserDetails } from "modules/networkingRecommendations/redux/slice";
import { ICustomRegistrationQuestion } from "modules/registration/types";
import { sendGetRequest, sendPostRequest } from "services/apiService";

export const networkRecommendationsRequestURL = (eventId: string) =>
  `/event/${eventId}/networking-recommendations`;

export const updateNetworkingRecommendation = async (
  eventId: string,
  isNetworkingRecommendationsEnabled: boolean,
): Promise<ICustomRegistrationQuestion[]> => {
  const resp = await sendPostRequest<
    {
      isNetworkingRecommendationsEnabled: boolean;
    },
    APIResponse<{
      questions: ICustomRegistrationQuestion[];
    }>
  >(networkRecommendationsRequestURL(eventId), {
    isNetworkingRecommendationsEnabled,
  });

  if (!resp.isSuccess) {
    throw new Error(resp?.message);
  }

  return resp.questions;
};

export const getNetworkingRecommendation = async (
  eventId: string,
): Promise<Array<Record<string, NetworkingUserDetails>>> => {
  const resp = await sendGetRequest<
    APIResponse<{
      data: Array<Record<string, NetworkingUserDetails>>;
    }>
  >(networkRecommendationsRequestURL(eventId));

  if (!resp.isSuccess) {
    throw new Error(
      resp?.message || "Failed to fetch networking recommendations",
    );
  }

  return resp.data;
};
