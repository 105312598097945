import { Theme } from "@remo-co/ui-core/src/types";

import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";

export const useStyles = makeStyles<Theme>((theme) => ({
  whiteboardContainer: {
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "space-between",
    "& iframe": {
      border: "none",
      marginRight: "4px",
    },
  },
  tryAgainButton: {
    marginTop: 15,
  },
  progressIndicator: {
    textAlign: "center",
  },
  whiteboardFabButton: {
    zIndex: 9,
    backgroundColor: "white",
    boxShadow: "1px 1px 4px -1px rgba(0, 0, 0, 0.2)",
    "&:hover": {
      color: theme.palette.blue.main,
    },
  },
  buttonBox: {
    display: "flex",
    flexDirection: "column",
    marginTop: 35,
  },

  closeButton: {
    marginBottom: 10,
  },
}));
