import { Theme } from "@remo-co/ui-core/src/types";
import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";
import zIndexes from "zIndexes.module.scss";

export const useStyles = makeStyles<
  Theme,
  {
    backgroundColor: string;
    percentage: number;
  }
>((_theme) => ({
  votesGraph: ({ backgroundColor, percentage }) => ({
    backgroundColor,
    height: "100%",
    left: 0,
    position: "absolute",
    top: 0,
    transition: "all 0.7s ease-out",
    zIndex: 0,
    width: `${percentage}%`,
  }),
  pollAnswerValuePercentage: {
    display: "flex",
    whiteSpace: "nowrap",
    alignItems: "flex-end",
    flexDirection: "column",
    zIndex: zIndexes.pollsPercentageBarZIndex,
  },
}));
