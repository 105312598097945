import { useEffect, useMemo, useState } from "react";
import { useDaily, useLocalSessionId } from "@daily-co/daily-react";
import { useSelector } from "react-redux";
import { selectConversationBroadcastSessionId } from "modules/conversationBroadcast/redux/selectors";

export const useParticipantSubscription = ({
  participants,
}: {
  participants: string[];
}) => {
  const daily = useDaily();
  const localSessionId = useLocalSessionId();
  const [subscribedParticipants, setSubscribedParticipants] = useState<
    string[]
  >([]);
  const conversationBroadcastSession = useSelector(
    selectConversationBroadcastSessionId,
  );
  const remoteParticipants = useMemo(
    () =>
      participants.filter((participantId) => participantId !== localSessionId),
    [localSessionId, participants],
  );

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (!daily || !localSessionId) {
      return undefined;
    }

    const participantsQueuedForSub = remoteParticipants.filter(
      (participantId) =>
        !subscribedParticipants.includes(participantId) ||
        participantId === conversationBroadcastSession,
    );
    participantsQueuedForSub.forEach((participant) => {
      daily.updateParticipant(participant, { setSubscribedTracks: true });
    });

    const participantsQueuedForUnsub = subscribedParticipants.filter(
      (participantId) =>
        !remoteParticipants.includes(participantId) &&
        participantId !== conversationBroadcastSession,
    );
    participantsQueuedForUnsub.forEach((participant) => {
      daily.updateParticipant(participant, { setSubscribedTracks: false });
    });

    setSubscribedParticipants([
      ...subscribedParticipants.filter(
        (subscribedParticipant) =>
          !participantsQueuedForUnsub.includes(subscribedParticipant),
      ),
      ...participantsQueuedForSub,
    ]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [remoteParticipants, daily, localSessionId]);
};
