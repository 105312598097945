import { Theme } from "@remo-co/ui-core/src/types";
import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";
import zIndexes from "zIndexes.module.scss";

export const useStyles = makeStyles<Theme>((theme) => ({
  popper: {
    zIndex: zIndexes.modalZIndex,
    width: 170,
  },
  popoverContainer: {
    overflow: "hidden",

    "&::after": {
      content: "",
      position: "absolute",
      left: "50%",
      transform: "translateX(-50%)",
      width: 0,
      height: 0,
      borderStyle: "solid",
      borderWidth: "10px 10px 0 10px",
      borderColor: `${theme.palette.white} transparent transparent transparent`,
    },
  },
  popoverItem: {
    padding: 0,
  },
}));
