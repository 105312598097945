import { useI18n } from "i18n";
import PresentationDisplayItemTitle from "modules/broadcastVideoView/PresentationDisplayItemTitle";
import { DisplayItem } from "../DailyContainer/Layouts/types";

export const SharedScreenText = ({
  hasEventManagerAccess,
  tileType,
}: {
  hasEventManagerAccess: boolean;
  tileType: DisplayItem;
}): JSX.Element | null => {
  const { t } = useI18n();

  const getScreenText = () => {
    switch (tileType) {
      case DisplayItem.whiteboard: {
        return t("user.shared.whiteboard");
      }
      case DisplayItem.presentContent: {
        return t("user.shared.content");
      }
      case DisplayItem.externalVideo: {
        return t("user.shared.video");
      }
      default: {
        return null;
      }
    }
  };

  const screenText = getScreenText();

  if (!screenText) {
    return null;
  }

  return (
    <PresentationDisplayItemTitle hostView={hasEventManagerAccess}>
      {screenText}
    </PresentationDisplayItemTitle>
  );
};
