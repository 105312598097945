import { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { usePrevious } from "helpers/reactHooksUtils";
import { selectCurrentEvent } from "modules/event/selectors";
import { selectUser } from "modules/auth/redux/selectors";
import { selectCurrentRoom } from "store/rooms";
import logger from "logging/logger";
import { useChatActions } from "../useChatActions";

export const useManageChatRoom = () => {
  const user = useSelector(selectUser);
  const event = useSelector(selectCurrentEvent);
  const isLoaded = useRef(false);
  const currentRoom = useSelector(selectCurrentRoom);
  const prevRoom = usePrevious(currentRoom);
  const { doOnLoad, handleRoomChange, leaveRoom, initRoomChat } =
    useChatActions();

  useEffect(() => {
    if (user && event && !isLoaded.current) {
      try {
        doOnLoad();
        isLoaded.current = true;
      } catch (e) {
        isLoaded.current = false;
        logger.error(
          "[ChatContainer] Chat initialization failed, clearing up condition to allow reconnection",
          {
            user,
            event,
          },
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, event]);

  useEffect(() => {
    if (currentRoom && !prevRoom) {
      initRoomChat(currentRoom);
      return;
    }

    if (currentRoom && prevRoom) {
      handleRoomChange(currentRoom, prevRoom);
      return;
    }

    if (!currentRoom && prevRoom) {
      // If switching space, leave room from current floor before subscribing to new floor room
      leaveRoom(prevRoom.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentRoom]);
};
