import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";

export const useStyles = makeStyles(() => ({
  media: {
    objectFit: "contain",
    height: "100%",
    minHeight: "394px",
  },
  image: {
    width: "100%",
    margin: "auto",
  },
  imageWrapper: {
    width: "100%",
    minHeight: "394px",
  },
}));
