import { Ref, forwardRef } from "react";
import { Typography } from "@remo-co/ui-core/src/components/Typography";
import { useSelector } from "react-redux";
import { selectIsMacOS } from "modules/systemCheck/redux/selectors";
import { useStyles } from "./styles";

interface Props {
  title: string;
  hotKey?: string;
}

const HOT_KEY_REGEX = /\s*\+\s*/g;

const osKeyNotation = (hotKey: string, isMacOs: boolean) => {
  if (isMacOs) {
    return hotKey
      .replace("alt", "Option")
      .replace("ctrl", "Command")
      .replace(HOT_KEY_REGEX, " + ");
  }

  return hotKey.replace(HOT_KEY_REGEX, " + ");
};

const ActionButtonTitle = forwardRef((props: Props, ref?: Ref<HTMLElement>) => {
  const { title, hotKey } = props;
  const isMac = useSelector(selectIsMacOS);
  const styles = useStyles();

  if (!hotKey) {
    return (
      <Typography id="action-button-title" ref={ref} variant="inherit">
        {title}
      </Typography>
    );
  }

  return (
    <Typography id="action-button-title" ref={ref} variant="inherit">
      {title}
      &nbsp;
      <Typography display="inline" variant="inherit" className={styles.hotkey}>
        ({osKeyNotation(hotKey, isMac)})
      </Typography>
    </Typography>
  );
});

export default ActionButtonTitle;
