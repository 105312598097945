import { ReactNode } from "react";
import { lightTheme, darkTheme, RemoTheme } from "@remo-co/ui-core/theme";
import {
  ThemeProvider,
  Theme,
  StyledEngineProvider,
} from "@mui/material/styles";

declare module "@mui/styles/defaultTheme" {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

export interface IProviderProps {
  children?: ReactNode;
  theme?: RemoTheme;
}

const Provider = ({ children, theme = RemoTheme.light }: IProviderProps) => (
  <StyledEngineProvider injectFirst>
    <ThemeProvider theme={theme === RemoTheme.light ? lightTheme : darkTheme}>
      {children}
    </ThemeProvider>
  </StyledEngineProvider>
);

export default Provider;
