import { Theme } from "@remo-co/ui-core/src/types";
import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";
import { MiniMapTableProps } from "./MiniMapTable";

export const useTableStyles = makeStyles<
  Theme,
  Pick<MiniMapTableProps, "x" | "y" | "width" | "height" | "scale">
>(() => ({
  root: ({ x, y, width, height, scale }) => ({
    position: "absolute",
    zIndex: 10,
    top: y * scale,
    left: x * scale,
    width: width * scale,
    height: height * scale,
    border: "2px solid #FAFF00",
    backgroundColor: `rgba(250, 255, 0, ${0.5})`,
  }),
  hidden: {
    display: "none",
  },
}));

export const useStyles = makeStyles<Theme>(() => ({
  root: {
    width: "100%",
    height: "100%",
    position: "relative",
    overflow: "hidden",
    maxHeight: 200,
  },
  miniMapContainer: {
    position: "relative",
  },
  center: {
    top: 0,
    position: "absolute",
    height: "100%",
    width: "100%",
  },
  map: {
    width: "100%",
    height: "100%",
    objectFit: "contain",
    filter: "brightness(0.5)",
  },
}));
