import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";

export const useStyles = makeStyles((theme) => ({
  root: {
    width: theme.spacing(62.625),
    borderRadius: theme.spacing(2.5),
    padding: `${theme.spacing(1.25)} 0`,
  },
  header: {
    padding: `${theme.spacing(1)} ${theme.spacing(3)}`,
  },
  closeButton: {
    position: "absolute",
    top: theme.spacing(2),
    right: theme.spacing(4),
  },
  dialogDescription: {
    marginTop: theme.spacing(2),
  },
  actions: {
    justifyContent: "space-between",
    padding: `${theme.spacing(0.5)} ${theme.spacing(2.5)}`,
  },
  button: {
    width: theme.spacing(25),
    height: theme.spacing(4),
    margin: theme.spacing(1),
    fontSize: theme.spacing(1.75),
  },
}));
