import { FLOOR_PLAN_THEMES } from "modules/customFloorPlan";
import { IMapTemplate } from "types/theater";
import { ConfigRoomNames } from "modules/customFloorPlan/constants";
import { IRoom } from "types";

export const isClassicTheme = (theme: string): boolean =>
  theme !== FLOOR_PLAN_THEMES.REALISTIC &&
  theme !== FLOOR_PLAN_THEMES.ISOMETRIC &&
  theme !== FLOOR_PLAN_THEMES.MODERN;

export const getDefaultTheme = (template?: IMapTemplate): FLOOR_PLAN_THEMES =>
  template && !isClassicTheme(template.floorPlanType)
    ? FLOOR_PLAN_THEMES[
        template.floorPlanType as keyof typeof FLOOR_PLAN_THEMES
      ]
    : FLOOR_PLAN_THEMES.DEFAULT;

interface TableSeatCountProps {
  template: IMapTemplate;
  setShowSeatWarning?: (showWarning: boolean) => void;
  maxInTable?: number;
}

export const validateTableSeatCount = ({
  template,
  maxInTable,
  setShowSeatWarning,
}: TableSeatCountProps) => {
  const configRooms: string[] = Object.values(ConfigRoomNames);

  const byNonConfigRooms = (room: IRoom) => !configRooms.includes(room.name);
  const hasMoreSeatsThanAllowed = (room: IRoom) =>
    typeof maxInTable !== "undefined" && room.positions.length > maxInTable;

  const exceedsSeatCap = template.rooms
    .filter(byNonConfigRooms)
    .some(hasMoreSeatsThanAllowed);

  const maxSeats = Math.max(
    ...template.rooms.map((room) => room.positions.length),
  );

  // TODO: refactor and remove these
  setShowSeatWarning?.(exceedsSeatCap);

  return { exceedsSeatCap, maxSeats };
};
