import { sendGetRequest } from "services/apiService";
import { ICustomRegistrationAnswer } from "../../types";

export const getRequestURL = (eventId?: string) => `/event/${eventId}/answers`;

export const getEventRegistrationAnswers = async (
  eventId?: string,
): Promise<ICustomRegistrationAnswer[]> => {
  const resp = await sendGetRequest<
    APIResponse<{
      answers: ICustomRegistrationAnswer[];
    }>
  >(getRequestURL(eventId));

  if (!resp.isSuccess) {
    throw new Error(resp?.message);
  }

  return resp.answers;
};
