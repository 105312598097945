import { Theme } from "@remo-co/ui-core/src/types";
import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";

const useStyles = makeStyles<Theme>((theme) => ({
  input: {
    backgroundColor: "var(--White-op-010)",
    color: theme.palette.text.primary,
    margin: "8px 16px",
    padding: "10px",
    width: "calc(100% - 32px)",
    borderRadius: "26px",
    height: "40px",
    "& .MuiInputAdornment-root": {
      marginLeft: "10px",
      color: "var(--Grey)",
    },
    "& > div": {
      height: "100%",
    },
    "& fieldset": {
      borderRadius: "20px",
    },
  },
}));

export default useStyles;
