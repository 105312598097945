import { memo, ReactNode } from "react";
import classNames from "classnames";
import { useSelector } from "react-redux";
import { useAppDispatch } from "store/hooks";
import { selectIsInBroadcast } from "modules/broadcast/redux/selectors";
import { CircularProgress } from "@remo-co/ui-core/src/components/CircularProgress";
import { MicOff } from "@remo-co/ui-core/src/icons/MicOff";
import { NetworkQuality } from "modules/audioVideo/types";
import { TileEmoji } from "modules/emoji/ConversationEmoji/TileEmoji";
import {
  setLayout,
  setActiveTile,
  setIsTileSelected,
} from "modules/audioVideo/redux/slice";
import { ConversationEmoji as ConversationEmojiType } from "modules/emoji/redux";
import { useShowReaction } from "modules/emoji/ConversationEmoji/hooks/useShowReaction";
import { useStyles } from "./styles";
import { MuteGuestButton } from "../MuteGuestButton";
import { NetworkQualityIndicator } from "../NetworkQualityIndicator";
import { DisplayLayout, TileDraft } from "../../DailyContainer/Layouts/types";

interface Props {
  showMuteButton?: boolean;
  hasAudio?: boolean;
  videoType?: string;
  networkQuality?: NetworkQuality | null;
  tile?: TileDraft;
  originalAspectRatio?: boolean | null;
  isTalking?: boolean;
  isLocal?: boolean;
  isClickable?: boolean;
  isInterrupted?: boolean;
  loading?: boolean;
  children: ReactNode;
  emoji?: ConversationEmojiType;
}

const BaseTile = ({
  showMuteButton,
  hasAudio,
  tile,
  isTalking,
  isLocal,
  videoType,
  networkQuality = "good",
  originalAspectRatio,
  children,
  loading = false,
  isClickable = true,
  isInterrupted = false,
  emoji,
}: Props) => {
  const isInBroadcast = useSelector(selectIsInBroadcast);
  const showMuteIcon = videoType !== "screen" && !hasAudio;

  const dispatch = useAppDispatch();

  const { showReaction } = useShowReaction({ emoji });

  const styles = useStyles({
    showMuteIcon,
    networkQuality,
    isLocal,
    showReaction,
    isInterrupted,
  });

  const shouldShowIsTalkingAnimation = isTalking && !isInBroadcast;

  return (
    <div
      className={classNames(styles.root, {
        [styles.original]: originalAspectRatio || videoType === "screen",
        [styles.isTalking]: shouldShowIsTalkingAnimation,
      })}
      data-testid="base-tile"
    >
      {loading && (
        <div data-testid="video-tile-loading" className={styles.loading}>
          <CircularProgress />
        </div>
      )}
      {showReaction && <TileEmoji emoji={emoji} />}

      <div className={styles.tileBackground}>
        <button
          data-testid={`video-tile-${tile?.streamId}`}
          className={styles.clickable}
          type="button"
          onClick={() => {
            if (!tile || !isClickable) {
              return;
            }

            dispatch(setLayout(DisplayLayout.Focus));
            dispatch(setActiveTile(tile));
            dispatch(setIsTileSelected(true));
          }}
        >
          {children}
        </button>
      </div>
      {showMuteButton && (
        <MuteGuestButton
          className={styles.muteGuestButton}
          isMuted={showMuteIcon}
          streamId={tile?.streamId}
        />
      )}
      <div className={styles.bottomLeftIconsContainer}>
        <div className={styles.networkQuality}>
          <NetworkQualityIndicator
            networkQuality={networkQuality}
            isInterrupted={isInterrupted}
            isLocal={Boolean(isLocal)}
          />
        </div>
        {!loading && (
          <MicOff data-testid="muted-audio-icon" className={styles.muted} />
        )}
      </div>
    </div>
  );
};

export default memo(BaseTile);
