import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";

export const useStyles = makeStyles(() => ({
  boardOverlayHorizontal: {
    position: "absolute",
    top: 0,
    left: 0,
    right: "2rem",
    bottom: "4rem",
    zIndex: 100,
  },

  boardOverlayVertical: {
    position: "absolute",
    top: "2rem",
    bottom: "4rem",
    left: 0,
    right: 0,
    zIndex: 100,
  },
  overlayRightSpacing: {
    right: "12rem",
    bottom: 0,
  },
}));
