const HotKeys = {
  toggleMicrophone: {
    keyName: "alt+c",
    getName: (isMacOS?: boolean): string =>
      isMacOS ? "Option + c" : "Alt + c",
  },
  toggleCamera: {
    keyName: "alt+v",
    getName: (isMacOS?: boolean): string =>
      isMacOS ? "Option + v" : "Alt + v",
  },
  toggleScreen: {
    keyName: "alt+s",
    getName: (isMacOS?: boolean): string =>
      isMacOS ? "Option + s" : "Alt + s",
  },
};

export default HotKeys;
