import { useCallback, useMemo, useState } from "react";
import {
  useDaily,
  useLocalSessionId,
  useParticipantProperty,
} from "@daily-co/daily-react";
import logger from "logging/logger";
import { useAppDispatch } from "store/hooks";
import { setCurrentBackground } from "modules/audioVideo/redux/slice";
import { useSelector } from "react-redux";
import { selectCurrentBackground } from "modules/audioVideo/redux/selectors";
import { Errors, trackError } from "modules/monitoring";
import DailyIframe from "@daily-co/daily-js";
import { VirtualBackgrounds } from "modules/audioVideo/constants";
import { useIsInEvent } from "modules/event";
import useNotificationActions from "modules/notification/hooks/useNotificationActions";
import { useI18n } from "i18n";
import { parseRequest } from "./processor";
import { VirtualBackgroundRequest } from "../../types";
import { useDevices } from "../useDevices";
import { VIRTUAL_BACKGROUND_CACHE_KEY } from "./constants";

export const useVirtualBackground = () => {
  const [isLoading, setIsLoading] = useState(false);
  const localSessionId = useLocalSessionId();
  const localParticipantVideo = useParticipantProperty(
    localSessionId || "",
    "video",
  );
  const { setCameraActive } = useDevices();
  const { t } = useI18n("videoBackground");
  const isInEvent = useIsInEvent();
  const currentBackground = useSelector(selectCurrentBackground);
  const dispatch = useAppDispatch();
  const daily = useDaily();
  const { addInfoNotification } = useNotificationActions();

  const { supportsVideoProcessing: virtualBackgroundSupported } = useMemo(
    () => DailyIframe.supportedBrowser(),
    [],
  );

  const setVirtualBackground = useCallback(
    async (request: VirtualBackgroundRequest) => {
      if (!daily) {
        logger.warn("[virtualBackground] no daily call object");
        return;
      }

      if (!virtualBackgroundSupported) {
        logger.warn("[virtualBackground] feature not supported");
        return;
      }

      setIsLoading(true);

      try {
        localStorage.setItem(
          VIRTUAL_BACKGROUND_CACHE_KEY,
          JSON.stringify(request),
        );
        const processorConfig = await parseRequest(request);

        await daily.updateInputSettings({
          video: {
            processor: processorConfig,
          },
        });

        dispatch(setCurrentBackground(request));

        if (
          request.type !== VirtualBackgrounds.NONE &&
          !localParticipantVideo
        ) {
          if (isInEvent) {
            addInfoNotification({
              message: t("warn.about.camera.being.off"),
              dismissible: false,
            });
          } else {
            setCameraActive(true);
          }
        }
      } catch (error) {
        trackError(error, { label: Errors.VIRTUAL_BACKGROUND });
      } finally {
        setIsLoading(false);
      }
    },
    [
      t,
      daily,
      dispatch,
      virtualBackgroundSupported,
      localParticipantVideo,
      addInfoNotification,
      isInEvent,
      setCameraActive,
    ],
  );

  return {
    setVirtualBackground,
    currentBackground,
    isLoading,
    virtualBackgroundSupported,
  };
};
