import { useEffect, useState } from "react";
import throttle from "modules/throttle";
import {
  DatabaseReference,
  DataSnapshot,
  off,
  onValue,
} from "firebase/database";
import { RaisedHandUser } from "../redux/broadcastRaiseHandSlice";
import { getRaisedHandUserDocRef } from "../raiseHand.firebase";

export const useUserRaisedHand = (
  theaterId: string | null,
  userId: string | null,
): boolean => {
  const [isUsersHandRaised, setIsUsersHandRaised] = useState(false);

  useEffect(() => {
    let docRef: DatabaseReference;

    if (theaterId && userId) {
      docRef = getRaisedHandUserDocRef(theaterId, userId);
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      subscribe(docRef);
    }

    return () => {
      if (docRef) {
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        unsubscribe(docRef);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [theaterId, userId]);

  const throttledUpdate = throttle((isHandRaised?: RaisedHandUser) => {
    setIsUsersHandRaised(isHandRaised ? isHandRaised.raised : false);
  }, 500);

  const subscribe = (docRef: DatabaseReference) => {
    if (docRef) {
      off(docRef);
      onValue(docRef, (snap: DataSnapshot) => {
        const raisedHandUsers = snap.val();

        throttledUpdate(raisedHandUsers);
      });
    }
  };

  const unsubscribe = (docRef: DatabaseReference) => {
    off(docRef);
  };

  return isUsersHandRaised;
};
