import logger from "logging/logger";
import { SVGSourceTypes } from "modules/customFloorPlan/constants";
import { generatePlanConfig } from "modules/customFloorPlan/validator/validator";
import { IMapTemplate } from "types/theater";

const getSvgContainer = () =>
  document.querySelector<HTMLDivElement>(".svg-container");

const setSvgContainerToFullSize = (svgElement: SVGSVGElement) => {
  const svgContainer = getSvgContainer();

  if (svgContainer) {
    svgContainer.style.transform = "none";
    svgContainer.style.transformStyle = "center";
    svgContainer.style.left = "0px";
    svgContainer.style.top = "0px";
    svgContainer.style.position = "absolute";

    const viewBox =
      svgElement.getAttribute("viewBox") || svgElement.getAttribute("viewbox");
    const parts = viewBox?.split(" ");

    svgContainer.style.width = `${parts?.[2]}px` || "auto";
    svgContainer.style.height = `${parts?.[3]}px` || "auto";
  }
};

/* This piece of code finds average distance between all the room boundary
 * clickable areas. If it is below a certain threshold it is considered as
 * not parsed correctly. In relative coordinate system all the clickable
 * areas are positioned relative to the room so if we parse it as absolute
 * system, they will accumulate in a corner of a map.
 */
export const checkIfRoomBoundariesAreRenderedProperly = (
  newDisplayConfig: Partial<IMapTemplate>,
) => {
  if (!newDisplayConfig?.rooms) return false;

  const { rooms } = newDisplayConfig;

  const bps = rooms.reduce<Record<string, number>>((acc, room, index) => {
    acc[room.code] = index;

    return acc;
  }, {});
  const bpEls = document.querySelectorAll(".room-boundary");

  if (!Object.keys(bps).length || bpEls.length <= 1) return false;
  let distance = 0;

  rooms.forEach((room, index: number) => {
    const currentRef = document.getElementById(`rm-bp-${room.code}`);
    const prevRef = rooms[index - 1]
      ? document.getElementById(`rm-bp-${rooms[index - 1].code}`)
      : null;

    if (room.boundaryPolygon && currentRef && prevRef) {
      const { x: x1, y: y1 } = prevRef.getBoundingClientRect();
      const { x: x2, y: y2 } = currentRef.getBoundingClientRect();

      distance += Math.sqrt((x1 - x2) * (x1 - x2) + (y1 - y2) * (y1 - y2));
    }
  });
  const averageDistance = distance / bpEls.length;

  if (averageDistance < 20) return false;

  return true;
};
const handleGenerateResultsConfig = (
  svgElement: SVGGElement,
  svgSource: SVGSourceTypes,
) => {
  const planConfig = generatePlanConfig(svgElement, svgSource);

  if (!planConfig) {
    logger.error(
      "[CustomFloorPlanValidatorV2] An error occurred during template validation.",
    );
  }

  return planConfig;
};

/* This effect is responsible parsing the configuration from the svg.
 * Before it parses, it paints the svg in full size to parse configuration
 * correctly. It then resizes the svg to how we want to display it to
 * the end user. Also, in case of isometric floorplans, it detects if
 * the floorplan is in relative coordinate system or absolute coordinate
 * system. It basically does the whole parsing again if the floorplan
 * is not in absolute coordinate system.
 */
export const parseFloorPlanConfig = (
  svgElementWrap: HTMLDivElement,
  svgSource: SVGSourceTypes,
  checkedMode?: string,
) => {
  const svgElement = svgElementWrap.querySelector("svg");

  if (!svgElement) {
    return null;
  }

  setSvgContainerToFullSize(svgElement);
  const newDisplayConfig = handleGenerateResultsConfig(svgElement, svgSource);

  return { newDisplayConfig, checkedMode, svgSource };
};

/**
 * Checks whether the floor plan is isometric
 * @param displayConfig
 * @returns
 */
export const isIsometricFloorPlan = (
  displayConfig: Partial<IMapTemplate> | null | undefined,
): boolean =>
  displayConfig?.rooms?.some(
    (room) => room.boundaryPolygon || room.nameTextPath,
  ) ?? false;

/**
 * If it is isometric FP, check if boundaries are calculated correctly,
 * if not, do it again with relative co ordinates
 * @param displayConfig
 * @returns
 */
export const shouldUseDifferentsSvgSource = (
  displayConfig: Partial<IMapTemplate>,
  svgSource: SVGSourceTypes,
  checkedMode: string,
): boolean =>
  isIsometricFloorPlan(displayConfig) &&
  checkedMode !== svgSource &&
  !checkIfRoomBoundariesAreRenderedProperly(displayConfig) &&
  (checkedMode !== SVGSourceTypes.RELATIVE ||
    svgSource !== SVGSourceTypes.ABSOLUTE) &&
  (checkedMode !== SVGSourceTypes.ABSOLUTE ||
    svgSource !== SVGSourceTypes.RELATIVE);

export const getNewSvgSource = (svgSource: string) =>
  svgSource === SVGSourceTypes.RELATIVE
    ? SVGSourceTypes.ABSOLUTE
    : SVGSourceTypes.RELATIVE;
