import { Box } from "@remo-co/ui-core/src/components/Box";
import { Tooltip } from "@remo-co/ui-core/src/components/Tooltip";
import { Typography } from "@remo-co/ui-core/src/components/Typography";
import "./AttendeeStats.scss";
import { useI18n } from "i18n";
import { IEventMemberDataResults } from "../types";

interface Props {
  attendees: IEventMemberDataResults[];
}

const AttendeeStats = ({ attendees = [] }: Props) => {
  const { t } = useI18n(["common", "eventLanding"]);
  const invited = attendees.filter((attendee) => attendee.invited).length;
  const registered = attendees.filter((attendee) => attendee.registered).length;
  const attended = attendees.filter((attendee) => attendee.attended).length;

  return (
    <Box
      display="flex"
      justifyContent="flex-start"
      className="attendee-stats-ctn"
    >
      <Box>
        <Tooltip title={<>{t("eventLanding:invites.sent.out")}</>}>
          <Typography component="span" color="inherit">
            {t("event:invited")}:
          </Typography>
        </Tooltip>
      </Box>
      <Box className="value">
        <Typography color="inherit">{invited || 0}</Typography>
      </Box>
      <Box>
        <Tooltip title={<>{t("eventLanding:people.save.spot")}</>}>
          <Typography component="span" color="inherit">
            {t("event:registered")}:
          </Typography>
        </Tooltip>
      </Box>
      <Box className="value">
        <Typography color="inherit">{registered || 0}</Typography>
      </Box>
      <Box>
        <Tooltip title={<>{t("eventLanding:people.logged.event")}</>}>
          <Typography component="span" color="inherit">
            {t("event:attended")}:
          </Typography>
        </Tooltip>
      </Box>
      <Box className="value">
        <Typography color="inherit">{attended || 0}</Typography>
      </Box>
    </Box>
  );
};

export default AttendeeStats;
