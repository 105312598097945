import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";

export const useStyles = makeStyles(() => ({
  container: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-around",
    boxSizing: "border-box",
  },
  avatar: {
    width: "40px",
    height: "40px",
    position: "relative",
  },
}));
