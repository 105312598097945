import React from "react";
import IconButton from "@mui/material/IconButton";
import { Tooltip } from "../Tooltip";
import { useStyles } from "./styles";

interface Props {
  id: string;
  title: string;
  onClick?: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
  children?: React.ReactNode;
  type?: string;
  color?: "inherit" | "primary" | "secondary" | "default" | undefined;
  className?: string;
  disabled?: boolean;
  hoverColor?: string;
  size?: "small" | "medium";
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  component?: React.ElementType<any>;
  to?: string;
}

const IconButtonWithTooltip = (props: Props) => {
  const {
    id,
    title,
    onClick,
    children,
    type,
    color,
    className,
    disabled,
    to,
    component,
    ...rest
  } = props;
  const style = useStyles();

  return (
    <Tooltip title={title} placement="top">
      <IconButton
        id={id}
        aria-label={title}
        className={`${style.iconButtonWithTooltip} ${className || ""}`}
        type={type}
        color={color}
        onClick={onClick}
        disabled={disabled}
        to={to}
        component={component ?? "button"}
        {...rest}
        size="large"
      >
        {children}
      </IconButton>
    </Tooltip>
  );
};

export default React.memo(IconButtonWithTooltip);
