import { useMemo } from "react";
import { useCompanyPlanSettings } from "modules/companyPlanSettings";
import { IRoom } from "types";
import { getMaxAllowedInTable } from "modules/companyPlanSettings/utils/event";
import { ConfigRoomNames } from "modules/customFloorPlan/constants";

const useComputeForOverCapacity = (rooms: IRoom[]) => {
  const { myCompanyPlan } = useCompanyPlanSettings();

  const configRooms: string[] = Object.values(ConfigRoomNames);

  const roomsOverCapacity: string[] = useMemo(
    () =>
      rooms.reduce(
        (arr: string[], room: IRoom) =>
          room.positions.length !== getMaxAllowedInTable(room, myCompanyPlan) &&
          !configRooms.includes(room.name)
            ? [room.name, ...arr]
            : arr,
        [],
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [rooms],
  );

  return { roomsOverCapacity };
};

export default useComputeForOverCapacity;
