import classNames from "classnames";
import { Box } from "@remo-co/ui-core/src/components/Box";
import { Grid } from "@remo-co/ui-core/src/components/Grid";
import { Typography } from "@remo-co/ui-core/src/components/Typography";
import { EventContentType } from "graphql/generated";
import { useI18n } from "i18n";
import FloorplanSVG from "../../assets/floorplan.svg";
import FullscreenSVG from "../../assets/fullscreen.svg";
import PopupSVG from "../../assets/popup.svg";
import { useStyles } from "./styles";

interface Props {
  displayType?: EventContentType;
  handleChange: (type: EventContentType) => void;
}

export const DisplayTypeRadioButtons = ({
  displayType,
  handleChange,
}: Props) => {
  const { t } = useI18n(["eventForm"]);
  const styles = useStyles();

  const options = [
    {
      type: EventContentType.EventFloor,
      image: FloorplanSVG,
      caption: t("eventForm:display.type.eventfloor"),
    },
    {
      type: EventContentType.Popup,
      image: PopupSVG,
      caption: t("eventForm:display.type.popup"),
    },
    {
      type: EventContentType.FullScreen,
      image: FullscreenSVG,
      caption: t("eventForm:display.type.fullscreen"),
    },
  ];

  return (
    <Box display="flex" flexDirection="column" className={styles.root}>
      <Typography variant="body1" className={styles.label}>
        {t("eventForm:display.type")}
      </Typography>
      <Grid container spacing={2}>
        {options.map((option) => (
          <Grid item xs={4} key={option.type}>
            <div
              role="button"
              onClick={() => handleChange(option.type)}
              onKeyPress={({ key }) => {
                if (key === "Enter") {
                  handleChange(option.type);
                }
              }}
              tabIndex={0}
            >
              <img
                src={option.image}
                alt={option.caption}
                className={classNames(styles.optionSVG, {
                  [styles.selectedType]: displayType === option.type,
                })}
              />
              <Typography align="center">{option.caption}</Typography>
            </div>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};
