import { useSelector } from "react-redux";
import { Box } from "@remo-co/ui-core/src/components/Box";
import classNames from "classnames";
import { isEventManager } from "modules/event/utils/eventUtils";
import { selectCurrentEvent } from "modules/event/selectors";
import { useIsInEvent } from "modules/event";
import { IUser } from "modules/app/types";
import { selectIsCompanyOwner } from "modules/auth/redux/selectors";
import {
  selectCompanyBadgeUrl,
  selectEnableCompanyBadge,
} from "modules/companySettings/redux/selectors";
import { ReactComponent as PictureIcon } from "../assets/picture.inline.svg";
import { useStyles } from "./styles";

interface IBadgePreviewProps {
  badgeUrl?: string;
  size: string;
  user?: IUser;
  isCompanyBadgeEdit?: boolean;
}

const BadgePreview = ({
  badgeUrl,
  size,
  user,
  isCompanyBadgeEdit,
}: IBadgePreviewProps) => {
  const classes = useStyles();
  const companyBadgeURL = useSelector(selectCompanyBadgeUrl);
  const enableCompanyBadge = useSelector(selectEnableCompanyBadge);
  const isCompanyOwner = useSelector(selectIsCompanyOwner);
  const currentEvent = useSelector(selectCurrentEvent);
  const isInEvent = useIsInEvent();
  // need to pass user id otherwise it's showing for badge for all manager not for specific company
  const isCompanyManager = isEventManager(currentEvent ?? undefined, user?.id);
  const imageUrl = badgeUrl || companyBadgeURL;

  const isValidCompanyMember = isInEvent ? isCompanyManager : isCompanyOwner;

  // check for showing badge only to company members
  const isCompanyBadgeVisible =
    isValidCompanyMember && (isCompanyBadgeEdit || enableCompanyBadge);

  if (!isCompanyBadgeVisible) {
    return null;
  }

  return (
    <Box
      data-testid="badge-preview-container"
      className={classNames(
        classes.badgePreview,
        classes[`badgePreview${size.toUpperCase()}`],
      )}
    >
      {(!imageUrl && <PictureIcon />) || (
        <img
          className={classes[`uploadedImage${size.toUpperCase()}`]}
          src={imageUrl}
          alt="Company Badge"
        />
      )}
    </Box>
  );
};

export default BadgePreview;
