import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";

export const useStyles = makeStyles((theme) => ({
  container: {
    color: theme.palette.gray.light,
    textAlign: "left",
    width: 470,
  },
  title: {
    marginBottom: theme.spacing(2),
    opacity: 0.9,
    fontWeight: "normal",
  },
  message: {
    backgroundColor: theme.palette.blue.darkShade2,
    color: theme.palette.gray.medium,
  },
}));
