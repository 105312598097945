import { ComponentProps } from "react";
import { Theme } from "@remo-co/ui-core/src/types";
import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";
import zIndexes from "zIndexes.module.scss";
import { PresetSelect } from "./PresetSelect";

const useStyles = makeStyles<
  Theme,
  Partial<ComponentProps<typeof PresetSelect>>
>((theme) => ({
  presetSelectContainer: {
    display: "flex",
    gap: (props) => (props.isInEvent ? theme.spacing(1.25) : theme.spacing(1)),
    alignItems: "center",
  },
  presetSelect: {
    width: "120px",
  },
  tooltip: {
    zIndex: zIndexes.modalZIndex + 1,
  },
}));

export default useStyles;
