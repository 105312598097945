import { RemoLogo } from "@remo-co/ui-core/src/components/RemoLogo";

interface IBaseCircleLogoProps {
  logoURL?: string;
  logoClass?: string;
  logoAlt?: string;
  full?: boolean;
  width?: number;
}

const BaseCircleLogo = ({
  logoURL,
  logoAlt,
  logoClass,
  full,
  width,
}: IBaseCircleLogoProps) => {
  if (logoURL) {
    return (
      <img
        src={logoURL}
        alt={logoAlt}
        className={logoClass}
        data-testid="logo-url-image"
      />
    );
  }

  if (full) {
    return (
      <div data-testid="rounded-logo">
        <RemoLogo width={width} />
      </div>
    );
  }

  return (
    <div data-testid="remo-logo">
      <RemoLogo size="sm" />
    </div>
  );
};

export default BaseCircleLogo;
