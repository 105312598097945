import { FloorIcon } from "@remo-co/ui-core/src/icons/Floor";
import { GalleryIcon } from "@remo-co/ui-core/src/icons/Gallery";
import { SpeakerIcon } from "@remo-co/ui-core/src/icons/Speaker";
import { DisplayLayout } from "../Layouts";

export const layoutDetails = [
  {
    id: DisplayLayout.Map,
    image: <FloorIcon />,
    heading: "floor",
    description: "floor.description",
  },
  {
    id: DisplayLayout.Tile,
    image: <GalleryIcon />,
    heading: "gallery",
    description: "gallery.description",
  },
  {
    id: DisplayLayout.Focus,
    image: <SpeakerIcon />,
    heading: "speaker",
    description: "speaker.description",
  },
];

export enum PopperPlacement {
  bottom = "bottom",
  bottomStart = "bottom-start",
  topStart = "top-start",
  top = "top",
}
