import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";

export const useStyles = makeStyles((theme) => ({
  dialog: {
    minHeight: "200px",
  },
  previewContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(4),
  },
  title: {
    fontSize: "1.5rem",
    marginBottom: theme.spacing(3),
    textAlign: "center",
  },
  body: {
    textAlign: "center",
  },
}));
