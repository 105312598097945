import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";

export const useStyles = makeStyles((theme) => ({
  container: {
    textAlign: "left",
  },
  clickableTab: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: theme.palette.white,
    border: `1px solid ${theme.palette.gray.disabled}`,
    boxSizing: "border-box",
    borderRadius: theme.spacing(0.5),
    margin: `0 ${theme.spacing(0.5)} ${theme.spacing(0.5)} 0`,
    padding: `${theme.spacing(0.5)} ${theme.spacing(2)}`,
    cursor: "pointer",
  },
  clickableTabSelected: {
    color: theme.palette.white,
    background: theme.palette.dark.main,
  },
  title: {
    color: theme.palette.blue.dark,
    fontSize: theme.typography.h1.fontSize,
    lineHeight: theme.typography.h1.lineHeight,
  },
  description: {
    color: theme.palette.blue.dark,
    fontSize: theme.typography.body1.fontSize,
  },
}));
