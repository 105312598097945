import { Theme } from "@remo-co/ui-core/src/types";
import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";

export const useStyles = makeStyles<Theme>((theme) => ({
  wrap: {
    display: "flex",
    "justify-content": "space-between",
    "flex-wrap": "wrap",
    position: "relative",
  },
  upload: {
    "box-sizing": "border-box",
    padding: "13px 8px",
    overflow: "hidden",
    width: "calc(100% - 130px)",
    height: "50px",
    "border-width": "1px",
    "border-style": "solid",
    "border-color": theme.palette.blue.darkShade1,
    "border-radius": "5px",
    display: "flex",
    position: "relative",
    "align-items": "center",
    "justify-content": "space-between",
    cursor: "pointer",

    "&:hover": {
      color: theme.palette.primary.main,
    },
  },
  icon: {
    "& path": {
      fill: theme.palette.text.primary,
      color: theme.palette.text.primary,
    },
    "$wrap:hover & path": {
      fill: theme.palette.primary.main,
      color: theme.palette.primary.main,
    },
  },
  pictureIcon: {
    transition: "transform ease-in-out 250ms",
    position: "absolute",
    left: "1.25rem",
    "$upload:hover &": {
      transform: "translateY(-38px)",
    },
  },
  uploadIcon: {
    transition: "transform ease-in-out 250ms",
    position: "absolute",
    left: "1.25rem",
    transform: "translateY(38px)",
    "$upload:hover &": {
      transform: "translateY(0)",
    },
  },
  label: {
    fontFamily: theme.typography.fontFamily,
    "justify-content": "left",
    "padding-left": "4rem",
    opacity: "0.7",
    cursor: "pointer",
    transition: "transform ease-in-out 100ms",
    "white-space": "nowrap",
    "max-width": "calc(100% - 4rem - 2rem)",
    overflow: "hidden",
    "text-overflow": "ellipsis",
  },
  preview: {
    "box-sizing": "border-box",
    border: "1px solid #C4C4C4",
    "border-radius": "5px",
    display: "flex",
    position: "relative",
    "align-items": "center",
    "justify-content": "center",
    cursor: "pointer",
    width: "114px",
    height: "50px",
  },
  uploadedImage: {
    "max-width": "12rem",
    "max-height": "none",
    transform: "translateX(-50%) scale(0)",
    transition: "transform ease-in-out 250ms",
    "transform-origin": "center top",
    "border-radius": "10px",
    "z-index": 1,
    position: "absolute",
    left: "50%",
    top: "50%",

    "$preview:hover &": {
      transform: "translateX(-50%) scale(1)",
    },
  },
  disabled: {
    display: "block",
    position: "absolute",
    width: "100% !important",
    height: "100%",
    opacity: "0.6",
  },
  buttons: {
    display: "flex",
    justifyContent: "space-between",
    top: "-4rem",
    margin: 0,
    padding: 0,
    background: "#fff",
    zIndex: 2,
    position: "sticky",
  },
  bannerSelect: {
    minWidth: 100,
  },
  ctaButton: {
    padding: 0,
  },
}));
