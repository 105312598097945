import React from "react";
import { Box } from "@remo-co/ui-core/src/components/Box";
import { Button } from "@remo-co/ui-core/src/components/Button";
import { Tooltip } from "@remo-co/ui-core/src/components/Tooltip";
import { Typography } from "@remo-co/ui-core/src/components/Typography";
import { MANAGE_EVENT_CONTEXT } from "modules/manageEvent";
import { useI18n } from "i18n";
import { getMapTemplateByType } from "modules/event/template";
import { useCompanyPlanSettings } from "modules/companyPlanSettings";
import { ISponsor } from "modules/event/types";
import SponsorForm from "./SponsorForm";
import "./Sponsors.settings.scss";

const Sponsorship = () => {
  const { state, actions } = React.useContext(MANAGE_EVENT_CONTEXT);
  const { eventData, sponsors = [] } = state ?? {};
  const [showSponsorForm, setShowSponsorForm] = React.useState(false);
  const { t } = useI18n(["common", "eventForm"]);
  const [sponsor, setSponsor] = React.useState<ISponsor | null>();
  const evTemplate = getMapTemplateByType(eventData?.theaters?.[0].template);
  const adLayouts = evTemplate?.sponsors;
  const availableSlots = (adLayouts && adLayouts.length) || 0;
  const { getMaxAdsPerEvent } = useCompanyPlanSettings();
  const maxAllowedAdsPerEvent = getMaxAdsPerEvent();

  /**
   * Changing breadcrumb nodes - moving into add/edit form and coming back
   * @param e
   */
  const onClick = (e?: React.MouseEvent<Element, MouseEvent>) => {
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }
    setShowSponsorForm(false);
    setSponsor(null);
  };

  /**
   * Show sponsor form for add/edit sponsor
   * @param e
   * @param isEdit
   */
  const addNewSponsor = (
    _: React.MouseEvent<Element, MouseEvent>,
    isEdit?: boolean,
  ) => {
    // If trying to add more than allowed, show error
    if (!isEdit && maxAllowedAdsPerEvent <= sponsors.length) {
      actions?.setUpgradeMessage(
        `You have reached the maximum number of sponsors of ${maxAllowedAdsPerEvent} that is allowed on your plan.
          Remove a sponsor or upgrade your plan.`,
      );

      return;
    }
    setShowSponsorForm(true);
  };

  // Edit a sponsor
  const editSponsor = (sponsorData: ISponsor) => {
    setShowSponsorForm(true);
    setSponsor(sponsorData);
  };

  const sponsorFormControls = () =>
    !showSponsorForm && (
      <div className="mar-top-20">
        <Tooltip
          placement="top"
          title={!availableSlots ? t("eventForm:select.sponsor.slot") : ""}
        >
          <span style={{ display: "inline-block" }}>
            <Button
              data-testid="button-add-sponsor"
              className="sc-add-sponsor"
              disabled={sponsors.length >= availableSlots}
              variant="secondary"
              color="blue"
              onClick={addNewSponsor}
            >
              {t("eventForm:button.add.sponsor")}
            </Button>
          </span>
        </Tooltip>
      </div>
    );

  return (
    <Box className="sponsor-cnt">
      <Typography variant="h3">{t("eventForm:add.sponsor.banner")}</Typography>
      <Typography variant="body1">
        {t("eventForm:add.sponsors.text")}
      </Typography>

      <Typography variant="h4" className="pad-top-40">
        {t("eventForm:event.sponsorship")}
      </Typography>
      {sponsorFormControls()}
      <SponsorForm
        eventData={eventData}
        updateSponsors={actions?.setSponsors}
        availableSlots={availableSlots}
        onCancel={onClick}
        sponsor={sponsor}
        showSponsorForm={showSponsorForm}
        editSponsor={editSponsor}
        allSponsors={sponsors}
      />
    </Box>
  );
};

export default Sponsorship;
