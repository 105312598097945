import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";

export const useStyles = makeStyles((theme) => ({
  switchBox: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(0.625),
    marginTop: theme.spacing(2.5),
  },
  tooltip: {
    backgroundColor: theme.palette.blue.dark,
    color: theme.palette.white,
    width: theme.spacing(28.5),
    gap: 0,
    opacity: 1,
    textAlign: "left",
    "& a": {
      color: theme.palette.white,
    },
    "& .MuiTooltip-arrow": {
      color: theme.palette.blue.dark,
    },
  },
  tooltipText: {
    color: theme.palette.white,
  },
  tooltipLinkText: {
    color: theme.palette.white,
    textDecoration: "underline",
  },
}));
