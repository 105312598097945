import { CreateCSSProperties } from "@mui/styles/withStyles/withStyles";
import { Breakpoint, Breakpoints } from "@mui/material/styles";
import { SpacingType } from "../../types";

export type ResponsiveProperties<T> = {
  [K in keyof T]: T[K] | PropValueOrResponsivenessMap<T[K]>;
};

export type ResponsiveObject<T> = {
  [K in SpacingType]?: T | null;
};

export type PropValueOrResponsivenessMap<T> = ResponsiveObject<T> | T;

export const isBreakpointProp = <T>(
  prop: PropValueOrResponsivenessMap<T>,
): prop is ResponsiveObject<T> => typeof prop === "object";

export const isPassthrough = (prop: unknown): prop is undefined =>
  prop === undefined;

export const isReset = (prop: unknown): prop is null => prop === null;

type ValueMapper<T, P> = (
  value: T | null,
  props: P,
  currentBreakpoint: Breakpoint | null,
) => CreateCSSProperties;

interface ICreateResponsiveRulesParams<T, P> {
  rule: PropValueOrResponsivenessMap<T>;
  valueMapper: ValueMapper<T, P>;
  breakpoints: Breakpoints;
  props: P;
}

export const createResponsiveRules = <T, P>({
  rule,
  valueMapper,
  breakpoints,
  props,
}: ICreateResponsiveRulesParams<T, P>): CreateCSSProperties => {
  if (isBreakpointProp(rule)) {
    return Object.entries(rule).reduce<CreateCSSProperties>(
      (curr, [breakpoint, rule]) => {
        if (rule !== undefined) {
          curr[breakpoints.up(breakpoint as Breakpoint)] = valueMapper(
            rule,
            props,
            breakpoint as Breakpoint,
          );
        }

        return curr;
      },
      {},
    );
  }

  return valueMapper(rule, props, null);
};
