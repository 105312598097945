import { getEventFromToken } from "services/apiService/apis";
import eventInviteApi from "../eventInvite.api";
import { IInviteAttendeeParams } from "../types";

export const SERVER_EMAIL_LIMIT_EXCEEDED = "server:emails.limit.exceeded";

const useEventInviteActions = () => {
  // move to some where?
  const getEventDataFromToken = async (inviteToken: string) =>
    getEventFromToken({ inviteToken });

  const checkInviteCode = async (inviteCode: string, userId?: string | null) =>
    eventInviteApi.checkInviteCode({ inviteCode, userId });

  const processJoinByCode = async (eventCode: string) =>
    eventInviteApi.joinEventByCode({ eventCode });

  const sendAttendeeInvite = async (params: IInviteAttendeeParams) => {
    const resp = await eventInviteApi.inviteAttendee(params);
    if (resp?.message === SERVER_EMAIL_LIMIT_EXCEEDED) {
      return {
        ...resp,
        isSuccess: false,
      };
    }
    return resp;
  };
  const removeAttendee = async (
    eventId: string,
    attendeeId: string,
    inviteId: string,
  ) => eventInviteApi.removeAttendee({ eventId, attendeeId, inviteId });

  const sendSpeakerInvite = async (
    eventId?: string,
    theaterId?: string,
    emails?: string[],
  ) =>
    eventInviteApi.inviteSpeaker({
      eventId,
      theaterId,
      emails,
    });

  return {
    checkInviteCode,
    getEventDataFromToken,
    processJoinByCode,
    sendAttendeeInvite,
    sendSpeakerInvite,
    removeAttendee,
  };
};

export default useEventInviteActions;
