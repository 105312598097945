import { FC } from "react";
import { SplitFactory as SplitContext } from "@splitsoftware/splitio-react";
import { getVar } from "config";
import { ISplitFactoryProps } from "@splitsoftware/splitio-react/types/types";

export const splitIoConfig: SplitIO.IBrowserSettings = {
  core: {
    authorizationKey: getVar("REACT_APP_SPLIT_IO_API_KEY"),
    key: "remo",
  },
};

export const SplitProvider: FC<Pick<ISplitFactoryProps, "children">> = ({
  children,
}) => <SplitContext config={splitIoConfig}>{children}</SplitContext>;
