import logger from "logging/logger";
import { IUser } from "modules/app/types";
import {
  handleAudioProcess,
  handleClose,
  handleError,
  handleMessage,
  handleOpen,
} from "modules/transcription/";

export const registerProcessorHandlers = (
  audioProcessor: ScriptProcessorNode,
  websocket: WebSocket,
): void => {
  logger.log(
    `[Transcription] registering processor handlers to websocket ${websocket.url}`,
  );

  audioProcessor.addEventListener(
    "audioprocess",
    (event: AudioProcessingEvent) => {
      handleAudioProcess(event, websocket);
    },
  );
};

export const registerWebSocketHandlers = (
  websocket: WebSocket,
  user: IUser,
  sampleRate: number | undefined,
  updateMessages: (message: RealtimeSpeechData) => void,
  updateFinalizedTranscript: (finalizedTranscript: RealtimeSpeechData) => void,
  isSpeaker: boolean,
): void => {
  logger.log(
    `[Transcription] registering websocket handlers to ${websocket.url}`,
  );

  websocket.addEventListener("message", (event) => {
    handleMessage(event, updateMessages, updateFinalizedTranscript);
  });

  websocket.addEventListener("error", (err) => {
    handleError(err, websocket);
  });

  websocket.addEventListener("close", () => {
    handleClose(websocket);
  });

  if (isSpeaker) {
    websocket.addEventListener("open", () => {
      handleOpen(websocket, user, sampleRate);
    });
  }
};
