import { useState, useCallback, useEffect } from "react";
import { useSelector } from "react-redux";
import miroBoardFirebase from "modules/whiteboard/miroBoard.firebase";
import { makeCancellable } from "modules/common/utils";
import { WhiteboardDataStatus } from "modules/whiteboard/constants";
import { useAppDispatch } from "store/hooks";
import { selectCurrentRoom } from "store/rooms";
import {
  selectIsEventManager,
  selectIsEventSpeaker,
} from "modules/event/selectors";
import useNotificationActions from "modules/notification/hooks/useNotificationActions";
import { useI18n } from "i18n";
import classnames from "classnames";
import { updateRoomWhiteboard } from "modules/roomWhiteboard/redux/slice";
import {
  selectIsRoomWhiteboardOpen,
  selectRoomWhiteboard,
} from "modules/roomWhiteboard/redux/selectors";
import { selectIsInBroadcast } from "modules/broadcast/redux/selectors";
import logger from "logging/logger";
import useMiroBoard from "../whiteboard/hooks/useMiroBoard";
import MiroWhiteboard from "../whiteboard/MiroWhiteboard";
import useRoomWhiteboardActions from "./hooks/useRoomWhiteboardActions";
import { useStyles } from "./styles";

const RoomMiroBoard = (): JSX.Element | null => {
  const classes = useStyles();
  const [isError, setIsError] = useState(false);
  const dispatch = useAppDispatch();
  const isInBroadcast = useSelector(selectIsInBroadcast);
  const isWhiteboardOpen = useSelector(selectIsRoomWhiteboardOpen);
  const { boardUrlStatus, boardCode, miroBoardId } =
    useSelector(selectRoomWhiteboard);
  const { closeWhiteboard } = useRoomWhiteboardActions();
  const room = useSelector(selectCurrentRoom);
  const roomId = room ? room.id : "";
  const { selectBoardFromPicker, createWhiteboard, isLoading } = useMiroBoard(
    roomId,
    true,
  );
  const hasEventManagerAccess = useSelector(selectIsEventManager);
  const hasEventSpeakerAccess = useSelector(selectIsEventSpeaker);
  const { addErrorNotification } = useNotificationActions();
  const { t } = useI18n(["common"]);

  const handleClose = useCallback(() => {
    closeWhiteboard({ roomId });
  }, [closeWhiteboard, roomId]);

  useEffect(() => {
    if (isInBroadcast) {
      handleClose();
    }
  }, [handleClose, isInBroadcast]);

  const importBoard = useCallback(async () => {
    if (roomId) {
      try {
        const resp = await selectBoardFromPicker();

        dispatch(
          updateRoomWhiteboard({
            boardCode: resp.code,
            miroBoardId: resp.id,
          }),
        );
      } catch (err) {
        addErrorNotification({ message: t("something.wrong") });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roomId]);

  const createNewWhiteboard = useCallback(() => {
    if (!room) {
      return null;
    }

    setIsError(false);

    return createWhiteboard(room.name);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [room]);

  useEffect(() => {
    if (!room || !isWhiteboardOpen || isLoading || miroBoardId) {
      return;
    }

    createNewWhiteboard();
  }, [
    room,
    isWhiteboardOpen,
    isLoading,
    miroBoardId,
    boardUrlStatus,
    createNewWhiteboard,
  ]);

  useEffect(() => {
    const updateBoardCodeIfNeeded = async () => {
      if (!room || !isWhiteboardOpen) return;

      if (boardUrlStatus === WhiteboardDataStatus.SUCCESS) {
        const whiteboard = await miroBoardFirebase.getMiroBoard(room.id);

        dispatch(
          updateRoomWhiteboard({
            boardCode: whiteboard.boardCode,
            miroBoardId: whiteboard.miroBoardId,
          }),
        );
      } else if (boardUrlStatus === WhiteboardDataStatus.ERROR) {
        setIsError(true);
      }
    };

    const cancellableUpdateBoardCodeIfNeeded = makeCancellable(
      updateBoardCodeIfNeeded(),
    );

    // eslint-disable-next-line promise/prefer-await-to-then
    cancellableUpdateBoardCodeIfNeeded.promise.catch((err) => {
      if (!err.isCanceled) {
        logger.warn(
          `[miro][updateBoard] Error encountered while updating board code: ${err.message}`,
        );
      }
    });

    return cancellableUpdateBoardCodeIfNeeded.cancel;
  }, [room, isWhiteboardOpen, boardUrlStatus, dispatch]);

  if (!isWhiteboardOpen) {
    return null;
  }

  return (
    <div
      className={classnames(classes.roomWhiteboardContainer)}
      data-testid="room-whiteboard-container"
    >
      <MiroWhiteboard
        className={classes.whiteboardContainer}
        miroBoardId={miroBoardId}
        isError={isError}
        boardCode={boardCode}
        showCloseButton
        createNewWhiteboard={createNewWhiteboard}
        onClose={handleClose}
        importBoard={importBoard}
        showImportButton={hasEventManagerAccess || hasEventSpeakerAccess}
      />
    </div>
  );
};

export default RoomMiroBoard;
