import { EventTableSetting } from "modules/assignTables/types";

export interface SeatingInfo {
  totalUsers: number;
  tableCount: number;
}

export const computeFloorSeatingInfo = (
  settings: EventTableSetting[],
  floorId: string,
): SeatingInfo =>
  settings.reduce(
    (seatingInfo, setting) =>
      setting.floor === floorId
        ? {
            totalUsers: seatingInfo.totalUsers + setting.users.length,
            tableCount: seatingInfo.tableCount + 1,
          }
        : seatingInfo,
    { totalUsers: 0, tableCount: 0 },
  );
