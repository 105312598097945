import { Grid, SearchBar, SearchContext } from "@giphy/react-components";
import { selectIsBroadcasting } from "modules/broadcast/redux/selectors";
import { SyntheticEvent, useContext } from "react";
import { useSelector } from "react-redux";
import { GifID, IGif } from "../messagesView/types/GifMessage/types";
import useStyles from "./styles";

interface Props {
  onGifSelect: (gif: GifID) => void;
}

export const GiphyGrid = ({ onGifSelect }: Props) => {
  const { fetchGifs, term, activeChannel } = useContext(SearchContext);
  const isBroadcasting = useSelector(selectIsBroadcasting);
  const styles = useStyles({ isBroadcasting: Boolean(isBroadcasting) });

  const onGifClick = (gif: IGif, e: SyntheticEvent<HTMLElement, Event>) => {
    e.preventDefault();
    onGifSelect(gif.id);
  };

  return (
    <div>
      <SearchBar className={styles.gifSearch} />
      <Grid
        key={`${term} ${activeChannel?.user.username}`}
        columns={2}
        width={290}
        onGifClick={onGifClick}
        onGifRightClick={onGifClick}
        fetchGifs={fetchGifs}
        hideAttribution
        gutter={10}
        noLink
      />
    </div>
  );
};
