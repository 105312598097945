import logger from "logging/logger";
import { IUser } from "modules/app/types";
import { startTranscriptionRequest } from "modules/transcription";

export const handleMessage = (
  event: MessageEvent,
  updateMessages: (message: RealtimeSpeechData) => void,
  updateFinalizedTranscript: (finalizedTranscript: RealtimeSpeechData) => void,
): void => {
  const data = JSON.parse(event.data);

  const isPunctuatedMessage =
    // eslint-disable-next-line no-prototype-builtins
    data.type === "message" && data.message.hasOwnProperty("punctuated");

  if (!isPunctuatedMessage) {
    return;
  }

  if (data.message.isFinal) {
    updateFinalizedTranscript(data.message);
  } else {
    updateMessages(data.message);
  }
};

export const handleError = (err: Event, websocket: WebSocket): void => {
  logger.error(`[Transcription] ${websocket.url} websocket error ${err}`, {
    err,
  });
};

export const handleClose = (websocket: WebSocket): void => {
  logger.debug(
    `[Transcription] Connection to websocket CLOSED: \n ${websocket.url}`,
  );
};

export const handleOpen = (
  websocket: WebSocket,
  user?: IUser,
  sampleRate?: number,
): void => {
  logger.info(
    `[Transcription] Connection to websocket OPENED: \n ${websocket.url}`,
  );
  startTranscriptionRequest(websocket, user, sampleRate);
};
