import { useState, useEffect, useRef, useCallback } from "react";
import { Close } from "@remo-co/ui-core/src/icons/Close";
import { PlayForWork } from "@remo-co/ui-core/src/icons/PlayForWork";
import { useI18n } from "i18n";
import classnames from "classnames";
import { useSelector } from "react-redux";
import { selectActiveTile } from "modules/audioVideo/redux/selectors";
import { useAppDispatch } from "store/hooks";
import BroadcastBoardOverlay from "modules/broadcastWhiteboard/BroadcastBoardOverlay";
import { setActiveTile } from "modules/audioVideo/redux/slice";
import { getMiroBoardUrl } from "modules/whiteboard/utils";
import { DisplayItem, DisplayItemConfig } from "modules/audioVideo";
import useIframeController from "@remo-co/ui-core/src/hooks/useIframeController";
import { Container } from "@remo-co/ui-core/src/components/Container";
import { CircularProgress } from "@remo-co/ui-core/src/components/CircularProgress";
import { Typography } from "@remo-co/ui-core/src/components/Typography";
import { Button } from "@remo-co/ui-core/src/components/Button";
import { Fab } from "@remo-co/ui-core/src/components/Fab";
import { Box } from "@remo-co/ui-core/src/components/Box";
import { Tooltip } from "@remo-co/ui-core/src/components/Tooltip";
import { selectIsWhiteboardLoading } from "modules/roomWhiteboard/redux/selectors";
import { useStyles } from "./styles";

interface Props {
  isError?: boolean;
  createNewWhiteboard?: () => void;
  boardCode?: string;
  showCloseButton?: boolean;
  showImportButton?: boolean;
  onClose: () => void;
  importBoard?: () => void;
  miroBoardId?: string;
  readonlyBoard?: boolean;
  className?: string;
}

const MiroWhiteboard = (props: Props): JSX.Element => {
  const {
    isError,
    createNewWhiteboard,
    boardCode,
    showCloseButton,
    onClose,
    importBoard,
    showImportButton,
    miroBoardId,
    readonlyBoard = false,
    className,
  } = props;
  const classes = useStyles(props);

  const { t } = useI18n();
  const isWhiteboardLoading = useSelector(selectIsWhiteboardLoading);
  const [isFetchingBoardUrl, setIsFetchingBoardUrl] = useState<boolean>(true);
  const [boardUrl, setBoardUrl] = useState<string>();
  const activeTile = useSelector(selectActiveTile);
  const dispatch = useAppDispatch();
  const iframeRef = useRef<null | HTMLIFrameElement>(null);
  const iframeCallbackRef = useCallback(
    (node: null | HTMLIFrameElement): void => {
      iframeRef.current = node;
    },
    [],
  );
  const handleCloseClick = () => {
    onClose();
  };

  const handleImportClick = () => {
    importBoard?.();
  };

  const handleIframeFocus = () => {
    dispatch(
      setActiveTile({
        streamId: DisplayItemConfig.whiteboardDisplayId,
        type: DisplayItem.whiteboard,
      }),
    );
  };

  useEffect(() => {
    if (!boardCode) {
      return;
    }
    const url = getMiroBoardUrl({ boardCode, miroBoardId });

    setBoardUrl(url);
    setIsFetchingBoardUrl(false);
    if (!activeTile) {
      handleIframeFocus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [boardCode, miroBoardId]);

  useIframeController({
    iframeRef,
    onIframeClick: handleIframeFocus,
  });

  const isLoading = isWhiteboardLoading || isFetchingBoardUrl;

  return (
    <div
      data-testid="whiteboard-container"
      className={classnames(className, classes.whiteboardContainer)}
    >
      {isError && (
        <Container
          flex
          column
          justifyContent="center"
          alignItems="center"
          data-testid="whiteboard-error"
        >
          <Typography variant="h5">{t("error.whiteboard.load")}</Typography>
          <Button
            data-testid="try-again-button"
            variant="primary"
            color="blue"
            className={classes.tryAgainButton}
            onClick={createNewWhiteboard}
          >
            {t("whiteboard.try.again")}
          </Button>
        </Container>
      )}
      {isLoading && !isError && (
        <div
          data-testid="progress-indicator"
          className={classes.progressIndicator}
        >
          <CircularProgress />
          <Typography variant="h5">{t("wait.whiteboard.load")}</Typography>
        </div>
      )}
      {readonlyBoard && <BroadcastBoardOverlay />}
      {!isLoading && !isError && boardUrl && (
        <iframe
          ref={iframeCallbackRef}
          title="Miro Whiteboard"
          data-testid="board-iframe"
          key={boardUrl}
          width="100%"
          height="100%"
          allow="fullscreen"
          src={boardUrl}
        />
      )}
      <Box className={classes.buttonBox}>
        {showCloseButton && (
          <Fab
            data-testid="close-button"
            size="medium"
            disableRipple
            className={classnames(
              classes.whiteboardFabButton,
              classes.closeButton,
            )}
            onClick={handleCloseClick}
          >
            <Close />
          </Fab>
        )}
        {showImportButton && (
          <Tooltip title={t("import.board.text") as string}>
            <Fab
              data-testid="import-button"
              size="medium"
              disableRipple
              className={classnames(classes.whiteboardFabButton)}
              onClick={handleImportClick}
            >
              <PlayForWork />
            </Fab>
          </Tooltip>
        )}
      </Box>
    </div>
  );
};

export default MiroWhiteboard;
