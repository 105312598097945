import { sendGetRequest } from "services/apiService";
import { EventTableSetting, SettingUser, TableData } from "./types";

interface EventTableSettingArrayResponse extends IGenericAPIResponse {
  assignedAttendees: SettingUser[];
  tableSettings: EventTableSetting[];
  tableData: TableData;
  reason?: string; // EventTableSetting module errors
}

export const getEventTableSettings = (
  eventId: string,
  presetId?: string,
): Promise<EventTableSettingArrayResponse> =>
  sendGetRequest<EventTableSettingArrayResponse>(
    `/event/${eventId}/table-settings${
      presetId ? `?presetId=${presetId}` : ""
    }`,
  );

export const getEventTableSettingPresets = (
  eventId: string,
): Promise<APIResponse<{ presets: string[] }>> =>
  sendGetRequest<APIResponse<{ presets: string[] }>>(
    `/event/${eventId}/table-setting-presets`,
  );
