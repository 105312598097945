import { addErrorNotification } from "modules/notification/redux/notificationSlice";
import { useSelector } from "react-redux";
import { selectUserId } from "modules/auth/redux/selectors";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Errors, trackError } from "modules/monitoring";
import { useI18n } from "i18n";
import {
  NETWORKING_RECOMMENDATIONS_QUERY_KEY,
  NetworkingRecommendationsErrors,
} from "modules/registration/constants";
import { updateNetworkingRecommendation } from "./request";

export const useNetworkingRecommendationsOperations = (
  onUpdateSuccess: (isNetworkingRecommendationsEnabled: boolean) => void,
  eventId?: string,
) => {
  const userId = useSelector(selectUserId);
  const queryClient = useQueryClient();
  const { t } = useI18n(["eventForm"]);

  const {
    mutateAsync: updateNetworkingRecommendations,
    mutate: updateNetworkingRecommendationsSync,
    isPending: isUpdateNetworkingRecommendationsLoading,
    status: updateNetworkingRecommendationsStatus,
  } = useMutation({
    mutationFn: async (isNetworkingRecommendationsEnabled: boolean) => {
      if (!eventId) {
        throw new Error(NetworkingRecommendationsErrors.NO_EVENT_ID);
      }

      return updateNetworkingRecommendation(
        eventId,
        isNetworkingRecommendationsEnabled,
      );
    },

    onSuccess: (questions, networkingRecommendationsEnabled) => {
      queryClient.setQueryData(
        [NETWORKING_RECOMMENDATIONS_QUERY_KEY, eventId, userId],
        questions,
      );
      onUpdateSuccess(networkingRecommendationsEnabled);
    },

    onError: (error: Error, _) => {
      addErrorNotification({
        message: t("networking.recommendations.updating.failed", {
          error: error.message,
        }),
        position: "tc",
      });
      trackError(error, {
        label: Errors.SWITCH_NETWORKING_RECOMMENDATIONS,
      });
    },
  });
  return {
    updateNetworkingRecommendations,
    updateNetworkingRecommendationsSync,
    isUpdateNetworkingRecommendationsLoading,
    updateNetworkingRecommendationsStatus,
  };
};
