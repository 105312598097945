import { useEffect } from "react";
import { useQuery } from "@apollo/client";
import {
  GetEventFloorContentQuery,
  GetEventFloorContentQueryVariables,
  OnEventFloorContentChangedSubscription,
  OnEventFloorContentChangedSubscriptionVariables,
  OnEventFloorContentDeletedSubscription,
  OnEventFloorContentDeletedSubscriptionVariables,
} from "graphql/generated";
import { Errors, trackError } from "modules/monitoring";
import { usePrevious } from "helpers/reactHooksUtils";
import { GET_EVENT_FLOOR_CONTENT } from "../../graphql/queries";
import {
  ON_EVENT_FLOOR_CONTENT_CHANGED,
  ON_EVENT_FLOOR_CONTENT_DELETED,
} from "../../graphql/subscriptions";
import {
  updateEventFloorContentList,
  removeDeletedEventFloorContent,
} from "../../utils";

interface SubscriptionParams {
  eventId: string;
  floorId: string;
}

export const useEventFloorContentSubscription = ({
  eventId,
  floorId,
}: SubscriptionParams) => {
  const prevFloorId = usePrevious(floorId);
  const { subscribeToMore, loading, data, error, refetch } = useQuery<
    GetEventFloorContentQuery,
    GetEventFloorContentQueryVariables
  >(GET_EVENT_FLOOR_CONTENT, {
    variables: {
      event: eventId,
      floor: floorId,
    },
  });

  if (error) {
    trackError(error, { label: Errors.EVENT_FLOOR_CONTENT_QUERY });
  }

  useEffect(() => {
    if (prevFloorId && floorId && prevFloorId !== floorId) {
      refetch({
        event: eventId,
        floor: floorId,
      });
    }
  }, [eventId, floorId, prevFloorId, refetch]);

  useEffect(() => {
    const unsubscribeFromChanges = subscribeToMore<
      OnEventFloorContentChangedSubscription,
      OnEventFloorContentChangedSubscriptionVariables
    >({
      document: ON_EVENT_FLOOR_CONTENT_CHANGED,
      variables: { event: eventId, floor: floorId },
      onError: (error) => {
        trackError(error, {
          label: Errors.EVENT_FLOOR_CONTENT_CHANGED_SUBSCRIPTION,
        });
      },
      updateQuery: updateEventFloorContentList,
    });

    const unsubscribeFromDeletions = subscribeToMore<
      OnEventFloorContentDeletedSubscription,
      OnEventFloorContentDeletedSubscriptionVariables
    >({
      document: ON_EVENT_FLOOR_CONTENT_DELETED,
      variables: { event: eventId, floor: floorId },
      onError: (error) => {
        trackError(error, {
          label: Errors.EVENT_FLOOR_CONTENT_DELETED_SUBSCRIPTION,
        });
      },
      updateQuery: removeDeletedEventFloorContent,
    });

    return () => {
      unsubscribeFromChanges();
      unsubscribeFromDeletions();
    };
  }, [eventId, floorId, subscribeToMore]);

  return {
    data,
    loading,
    error,
  };
};
