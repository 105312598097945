import getRoomId from "modules/room/utils";
import store, { RootState } from "store";

export const getReducerState = <T extends keyof RootState>(
  name: T,
): RootState[T] => {
  const state = store.getState();

  return state[name];
};

export const getCurrentSpaceId = () => {
  const spaceState = getReducerState("space");

  return spaceState.spaceId;
};

export const getCurrentUserId = () => {
  const authState = getReducerState("auth");

  return (authState && authState.user && authState.user.id) || null;
};

export const getCurrentRoomId = (): string | null => {
  const userId = getReducerState("auth")?.user?.id;
  const userRoomStatus = getReducerState("userRoomStatus");

  if (userId) {
    return getRoomId(userId, userRoomStatus);
  }

  return null;
};

export const getCurrentEventId = () => {
  const state: RootState = store.getState();
  const eventState = state.event;

  return eventState && eventState.currentEventId;
};

// eslint-disable-next-line consistent-return
export const getCurrentEvent = () => {
  const state: RootState = store.getState();
  const eventState = state.event;

  if (eventState && eventState.currentEventId) {
    return eventState.events && eventState.events[eventState.currentEventId];
  }
};

export const getEventManagerIds = () => {
  const event = getCurrentEvent();

  return event?.managers || [];
};
