import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";

export const useStyles = makeStyles((theme) => ({
  tooltipButton: {
    height: theme.spacing(3.75),
    padding: `${theme.spacing(1.3125)} ${theme.spacing(1.875)} ${theme.spacing(
      1.3125,
    )} ${theme.spacing(1.875)}`,
    borderRadius: theme.spacing(0.625),
  },
  tooltipButtons: {
    display: "flex",
    gap: theme.spacing(1.25),
    flexDirection: "column",
  },
  tooltipText: {
    color: theme.palette.white,
  },
  avatarGroup: {
    justifyContent: "center",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(2),
  },
}));
