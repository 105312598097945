import {
  sendDeleteRequest,
  sendPostRequest,
  sendPutRequest,
} from "services/apiService";
import { ICustomRegistrationQuestion, IQuestionOrder } from "../../types";

export const getRequestURL = (eventId: string) => `/event/${eventId}/questions`;
export const getReorderRequestURL = (eventId: string) =>
  `/event/${eventId}/questions/reorder`;
export const getExistingQuestionRequestURL = (
  eventId: string,
  questionId: string,
) => `/event/${eventId}/questions/${questionId}`;

export const reorderQuestionsRequest = async (
  eventId: string,
  reorderedQuestionIds: IQuestionOrder[],
): Promise<ICustomRegistrationQuestion[]> => {
  const resp = await sendPostRequest<
    { reorderedQuestionIds: IQuestionOrder[] },
    APIResponse<{
      questions: ICustomRegistrationQuestion[];
    }>
  >(getReorderRequestURL(eventId), {
    reorderedQuestionIds,
  });

  if (!resp.isSuccess) {
    throw new Error(resp?.message);
  }

  return resp.questions;
};

export const createQuestionRequest = async (
  eventId: string,
  question: Partial<ICustomRegistrationQuestion>,
): Promise<ICustomRegistrationQuestion[]> => {
  const resp = await sendPostRequest<
    { question: Partial<ICustomRegistrationQuestion> },
    APIResponse<{
      questions: ICustomRegistrationQuestion[];
    }>
  >(getRequestURL(eventId), {
    question,
  });

  if (!resp.isSuccess) {
    throw new Error(resp?.message);
  }

  return resp.questions;
};

export const updateQuestionRequest = async (
  eventId: string,
  questionId: string,
  question: Partial<ICustomRegistrationQuestion> & { _id: string },
): Promise<ICustomRegistrationQuestion[]> => {
  const resp = await sendPutRequest<
    { question: Partial<ICustomRegistrationQuestion> },
    APIResponse<{
      questions: ICustomRegistrationQuestion[];
    }>
  >(getExistingQuestionRequestURL(eventId, questionId), {
    question,
  });

  if (!resp.isSuccess) {
    throw new Error(resp?.message);
  }

  return resp.questions;
};

export const deleteQuestionRequest = async (
  eventId: string,
  questionId: string,
): Promise<ICustomRegistrationQuestion[]> => {
  const resp = await sendDeleteRequest<
    Record<string, never>,
    APIResponse<{
      questions: ICustomRegistrationQuestion[];
    }>
  >(getExistingQuestionRequestURL(eventId, questionId));

  if (!resp.isSuccess) {
    throw new Error(resp?.message);
  }

  return resp.questions;
};
