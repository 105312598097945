import { trackWarning, Warnings } from "modules/monitoring";
import { UAParser } from "ua-parser-js";
import { SystemInfo } from "modules/systemCheck/types";
import { BROWSER_NAMES, SUPPORT_STATUS, OS_NAMES } from "../../constants";
import { checkBrowserSupport } from "./checkBrowserSupport";
import { checkOsSupport } from "./checkOsSupport";
import { isBrowserName, isOsName } from "../../typeGuards";

const getBrowserInfo = (
  browserName?: string,
  browserVersion?: string,
  isMobile?: boolean,
) => ({
  browserName:
    browserName && isBrowserName(browserName)
      ? browserName
      : BROWSER_NAMES.UNKNOWN,
  browserVersion: browserVersion || "",
  isMobile: isMobile || false,
});

const getBrowserInfoFromUserAgentData = (userAgentData: NavigatorUAData) => {
  const KNOWN_BROWSER_NAMES = Object.values(BROWSER_NAMES);

  const brand = userAgentData.brands.find(({ brand }) =>
    KNOWN_BROWSER_NAMES.includes(brand as BROWSER_NAMES),
  );

  return getBrowserInfo(brand?.brand, brand?.version, userAgentData.mobile);
};

const parseUserAgent = () => {
  const parser = new UAParser();
  const { userAgentData } = window.navigator;
  const result = parser.getResult();
  const { os, browser } = result;
  const osName =
    os.name && isOsName(os.name) ? (os.name as OS_NAMES) : OS_NAMES.UNKNOWN;
  const osVersion = os.version || "";

  const { browserName, browserVersion, isMobile } = userAgentData
    ? getBrowserInfoFromUserAgentData(userAgentData)
    : getBrowserInfo(
        browser.name,
        browser.version,
        osName === OS_NAMES.ANDROID || osName === OS_NAMES.IOS,
      );

  return {
    browserName,
    browserVersion,
    osName,
    osVersion,
    isMobile,
  };
};

export const checkSystemInfo = (): SystemInfo => {
  const { browserName, browserVersion, osName, osVersion, isMobile } =
    parseUserAgent();

  const browserSupport = checkBrowserSupport(browserName, browserVersion);

  if (browserSupport.status === SUPPORT_STATUS.UNSUPPORTED) {
    trackWarning(Warnings.GEARTEST_BROWSER_NOT_SUPPORTED);
  }

  const osSupported = checkOsSupport(osName, osVersion);

  if (!osSupported) {
    trackWarning(Warnings.GEARTEST_OS_NOT_SUPPORTED);
  }

  return {
    browserName,
    browserSupport,
    browserVersion,
    isMobile,
    osName,
    osVersion,
    osSupported,
  };
};
