import { getVar } from "config";
import logger from "logging/logger";
import { sdk } from "@symblai/symbl-js/build/client.sdk.min";

export const initSymblSdk = (): void => {
  sdk
    .init({
      appId: getVar("REACT_APP_SYMBL_APP_ID"),
      appSecret: getVar("REACT_APP_SYMBL_APP_SECRET"),
      basePath: getVar("REACT_APP_SYMBL_BASE_PATH"),
    })
    // eslint-disable-next-line promise/prefer-await-to-then
    .catch((err: Error) =>
      logger.error("[initSymblSdk]  Error in Symbl initialization.", { err }),
    );
};
