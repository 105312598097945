import { useTranslation } from "react-i18next";

const useI18n: typeof useTranslation = (...args) => {
  const result = useTranslation(...args);

  return {
    ...result,
  };
};

export default useI18n;
