import {
  sendDeleteRequest,
  sendPostRequest,
  sendPutRequest,
} from "services/apiService";

export type LiveStreamPayload = {
  eventId: string;
  serverUrl: string;
  playbackUrl: string;
  streamKey: string;
  destinationName: string;
  streamId?: string;
};

export type LiveStreamData = {
  serverUrl: string;
  playbackUrl: string;
  streamKey: string;
  destinationName: string;
  id: string;
};

type LiveStreamResponse =
  | {
      status: boolean;
      reason?: string;
      message?: string;
      data?: LiveStreamData;
    }
  | {
      status: true;
      data: LiveStreamData;
    };

export type DeleteLiveStreamPayload = {
  streamId: string;
  eventId: string;
};

export const addLiveStreamRequest = async ({
  eventId,
  ...payload
}: LiveStreamPayload) => {
  const resp = await sendPostRequest<
    Omit<LiveStreamPayload, "eventId">,
    LiveStreamResponse
  >(`/event/${eventId}/live-stream`, payload);

  if (!resp.status) {
    throw new Error(resp?.reason ?? resp?.message);
  }

  return resp;
};

export const updateLiveStreamRequest = async ({
  eventId,
  streamId,
  ...payload
}: LiveStreamPayload) => {
  const resp = await sendPutRequest<
    Omit<LiveStreamPayload, "eventId">,
    LiveStreamResponse
  >(`/event/${eventId}/live-stream/${streamId}`, payload);
  if (!resp.status) {
    throw new Error(resp?.reason ?? resp?.message);
  }

  return resp;
};

export const deleteLiveStreamRequest = async ({
  eventId,
  streamId,
}: DeleteLiveStreamPayload) => {
  const resp = await sendDeleteRequest<
    Omit<LiveStreamPayload, "eventId">,
    LiveStreamResponse
  >(`/event/${eventId}/live-stream/${streamId}`);
  if (!resp.status) {
    throw new Error(resp?.reason ?? resp?.message);
  }

  return resp;
};
