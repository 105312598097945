import classNames from "classnames";
import { Box } from "@remo-co/ui-core/src/components/Box";
import { Typography } from "@remo-co/ui-core/src/components/Typography";
import { EventContentType } from "graphql/generated";
import { useStyles } from "./styles";

interface Props {
  media: string;
  contentType: EventContentType;
}

export const TextMedia = ({ media, contentType }: Props) => {
  const styles = useStyles();

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      className={classNames(styles.root, {
        [styles.dialog]: contentType === EventContentType.FullScreen,
      })}
    >
      <Typography variant="h4" className={styles.text}>
        {media}
      </Typography>
    </Box>
  );
};
