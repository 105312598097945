import {
  ClosedCaptionButton,
  Transcription,
  TranscriptionContext,
  TranscriptionFeed,
  TranscriptionProfileInfo,
  TranscriptionProvider,
  TranscriptionSidebar,
} from "./components";
import {
  getSymblToken,
  initSymblSdk,
  sendBuffer,
  startTranscriptionRequest,
  stopTranscriptionRequest,
} from "./services";
import {
  useTranscriptionPublisher,
  useTranscriptionSidebar,
  useTranscriptionSubscriber,
  useTranscriptionRequests,
} from "./hooks";
import {
  handleAudioProcess,
  handleClose,
  handleError,
  handleMessage,
  handleOpen,
} from "./handlers";
import { transcriptionSlice, toggleShowCaptions, hideCaptions } from "./redux";

export {
  Transcription,
  TranscriptionContext,
  TranscriptionFeed,
  TranscriptionProfileInfo,
  TranscriptionSidebar,
  useTranscriptionSubscriber,
  useTranscriptionPublisher,
  useTranscriptionSidebar,
  useTranscriptionRequests,
  getSymblToken,
  initSymblSdk,
  sendBuffer,
  startTranscriptionRequest,
  stopTranscriptionRequest,
  handleMessage,
  handleOpen,
  handleClose,
  handleError,
  handleAudioProcess,
  transcriptionSlice,
  toggleShowCaptions,
  hideCaptions,
  ClosedCaptionButton,
};

export default TranscriptionProvider;
