import { SVGRoomNames } from "modules/customFloorPlan/constants";
import { IRoom } from "types";
import { Table, TableWithId } from "../../types";

// filters un-assignable rooms and sorts table names alphabetically
const getAssignableTables = (
  tables: Record<string, Table>,
  templateTables: IRoom[],
  planLimit?: number,
): Array<TableWithId> =>
  Object.entries(tables)
    .filter(
      ([_, table]) =>
        !(Object.values(SVGRoomNames) as string[]).includes(table.code),
    )
    .map(([id, table]) => {
      const templateData = templateTables.find(
        (templateTable) => templateTable.code === table.code,
      ) as IRoom;

      return {
        ...table,
        id,
        limit: planLimit
          ? Math.min(templateData.positions.length, planLimit)
          : templateData.positions.length,
      };
    })
    .sort((a, b) => a.code.localeCompare(b.code));

export default getAssignableTables;
