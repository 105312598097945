import { ref, remove, set } from "firebase/database";
import { database } from "services/firebaseService/firebaseConfig";
import { THEATER_PATH } from "modules/theater/firebase";
import { InviteToStageStatus } from "./constants";

const getBroadcasterDocRef = (theaterId: string, userId?: string) => {
  const basePath = `${THEATER_PATH}/${theaterId}/Broadcaster`;
  const fullPath = userId ? `${basePath}/${userId}` : basePath;
  return ref(database, fullPath);
};

const giveBroadcastRight = (theaterId: string, userId: string) => {
  const dbRef = getBroadcasterDocRef(theaterId, userId);

  return set(dbRef, {
    status: InviteToStageStatus.SUCCESS,
  });
};

const takeBroadcastRight = (theaterId: string, userId: string) => {
  const dbRef = getBroadcasterDocRef(theaterId, userId);

  return remove(dbRef);
};

const removeBroadcaster = (theaterId: string) => {
  if (!theaterId) {
    return false;
  }

  return remove(getBroadcasterDocRef(theaterId));
};

export default {
  getBroadcasterDocRef,
  giveBroadcastRight,
  takeBroadcastRight,
  removeBroadcaster,
};
