import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export const initialState = {
  isVisible: false,
};

export const slice = createSlice({
  name: "assignedTableNudge",
  initialState,
  reducers: {
    setVisible: (state, action: PayloadAction<boolean>) => {
      state.isVisible = action.payload;
    },
  },
});

export const { setVisible } = slice.actions;

export default slice.reducer;
