import { useClient } from "@splitsoftware/splitio-react";
import { selectCurrentEventCode } from "modules/event/selectors";
import { useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { EventFeatures } from "services/splitService/features";
import { updateFeatureFlagInContext } from "modules/monitoring";
import logger from "logging/logger";

const useEventSplitTreatment = (
  feature: EventFeatures,
  fallbackEventCode?: string,
  timeout = 10000,
): {
  treatment: string;
  featureEnabled: boolean;
  treatmentStale: boolean;
} => {
  const { pathname } = window.location;
  const eventCodeFromLandingPage = useMemo(() => {
    // eslint-disable-next-line prefer-regex-literals
    const eventLandingURIRegExp = new RegExp(/e\/([A-Za-z0-9._%-]*)\/register/);
    if (eventLandingURIRegExp.test(pathname)) {
      const eventCode = pathname.match(eventLandingURIRegExp);
      return eventCode ? eventCode[1] : null;
    }
    return null;
  }, [pathname]);

  const currentEventCode =
    useSelector(selectCurrentEventCode) ?? eventCodeFromLandingPage;
  const splitEventCode = currentEventCode ?? fallbackEventCode;
  const eventClient = useClient(splitEventCode, "Event");
  const [readyEventClient, setReadyEventClient] = useState<SplitIO.IClient>();
  const [error, setError] = useState<boolean>(false);
  const timeoutRef = useRef<NodeJS.Timeout>();
  const [isTimedOut, setIsTimedOut] = useState(false);
  const [treatmentStale, setTreatmentStale] = useState(false);

  useEffect(() => {
    timeoutRef.current = setTimeout(() => setIsTimedOut(true), timeout);

    return () => clearTimeout(timeoutRef.current);
  }, [timeout]);

  useEffect(() => {
    eventClient
      ?.ready()
      // eslint-disable-next-line promise/always-return, promise/prefer-await-to-then
      .then(() => {
        setReadyEventClient(eventClient);
      })
      .catch((splitError) => {
        logger.error(`Split failed to load ${splitError}`);
        setError(true);
      })
      .finally(() => {
        clearTimeout(timeoutRef.current);
      });
  }, [eventClient]);

  const treatment = useMemo(() => {
    if (error || isTimedOut) {
      return "error";
    }

    if (!readyEventClient) {
      return "pending";
    }

    if (splitEventCode) {
      const resolvedTreatment = readyEventClient.getTreatment(feature);
      updateFeatureFlagInContext(feature, resolvedTreatment);
      return resolvedTreatment;
    }

    return "control";
  }, [readyEventClient, error, splitEventCode, feature, isTimedOut]);

  useEffect(() => {
    if (!readyEventClient) {
      return undefined;
    }

    const splitUpdateHandler = () => {
      if (treatment === "pending") {
        return;
      }
      const newSplitTreatment = readyEventClient.getTreatment(feature);
      setTreatmentStale(newSplitTreatment !== treatment);
    };
    readyEventClient.addListener(
      readyEventClient.Event.SDK_UPDATE,
      splitUpdateHandler,
    );

    return () => {
      readyEventClient.removeListener(
        readyEventClient.Event.SDK_UPDATE,
        splitUpdateHandler,
      );
    };
  }, [readyEventClient, treatment, feature]);

  return { treatment, featureEnabled: treatment === "on", treatmentStale };
};

export default useEventSplitTreatment;
