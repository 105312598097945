import { DeprecatedThemeOptions } from "@mui/material/styles";
import { typography } from "./typography";

export const baseTheme: Pick<
  DeprecatedThemeOptions,
  "props" | "breakpoints" | "typography"
> = {
  props: {
    MuiModal: {
      disableEnforceFocus: true,
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 640,
      md: 720,
      lg: 960,
      xl: 1140,
    },
  },
  typography,
};
