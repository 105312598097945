import CircularProgress from "@mui/material/CircularProgress";
import { Colors } from "@remo-co/ui-core/theme";
import { useStyles } from "./Loader.styles";

export interface ILoaderProps {
  backgroundColor?: Colors;
  loaderColor?: Colors;
}

const Loader = (props: ILoaderProps): JSX.Element => {
  const styles = useStyles(props);

  return (
    <div data-testid="core-loader" className={styles.root}>
      <CircularProgress color="inherit" />
    </div>
  );
};

export default Loader;
