import { ICompanyServicePlan } from "modules/company/types";
import { IRoom } from "types";

export const getMaxAllowedInTable = (
  room: IRoom,
  plan: ICompanyServicePlan | null,
) => {
  const capacity = room.positions.length;

  return plan?.settings
    ? Math.min(capacity, plan.settings.theater.maxInTable)
    : capacity;
};
