import { useNetwork } from "@daily-co/daily-react";
import { usePrevious } from "helpers/reactHooksUtils";
import { Events, TRACKING_CONTEXT } from "modules/tracking";
import { useContext, useEffect, useState } from "react";

export const DAILY_LOCAL_NETWORK_KEY = "daily-local-network";

export const useDailyLocalNetwork = () => {
  const { track } = useContext(TRACKING_CONTEXT);
  // initial seeding states
  const storedIsP2PInterrupted = localStorage.getItem(
    `${DAILY_LOCAL_NETWORK_KEY}-p2p`,
  );

  const storedIsSFUInterrupted = localStorage.getItem(
    `${DAILY_LOCAL_NETWORK_KEY}-sfu`,
  );

  const storedIsSignalingInterrupted = localStorage.getItem(
    `${DAILY_LOCAL_NETWORK_KEY}-signaling`,
  );

  const [isSignalingInterrupted, setIsSignalingInterrupted] = useState(
    Boolean(
      storedIsSignalingInterrupted && storedIsSignalingInterrupted === "true",
    ),
  );
  const [isP2PInterrupted, setIsP2PInterrupted] = useState(
    Boolean(storedIsP2PInterrupted && storedIsP2PInterrupted === "true"),
  );
  const [isSFUInterrupted, setIsSFUInterrupted] = useState(
    Boolean(storedIsSFUInterrupted && storedIsSFUInterrupted === "true"),
  );
  const isInterrupted =
    isSignalingInterrupted || isP2PInterrupted || isSFUInterrupted;
  const prevIsInterrupted = usePrevious(isInterrupted);
  const { threshold: networkQuality } = useNetwork({
    onNetworkConnection: (ev) => {
      switch (ev.type) {
        case "sfu": {
          const incomingIsSFUInterrupted = ev.event === "interrupted";
          setIsSFUInterrupted(ev.event === "interrupted");
          localStorage.setItem(
            `${DAILY_LOCAL_NETWORK_KEY}-sfu`,
            `${incomingIsSFUInterrupted}`,
          );
          break;
        }
        case "peer-to-peer": {
          const incomingIsP2PInterrupted = ev.event === "interrupted";
          setIsP2PInterrupted(incomingIsP2PInterrupted);
          localStorage.setItem(
            `${DAILY_LOCAL_NETWORK_KEY}-p2p`,
            `${Boolean(incomingIsP2PInterrupted)}`,
          );
          break;
        }

        case "signaling":
        default: {
          const incomingIsSignalingInterrupted = ev.event === "interrupted";
          setIsSignalingInterrupted(incomingIsSignalingInterrupted);
          localStorage.setItem(
            `${DAILY_LOCAL_NETWORK_KEY}-signaling`,
            `${Boolean(incomingIsSignalingInterrupted)}`,
          );
          break;
        }
      }
    },
  });

  useEffect(() => {
    if (prevIsInterrupted !== isInterrupted) {
      track(
        isInterrupted
          ? Events.INTERRUPTED_NETWORK_CONNECTION
          : Events.INTERRUPTED_NETWORK_CONNECTION_RECOVERED,
      );
    }
  }, [prevIsInterrupted, isInterrupted, track]);

  return {
    isInterrupted,
    networkQuality,
  };
};
