import { Theme } from "@remo-co/ui-core/src/types";
import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";

interface Props {
  isWhiteBoardFocused?: boolean;
}

export const useStyles = makeStyles<Theme, Props>(() => ({
  fullScreenButtonWrapper: {
    height: "fit-content",
    pointerEvents: "all",
    position: "absolute",
    right: ({ isWhiteBoardFocused }) => (isWhiteBoardFocused ? 10 : 0),
    top: 0,
    width: "fit-content",
    zIndex: 3,
  },
  fullScreen: {
    position: "absolute",
    right: 0,
    top: 0,
    bottom: 0,
    left: 0,
    height: "fit-content",
  },
  container: {
    position: "relative",
    width: "fit-content",
  },
}));
