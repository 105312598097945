import {
  useRef,
  useState,
  useCallback,
  useEffect,
  useLayoutEffect,
} from "react";
import { useSelector } from "react-redux";
import { Virtuoso, VirtuosoHandle } from "react-virtuoso";
import {
  makeSelectActiveChannel,
  makeSelectChatForActiveChannel,
  makeSelectChatIsLoading,
} from "modules/chat/redux/selectors";
import { CHAT_TYPE } from "modules/chat/redux/constants";
import debounce from "lodash/debounce";
import { Dialog } from "@remo-co/ui-core/src/components/Dialog";
import { DialogContent } from "@remo-co/ui-core/src/components/DialogContent";
import { LinearProgress } from "@remo-co/ui-core/src/components/LinearProgress";
import useChatFirestore from "modules/chat/firestore/useChatFirestore";
import { selectUser } from "modules/auth/redux/selectors";
import { selectIsEventManager } from "modules/event/selectors";
import { IChat } from "modules/chat/types";
import Message from "../messagesView/Message";
import ChatModalImageHeader from "../messagesView/types/ChatModalImageHeader";
import useStyles from "./styles";
import { MESSAGE_LIST_STATE_KEY } from "../messagesView/constants";

const MessageList = () => {
  const styles = useStyles();
  const listRef = useRef<VirtuosoHandle>(null);
  const messages = useSelector(makeSelectChatForActiveChannel());
  const [imageUrl, setImageUrl] = useState<string | undefined>(undefined);
  const isChatLoading = useSelector(makeSelectChatIsLoading());
  const activeChannel = useSelector(makeSelectActiveChannel);
  const { getMoreChats, updateLastAccessTimeForChannel } = useChatFirestore();
  const user = useSelector(selectUser);
  const [isAtBottom, setIsAtBottom] = useState(true);
  const [hasInitiallyScrolled, setHasInitiallyScrolled] = useState(false);
  const hasEventManagerAccess = useSelector(selectIsEventManager);

  const serializedListStateSnapshot = localStorage.getItem(
    MESSAGE_LIST_STATE_KEY,
  );

  useEffect(() => {
    if (!activeChannel.newChannel) {
      // If channel is changed and it does not have any message, then load messages
      // If paginateAfter is not set, then this channel is not loaded till now, so start loading messages
      // If it is room, show messages only from when you have landed in the room
      // Cases to verify: open chat from avatar hover, open chat by clicking browser notification
      // TODO: check how to get prev props
      const prevProps: typeof activeChannel = {};

      if (
        !isChatLoading &&
        activeChannel.type !== CHAT_TYPE.ROOM &&
        activeChannel.id !== (prevProps.channel && prevProps.channel.id) &&
        !activeChannel.paginateAfter &&
        (!messages || !messages.size)
      ) {
        getMoreChats(activeChannel);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeChannel]);

  useEffect(() => {
    if (!listRef.current || !messages) {
      return undefined;
    }

    let timeoutId: number;
    const latestMessage = messages.get(messages.size - 1);

    if (isAtBottom && latestMessage && !hasInitiallyScrolled) {
      timeoutId = window.setTimeout(() => {
        listRef.current?.scrollToIndex(messages.size - 1);
      }, 500);
      setHasInitiallyScrolled(true);
    }

    return () => {
      if (timeoutId) window.clearTimeout(timeoutId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messages]);

  const handleGifLoaded = () => {
    if (listRef.current && messages && isAtBottom) {
      listRef.current.scrollToIndex(messages.size - 1);
    }
  };

  const atListBottomStateChange = useCallback((atBottom: boolean) => {
    setIsAtBottom(atBottom);
  }, []);

  useEffect(() => {
    if (user) {
      updateLastAccessTimeForChannel(user.id, activeChannel);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messages, user?.id, activeChannel]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleLoadMore = useCallback(
    debounce(() => {
      if (activeChannel.type !== CHAT_TYPE.ROOM && !isChatLoading) {
        getMoreChats(activeChannel);
      }
    }, 2000),
    [activeChannel, isChatLoading, getMoreChats],
  );

  useLayoutEffect(() => {
    const listRefCurrent = listRef.current;

    return () => {
      if (listRefCurrent) {
        listRefCurrent.getState((state) => {
          localStorage.setItem(MESSAGE_LIST_STATE_KEY, JSON.stringify(state));
        });
      }
    };
  }, []);

  const showImageModal = (url: string) => {
    setImageUrl(url);
  };

  return (
    <>
      {isChatLoading && <LinearProgress color="primary" />}
      {messages && (
        <Virtuoso
          className={styles.messagesList}
          ref={listRef}
          id="sc-message-list"
          data-testid="sc-message-list"
          totalCount={messages.size}
          followOutput
          firstItemIndex={0}
          initialTopMostItemIndex={0}
          startReached={hasInitiallyScrolled ? handleLoadMore : undefined}
          atBottomThreshold={4}
          atBottomStateChange={atListBottomStateChange}
          restoreStateFrom={
            serializedListStateSnapshot
              ? JSON.parse(serializedListStateSnapshot)
              : undefined
          }
          increaseViewportBy={200}
          defaultItemHeight={82}
          // eslint-disable-next-line react/no-unstable-nested-components
          itemContent={(index) => {
            const message = messages.get(index) as IChat;
            return (
              <Message
                onImageClick={showImageModal}
                onGifLoaded={handleGifLoaded}
                message={message}
                key={message.id}
                hasEventManagerAccess={hasEventManagerAccess}
                channelType={activeChannel.type}
              />
            );
          }}
        />
      )}
      <Dialog
        maxWidth="xl"
        open={!!imageUrl}
        onClose={() => setImageUrl(undefined)}
        aria-labelledby="Chat Image Modal"
        data-testid="chat-dialog"
      >
        <DialogContent style={{ padding: 4 }}>
          <ChatModalImageHeader url={imageUrl ?? ""} />
          <img
            style={{
              maxWidth: "100%",
              maxHeight: "calc(100vh - 104px)",
              minWidth: 100,
              display: "block",
              borderRadius: 3,
            }}
            src={imageUrl}
            alt=""
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default MessageList;
