import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";

export const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(1),
    color: theme.palette.blue.dark,
  },
  tooltipIcon: {
    marginLeft: theme.spacing(1),
    height: theme.spacing(2),
    width: theme.spacing(2),
  },
}));
