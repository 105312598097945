import { Theme } from "@remo-co/ui-core/src/types";
import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";

export const useStyles = makeStyles<Theme>((theme) => ({
  active: {
    "&.highlight": {
      fontSize: "7rem",
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.white,
    },
    "&:hover": {
      fontSize: "7rem",
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.white,
    },
  },
  root: {
    position: "absolute",
    display: "flex",
    cursor: "pointer",
    background: "transparent",
  },
  rootSlot: {
    fontSize: "4rem",
    justifyContent: "center",
    alignItems: "center",
    transition:
      "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, font-size 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    overflow: "hidden",
  },
  sponsorImg: {
    borderRadius: "4px",
    objectFit: "contain",
    width: "100%",
    height: "100%",
  },
}));
