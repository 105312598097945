import classNames from "classnames";
import { ContentWithMapping, EventContentMediaType } from "graphql/generated";
import { getEmbeddedVideoURL } from "helpers/videoHelper";
import { ISlot } from "modules/theater/types";
import VideoPlayerIFrame from "modules/videoPlayer/VideoPlayerIFrame";
import { IframeMedia } from "../IframeMedia";
import { TextMedia } from "../TextMedia";
import { useStyles } from "./styles";

interface Props {
  content: ContentWithMapping;
  slot: ISlot;
  preview?: boolean;
}

export const FloorContent = ({ content, slot, preview }: Props) => {
  const styles = useStyles();

  return (
    <div
      data-testid={`floor-content-${content.id}`}
      key={content.id}
      className={classNames(styles.root, {
        [styles.preview]: preview,
      })}
      style={{
        left: slot.x ?? "unset",
        top: slot.y ?? "unset",
        width: slot.width,
        height: slot.height,
      }}
    >
      {
        {
          [EventContentMediaType.Iframe]: (
            <IframeMedia
              media={content.media}
              name={content.name}
              contentType={content.type}
              hideOpenInNewTabButton={content.hideOpenInNewTabButton}
            />
          ),
          [EventContentMediaType.Image]: (
            <img
              src={content.media}
              alt={content.name}
              className={styles.imageMedia}
            />
          ),
          [EventContentMediaType.Video]: getEmbeddedVideoURL(content.media) && (
            <VideoPlayerIFrame
              src={getEmbeddedVideoURL(content.media) as string}
              allowFullScreen
            />
          ),
          [EventContentMediaType.Text]: (
            <TextMedia media={content.media} contentType={content.type} />
          ),
        }[content.mediaType]
      }
    </div>
  );
};
