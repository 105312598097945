import { useCompanyPlanSettings } from "modules/companyPlanSettings";
import { CompanyFeatures } from "services/splitService/features";
import useCompanySplitTreatment from "modules/split/useCompanySplitTreatment";
import { isWhiteLabellingEnabled } from "modules/companyPlanSettings/utils/whitelabel";
import { IEventPage } from "../types";
import { MANAGE_EVENT_PAGES, BASIC_SETTINGS } from "../constants";

const useManageEventPages = () => {
  const { featureEnabled: isCustomQuestionsEnabled } = useCompanySplitTreatment(
    CompanyFeatures.EVENT_REGISTRATION_QUESTIONS,
  );
  const { myCompanyPlan, isAgencyPlan } = useCompanyPlanSettings();

  const getAdvancedEventPages = (
    pages: IEventPage[] = MANAGE_EVENT_PAGES,
    hasSponsors?: boolean,
    isUnlimitedEvent?: boolean,
  ) =>
    pages.map((page: IEventPage) => {
      if (page.pageId === "registration") {
        return {
          ...page,
          isHidden: !isCustomQuestionsEnabled,
        };
      }
      if (page.pageId === "sponsors") {
        return {
          ...page,
          // only show tab if there are remaining sponsors saved
          isHidden: !hasSponsors,
        };
      }
      if (page.pageId === "branding") {
        return {
          ...page,
          // Only show tab for agencies with whitelabelling enabled
          isHidden: !(
            isWhiteLabellingEnabled(myCompanyPlan) &&
            isAgencyPlan(myCompanyPlan)
          ),
        };
      }

      if (page.pageId === "agenda") {
        return {
          ...page,
          isHidden: isUnlimitedEvent,
        };
      }
      if (page.pageId === "stream") {
        return {
          ...page,
          isHidden: isUnlimitedEvent,
        };
      }

      return page;
    });

  const getNextPageId = (
    currentPageId?: string,
    isEventPublished?: boolean,
    hasSponsors?: boolean,
    isUnlimitedEvent?: boolean,
  ) => {
    const filteredPages = getAdvancedEventPages(
      MANAGE_EVENT_PAGES,
      hasSponsors ?? false,
      isUnlimitedEvent ?? false,
    );

    return filteredPages.reduce((result: string) => {
      const index = filteredPages.findIndex(
        (child: IEventPage) => child.pageId === currentPageId,
      );

      if (index === -1) return result;

      const remainingPages = filteredPages.slice(index + 1);
      const nextAccessiblePage = remainingPages.findIndex(
        (child: IEventPage) =>
          !!(
            !child.isHidden &&
            (!child.isDisabledInCreateEvent || isEventPublished)
          ),
      );

      if (nextAccessiblePage === -1) return result;
      const nextPageId = remainingPages[nextAccessiblePage].pageId;

      return nextPageId;
    }, BASIC_SETTINGS.pageId);
  };

  return { getAdvancedEventPages, getNextPageId };
};

export default useManageEventPages;
