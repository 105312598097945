import { Theme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import { IRemoLogoProps } from "./RemoLogo";

export const useStyles = makeStyles<Theme, IRemoLogoProps>(() => ({
  root: {
    display: "block",
    width: (props) => (props.size === "lg" ? 103 : 40),
  },
  customWidth: {
    width: (props) => props.width,
  },
}));
