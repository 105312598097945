import { useI18n } from "i18n";
import {
  selectMappedBroadcastAttendees,
  selectMappedBroadcastManagers,
  selectMappedBroadcastSpeakers,
  selectMappedBroadcastableAttendees,
} from "modules/event/usersInEvent/selectors";
import { useSelector } from "react-redux";
import { sortBy } from "lodash";
import { IUser } from "modules/app/types";
import { selectIsBroadcasting } from "modules/broadcast/redux/selectors";
import { selectIsEventManager } from "modules/event/selectors";
import { useCallback, useMemo } from "react";
import { UnraiseIcon } from "modules/eventButtons/icons/UnraiseIcon";
import useRaiseHandActions from "modules/broadcastRaiseHand/hooks/useRaiseHandActions";
import { selectCurrentTheaterId } from "modules/theater/selectors";
import { selectOnlineGuests } from "modules/presence/presence.selectors";
import { selectRaisedHandUsers } from "modules/broadcastRaiseHand/redux/selectors";
import { SectionMoreAction } from "../../types";
import {
  filterUsers,
  nameSortFunc,
  sortByRaisedHandFirst,
} from "../../sectionContainerHelpers";

export enum ParticipantGroup {
  ATTENDEES_CONVERSATION = "ATTENDEES_CONVERSATION",
  ATTENDEES_ON_STAGE = "ATTENDEES_ON_STAGE",
  ATTENDEES_PRESENTATION = "ATTENDEES_PRESENTATION",
  MANAGERS = "MANAGERS",
  REQUESTS = "REQUESTS",
  SPEAKERS = "SPEAKERS",
}

type GroupData = {
  [K in ParticipantGroup]?: {
    heading: string;
    participants: IUser[];
    moreActions?: SectionMoreAction[];
    sortByFn?: (user: IUser) => string;
  };
};

export const useParticipantGroups = (query = "") => {
  const { t } = useI18n(["common", "event"]);
  const raisedHandUsers = useSelector(selectRaisedHandUsers);
  const isBroadcasting = useSelector(selectIsBroadcasting);
  const hasEventManagerAccess = useSelector(selectIsEventManager);
  const attendees = useSelector(selectMappedBroadcastAttendees);
  const convAttendees = useSelector(selectOnlineGuests);
  const managers = useSelector(selectMappedBroadcastManagers);
  const speakers = useSelector(selectMappedBroadcastSpeakers);
  const allowedToBroadcastUsers = useSelector(
    selectMappedBroadcastableAttendees,
  );

  const { unraiseAllHands } = useRaiseHandActions();
  const theaterId = useSelector(selectCurrentTheaterId);
  const onLowerAllHandsClick = useCallback(() => {
    if (theaterId) {
      unraiseAllHands(theaterId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [theaterId]);

  const broadcastAttendees = useMemo(() => {
    if (!attendees) return [];

    return attendees.map((attendee) => ({
      ...attendee,
      isRaisedHand: !!raisedHandUsers[attendee.id]?.raised,
    }));
  }, [attendees, raisedHandUsers]);

  const raisedHandUsersInEvent = useMemo(() => {
    const users = broadcastAttendees.filter(
      (attendee) => attendee.isRaisedHand,
    );

    return sortByRaisedHandFirst(raisedHandUsers, users);
  }, [broadcastAttendees, raisedHandUsers]);

  const groupData = useMemo(() => {
    const initialGroupData: GroupData = {
      [ParticipantGroup.MANAGERS]: {
        heading: t("common:host"),
        participants: managers,
      },
      [ParticipantGroup.SPEAKERS]: {
        heading: t("event:speakers"),
        participants: speakers,
      },
    };

    if (isBroadcasting) {
      initialGroupData[ParticipantGroup.ATTENDEES_ON_STAGE] = {
        heading: t("common:attendees.stage"),
        participants: allowedToBroadcastUsers,
      };

      if (hasEventManagerAccess) {
        initialGroupData[ParticipantGroup.REQUESTS] = {
          heading: t("requests"),
          participants: raisedHandUsersInEvent,
          moreActions: [
            {
              label: t("lower.all.hands"),
              icon: <UnraiseIcon />,
              onClick: onLowerAllHandsClick,
            },
          ],
        };
      }

      initialGroupData[ParticipantGroup.ATTENDEES_PRESENTATION] = {
        heading: t("common:attendees"),
        participants: broadcastAttendees,
        sortByFn: nameSortFunc,
      };
    } else {
      initialGroupData[ParticipantGroup.ATTENDEES_CONVERSATION] = {
        heading: t("common:attendees"),
        participants: convAttendees,
      };
    }

    return initialGroupData;
  }, [
    allowedToBroadcastUsers,
    broadcastAttendees,
    convAttendees,
    hasEventManagerAccess,
    isBroadcasting,
    managers,
    onLowerAllHandsClick,
    raisedHandUsersInEvent,
    speakers,
    t,
  ]);

  return useMemo(() => {
    const groups: string[] = [];
    const groupLengths: number[] = [];
    const headings: string[] = [];
    let participants: IUser[] = [];
    const actions: SectionMoreAction[][] = [];

    Object.entries(groupData).forEach(
      ([
        group,
        { participants: groupParticipants, heading, moreActions, sortByFn },
      ]) => {
        let filteredParticipants = filterUsers(
          query,
          groupParticipants,
          group === ParticipantGroup.MANAGERS,
        );

        if (sortByFn) {
          filteredParticipants = sortBy(filteredParticipants, sortByFn);
        }

        if (filteredParticipants.length) {
          groups.push(group);
          groupLengths.push(filteredParticipants.length);
          headings.push(heading);
          participants = [...participants, ...filteredParticipants];
          actions.push(moreActions || []);
        }
      },
    );

    return {
      groups,
      groupLengths,
      headings,
      participants,
      actions,
    };
  }, [groupData, query]);
};
