import { getVar } from "config";

export const SYMBL_TOKEN_REQUEST_OPTIONS = {
  method: "POST",
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
  body: JSON.stringify({
    type: "application",
    appId: getVar("REACT_APP_SYMBL_APP_ID"),
    appSecret: getVar("REACT_APP_SYMBL_APP_SECRET"),
  }),
};
