import React, { useCallback, useContext } from "react";
import { useSelector } from "react-redux";
import { stringify } from "query-string";
import useUserProfile from "modules/userProfile/hooks/useUserProfile";
import useNotificationActions from "modules/notification/hooks/useNotificationActions";
import { Events, TRACKING_CONTEXT } from "modules/tracking";
import { determineEventRecency } from "modules/tracking/amplitude/utils";
import useSystemClock from "modules/system/hooks/useSystemClock";
import { useI18n } from "i18n";
import { useHistory } from "react-router";
import logger from "logging/logger";
import {
  getEventMemberStatus,
  registerUserToEvent,
} from "services/apiService/apis";
import PerformanceLogger from "helpers/performanceHelper";
import { IEventMember } from "modules/eventMemberList/types";
import { EVENT_CONTEXT } from "../eventView/EventViewContext";
import { selectIsEventManager } from "../selectors";
import { IEvent } from "../types";

interface URLParams {
  autojoin?: "1";
}

const useRegisterToEvent = () => {
  const history = useHistory();
  const { getUserByEmail } = useUserProfile();
  const { addErrorNotification } = useNotificationActions();
  const { setEventMember } = React.useContext(EVENT_CONTEXT);
  const hasEventManagerAccess = useSelector(selectIsEventManager);
  const { serverTime } = useSystemClock();
  const { track } = useContext(TRACKING_CONTEXT);

  const { t } = useI18n(["common", "event", "server"]);

  const checkIfRecentAndTrack = (
    eventId: string,
    eventMember: IEventMember,
  ) => {
    try {
      const { createdAt } = eventMember;

      const isEventRecent = determineEventRecency({
        eventTime: createdAt,
        currentTime: serverTime,
        maxAgeInMinutes: 1,
      });

      if (isEventRecent) {
        track(Events.EVENT_REGISTRATION_SUCCESS, {
          event: eventId,
        });
      }
    } catch (error) {
      if (error instanceof Object) {
        logger.error(
          "[useRegisterToEvent][checkIfRecentAndTrack] error",
          error,
        );
      }
    }
  };

  /**
   * Register user into event
   * @param eventId String
   */
  // eslint-disable-next-line consistent-return
  const registerToEvent = async (eventId: string) => {
    const resp = await registerUserToEvent({
      eventId,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    });

    if (resp.isSuccess) {
      if (resp.eventMember) {
        setEventMember(resp.eventMember);
        checkIfRecentAndTrack(eventId, resp.eventMember);
      }

      return resp.eventMember;
    }
    logger.warn(
      `[useRegisterToEvent][registerToEvent] Could not register user into event: ${eventId} message: ${resp.message}`,
    );
    addErrorNotification({
      message: t(resp.message || "") || t("error.unknown"),
    });
  };

  /**
   * Get member status in the event
   * @param eventId String
   */
  const getEventMemberData = async (eventId: string) => {
    const resp = await getEventMemberStatus(eventId);

    if (resp.isSuccess) {
      // setEventMember(resp.data);

      return resp.data;
    }
    logger.warn(
      `[useRegisterToEvent][getEventMemberStatus] Could not get user in eventmember: ${eventId} message: ${resp.message}`,
    );

    return null;
  };

  /**
   * Checks whether the user is already registered to the event
   * @param eventId string
   * @returns boolean
   */
  const isRegistered = useCallback(
    (eventId: string, eventMember: IEventMember | null): boolean =>
      !!(
        eventMember &&
        eventMember.event === eventId &&
        (hasEventManagerAccess || eventMember.status)
      ) || false,
    [hasEventManagerAccess],
  );

  /**
   * Get user by email
   * @param email string
   *
   */
  const getUserDetailsByEmail = async (email: string) => {
    try {
      return await getUserByEmail(email);
    } catch (error) {
      const errorMessage = error instanceof Error ? error.message : "";
      logger.error(
        `[useRegisterToEvent][getUserDetailsByEmail] Could not get user: ${email} message: ${errorMessage}`,
      );

      return null;
    }
  };

  /**
   * Take user into event map
   */
  const goToEventMap = (event: IEvent, params?: URLParams) => {
    PerformanceLogger.start("EventLoad");

    history.push({
      pathname: `/e/${event.code}`,
      ...(params && { search: stringify(params) }),
    });
  };

  return {
    registerToEvent,
    getEventMemberData,
    isRegistered,
    getUserDetailsByEmail,
    goToEventMap,
  };
};

export default useRegisterToEvent;
