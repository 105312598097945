import { useSelector } from "react-redux";
import VideoPlayerIFrame from "modules/videoPlayer/VideoPlayerIFrame";
import "./ExternalVideoDisplayItem.scss";
import {
  selectVideoURL,
  selectIsVideoControlsEnabled,
  selectVideoShareTime,
} from "modules/broadcast/redux/selectors";

const ExternalVideoDisplayItem = () => {
  const videoURL = useSelector(selectVideoURL);
  const disabled = !useSelector(selectIsVideoControlsEnabled);
  const videoShareTime = useSelector(selectVideoShareTime);

  if (!videoURL) return null;

  return (
    <>
      <VideoPlayerIFrame
        videoShareTime={disabled ? videoShareTime : undefined}
        shouldSyncVideo={disabled && Boolean(videoShareTime)}
        src={videoURL}
      />
      {disabled && <div className="disabled-video" />}
    </>
  );
};

export default ExternalVideoDisplayItem;
