import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";

export const useStyles = makeStyles((theme) => ({
  description: {
    maxWidth: "36rem",
    color: "rgba(72, 74, 86, 0.7)",
  },
  helpLinkButton: {
    marginTop: theme.spacing(2),
    textDecoration: "underline",
  },
}));

export default useStyles;
