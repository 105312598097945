import { useI18n } from "i18n";
import { ActionButton } from "modules/actionButton";
import { Settings } from "@remo-co/ui-core/src/icons/Settings";
import { useAppDispatch } from "store/hooks";
import { showCameraSettingsDialog } from "modules/cameraSettings/redux/actions";

export const SettingsButton = () => {
  const { t } = useI18n("micCamCheck");
  const dispatch = useAppDispatch();
  const handleSettings = () => dispatch(showCameraSettingsDialog());

  return (
    <ActionButton
      data-testid="settings-button"
      onClick={handleSettings}
      label={t("settings")}
      icon={<Settings />}
    />
  );
};
