import { Button } from "@remo-co/ui-core/src/components/Button";
import { Tooltip } from "@remo-co/ui-core/src/components/Tooltip";
import { RecordVoiceOver } from "@remo-co/ui-core/src/icons/RecordVoiceOver";
import { useI18n } from "i18n";
import { useInviteAllAssignedAttendees } from "../../hooks/useInviteAllAssignedAttendees";
import useStyles from "./styles";

interface Props {
  eventId: string;
  selectedPreset: string;
  onClick: (onAssignmentCompleteClick: () => void) => void;
  disabled: boolean;
}

const InviteAllToAssignedTableButton = ({
  eventId,
  selectedPreset,
  onClick,
  disabled,
}: Props): JSX.Element => {
  const { inviteAllAttendees } = useInviteAllAssignedAttendees(
    eventId,
    selectedPreset !== "default" ? selectedPreset : undefined,
  );
  const { t } = useI18n(["common", "manageEvent"]);
  const styles = useStyles();

  const handleClick = async () => {
    await inviteAllAttendees();
  };

  return (
    <Tooltip
      title={
        disabled
          ? t("manageEvent:attendee.management.invite.disabled")
          : t("manageEvent:invite.attendees.to.assigned.tables")
      }
      placement="bottom"
      PopperProps={{
        className: styles.tooltip,
      }}
    >
      <span>
        <Button
          variant="secondary"
          color="dark"
          size="md"
          isIconButton
          onClick={() => onClick(handleClick)}
          data-testid="invite-button"
          disabled={disabled}
        >
          <RecordVoiceOver />
        </Button>
      </span>
    </Tooltip>
  );
};

export default InviteAllToAssignedTableButton;
