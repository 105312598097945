import { useContext } from "react";
import logger from "logging/logger";
import { TRACKING_CONTEXT } from "modules/tracking";

const useHelpChatActions = () => {
  // Getting from window everytime, because this object becomes undefined when going to another page

  const { track } = useContext(TRACKING_CONTEXT);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const getFcWidget = () => (window as any).fcWidget;

  const pushEventsToFc = (key: string, data: Record<string, unknown>) => {
    const fcWidget = getFcWidget();

    if (!fcWidget) {
      logger.info("[useHelpChatActions][pushEvents] fcwidget not loaded yet");

      return;
    }
    try {
      logger.info(`[useHelpChatActions][pushEvents] event: ${key}`, data);
      fcWidget.track(key, data);
    } catch (error) {
      const errorMessage = error instanceof Error ? error.message : "";
      logger.error(
        `[useHelpChatActions][pushEvents] error in push events: ${errorMessage}`,
      );
    }
  };

  const pushEvents = (key: string, data: Record<string, unknown>) => {
    pushEventsToFc(key, data);
    track(key, data);
  };

  return {
    pushEvents,
  };
};

export default useHelpChatActions;
