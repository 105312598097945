import { useParticipantProperty } from "@daily-co/daily-react";
import { DAILY_PLAYABLE_TRACK_STATES } from "modules/audioVideo/constants";
import { useFindParticipant } from "./findParticipant";

export const useParticipant = ({ userId }: { userId: string }) => {
  const { findParticipant } = useFindParticipant();

  const participantSessionId = findParticipant(userId);

  const [audio, video, screenVideoState] = useParticipantProperty(
    participantSessionId,
    ["audio", "video", "tracks.screenVideo.state"],
  );

  return {
    audio,
    video,
    screenVideo: DAILY_PLAYABLE_TRACK_STATES.includes(screenVideoState),
  };
};
