export function createExpandedSlots(
  oldSlots: (null | string)[],
  itemsPerRow: number,
) {
  const slots: (string | null)[] = Array.from(
    { length: itemsPerRow * 2 },
    () => null,
  );

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < oldSlots.length / 2; i++) {
    slots[i + Math.floor((itemsPerRow - oldSlots.length / 2) / 2)] =
      oldSlots[i];
  }

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < oldSlots.length / 2; i++) {
    slots[
      i + Math.floor((itemsPerRow - oldSlots.length / 2) / 2) + itemsPerRow
    ] = oldSlots[i + oldSlots.length / 2];
  }

  return slots;
}
