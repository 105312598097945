import { Theme } from "@remo-co/ui-core/src/types";
import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";

export const useStyles = makeStyles<Theme, { isInBroadcast?: boolean }>(
  (theme) => ({
    text: {
      color: theme.palette.text.primary,
      padding: "6px 10px",
      marginBottom: "10px",
      borderRadius: "6px",
      fontWeight: 300,
      fontSize: "15px",
      lineHeight: "1.4",
      whiteSpace: "pre-wrap",
      wordWrap: "break-word",
    },
    textMessage: {
      color: theme.palette.text.primary,
      fontWeight: 300,
      fontSize: "15px",
      lineHeight: "1.4",
      whiteSpace: "pre-wrap",
    },
    authorName: {
      color: (props) =>
        props.isInBroadcast ? theme.palette.white : theme.palette.text.primary,
    },
    fileContainer: {
      marginBottom: "10px",
      maxWidth: "250px",
    },
    imageMessageContainer: {
      display: "flex",
      flexDirection: "column",

      "&:hover button": {
        opacity: 0.8,
      },
    },
    imageContainer: {
      position: "relative",
      textAlign: "center",
      display: "flex",
      maxWidth: "fit-content",
    },
    imageMessage: {
      maxWidth: "100%",
      height: "8rem",
      minWidth: "6rem",
      border: "1px solid #999",
      borderRadius: "5px",
      cursor: "pointer",
      objectFit: "contain",
    },
    fileMessage: {
      display: "block",
      textDecoration: "none",
      color: (props) =>
        props.isInBroadcast ? theme.palette.white : theme.palette.text.primary,
      border: "1px solid #ddd",
      padding: "0.5rem",
      borderRadius: "5px",
    },
    fileName: {
      display: "block",
      fontWeight: 700,
      lineHeight: "1rem",
      maxWidth: "100%",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      cursor: "pointer",
    },
    fileSize: {
      fontSize: "0.7rem",
      color: "#666",
      fontStyle: "normal",
      textTransform: "capitalize",
    },
    audioMessage: {
      maxWidth: "100%",
    },
  }),
);
