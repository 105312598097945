import { getEmbeddedVideoURL } from "helpers/videoHelper";
import { ISponsor } from "modules/event/types";
import VideoPlayerIFrame from "modules/videoPlayer/VideoPlayerIFrame";
import useSponsors from "modules/event/hooks/useSponsors";
import classNames from "classnames";
import { useStyles } from "./styles";

export interface ISponsorsMediaProps {
  selectedSponsor: ISponsor;
}

const SponsorsMedia = ({ selectedSponsor }: ISponsorsMediaProps) => {
  const { isVideo } = useSponsors();
  const styles = useStyles();

  return (
    <div className={styles.root}>
      {(isVideo(selectedSponsor.media) && (
        <VideoPlayerIFrame
          className={classNames([styles.iframe, styles.media])}
          // should add guard here in case below expression returns null (src expects string).
          src={getEmbeddedVideoURL(selectedSponsor.media) as string}
          allowFullScreen
        />
      )) || (
        <img
          className={styles.media}
          src={selectedSponsor.media}
          alt={selectedSponsor.name}
        />
      )}
    </div>
  );
};

export default SponsorsMedia;
