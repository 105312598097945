import {
  DataSnapshot,
  DatabaseReference,
  off,
  onValue,
  ref,
} from "firebase/database";
import { useEffect, useState } from "react";
import { database } from "services/firebaseService/firebaseConfig";
import { THEATER_PATH } from "modules/theater/firebase";

export const useFloorNames = (
  theaterId: string | null,
): Record<string, string> => {
  const [floorNameRecord, setFloorNameRecord] = useState<
    Record<string, string>
  >({});

  const subscribe = (docRef: DatabaseReference) => {
    if (docRef) {
      onValue(docRef, (snap: DataSnapshot) => {
        const record = snap.val();

        setFloorNameRecord(record || {});
      });
    }
  };

  const unsubscribe = (docRef: DatabaseReference) => {
    off(docRef);
  };

  useEffect(() => {
    let docRef: DatabaseReference;

    if (theaterId) {
      docRef = ref(database, `${THEATER_PATH}/${theaterId}/FloorNames/`);
      subscribe(docRef);
    }

    return () => {
      if (docRef) {
        unsubscribe(docRef);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [theaterId]);

  return floorNameRecord;
};
