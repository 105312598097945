import { useEffect } from "react";
import { setGlobalContext } from "modules/monitoring";
import { useLocalParticipant } from "@daily-co/daily-react";

export const DailyTracker = () => {
  const participant = useLocalParticipant();

  useEffect(() => {
    if (!participant) {
      return;
    }
    setGlobalContext("daily", {
      participant,
    });
  }, [participant]);

  return null;
};
