import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";

export const useStyles = makeStyles((theme) => ({
  popoverContainer: {
    borderRadius: "5px",
    border: `1px solid ${theme.palette.gray.light}`,
    background: theme.palette.white,
  },
  popoverOption: {
    display: "flex",
    padding: "18px",
    gap: "25px",
    cursor: "pointer",
    "&:hover": {
      background: theme.palette.gray.light,
    },
  },
}));
