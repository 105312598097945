import { ComponentProps } from "react";
import { Theme } from "@remo-co/ui-core/src/types";
import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";
import TableGrid from "./TableGrid";

const useStyles = makeStyles<Theme, Partial<ComponentProps<typeof TableGrid>>>(
  () => ({
    tableGrid: {
      display: "grid",
      flex: 1,
      overflow: (props) => (props.isInEvent ? "auto" : "scroll"),
      gridGap: 10,
      padding: 10,
      gridTemplateColumns: `repeat(auto-fill, 300px)`,
    },
  }),
);

export default useStyles;
