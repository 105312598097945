import { Map } from "immutable";
import { IUserPreferences, USER_PREFERENCES } from "./types";

export const initialState = {
  userPreferences: Map<string, IUserPreferences>(),
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any,  @typescript-eslint/default-param-last
const userPreferencesReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case USER_PREFERENCES:
      // eslint-disable-next-line no-case-declarations
      const currentValue = state.userPreferences.get(action.payload.userId);
      // eslint-disable-next-line no-case-declarations
      const updatedValue = {
        isLoaded: false,
        ...currentValue,
        ...action.payload.userPreferences,
      };

      return {
        ...state,
        userPreferences: state.userPreferences.set(
          action.payload.userId,
          updatedValue,
        ), // Concat new users and update state
      };
    default:
      return state;
  }
};

export default userPreferencesReducer;
