import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";

export const useStyles = makeStyles((theme) => ({
  wrap: { display: "flex", justifyContent: "space-between", marginBottom: 16 },
  button: {
    height: 40,
    whiteSpace: "nowrap",
    color: theme.palette.primary.main,
  },
  header: { marginBottom: theme.spacing(2) },
}));
