import store from "store";
import logger from "logging/logger";
import { setPanelState, TABS } from "modules/rightPanel";
import { addInfoNotification } from "modules/notification/redux/notificationSlice";
import { updateActiveChannel } from "modules/chat/redux/actions";
import { CHAT_MESSAGE_TYPE } from "modules/chat/redux/constants";
import { getReducerState } from "helpers/reduxHelper";

export enum NOTIFICATION_TYPE {
  CHAT,
}

const isSupportNotification = !!window.Notification;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const sendBrowserNotification = (message: string, options?: any) => {
  if (document.hasFocus() && (!options || !options.skipFocusCheck)) {
    // is current tab active, but window inactive
    // Send Inapp chat notifications
    if (
      options &&
      options.data &&
      options.data.type === NOTIFICATION_TYPE.CHAT &&
      options.data.messageType !== CHAT_MESSAGE_TYPE
    ) {
      const { activeChannel } = getReducerState("chatV2") || {};
      const { isOpen, tabId } = getReducerState("rightPanel") || {};
      const isChatOpen = isOpen && tabId === TABS.CHAT;

      const broadcastState = getReducerState("broadcast");
      const isBroadcasting = broadcastState && broadcastState.isBroadcasting;

      // Do not show notifications if chat is already open
      if (
        isChatOpen ||
        activeChannel === options.data.channel ||
        isBroadcasting
      )
        return;

      store.dispatch(
        addInfoNotification({
          title: message,
          message: options.body,
          position: "tr",
          action: {
            label: "Open",
            callback: () => {
              store.dispatch(setPanelState({ isOpen: true, tabId: TABS.CHAT }));
              store.dispatch(updateActiveChannel(options.data.channel));
            },
          },
        }),
      );
    }

    return;
  }

  if (!isSupportNotification) {
    logger.error("This browser does not support notifications");
  } else if (Notification.permission === "granted") {
    // Refer https://web-push-book.gauntface.com/demos/notification-examples/ for various options
    const defaultOptions = {
      // "body": "Body text",
      icon: `${window.location.origin}/favicon-96x96.png`,
      // "image": "https://web-push-book.gauntface.com/images/notification-ui.png",
      // "data": "{'key': 'value'}",
      timestamp: Date.now(),
    };
    const notification = new Notification(message, {
      ...defaultOptions,
      ...options,
    });

    notification.onclick = (event: Event) => {
      // Reopen/focus the correct tab
      window.focus();
      // Close the notification
      notification.close();
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const { data }: any = event.target;

      if (data) {
        switch (data.type) {
          // Open chat window with messages when clicked from notifications
          case NOTIFICATION_TYPE.CHAT:
            if (data.messageType !== CHAT_MESSAGE_TYPE) {
              store.dispatch(setPanelState({ isOpen: true, tabId: TABS.CHAT }));
              store.dispatch(updateActiveChannel(data.channel));
            }
            break;
          default:
            break;
        }
      }
    };
  }
};
