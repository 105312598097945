import { useEffect } from "react";
import { Errors, trackError } from "modules/monitoring";
import useNotificationActions from "modules/notification/hooks/useNotificationActions";
import eventMemberApi from "modules/eventMemberList/eventMember.api";
import { useQuery } from "@tanstack/react-query";
import { useI18n } from "i18n";
import exportAttendeeHelper from "modules/eventMemberList/exportAttendeeHelper";
import { IEventMemberDataResults } from "modules/eventMemberList/types";
import { EventRole } from "modules/event";
import { ATTENDEE_LIST_QUERY_KEY } from "../../constants";

interface AttendeeListQuery {
  invitedAttendees: IEventMemberDataResults[];
  isFetching: boolean;
  status: "idle" | "error" | "pending" | "success";
  refetch: () => void;
}

const useAttendeeList = (
  eventId?: string,
  withAttendance = true,
  withRegistrationAnswers = false,
  role?: EventRole,
): AttendeeListQuery => {
  const { t } = useI18n(["manageEvent"]);
  const { addErrorNotification } = useNotificationActions();
  // accommodating "" as a potential value here given how the ManageEventContext initializes this
  const isEventIdMissing = !eventId || eventId === "";

  const {
    data: invitedAttendees,
    isFetching,
    status,
    refetch,
    error,
  } = useQuery({
    queryKey: [ATTENDEE_LIST_QUERY_KEY, eventId],
    queryFn: async () => {
      if (isEventIdMissing) {
        return [];
      }

      const { attendees, isSuccess, message } =
        await eventMemberApi.getEventAttendeeList(
          eventId,
          withAttendance,
          withRegistrationAnswers,
          role,
        );

      if (!isSuccess) throw new Error(message);

      const attendeeList = exportAttendeeHelper.convertEventMemberData(
        { name: "" },
        attendees ?? [],
      );
      return attendeeList;
    },
  });

  useEffect(() => {
    if (!error) return;

    const errorMessage = error.message || "something.wrong";
    addErrorNotification({
      message: t(errorMessage),
      position: "tc",
    });

    trackError(error, {
      label: Errors.GET_ATTENDEE_LIST,
      eventId,
    });
  }, [error, addErrorNotification, t, eventId]);

  return {
    invitedAttendees: invitedAttendees ?? [],
    isFetching,
    status,
    refetch,
  };
};

export default useAttendeeList;
