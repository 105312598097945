import { Theme } from "@remo-co/ui-core/src/types";
import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";

export const useStyles = makeStyles<Theme>(() => ({
  broadcastConfirmationDialog: {
    width: "305px",
    margin: "20px 0px",
    "& .MuiDialogTitle-root": {
      padding: "0 30px",
    },
  },
  titleText: {
    fontSize: "18px",
    textAlign: "center",
  },
  closeBtn: {
    position: "absolute",
    right: "15px",
    top: "15px",
  },
  content: {
    padding: "30px 5px 20px",
  },
  labelIcon: {
    display: "inline-block",
    height: "20px",
    width: "20px",
  },
  textIcon: {
    verticalAlign: "bottom",
  },
  buttons: {
    flexDirection: "column",
  },
  button: {
    margin: "0 0 10px 0 !important",
  },
}));
