import { createContext, ReactNode, useEffect, useState } from "react";
import eventsApi from "modules/eventForm/events.api";
import { useManageEventReducer } from "modules/manageEvent";
import useCompany from "modules/company/hooks/useCompany";
import templatesService from "services/firestoreService/templates";
import {
  IManageEventActions,
  ManageEventState,
} from "modules/manageEvent/hooks/types";
import { IMapTemplate } from "types/theater";
import { useFilteredTemplates } from "../hooks/useFilteredTemplates";

interface ICustomFloorContextProps {
  actions: IManageEventActions | null;
  state: Partial<ManageEventState> | null;
  isLoading: boolean;
  getTemplates: () => void;
  templates?: Record<string, IMapTemplate[]>;
}

export const CUSTOM_FLOOR_CONTEXT = createContext<ICustomFloorContextProps>({
  actions: null,
  state: null,
  isLoading: false,
  getTemplates: () => null,
});

type Props = {
  children: ReactNode;
};

const CustomFloorContextProvider = ({ children }: Props): JSX.Element => {
  const [state, actions] = useManageEventReducer();
  const [isLoading, setIsLoading] = useState(true);
  const { company } = useCompany();
  const templates = useFilteredTemplates(state.templates);
  const getTemplates = async () => {
    if (company?.id) {
      const [publicTemplates, { privateTemplateCodes }] = await Promise.all([
        templatesService.getPublicTemplates(),
        eventsApi.getTemplates(company.id),
      ]);
      const privateTemplates = await templatesService.getTemplatesByCodes(
        privateTemplateCodes,
      );

      actions.setTemplates({ publicTemplates, privateTemplates });
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (company) {
      getTemplates();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company]);

  return (
    <CUSTOM_FLOOR_CONTEXT.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        state,
        actions,
        isLoading,
        getTemplates,
        templates,
      }}
    >
      {children}
    </CUSTOM_FLOOR_CONTEXT.Provider>
  );
};

export default CustomFloorContextProvider;
