import {
  collection,
  doc,
  getDocs,
  onSnapshot,
  query,
  where,
  orderBy,
  limit,
  startAfter,
  addDoc,
  setDoc,
  deleteDoc,
} from "firebase/firestore";
import { firestore } from "services/firebaseService/firebaseConfig";
import logger from "logging/logger";
import { useSelector } from "react-redux";
import { useAppDispatch } from "store/hooks";
import useSystemClock from "modules/system/hooks/useSystemClock";
import { selectCurrentEventId } from "modules/event/selectors";
import { selectUser } from "modules/auth/redux/selectors";
import { useI18n } from "i18n";
import {
  CHAT_MESSAGE_TYPE,
  CHAT_TYPE,
  SPACE_CHAT_NAME,
} from "../redux/constants";
import {
  removeMessageFromChannel,
  setIsLoading,
  unsubscribeFromChannel,
  updateChannelListensFrom,
  updateChannelPagination,
  updateChatChannel,
} from "../redux/actions";
import useChatOnMessage from "./useChatOnMessage";
import { IChannel, IChat } from "../types";

const chatListeners = new Map();
const chatMetaListeners = new Map();

const useChatFirestore = () => {
  const chatsDB = collection(firestore, "Chats");
  const chatsMetaDB = collection(firestore, "Chats_meta");
  const { getCurrentTime } = useSystemClock();
  const PAGINATE_COUNT = 25;
  const SUBSCRIBE_RETRY = <Record<string, number>>{};
  const eventId = useSelector(selectCurrentEventId);
  const user = useSelector(selectUser);
  const userId = (user && user.id) || undefined;
  const { t } = useI18n();
  const dispatch = useAppDispatch();
  const { onReceivingMessage, throttledChatUpdate } = useChatOnMessage();

  const getMoreChats = (currentChannel: IChannel) => {
    const channel = currentChannel.id;

    if (!eventId) {
      throw new Error(
        `[Chats] getMoreChats space not found Channel: ${channel}`,
      );
    }

    // If all messages are loaded dont trigger more messages anymore
    if (!currentChannel || currentChannel.stopPagination) {
      return;
    }

    const time = currentChannel.listensFrom;

    if (time === undefined) {
      return;
    }

    dispatch(setIsLoading(true));
    let chatQuery = query(
      collection(doc(chatsDB, eventId), channel),
      where("time", "<", time),
      orderBy("time", "desc"),
    );

    if (currentChannel.paginateAfter) {
      chatQuery = query(chatQuery, startAfter(currentChannel.paginateAfter));
    }

    getDocs(query(chatQuery, limit(PAGINATE_COUNT)))
      .then((querySnapshot) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const messages: any[] = [];
        const paginateAfter = querySnapshot.docs[querySnapshot.docs.length - 1];

        // Update redux state for this channel with latest paginated message ID
        // Loaded all messages - dont trigger load more messages
        if (querySnapshot.size < PAGINATE_COUNT) {
          dispatch(updateChannelPagination(channel, time, paginateAfter, true));
        } else {
          dispatch(updateChannelPagination(channel, time, paginateAfter));
        }
        querySnapshot.forEach((doc) => {
          const chat = doc.data();
          chat.id = doc.ref.id;
          if (chat.deleted === false || chat.deleted === undefined) {
            messages.push(chat);
          }
        });

        // eslint-disable-next-line promise/always-return
        if (messages.length) {
          throttledChatUpdate({ messages, channel, isNewMessage: false });
        }
        dispatch(setIsLoading(false));
      })
      // eslint-disable-next-line promise/prefer-await-to-then
      .catch((err) => {
        dispatch(setIsLoading(false));
        throw new Error(
          `[Chats] subscribe onGet: ${err.message} Channel: ${channel} event: ${eventId}`,
        );
      });
  };

  const unsubscribe = (channel: string) => {
    const listener = chatListeners.get(channel);

    if (listener) {
      listener();
    } else {
      logger.error(`[Chats][unsubscribe] no listener Channel: ${channel}.`);
    }
    chatListeners.delete(channel);

    // Clear all messages from store
    dispatch(unsubscribeFromChannel(channel));
  };

  const subscribeToChannel = async (channel: string) => {
    if (chatListeners.get(channel)) {
      return;
    }

    if (!eventId) {
      logger.error(
        `[Chats] subscribe ${eventId} not found Channel: ${channel}`,
      );
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      retrySubscribe(channel);

      return;
    }

    if (!userId) {
      logger.error(`[Chats] subscribe user not found Channel: ${channel}`);
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      retrySubscribe(channel);

      return;
    }
    const currentTime = getCurrentTime();
    // Get data till 1 secs before
    const time = currentTime - 1000;

    // Update redux state for this channel with time from which we are listening the chat
    dispatch(updateChannelListensFrom(channel, time));
    // Listen for new messages and set the unsubcribe handle in map
    // If theaterID is sent, looks for theater channel
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    const handler = handleChannelMessages(eventId, channel, time);

    if (handler) {
      chatListeners.set(channel, handler);
      // Reset the retry to 0
      SUBSCRIBE_RETRY[channel] = 0;
    } else {
      logger.error(
        `[Chats] failed to subscribe to Channel: ${channel}, retrying.`,
      );
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      retrySubscribe(channel);
    }
  };

  const loadChannels = (time: number) => {
    if (!eventId) {
      throw new Error("[Chats] loadChannels event not found");
    }

    if (!userId) {
      throw new Error("[Chats] loadChannels User not found");
    }

    // only general chat can be cleared anyway
    const clearedChannelListener = onSnapshot(
      query(
        collection(doc(chatsMetaDB, eventId), "Channels"),
        where("lastCleared", ">", time),
      ),
      (querySnapshot) => {
        querySnapshot.docChanges().forEach(async (change) => {
          // respond to cleared channels
          if (change.type === "added" || change.type === "modified") {
            const data = change.doc.data();

            // unsubscribe channel and delete all messages, then resubscribe
            // we need it this way to avoid the listeners @ handleChannelMessages from getting confused
            // since we can't easily handle change.type === "removed" there
            unsubscribe(data.id);
            dispatch(
              updateChatChannel({
                id: data.id,
                name: t(SPACE_CHAT_NAME),
                type: CHAT_TYPE.THEATER,
                listensFrom: getCurrentTime(),
              }),
            );
          }
        });
      },
    );

    const accessedDMChannelsListener = onSnapshot(
      query(
        collection(doc(chatsMetaDB, eventId), "Channels"),
        where(`users.${userId}.lastAccess`, ">", 0),
        orderBy(`users.${userId}.lastAccess`, "desc"),
      ),
      (querySnapshot) => {
        querySnapshot.docChanges().forEach(async (change) => {
          if (change.type === "added") {
            const data = change.doc.data() as IChannel;
            const listensFrom =
              data.type === CHAT_TYPE.USER
                ? getCurrentTime()
                : data.listensFrom;

            const channel: IChannel = {
              ...data,
              listensFrom,
            };

            dispatch(updateChatChannel(channel));
            subscribeToChannel(channel.id);

            getMoreChats(channel);
          }
        });
      },
      (error) => {
        if (
          error &&
          error.message &&
          error.message === "Missing or insufficient permissions."
        ) {
          logger.error(
            `[Chats] loadChannelsFromFirestore: ${error.message}, user might be logging out`,
          );
        } else {
          throw new Error(
            `[Chats] loadChannelsFromFirestore: error: ${error.message} space: ${eventId} user: ${userId}`,
          );
        }
      },
    );

    chatMetaListeners.set(userId, [
      clearedChannelListener,
      accessedDMChannelsListener,
    ]);
  };

  /**
   * Retry channel subscription to firestore for 5times max
   * @param channelId String
   */
  const retrySubscribe = (channelId: string) => {
    if (!SUBSCRIBE_RETRY[channelId] || SUBSCRIBE_RETRY[channelId] < 5) {
      SUBSCRIBE_RETRY[channelId] = SUBSCRIBE_RETRY[channelId]
        ? SUBSCRIBE_RETRY[channelId] + 1
        : 1;
      setTimeout(() => {
        subscribeToChannel(channelId);
      }, 1000);
    } else {
      logger.warn(
        `[Chats][retrySubscribe] retry maxed out for channel: ${channelId} ${SUBSCRIBE_RETRY[channelId]} time.`,
      );
    }
  };

  const handleChannelMessages = (
    spaceId: string,
    channel: string,
    time: number,
  ) => {
    if (!eventId) {
      return null;
    }

    try {
      return onSnapshot(
        query(
          collection(doc(chatsDB, eventId), channel),
          orderBy("time", "desc"),
          limit(15),
        ),
        (snapshot) => {
          snapshot.docChanges().forEach((change) => {
            const chat = change.doc.data();
            chat.id = change.doc.id;
            if (
              (change.type === "added" || change.type === "modified") &&
              chat.time > time
            ) {
              if (chat.deleted) {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                dispatch(removeMessageFromChannel(channel, chat as IChat));
              } else {
                onReceivingMessage([change]);
              }
            }
          });
        },
        (error) => {
          logger.error(
            `[Chats][handleChannelMessages] onsnapshot: ${error.message} Channel: ${channel} space: ${spaceId} user: ${userId}`,
          );
        },
      );
    } catch (err) {
      logger.error(
        `[Chats][handleChannelMessages] Error while creating snapshot handler: ${JSON.stringify(
          err,
        )}`,
      );
    }

    return null;
  };

  const sendToChannel = (channel: string, data: IChat) => {
    if (!eventId) {
      throw new Error("[Chats] sendToChannel space not found");
    }

    // Adding this for making sure if users are not subscribed to new messages
    const listener = chatListeners.get(channel);

    if (!listener) {
      logger.info(
        `[chats][sendToChannel] subscribing to channel ${channel} since it is not subscribed yet.`,
      );
      subscribeToChannel(channel);
    }
    addDoc(collection(doc(chatsDB, eventId), channel), data)
      .then((docRef) => {
        // If action message, then delete the message after set, as we dont need to persist it in DB for long time
        // eslint-disable-next-line promise/always-return
        if (data.type === CHAT_MESSAGE_TYPE) {
          deleteDoc(docRef);
        }
      })
      .catch((error) => {
        throw new Error(
          `[Chats] adding document: error: ${error.message} Channel: ${channel} event: ${eventId}`,
        );
      });
  };

  /**
   * Update lastaccess time only for direct messages
   * Rooms does not need to handle unread messages for now
   */
  const updateLastAccessTimeForChannel = (
    currentUser: string,
    channel: IChannel,
  ) => {
    if (channel.type === CHAT_TYPE.USER) {
      const time = getCurrentTime();

      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      updateChatChannelMeta({
        id: channel.id,
        users: { [currentUser]: { lastAccess: time } },
      });
    }
  };

  /**
   * Store only DM channel data
   * We dont need to store Room meta data as it will be volatile and needed only when user joins the room
   *  This will be taken care by subscribe event
   * @param channelMeta
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const updateChatChannelMeta = (channelMeta: any) => {
    if (!eventId) {
      throw new Error("[Chats] updateChatChannelMeta space not found");
    }
    setDoc(
      doc(collection(doc(chatsMetaDB, eventId), "Channels"), channelMeta.id),
      channelMeta,
      { merge: true },
    ).catch((error) => {
      throw new Error(
        `[Chats] updateChatChannelMeta adding document: ${error.message}`,
      );
    });
  };

  const unsubscribeAll = () => {
    chatMetaListeners.forEach(
      ([clearedChannelListener, accessedDMChannelsListener]) => {
        clearedChannelListener();
        accessedDMChannelsListener();
      },
    );
    chatListeners.forEach((listener, channelId: string) => {
      unsubscribe(channelId);
    });

    chatListeners.clear();
    chatMetaListeners.clear();
  };

  return {
    loadChannels,
    unsubscribe,
    subscribeToChannel,
    sendToChannel,
    updateLastAccessTimeForChannel,
    getMoreChats,
    updateChatChannelMeta,
    unsubscribeAll,
  };
};

export default useChatFirestore;
