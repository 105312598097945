import { Info } from "@remo-co/ui-core/src/icons/Info";
import "./InfoBlock.scss";
import { Typography } from "@remo-co/ui-core/src/components/Typography";

interface IInfoBlockProps {
  text: string;
}

const InfoBlock = ({ text }: IInfoBlockProps) => (
  <div className="info-block">
    <Info /> <Typography color="inherit">{text}</Typography>
  </div>
);

export default InfoBlock;
