import { fromJS } from "immutable";
import store from "store";
import {
  ref,
  off,
  onChildChanged,
  onChildAdded,
  onChildRemoved,
  DataSnapshot,
} from "firebase/database";
import userStatusActions from "modules/userStatus/redux/actions";
import { database } from "./firebaseConfig"; // Assuming this is your initialized Firebase database instance
import { getCurrentSpaceId } from "../../helpers/reduxHelper";
import { SPACE_PATH, USER_STATUS_PATH } from "./storagePaths";

const syncUserStatusWithRedux = async (
  userStatusResult: DataSnapshot,
  key: string | null,
) => {
  if (userStatusResult.val()) {
    const data = key
      ? { [key]: userStatusResult.val() }
      : userStatusResult.val();

    store.dispatch(userStatusActions.syncUserStatus(fromJS(data)));
  }
};

export const unsubscribeUserStatus = () => {
  const spaceId = getCurrentSpaceId();
  const dbRef = ref(database, `${SPACE_PATH}/${spaceId}/${USER_STATUS_PATH}`);

  off(dbRef);
};

export const handleSyncUserStatus = () => {
  const spaceId = getCurrentSpaceId();
  const dbRef = ref(database, `${SPACE_PATH}/${spaceId}/${USER_STATUS_PATH}`);

  off(dbRef);

  const handler = async (userStatusResult: DataSnapshot) => {
    if (userStatusResult) {
      syncUserStatusWithRedux(userStatusResult, userStatusResult.key);
    }
  };

  // Listen for changes only after getting all values
  onChildChanged(dbRef, handler);
  onChildAdded(dbRef, handler);
  onChildRemoved(dbRef, async (userStatusResult: DataSnapshot) => {
    const { key } = userStatusResult;

    if (key) {
      store.dispatch(userStatusActions.removeUserStatus(key));
    }
  });
};
