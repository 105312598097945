import { useI18n } from "i18n";
import { Container } from "@remo-co/ui-core/src/components/Container";
import { Typography } from "@remo-co/ui-core/src/components/Typography";
import { DragAndDrop } from "@remo-co/ui-core/src/icons/DragAndDrop";
import useStyles from "./styles";

const TableItemBlankState = (): JSX.Element => {
  const { t } = useI18n(["manageEvent"]);
  const styles = useStyles();

  return (
    <Container className={styles.container}>
      <DragAndDrop className={styles.icon} />
      <Typography variant="body2" className={styles.text}>
        {t("drag.guests.to.table")}
      </Typography>
    </Container>
  );
};

export default TableItemBlankState;
