import { useSelector } from "react-redux";
import { useIsInEvent } from "modules/event";
import { selectIsLoading } from "modules/helpChat/selectors";
import { UpdatePrompt } from "modules/version";
import { HelpChat } from "..";
import { useStyles } from "./styles";
import { LoadingChat } from "../LoadingChat";

const GlobalHelpChat = () => {
  const isInEvent = useIsInEvent();
  const styles = useStyles();
  const isLoading = useSelector(selectIsLoading);

  if (isLoading) {
    return <LoadingChat />;
  }

  if (isInEvent) {
    return null;
  }

  return (
    <div data-testid="global-helpChat" className={styles.root}>
      <UpdatePrompt>
        <HelpChat />
      </UpdatePrompt>
    </div>
  );
};

export default GlobalHelpChat;
