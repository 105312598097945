import { selectCurrentTheaterId } from "modules/theater/selectors";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { liveStreamFirebase } from "services/firebaseService/liveStream";

export const LiveStreamListener = () => {
  const theaterId = useSelector(selectCurrentTheaterId);

  useEffect(() => {
    if (!theaterId) {
      return undefined;
    }

    liveStreamFirebase.subscribeEventLiveStream(theaterId);

    return () => {
      liveStreamFirebase.unsubscribeEventLiveStream(theaterId);
    };
  }, [theaterId]);

  return null;
};
