export enum ConfigRoomNames {
  LEFT_BOARD = "Left Board",
  RIGHT_BOARD = "Right Board",
  STAGE = "Stage",
}

export enum SVGRoomNames {
  LEFT_BOARD_DEPRECATED = "left-board",
  RIGHT_BOARD_DEPRECATED = "right-board",
  STAGE = "stage",
}

export const maxRecommendedFileSize = 2097152;

export const maxFileSize = 4194304;

export const maxPlanNameLength = 30;

export enum SVGSourceTypes {
  ABSOLUTE = "absolute",
  RELATIVE = "relative",
}

export const SVG_TEST_ID = "cfp-results-preview";

export const SVG_FULLSCREEN_TEST_ID = "cfp-results-fullscreen";

export enum FLOOR_PLAN_THEMES {
  BLUE = "BLUE", // obsolete
  CLASSIC = "CLASSIC",
  DEFAULT = "DEFAULT", // obsolete
  ISOMETRIC = "ISOMETRIC",
  MODERN = "MODERN",
  PURPLE = "PURPLE", // obsolete
  REALISTIC = "REALISTIC",
  RED = "RED", // obsolete
}

export const DEFAULT_THEME = FLOOR_PLAN_THEMES.REALISTIC;

export const DEFAULT_FLOOR_PLAN = "PHOTOREALISTIC-PHOTO-REALISTIC";

export const ACTIVE_THEMES = [
  FLOOR_PLAN_THEMES.REALISTIC,
  FLOOR_PLAN_THEMES.ISOMETRIC,
  FLOOR_PLAN_THEMES.MODERN,
  FLOOR_PLAN_THEMES.CLASSIC,
];
