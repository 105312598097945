import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { EnforceTableAssignmentState } from "../types";

export const initialState: EnforceTableAssignmentState = {
  isCountdownBannerVisible: false,
  isCountdownDialogVisible: false,
  isInProgressDialogVisible: false,
  countdownStartTime: null,
};

const enforceTableAssignmentSlice = createSlice({
  initialState,
  name: "enforceTableAssignment",
  reducers: {
    setIsCountdownDialogVisible: (state, action: PayloadAction<boolean>) => ({
      ...state,
      isCountdownDialogVisible: action.payload,
    }),
    setIsCountdownBannerVisible: (state, action: PayloadAction<boolean>) => ({
      ...state,
      isCountdownBannerVisible: action.payload,
    }),
    // if countdown dialog is closed, we're opening the banner instead
    switchCountdownDialogToBanner: (state) => ({
      ...state,
      isCountdownDialogVisible: false,
      isCountdownBannerVisible: true,
    }),
    setIsInProgressDialogVisible: (state, action: PayloadAction<boolean>) => ({
      ...state,
      isInProgressDialogVisible: action.payload,
    }),
    setCountdownStartTime: (state, action: PayloadAction<number | null>) => ({
      ...state,
      countdownStartTime: action.payload,
    }),
    reset: (state) => ({
      ...state,
      isCountdownDialogVisible: false,
      isInProgressDialogVisible: false,
      isCountdownBannerVisible: false,
      countdownStartTime: null,
    }),
  },
});

export const {
  setIsCountdownDialogVisible,
  setIsCountdownBannerVisible,
  setIsInProgressDialogVisible,
  setCountdownStartTime,
  switchCountdownDialogToBanner,
  reset,
} = enforceTableAssignmentSlice.actions;

export default enforceTableAssignmentSlice.reducer;
