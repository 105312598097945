import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";

export const useStyles = makeStyles((theme) => ({
  downloadButton: {
    opacity: 0,
    position: "absolute",
    top: "8px",
    right: "8px",
    boxSizing: "border-box",
    borderRadius: "5px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: theme.palette.gray.light,
    width: "34px",
    height: "34px",
    "&:hover": {
      backgroundColor: theme.palette.gray.light,
    },
  },
  downloadIcon: {
    fill: "#000",
    width: "24px",
    height: "16px",
  },
}));
