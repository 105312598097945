import { Popper } from "@remo-co/ui-core/src/components/Popper";
import { Typography } from "@remo-co/ui-core/src/components/Typography";
import { Box } from "@remo-co/ui-core/src/components/Box";
import { Lottie } from "@remo-co/ui-core/src/components/Lottie";
import { useI18n } from "i18n";
import { useProcessEnforceTableAssignment } from "modules/enforceTableAssignment";
import { EnforceCountdownTimer } from "../EnforceCountdownTimer";
import Animation from "../../assets/animation.json";
import useStyles from "./styles";

interface Props {
  eventId: string;
  presetId?: string;
  closeDialog: () => void;
}

const WaitForEnforceDialog = ({
  eventId,
  presetId,
  closeDialog,
}: Props): JSX.Element => {
  const { process } = useProcessEnforceTableAssignment(eventId, presetId);
  const { t } = useI18n(["event", "manageEvent"]);
  const styles = useStyles();

  const startEnforce = async () => {
    process();
    closeDialog();
  };

  return (
    <Popper
      anchorEl={document.body}
      placement="right"
      className={styles.container}
      open
    >
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="row"
        data-testid="wait-for-enforce-dialog"
        className={styles.animationContainer}
      >
        <Lottie animationData={Animation} />
      </Box>
      <div className={styles.content}>
        <Typography className={styles.title} variant="h4" align="center">
          {t("event:enforce.table.assignment.notify.host")}
        </Typography>
        <Typography className={styles.subtitle} variant="body1" align="center">
          {t("manageEvent:keep.enforce.dialog.open")}
        </Typography>
        <EnforceCountdownTimer size={50} onCountdownEnd={startEnforce} />
      </div>
    </Popper>
  );
};

export default WaitForEnforceDialog;
