import { IShuffleAttendeesState, IShuffleAttendeesAction } from "../types";
import ActionTypes from "./constants";

export const initialState: IShuffleAttendeesState = {
  isShuffleAttendeesDialogVisible: false,
  isShuffleAttendeesCountdownDialogVisible: false,
  isShuffleAttendeesCountdownBannerVisible: false,
  isInitShuffleBannerVisible: false,
  shuffleStartTime: null,
};

const shuffleAttendeesReducer = (
  state = initialState,
  action: IShuffleAttendeesAction,
): IShuffleAttendeesState => {
  switch (action.type) {
    case ActionTypes.SHOW_SHUFFLE_ATTENDEES_DIALOG: {
      return {
        ...state,
        isShuffleAttendeesDialogVisible: true,
      };
    }
    case ActionTypes.HIDE_SHUFFLE_ATTENDEES_DIALOG: {
      return {
        ...state,
        isShuffleAttendeesDialogVisible: false,
      };
    }
    case ActionTypes.SHOW_SHUFFLE_ATTENDEES_CNTDWN_DIALOG: {
      return {
        ...state,
        isShuffleAttendeesCountdownDialogVisible: true,
      };
    }
    case ActionTypes.HIDE_SHUFFLE_ATTENDEES_CNTDWN_DIALOG: {
      return {
        ...state,
        isShuffleAttendeesCountdownDialogVisible: false,
      };
    }
    case ActionTypes.SHOW_SHUFFLE_ATTENDEES_CNTDWN_BANNER: {
      return {
        ...state,
        isShuffleAttendeesCountdownBannerVisible: true,
      };
    }
    case ActionTypes.HIDE_SHUFFLE_ATTENDEES_CNTDWN_BANNER: {
      return {
        ...state,
        isShuffleAttendeesCountdownBannerVisible: false,
      };
    }
    case ActionTypes.HIDE_INIT_SHUFFLE_BANNER: {
      return {
        ...state,
        isInitShuffleBannerVisible: false,
      };
    }
    case ActionTypes.SHOW_INIT_SHUFFLE_BANNER: {
      return {
        ...state,
        isInitShuffleBannerVisible: true,
      };
    }
    case ActionTypes.SET_SHUFFLE_START_TIME: {
      return {
        ...state,
        shuffleStartTime: action.payload.startTime as number,
      };
    }
    default:
      return state;
  }
};

export default shuffleAttendeesReducer;
