import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ILobbyGuest } from "../types";

export interface LobbyState {
  isWindowOpen: boolean;
  isLobbyBannerOpen: boolean;
  guests: Record<string, ILobbyGuest>;
}

export const initialState: LobbyState = {
  isLobbyBannerOpen: false,
  isWindowOpen: false,
  guests: {},
};

const updateGuests = (
  state: Record<string, ILobbyGuest>,
  guests: ILobbyGuest[],
) =>
  guests.reduce(
    (initial: Record<string, ILobbyGuest>, guest: ILobbyGuest) => ({
      ...initial,
      [guest.userId]: guest,
    }),
    state,
  );

const removeGuest = (
  guests: Record<string, ILobbyGuest>,
  userIdToRemove: string,
) =>
  Object.entries(guests).reduce(
    (acc: Record<string, ILobbyGuest>, [, guest]: [string, ILobbyGuest]) =>
      guest.userId === userIdToRemove ? acc : { ...acc, [guest.userId]: guest },
    {},
  );

const lobbySlice = createSlice({
  initialState,
  name: "lobby",
  reducers: {
    toggleLobbyBanner: (state, action: PayloadAction<boolean | undefined>) => {
      state.isLobbyBannerOpen =
        action.payload !== undefined
          ? action.payload
          : !state.isLobbyBannerOpen;
    },
    updateGuestsToLobby: (state, action: PayloadAction<ILobbyGuest[]>) => {
      state.guests = updateGuests(state.guests, action.payload);
    },
    removeGuestFromLobby: (state, action: PayloadAction<string>) => {
      state.guests = removeGuest(state.guests, action.payload);
    },
    resetLobby: () => initialState,
  },
});

export const {
  toggleLobbyBanner,
  updateGuestsToLobby,
  removeGuestFromLobby,
  resetLobby,
} = lobbySlice.actions;

export default lobbySlice.reducer;
