import { getVar } from "config";
import { IMapTemplate } from "types/theater";
import { getReducerState } from "../../helpers/reduxHelper";

export const getMapImageWithTheme = (
  template: IMapTemplate,
  theme?: string,
) => {
  const imageUrl =
    theme && template.themes && template.themes[theme]
      ? template.themes[theme].image
      : template.image;

  return `${getVar("REACT_APP_ASSETS_URL")}Layouts/${decodeURIComponent(
    imageUrl,
  )}`;
};

export const getCurrentTheaterId = () => {
  const theaterReducer = getReducerState("theater");

  if (!theaterReducer.currentTheater) {
    return null;
  }

  return theaterReducer.currentTheater.id;
};
