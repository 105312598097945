import validator from "validator";

export const isValidURL = (url?: string) => {
  const urlValidatorOptions = {
    protocols: ["http", "https"],
    require_protocol: true,
  };

  return url ? validator.isURL(url, urlValidatorOptions) : false;
};
