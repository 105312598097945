import { useCallback } from "react";
import { useSelector } from "react-redux";
import { useDailyEvent } from "@daily-co/daily-react";
import useNotificationActions from "modules/notification/hooks/useNotificationActions";
import { useI18n } from "i18n";
import logger from "logging/logger";
import { selectIsEventManager } from "modules/event/selectors";
import { useAppDispatch } from "store/hooks";
import { setIsLiveStreaming } from "modules/liveStream/redux/slice";
import { liveStreamFirebase } from "services/firebaseService/liveStream";
import { selectCurrentTheaterId } from "modules/theater/selectors";
import { useLiveStreamingAsync } from "./useLiveStreamingAsync";

export const LiveStreamSubscription = () => {
  const { t } = useI18n();
  const dispatch = useAppDispatch();

  const { stopStreaming } = useLiveStreamingAsync();
  const { addErrorNotification } = useNotificationActions();

  const isEventManager = useSelector(selectIsEventManager);
  const theaterId = useSelector(selectCurrentTheaterId);

  useDailyEvent(
    "live-streaming-error",
    useCallback(
      (event) => {
        if (!isEventManager) {
          return;
        }
        addErrorNotification({
          message: t("something.wrong"),
        });
        logger.error(
          `[livestreaming][onLiveStreamingError] ${event?.errorMsg}`,
        );
        if (theaterId) {
          liveStreamFirebase.stopLiveStream(theaterId);
        }
        dispatch(setIsLiveStreaming(false));
        stopStreaming();
      },
      [
        isEventManager,
        addErrorNotification,
        t,
        theaterId,
        dispatch,
        stopStreaming,
      ],
    ),
  );
  return null;
};
