import classNames from "classnames";
import { ContentWithMapping, EventContentMediaType } from "graphql/generated";
import { getEmbeddedVideoURL } from "helpers/videoHelper";
import VideoPlayerIFrame from "modules/videoPlayer/VideoPlayerIFrame";
import { ContentWithConsolidatedMapping } from "../../types";
import { IframeMedia } from "../IframeMedia";
import { TextMedia } from "../TextMedia";
import { useStyles } from "./styles";

interface Props {
  content: ContentWithMapping | ContentWithConsolidatedMapping;
}

export const DialogMedia = ({ content }: Props): JSX.Element | null => {
  const styles = useStyles();

  switch (content.mediaType) {
    case EventContentMediaType.Iframe:
      return (
        <IframeMedia
          media={content.media}
          name={content.name}
          contentType={content.type}
          hideOpenInNewTabButton={content.hideOpenInNewTabButton}
          className={styles.media}
        />
      );

    case EventContentMediaType.Image:
      return (
        <div className={styles.imageWrapper}>
          <img
            src={content.media}
            alt={content.name}
            className={classNames(styles.media, styles.image)}
          />
        </div>
      );

    case EventContentMediaType.Video: {
      const embedVideoURL = getEmbeddedVideoURL(content.media);

      if (!embedVideoURL) {
        return null;
      }

      return (
        <VideoPlayerIFrame
          src={embedVideoURL}
          className={styles.media}
          allowFullScreen
        />
      );
    }

    case EventContentMediaType.Text:
      return <TextMedia media={content.media} contentType={content.type} />;

    default:
      return null;
  }
};
