import { createTheme, adaptV4Theme } from "@mui/material/styles";
import { TabProps } from "@mui/material/Tab";
import { InputBaseProps } from "@mui/material/InputBase";
import { FormLabelProps } from "@mui/material/FormLabel";
import { Colors } from "./colors";
import { darkPalette } from "./palettes";
import { overrides } from "./overrides";
import { baseTheme } from "./baseTheme";

export const darkTheme = createTheme(
  adaptV4Theme({
    ...baseTheme,
    palette: darkPalette,
    overrides: {
      ...overrides,
      MuiPaper: {
        root: {
          backgroundColor: darkPalette?.background?.default,
        },
      },
      MuiListItem: {
        button: {
          "&:hover": {
            backgroundColor: darkPalette.blue.darkShade1,
          },
        },
      },
      MuiInputBase: {
        input: {
          ...(overrides?.MuiInputBase?.input as InputBaseProps),
          color: darkPalette.white,
          "&::placeholder": {
            color: darkPalette.white,
            opacity: "100%",
          },
        },
        root: {
          ...(overrides?.MuiInputBase?.root as InputBaseProps),
          backgroundColor: "#333644",
          borderColor: darkPalette.blue.darkShade1,
          "&:hover": {
            borderColor: darkPalette.blue.dark,
          },
          "&$focused": {
            borderColor: `${darkPalette.blue.main} !important`,
            "&:hover": {
              borderColor: darkPalette.blue.main,
            },
          },
          "&$disabled": {
            background: darkPalette.gray.disabledInput,
            borderColor: darkPalette.gray.light,
            color: `${darkPalette.blue.darkShade1}70 !important`,

            "&:hover": {
              cursor: "not-allowed",
              background: darkPalette.gray.disabledInput,
              borderColor: darkPalette.gray.light,
            },
          },
        },
      },
      MuiTypography: {
        subtitle1: {
          color: darkPalette.white,
        },
        caption: {
          color: darkPalette.white,
        },
        body1: {
          color: darkPalette.white,
        },
        body2: {
          color: darkPalette.white,
        },
        h3: {
          color: darkPalette.white,
        },
        h4: {
          color: darkPalette.white,
        },
        h5: {
          color: darkPalette.white,
        },
        subtitle2: {
          color: Colors.PRESENT_WHITE,
        },
        root: {
          color: "inherit",
        },
      },
      MuiAppBar: {
        root: {
          backgroundColor: Colors.BLUE_DARKSHADE1,
          color: Colors.WHITE,
        },
        colorPrimary: {
          backgroundColor: Colors.BLUE_DARKSHADE1,
          color: Colors.WHITE,
        },
      },
      MuiTabs: {
        root: {
          backgroundColor: Colors.PRESENTATION_MODE_BACKGROUND,
          color: Colors.WHITE,
        },
        indicator: {
          backgroundColor: darkPalette.blue.main,
          "&::after": {
            backgroundColor: darkPalette.blue.main,
          },
          height: "0.2rem",
        },
      },
      MuiFormLabel: {
        root: {
          ...(overrides?.MuiFormLabel?.root as FormLabelProps),
          color: darkPalette.white,
        },
      },
      MuiTextField: {
        root: {
          "& > di": {
            backgroundColor: darkPalette?.background?.default,
          },
        },
      },
      MuiTab: {
        ...overrides?.MuiTab,
        textColorSecondary: {
          ...(overrides?.MuiTab?.textColorSecondary as TabProps),
          color: Colors.WHITE,
        },
        textColorInherit: {
          ...(overrides?.MuiTab?.textColorInherit as TabProps),
          color: Colors.WHITE,
        },
        textColorPrimary: {
          ...(overrides?.MuiTab?.textColorPrimary as TabProps),
          color: Colors.WHITE,
        },
        root: {
          ...(overrides?.MuiTab?.root as TabProps),
          color: Colors.WHITE,
        },
      },
    },
  }),
);
