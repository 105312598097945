import { selectFloorPlanTags } from "modules/customFloorPlan/redux/selectors";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { TemplatesType } from "types/theater";

export const useFilteredTemplates = (templates?: TemplatesType) => {
  const selectedTags = useSelector(selectFloorPlanTags);

  const templateData = useMemo(() => {
    if (!templates || Object.keys(templates).length === 0) {
      return {
        publicTemplates: [],
        privateTemplates: [],
      };
    }

    const data = Object.entries(templates || {}).reduce(
      (acc: TemplatesType, [key, codeTemplates]) => {
        if (selectedTags.length > 0) {
          const filteredTemplates = codeTemplates.filter((template) => {
            const allTags = [...(template.tags || []), ...selectedTags];
            const duplicated = allTags.filter(
              (tag, index) => allTags.indexOf(tag) !== index,
            );

            return duplicated.length > 0;
          });
          acc[key] = filteredTemplates;
          return acc;
        }

        acc[key] = codeTemplates;
        return acc;
      },
      {},
    );
    return data;
  }, [templates, selectedTags]);

  return templateData;
};
