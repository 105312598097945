export enum TranscriptionActions {
  CLEAR_AUDIO_CONTEXT = "CLEAR_AUDIO_CONTEXT",
  CLEAR_FINALIZED_TRANSCRIPT = "CLEAR_FINALIZED_TRANSCRIPT",
  CLEAR_PROCESSOR = "CLEAR_PROCESSOR",
  CLEAR_WEBSOCKET = "CLEAR_WEBSOCKET",
  UPDATE_AUDIO_CONTEXT = "UPDATE_AUDIO_CONTEXT",
  UPDATE_FINALIZED_TRANSCRIPT = "UPDATE_FINALIZED_TRANSCRIPT",
  UPDATE_PROCESSOR = "UPDATE_PROCESSOR",
  UPDATE_SAMPLE_RATE = "UPDATE_SAMPLE_RATE",
  UPDATE_TOKEN = "UPDATE_TOKEN",
  UPDATE_TRANSCRIPT = "UPDATE_TRANSCRIPT",
  UPDATE_WEBSOCKET = "UPDATE_WEBSOCKET",
}
