import { useQuery } from "@apollo/client";
import {
  GetEventPollsQuery,
  GetEventPollsQueryVariables,
} from "graphql/generated";
import { selectCurrentEventId } from "modules/event/selectors";
import { GET_POLLS } from "modules/polls/graphql";
import { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";

export const usePolls = () => {
  const eventId = useSelector(selectCurrentEventId);
  const { data, refetch } = useQuery<
    GetEventPollsQuery,
    GetEventPollsQueryVariables
  >(GET_POLLS);

  useEffect(() => {
    if (!eventId) {
      return;
    }

    refetch({ eventId });
  }, [eventId, refetch]);

  const ongoingPolls = useMemo(() => {
    if (!data) {
      return [];
    }

    if (data.eventPolls.__typename === "GQLError") {
      return [];
    }

    return data.eventPolls.polls.filter((poll) => poll.status === "ONGOING");
  }, [data]);

  const unansweredPolls = useMemo(
    () =>
      ongoingPolls.filter((poll) => typeof poll.selectedAnswer !== "number"),
    [ongoingPolls],
  );

  return { ongoingPolls, unansweredPolls };
};
