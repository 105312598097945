import {
  EventEmbedContentMode,
  CreateEmbedContentMutation,
  DeleteEventEmbedContentMutation,
  CreateEmbedContentMutationVariables,
  DeleteEventEmbedContentMutationVariables,
} from "graphql/generated";
import { useI18n } from "i18n";
import { useSelector } from "react-redux";
import {
  CREATE_EMBED_CONTENT,
  DELETE_EVENT_EMBED_CONTENT,
} from "modules/eventEmbedContent/graphql";
import { useCallback, useContext, useState } from "react";
import { useMutation } from "@apollo/client";
import { IEvent } from "modules/event/types";
import { Errors, trackError } from "modules/monitoring";
import { Events, TRACKING_CONTEXT } from "modules/tracking";
import { selectPresentationModeContent } from "modules/eventEmbedContent/redux";
import useNotificationActions from "modules/notification/hooks/useNotificationActions";

const usePresentContentActions = (event: IEvent | null) => {
  const [createEmbedContent] = useMutation<
    CreateEmbedContentMutation,
    CreateEmbedContentMutationVariables
  >(CREATE_EMBED_CONTENT);
  const { t } = useI18n(["event", "common"]);
  const [deleteEventEmbedContent] = useMutation<
    DeleteEventEmbedContentMutation,
    DeleteEventEmbedContentMutationVariables
  >(DELETE_EVENT_EMBED_CONTENT);
  const content = useSelector(selectPresentationModeContent);
  const { track } = useContext(TRACKING_CONTEXT);
  const [isLoading, setIsLoading] = useState(false);
  const { addErrorNotification } = useNotificationActions();

  const shareContent = async (url: string) => {
    if (!event) return;
    try {
      setIsLoading(true);
      const { data } = await createEmbedContent({
        variables: {
          eventId: event.id,
          url,
          mode: EventEmbedContentMode.Presentation,
        },
      });

      if (!data) {
        trackError("No data received", {
          label: Errors.EMBED_CONTENT_CREATE_MUTATION,
          url,
        });

        addErrorNotification({
          message: t("event:present.content.error.sharing"),
          position: "tc",
        });
        setIsLoading(false);
        return;
      }

      if (data.createEmbedContent?.__typename === "GQLError") {
        trackError(data.createEmbedContent.message, {
          label: Errors.EMBED_CONTENT_CREATE_MUTATION,
          url,
        });

        addErrorNotification({
          message: t("event:present.content.error.sharing"),
          position: "tc",
        });
        setIsLoading(false);
        return;
      }

      track(Events.PRESENT_CONTENT_SHARED, {
        eventId: event.id,
        eventType: event.eventType,
        url,
      });
    } catch (error) {
      trackError(error, {
        label: Errors.GRAPHQL_NETWORK_ERROR,
        url,
      });
    }
    setIsLoading(false);
  };

  const unShareContent = useCallback(async () => {
    if (!content || !event) return;
    try {
      setIsLoading(true);
      const { data } = await deleteEventEmbedContent({
        variables: {
          id: content.id,
          eventId: event.id,
        },
      });

      if (!data) {
        trackError("No data received", {
          label: Errors.EMBED_CONTENT_DELETE_MUTATION,
          id: content.id,
        });

        addErrorNotification({
          message: t("event:present.content.error.unsharing"),
          position: "tc",
        });

        setIsLoading(false);
        return;
      }

      if (data.deleteEventEmbedContent?.__typename === "GQLError") {
        trackError(data.deleteEventEmbedContent.message, {
          label: Errors.EMBED_CONTENT_DELETE_MUTATION,
          id: content.id,
        });

        addErrorNotification({
          message: t("event:present.content.error.unsharing"),
          position: "tc",
        });
        setIsLoading(false);
        return;
      }
    } catch (error) {
      trackError(error, {
        label: Errors.GRAPHQL_NETWORK_ERROR,
      });
    }
    setIsLoading(false);
  }, [addErrorNotification, content, deleteEventEmbedContent, event, t]);

  return {
    shareContent,
    unShareContent,
    isLoading,
  };
};

export default usePresentContentActions;
