import { useAppDispatch } from "store/hooks";
import { useCustomRegistrationQuestions } from "../useCustomRegistrationQuestions";
import { useRegistrationQuestionAnswers } from "../useRegistrationQuestionAnswers";
import { setShowForm } from "../../redux/slice";

export const useHasAnsweredQuestions = (eventId?: string) => {
  const dispatch = useAppDispatch();
  const {
    questions,
    status: questionStatus,
    fetchStatus: questionFetchStatus,
    isLoading: questionLoading,
  } = useCustomRegistrationQuestions(eventId);
  const {
    answers,
    status: answersStatus,
    fetchStatus: answerFetchStatus,
    isLoading: answerLoading,
  } = useRegistrationQuestionAnswers(eventId);

  const getStatus = () => {
    if (questionStatus === "error" || answersStatus === "error") {
      return "error";
    }

    if (questionStatus === "success" && answersStatus === "success") {
      return "success";
    }

    if (questionFetchStatus === "idle" && answerFetchStatus === "idle") {
      return "idle";
    }

    if (questionStatus === "pending" || answersStatus === "pending") {
      return "loading";
    }

    return "idle";
  };

  const hasQuestions = questions.length > 0;
  const showRegistrationForm = () => {
    dispatch(setShowForm(true));
  };

  return {
    hasQuestions,
    hasAnsweredQuestions: hasQuestions && answers.length > 0,
    showRegistrationForm,
    status: getStatus(),
    loading: questionLoading || answerLoading,
  };
};
