import { createSelector } from "@reduxjs/toolkit";
import { IChatState } from "types/chat";
import { initialState } from "./reducer";
import { IChannel } from "../types";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const selectChatState = (state: any) => state.chatV2;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const selectActiveChannel = (state: any) =>
  (state.chat ?? initialState).activeChannel;

const makeSelectChatChannels = () =>
  createSelector(selectChatState, (substate: IChatState) => substate.channels);

const makeSelectActiveChannel = createSelector(
  selectChatState,
  (substate) =>
    substate.activeChannel && substate.channels.get(substate.activeChannel),
);
const selectUnreadCount = createSelector(
  selectActiveChannel,
  selectChatState,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (channel: IChannel, substate: any) => substate.newMessageCount,
);
const makeSelectChat = () =>
  createSelector(selectChatState, (substate: IChatState) => substate.chats);
const makeSelectChatIsLoading = () =>
  createSelector(selectChatState, (substate: IChatState) => substate.isLoading);
const makeSelectChatListenersSetup = () =>
  createSelector(
    selectChatState,
    (substate: IChatState) => substate.areListenersSetup,
  );
const selectChatCountForActiveChannel = createSelector(
  selectChatState,
  (substate: IChatState): number =>
    substate.activeChannel
      ? substate.chats.get(substate.activeChannel)?.size ?? 0
      : 0,
);
const makeSelectChatForActiveChannel = () =>
  createSelector(
    selectChatState,
    (substate: IChatState) =>
      substate.activeChannel && substate.chats.get(substate.activeChannel),
  );

const selectChatPrefilledMessageForActiveChannel = createSelector(
  selectChatState,
  makeSelectActiveChannel,
  (substate, channel) => {
    if (channel?.id) {
      return substate?.preFilledMessage?.[channel?.id] ?? "";
    }
    return "";
  },
);

export {
  makeSelectChatChannels,
  makeSelectChat,
  makeSelectActiveChannel,
  selectChatCountForActiveChannel,
  selectUnreadCount,
  makeSelectChatForActiveChannel,
  makeSelectChatIsLoading,
  makeSelectChatListenersSetup,
  selectChatPrefilledMessageForActiveChannel,
};
