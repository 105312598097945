export const CONTENT_TYPES = [
  {
    label: "event:content.type.feedback",
    value: "Feedback/Event Survey",
  },
  {
    label: "event:content.type.entertainment",
    value: "Entertainment (Music, Games, Ice-breakers)",
  },
  {
    label: "event:content.type.information",
    value: "Event Information",
  },
  {
    label: "event:content.type.sponsorship",
    value: "Sponsorship Content",
  },
  {
    label: "event:content.type.other",
    value: "Other",
  },
];
