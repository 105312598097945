import { datadogRum } from "@datadog/browser-rum";

const FEATURE_FLAG_KEY = "featureFlags";

const updateFeatureFlagInContext = (flag: string, treatment: string): void => {
  const ctx = datadogRum.getGlobalContext();
  if (!(FEATURE_FLAG_KEY in ctx)) {
    ctx[FEATURE_FLAG_KEY] = {};
  }

  const { [FEATURE_FLAG_KEY]: featureFlags } = ctx;

  if (typeof featureFlags !== "object") {
    return;
  }

  datadogRum.setGlobalContextProperty(FEATURE_FLAG_KEY, {
    ...featureFlags,
    [flag]: { treatment },
  });
};

export default updateFeatureFlagInContext;
