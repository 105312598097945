import React from "react";
import { Create } from "@remo-co/ui-core/src/icons/Create";
import { Delete } from "@remo-co/ui-core/src/icons/Delete";
import { Poll } from "@remo-co/ui-core/src/icons/Poll";
import classnames from "classnames";
import { Avatar } from "modules/avatar";
import { MANAGE_EVENT_CONTEXT } from "modules/manageEvent";
import { useCompanyPlanSettings } from "modules/companyPlanSettings";
import { useI18n } from "i18n";
import { ISponsor } from "modules/event/types";
import useConfirmationDialog from "modules/dialogNotification/hooks/useConfirmationDialog";
import "modules/manageEvent/components/ManageEvent/styles.scss";
import { List } from "@remo-co/ui-core/src/components/List";
import { Tooltip } from "@remo-co/ui-core/src/components/Tooltip";
import { ListItem } from "@remo-co/ui-core/src/components/ListItem";
import { ListItemAvatar } from "@remo-co/ui-core/src/components/ListItemAvatar";
import { ListItemText } from "@remo-co/ui-core/src/components/ListItemText";
import { Typography } from "@remo-co/ui-core/src/components/Typography";
import { ListItemSecondaryAction } from "@remo-co/ui-core/src/components/ListItemSecondaryAction";
import { IconButton } from "@remo-co/ui-core/src/components/IconButton";
import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";
import { Theme } from "@remo-co/ui-core/src/types";
import useSponsors from "../../event/hooks/useSponsors";

const useStyles = makeStyles<Theme>(() => ({
  root: {
    "& li": {
      border: 0,
      width: "100%",
    },
    "& li:hover": {
      border: 0,
      boxShadow: "none",
    },
  },
  url: {
    maxWidth: "50%",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
    display: "inline-block",
  },
}));

export interface ISponsorListProps {
  sponsors: ISponsor[];
  eventId: string | undefined;
  updateSponsors?: (allSponsors: ISponsor[]) => void;
  editSponsor: (sponsor: ISponsor) => void;
  isClone: boolean;
}

const SponsorList = ({
  sponsors,
  eventId,
  updateSponsors,
  editSponsor,
  isClone,
}: ISponsorListProps) => {
  const { deleteSponsor, exportMembers } = useSponsors();
  const { t } = useI18n(["common", "eventForm"]);
  const { getMaxAdsPerEvent } = useCompanyPlanSettings();
  const maxAllowedAds = getMaxAdsPerEvent();
  const { setLoading } = React.useContext(MANAGE_EVENT_CONTEXT);
  const classes = useStyles();
  const confirm = useConfirmationDialog({
    message: t("eventForm:delete.event.sponsorship"),
    confirmLabel: t("confirm.text.delete"),
    cancelLabel: t("dismiss.text"),
  });

  const removeSponsor = async (sponsorId: string) => {
    if (updateSponsors) {
      setLoading(t("eventForm:delete.sponsor"));
      const allSponsors = await deleteSponsor(eventId, sponsorId);

      updateSponsors(allSponsors ?? []);
      setLoading("");
    }
  };

  if (!sponsors.length) {
    return null;
  }

  return (
    <List className={classnames(classes.root, "sponsors-list", "mar-top-40")}>
      {sponsors.map((sponsorData: ISponsor, index: number) => {
        const isMaxed = maxAllowedAds <= index;

        const handleDelete = () =>
          removeSponsor(sponsorData._id || index.toString());
        return (
          <Tooltip
            title={
              isMaxed
                ? `Your current plan allows maximum of ${maxAllowedAds} sponsors. Remove a sponsor or upgrade your plan.`
                : ""
            }
            key={sponsorData.name}
          >
            <ListItem className={classnames({ max: isMaxed })} disableGutters>
              <ListItemAvatar>
                <Avatar alt={sponsorData.name} pictureUrl={sponsorData.logo} />
              </ListItemAvatar>
              <ListItemText
                primary={
                  <Typography variant="h5">{sponsorData.name}</Typography>
                }
                secondary={
                  <Typography variant="caption" className={classes.url}>
                    {sponsorData.ctaLink}
                  </Typography>
                }
              />
              <ListItemSecondaryAction className="actions">
                <Tooltip
                  title={
                    !eventId || isClone
                      ? t("eventForm:edit.sponsor.list")
                      : t("eventForm:edit.sponsor.tooltip")
                  }
                  placement="bottom"
                >
                  <div>
                    <IconButton
                      className="imgBtn"
                      disabled={!eventId || isClone}
                      onClick={() => editSponsor(sponsorData)}
                      size="large"
                    >
                      <Create fontSize="small" />
                    </IconButton>
                  </div>
                </Tooltip>
                <Tooltip
                  title={
                    !eventId || isClone
                      ? t("eventForm:export.sponsor.list")
                      : t("eventForm:click.download")
                  }
                  placement="bottom"
                >
                  <div>
                    <IconButton
                      disabled={!eventId || isClone}
                      onClick={() =>
                        eventId ? exportMembers(sponsorData, eventId) : null
                      }
                      size="large"
                    >
                      <Poll fontSize="small" />
                    </IconButton>
                  </div>
                </Tooltip>

                <Tooltip title={t("eventForm:click.delete")} placement="bottom">
                  <IconButton
                    onClick={() => confirm(handleDelete)}
                    size="large"
                  >
                    <Delete fontSize="small" />
                  </IconButton>
                </Tooltip>
              </ListItemSecondaryAction>
            </ListItem>
          </Tooltip>
        );
      })}
    </List>
  );
};

export default SponsorList;
