export const enum PAGES {
  ACCOUNT_SETTINGS = "Account Settings Page",
  BACKGROUND_COMPANY_CREATE_PAGE = "Background Company Creation Page",
  CFP_VALIDATOR = "Custom Floor Plan Validator",
  CHANGE_PASSWORD = "Change Password Page",
  EVENT_FLOOR = "Event Floor",
  EVENT_LANDING_PAGE = "Event Landing Page",
  FORGOT_PASSWORD = "Forgot Password Page",
  GET_STARTED = "Get Started Page",
  MAGIC_LINK_CHECK_EMAIL = "Magic Link - Check Email Page",
  MAGIC_LINK_REDIRECT = "Magic Link - Redirect Page",
  MANAGE_EVENT_CLONE = "Manage Event Page - Clone Event",
  MANAGE_EVENT_CREATE = "Manage Event Page - Create Event",
  MANAGE_EVENT_UPDATE = "Manage Event Page - Update Event",
  MIC_CAM_CHECK = "Mic & Cam Check Page",
  MY_EVENTS = "My Events Page",
  ONBOARDING_PROFILE_PROMPT = "Attendee Onboarding - Update Profile Prompt",
  ONBOARDING_PROFILE_UPDATE = "Attendee Onboarding - Update Profile",
  PAGE_NOT_FOUND = "404 Page",
  PUBLIC_GEARTEST = "Public Geartest Page",
  SELECT_COMPANY = "Select Company Page",
  SIGNIN = "Sign-in Page",
  SIGNUP = "Sign-up Page",
  SSO_REDIRECT = "SSO Redirect",
  TEMPORARY_SIGNIN = "Temporary Sign-in Page",
}
