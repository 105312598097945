import { Theme } from "@remo-co/ui-core/src/types";
import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";

export const useStyles = makeStyles<Theme>((theme) => ({
  addContentButtonIcon: {
    fontSize: theme.spacing(1.75),
  },
  addContentButton: {
    height: "unset",
    padding: theme.spacing(2),
  },
  popoverContent: {
    padding: theme.spacing(3),
    border: `${theme.spacing(0.125)} solid ${theme.palette.gray.light}`,
    width: theme.spacing(45),
    borderRadius: theme.spacing(0.625),
    backgroundColor: theme.palette.white,
  },
  promptIcon: {
    color: theme.palette.warning.light,
  },
  message: {
    marginLeft: theme.spacing(2),
  },
  dialogButtons: {
    display: "flex",
    marginTop: theme.spacing(2),
    justifyContent: "flex-end",
    marginRight: theme.spacing(2),
  },
  button: {
    width: theme.spacing(25),
    height: theme.spacing(4),
    margin: theme.spacing(1),
    fontSize: theme.spacing(1.75),
    color: theme.palette.white,
    textDecoration: "none",
  },
  buttonGrey: {
    color: theme.palette.grey[600],
    borderColor: theme.palette.grey[600],
  },
}));
