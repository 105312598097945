import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "store";
import logger from "logging/logger";
import { supportsSetSinkId } from "../utils";
import { DeviceInfoState, PermissionState } from "../types";

const selectDeviceInfoState = (state: RootState): DeviceInfoState =>
  state.deviceInfo;

const isVideoInputDevice = (device: MediaDeviceInfo): boolean =>
  device.kind === "videoinput";

const isAudioInputDevice = (device: MediaDeviceInfo): boolean =>
  device.kind === "audioinput";

const isAudioOutputDevice = (device: MediaDeviceInfo): boolean =>
  device.kind === "audiooutput";

const hasNonEmptyLabel = (device: MediaDeviceInfo): boolean =>
  device.label !== "";

const getAudioInputDevices = ({
  devices,
}: DeviceInfoState): MediaDeviceInfo[] => devices.filter(isAudioInputDevice);

const getAudioInputDevicesWithLabels = (
  deviceInfo: DeviceInfoState,
): MediaDeviceInfo[] =>
  getAudioInputDevices(deviceInfo).filter(hasNonEmptyLabel);

const getAudioOutputDevices = ({
  devices,
}: DeviceInfoState): MediaDeviceInfo[] => devices.filter(isAudioOutputDevice);

const getAudioOutputDevicesWithLabels = (
  deviceInfo: DeviceInfoState,
): MediaDeviceInfo[] =>
  getAudioOutputDevices(deviceInfo).filter(hasNonEmptyLabel);

const getVideoInputDevices = ({
  devices,
}: DeviceInfoState): MediaDeviceInfo[] => devices.filter(isVideoInputDevice);

const getVideoInputDevicesWithLabels = (
  deviceInfo: DeviceInfoState,
): MediaDeviceInfo[] =>
  getVideoInputDevices(deviceInfo).filter(hasNonEmptyLabel);

const selectAudioInputDevicesWithLabels = createSelector(
  selectDeviceInfoState,
  getAudioInputDevicesWithLabels,
);

const selectAudioInputDevices = createSelector(
  selectDeviceInfoState,
  getAudioInputDevices,
);

const selectAudioOutputDevicesWithLabels = createSelector(
  selectDeviceInfoState,
  getAudioOutputDevicesWithLabels,
);

const selectVideoInputDevicesWithLabels = createSelector(
  selectDeviceInfoState,
  getVideoInputDevicesWithLabels,
);

const selectVideoInputDevices = createSelector(
  selectDeviceInfoState,
  getVideoInputDevices,
);

export const selectIsMobile = createSelector(
  selectDeviceInfoState,
  (state) => state.isMobile,
);

const selectPermissionsState = createSelector(
  selectDeviceInfoState,
  ({ permissions }) => permissions,
);

export const selectAudioPermissionsState = createSelector(
  selectPermissionsState,
  ({ audio }) => audio,
);

export const selectVideoPermissionsState = createSelector(
  selectPermissionsState,
  ({ video }) => video,
);

export const selectHasAudioPermissions = createSelector(
  selectPermissionsState,
  ({ audio }) =>
    audio === PermissionState.GRANTED || audio === PermissionState.IDLE,
);

export const selectHasVideoPermissions = createSelector(
  selectPermissionsState,
  ({ video }) =>
    video === PermissionState.GRANTED || video === PermissionState.IDLE,
);

/**
 * @deprecated use individual selectors from `modules/deviceInfo` instead
 */
export const getDeviceInfo = createSelector(
  selectDeviceInfoState,
  selectAudioOutputDevicesWithLabels,
  selectVideoInputDevicesWithLabels,
  selectAudioInputDevicesWithLabels,
  (deviceInfo, audioOutputDevices, videoInputDevices, audioInputDevices) => {
    const hasSpeakers = audioOutputDevices.length > 0;
    const isSetSinkIdSupported = supportsSetSinkId();

    if (
      navigator &&
      navigator.mediaDevices &&
      typeof navigator.mediaDevices.addEventListener === "undefined"
    ) {
      navigator.mediaDevices.addEventListener = () => {
        logger.warn("[getDeviceInfo] Calling mocked addEventListener");
      };
    }

    if (
      navigator &&
      navigator.mediaDevices &&
      typeof navigator.mediaDevices.removeEventListener === "undefined"
    ) {
      navigator.mediaDevices.removeEventListener = () => {
        logger.warn("[getDeviceInfo] Calling mocked removeEventListener");
      };
    }

    return {
      ...deviceInfo,
      hasSpeakers,
      hasWebcam: videoInputDevices.length > 0,
      hasMicrophone: audioInputDevices.length > 0,
      // TODO: extract to const
      hasDevicesAPI: !!navigator?.mediaDevices?.enumerateDevices,
      isSetSinkIdSupported,
      canChangeSpeakers: hasSpeakers && isSetSinkIdSupported,
      audioOutputDevices,
      videoInputDevices,
      audioInputDevices,
    };
  },
);

export const DEFAULT_MEDIA_STREAM_CONSTRAINTS: Readonly<MediaStreamConstraints> =
  {
    audio: true,
    video: true,
  };

const selectIsAnyAudioInputAvailable = createSelector(
  selectAudioInputDevices,
  (audioInputDevices) => audioInputDevices.length !== 0,
);

const selectIsAnyVideoInputAvailable = createSelector(
  selectVideoInputDevices,
  (videoInputDevices) => videoInputDevices.length !== 0,
);

export const selectAvailableMediaStreamConstraints = createSelector(
  selectIsAnyAudioInputAvailable,
  selectIsAnyVideoInputAvailable,
  (
    isAnyAudioInputAvailable,
    isAnyVideoInputAvailable,
  ): MediaStreamConstraints => {
    const isAnyAudioInputOrVideoInputAvailable =
      isAnyAudioInputAvailable || isAnyVideoInputAvailable;

    if (!isAnyAudioInputOrVideoInputAvailable)
      return DEFAULT_MEDIA_STREAM_CONSTRAINTS;

    return {
      audio: isAnyAudioInputAvailable,
      video: isAnyVideoInputAvailable,
    };
  },
);
