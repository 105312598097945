import { useSelector } from "react-redux";
import { Transcription } from "modules/transcription";
import { TransitionGroup } from "react-transition-group";
import { selectIsInBroadcast } from "modules/broadcast/redux/selectors";
import { selectShowLiveCaptions } from "modules/transcription/redux/selectors";
import classNames from "classnames";
import { Fade } from "@remo-co/ui-core/src/components/Fade";
import TranscriptionFeedStyles from "./styles";
import useTranscriptionFeed from "../../hooks/useTranscriptionFeed/useTranscriptionFeed";

const TranscriptionFeed = (): JSX.Element | null => {
  const classes = TranscriptionFeedStyles();
  const { transcripts, activeSpeaker } = useTranscriptionFeed();
  const showLiveCaptions = useSelector(selectShowLiveCaptions);
  const isInBroadcast = useSelector(selectIsInBroadcast);

  if (!isInBroadcast || !showLiveCaptions) return null;

  return (
    <div data-testid="transcription-wrapper" className={classes.root}>
      <ul>
        <TransitionGroup
          data-testid="transcription-transition-group"
          className={classes.messageFeed}
        >
          {transcripts.map((ts) => {
            const transcriptionKey = ts.userId;
            return (
              <Fade
                key={transcriptionKey}
                in
                timeout={{ enter: 150, exit: 150 }}
                mountOnEnter
                unmountOnExit
              >
                <div
                  key={transcriptionKey}
                  className={classNames(classes.transcription, {
                    [classes.activeTranscription]: ts.userId === activeSpeaker,
                  })}
                >
                  <li className={classes.message}>
                    <Transcription key={transcriptionKey} message={ts.data} />
                  </li>
                </div>
              </Fade>
            );
          })}
        </TransitionGroup>
      </ul>
    </div>
  );
};

export default TranscriptionFeed;
