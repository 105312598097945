import { ReactElement, memo, useEffect, useRef, useContext } from "react";
import lottie, { AnimationItem } from "lottie-web/build/player/lottie_light";
import useStyles from "./styles";
import { LottieAnimationsEnabledContext } from "./Context";
import { AnimationDirection } from "../../types";

type AnimationData = unknown;

interface ILottieProps {
  animationData: AnimationData;
  loop?: boolean | number;
  autoplay?: boolean;
  className?: string;
  speedMultiplier?: number;
  direction?: AnimationDirection;
  isPaused?: boolean;
  onComplete?: () => void;
}

const Lottie = ({
  animationData,
  loop = true,
  autoplay = true,
  className,
  speedMultiplier = 1,
  direction = AnimationDirection.NORMAL,
  isPaused = false,
  onComplete,
}: ILottieProps): ReactElement => {
  const classes = useStyles();
  const lottieRef = useRef<HTMLDivElement>(null);
  const animationRef = useRef<AnimationItem>();
  const animationsEnabled = useContext(LottieAnimationsEnabledContext);

  useEffect(() => {
    if (!lottieRef.current) return undefined;
    animationRef.current = lottie.loadAnimation({
      container: lottieRef.current,
      animationData,
      renderer: "svg",
      loop,
      autoplay,
      rendererSettings: { className },
    });

    animationRef.current.setSpeed(speedMultiplier);
    animationRef.current.setDirection(direction);

    if (!animationsEnabled ?? isPaused) {
      animationRef.current.pause();
    } else {
      animationRef.current.play();
    }

    if (onComplete)
      animationRef.current.addEventListener("complete", onComplete);

    return () => {
      if (onComplete)
        animationRef.current?.removeEventListener("complete", onComplete);
      animationRef.current?.destroy();
    };
  }, [
    loop,
    isPaused,
    autoplay,
    direction,
    className,
    onComplete,
    animationData,
    speedMultiplier,
    animationsEnabled,
  ]);

  return (
    <div className={classes.container} data-testid="lottie" ref={lottieRef} />
  );
};

export default memo(Lottie);
