import { Constants } from "./constants";

export const setIsExpanded = (isExpanded: boolean) => ({
  type: Constants.TOGGLE_EXPAND as const,
  isExpanded,
});

export const setItemsPerRow = (itemsPerRow: number, liveUserIds: string[]) => ({
  type: Constants.SET_ITEMS_PER_ROW as const,
  itemsPerRow,
  liveUserIds,
});

export const updateSlots = (liveUserIds: string[]) => ({
  type: Constants.UPDATE_SLOTS as const,
  liveUserIds,
});

export const setIsContainerMounted = (isContainerMounted: boolean) => ({
  type: Constants.SET_IS_CONTAINER_MOUNTED as const,
  isContainerMounted,
});

export const resetAudienceViewState = () => ({
  type: Constants.RESET_AUDIENCE_VIEW_STATE as const,
});

export type AudienceViewAction =
  | ReturnType<typeof setIsExpanded>
  | ReturnType<typeof setItemsPerRow>
  | ReturnType<typeof updateSlots>
  | ReturnType<typeof setIsContainerMounted>
  | ReturnType<typeof resetAudienceViewState>;
