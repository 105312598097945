import { useCallback } from "react";
import {
  ExtendedDailyParticipant,
  useParticipantIds,
} from "@daily-co/daily-react";
import { UserData } from "../useUserData/types";

export const useParticipantsAtTable = ({
  tableId,
}: {
  tableId: string | null;
}) => {
  const participantsAtTable = useParticipantIds({
    filter: useCallback(
      ({ local, userData }: ExtendedDailyParticipant) => {
        if (tableId === null || local) {
          return false;
        }

        return tableId === (userData as UserData)?.conversationId;
      },
      [tableId],
    ),
  });

  return participantsAtTable;
};
