import { useI18n } from "i18n";
import { Typography } from "@remo-co/ui-core/src/components/Typography";
import { useStyles } from "./HostReplaceScreenText.styles";

export interface Props {
  isScreenType?: boolean;
}

export const HostReplaceScreenText = ({ isScreenType = true }: Props) => {
  const { t } = useI18n();
  const styles = useStyles();

  return (
    <div
      data-testid="hostscreen-text"
      className={styles.displayScreenExpandControls}
    >
      <div className={styles.displayScreenExpandText}>
        <Typography color="inherit">
          {isScreenType
            ? t("replace.primary.screen.share")
            : t("replace.primary.screen")}
        </Typography>
      </div>
    </div>
  );
};
