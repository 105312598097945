import { SVGRoomNames } from "../../../constants";

export const computeForOverlap = (roomArray: Array<HTMLDivElement | null>) => {
  // no rooms
  if (!roomArray || !roomArray.length) {
    return { overlappingRoomIds: [], areElementsVisible: false };
  }
  const overlappingRoomIds: string[] = [];
  let areElementsVisible = false;

  roomArray.forEach((roomRef) => {
    if (
      roomRef &&
      !(Object.values(SVGRoomNames) as string[]).includes(roomRef.id)
    ) {
      const { x, y, width, height } = roomRef.getBoundingClientRect();
      const xP = x + width / 2;
      const yP = y + height / 2;
      const topElement = document.elementFromPoint(xP, yP);

      // topElement is not null - elements are visible
      // setting this on every loop ensures all have been rendered before closing overlay
      areElementsVisible = !!topElement;

      if (
        topElement &&
        !(
          topElement.id === roomRef.id ||
          (topElement.parentElement &&
            topElement.parentElement.id === roomRef.id)
        )
      ) {
        overlappingRoomIds.push(roomRef.id);
      }
    }
  });

  return { overlappingRoomIds, areElementsVisible };
};
