import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";

const useStyles = makeStyles((theme) => ({
  title: {
    color: theme.palette.blue.dark,
    fontWeight: 800,
    marginBottom: 10,
  },
  activeOption: {
    color: theme.palette.blue.dark,
  },
}));

export default useStyles;
