import { useSelector } from "react-redux";
import { useCallback, useContext } from "react";
import { IRoom } from "types";
import logger from "logging/logger";
import { MAX_ALLOWED_PER_FLOOR } from "@remo-co/constants";
import {
  DEFAULT_DIRECTORY_LIMIT,
  DEFAULT_EVENT_LIMIT,
} from "modules/eventDirectory/constants";
import { EVENT_CONTEXT } from "modules/event/eventView/EventViewContext";
import { selectCompany } from "modules/company/redux/selectors";
import { getCompanyCoupon } from "modules/company/companyCoupon/companyCouponSelectors";
import { getMaxAllowedInTable } from "modules/companyPlanSettings/utils/event";
import { IMapTemplate } from "types/theater";
import { ICompanyServicePlan } from "modules/company/types";
import isCustomSsoEnabled from "modules/companyPlanSettings/utils/isCustomSsoEnabled";
import isUploadCustomFloorPlanEnabled from "modules/companyPlanSettings/utils/isUploadCustomFloorPlanEnabled";
import areIntegrationsEnabled from "modules/companyPlanSettings/utils/areIntegrationsEnabled";
import { DEFAULT_EVENT_CAPACITY } from "modules/event";

const useCompanyPlanSettings = () => {
  const { companyPlan: myCompanyPlan } = useSelector(selectCompany);
  const settings = myCompanyPlan?.settings;
  const unlimitedEventsSettings = myCompanyPlan?.settings?.unlimitedEvents;
  /** @deprecated if within an event, use the selectCurrentEventCompanyPlan selector from modules/event instead */
  const { eventCompanyPlan } = useContext(EVENT_CONTEXT);
  const companyCoupon = useSelector(getCompanyCoupon);
  // TODO: https://github.com/remo-co/remo-conference/issues/5820
  const eventSettingsTimeLimit = settings?.event?.timeLimit ?? -1;

  /**
   * Max no of people allowed to attend the event as per company plan
   */
  const getMaxTheaterCapacity = () =>
    settings ? settings.theater.maxCapacity : DEFAULT_EVENT_CAPACITY;

  const getMaxAllowedInSpace = useCallback(
    (template: IMapTemplate): number => {
      try {
        if (!template || !template.rooms) {
          return 0;
        }

        const rooms = template.rooms.filter(
          (room: IRoom) =>
            // filter out stage and boards
            room.order,
        );

        if (rooms.length) {
          const finalCount = Math.ceil(
            rooms.reduce((acc, room) => {
              const maxAllowedInTable = getMaxAllowedInTable(
                room,
                myCompanyPlan,
              );

              return acc + maxAllowedInTable;
            }, 0) * 0.8,
          );

          return Math.min(MAX_ALLOWED_PER_FLOOR, finalCount);
        }

        return 0;
      } catch (error) {
        const errorMessage = error instanceof Error ? error.message : "";
        logger.error(
          `[getMaxAllowedInSpace] Error calculating seats in template: ${template?.name} err: ${errorMessage}`,
        );
      }

      return Math.min(MAX_ALLOWED_PER_FLOOR, template.maxCapacity || 0);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [myCompanyPlan],
  );

  /**
   * Get max time limit per event
   * TODO: https://github.com/remo-co/remo-conference/issues/5820
   */
  const getTimeLimitPerEvent = () => {
    const plan = eventCompanyPlan ? eventCompanyPlan.settings : settings;

    return plan ? plan.event.timeLimit : -1;
  };

  /**
   * Get max allowed no of ads per event as per company plan
   */
  const getMaxAdsPerEvent = () => {
    const plan = eventCompanyPlan ? eventCompanyPlan.settings : settings;

    return plan ? plan.event.sponsors : 0;
  };

  const hasCustomExitPage = (plan: ICompanyServicePlan | null) =>
    plan ? plan.settings.event.exitPage : false;

  const getDirectoryEventLimit = () =>
    settings && settings.company.directoryEventLimit
      ? settings.company.directoryEventLimit
      : DEFAULT_EVENT_LIMIT;

  const getDirectoryLimit = () =>
    settings && settings.company.directoryLimit
      ? settings.company.directoryLimit
      : DEFAULT_DIRECTORY_LIMIT;

  /**
   * Get no of allowed managers by plan
   * check only my company plan
   */
  const maxAllowedManagers = settings ? settings.company.managers : 0;

  const maxAllowedAssistants =
    settings?.company?.assistants || maxAllowedManagers;

  const isAgencyPlan = (plan: ICompanyServicePlan | null) =>
    Boolean(plan?.isAgencyPlan);

  const isFreePlan = (plan: ICompanyServicePlan | null) =>
    plan?.guestPrice === 0;

  /**
   * If company has coupon, then calculate price per guest based on this coupon code
   * @returns number
   */
  const getGuestPrice = () => {
    if (companyCoupon.discount_percentage && myCompanyPlan?.guestPrice) {
      return Math.max(
        0,
        // eslint-disable-next-line no-unsafe-optional-chaining
        myCompanyPlan?.guestPrice -
          // eslint-disable-next-line no-unsafe-optional-chaining
          myCompanyPlan?.guestPrice *
            // eslint-disable-next-line no-unsafe-optional-chaining
            (companyCoupon?.discount_percentage / 100),
      );
    }

    return myCompanyPlan?.guestPrice;
  };

  /**
   *
   * @param plan
   * @returns allowedOverdueHours
   */
  const getDueHours = (plan: ICompanyServicePlan | null) =>
    plan?.settings.company.allowedOverdueHours || 0;

  return {
    myCompanyPlan,
    /** @deprecated use the selectCurrentEventCompanyPlan selector from modules/event instead */
    eventCompanyPlan,
    getMaxTheaterCapacity,
    getMaxAllowedInSpace,
    getTimeLimitPerEvent,
    eventSettingsTimeLimit,
    getMaxAdsPerEvent, // could probably be renamed
    hasCustomExitPage,
    maxAllowedManagers,
    maxAllowedAssistants,
    isAgencyPlan,
    getDirectoryEventLimit,
    getDirectoryLimit,
    isFreePlan,
    getDueHours,
    getGuestPrice,
    isCustomSsoEnabled: isCustomSsoEnabled(myCompanyPlan),
    isUploadCustomFloorPlanEnabled:
      isUploadCustomFloorPlanEnabled(myCompanyPlan),
    areIntegrationsEnabled: areIntegrationsEnabled(myCompanyPlan),
    isAppsumoPlan: Boolean(myCompanyPlan?.appsumoPlanId),
    unlimitedEventsSettings,
  };
};

export default useCompanyPlanSettings;
