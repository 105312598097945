import { useCallback } from "react";
import { useSelector } from "react-redux";
import { Tooltip } from "@remo-co/ui-core/src/components/Tooltip";
import { Typography } from "@remo-co/ui-core/src/components/Typography";
import { useI18n } from "i18n";
import { selectIsInBroadcast } from "modules/broadcast/redux/selectors";
import { getUserName } from "modules/userProfile";
import { IMessageTypeProps } from "../../Message";
import MessageTime from "../MessageTime";
import ChatModalImageHeader from "../ChatModalImageHeader";
import { getSize, getType, isAudio, isImage } from "../fileUtils";
import { useStyles } from "./styles";

const FileMessage = (props: IMessageTypeProps) => {
  const { t } = useI18n(["company"]);
  const { message, author, isMe = false, onImageClick } = props;
  const isInBroadcast = useSelector(selectIsInBroadcast);
  const classes = useStyles({ isInBroadcast: Boolean(isInBroadcast) });

  const handleImageClick = useCallback(() => {
    if (onImageClick) {
      onImageClick(message.meta.url);
    }
  }, [onImageClick, message]);

  const renderFile = () => {
    const { meta } = message;

    if (isImage(meta.mime)) {
      return (
        <Tooltip
          placement="top"
          title={t("company:click.enlarge") as string}
          aria-label={t("company:click.enlarge")}
        >
          <div
            role="presentation"
            className={classes.imageMessageContainer}
            onClick={handleImageClick}
          >
            <Typography component="span" className={classes.textMessage}>
              {message.text}
            </Typography>
            <div role="presentation" className={classes.imageContainer}>
              <ChatModalImageHeader url={meta.url} />
              <img
                src={meta.url}
                alt={message.text}
                data-testid="image-message"
                className={classes.imageMessage}
              />
            </div>
          </div>
        </Tooltip>
      );
    }

    if (isAudio(meta.mime)) {
      return (
        <audio
          controls
          data-testid="audio-message"
          className={classes.audioMessage}
        >
          <source src={meta.url} type={meta.mime} />
        </audio>
      );
    }

    const type = getType(meta.mime);

    return (
      <a
        className={classes.fileMessage}
        href={meta.url}
        rel="noopener noreferrer"
        target="_blank"
        title={message.text}
      >
        <Typography component="span" className={classes.fileName}>
          {message.text}
        </Typography>
        <Typography component="span" className={classes.fileSize}>
          {getSize(meta.size)} {type}
        </Typography>
      </a>
    );
  };

  if (isMe) {
    return (
      <div className={classes.fileContainer}>
        {renderFile()}
        <MessageTime time={message.time} />
      </div>
    );
  }

  return (
    <div className={classes.fileContainer}>
      <Typography className={classes.authorName} variant="body2">
        {getUserName(author)}
      </Typography>
      {renderFile()}
      <MessageTime time={message.time} />
    </div>
  );
};

export default FileMessage;
