import classNames from "classnames";
import { useStyles } from "./styles";
import { GenericLoader } from "../GenericLoader";
import { Box } from "../Box";
import { Typography } from "../Typography";

export interface Props {
  message: string;
  fullScreen?: boolean;
  "data-testid"?: string;
  className?: string;
}

const PageLoading = ({
  message,
  fullScreen = false,
  className,
  ...rest
}: Props): JSX.Element => {
  const styles = useStyles({ fullScreen });

  return (
    <div className={classNames(styles.root, className)} {...rest}>
      <Box width="200px" mb={2}>
        <span data-testid="generic-loader">
          <GenericLoader />
        </span>
      </Box>
      <Typography aria-live="assertive" aria-label={message}>
        {message}
      </Typography>
    </div>
  );
};

export default PageLoading;
