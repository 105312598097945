import classNames from "classnames";
import React, { ReactNode } from "react";
import "./ScrollableView.scss";

interface IScrollableViewProps {
  withAppBar?: boolean;
  style?: React.CSSProperties;
  onScroll?: (e: React.UIEvent<HTMLDivElement, UIEvent>) => void;
  onScrollToBottom?: () => void;
  className?: string;
  children?: ReactNode;
  "data-testid"?: string;
}

const ScrollableView = ({
  withAppBar,
  children,
  className,
  onScroll,
  onScrollToBottom,
  style = {},
  ...props
}: IScrollableViewProps) => (
  <div
    data-testid={props["data-testid"]}
    className={classNames(
      "scrollable-view-container",
      { "with-appbar": withAppBar },
      className,
    )}
    style={style}
    onScroll={(event) => {
      onScroll?.(event);

      const element = event.currentTarget;
      const scrolledToBottom =
        Math.abs(
          element.scrollHeight - element.scrollTop - element.clientHeight,
        ) < 1;

      if (scrolledToBottom) {
        onScrollToBottom?.();
      }
    }}
  >
    {children}
  </div>
);

export default ScrollableView;
