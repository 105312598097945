import logger from "../logging/logger";

const MESSAGE_SOUND = require("../assets/sounds/message.mp3");

const ENTER_ROOM_SOUND = require("../assets/sounds/entered.mp3");

const INVITE_SOUND = require("../assets/sounds/invite.mp3");

const ANNOUNCEMENT_SOUND = require("../assets/sounds/announcement.mp3");

export const playMessageSound = () => {
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  playSound(MESSAGE_SOUND);
};

export const playEnteredRoomSound = () => {
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  playSound(ENTER_ROOM_SOUND);
};

export const playInviteSound = () => {
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  playSound(INVITE_SOUND);
};

export const playAnnouncementSound = () => {
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  playSound(ANNOUNCEMENT_SOUND);
};

const playSound = async (path: string) => {
  const sound = new Audio(path);

  try {
    await sound.play();
  } catch (error) {
    const errorMessage = error instanceof Error ? error.message : "";
    logger.error(`[playSound] error: ${errorMessage}`);
  }
};
