import defaultProfile from "modules/userAvatar/assets/default-profile.png";
import { getUserPic } from "helpers/appHelper";
import { IUser } from "modules/app/types";

export const getProfileAvatar = (user?: IUser) => {
  if (!user) return defaultProfile;

  let profileImage: string | null = user.profile.picture
    ? // eslint-disable-next-line @typescript-eslint/no-use-before-define
      getGooglePicBySize(user.profile.picture, 500)
    : null;

  if (profileImage === "default") {
    profileImage = getUserPic(user);
  }

  return profileImage && profileImage !== "default"
    ? profileImage
    : defaultProfile;
};

export const getGooglePicBySize = (
  url: string,
  size: number | string,
): string => {
  if (!url) {
    return "";
  }

  if (url.indexOf("googleusercontent.com") === -1) {
    return url;
  }

  return url
    .replace("sz=50", `sz=${size}`)
    .replace("/s50", `/s${size}`)
    .replace("=s50", `=s${size}`)
    .replace("=s96", `=s${size}`);
};

export const isGoogleUserImage = (url: string) => {
  if (!url) {
    return false;
  }

  return url.indexOf("googleusercontent.com") !== -1;
};

export const getBase64 = (file: Blob) =>
  new Promise<string>((resolve, reject) => {
    const reader = new FileReader();

    reader.readAsDataURL(file);
    // eslint-disable-next-line consistent-return
    reader.onload = () => {
      if (typeof reader.result === "string") {
        return resolve(reader.result);
      }

      reject(new Error("not a string"));
    };
    reader.onerror = (error) => reject(error);
  });
