import { useMemo, useReducer } from "react";
import { IEventAgenda } from "../types";
import { IEventAgendaState } from "./types";

const initialState: IEventAgendaState = {
  agendaItems: new Map(),
  currentDate: null,
};

enum ActionTypes {
  REMOVE_AGENDA_ITEM = "REMOVE_AGENDA_ITEM",
  SET_CURRENT_DATE = "SET_CURRENT_DATE",
  UPSERT_AGENDA_ITEM = "UPSERT_AGENDA_ITEM",
}

type EventAgendaActions =
  | {
      type: ActionTypes.UPSERT_AGENDA_ITEM;
      payload: {
        key: string;
        value: IEventAgenda;
      };
    }
  | {
      type: ActionTypes.REMOVE_AGENDA_ITEM;
      payload: string;
    }
  | {
      type: ActionTypes.SET_CURRENT_DATE;
      payload: string;
    };

const reducer = (
  state: IEventAgendaState,
  action: EventAgendaActions,
): IEventAgendaState => {
  switch (action.type) {
    case ActionTypes.UPSERT_AGENDA_ITEM: {
      // Merge the new/updated item
      const { value } = action.payload;

      value.actions = Array.from(value.actions || []);
      const updatedItems = state.agendaItems.set(action.payload.key, {
        ...value,
        id: action.payload.key,
      });

      return {
        ...state,
        // Sort the map using rank field - sorting here so it will update after moving up/down
        agendaItems: new Map(
          [...updatedItems].sort((a, b) => (a[1].rank < b[1].rank ? -1 : 1)),
        ),
      };
    }
    case ActionTypes.REMOVE_AGENDA_ITEM: {
      state.agendaItems.delete(action.payload);

      return {
        ...state,
        agendaItems: state.agendaItems,
      };
    }
    case ActionTypes.SET_CURRENT_DATE: {
      return {
        ...state,
        agendaItems: new Map(), // reset the items on changing the date
        currentDate: action.payload,
      };
    }
    default:
      return state;
  }
};

interface IEventAgendaHookActions {
  upsertAgendaItem(key: string, value: IEventAgenda): void;
  removeAgendaItem(key: string): void;
  setCurrentDate(key: string): void;
}

const useEventAgendaReducer = (): [
  IEventAgendaState,
  IEventAgendaHookActions,
] => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const actions = useMemo(
    () => ({
      upsertAgendaItem(key: string, value: IEventAgenda) {
        dispatch({
          type: ActionTypes.UPSERT_AGENDA_ITEM,
          payload: { key, value },
        });
      },
      removeAgendaItem(key: string) {
        dispatch({
          type: ActionTypes.REMOVE_AGENDA_ITEM,
          payload: key,
        });
      },
      setCurrentDate(key: string) {
        dispatch({
          type: ActionTypes.SET_CURRENT_DATE,
          payload: key,
        });
      },
    }),
    [],
  );

  return [state, actions];
};

export default useEventAgendaReducer;
