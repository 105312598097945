import { get, ref, update } from "firebase/database";
import store from "store";
import { FirebaseError } from "firebase/app";
import { database } from "./firebaseConfig";
import { IUserPreferences } from "../../modules/userPreferences/redux/types";
import { addUserPreferencesInSpace } from "../../modules/userPreferences/redux/actions";
import logger from "../../logging/logger";
import { getCurrentEventId, getCurrentUserId } from "../../helpers/reduxHelper";

const USER_PREFERENCES_PATH = "UserPreferences";

let RETRIES = 0;

export const getUserPreferences = async (
  userId: string,
  currentEventId?: string,
) => {
  const eventId = currentEventId || getCurrentEventId();

  if (!eventId) {
    return;
  }

  const dbRef = ref(
    database,
    `Event/${eventId}/${USER_PREFERENCES_PATH}/${userId}`,
  );

  try {
    const snapshot = await get(dbRef);
    RETRIES = 0;

    if (snapshot.exists()) {
      const userPrefs: IUserPreferences = snapshot.val();
      if (userPrefs) {
        store.dispatch(addUserPreferencesInSpace(userId, userPrefs));
      }
    } else {
      logger.warn(
        `[getUserPreferences] No user preferences found for userId: ${userId}`,
      );
    }
  } catch (error) {
    if (error instanceof FirebaseError) {
      logger.error(
        `[UserPreferences][getUserPreferences] Error while getting user preferences: Code: ${error.code}, message: ${error.message}`,
      );

      // Retry logic for `PERMISSION_DENIED` error
      if (RETRIES <= 2 && error.code === "PERMISSION_DENIED") {
        RETRIES += 1;
        setTimeout(() => {
          getUserPreferences(userId);
        }, 1000);
      }
    } else {
      logger.error(
        `[UserPreferences][getUserPreferences] Error while getting user preferences: ${error}`,
      );
    }
  }
};

/**
 * Update preference for current user in firebase
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const updateEventUserPreference = async (data: any) => {
  // Get currently logged in user
  const loggedinUserId = getCurrentUserId();

  if (loggedinUserId) {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    await updateEventPreferencesByUserId(loggedinUserId, data);
  }
};

export const updateEventPreferencesByUserId = async (
  userId: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any,
) => {
  const eventId = getCurrentEventId();

  if (eventId) {
    const dbRef = ref(
      database,
      `Event/${eventId}/${USER_PREFERENCES_PATH}/${userId}`,
    );

    await update(dbRef, data);
  }
};
