import { Theme } from "@remo-co/ui-core/src/types";
import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";
import { TICKET_STATUS } from "../../constants";

const TicketStatusIconColorMapping = {
  [TICKET_STATUS.ACTIVE]: "#00613F",
  [TICKET_STATUS.INACTIVE]: "#88909F",
  [TICKET_STATUS.SOLD_OUT]: "#DC3545",
  [TICKET_STATUS.UPCOMING]: "#E9BE25",
};

const useStyles = makeStyles<Theme, { status: TICKET_STATUS }>((theme) => ({
  item: {
    background: "white",
    border: `1px solid ${theme.palette.gray.light}`,
    borderRadius: "5px",
    padding: "15px 10px",
    display: "flex",
    justifyContent: "space-between",
  },
  mainDetails: {
    display: "flex",
    flexDirection: "column",
  },
  title: {
    // fontSize: "14px",
    // fontWeight: "500",
  },
  statusBullet: {
    marginRight: theme.spacing(1.25),
    height: "10px",
    width: "10px",
    borderRadius: "50%",
    backgroundColor: ({ status }) => TicketStatusIconColorMapping[status],
  },
  status: {
    display: "flex",
    alignItems: "center",
  },
  statusText: {
    fontSize: "12px",
  },
  otherDetailsContainer: {
    display: "flex",
    alignItems: "center",
    gap: "20px",
  },
  otherDetails: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  actions: {
    display: "flex",
    gap: theme.spacing(0.5),
    justifyContent: "center",
  },
  confirmationActions: {
    margin: "0 !important",
    paddingBottom: `${theme.spacing(2.5)} !important`,
  },
  confirmationActionButtons: {
    width: "100%",
  },
  confirmation: {
    textAlign: "left",
    width: "360px",
  },
}));

export default useStyles;
