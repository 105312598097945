import { RootState } from "store";

export const selectShowCaptions = (state: RootState): boolean =>
  state.transcription.showCaptions;

export const selectShowLiveCaptions = (state: RootState): boolean =>
  state.transcription.showLiveCaptions;

export const selectShouldProcessCaptions = (state: RootState): boolean =>
  state.transcription.shouldProcessCaptions;

export const selectIsLoading = (state: RootState): boolean =>
  state.transcription.isLoading;
