import { ErrorInfo, useEffect, useState } from "react";
import { useI18n } from "i18n";
import { getFirebaseUID } from "services/firebaseService/firebaseConfig";
import { getVar } from "config";
import { BaseCircleLogo } from "modules/app/branding";
import { useHistory } from "react-router";
import { Errors, trackError } from "modules/monitoring";
import {
  FRESH_CHAT_SETTINGS,
  HELP_CHAT_ELEMENT_ID,
  FRESH_CHAT_ELEMENT_ID,
  useNeedHelp,
} from "modules/helpChat";
import { Button } from "@remo-co/ui-core/src/components/Button";
import { Typography } from "@remo-co/ui-core/src/components/Typography";
import { useStyles } from "./ErrorPage.styles";

interface Props {
  error: Error;
  info?: ErrorInfo;
}

const ErrorPage = ({ error, info }: Props) => {
  const styles = useStyles();
  const history = useHistory();
  const [isFCInitialized, setIsFCInitialized] = useState(false);
  const { t } = useI18n(["errorPage"]);
  const { callApi } = useNeedHelp();

  // Reused code for FreshChat is needed here to initialize outside of redux context
  const initFreshChat = () => {
    const { fcWidget } = window;

    if (!fcWidget) {
      return;
    }
    const fcProps: FCProps = {
      token: getVar("REACT_APP_FRESH_CHAT_TOKEN"),
      host: getVar("REACT_APP_FRESH_CHAT_HOST"),
      externalId: getFirebaseUID() ?? "",
      ...FRESH_CHAT_SETTINGS,
    };

    fcWidget.init(fcProps);
  };

  const init = () => {
    const d = document;
    const fcJS = d.createElement("script");

    fcJS.id = FRESH_CHAT_ELEMENT_ID;
    fcJS.async = true;
    fcJS.src = "https://wchat.freshchat.com/js/widget.js";
    fcJS.onload = initFreshChat;
    d.head.appendChild(fcJS);
    setIsFCInitialized(true);
  };

  useEffect(() => {
    if (!isFCInitialized) {
      init();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    trackError(error, { info, label: Errors.UNHANDLED_ERROR });
  }, [error, info]);

  const reloadPage = () => {
    window.location.reload();
  };

  const handleContactUs = () => {
    callApi("showNewMessage");
  };

  const handleClickTakeMeHome = () => {
    history.push("/");
    window.location.reload();
  };

  return (
    <div className={styles.errorPageContainer}>
      <div className={styles.leftMaskContainer} />
      <div className={styles.logoContainer}>
        <BaseCircleLogo full width={150} />
      </div>
      <div className={styles.contentContainer}>
        <div className={styles.titleContainer}>
          <Typography variant="h2" className={styles.title}>
            {t("errorPage:unexpected")}
          </Typography>
          <br />
          <Typography className={styles.subTitle}>
            {t("errorPage:error.occurred")}
          </Typography>
        </div>
        <div className={styles.buttonsContainer}>
          <div className={styles.buttons}>
            <Button
              onClick={reloadPage}
              className={styles.buttonRefresh}
              variant="secondary"
              size="lg"
              color="blue"
            >
              {t("errorPage:refresh.page")}
            </Button>
            <Button
              variant="primary"
              size="lg"
              color="blue"
              fullWidth
              className={styles.button}
              onClick={handleClickTakeMeHome}
            >
              {t("errorPage:take.me.home")}
            </Button>
          </div>
          <Button
            className={styles.contactSupport}
            id={HELP_CHAT_ELEMENT_ID}
            aria-label="Contact us"
            onClick={handleContactUs}
            variant="text"
            color="dark"
            type="button"
          >
            {t("errorPage:contact.support")}
          </Button>
        </div>
      </div>
      <div className={styles.maskContainer} />
    </div>
  );
};

export default ErrorPage;
