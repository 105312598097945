import { sendDeleteRequest } from "services/apiService";

type RemoveLocksResponse =
  | {
      status: true;
    }
  | {
      status: false;
      reason: string;
    };

export const removeLocksFromFloorRequest = ({
  eventId,
  floorId,
}: {
  eventId: string;
  floorId: string;
}) =>
  sendDeleteRequest<void, RemoveLocksResponse>(
    `/event/${eventId}/table-settings/locks/floor/${floorId}`,
  );

export const removeLocksFromEventRequest = ({ eventId }: { eventId: string }) =>
  sendDeleteRequest<void, RemoveLocksResponse>(
    `/event/${eventId}/table-settings/locks`,
  );
