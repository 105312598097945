import { TroubleShootLinks } from "modules/systemCheck/types";

const defaultMicCamTroubleShootLink =
  "https://help.remo.co/en/support/solutions/articles/63000251578-my-mac-camera-or-microphone-is-not-working";

const defaultOsTroubleShootLink =
  "https://help.remo.co/en/support/solutions/articles/63000251000-what-desktop-devices-and-browsers-are-supported-on-remo-conference-";

const troubleshootLinks: Record<string, TroubleShootLinks> = {
  iOS: {
    id: 0,
    osLink:
      "https://help.remo.co/en/support/solutions/articles/63000251000-what-desktop-devices-and-browsers-are-supported-on-remo-conference-",
    micCamLink:
      "https://help.remo.co/en/support/solutions/articles/63000251580-my-iphone-camera-and-microphone-is-not-working",
    speakerLink: "http://help.remo.co/",
  },
  Android: {
    id: 1,
    osLink:
      "https://help.remo.co/en/support/solutions/articles/63000251000-what-desktop-devices-and-browsers-are-supported-on-remo-conference-",
    micCamLink:
      "https://help.remo.co/en/support/solutions/articles/63000251579-my-android-camera-and-microphone-is-not-working",
    speakerLink: "http://help.remo.co/",
  },
  Windows: {
    id: 2,
    osLink:
      "https://help.remo.co/en/support/solutions/articles/63000251000-what-desktop-devices-and-browsers-are-supported-on-remo-conference-",
    micCamLink:
      "https://help.remo.co/en/support/solutions/articles/63000251618-my-windows-10-camera-or-microphone-is-not-working",
    speakerLink: "http://help.remo.co/",
  },
  "Mac OS": {
    id: 3,
    osLink:
      "https://help.remo.co/en/support/solutions/articles/63000251000-what-desktop-devices-and-browsers-are-supported-on-remo-conference-",
    micCamLink:
      "https://help.remo.co/en/support/solutions/articles/63000251578-my-mac-camera-or-microphone-is-not-working",
    speakerLink: "http://help.remo.co/",
  },
};

export const getTroubleShootLinks = (osName: string): TroubleShootLinks => {
  if (Object.keys(troubleshootLinks).includes(osName)) {
    return troubleshootLinks[osName];
  }

  return {
    id: 4,
    osLink: defaultOsTroubleShootLink,
    micCamLink: defaultMicCamTroubleShootLink,
    speakerLink: "http://help.remo.co/",
  };
};
