import { Button } from "@remo-co/ui-core/src/components/Button";
import { Container } from "@remo-co/ui-core/src/components/Container";
import { Tooltip } from "@remo-co/ui-core/src/components/Tooltip";
import { Typography } from "@remo-co/ui-core/src/components/Typography";
import { Radio } from "@remo-co/ui-core/src/components/Radio";
import { RadioGroup } from "@remo-co/ui-core/src/components/RadioGroup";
import { FormControlLabel } from "@remo-co/ui-core/src/components/FormControlLabel";
import { LayersClear } from "@remo-co/ui-core/src/icons/LayersClear";
import { useI18n } from "i18n";
import { DialogType } from "modules/dialogNotification/constants";
import { useAppDispatch } from "store/hooks";
import {
  addDialogNotification,
  updateDialogNotificationParams,
} from "modules/dialogNotification/redux/dialogNotificationSlice";
import useStyles from "./styles";

interface Props {
  unassignAttendeesFromFloor: () => void;
  unassignAttendeesFromEvent: () => void;
  disabled?: boolean;
}

const UnassignAllGuestsButton = ({
  unassignAttendeesFromFloor,
  unassignAttendeesFromEvent,
  disabled,
}: Props): JSX.Element => {
  const dispatch = useAppDispatch();
  const { t } = useI18n(["common", "manageEvent"]);
  const styles = useStyles();

  const handleConfirm = (unassignAllFrom: string) => {
    dispatch(
      updateDialogNotificationParams({
        type: DialogType.UNASSIGN_ALL_ATTENDEES_TABLE_MANAGER,
        data: {
          confirmLoading: true,
        },
      }),
    );
    if (unassignAllFrom === "event") {
      unassignAttendeesFromEvent();
    } else {
      unassignAttendeesFromFloor();
    }
  };

  const getDialogContent = (value: string) => (
    <Container>
      <Typography variant="h5" className={styles.title}>
        {t("manageEvent:remove.assigned.attendees")}
      </Typography>
      <RadioGroup
        value={value}
        onChange={(e) => {
          dispatch(
            updateDialogNotificationParams({
              type: DialogType.UNASSIGN_ALL_ATTENDEES_TABLE_MANAGER,
              data: {
                content: getDialogContent(e.target.value),
                onConfirm: () => handleConfirm(e.target.value),
              },
            }),
          );
        }}
      >
        <FormControlLabel
          className={value === "floor" ? styles.activeOption : ""}
          value="floor"
          control={<Radio color="primary" />}
          label={t("manageEvent:this.floor")}
        />
        <FormControlLabel
          className={value === "event" ? styles.activeOption : ""}
          value="event"
          control={<Radio color="primary" />}
          label={t("manageEvent:entire.event")}
        />
      </RadioGroup>
    </Container>
  );

  const handleClick = () => {
    dispatch(
      addDialogNotification({
        confirmBtnId: "toggle-confirm",
        dismissBtnId: "toggle-dismiss",
        content: getDialogContent("floor"),
        onConfirm: () => handleConfirm("floor"),
        confirmText: t("manageEvent:unassign.attendees"),
        dismissText: t("common:button.cancel"),
        hideCloseButton: true,
        type: DialogType.UNASSIGN_ALL_ATTENDEES_TABLE_MANAGER,
      }),
    );
  };

  return (
    <Tooltip
      title={`${t("manageEvent:unassign.all.attendees")}`}
      placement="bottom"
      PopperProps={{
        className: styles.tooltip,
      }}
    >
      <Button
        variant="secondary"
        color="dark"
        size="md"
        isIconButton
        onClick={handleClick}
        data-testid="unassign-all-button"
        disabled={disabled}
      >
        <LayersClear />
      </Button>
    </Tooltip>
  );
};

export default UnassignAllGuestsButton;
