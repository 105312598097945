import { Theme } from "@remo-co/ui-core/src/types";
import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";

export const useStyles = makeStyles<Theme, { forceBgColor?: boolean }>(() => ({
  logo: {
    borderRadius: "50%",
    objectFit: "cover",
    backgroundColor: ({ forceBgColor }) => (forceBgColor ? "white" : "inherit"),
    width: "40px",
    height: "40px",
  },
  fullLogo: {
    width: "60px",
    height: "60px",
    borderRadius: "5px",
    objectFit: "cover",
  },
}));
