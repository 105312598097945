import { Theme } from "@remo-co/ui-core/src/types";
import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";

export const useStyles = makeStyles<Theme>((theme) => ({
  recordButton: {
    padding: "0",
    height: "46px",
    whiteSpace: "nowrap",
    color: theme.palette.white,
    backgroundColor: "var(--EbonyClay2)",
    "&:hover": {
      backgroundColor: "#efefef26",
    },
  },
  recordButtonContent: {
    fontSize: "14px",
    fontWeight: 400,
    paddingLeft: "0",
  },
  recordIcon: {
    fontSize: "26px",
    height: "26px",
    width: "26px",
    marginRight: "10px",
    color: "#fc6a6a",
  },
  loadingIcon: {
    height: "20px !important",
    width: "20px !important",
    marginRight: "10px",
    color: "var(--White-op-090)",
  },
}));
