import { useI18n } from "i18n";
import { useMemo } from "react";
import { ActionButton } from "modules/actionButton";
import { BlurOn } from "@remo-co/ui-core/src/icons/BlurOn";
import { useDevices } from "modules/audioVideo/hooks/useDevices";
import { VirtualBackgrounds } from "modules/audioVideo/constants";
import { useVirtualBackground } from "modules/audioVideo/hooks/useVirtualBackground";

export const BlurButton = () => {
  const { t } = useI18n("micCamCheck");
  const { activeDevices } = useDevices();
  const {
    isLoading: isLoadingVirtualBackground,
    setVirtualBackground,
    currentBackground,
  } = useVirtualBackground();

  const disabled = useMemo(
    () => activeDevices.videoInput === null || isLoadingVirtualBackground,
    [activeDevices.videoInput, isLoadingVirtualBackground],
  );

  const isBlurred = currentBackground.type === VirtualBackgrounds.BLUR;

  const handleOnBlur = () => {
    if (isBlurred) {
      setVirtualBackground({ type: VirtualBackgrounds.NONE });
    } else {
      setVirtualBackground({ type: VirtualBackgrounds.BLUR });
    }
  };

  return (
    <ActionButton
      data-testid="blur-button"
      onClick={handleOnBlur}
      label={isBlurred ? t("blur.on") : t("blur.off")}
      state={isBlurred ? "active" : "default"}
      icon={<BlurOn />}
      disabled={disabled}
    />
  );
};
