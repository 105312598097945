import {
  AnswerPollMutation,
  EventPoll,
  EventPollOption,
} from "graphql/generated";
import { ReactElement } from "react";
import { Quiz, Poll } from "../..";

export const PollsAndQuizzes = ({
  polls,
  quizzes,
  isHost,
  onSubmit,
}: {
  polls: EventPoll[];
  quizzes: EventPoll[];
  isHost: boolean;
  onSubmit: (
    option: EventPollOption,
    pollId: number,
  ) => Promise<AnswerPollMutation | null | undefined>;
}): ReactElement => (
  <>
    {quizzes.map((quiz) => (
      <Quiz
        key={quiz.id}
        status={quiz.status}
        quiz={quiz}
        onSubmit={(option) => onSubmit(option, quiz.id)}
        isHost={isHost}
      />
    ))}
    {polls.map((poll) => (
      <Poll
        key={poll.id}
        poll={poll}
        status={poll.status}
        isHost={isHost}
        onSubmit={(option) => onSubmit(option, poll.id)}
      />
    ))}
  </>
);
