import React from "react";
import * as DOMPurify from "dompurify";
import anchorme from "anchorme";
import { Typography } from "@remo-co/ui-core/src/components/Typography";
import { useSelector } from "react-redux";
import { selectIsInBroadcast } from "modules/broadcast/redux/selectors";
import { useStyles } from "./types/styles";

export const MessageContent = ({ text }: { text: string }) => {
  const content = React.useRef(null);
  const isInBroadcast = useSelector(selectIsInBroadcast);
  const classes = useStyles({ isInBroadcast: Boolean(isInBroadcast) });

  /**
   * Convert link texts to Anchor tag, DOMPurify will allow only target and href attributes
   * https://stackoverflow.com/a/19696443/1139322
   * https://stackoverflow.com/questions/50604549
   * Following texts should be handled
   * - simple text
   * - google.com
   * - www.google.com
   * - http://www.google.com
   * - https://www.google.com
   * www.google.com text content
   * with email and links
   * https://docs.google.com/spreadsheets/u/1/d/1c69T-9dEb4yqFsq7GTaawHWMA4pREADmemhfmmdZ1hY/edit#gid=0
   * test message with email, url sarav@gmail.com sarav@remo.co test@remo.co.in google.com www.google.com
   * http://www.yahoo.com https://www.medium.com test message
   */

  const convertTextToA = (textContent: string) => {
    if (textContent) {
      return anchorme({
        input: textContent,
        options: {
          attributes: {
            target: "_blank",
            rel: "noopener noreferrer",
            class: classes.link,
          },
        },
      });
    }

    return textContent;
  };

  return (
    <div ref={content}>
      <Typography
        variant="body1"
        className={classes.textMessage}
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(convertTextToA(text), {
            ALLOWED_ATTR: ["target", "href", "class", "rel"],
            ALLOWED_TAGS: ["a"],
          }),
        }}
      />
    </div>
  );
};

export default MessageContent;
