import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export type RaisedHandUser = {
  raised: boolean;
  time: number;
};

export type RaisedHandUserRecord = Record<string, RaisedHandUser>;

export interface IBroadcastRaiseHandState {
  raisedHandUsers: RaisedHandUserRecord;
}

export const initialState: IBroadcastRaiseHandState = {
  raisedHandUsers: {},
};

const broadcastRaiseHandSlice = createSlice({
  name: "broadcastRaiseHand",
  initialState,
  reducers: {
    updateRaisedHandUsers: (
      state: IBroadcastRaiseHandState,
      action: PayloadAction<RaisedHandUserRecord>,
    ) => {
      state.raisedHandUsers = action.payload;
    },
  },
});

export const { updateRaisedHandUsers } = broadcastRaiseHandSlice.actions;

export default broadcastRaiseHandSlice.reducer;
