import { isPassthrough, isReset } from "../../utils/createResponsiveRules";
import { IContainerStylingProperties, SpacingEnum } from "./styles";

export const sizeMap = (size: IContainerStylingProperties["size"] | null) => {
  if (isPassthrough(size)) {
    return {};
  }
  if (isReset(size)) {
    return {
      padding: 0,
    };
  }

  return {
    xl: {
      padding: SpacingEnum.xl,
    },
    lg: {
      padding: SpacingEnum.lg,
    },
    md: {
      padding: SpacingEnum.md,
    },
    sm: {
      padding: SpacingEnum.sm,
    },
    xs: {
      padding: SpacingEnum.xs,
    },
  }[size];
};
