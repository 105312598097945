export const ENFORCE_TABLE_ASSIGNMENT_COLLECTION = "EnforceTableAssignment";
export const ENFORCE_COUNTDOWN_DURATION = 30;
export const ENFORCE_TIMEOUT = 30;

export enum EnforceAssignmentErrors {
  NO_EVENT_ID = "NO_EVENT_ID",
  NO_FLOOR_FOUND_FOR_ATTENDEE = "NO_FLOOR_FOUND_FOR_ATTENDEE",
  NO_TABLE_FOUND_FOR_ATTENDEE = "NO_TABLE_FOUND_FOR_ATTENDEE",
}

export enum EnforceAssignmentStates {
  ERROR = "ERROR",
  IN_PROGRESS = "IN_PROGRESS",
  QUEUE_FLOOR_TRANSFERS = "QUEUE_FLOOR_TRANSFERS",
  SCHEDULED = "SCHEDULED",
  SUCCESS = "SUCCESS",
}
