import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";

export const useStyles = makeStyles((theme) => ({
  dialog: {
    minHeight: "calc(100% - 64px)", // prevent reflow, max is also set to this
  },
  content: {
    display: "flex",
    padding: 0,
    background: theme.palette.gray.light,
    border: `1px solid ${theme.palette.gray.light}`,
    overflow: "hidden",
  },
  table: {
    width: 240,
    height: 240,
    background: "white",
    border: `1px solid ${theme.palette.gray.light}`,
  },
  leftPanel: {
    display: "flex",
    flexDirection: "column",
    width: "30%",
    maxWidth: "300px",
    background: "white",
  },
  minimap: {
    display: "flex",
    flexDirection: "column",
    height: "30%",
  },
  minimapTitle: {
    padding: "15px 15px 5px",
    border: `1px solid ${theme.palette.gray.light}`,
  },
}));

export default useStyles;
