import { useMemo } from "react";
import { useSelector } from "react-redux";
import { useI18n } from "i18n";
import useNotificationActions from "modules/notification/hooks/useNotificationActions";
import { selectCurrentEventId } from "modules/event/selectors";
import { selectCurrentSpaceId } from "modules/space/redux/selectors";
import { ContextMenuItem } from "modules/contextMenu";
import { useAppDispatch } from "store/hooks";
import { addDialogNotification } from "modules/dialogNotification/redux/dialogNotificationSlice";
import { ReactComponent as UnassignIcon } from "../../assets/person-minus-icon.inline.svg";
import {
  useEventTableSettings,
  useUpdateEventTableSettings,
} from "../../hooks";
import { tableHasSeatAssignments } from "../../utils";
import { UnassignTableContextMenuDialogContent } from "../UnassignTableContextMenuDialogContent";

interface Props {
  tableId: string;
  name: string;
}

const UnassignTableContextMenuItem = ({
  tableId,
  name,
}: Props): JSX.Element | null => {
  const currentEventId = useSelector(selectCurrentEventId);
  const floorId = useSelector(selectCurrentSpaceId);
  const { t } = useI18n(["common", "event", "manageEvent"]);
  const { tableData: tableAssignmentData } =
    useEventTableSettings(currentEventId);
  const dispatch = useAppDispatch();
  const { addSuccessNotification } = useNotificationActions();
  const { unassignAttendeesFromTable } =
    useUpdateEventTableSettings(currentEventId);

  const hasSeatAssignments = useMemo(
    () => tableHasSeatAssignments(tableAssignmentData, floorId, tableId),
    [tableAssignmentData, floorId, tableId],
  );

  if (!hasSeatAssignments) {
    return null;
  }

  const handleClick = () => {
    if (floorId && tableId) {
      dispatch(
        addDialogNotification({
          content: <UnassignTableContextMenuDialogContent name={name} />,
          onConfirm: () => {
            unassignAttendeesFromTable(floorId, tableId);

            addSuccessNotification({
              message: t("manageEvent:unassign.table.success"),
              position: "tc",
            });
          },
          confirmText: t("common:remove"),
          dismissText: t("common:button.cancel"),
          hideCloseButton: true,
        }),
      );
    }
  };

  // TODO icon below also exists on MUI icons but our dep is not updated,
  // replace use here once updated GH #7739
  return (
    <ContextMenuItem
      id="rcm-unassign-table-seats"
      onClick={handleClick}
      text={t("event:unassign.all.table.seats")}
      icon={<UnassignIcon width={24} height={24} viewBox="0 -4 24 24" />}
    />
  );
};

export default UnassignTableContextMenuItem;
