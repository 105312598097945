import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";

export const useStyles = makeStyles((theme) => ({
  filterTagsBox: {
    paddingBottom: 20,
  },
  activeTab: {
    border: `1px solid ${theme.palette.blue.main}`,
    background: theme.palette.blue.lightShade1,
  },
  inactiveTab: {
    border: `1px solid ${theme.palette.border.default}`,
  },
}));

export default useStyles;
