import { createSelector } from "@reduxjs/toolkit";
import { selectTheaterSpaces } from "modules/theater/selectors";
import { RootState } from "store";

const selectSpace = (state: RootState) => state.space;

const selectIsChangeSpaceInProgress = createSelector(
  selectSpace,
  (substate) => substate.spaceChangeInProgress,
);

const selectCurrentSpaceId = createSelector(
  (state: RootState) => state.space,
  (substate) => substate.spaceId,
);

const selectIsCurrentFloorInTheaterSpaces = createSelector(
  selectTheaterSpaces,
  selectCurrentSpaceId,
  (theaterSpaces, currentSpaceId) =>
    currentSpaceId &&
    theaterSpaces.some((spaceId) => spaceId === currentSpaceId),
);

export {
  selectSpace,
  selectIsChangeSpaceInProgress,
  selectCurrentSpaceId,
  selectIsCurrentFloorInTheaterSpaces,
};
