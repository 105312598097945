import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { IRoom } from "types";

export enum JoinRoomStatus {
  DONE = "DONE",
  IN_PROGRESS = "IN_PROGRESS",
  PREPARING = "PREPARING",
}

interface RoomsInitialState {
  rooms: Record<string, IRoom>;
  status: JoinRoomStatus;
  joiningRoomId: string | null;
}

export const initialState: RoomsInitialState = {
  rooms: {},
  status: JoinRoomStatus.DONE,
  joiningRoomId: null,
};

const roomsSlice = createSlice({
  name: "rooms",
  initialState,
  reducers: {
    syncRooms: (state, action: PayloadAction<Record<string, IRoom>>) => {
      state.rooms = action.payload;
    },
    setJoiningRoomId: (state, action: PayloadAction<string | null>) => {
      state.joiningRoomId = action.payload;
    },
    updateRoomStatus: (state, action: PayloadAction<JoinRoomStatus>) => {
      state.status = action.payload;
    },
    resetRooms: () => initialState,
  },
});

export const { syncRooms, resetRooms, setJoiningRoomId, updateRoomStatus } =
  roomsSlice.actions;

export default roomsSlice.reducer;
