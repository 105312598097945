import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";

export const useStyles = makeStyles((theme) => ({
  header: {
    marginTop: theme.spacing(2),
  },
  headerText: {
    fontSize: "16px !important",
  },
  addContentButtonIcon: {
    fontSize: "14px",
  },
  addContentButton: {
    height: "unset",
  },
  addContentButtonContent: {
    display: "flex",
    alignItems: "center",
    padding: `${theme.spacing(1.5)} ${theme.spacing(5)}`,
  },
  tooltipIcon: {
    marginLeft: theme.spacing(2),
  },
  contents: {
    position: "relative",
    borderRadius: "15px",
    border: `1px solid ${theme.palette.gray.light}`,
    marginTop: theme.spacing(1.5),
  },
  contentList: {
    padding: theme.spacing(2),
    gap: theme.spacing(1),
    paddingBottom: 0,
    marginBottom: -1,
  },
  loading: {
    position: "absolute",
    height: "100%",
    width: "100%",
  },
}));
