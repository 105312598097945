import { Container } from "@remo-co/ui-core/src/components/Container";
import { Typography } from "@remo-co/ui-core/src/components/Typography";
import { ReactComponent as FloorPlanIcon } from "modules/eventForm/assets/floor-plan.inline.svg";
import { useI18n } from "i18n";
import { useStyles } from "./styles";

const LayoutChangeDialogContent = (): JSX.Element => {
  const { t } = useI18n(["manageEvent"]);
  const classes = useStyles();

  return (
    <Container className={classes.container}>
      <FloorPlanIcon className={classes.icon} />
      <Typography variant="h4" className={classes.title}>
        {t("manageEvent:warning.change.floorplan")}
      </Typography>

      <div className={classes.content}>
        <span>{t("manageEvent:warning.change.effects")}</span>
        <br />
        <span>{t("manageEvent:warning.change.included")}</span>
        <br />
        <br />
        <span>{t("manageEvent:warning.change.ongoing")}</span>
      </div>
    </Container>
  );
};

export default LayoutChangeDialogContent;
