import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";
import AdminSVG from "../svgMapView/assets/admin-seat-icon.svg";

export const useStyles = makeStyles(() => ({
  conferenceAreaLabel: {
    position: "absolute",
    top: "-50px",
    color: "#000",
    backgroundColor: "#fff",
    zIndex: 2,
    fontSize: "30px",
  },
  svgContainer: {
    position: "relative",
    height: 344,
    marginRight: 24,
  },
  fullScreenContainer: {
    left: "10vw",
    top: "65px",
    position: "absolute",
    transformOrigin: "left top",
  },
  fullscreenBtn: {
    position: "absolute",
    bottom: "0.75rem",
    right: "0.75rem",
    zIndex: 2,
    backgroundColor: "white !important",
  },
  fullscreenBtnText: {
    display: "flex",
    alignItems: "center",
    lineHeight: 1,
    marginRight: "1rem",
  },
  svgConfigWrap: {
    width: "100%",
    height: "100%",
    zIndex: 1,
    position: "absolute",
    top: 0,
    left: 0,
  },
  svgConfArea: {
    position: "absolute",
    backgroundColor: "rgba(255, 0, 0, 0.15)",
    border: "2px solid red",

    "& .sponsor": {
      position: "absolute",
      background: "rgba(98, 98, 98, 0.4)",
      textAlign: "center",
      fontSize: "30px",
    },
    "& .room": {
      display: "inline-block",
      position: "absolute",
      background: "rgba(0, 0, 0, 0.6)",
      color: "#000",
      textAlign: "center",

      "&.isometric": {
        background: "transparent",
      },

      "&.blocked": {
        backgroundColor: "#e9be25",
        animation: "flickerAnimation 3s infinite",
      },

      "& .seat": {
        position: "absolute",
        borderRadius: "50%",
        background: "rgba(0, 0, 0, 0.4)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: "#fff",

        "&.admin": {
          "& b": {
            background: `url(${AdminSVG}) no-repeat center`,
            height: "100%",
            width: "100%",
            display: "inline-block",
            backgroundSize: "contain",
            opacity: "0.8",
          },
        },
      },
      "& .room-name": {
        color: "rgb(136, 104, 104)",
        fontSize: "16px",
        textAlign: "center",
        width: "100%",
        zIndex: "2",
        position: "absolute",
      },
      "& .text-path-name-container": {
        top: "0",
        width: "100%",
        height: "100%",
      },
      "& .room-position": {
        position: "absolute",
        display: "flex",
        alignItems: "center",
        top: "0",
        left: "0",
        minWidth: "1.5rem",
        minHeight: "1.5rem",
        background: "blue",
        color: "#fff",
        padding: "0.5rem",
        height: "0.5rem",
        fontSize: "1.5rem",

        "&.blocked": {
          backgroundColor: "red",
        },

        "& > .icon": {
          marginLeft: "4px",
        },

        "&.special": {
          backgroundColor: "yellow",
          color: "#666",
          padding: "0 8px",
        },

        "&.isometric": {
          top: "calc(50% - 13px)",
          left: "calc(50% - 28px)",
        },
      },
    },
  },
  closeBtn: {
    position: "absolute",
    zIndex: 2,
    transform: "scale(2.5)",
    transformOrigin: "top right",
    right: "2rem",
    top: "2rem",
    width: "2.25rem",
    height: "2.25rem",
    background: "#eee",
    boxSizing: "border-box",
    color: "#7b7b7b",
    borderRadius: 5,
    "&:hover": {
      backgroundColor: "rgba(14, 31, 60, 0.04)",
    },
  },
}));
