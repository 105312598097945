import { RootState } from "store";
import { selectUserId } from "modules/auth/redux/selectors";
import { IUserRoomState } from "modules/room/redux/roomSlice";
import { createSelector } from "@reduxjs/toolkit";
import { makeSelectUsersInEvent } from "modules/event/usersInEvent/selectors";
import getRoomId from "../utils";
import { IUserRoomStatus } from "../userRoomStatus";

const selectUserRoomStatus = (state: RootState): IUserRoomState =>
  state.userRoomStatus;

const makeSelectUserRoomStatusByRoomId =
  (roomId?: string | null) =>
  ({ userRoomStatus }: RootState): Record<string, IUserRoomStatus> | null => {
    if (!roomId) {
      return null;
    }

    const table = userRoomStatus[roomId];

    if (!table) {
      return null;
    }

    return table;
  };

const makeSelectOverflow = (roomId?: string | null) =>
  createSelector(
    selectUserRoomStatus,
    makeSelectUsersInEvent,
    (userRoomStatus, usersInEvent) => {
      if (!roomId) {
        return [];
      }

      const table = userRoomStatus[roomId];

      if (!table) {
        return [];
      }

      return Object.entries(table)
        .filter(([_, seat]) => seat?.isOverflow)
        .map(([userId, position]) => ({
          userId,
          position,
          user: usersInEvent.get(userId),
        }));
    },
  );

// Returns room Id of current user based on UserRoomStatus
const makeSelectUserRoomStatusByUserId =
  (userId?: string) =>
  ({ userRoomStatus }: RootState): string | null => {
    if (!userId || !userRoomStatus) {
      return null;
    }

    return (
      Object.keys(userRoomStatus).find((roomId: string) =>
        Boolean(userRoomStatus[roomId][userId]),
      ) ?? null
    );
  };

const selectCurrentRoomId = createSelector(
  selectUserRoomStatus,
  selectUserId,
  (userRoomStatus, userId) => {
    if (userId) {
      return getRoomId(userId, userRoomStatus);
    }
    return null;
  },
);

const selectUsersCountInRoom =
  (roomId: string) =>
  ({ userRoomStatus }: RootState): number =>
    Object.keys(userRoomStatus?.[roomId] ?? {}).length;

const selectOccupiedAdminSeats = createSelector(
  selectUserRoomStatus,
  (userRoomStatus) =>
    Object.entries(userRoomStatus).reduce((acc: string[], [roomId, users]) => {
      const hasAdminSeatOccupied = Object.values(users).some(
        (u) => u.isAdminSeat,
      );

      if (hasAdminSeatOccupied) {
        acc.push(roomId);
      }

      return acc;
    }, []),
);

export {
  selectUserRoomStatus,
  makeSelectUserRoomStatusByRoomId,
  makeSelectUserRoomStatusByUserId,
  selectCurrentRoomId,
  getRoomId,
  selectUsersCountInRoom,
  makeSelectOverflow,
  selectOccupiedAdminSeats,
};
