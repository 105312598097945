import { RadioGroup } from "@remo-co/ui-core/src/components/RadioGroup";
import { Box } from "@remo-co/ui-core/src/components/Box";
import { useI18n } from "i18n";
import { Container } from "@remo-co/ui-core/src/components/Container";
import { FormControlLabel } from "@remo-co/ui-core/src/components/FormControlLabel";
import { Input } from "@remo-co/ui-core/src/components/Input";
import { Radio } from "@remo-co/ui-core/src/components/Radio";
import { Delete } from "@remo-co/ui-core/src/icons/Delete";
import { ContactChannelTypes, IContactChannel } from "modules/eventForm/types";
import { IconButtonWithTooltip } from "@remo-co/ui-core/src/components/IconButtonWithTooltip";
import { useStyles } from "./styles";

interface Props {
  index: number;
  channel: IContactChannel;
  handleChange: <K extends keyof IContactChannel>(
    index: number,
    field: K,
    value: K extends "type" ? ContactChannelTypes : string,
  ) => void;
  handleDelete: (index: number) => void;
}

export const ContentChannelItem = ({
  index,
  channel,
  handleDelete,
  handleChange,
}: Props) => {
  const { t } = useI18n(["common", "eventForm"]);
  const styles = useStyles();

  return (
    <Container className={styles.container}>
      <RadioGroup
        name="channelType"
        value={channel.type}
        onChange={(e) => {
          handleChange(index, "type", e.target.value as ContactChannelTypes);
        }}
        row
      >
        {Object.values(ContactChannelTypes).map((option) => (
          <FormControlLabel
            value={option}
            control={<Radio size="small" color="primary" />}
            label={t(`common:${option}`)}
            data-testid={t(`item-${index + 1}-${option}`)}
            key={option}
          />
        ))}
      </RadioGroup>
      <Box display="flex" flexDirection="row" alignItems="center">
        <Input
          value={channel.value}
          size="sm"
          placeholder={t("eventForm:contact.channel.placeholder")}
          fullWidth
          onChange={(e) => {
            handleChange(index, "value", e.target.value);
          }}
          inputProps={{ "data-testid": "channel-value-input" }}
        />
        <IconButtonWithTooltip
          title={t("eventForm:delete.channel")}
          id="delete-channel-button"
          size="small"
          onClick={() => handleDelete(index)}
          data-testid="delete-channel-button"
        >
          <Delete fontSize="small" />
        </IconButtonWithTooltip>
      </Box>
    </Container>
  );
};
