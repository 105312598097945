import { useEffect } from "react";
import { Divider } from "@remo-co/ui-core/src/components/Divider";
import { useEnforceTableAssignmentFirestore } from "modules/enforceTableAssignment";
import { useSelector } from "react-redux";
import { selectCurrentEventId } from "modules/event/selectors";
import { InviteAllToAssignedTableButton } from "../InviteAllToAssignedTableButton";
import { MoveAllToAssignedTableButton } from "../MoveAllToAssignedTableButton";

interface Props {
  eventId: string;
  selectedPreset: string;
  disabled: boolean;
  isInEvent?: boolean;
}

const AttendeeManagementActions = ({
  eventId,
  selectedPreset,
  disabled,
  isInEvent,
}: Props): JSX.Element | null => {
  const currentEventId = useSelector(selectCurrentEventId);
  const { subscribeToEvent, unsubscribeFromEvent } =
    useEnforceTableAssignmentFirestore(eventId);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (eventId) {
      subscribeToEvent();

      return unsubscribeFromEvent;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventId, currentEventId]);

  const handleClick = async (onAssignmentCompleteClick: () => void) => {
    onAssignmentCompleteClick();
  };

  return (
    <>
      {!isInEvent && (
        <Divider orientation="vertical" variant="middle" flexItem />
      )}
      <InviteAllToAssignedTableButton
        eventId={eventId}
        selectedPreset={selectedPreset}
        onClick={handleClick}
        disabled={disabled}
      />
      <MoveAllToAssignedTableButton
        eventId={eventId}
        selectedPreset={selectedPreset}
        onClick={handleClick}
        disabled={disabled}
      />
    </>
  );
};

export default AttendeeManagementActions;
