import { Theme } from "@remo-co/ui-core/src/types";
import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";

const useStyles = makeStyles<Theme, { isBroadcasting: boolean }>((theme) => ({
  giphyContainer: {
    width: "310px",
    height: "278px",
    backgroundColor: ({ isBroadcasting }) =>
      isBroadcasting
        ? theme.palette.background.default
        : theme.palette.grey[200],
    padding: "4px",
    overflowY: "scroll",
    borderRadius: "10px",
  },
  gifSearch: {
    marginBottom: "5px",
  },
}));

export default useStyles;
