import { useI18n } from "i18n";
import { BaseCircleLogo } from "modules/app/branding";
import { selectAppLogo } from "modules/appSetting";
import { useSelector } from "react-redux";
import useWhitelabelMode, {
  WhitelabelMode,
} from "../../hooks/useWhitelabelMode";
import { useStyles } from "./styles";

interface Props {
  forceBgColor?: boolean;
  full?: boolean;
  width?: number;
}

const AppCircleLogo = ({ forceBgColor, full, width }: Props) => {
  const { t } = useI18n(["branding"]);
  const appLogo = useSelector(selectAppLogo);
  const classes = useStyles({ forceBgColor });
  const [whitelabelMode, logoURL] = useWhitelabelMode();

  if (
    whitelabelMode === WhitelabelMode.CUSTOM_EVENT_BRANDING_LOGO ||
    whitelabelMode === WhitelabelMode.WHITE_LABEL_BRANDING_LOGO
  ) {
    return (
      <BaseCircleLogo
        logoURL={logoURL}
        logoAlt={t("branding:custom.event.logo")}
        logoClass={full ? classes.fullLogo : classes.logo}
      />
    );
  }

  if (appLogo) {
    return (
      <BaseCircleLogo
        logoURL={appLogo}
        logoAlt={t("branding:app.logo")}
        logoClass={full ? classes.fullLogo : classes.logo}
      />
    );
  }

  return <BaseCircleLogo full={full} width={width} />;
};

export default AppCircleLogo;
