import { useState, ReactElement, useContext } from "react";
import { useMutation } from "@apollo/client";
import { Button } from "@remo-co/ui-core/src/components/Button";
import { UPDATE_POLL } from "modules/polls/graphql";
import { useI18n } from "i18n";
import {
  EventPollOptionInput,
  EventPollType,
  MutationUpdateEventPollArgs,
  UpdateEventPollMutation,
  UpdateEventPollMutationVariables,
} from "graphql/generated";
import { Actions, trackAction } from "modules/monitoring";
import { PollForm } from "modules/polls/components/PollForm";
import { Events, TRACKING_CONTEXT } from "modules/tracking";
import { selectCurrentEvent } from "modules/event/selectors";
import { useSelector } from "react-redux";

export const EditPollForm = ({
  closeForm,
  pollType,
  initialForm,
}: {
  closeForm: () => void;
  pollType: EventPollType;
  initialForm: MutationUpdateEventPollArgs;
}): ReactElement => {
  const [updatePoll, { loading: mutationLoading }] = useMutation<
    UpdateEventPollMutation,
    UpdateEventPollMutationVariables
  >(UPDATE_POLL);

  const { t } = useI18n(["event"]);
  const [pollState, setPollState] = useState<
    Omit<MutationUpdateEventPollArgs, "options" | "timer"> & { timer?: number }
  >(initialForm);
  const [options, setOptions] = useState<
    Array<EventPollOptionInput & { id?: number; index: number }>
  >(
    initialForm?.options?.map((option, index) => ({
      ...option,
      index,
      id: option.id ?? index,
    })) ?? [],
  );
  const { track } = useContext(TRACKING_CONTEXT);
  const currentEvent = useSelector(selectCurrentEvent);

  const handleSubmit = async () => {
    trackAction(Actions.POLLS_EDIT);
    setPollState(pollState);
    track(Events.POLL_EDITED, {
      eventId: currentEvent?.id,
      eventType: currentEvent?.eventType,
      pollType,
    });
    await updatePoll({
      variables: {
        ...pollState,
        id: initialForm.id,
        timer: pollState.timer ?? 30,
        options: options.map((option) => ({
          id: option.id,
          option: option.option,
          correctAnswer: option.correctAnswer ?? false,
        })),
      },
    });

    closeForm();
  };

  return (
    <PollForm<MutationUpdateEventPollArgs, { id?: number }>
      options={options}
      setOptions={(newOptions) => setOptions(newOptions)}
      pollState={pollState}
      pollType={pollType}
      onPollStateChange={(newPollState) => setPollState(newPollState)}
      Buttons={
        <>
          <Button
            disabled={mutationLoading}
            loading={mutationLoading}
            onClick={() => {
              handleSubmit();
            }}
            variant="primary"
            color="blue"
          >
            {t("common:button.save")}
          </Button>
          <Button
            disabled={mutationLoading}
            onClick={closeForm}
            variant="secondary"
            color="red"
          >
            {t("common:button.cancel")}
          </Button>
        </>
      }
    />
  );
};
