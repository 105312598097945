import { isPassthrough, isReset } from "../../utils/createResponsiveRules";
import { IContainerStylingProperties } from "./styles";

export const roundedMap = (
  roundness: IContainerStylingProperties["rounded"] | null,
) => {
  if (isPassthrough(roundness)) {
    return {};
  }
  if (isReset(roundness)) {
    return {
      borderRadius: 0,
    };
  }

  return {
    xl: {
      borderRadius: "20px",
    },

    lg: {
      borderRadius: "20px",
    },

    md: {
      borderRadius: "20px",
    },

    sm: {
      borderRadius: "15px",
    },

    xs: {
      borderRadius: "5px",
    },
  }[roundness];
};
