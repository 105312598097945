import { ReactNode, useContext, useState } from "react";
import { Box } from "@remo-co/ui-core/src/components/Box";
import { Typography } from "@remo-co/ui-core/src/components/Typography";
import { PageLoader } from "@remo-co/ui-core/src/components/PageLoader";
import { useI18n } from "i18n";
import EventAgendaItem from "./EventAgendaItem";
import NewAgendaItem from "./NewAgendaItem";
import { EVENT_AGENDA_CONTEXT } from "./context/EventAgendaContext";
import EmptyAgenda from "./assets/empty-agenda.svg";
import { IEventAgenda } from "./types";

const EventAgendaItems = () => {
  const { state, isLoading } = useContext(EVENT_AGENDA_CONTEXT);
  const agendaItems = state && state.agendaItems ? state.agendaItems : null;

  const [addNewItemAgenda, setAddNewItemAgenda] = useState<IEventAgenda | null>(
    null,
  );

  const onSave = () => {
    setAddNewItemAgenda(null);
  };

  const editAgenda = (key: string) => {
    if (agendaItems) {
      const newItem = agendaItems.get(key);

      if (newItem) {
        setAddNewItemAgenda(newItem);
      }
    }
  };

  const addNewAfter = (key: string) => {
    if (agendaItems) {
      const currentItem = agendaItems.get(key);

      if (currentItem) {
        setAddNewItemAgenda({
          rank: currentItem.rank + 1,
        } as unknown as IEventAgenda);
      }
    }
  };

  const renderEventAgendaItems = () => {
    let timeOffset = 0;
    const eventAgendaItems: ReactNode[] = [];

    if (!agendaItems) {
      return eventAgendaItems;
    }

    agendaItems.forEach((agendaItem: IEventAgenda) => {
      eventAgendaItems.push(
        <EventAgendaItem
          key={`${agendaItem.title}-${timeOffset}`}
          offset={timeOffset}
          item={agendaItem}
          editAgenda={editAgenda}
          addNewAfter={addNewAfter}
        />,
      );
      timeOffset += agendaItem.duration || 0;
    });

    return eventAgendaItems;
  };
  const { t } = useI18n(["common", "eventAgenda"]);

  if (agendaItems && agendaItems.size) {
    return (
      <>
        <Box className="ea-list">{renderEventAgendaItems()}</Box>
        {addNewItemAgenda && (
          <NewAgendaItem agenda={addNewItemAgenda} onAfterSave={onSave} />
        )}
      </>
    );
  }

  if (isLoading) {
    return (
      <Box className="ea-loading">
        <PageLoader
          fullScreen
          message={t("eventAgenda:loading.event.agenda") as string}
        />
      </Box>
    );
  }

  return (
    <Box className="ea-empty">
      <img src={EmptyAgenda} alt={t("eventAgenda:event.agenda.empty")} />
      <Typography color="inherit" component="div">
        {t("eventAgenda:event.agenda.empty")}
      </Typography>
      <Typography component="div" color="inherit">
        {t("eventAgenda:start.add.item")}
      </Typography>
    </Box>
  );
};

export default EventAgendaItems;
