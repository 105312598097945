import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "store";
import { ILobbyGuest } from "../types";
import { LobbyState } from "./slice";

const selectLobby = (state: RootState) => state.lobby;

const selectIsLobbyBannerOpen = createSelector(
  selectLobby,
  (substate: LobbyState) => substate.isLobbyBannerOpen,
);

const selectWaitingGuests = createSelector(
  selectLobby,
  (substate: LobbyState): ILobbyGuest[] =>
    Object.entries(substate.guests)
      .filter(([, guest]) => guest.state === "PENDING")
      .map(([, guest]) => guest),
);

const selectSortedWaitingGuests = createSelector(
  selectWaitingGuests,
  (waitingGuests: ILobbyGuest[]): ILobbyGuest[] =>
    waitingGuests.sort((a, b) => b.time - a.time),
);

export {
  selectIsLobbyBannerOpen,
  selectWaitingGuests,
  selectSortedWaitingGuests,
};
