import { useEffect } from "react";
import { datadogRum } from "@datadog/browser-rum";
import { inIframe } from "../../helpers/inIframe";

export const TrackIframe = () => {
  useEffect(() => {
    datadogRum.setGlobalContextProperty("isInIframe", inIframe());
  }, []);

  return null;
};
