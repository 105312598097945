import { ICompanyServicePlan } from "modules/company/types";
import { POST_TRIAL } from "../../constants";

export const isTrialCompleted = (plan: ICompanyServicePlan | null): boolean =>
  plan ? plan.trialExpired : false;

export const getRemainingTrialDays = (
  plan: ICompanyServicePlan | null,
): number | null => (plan ? plan.trialLeft : null);

export const isInPostTrialPlan = (plan: ICompanyServicePlan | null): boolean =>
  plan ? plan.planId === POST_TRIAL : false;

export const isMyCompanyTrialPeriodActive = (
  myCompanyPlan: ICompanyServicePlan | null,
): boolean => {
  const trialLeft = getRemainingTrialDays(myCompanyPlan);
  const trialExpired = isTrialCompleted(myCompanyPlan);

  return myCompanyPlan
    ? (trialLeft !== null && trialLeft > 0) || !trialExpired
    : false;
};
