import { useDaily } from "@daily-co/daily-react";
import { useCallback, useEffect, useMemo, useState } from "react";

import { groupItems } from "./group";
import { DisplayItem, TileDraft } from "../types";

export const usePagination = (tiles: TileDraft[], pageSize: number) => {
  const [page, setPage] = useState<number>(1);

  const pages = useMemo(() => groupItems(tiles, pageSize), [tiles, pageSize]);

  const daily = useDaily();

  const paginateForward = useCallback(() => {
    setPage((currentPage) => {
      if (currentPage > tiles.length) {
        return currentPage;
      }
      return currentPage + 1;
    });
  }, [tiles.length]);

  const paginateBack = useCallback(() => {
    setPage((currentPage) => {
      if (currentPage <= 0) {
        return currentPage;
      }
      return currentPage - 1;
    });
  }, []);

  const currentPage = useMemo(() => pages[page - 1] ?? [], [page, pages]);

  const canPaginateForward = useMemo(() => page < pages.length, [page, pages]);
  const canPaginateBackward = useMemo(() => page > 1, [page]);

  // Only subscribe to video of user's on current page
  useEffect(() => {
    const userStreams = currentPage.filter(
      (tile) => tile.type === DisplayItem.camera && !tile.local,
    );

    userStreams.forEach(({ streamId }) => {
      daily?.updateParticipant(streamId, { setSubscribedTracks: true });
    });

    return () => {
      // Allowing audio streams for all users
      pages
        .flat(1)
        .filter((tile) => tile.type === DisplayItem.camera && !tile.local)
        .forEach(({ streamId }) => {
          daily?.updateParticipant(streamId, {
            setSubscribedTracks: { audio: true },
          });
        });
    };
  }, [currentPage, daily, page, pages]);

  const audioStreams = useMemo(
    () =>
      pages
        .filter((_, index) => index !== page - 1)
        .flat(1)
        .filter(
          (tileDraft) =>
            tileDraft.type === DisplayItem.camera && !tileDraft.local,
        )
        .map(({ streamId }) => streamId),
    [page, pages],
  );

  const hasPagination = canPaginateForward || canPaginateBackward;

  return {
    currentPage,
    canPaginateBackward,
    canPaginateForward,
    paginateBack,
    paginateForward,
    pageIndex: page,
    totalPages: pages.length,
    audioStreams,
    hasPagination,
  };
};
