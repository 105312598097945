import React from "react";
import logger from "logging/logger";
import { isTypeOfIMapTemplate } from "modules/event/template";
import { IEvent } from "modules/event/types";
import { IMapTemplate } from "types/theater";
import { IManageEventActions } from "./types";

const useManageEventTemplates = (
  eventData: IEvent | undefined,
  templates:
    | {
        [key: string]: IMapTemplate[];
      }
    | undefined,
  actions: IManageEventActions,
) => {
  const theater = eventData?.theaters && eventData?.theaters[0];

  React.useEffect(() => {
    if (
      templates?.publicTemplates &&
      theater &&
      isTypeOfIMapTemplate(theater.template)
    ) {
      const theaterCode = theater.template.code;
      const filteredTemplates = templates.publicTemplates.filter((template) => {
        if (!template.isPrivate) {
          if (theaterCode === template.code) {
            if (template.isDisabled) {
              logger.info(
                `Legacy floor plan used: ${template.code} theater: ${theater.id}`,
              );
            }

            return true;
          }

          if (template.isDisabled) {
            return false;
          }
        }

        return true;
      });

      if (filteredTemplates.length < templates.publicTemplates.length) {
        actions.setTemplates({
          publicTemplates: filteredTemplates,
          privateTemplates: templates.privateTemplates,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [templates?.publicTemplates, theater]);
};

export default useManageEventTemplates;
