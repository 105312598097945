import { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import { Box } from "@remo-co/ui-core/src/components/Box";
import { CircularProgress } from "@remo-co/ui-core/src/components/CircularProgress";
import { Typography } from "@remo-co/ui-core/src/components/Typography";
import { getUnixTimestamp } from "helpers/time/format";
import { selectCountdownStartTime } from "../../redux/selectors";
import { ENFORCE_COUNTDOWN_DURATION } from "../../constants";

interface Props {
  size: number;
  onCountdownEnd: () => void;
}

const EnforceCountdownTimer = ({
  size,
  onCountdownEnd,
}: Props): JSX.Element => {
  const countdownStartTime = useSelector(selectCountdownStartTime);
  const [countdown, setCountdown] = useState<number | null>(null);
  const timer = useRef<NodeJS.Timeout | null>(null);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (countdownStartTime) {
      setCountdown(
        countdownStartTime + ENFORCE_COUNTDOWN_DURATION - getUnixTimestamp(),
      );
      timer.current = setInterval(() => {
        const seconds =
          countdownStartTime + ENFORCE_COUNTDOWN_DURATION - getUnixTimestamp();

        if (seconds < 0) {
          // Stop showing the popup
          if (timer.current) {
            clearInterval(timer.current);
          }

          onCountdownEnd();
        } else {
          setCountdown(seconds);
        }
      }, 1000);

      return () => {
        if (timer.current) {
          clearInterval(timer.current);
        }
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countdownStartTime]);

  return (
    <Box
      position="relative"
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="row"
      my={3}
      data-testid="countdown-timer"
    >
      <CircularProgress size={size} thickness={2} />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        {countdown && (
          <Typography
            variant="body1"
            component="div"
            data-testid="countdown-text"
          >
            {countdown < 0 ? "" : countdown}
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default EnforceCountdownTimer;
