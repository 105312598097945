export enum DisplayItem {
  camera = "camera",
  screenShare = "screen",
  whiteboard = "whiteboard",
  eventContent = "eventContent",
  presentContent = "presentContent",
  externalVideo = "externalVideo",
}

export const DisplayItemConfig = {
  whiteboardDisplayId: "whiteboard",
  externalVideoDisplayId: "external-video",
  presentContentDisplayId: "present-content",
  eventContentDisplayId: "event-content",
};

export enum DisplayLayout {
  Focus = "focus",
  Map = "map",
  Tile = "tile",
}

export type TileDraft = {
  type: DisplayItem;
  local?: boolean;
  streamId: string;
};

export type LayoutProps = {
  tiles: TileDraft[];
  onTileClick?: (streamId: string, displayDialog?: boolean) => void;
  switchLayout?: (layout: DisplayLayout, showDialog?: boolean) => void;
};

export type LayoutTileProps = {
  tile: TileDraft;
  className?: string;
  onTileClick?: (streamId: string) => void;
};
