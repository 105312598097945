import { selectIsEventManager } from "modules/event/selectors";
import {
  LiveStreamSubscription,
  LiveStreamListener,
  useLiveStreamingAsync,
} from "modules/liveStream/hooks";
import {
  selectIsLiveStreamLoading,
  selectIsLiveStreaming,
  selectLiveStreams,
} from "modules/liveStream/redux/selectors";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { selectIsInBroadcast } from "modules/broadcast/redux/selectors";
import { LiveStreamDestinationsSubscriber } from "modules/liveStream/hooks/LiveStreamDestinationsSubscriber";

export const LiveStreamSubscriber = () => {
  const isInBroadcast = useSelector(selectIsInBroadcast);
  const liveStreams = useSelector(selectLiveStreams);
  const isLoading = useSelector(selectIsLiveStreamLoading);
  const isLiveStreaming = useSelector(selectIsLiveStreaming);
  const isEventManager = useSelector(selectIsEventManager);

  const { startStreaming, isLiveStreaming: isDailyLiveStreaming } =
    useLiveStreamingAsync();

  useEffect(() => {
    // start live streaming on page refresh if there was only manager in the event
    if (
      !isDailyLiveStreaming &&
      isEventManager &&
      isLiveStreaming &&
      liveStreams.length &&
      !isLoading &&
      isInBroadcast
    ) {
      startStreaming({
        serverUrl: liveStreams[0].serverUrl,
        streamKey: liveStreams[0].streamKey,
      });
    }
  }, [
    isEventManager,
    liveStreams,
    startStreaming,
    isLiveStreaming,
    isLoading,
    isDailyLiveStreaming,
    isInBroadcast,
  ]);

  return (
    <>
      <LiveStreamSubscription />
      <LiveStreamDestinationsSubscriber />
      <LiveStreamListener />
    </>
  );
};
