import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getFirebaseUID } from "services/firebaseService/firebaseConfig";
import { selectUser } from "modules/auth/redux/selectors";
import { getFirstName, getLastName } from "helpers/nameHelper";
import { FRESH_CHAT_ELEMENT_ID } from "modules/helpChat";

const useHelpChatLoader = () => {
  const user = useSelector(selectUser);
  const [isInitialized, setIsInitialized] = useState(false);

  useEffect(() => {
    const { fcWidget } = window;

    if (fcWidget && user) {
      fcWidget.setExternalId(getFirebaseUID());
      fcWidget.user.setEmail(user.email);
      fcWidget.user.setFirstName(
        user.profile.firstName ?? getFirstName(`${user.profile.name}`),
      );
      fcWidget.user.setLastName(
        user.profile.lastName ?? getLastName(`${user.profile.name}`),
      );
    }
  }, [user]);

  const init = () => {
    const d = document;
    const fcJS = d.createElement("script");

    fcJS.setAttribute("data-happo-ignore", "true");

    fcJS.id = FRESH_CHAT_ELEMENT_ID;
    fcJS.async = true;
    fcJS.src = "https://wchat.freshchat.com/js/widget.js";
    d.head.appendChild(fcJS);
    setIsInitialized(true);
  };

  useEffect(() => {
    if (!isInitialized) {
      init();
    }
  }, [isInitialized]);
};

export default useHelpChatLoader;
