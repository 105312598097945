import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";

export const useStyles = makeStyles((theme) => ({
  root: {
    padding: `${theme.spacing(7)} ${theme.spacing(5)}`,
    textAlign: "center",
  },
  addContentButtonIcon: {
    fontSize: "14px",
  },
  addContentButton: {
    marginTop: theme.spacing(2),
    height: "unset",
  },
  addContentButtonContent: {
    display: "flex",
    alignItems: "center",
    padding: `${theme.spacing(1.5)} ${theme.spacing(5)}`,
  },
}));
