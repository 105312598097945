import { useContext } from "react";
import useNotificationActions from "modules/notification/hooks/useNotificationActions";
import fileDownload from "js-file-download";
import { getMaskedUrl, validateVideoURL } from "helpers/videoHelper";
import { MANAGE_EVENT_CONTEXT } from "modules/manageEvent";
import { Events, TRACKING_CONTEXT } from "modules/tracking";
import { useI18n } from "i18n";
import logger from "logging/logger";
import {
  addSponsorInEvent,
  deleteSponsorInEvent,
  exportMembersBySponsor,
  updateSponsorInEvent,
} from "services/apiService/apis";
import { ISponsor } from "../types";

const useSponsors = () => {
  const { addSuccessNotification, addErrorNotification } =
    useNotificationActions();
  const { actions, state } = useContext(MANAGE_EVENT_CONTEXT);
  const { track } = useContext(TRACKING_CONTEXT);
  const { t } = useI18n(["common", "event", "server"]);

  const addSponsor = async (sponsor: ISponsor, eventId?: string) => {
    // If new or clone event, create sponsors after creating event
    if (!eventId) {
      logger.info("[useSponsors][addSponsor] Add sponsor for new event", {
        sponsor,
      });

      const copy = state && state.sponsors ? [...state.sponsors] : [];

      copy.push(sponsor);
      actions?.setSponsors(copy);

      return copy;
    }
    logger.info(`[useSponsors][addSponsor] Add sponsor for event: ${eventId}`, {
      sponsor,
    });
    const resp = await addSponsorInEvent(eventId, sponsor);

    if (resp.isSuccess) {
      logger.info(
        `[useSponsors][addSponsor] Successfully added sponsor into event: ${eventId}`,
      );
      addSuccessNotification({ message: t("event:add.sponsor.success") });
      track(Events.SPONSORS_ADDED, {
        eventId,
      });

      return resp.sponsors;
    }
    logger.error(
      `[useSponsors][addSponsor] Could not add sponsor into event: ${eventId} message: ${resp.message}`,
    );
    addErrorNotification({
      message: t(resp.message || "error.unknown"),
    });

    return null;
  };

  const updateSponsor = async (sponsor: ISponsor, eventId: string) => {
    logger.info(
      `[useSponsors][updateSponsor] Update sponsor for event: ${eventId}`,
      { sponsor },
    );

    const resp = await updateSponsorInEvent(eventId, sponsor);

    if (resp.isSuccess) {
      logger.info(
        `[useSponsors][updateSponsor] Successfully updated sponsor in event: ${eventId}`,
      );
      addSuccessNotification({ message: t("event:success.sponsor.save") });

      return resp.sponsors;
    }
    logger.error(
      `[useSponsors][updateSponsor] Could not update sponsor in event: ${eventId} message: ${resp.message}`,
    );
    addErrorNotification({
      message: t(resp.message || "error.unknown"),
    });

    return null;
  };

  const deleteSponsor = async (
    eventId: string | undefined,
    sponsorId: string,
  ) => {
    if (!eventId) {
      logger.info(
        "[useSponsors][deleteSponsor] deleting sponsor for new event",
        { sponsorId },
      );

      // delete selected sponsor from list
      const copy = state && state.sponsors ? [...state.sponsors] : [];

      // eslint-disable-next-line radix
      copy.splice(parseInt(sponsorId), 1);
      actions?.setSponsors(copy);

      return copy;
    }
    logger.info(
      `[useSponsors][deleteSponsor] Delete sponsor for event: ${eventId}`,
      { sponsorId },
    );

    const resp = await deleteSponsorInEvent(eventId, sponsorId);

    if (resp.isSuccess) {
      logger.info(
        `[useSponsors][deleteSponsor] Successfully deleted sponsor from event: ${eventId}`,
      );

      return resp.sponsors;
    }
    logger.error(
      `[useSponsors][deleteSponsor] Could not deleted sponsor from event: ${eventId} message: ${resp.message}`,
    );
    addErrorNotification({
      message: t(resp.message || "error.unknown"),
    });

    return null;
  };

  const isVideo = (url: string) => !!validateVideoURL(getMaskedUrl(url));

  const exportMembers = async (sponsorData: ISponsor, eventId: string) => {
    if (!sponsorData._id) {
      logger.error(`[useSponsors][exportMembers] No sponsor ID for ${eventId}`);

      return;
    }

    const result = await exportMembersBySponsor(
      eventId,
      sponsorData._id,
      encodeURIComponent(Intl.DateTimeFormat().resolvedOptions().timeZone),
    );

    if (!result.data) {
      logger.error(`[useSponsors][exportMembers] No result data: ${result}`);

      addErrorNotification({
        message: t("error.unknown"),
      });

      return;
    }
    fileDownload(result.data, `${sponsorData.name}.csv`);
  };

  return {
    addSponsor,
    updateSponsor,
    deleteSponsor,
    isVideo,
    exportMembers,
  };
};

export default useSponsors;
