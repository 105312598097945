import { getVar } from "config";
import { deleteObject, getDownloadURL, listAll, ref } from "firebase/storage";
import logger from "logging/logger";
import { storage } from "services/firebaseService";

// TODO: this does not use any react hooks. Doesn't need to be a hook.
const fireStorage = () => {
  const deleteFile = (filePath: string) => deleteObject(ref(storage, filePath));

  const listFiles = async (path: string): Promise<string[]> => {
    const files = await listAll(ref(storage, path));
    const paths = files.items.map((item) => item.fullPath);

    return paths;
  };

  const getFileDownloadURL = async (filePath: string): Promise<string> =>
    getDownloadURL(ref(storage, filePath));

  const getAssetsUrl = async (filePath: string): Promise<string> => {
    const url = await getFileDownloadURL(filePath);

    try {
      if (getVar("NODE_ENV") === "development") {
        return url;
      }

      const assetsUrl = `${getVar("REACT_APP_ASSETS_URL")}${decodeURIComponent(
        url.split("/o/")[1],
      )}`;

      return assetsUrl;
    } catch (err) {
      if (err instanceof Error) {
        logger.error(`[getAssetsUrl] err: ${err.message} path: ${filePath}`);
      }
    }

    return url;
  };

  return {
    deleteFile,
    listFiles,
    getFileDownloadURL,
    getAssetsUrl,
  };
};

export default fireStorage;
