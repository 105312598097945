import { sendGetRequest } from "services/apiService";
import { ICustomRegistrationQuestion } from "../../types";

export const getRequestURL = (eventId?: string) =>
  `/event/${eventId}/questions`;

export const getCustomQuestions = async (
  eventId?: string,
): Promise<ICustomRegistrationQuestion[]> => {
  const resp = await sendGetRequest<
    APIResponse<{
      questions: ICustomRegistrationQuestion[];
    }>
  >(getRequestURL(eventId));

  if (!resp.isSuccess) {
    throw new Error(resp?.message);
  }

  return resp.questions;
};
