import React from "react";
import { useSelector } from "react-redux";
import { selectIsEventManager } from "modules/event/selectors";
import { selectIsCurrentUserInTutorial } from "modules/userStatus/redux/selectors";
import { selectIsMobile } from "modules/deviceInfo";
import { MobileToolbar } from "./MobileToolbar";
import { RemoDesktopToolbar } from "./RemoDesktopToolbar";

const BottomMenu = () => {
  const isMobileView = useSelector(selectIsMobile);
  const isEventManager = useSelector(selectIsEventManager);
  const isMeInTutorial = useSelector(selectIsCurrentUserInTutorial);

  if (isMeInTutorial) {
    return null;
  }

  return isMobileView ? (
    <MobileToolbar />
  ) : (
    <RemoDesktopToolbar isEventManager={isEventManager} />
  );
};

export default React.memo(BottomMenu);
