import { useI18n } from "i18n";
import { useCompanyPlanSettings } from "modules/companyPlanSettings";
import { IManageEventActions } from "modules/manageEvent/hooks/types";
import { IEvent } from "../types";

const useEventValidator = () => {
  const { getTimeLimitPerEvent } = useCompanyPlanSettings();
  const timeLimit = getTimeLimitPerEvent();

  const { t } = useI18n(["common", "event"]);

  const isEventWithValidTime = (
    { startTime, endTime, isUnlimitedEvent }: IEvent,
    actions: IManageEventActions,
  ) => {
    // unlimited events
    if (isUnlimitedEvent) {
      return true;
    }
    // -1 means unlimited
    if (
      endTime &&
      startTime &&
      timeLimit > -1 &&
      endTime - startTime > timeLimit * 60 * 1000
    ) {
      actions.setUpgradeMessage(
        t("event:warning.plan.max.event.time", {
          key: timeLimit,
        }),
      );

      return false;
    }

    actions.setUpgradeMessage("");

    return true;
  };

  const onUpgradeClose = (action: () => void) => {
    action();
  };

  return {
    isEventWithValidTime,
    onUpgradeClose,
  };
};

export default useEventValidator;
