import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { IDialogParams } from "../DialogNotificationContainer";

interface DialogNotificationReducerState {
  notifications: IDialogParams[];
}

interface UpdateDialogParams {
  type: string;
  data: Partial<IDialogParams>;
}

export const initialState: DialogNotificationReducerState = {
  notifications: [],
};

const dialogNotificationSlice = createSlice({
  name: "dialogNotification",
  initialState,
  reducers: {
    addDialogNotification: (
      state: DialogNotificationReducerState,
      action: PayloadAction<IDialogParams>,
    ) => {
      if (
        !state.notifications.some(
          (notification) => notification.type === action.payload.type,
        )
      ) {
        state.notifications.push(action.payload);
      }
    },
    closeDialogNotification: (state: DialogNotificationReducerState) => {
      state.notifications = state.notifications.slice(1);
    },
    closeDialogNotificationsByType: (
      state: DialogNotificationReducerState,
      action: PayloadAction<string>,
    ) => {
      state.notifications = state.notifications.filter(
        (item) => item.type && item.type !== action.payload,
      );
    },
    updateDialogNotificationParams: (
      state: DialogNotificationReducerState,
      action: PayloadAction<UpdateDialogParams>,
    ) => {
      state.notifications = state.notifications.map((item) =>
        item.type === action.payload.type
          ? { ...item, ...action.payload.data }
          : item,
      );
    },
  },
});

export const {
  addDialogNotification,
  closeDialogNotification,
  closeDialogNotificationsByType,
  updateDialogNotificationParams,
} = dialogNotificationSlice.actions;

export default dialogNotificationSlice.reducer;
