import store from "store";
import { trackHelpChatEvent } from "modules/helpChat";
import { getCurrentClientTime } from "helpers/firebaseTimeHelper";

// Setting timeout of 15s so it will load user details from helpChat
// To avoid over writing login details (ex: rc_first_event_attended_name)
export const updateHelpChatEvents = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: Record<string, any>,
  timeout = 15000,
  skipUpdate = true,
) => {
  setTimeout(() => {
    // eslint-disable-next-line no-restricted-syntax
    for (const key in data) {
      if (data[key] !== undefined) {
        store.dispatch(
          trackHelpChatEvent({ key, value: data[key], skipUpdate }),
        );
      }
    }
  }, timeout);
};

export const viewEvent = () => async () => {
  const state = store.getState();
  const { events, currentEventId } = state.event;
  const event = currentEventId ? events[currentEventId] : null;
  const time = await getCurrentClientTime();

  updateHelpChatEvents({
    rc_first_event_attended_name: event?.name,
    rc_first_event_attended_at: Math.ceil(time),
  });
  // Track last attended event - keep updating even if it is already added
  updateHelpChatEvents(
    {
      rc_last_event_attended_name: event?.name,
      rc_last_event_attended_at: Math.ceil(time),
    },
    15000,
    false,
  );
};
