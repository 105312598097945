import { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import {
  EventEmbedContentMode,
  GetEmbedContentQuery,
  GetEmbedContentQueryVariables,
  OnEmbedContentCreatedSubscription,
  OnEmbedContentCreatedSubscriptionVariables,
} from "graphql/generated";
import { useAppDispatch } from "store/hooks";
import { Errors, trackError } from "modules/monitoring";
import { GET_EMBED_CONTENT, ON_EMBED_CONTENT_CREATED } from "../../graphql";
import { updateConversationModeContent } from "../../redux/slice";
import { updateEmbedContentQuery } from "../../utils";

const useEmbedContentSubscriptionConversation = (eventId: string) => {
  const dispatch = useAppDispatch();
  const [subscribedAt] = useState(new Date().toISOString());

  const { subscribeToMore, loading, data, error } = useQuery<
    GetEmbedContentQuery,
    GetEmbedContentQueryVariables
  >(GET_EMBED_CONTENT, {
    variables: {
      eventId,
      subscribedAt,
    },
  });

  if (error) {
    trackError(error, { label: Errors.EMBED_CONTENT_QUERY });
  }

  useEffect(() => {
    const unsubscribe = subscribeToMore<
      OnEmbedContentCreatedSubscription,
      OnEmbedContentCreatedSubscriptionVariables
    >({
      document: ON_EMBED_CONTENT_CREATED,
      variables: { eventId },
      onError: (error) => {
        trackError(error, { label: Errors.EMBED_CONTENT_SUBSCRIPTION });
      },
      updateQuery: updateEmbedContentQuery,
    });

    return () => {
      unsubscribe();
    };
  }, [eventId, subscribeToMore]);

  useEffect(() => {
    if (
      !loading &&
      !error &&
      data &&
      data.embedContent.__typename === "EventEmbedContentList"
    ) {
      dispatch(
        updateConversationModeContent(
          data.embedContent.content.filter(
            (content) => content.mode === EventEmbedContentMode.Conversation,
          ),
        ),
      );
    }
  }, [dispatch, data, loading, error]);

  return {
    data,
    loading,
    error,
  };
};

export default useEmbedContentSubscriptionConversation;
