import classnames from "classnames";
import { ReactNode } from "react";
import { useStyles } from "./styles";
import { FormHelperText } from "../FormHelperText";

interface Props {
  isError?: boolean;
  children: ReactNode;
  className?: string;
}

const InputHelpText = ({
  isError = false,
  children,
  className,
}: Props): JSX.Element => {
  const styles = useStyles();
  return (
    <FormHelperText
      className={classnames(styles.description, className, {
        [styles.errorDescription]: isError,
      })}
      error={isError}
    >
      {children}
    </FormHelperText>
  );
};
export default InputHelpText;
