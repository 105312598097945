import { Copy } from "@remo-co/ui-core/src/icons/Copy";
import { Delete } from "@remo-co/ui-core/src/icons/Delete";
import { Edit } from "@remo-co/ui-core/src/icons/Edit";
import { StreamList } from "@remo-co/ui-core/src/icons/StreamList";
import { LiveStreamData } from "modules/liveStream/request";
import copy from "copy-to-clipboard";
import {
  useDeleteLiveStream,
  useLiveStreamingAsync,
} from "modules/liveStream/hooks";
import { useAppDispatch } from "store/hooks";
import {
  addErrorNotification,
  addSuccessNotification,
} from "modules/notification/redux/notificationSlice";
import { DeleteLiveStream } from "modules/liveStream/components";
import { Button } from "@remo-co/ui-core/src/components/Button";
import { Typography } from "@remo-co/ui-core/src/components/Typography";
import { useState } from "react";
import { useStyles } from "./style";

interface Props {
  stream: LiveStreamData;
  openEditForm: (stream: LiveStreamData) => void;
  eventId: string;
}

export const LiveStreamItem = ({
  stream,
  openEditForm,
  eventId,
}: Props): JSX.Element => {
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const styles = useStyles();
  const dispatch = useAppDispatch();
  const { isLiveStreaming } = useLiveStreamingAsync();
  const { deleteLiveStream } = useDeleteLiveStream();

  const handleCopy = () => {
    copy(stream.playbackUrl);
    dispatch(
      addSuccessNotification({
        message: "Playback Url copied to ClipBoard",
      }),
    );
  };

  const handleOpenEditForm = () => {
    if (isLiveStreaming) {
      dispatch(
        addErrorNotification({
          message: "Please Stop Streaming to edit",
        }),
      );
      return;
    }
    openEditForm(stream);
  };

  const handleDeleteDialog = () => {
    setDeleteDialogOpen(!deleteDialogOpen);
  };

  const handleDeleteSubmit = () => {
    deleteLiveStream({
      streamId: stream.id,
      eventId,
    });
    handleDeleteDialog();
  };

  return (
    <div className={styles.listItem} data-testid="live-stream-item">
      <div className={styles.listItemIcon}>
        <StreamList />
      </div>
      <div className={styles.listItemName}>
        <Typography variant="h4">{stream.destinationName}</Typography>
      </div>
      <div className={styles.listItemFunctionIcons}>
        {stream.playbackUrl && (
          <div className={styles.streamManageIcon}>
            <Button
              className={styles.functionButton}
              onClick={handleCopy}
              data-testid="copy-live-stream"
            >
              <Copy onClick={handleCopy} />
            </Button>
          </div>
        )}

        <div className={styles.streamManageIcon}>
          <Button
            className={styles.functionButton}
            onClick={handleOpenEditForm}
            data-testid="edit-live-stream"
          >
            <Edit />
          </Button>
        </div>
        <div className={styles.streamManageIcon}>
          <Button
            className={styles.functionButton}
            onClick={handleDeleteDialog}
            data-testid="delete-live-stream"
          >
            <Delete />
          </Button>
        </div>
      </div>
      {deleteDialogOpen && (
        <DeleteLiveStream
          handleSubmit={handleDeleteSubmit}
          handleClose={handleDeleteDialog}
        />
      )}
    </div>
  );
};
