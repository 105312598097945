import { selectUsersPerSpace } from "modules/eventUserPresence/redux/selectors";
import { selectTheaterSpaces } from "modules/theater/selectors";
import { useSelector } from "react-redux";
import { selectRooms } from "store/rooms/selectors";
import useMapNavigation from "modules/mapNavigation/hooks/useMapNavigation";
import { IMapNavigationCoordinatesState } from "modules/mapNavigation/redux/mapNavigationSlice";
import { selectUserRoomStatus } from "modules/room/redux/selectors";

type UserLocation =
  | {
      status: "currentFloor";
      coordinates: IMapNavigationCoordinatesState;
    }
  | {
      status: "differentFloor";
      floorId: string;
      floor: number;
    }
  | {
      status: "notFound";
    };

const useParticipantLocation = () => {
  const usersPerSpace = useSelector(selectUsersPerSpace);
  const spaces = useSelector(selectTheaterSpaces);
  const rooms = useSelector(selectRooms);
  const { getCoordinates } = useMapNavigation();
  const userRoomStatus = useSelector(selectUserRoomStatus);

  const findUserInFloor = (userId: string) => {
    const userAtTable = Object.entries(userRoomStatus).find(
      ([_, userPositions]) => Object.keys(userPositions).includes(userId),
    );

    if (!userAtTable) {
      return undefined;
    }

    const [tableId] = userAtTable;
    return rooms[tableId];
  };

  const findFloorNumber = (spaceId?: string) => {
    if (!spaceId) return undefined;

    const floorIndex = spaces.indexOf(spaceId);
    return floorIndex > -1 ? floorIndex + 1 : undefined;
  };

  const findUserInEvent = (userId: string): string | undefined => {
    const spaceIds = Object.keys(usersPerSpace);
    const usersSpaceId = spaceIds.find((spaceId) => {
      const { [spaceId]: usersInSpace } = usersPerSpace;

      if (!Array.isArray(usersInSpace)) {
        return false;
      }

      return usersInSpace.includes(userId);
    });

    return usersSpaceId;
  };

  const locateUser = (userId: string): UserLocation => {
    const room = findUserInFloor(userId);

    if (room?.id) {
      return {
        status: "currentFloor",
        coordinates: getCoordinates(userId, room),
      };
    }

    const floorId = findUserInEvent(userId);
    const floorNumber = findFloorNumber(floorId);

    if (floorNumber && floorId) {
      return {
        status: "differentFloor",
        floorId,
        floor: floorNumber,
      };
    }

    return {
      status: "notFound",
    };
  };

  return {
    locateUser,
    findUserInEvent,
    findUserInFloor,
  };
};

export default useParticipantLocation;
