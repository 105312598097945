import { Breakpoint } from "@mui/material/styles";
import { spacingToPx } from "../../utils/spacingToPx";
import {
  PropValueOrResponsivenessMap,
  isBreakpointProp,
  isPassthrough,
  isReset,
} from "../../utils/createResponsiveRules";
import { SpacingType } from "../../types";
import { IContainerStylingProperties } from "./styles";
import { IContainerProps } from "./Container";

type MarginRule = "marginLeft" | "marginTop" | "marginBottom" | "marginRight";

const getMargin = (
  margin: PropValueOrResponsivenessMap<number | SpacingType | undefined>,
  currentBreakpoint: Breakpoint | null,
) => {
  if (!margin || !currentBreakpoint) {
    return;
  }

  if (isBreakpointProp(margin)) {
    const currentMargin = margin[currentBreakpoint];

    if (currentMargin != null) {
      // eslint-disable-next-line consistent-return
      return spacingToPx(currentMargin);
    }

    return;
  }

  // eslint-disable-next-line consistent-return
  return spacingToPx(margin);
};

export const generateMarginMap =
  (side: MarginRule) =>
  (
    spacing: IContainerStylingProperties["top"] | null,
    props: IContainerProps,
    currentBreakpoint: Breakpoint | null,
  ) => {
    if (isReset(spacing)) {
      return {
        [side]: 0,
      };
    }

    if (!isPassthrough(spacing)) {
      return {
        [side]: spacingToPx(spacing),
      };
    }

    if (props.margin != null) {
      return {
        [side]: getMargin(props.margin, currentBreakpoint),
      };
    }

    return {};
  };
