import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";

export const useStyles = makeStyles((theme) => ({
  heading: {
    marginTop: theme.spacing(4),
  },
  title: {
    display: "inline",
  },
  uploader: {
    position: "relative",
    "& .MuiButtonBase-root": {
      textDecoration: "none",
    },
  },
  imageUploaderContainer: {
    position: "relative",
    width: "100%",
    marginBottom: theme.spacing(1),
    paddingTop: theme.spacing(2),
  },
}));
