import { EventContentType } from "graphql/generated";
import { selectIsMobile } from "modules/deviceInfo";
import { ISlot } from "modules/theater/types";
import { useSelector } from "react-redux";
import { useEventFloorContentSubscription } from "../../hooks/useEventFloorContentSubscription";
import { FloorContent } from "../FloorContent";
import { FullScreenContent } from "../FullScreenContent";
import { PopupContent } from "../PopupContent";

interface Props {
  eventId: string;
  floorId: string;
  templateSlots: ISlot[];
}

export const EventContentRenderer = ({
  eventId,
  floorId,
  templateSlots,
}: Props) => {
  const isMobileView = useSelector(selectIsMobile);
  const { data } = useEventFloorContentSubscription({
    eventId,
    floorId,
  });

  if (
    !(data && data.eventFloorContent.__typename === "EventFloorContentList")
  ) {
    return null;
  }

  const { content } = data.eventFloorContent;

  return (
    <>
      {content.map((contentItem) => {
        const slot = templateSlots[Number(contentItem.slot)];
        // TODO: the sponsors counterpart seems to check for max ads at the rendering stage
        // maybe the event management form needs to handle that instead, investigate

        if (!slot) {
          return null;
        }

        switch (contentItem.type) {
          case EventContentType.EventFloor:
            return <FloorContent content={contentItem} slot={slot} />;

          case EventContentType.FullScreen:
            return isMobileView ? (
              <PopupContent content={contentItem} slot={slot} />
            ) : (
              <FullScreenContent content={contentItem} slot={slot} />
            );
          case EventContentType.Popup:
            return <PopupContent content={contentItem} slot={slot} />;

          default:
            return null;
        }

        return null;
      })}
    </>
  );
};
