import { ApolloClient, ApolloLink, InMemoryCache } from "@apollo/client";
import { authLinks } from "./middleware/auth";
import { typePolicies } from "./typePolicies";

const client = new ApolloClient({
  link: ApolloLink.from(authLinks),
  cache: new InMemoryCache({
    typePolicies,
  }),
  connectToDevTools: true,
});

export default client;
