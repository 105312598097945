import React from "react";
import { I18nContextType } from "./types";

export const I18nContext = React.createContext<I18nContextType>({
  translate: () => {
    throw new Error("I18nProvider required");
  },
  setLanguage: () => {
    throw new Error("I18nProvider required");
  },
  language: "en",
  ns: [],
  addNameSpaces: () => {},
  noEscape: false,
});
