import { MouseEvent, memo, useContext, useEffect, useState } from "react";
import { Divider } from "@remo-co/ui-core/src/components/Divider";
import { List } from "@remo-co/ui-core/src/components/List";
import { Tooltip } from "@remo-co/ui-core/src/components/Tooltip";
import { ListItem } from "@remo-co/ui-core/src/components/ListItem";
import { ListItemText } from "@remo-co/ui-core/src/components/ListItemText";
import { Collapse } from "@remo-co/ui-core/src/components/Collapse";
import { ExpandLess } from "@remo-co/ui-core/src/icons/ExpandLess";
import { ExpandMore } from "@remo-co/ui-core/src/icons/ExpandMore";
import { useHistory } from "react-router-dom";
import { sortBy } from "lodash";
import { useSelector } from "react-redux";
import { selectAuth } from "modules/auth/redux/selectors";
import { useI18n } from "i18n";
import { useCompanyPlanSettings } from "modules/companyPlanSettings";
import { IEventPage } from "modules/manageEvent/types";
import { MANAGE_EVENT_CONTEXT } from "modules/manageEvent";
import useManageEventPages from "modules/manageEvent/hooks/useManageEventPages";
import useCompanySplitTreatment from "modules/split/useCompanySplitTreatment";
import { CompanyFeatures } from "services/splitService/features";
import { hasTicketingAccess } from "modules/companyPlanSettings/utils/ticketing";
import { LeftMenuItem } from "../LeftMenuItem";
import {
  BASIC_SETTINGS,
  INVITATIONS,
  MANAGE_EVENT_PAGES,
  POST_EVENT,
  TICKETING,
} from "../../constants";
import "./LeftMenu.scss";
import useStyles from "./styles";

const ManageEventLeftMenu = memo(() => {
  const styles = useStyles();
  const { state, onTabChange } = useContext(MANAGE_EVENT_CONTEXT);
  const pageId = state?.pageId ?? undefined;
  const eventData = state?.eventData ?? undefined;
  const { isUnlimitedEvent } = eventData ?? {};
  const eventId = (eventData && (eventData.id || eventData.clonedFrom)) || "";
  const [pages, setPages] = useState<IEventPage[]>();
  const [showAdvancedMenu, setShowAdvancedMenu] = useState(false);
  const history = useHistory();
  const { isManager, isEventHelper } = useSelector(selectAuth);
  const { myCompanyPlan } = useCompanyPlanSettings();
  const { t } = useI18n(["common", "manageEvent"]);
  const { getAdvancedEventPages } = useManageEventPages();

  const { featureEnabled: isTicketingEnabled } = useCompanySplitTreatment(
    CompanyFeatures.TICKETING,
  );

  useEffect(() => {
    if (
      pageId &&
      pageId !== BASIC_SETTINGS.pageId &&
      pageId !== TICKETING.pageId &&
      pageId !== INVITATIONS.pageId &&
      pageId !== POST_EVENT.pageId &&
      !showAdvancedMenu
    ) {
      setShowAdvancedMenu(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageId]);

  useEffect(() => {
    let filteredPages = getAdvancedEventPages(
      MANAGE_EVENT_PAGES,
      Boolean(state?.sponsors?.length),
      isUnlimitedEvent,
    );

    // If edit/clone event, show the summary tab at top
    if (eventId) {
      filteredPages = sortBy(filteredPages, (page) =>
        page.pageId === "summary" ? 0 : 1,
      );
    }

    // If no eventId, don't show stream tab
    if (!eventId) {
      filteredPages = filteredPages.filter((page) => page.pageId !== "stream");
    }
    if (isUnlimitedEvent) {
      filteredPages = filteredPages.filter(
        (page) => page.pageId !== "agenda" && page.pageId !== "stream",
      );
    }

    setPages(filteredPages);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    eventId,
    isManager,
    isEventHelper,
    pageId,
    myCompanyPlan,
    isUnlimitedEvent,
    state?.sponsors?.length,
  ]);

  const handleClick =
    (disabled = false) =>
    (e: MouseEvent<HTMLAnchorElement>) => {
      e.stopPropagation();
      e.preventDefault();
      const { href } = e.currentTarget;

      if (href !== undefined && !disabled) {
        onTabChange((isConfirmed?: boolean) => {
          if (isConfirmed) {
            const parts = href.split(window.location.origin);

            history.push(parts[1]);
          }
        });
      }
    };

  const toggleAdvancedMenu = () => setShowAdvancedMenu(!showAdvancedMenu);

  return (
    <List
      component="nav"
      aria-labelledby={t("manageEvent:event.navigate")}
      className="me-leftmenu"
      disablePadding
    >
      <LeftMenuItem
        onClick={handleClick()}
        title={t(BASIC_SETTINGS.title)}
        linkPageId={BASIC_SETTINGS.pageId}
        pageId={pageId}
        eventId={eventId}
      />
      {isTicketingEnabled &&
        hasTicketingAccess(myCompanyPlan) &&
        !isUnlimitedEvent && (
          <LeftMenuItem
            onClick={handleClick()}
            title={t(TICKETING.title)}
            linkPageId={TICKETING.pageId}
            pageId={pageId}
            eventId={eventId}
          />
        )}
      <LeftMenuItem
        onClick={handleClick()}
        title={t(INVITATIONS.title)}
        linkPageId={INVITATIONS.pageId}
        pageId={pageId}
        eventId={eventId}
      />
      <ListItem onClick={toggleAdvancedMenu}>
        <ListItemText primary={t("manageEvent:advanced")} />
        {showAdvancedMenu ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={showAdvancedMenu} timeout="auto" unmountOnExit>
        <Divider />
        {pages?.map((page: IEventPage) => {
          const disabled = page.hiddenInCreateEvent && !eventId;

          return (
            <div key={page.pageId} className={styles.section}>
              <Tooltip
                title={
                  disabled
                    ? (t("manageEvent:button.disable.text") as string)
                    : ""
                }
                placement="top"
              >
                <LeftMenuItem
                  onClick={handleClick(!!disabled)}
                  title={t(page.title)}
                  linkPageId={page.pageId}
                  pageId={pageId}
                  eventId={eventId}
                  isHidden={page.isHidden}
                  isDisabledInCreateEvent={page.isDisabledInCreateEvent}
                  key={page.pageId}
                />
              </Tooltip>
            </div>
          );
        })}
      </Collapse>
      {eventData && eventData.id && !eventData.clonedFrom && (
        <>
          <Divider />
          <LeftMenuItem
            onClick={handleClick()}
            title={t(POST_EVENT.title)}
            linkPageId={POST_EVENT.pageId}
            pageId={pageId}
            eventId={eventId}
          />
        </>
      )}
    </List>
  );
});

export default ManageEventLeftMenu;
