import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface UserProfileCardState {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  anchorEl: any;
  userId: string | undefined;
  isShowProfileCard: boolean;
}

export const initialState: UserProfileCardState = {
  anchorEl: null,
  userId: undefined,
  isShowProfileCard: false,
};

const userProfileCardSlice = createSlice({
  name: "userProfileCard",
  initialState,
  reducers: {
    openUserProfileCard: (
      state,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      action: PayloadAction<{ userId: string; anchorEl: any }>,
    ) => {
      const { userId, anchorEl } = action.payload;
      state.userId = userId;
      state.anchorEl = anchorEl ? { ...anchorEl } : null;
      state.isShowProfileCard = true;
    },
    closeUserProfileCard: (state) => {
      state.userId = undefined;
      state.anchorEl = null;
      state.isShowProfileCard = false;
    },
    resetUserProfileCard: () => initialState,
  },
});

export const {
  openUserProfileCard,
  closeUserProfileCard,
  resetUserProfileCard,
} = userProfileCardSlice.actions;

export default userProfileCardSlice.reducer;
