import { useCallback, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  selectIsEventManager,
  selectIsEventSpeaker,
  selectIsWhiteboardEnabled,
} from "modules/event/selectors";
import { useAppDispatch } from "store/hooks";
import { selectBroadcastWhiteboard } from "modules/broadcastWhiteboard/redux/selectors";
import { updateBroadcastWhiteboard } from "modules/broadcastWhiteboard/redux/slice";
import { selectIsMeBroadcaster } from "modules/broadcaster";
import useBroadcastWhiteboardActions from "./hooks/useBroadcastWhiteboardActions";
import MiroWhiteboard from "../whiteboard/MiroWhiteboard";
import useMiroBoard from "../whiteboard/hooks/useMiroBoard";
import logger from "../../logging/logger";
import { selectCurrentTheaterId } from "../theater/selectors";

const BroadcastMiroBoard = (): JSX.Element | null => {
  const dispatch = useAppDispatch();
  const { boardCode, miroBoardId } = useSelector(selectBroadcastWhiteboard);
  const hasEventManagerAccess = useSelector(selectIsEventManager);
  const hasEventSpeakerAccess = useSelector(selectIsEventSpeaker);
  const theaterId = useSelector(selectCurrentTheaterId);
  const { closeWhiteboard } = useBroadcastWhiteboardActions();
  const showCloseButton = hasEventManagerAccess;
  const showImportButton = hasEventSpeakerAccess || hasEventManagerAccess;
  const isMeBroadcaster = useSelector(selectIsMeBroadcaster);
  const readonly =
    !isMeBroadcaster && !hasEventSpeakerAccess && !hasEventManagerAccess;
  const isWhiteboardEnabled = useSelector(selectIsWhiteboardEnabled);

  const { getMiroBoardAccessLinkToken, selectBoardFromPicker } = useMiroBoard(
    theaterId ?? "",
    false,
  );

  const handleClose = () => {
    closeWhiteboard();
  };

  useEffect(() => {
    const start = async () => {
      if (theaterId) {
        const resp = await getMiroBoardAccessLinkToken("Presentation");

        dispatch(
          updateBroadcastWhiteboard({
            boardCode: resp?.code,
            miroBoardId: resp?.id,
          }),
        );
      }
    };

    // eslint-disable-next-line promise/prefer-await-to-then
    start().catch((error) => {
      logger.error(`[BroadcastMiroBoard][start] ${error.message}`);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [theaterId, dispatch]);

  const importBoard = useCallback(async () => {
    if (theaterId) {
      const resp = await selectBoardFromPicker();

      dispatch(
        updateBroadcastWhiteboard({
          boardCode: resp?.code,
          miroBoardId: resp?.id,
        }),
      );
    }
  }, [theaterId, dispatch, selectBoardFromPicker]);

  if (!isWhiteboardEnabled) {
    handleClose();

    return null;
  }

  return (
    <MiroWhiteboard
      readonlyBoard={readonly}
      miroBoardId={miroBoardId}
      boardCode={boardCode}
      showCloseButton={showCloseButton}
      onClose={handleClose}
      importBoard={importBoard}
      showImportButton={showImportButton}
    />
  );
};

export default BroadcastMiroBoard;
