import { useSelector } from "react-redux";
import { selectIsMobile } from "modules/deviceInfo";
import { useMemo } from "react";
import {
  selectActiveTile,
  selectPresentationSpeakersSessionIds,
} from "modules/audioVideo/redux/selectors";
import { DisplayItem, DisplayItemConfig } from "modules/audioVideo";
import useEventSplitTreatment from "modules/split/useEventSplitTreatment";
import { EventFeatures } from "services/splitService/features";
import { selectIsInBroadcast } from "modules/broadcast/redux/selectors";
import { selectCurrentRoomId } from "store/rooms";
import {
  MAX_HIGH_QUALITY_PARTICIPANTS,
  MAX_HIGH_QUALITY_PARTICIPANTS_WITH_VIDEO_OPTIMIZATION,
} from "../constants";
import { useParticipantsAtTable } from "../../useMeetingParticipants";

export const usePerformanceHelpers = () => {
  const activeTile = useSelector(selectActiveTile);
  const tableId = useSelector(selectCurrentRoomId);
  const participants = useParticipantsAtTable({ tableId });
  const isInBroadcast = useSelector(selectIsInBroadcast);
  const speakers = useSelector(selectPresentationSpeakersSessionIds);
  const isMobile = useSelector(selectIsMobile);
  const { featureEnabled } = useEventSplitTreatment(
    EventFeatures.VIDEO_OPTIMIZATION,
  );

  const highQualityParticipantCount = useMemo(() => {
    if (featureEnabled) {
      return MAX_HIGH_QUALITY_PARTICIPANTS;
    }
    return MAX_HIGH_QUALITY_PARTICIPANTS_WITH_VIDEO_OPTIMIZATION;
  }, [featureEnabled]);

  const activeParticipantId = useMemo(() => {
    const isNotScreenOrCam = [
      DisplayItemConfig.whiteboardDisplayId,
      DisplayItemConfig.externalVideoDisplayId,
      DisplayItemConfig.presentContentDisplayId,
    ];

    if (
      activeTile &&
      !isNotScreenOrCam.includes(activeTile.streamId) &&
      !activeTile.streamId.includes(DisplayItem.screenShare)
    ) {
      return activeTile.streamId;
    }

    return null;
  }, [activeTile]);

  const loadTileModeReceiveSettings = useMemo(() => {
    const tooManyParticipants =
      participants.length > highQualityParticipantCount;
    const tooManySpeakers = speakers.length > highQualityParticipantCount;
    const singleSpeaker = isInBroadcast
      ? speakers.length === 1
      : participants.length === 0;

    if (
      (tooManyParticipants && !isInBroadcast) ||
      (isInBroadcast && tooManySpeakers)
    ) {
      return { video: { layer: 0 } };
    }

    if (singleSpeaker) {
      return { video: { layer: 2 } };
    }

    return { video: { layer: isMobile ? 0 : 1 } };
  }, [
    highQualityParticipantCount,
    isInBroadcast,
    isMobile,
    participants.length,
    speakers.length,
  ]);

  return {
    activeParticipantId,
    loadTileModeReceiveSettings,
  };
};
