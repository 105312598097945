import { PayloadAction, createSlice, createAction } from "@reduxjs/toolkit";
import { getVar } from "config";

export enum LoadingState {
  NOT_STARTED,
  LOADING,
  LOADED,
}

export type AppVersionState = {
  loadingState: LoadingState;
  deployedVersion: null | string;
  currentVersion: null | string;
};

export const initialState: AppVersionState = {
  loadingState: LoadingState.NOT_STARTED,
  currentVersion: getVar("REACT_APP_VERSION") ?? null,
  deployedVersion: null,
};

const fetchDeployedVersionPending = createAction(
  "appVersion/fetchDeployedVersion/pending",
);
const fetchDeployedVersionFulfilled = createAction<string>(
  "appVersion/fetchDeployedVersion/fulfilled",
);
const fetchDeployedVersionRejected = createAction(
  "appVersion/fetchDeployedVersion/rejected",
);

export const appVersionSlice = createSlice({
  name: "appVersion",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchDeployedVersionPending, (state) => {
        state.loadingState = LoadingState.LOADING;
      })
      .addCase(
        fetchDeployedVersionFulfilled,
        (state, action: PayloadAction<string>) => {
          state.loadingState = LoadingState.LOADED;
          state.deployedVersion = action.payload;
        },
      )
      .addCase(fetchDeployedVersionRejected, (state) => {
        state.loadingState = LoadingState.LOADED;
        state.deployedVersion = null;
      });
  },
});

export default appVersionSlice.reducer;
