import { Box } from "@remo-co/ui-core/src/components/Box";
import { Typography } from "@remo-co/ui-core/src/components/Typography";
import { StatusIcon } from "@remo-co/ui-core/src/components/StatusIcon";
import { useI18n } from "i18n";
import { useStyles } from "./style";

interface Props {
  maxSeats: number;
}

const FloorPlanWarning = ({ maxSeats }: Props) => {
  const classes = useStyles();
  const { t } = useI18n(["floorplans"]);

  return (
    <Box
      data-testid="floor-plan-warning"
      className={classes.tableSeatContainer}
    >
      <Box className={classes.tableSeat}>
        <Typography variant="body2" className={classes.heading}>
          {t("floorplans:use.plan.message", { maxSeats })}
        </Typography>
        <StatusIcon variant="warning" />
      </Box>
      <Typography variant="body1" className={classes.content}>
        {t("floorplans:plan.heads.up")}
      </Typography>
      <Typography variant="body1" className={classes.content}>
        {t("floorplans:plan.note")}
      </Typography>
    </Box>
  );
};

export default FloorPlanWarning;
