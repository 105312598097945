import React from "react";
import Avatar from "modules/userAvatar/Avatar";
import { Typography } from "@remo-co/ui-core/src/components/Typography";
import { IUser } from "modules/app/types";
import { getUserName } from "modules/userProfile";
import ProfileInfoStyles from "./styles";

interface IProfileInfoProps {
  user?: IUser;
  mobileView?: boolean;
}

const TranscriptionProfileInfo = React.memo(
  ({ user, mobileView }: IProfileInfoProps) => {
    const classes = ProfileInfoStyles();

    return (
      <div data-testid="transcription-profile-info" className={classes.root}>
        <div className={classes.profileImage}>
          {!mobileView && <Avatar user={user} />}
        </div>
        <Typography variant="h6">{getUserName(user)}</Typography>
      </div>
    );
  },
);

export default TranscriptionProfileInfo;
