import { CSSProperties, Theme } from "@remo-co/ui-core/src/types";
import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";

export const useStyles = makeStyles<Theme>((theme) => ({
  description: {
    marginBottom: "40.5px",
  },
  avatar: {
    width: "60px !important",
    height: "60px !important",
    marginRight: "15px",
  },
  imageUploadCtn: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    marginBottom: "10px",
  },

  imageUploadBtn: {
    marginRight: "10px",
  },
  format: {
    color: theme.palette.blue.darkShade1,
  },
  warning: {
    display: "flex",
    alignItems: "center",
    borderRadius: "5px",
    backgroundColor: theme.palette.yellow.lightShade1,
    padding: "5px 10px",
    marginBottom: "1rem",
  },
  text: {
    fontWeight: theme.typography.fontWeightMedium,
    marginRight: "12px",
  } as CSSProperties,
  icon: {
    color: theme.palette.yellow.dark,
    marginRight: "12px",
  },
}));
