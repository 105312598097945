import { Sponsors } from "modules/sponsors";
import { ISponsor } from "modules/event/types";
import { ISlot } from "modules/theater/types";
import { EventContentRenderer } from "../EventContentRenderer";

interface Props {
  eventId: string;
  currentFloorId: string | null;
  sponsors: ISponsor[];
  templateSlots: ISlot[];
}

export const EventContentWrapper = ({
  eventId,
  currentFloorId,
  sponsors,
  templateSlots,
}: Props) => {
  // load old sponsor system if sponsors are passed
  if (sponsors.length && templateSlots.length) {
    return (
      <Sponsors eventId={eventId} sponsors={sponsors} slots={templateSlots} />
    );
  }

  if (!currentFloorId) {
    return null;
  }

  // load the new system below
  return (
    <EventContentRenderer
      eventId={eventId}
      floorId={currentFloorId}
      templateSlots={templateSlots}
    />
  );
};
