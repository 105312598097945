import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface AssignedShuffleState {
  isDialogVisible: boolean;
  isWaitForEnforceNoticeVisible: boolean;
  hoveredTableCode: string | null;
  hoveredUserId: string | null;
}

export const initialState: AssignedShuffleState = {
  isDialogVisible: false,
  isWaitForEnforceNoticeVisible: false,
  hoveredTableCode: null,
  hoveredUserId: null,
};

export const slice = createSlice({
  name: "assignedShuffle",
  initialState,
  reducers: {
    setDialogVisible: (state, action: PayloadAction<boolean>) => {
      state.isDialogVisible = action.payload;
    },
    setHoveredTableCode: (state, action: PayloadAction<string | null>) => {
      state.hoveredTableCode = action.payload;
    },
    setHoveredUserId: (state, action: PayloadAction<string | null>) => {
      state.hoveredUserId = action.payload;
    },
    setIsWaitForEnforceNoticeVisible: (
      state,
      action: PayloadAction<boolean>,
    ) => {
      state.isWaitForEnforceNoticeVisible = action.payload;
    },
  },
});

export const {
  setDialogVisible,
  setHoveredTableCode,
  setHoveredUserId,
  setIsWaitForEnforceNoticeVisible,
} = slice.actions;

export default slice.reducer;
