import { Box } from "@remo-co/ui-core/src/components/Box";
import { FormHelperText } from "@remo-co/ui-core/src/components/FormHelperText";
import { Typography } from "@remo-co/ui-core/src/components/Typography";
import { Button } from "@remo-co/ui-core/src/components/Button";
import { ImageUploader } from "modules/uploader";
import SponsorUploadAndPreview from "modules/eventForm/sponsorship/SponsorUploadAndPreview";
import UploadCaption, { MaxUploadSizes } from "modules/uploader/UploadCaption";
import * as FileStoragePaths from "services/firebaseService/storagePaths";
import { useI18n } from "i18n";
import { useStyles } from "./styles";

interface Props {
  floorImage?: string | null;
  handleFloorPlanImageChange: (field: string, imageURL?: string) => void;
}

const FLOOR_IMAGE_FIELD = "floorImage";

export const FloorPlanImageField = ({
  floorImage,
  handleFloorPlanImageChange,
}: Props) => {
  const { t } = useI18n(["eventForm", "common", "url"]);
  const styles = useStyles();

  return (
    <Box>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        className={styles.labelBox}
      >
        <Typography variant="body1" className={styles.label}>
          {t("eventForm:floor.plan.image")}
        </Typography>
        <Button<"a">
          href={t("url:floor.plan.help.article")}
          target="_blank"
          rel="noreferrer"
          variant="text"
          size="sm"
          color="blue"
          className={styles.sizeRecommendationsLink}
          data-testid="open-new-tab-icon-button"
        >
          {t("eventForm:size.recommendations")}
        </Button>
      </Box>
      <ImageUploader
        name="media"
        level={FileStoragePaths.UPLOAD_LEVEL.COMPANY}
        path={FileStoragePaths.EVENT_CONTENT}
        maxFileSize="2048KB"
        onImageUploaded={(url) =>
          handleFloorPlanImageChange(FLOOR_IMAGE_FIELD, url)
        }
        className={styles.floorImageUploader}
        contentClassName={styles.floorImageUploaderContent}
        variant="ghost"
        color="dark"
        size="md"
        data-testid="upload-image-button"
      >
        <SponsorUploadAndPreview
          onDelete={() => handleFloorPlanImageChange(FLOOR_IMAGE_FIELD)}
          dataKey="floorImage"
          uploadedImage={floorImage || undefined}
          helpText={
            <FormHelperText>
              <UploadCaption allowGif maxSize={MaxUploadSizes.big} />
            </FormHelperText>
          }
          className={styles.floorImageUploaderPreview}
        />
      </ImageUploader>
    </Box>
  );
};
