import { Theme } from "@remo-co/ui-core/src/types";
import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";
import { Colors } from "@remo-co/ui-core/theme";
import zIndexes from "zIndexes.module.scss";

export const useStyles = makeStyles<Theme>((theme) => ({
  container: {
    boxSizing: "border-box",
    position: "absolute",
    bottom: theme.spacing(1),
    left: theme.spacing(1),
    height: 500,
    width: 300,
    backgroundColor: Colors.ORANGE,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    boxShadow: "2px 4px 20px rgba(0, 0, 0, 0.1)",
    zIndex: zIndexes.helpChatZIndex,
    padding: theme.spacing(7),
    textAlign: "center",
  },
  logo: {
    width: 100,
    height: 100,
  },
  closeButton: {
    position: "absolute",
    top: theme.spacing(0.5),
    right: theme.spacing(0.5),
    width: "31px",
    color: theme.palette.text.secondary,
  },
}));
