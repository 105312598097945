import { RootState } from "store";
import { createSelector } from "@reduxjs/toolkit";

export const liveStream = (state: RootState) => state.liveStream;

export const selectLiveStreams = createSelector(
  liveStream,
  (liveStream) => liveStream.streams,
);

export const selectIsLiveStreamLoading = createSelector(
  liveStream,
  (liveStream) => liveStream.isLoading,
);

export const selectIsLiveStreaming = createSelector(
  liveStream,
  (liveStream) => liveStream.isLiveStreaming,
);
