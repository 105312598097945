import { Theme } from "@remo-co/ui-core/src/types";
import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";
import zIndexes from "zIndexes.module.scss";

const useStyles = makeStyles<Theme>((theme) => ({
  rightPanelButton: {
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
    display: "block",
    backgroundColor: "var(--CornflowerBlue)",
    color: "var(--White-op-090)",
    border: "1px solid rgba(0, 0, 0, 0.2)",
    position: "absolute",
    right: 0,
    top: 0,
    bottom: 0,
    margin: "auto 0",
    height: "80px",
    padding: "0 5px",
    borderTopLeftRadius: "10px",
    borderBottomLeftRadius: "10px",
    cursor: "pointer",
    zIndex: zIndexes.broadcastDialogZIndex,

    "&:focus": {
      outline: "none",
    },

    "&:hover": {
      backgroundColor: "var(--Astronaut)",
    },
  },
  chatIcon: {
    height: "20px",

    svg: {
      width: "34px",
      height: "34px",
      position: "relative",
    },
  },
}));

export default useStyles;
