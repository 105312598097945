import { useCallback } from "react";
import { useI18n } from "i18n";
import { useSelector } from "react-redux";
import { DailyEventObjectAppMessage } from "@daily-co/daily-js";
import { selectIsInBroadcast } from "modules/broadcast/redux/selectors";
import {
  CustomAppEvent,
  CustomAppEvents,
} from "modules/audioVideo/DailyEvents";
import { useAppMessage } from "@daily-co/daily-react";
import useNotificationActions from "modules/notification/hooks/useNotificationActions";
import { useDevices } from "modules/audioVideo/hooks";
import { addDialogNotification } from "modules/dialogNotification/redux/dialogNotificationSlice";
import { useAppDispatch } from "store/hooks";

const useUserMute = () => {
  const isInBroadcast = useSelector(selectIsInBroadcast);
  const { addSuccessNotification } = useNotificationActions();
  const dispatch = useAppDispatch();
  const { t } = useI18n(["micCamCheck"]);

  const { setMicrophoneActive } = useDevices();

  const handleMuteSignalReceive = useCallback(() => {
    setMicrophoneActive(false);
    const messageInConversation = t("micCamCheck:host.mute.mic");
    const messageInBroadcast = t("micCamCheck:host.mute.mic");

    addSuccessNotification({
      message: isInBroadcast ? messageInBroadcast : messageInConversation,
    });
  }, [addSuccessNotification, setMicrophoneActive, isInBroadcast, t]);

  const handleUnmuteSignalReceive = useCallback(() => {
    const messageInConversation = t("micCamCheck:asking.on.mic");
    const messageInBroadcast = t("micCamCheck:asking.on.mic");

    dispatch(
      addDialogNotification({
        message: isInBroadcast ? messageInBroadcast : messageInConversation,
        hideCloseButton: true,
        confirmText: t("micCamCheck:turn.on.mic"),
        onConfirm: () => {
          setMicrophoneActive(true);
        },
        dismissText: t("micCamCheck:keep.mic.off"),
        className: isInBroadcast ? "dark" : "",
      }),
    );
  }, [t, dispatch, isInBroadcast, setMicrophoneActive]);

  useAppMessage<CustomAppEvent>({
    onAppMessage: useCallback(
      (ev: DailyEventObjectAppMessage) => {
        if (ev.data.event === CustomAppEvents.MUTE_USER) {
          if (ev.data.setAudio === false) {
            handleMuteSignalReceive();
          } else {
            handleUnmuteSignalReceive();
          }
        }
      },
      [handleMuteSignalReceive, handleUnmuteSignalReceive],
    ),
  });
};

export default useUserMute;
