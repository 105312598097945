import { Tooltip } from "@remo-co/ui-core/src/components/Tooltip";
import { useI18n } from "i18n";
import { KeyboardEvent, MouseEvent, useCallback, useMemo } from "react";
import { Typography } from "@remo-co/ui-core/src/components/Typography";
import { useSelector } from "react-redux";
import { selectCurrentEventId } from "modules/event/selectors";
import { selectNetworkingRecommendationUserCommonTopics } from "modules/networkingRecommendations/redux/selectors";
import { useUpdateRecommendedUserConnections } from "modules/networkingRecommendations/hooks/useUpdateRecommendedUserConnections";
import { RatingIcons } from "../RatingIcons";
import { useStyles } from "./styles";

interface Props {
  userId: string;
}

export const RecommendedTooltip = ({ userId }: Props) => {
  const currentEventId = useSelector(selectCurrentEventId);
  const commonTopics = useSelector(
    selectNetworkingRecommendationUserCommonTopics(userId),
  );
  const { t } = useI18n("event");
  const styles = useStyles();

  const { updateConnection } = useUpdateRecommendedUserConnections(userId);

  const handleRequestContactInfoClick = useCallback(() => {
    if (userId && currentEventId) {
      updateConnection({
        connected: true,
        eventId: currentEventId,
        recommendedUser: userId,
      });
    }
  }, [userId, currentEventId, updateConnection]);

  const handleTooltipClick = useCallback(
    (e: MouseEvent<HTMLDivElement> | KeyboardEvent<HTMLDivElement>) => {
      e.stopPropagation();
    },
    [],
  );

  const handleKeyDown = useCallback(
    (e: KeyboardEvent<HTMLDivElement>) => {
      if (["Enter", " "].includes(e.key)) {
        handleTooltipClick(e);
      }
    },
    [handleTooltipClick],
  );

  const tooltipTitle = useMemo(
    () => (
      <div
        onClick={handleTooltipClick}
        onKeyDown={handleKeyDown}
        role="button"
        tabIndex={0}
        className={styles.networkingRecommendationToolTipContainer}
      >
        <Typography
          variant="body1"
          className={styles.networkingRecommendationToolTipDescription}
        >
          {t("remote.video.networking.recommendation.description")}
        </Typography>
        <ul className={styles.networkingRecommendationToolTipList}>
          {commonTopics?.map((topic) => (
            <li key={topic}>
              <Typography variant="body1">{topic}</Typography>
            </li>
          ))}
        </ul>
        <div className={styles.networkingRecommendationsRequestContactInfo}>
          <Typography
            variant="h5"
            className={styles.networkingRecommendationToolTipDescription}
            onClick={handleRequestContactInfoClick}
          >
            {t("request.contact.info")}
          </Typography>
        </div>
        <RatingIcons userId={userId} />
      </div>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      commonTopics,
      handleKeyDown,
      handleRequestContactInfoClick,
      handleTooltipClick,
      userId,
    ],
  );

  return (
    <Tooltip
      title={tooltipTitle}
      arrow
      placement="bottom"
      disableHoverListener={false}
      leaveDelay={150}
      classes={{ tooltip: styles.tooltip }}
      data-testid="remote-tile-networking-recommendations-tooltip"
    >
      <div className={styles.recommendedTooltipTitleContainer}>
        <div className={styles.recommendedTooltipTitle}>
          <Typography variant="caption">{t("recommended")}</Typography>
        </div>
      </div>
    </Tooltip>
  );
};
