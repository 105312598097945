import { useSelector } from "react-redux";
import { selectIsBroadcasting } from "modules/broadcast/redux/selectors";
import { useI18n } from "i18n";
import { IconButtonWithTooltip } from "@remo-co/ui-core/src/components/IconButtonWithTooltip";
import { UserItem } from "modules/participants/types";
import { memo, useContext } from "react";
import { Events, TRACKING_CONTEXT } from "modules/tracking";
import { selectCurrentEventId } from "modules/event/selectors";
import { selectUser } from "modules/auth/redux/selectors";
import { useStyles } from "./styles";
import locateIcon from "../assets/locate.svg";
import { useLocateParticipant } from "../../hooks/useLocateParticipant";

interface Props {
  user: UserItem;
}

export const LocateParticipantButton = memo(({ user }: Props) => {
  const { track } = useContext(TRACKING_CONTEXT);
  const currentEventId = useSelector(selectCurrentEventId);
  const currentUser = useSelector(selectUser);
  const { t } = useI18n(["participants"]);
  const isBroadcasting = useSelector(selectIsBroadcasting);
  const { locateParticipant } = useLocateParticipant(user);
  const styles = useStyles();

  if (isBroadcasting) {
    return null;
  }

  const handleLocateParticipant = () => {
    track(Events.RECOMMENDED_CONNECTION_LOCATE_PARTICIPANT, {
      eventId: currentEventId,
      recommendedUser: user?.email,
      currentUser: currentUser?.email,
    });
    locateParticipant();
  };

  return (
    <IconButtonWithTooltip
      className={styles.locateButton}
      id="locate-participant"
      data-testid="locate-participant-button"
      title={t("participants:participant.locate")}
      onClick={handleLocateParticipant}
    >
      <img src={locateIcon} alt={t("participants:participant.locate")} />
    </IconButtonWithTooltip>
  );
});
