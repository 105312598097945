import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import logger from "logging/logger";
import Youtube, { Options } from "react-youtube";
import "./VideoPlayerIFrame.scss";
import { Button } from "@remo-co/ui-core/src/components/Button";
import { Box } from "@remo-co/ui-core/src/components/Box";
import { VolumeOff } from "@remo-co/ui-core/src/icons/VolumeOff";
import { YouTubePlayer } from "youtube-player/dist/types";
import { getCurrentClientTime } from "helpers/firebaseTimeHelper";
import { Events, TRACKING_CONTEXT } from "modules/tracking";
import { Actions, trackAction } from "modules/monitoring";
import { VIDEO_TRY_SYNC_EVERY_MS } from "modules/broadcast/redux/constants";
import useVideoPlayerActions from "./useVideoPlayerActions";
import { useStyles } from "./styles";

export interface IVideoPlayerIFrameProps {
  src: string;
  videoShareTime?: number;
  shouldSyncVideo?: boolean;
  allowFullScreen?: boolean;
  className?: string;
}

const getYoutubeVideoId = (url: string) => {
  const matchedUrl = url.split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);

  return matchedUrl[2] !== undefined
    ? matchedUrl[2].split(/[^0-9a-z_-]/i)[0]
    : matchedUrl[0];
};

const getStartTime = (url: string) => {
  const matchedUrl = url.split("start=");

  // eslint-disable-next-line radix
  return matchedUrl[1] !== undefined ? parseInt(matchedUrl[1]) : 0;
};
const VideoPlayerIFrame = ({
  src,
  allowFullScreen,
  videoShareTime,
  shouldSyncVideo,
  className,
}: IVideoPlayerIFrameProps) => {
  const isYoutube = useMemo(() => src.indexOf("youtu") !== -1, [src]);

  const videoId = isYoutube && getYoutubeVideoId(src);
  const loopVideo = src.includes("loop=1");

  const opts: Options = {
    playerVars: {
      autoplay: src.includes("autoplay=1") ? 1 : 0,
      start: src.includes("start") ? getStartTime(src) : 0,
      ...(loopVideo && videoId && { loop: 1, playlist: videoId }),
    },
  };

  const onError = useCallback((event: { data: number }) => {
    const errorMessage = `[VideoPlayerIFrame] youtube player error: ${event.data}`;

    logger.error(errorMessage);
  }, []);

  if (!src) return null;

  return (
    <Box
      className="video-player-iframe-ctn"
      data-happo-hide
      data-testid="video-player-iframe-ctn"
    >
      {videoId ? (
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        <YoutubeWrapper
          className={className}
          videoId={videoId}
          videoShareTime={videoShareTime}
          shouldSyncVideo={shouldSyncVideo}
          opts={opts}
          onError={onError}
        />
      ) : (
        <iframe
          className={className}
          src={src}
          frameBorder={0}
          title="Video Player"
          allow={`accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture${
            allowFullScreen ? "; fullscreen" : ""
          }`}
        />
      )}
    </Box>
  );
};

export interface IYoutubeWrapperProps {
  videoId: string;
  videoShareTime?: number;
  shouldSyncVideo?: boolean;
  opts: Options;
  onError: (event: { data: number }) => void;
  className?: string;
}

export const YoutubeWrapper = (props: IYoutubeWrapperProps) => {
  const [muted, setMuted] = useState<boolean>(false);
  const [recentSeeks, setRecentSeeks] = useState<number[]>([]);
  const { track } = useContext(TRACKING_CONTEXT);
  const { isVideoSeekable } = useVideoPlayerActions();

  const player = useRef<YouTubePlayer | null>();
  const classes = useStyles();
  const { videoId, videoShareTime, shouldSyncVideo, opts, onError, className } =
    props;

  // Sync video playback
  useEffect(() => {
    if (!opts.playerVars?.autoplay || !shouldSyncVideo || !videoShareTime) {
      return;
    }

    const intervalId = window.setInterval(async () => {
      try {
        if (!player?.current) return;
        const clientTime = await getCurrentClientTime();
        const timeElapsed = Math.ceil((clientTime - videoShareTime) / 1000);
        const duration = await player.current.getDuration();
        const timeCurrent = await player.current.getCurrentTime();

        const { shouldSeek, aheadBySeconds } = isVideoSeekable({
          playerState: await player.current.getPlayerState(),
          timeCurrent,
          duration,
          timeElapsed,
          recentSeeks,
        });

        if (!shouldSeek) return;
        const logData: { [key: string]: unknown } = {
          timeCurrent,
          timeElapsed,
          differenceInSeconds: Math.abs(aheadBySeconds ?? 0),
        };

        if (aheadBySeconds !== null) {
          logData[aheadBySeconds > 0 ? "isUserAhead" : "isUserBehind"] = true;
        }

        track(Events.SHARE_VIDEO_DELAY_SYNC_ACTION, logData);
        trackAction(Actions.SHARE_VIDEO_DELAY_SYNC_ACTION, logData);

        // true - synchronize (seek) the video whether it's buffered or not
        // false - synchronize (seek) the video only if it's already buffered
        player.current.seekTo(timeElapsed, true);
        player.current.playVideo();
        setRecentSeeks([...recentSeeks, Date.now()]);
      } catch (e) {
        logger.error("[YoutubeWrapper][useEffect] error", {
          e,
        });
      }
    }, VIDEO_TRY_SYNC_EVERY_MS);

    // eslint-disable-next-line consistent-return
    return () => {
      if (intervalId) window.clearInterval(intervalId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recentSeeks]);

  return (
    <div className={classes.youtubeWrapper}>
      <Youtube
        className={className}
        onReady={async (event) => {
          player.current = event.target;
          setMuted(await event.target.isMuted());
        }}
        videoId={videoId}
        opts={opts}
        onError={onError}
      />
      {muted ? (
        <div
          data-testid="youtube-muted-overlay"
          className={classes.unmuteButtonWrapper}
        >
          <Button
            data-testid="youtube-unmute"
            onClick={() => {
              if (player.current) {
                player.current.unMute();
                setMuted(false);
              }
            }}
            color="red"
            variant="primary"
          >
            <VolumeOff />
          </Button>
        </div>
      ) : null}
    </div>
  );
};

export default VideoPlayerIFrame;
