import { selectUser } from "modules/auth/redux/selectors";
import { updateMapTargetState } from "modules/mapNavigation/redux/mapNavigationSlice";
import { useSelector } from "react-redux";
import { useAppDispatch } from "store/hooks";
import { selectCurrentRoom } from "store/rooms";
import { DisplayLayout } from "modules/audioVideo";
import { selectLayout } from "modules/audioVideo/redux/selectors";
import { setLayout } from "modules/audioVideo/redux/slice";
import useMapNavigation from "../useMapNavigation";

interface Props {
  forceMapLayout?: boolean;
}

export const useWhereAmI = (props: Props = {}) => {
  const { forceMapLayout = false } = props;
  const dispatch = useAppDispatch();
  const user = useSelector(selectUser);
  const { getCoordinates } = useMapNavigation();
  const layout = useSelector(selectLayout);
  const currentTable = useSelector(selectCurrentRoom);

  const showWhereAmI = () => {
    if (user?.id && currentTable) {
      if (forceMapLayout && layout !== DisplayLayout.Map) {
        dispatch(setLayout(DisplayLayout.Map));
      }

      if (forceMapLayout || layout === DisplayLayout.Map) {
        const coordinates = getCoordinates(user.id, currentTable);

        dispatch(updateMapTargetState(coordinates));
      }
    }
  };

  return {
    showWhereAmI,
  };
};
