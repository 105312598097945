import { Theme } from "@remo-co/ui-core/src/types";
import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";
import { FiberManualRecord as ColorIndicatorIcon } from "@remo-co/ui-core/src/icons/FiberManualRecord";

interface Props {
  color: string;
}

const useStyles = makeStyles<Theme, Props>(() => ({
  root: ({ color }) => ({
    color,
  }),
}));

const ColorOptionIcon = (props: Props) => {
  const classes = useStyles(props);

  return <ColorIndicatorIcon className={classes.root} />;
};

export default ColorOptionIcon;
