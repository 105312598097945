let audioContext: AudioContext;

export const getAudioContext = () => {
  if (!audioContext) {
    // @ts-expect-error: on safari AudioContext is undefined and it's on webkitAudioContext
    // ts doesn't have webkitAudioContext on window so we're ignoring the next line.
    const AudioContext = window.AudioContext || window.webkitAudioContext;
    audioContext = new AudioContext();
  }

  return audioContext;
};
