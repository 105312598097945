import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ITheater } from "types/theater";

export type TheaterState = {
  currentTheater: ITheater | null;
  theaterSpaces: string[];
  isInHostOnboarding: boolean;
};

export const initialState: TheaterState = {
  currentTheater: null,
  theaterSpaces: [],
  isInHostOnboarding: false,
};

export const theaterSlice = createSlice({
  name: "theater",
  initialState,
  reducers: {
    setCurrentTheater: (state, action: PayloadAction<ITheater>) => {
      state.currentTheater = action.payload;
    },
    setTheaterSpaces: (state, action: PayloadAction<string[]>) => {
      state.theaterSpaces = action.payload;
    },
    setIsInHostOnboarding: (state, action: PayloadAction<boolean>) => {
      state.isInHostOnboarding = action.payload;
    },
    resetTheater: () => initialState,
  },
});

export const {
  setCurrentTheater,
  setTheaterSpaces,
  resetTheater,
  setIsInHostOnboarding,
} = theaterSlice.actions;

export default theaterSlice.reducer;
