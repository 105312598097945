import { useEffect, useState, useCallback } from "react";

const ignoreRoutes = ["event/details"];

const isIgnoredRoute = () => {
  const { pathname } = window.location;

  return ignoreRoutes.some((route) => pathname.includes(route));
};

const isIgnoredHtmlTarget = (target: EventTarget | null) => {
  if (!target) return false;

  if (
    target instanceof HTMLInputElement ||
    target instanceof HTMLTextAreaElement
  ) {
    return true;
  }
  return false;
};

const useKeyPressedAndHeld = (keyCode: string) => {
  const [isKeyPressedAndHeld, setIsKeyPressedAndHeld] = useState(false);
  const [didPressOnce, setDidPressOnce] = useState(false);

  const downHandler = useCallback(
    (e: KeyboardEvent) => {
      if (!didPressOnce) setDidPressOnce(true);
      if (
        e.code === keyCode &&
        !isIgnoredHtmlTarget(e.target) &&
        !isIgnoredRoute()
      ) {
        e.preventDefault();
        setIsKeyPressedAndHeld(true);
      }
    },
    [keyCode, didPressOnce],
  );

  const upHandler = useCallback(
    (e: KeyboardEvent) => {
      if (e.code === keyCode) {
        setIsKeyPressedAndHeld(false);
      }
    },
    [keyCode],
  );

  useEffect(() => {
    window.addEventListener("keydown", downHandler);
    window.addEventListener("keyup", upHandler);
    return () => {
      window.removeEventListener("keydown", downHandler);
      window.removeEventListener("keyup", upHandler);
    };
  }, [downHandler, upHandler]);

  return { isKeyPressedAndHeld, didPressOnce };
};

export default useKeyPressedAndHeld;
