export const EVENT_TICKETS_QUERY_KEY = "event-tickets";

export enum TICKET_STATUS {
  ACTIVE = "active",
  INACTIVE = "inactive",
  SOLD_OUT = "sold-out",
  UPCOMING = "upcoming",
}

export enum TICKET_PRICE {
  FREE = "free",
  PAID = "paid",
}

export const remoTicketFee = "USD 0.99";
export const stripeTicketFee = "2.9% + USD 0.3";
