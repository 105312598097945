import * as Immutable from "immutable";
import Batcher from "batcher-js";
import { selectUser } from "modules/auth/redux/selectors";
import { receivedMessages } from "modules/chat/redux/actions";
import { getCurrentUserId, getReducerState } from "helpers/reduxHelper";
import { playMessageSound } from "helpers/audioHelper";
import {
  NOTIFICATION_TYPE,
  sendBrowserNotification,
} from "services/pushNotificationService/BrowserNotificationsService";
import { useSelector } from "react-redux";
import { useAppDispatch } from "store/hooks";
import { getUserName } from "modules/userProfile";
import { DocumentChange, DocumentData } from "firebase/firestore";
import { IChannel, IChat } from "../types";
import {
  CHAT_MESSAGE_TYPE,
  CHAT_MESSAGE_TYPES,
  CHAT_TYPE,
  CLIENT_MESSAGE,
} from "../redux/constants";

interface IChatPayload {
  messages: IChat[];
  channel: string;
  isNewMessage: boolean;
}

const useChatOnMessage = () => {
  const user = useSelector(selectUser);
  const dispatch = useAppDispatch();

  const onReceivingMessage = (data: DocumentChange<DocumentData>[]) => {
    const userId = user?.id;

    data.forEach((change) => {
      const chat = change.doc.data();

      if (change.type === "added") {
        chat.sentByMe = userId === chat.authorId;
        chat.id = change.doc.ref.id;
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        handleMessage(chat, chat.roomId);
      }
    });
  };

  const chatsByChannel = (payloads: IChatPayload[]) =>
    payloads.reduce(
      (
        acc: Record<string, { msgs: IChat[]; isNewMessage: boolean }>,
        payload,
      ) => {
        acc[payload.channel] = acc[payload.channel] || {
          msgs: [],
          isNewMessage: payload.isNewMessage,
        };
        acc[payload.channel].msgs = acc[payload.channel].msgs.concat(
          payload.messages,
        );

        return acc;
      },
      {},
    );

  const dispatchChatMessages = (payloads: IChatPayload[]) => {
    const messagesByChannel = chatsByChannel(payloads);

    Object.keys(messagesByChannel).forEach((channelId) => {
      dispatch(
        receivedMessages(
          Immutable.List<IChat>(messagesByChannel[channelId].msgs),
          channelId,
          messagesByChannel[channelId].isNewMessage,
        ),
      );
    });
  };

  const throttledChatUpdate = ({
    messages,
    channel,
    isNewMessage,
  }: IChatPayload) => {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    batch({ messages, channel, isNewMessage });
  };
  const batch = Batcher(dispatchChatMessages, { interval: 500, maximum: 10 });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleMessage = (msg: any, channelId: string) => {
    if (msg.action === CLIENT_MESSAGE) {
      throttledChatUpdate({
        messages: [msg],
        channel: channelId,
        isNewMessage: true,
      });
      const currentUserId = getCurrentUserId();

      if (!currentUserId || msg.authorId === currentUserId) {
        return;
      }
      // Getting from store because of scope issue in callback
      const { channels, activeChannel } = getReducerState("chatV2");
      const channel: IChannel | undefined = channels.get(msg.roomId);

      if (!channel) {
        return;
      }

      // Dont play sound for general chat messages
      if (
        msg.type !== CHAT_MESSAGE_TYPE &&
        channel.type !== CHAT_TYPE.THEATER &&
        channel.id !== activeChannel
      ) {
        playMessageSound();
      }

      // RC-2020 - dont send in app notification for general chat
      if (channel.type !== CHAT_TYPE.THEATER) {
        let msgBody = "";
        let msgText = msg.text;
        const msgImage =
          msg.meta && msg.meta.mime.indexOf("image") === 0
            ? msg.meta.url
            : null;
        const inChannel = channel && channel.name ? `In ${channel.name}, ` : "";
        const usersInEvent = getReducerState("usersInEvent").users;
        const author = usersInEvent.get(msg.authorId);
        const authorName = getUserName(author);

        switch (msg.type) {
          case CHAT_MESSAGE_TYPES.FILE:
            msgBody = `${inChannel}${authorName} uploaded ${msg.text}`;
            msgText = "";
            break;
          case CHAT_MESSAGE_TYPES.TEXT:
          case CHAT_MESSAGE_TYPES.EMOJI:
            msgBody = `${inChannel}${authorName} says`;
            break;
          case CHAT_MESSAGE_TYPES.GIF:
            msgBody = `${inChannel}${authorName} sent a GIF`;
            break;
          case CHAT_MESSAGE_TYPE:
            msgText = `${msg.text} ${channel.name}`;
            break;
          default:
            break;
        }

        sendBrowserNotification(msgBody, {
          body: msgText,
          image: msgImage,
          data: {
            type: NOTIFICATION_TYPE.CHAT,
            channel: channel.id,
            messageType: msg.type,
          },
        });
      }
    }
  };

  return {
    onReceivingMessage,
    throttledChatUpdate,
  };
};

export default useChatOnMessage;
