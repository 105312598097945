import store from "store";
import { addDialogNotification } from "modules/dialogNotification/redux/dialogNotificationSlice";
import { isTypeOfIMapTemplate } from "modules/event/template";
import { TFunction } from "i18next";
import { ref, onValue, off } from "firebase/database";
import { database } from "services/firebaseService/firebaseConfig";
import { getReducerState } from "helpers/reduxHelper";
import { getCurrentTheaterId } from "./theater.helper";
import { setCurrentTheater, setTheaterSpaces } from "./theaterSlice";

export const THEATER_PATH = "Theater";

export const getTheaterDocRef = (theaterId: string) =>
  ref(database, `${THEATER_PATH}/${theaterId}/`);

const subscribeTheaterSpaces = () => {
  const spacesRef = ref(
    database,
    `${THEATER_PATH}/${getCurrentTheaterId()}/Spaces`,
  );

  onValue(spacesRef, (spacesResult) => {
    const spaces = spacesResult.val();

    if (spaces) {
      store.dispatch(setTheaterSpaces(spaces));
    }
  });
};
const unsubscribeTheaterSpaces = () => {
  const spacesRef = ref(
    database,
    `${THEATER_PATH}/${getCurrentTheaterId()}/Spaces`,
  );

  off(spacesRef);
  store.dispatch(setTheaterSpaces([]));
};

const subscribeTheaterDetails = (t: TFunction) => {
  const detailsRef = ref(
    database,
    `${THEATER_PATH}/${getCurrentTheaterId()}/Details`,
  );

  onValue(detailsRef, (theaterResult) => {
    const theater = theaterResult.val();

    if (!theater) {
      return;
    }
    const { currentTheater } = getReducerState("theater");

    if (currentTheater) {
      const currentTheaterTemplate = currentTheater.template;

      if (!isTypeOfIMapTemplate(currentTheaterTemplate)) {
        return;
      }

      // Since only few fields from template will be synched, we do this separately
      if (
        currentTheaterTemplate.code === theater.template.code &&
        (!theater.theme || theater.theme === currentTheater.theme)
      ) {
        theater.template = {
          ...currentTheaterTemplate,
          ...theater.template,
        };
        store.dispatch(setCurrentTheater({ ...currentTheater, ...theater }));
      } else {
        // Since the template is changed, ask user to refresh the page
        store.dispatch(
          addDialogNotification({
            message: t("refresh.for.updated.floorplan.message"),
            onConfirm: () => window.location.reload(),
            confirmText: t("refresh"),
            hideDismissButton: true,
          }),
        );
      }
    }
  });
};

const unsubscribeTheaterDetails = () => {
  const detailsRef = ref(
    database,
    `${THEATER_PATH}/${getCurrentTheaterId()}/Details`,
  );

  off(detailsRef);
};

export const initTheaterFirebase = (t: TFunction) => {
  subscribeTheaterDetails(t);
  subscribeTheaterSpaces();
};

export const unsubscribeTheaterFirebase = () => {
  unsubscribeTheaterDetails();
  unsubscribeTheaterSpaces();
};
