import { IEvent } from "modules/event/types";
import { sendPostRequest } from "services/apiService";
import { EventRole } from "modules/event/constants";
import {
  IInviteSpeakerParams,
  IInviteAttendeeParams,
  ICheckInviteCodeParams,
} from "./types";

interface IJoinEventByCode {
  eventCode: string;
}

export type PostJoinEventByCodeResponse =
  | {
      isSuccess: true;
      code: string;
    }
  | {
      isSuccess: false;
      message: string;
    };

const joinEventByCode = (data: IJoinEventByCode) =>
  sendPostRequest<typeof data, PostJoinEventByCodeResponse>(
    "/event-member/join",
    data,
  );

const inviteSpeaker = (data: IInviteSpeakerParams) =>
  sendPostRequest<IInviteSpeakerParams, IGenericAPIResponse>(
    `/event-member/${data.eventId}/invite-speaker`,
    data,
  );

const inviteAttendee = (data: IInviteAttendeeParams) =>
  sendPostRequest<IInviteAttendeeParams, IGenericAPIResponse>(
    `/event-member/${data.eventId}/invite-attendee`,
    data,
  );

interface IRemoveAtendee {
  attendeeId: string;
  inviteId: string;
  eventId: string;
}
const removeAttendee = (data: IRemoveAtendee) =>
  sendPostRequest<typeof data, IGenericAPIResponse>(
    `/event-member/${data.eventId}/attendee/remove`,
    data,
  );

const checkInviteCode = (data: ICheckInviteCodeParams) =>
  sendPostRequest<
    ICheckInviteCodeParams,
    APIResponse<{
      event?: IEvent;
      isInvitee?: boolean;
      email?: string;
      role?: EventRole;
      inviteeLoginMethods?: Record<string, string>;
    }>
  >("/event-member/check-invite-code", data);

export default {
  checkInviteCode,
  inviteSpeaker,
  inviteAttendee,
  removeAttendee,
  joinEventByCode,
};
