import { useCallback, useEffect } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "store/hooks";
import { Chat as ChatIcon } from "@remo-co/ui-core/src/icons/Chat";
import { selectIsInBroadcast } from "modules/broadcast/redux/selectors";
import { useMediaQuery } from "@remo-co/ui-core/src/hooks/useMediaQuery";
import { lightTheme } from "@remo-co/ui-core/src/theme/lightTheme";
import useNotificationActions from "modules/notification/hooks/useNotificationActions";
import { useAppMessage } from "@daily-co/daily-react";
import {
  CustomAppEvent,
  CustomAppEvents,
} from "modules/audioVideo/DailyEvents";
import { EventPollStatus } from "graphql/generated";
import { selectIsEventManager } from "modules/event/selectors";
import { PollsNotification } from "modules/eventButtons/components/PollsButton/PollsNotification";
import { TABS, selectRightPanelState, setPanelState } from "../../redux";
import { RightPanel } from "../RightPanel";
import useStyles from "./styles";

const RightPanelContainer = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const { isOpen, tabId, pollsTabId } = useSelector(selectRightPanelState);
  const isEventManager = useSelector(selectIsEventManager);
  const isInPresentationMode = useSelector(selectIsInBroadcast);
  const isDesktop = useMediaQuery(lightTheme.breakpoints.up("sm"));
  const styles = useStyles();
  const { addInfoNotification } = useNotificationActions();

  useAppMessage<CustomAppEvent>({
    onAppMessage: (event) => {
      const notInOngoingTab = isOpen && pollsTabId !== EventPollStatus.Ongoing;
      const notInPollsTab = isOpen && tabId !== TABS.POLLS;

      if (
        event.data.event === CustomAppEvents.POLL_QUIZ_LAUNCHED &&
        !isEventManager &&
        (!isOpen || notInOngoingTab || notInPollsTab)
      ) {
        addInfoNotification({
          message: <PollsNotification pollType={event.data.pollType} />,
          position: "tr",
        });
      }
    },
  });

  useEffect(() => {
    if (isDesktop) {
      dispatch(
        setPanelState({
          isOpen: Boolean(isInPresentationMode),
          tabId,
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, isDesktop, isInPresentationMode]);

  const onHideRightPanel = useCallback(() => {
    dispatch(
      setPanelState({
        isOpen: false,
        tabId,
      }),
    );
  }, [dispatch, tabId]);

  const openRightPanel = useCallback(() => {
    dispatch(
      setPanelState({
        isOpen: true,
        tabId,
      }),
    );
  }, [dispatch, tabId]);

  return (
    <>
      {!isOpen && isInPresentationMode && (
        <button
          type="button"
          data-testid="open-panel-button"
          className={styles.rightPanelButton}
          onClick={openRightPanel}
        >
          <span className={styles.chatIcon}>
            <ChatIcon />
          </span>
        </button>
      )}
      <RightPanel onHideRightPanel={onHideRightPanel} />
    </>
  );
};

export default RightPanelContainer;
