import { sendDeleteRequest, sendPostRequest } from "services/apiService";

export const getRequestURL = (eventId: string) =>
  `/event/${eventId}/table-setting-presets`;
export const getExistingPresetRequestURL = (
  eventId: string,
  presetId: string,
) => `/event/${eventId}/table-setting-presets/${presetId}`;

export const createPresetRequest = async (eventId: string): Promise<string> => {
  const resp = await sendPostRequest<
    {},
    APIResponse<{
      preset: string;
    }>
  >(getRequestURL(eventId), {});

  if (!resp.isSuccess) {
    throw new Error(resp?.message);
  }

  return resp.preset;
};

export const deletePresetRequest = async (
  eventId: string,
  presetId: string,
): Promise<boolean> => {
  const resp = await sendDeleteRequest<
    Record<string, never>,
    IGenericAPIResponse
  >(getExistingPresetRequestURL(eventId, presetId));

  if (!resp.isSuccess) {
    throw new Error(resp?.message);
  }

  return true;
};
