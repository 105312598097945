import { useI18n } from "i18n";
import useNotificationActions from "../../notification/hooks/useNotificationActions";
import { updateRoomName } from "../../../services/apiService/apis";
import logger from "../../../logging/logger";

const useRoomSettings = () => {
  const { addErrorNotification, addSuccessNotification } =
    useNotificationActions();
  const { t } = useI18n();

  const changeRoomName = async (
    roomId: string,
    roomName: string,
    isForAllRooms: boolean,
    code: string,
    eventId: string,
    // eslint-disable-next-line max-params
  ) => {
    const res = await updateRoomName(
      roomId,
      roomName,
      isForAllRooms,
      code,
      eventId,
    );

    if (res.isSuccess) {
      logger.info(
        "[RoomSaga][changeRoomName] Success to send change table name api.",
      );
      addSuccessNotification({
        message: t("event:table.name.changed"),
      });
    } else {
      logger.error(
        `[RoomSaga][changeRoomName] Fail to change table name. Reason: ${res.message}.`,
      );
      addErrorNotification({
        message: "Error",
      });
    }
  };

  return {
    changeRoomName,
  };
};

export default useRoomSettings;
