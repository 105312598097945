import { Theme } from "@remo-co/ui-core/src/types";
import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";

const useStyles = makeStyles<Theme>((theme) => ({
  container: {
    height: "100%",
    overflow: "hidden",
    display: "flex",
    flexDirection: "column",
    color: theme.palette.text.primary,
    "& .MuiInput-underline": {
      "&:before,&:after": {
        content: "none",
      },
    },
    "& .MuiListItemText-root, & .MuiTypography-body2": {
      fontSize: "16px !important",
      fontWeight: "400 !important",
    },
  },
  scrollableView: {
    "&::-webkit-scrollbar": {
      width: "10px",
    },
    "&::-webkit-scrollbar-track": {
      background: "var(--White-op-030)",
      borderRadius: "5px",
    },
    "&::-webkit-scrollbar-thumb": {
      background: "#45475e",
      borderRadius: "5px",
    },
    scrollbarColor: "#45475e var(--White-op-030)",
  },
}));

export default useStyles;
