import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export interface ConversationBroadcastReducerState {
  sessionId?: string | null;
  sender?: string | null;
  sentAt?: number;
  channel: string | null;
  volume?: number;
}

export const initialState: ConversationBroadcastReducerState = {
  channel: null,
  sessionId: null,
  sender: null,
  sentAt: undefined,
  volume: 50,
};

const ConversationBroadcastSlice = createSlice({
  name: "conversationBroadcast",
  initialState,
  reducers: {
    startConversationBroadcast: (
      state: ConversationBroadcastReducerState,
      {
        payload,
      }: PayloadAction<{
        sessionId: string;
        sender: string;
        sentAt: number;
      }>,
    ) => ({
      ...state,
      ...payload,
    }),
    setupConversationBroadcast: (
      state: ConversationBroadcastReducerState,
      {
        payload,
      }: PayloadAction<{
        channel: string;
      }>,
    ) => ({
      ...state,
      ...payload,
    }),
    setConversationBroadcastVolume: (
      state: ConversationBroadcastReducerState,
      { payload }: PayloadAction<number>,
    ) => ({
      ...state,
      volume: payload,
    }),
    resetConversationBroadcast: () => initialState,
  },
});

export const {
  startConversationBroadcast,
  setupConversationBroadcast,
  resetConversationBroadcast,
  setConversationBroadcastVolume,
} = ConversationBroadcastSlice.actions;

export default ConversationBroadcastSlice.reducer;
