import { memo, useCallback, useRef } from "react";
import { useSelector } from "react-redux";
import Avatar from "modules/userAvatar/Avatar";
import {
  CHAT_MESSAGE_TYPE,
  CHAT_MESSAGE_TYPES,
  CHAT_TYPE,
} from "modules/chat/redux/constants";
import classNames from "classnames";
import logger from "logging/logger";
import { makeSelectUserById } from "modules/event/usersInEvent/selectors";
import { selectUser } from "modules/auth/redux/selectors";
import { selectIsInBroadcast } from "modules/broadcast/redux/selectors";
import { IChat } from "modules/chat/types";
import { IUser } from "modules/app/types";
import { GifMessage } from "modules/chat/components/messagesView/types/GifMessage";
import { useAppDispatch } from "store/hooks";
import { openUserProfileCard } from "modules/userProfileCard/redux/slice";
import FileMessage from "./types/FileMessage";
import { TextMessage } from "./types/TextMessage";
import EmojiMessage from "./types/EmojiMessage";
import ActionMessage from "./types/ActionMessage";
import DeleteMessageButton from "./DeleteMessageButton";
import { useStyles } from "./styles";

interface IMessageProps {
  message: IChat;
  onImageClick: (url: string) => void;
  onGifLoaded: () => void;
  hasEventManagerAccess?: boolean;
  channelType: string;
}

export interface IMessageTypeProps {
  message: IChat;
  isMe?: boolean;
  onImageClick?: (url: string) => void;
  author: IUser;
}

const Message = memo<IMessageProps>(
  (props: IMessageProps) => {
    const {
      message,
      onImageClick,
      onGifLoaded,
      hasEventManagerAccess = false,
      channelType,
    } = props;
    const user = useSelector(selectUser);
    const isInBroadcast = useSelector(selectIsInBroadcast);
    const author = useSelector(makeSelectUserById(message.authorId));
    const classes = useStyles({ isInBroadcast: Boolean(isInBroadcast) });
    const dispatch = useAppDispatch();

    const avatarRef = useRef<HTMLDivElement>(null);
    const onAvatarClick = useCallback(() => {
      dispatch(
        openUserProfileCard({ userId: message.authorId, anchorEl: avatarRef }),
      );
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);

    if (!author) {
      logger.warn("[Message] no author.", { message });

      return null;
    }

    const renderMessageOfType = (type: CHAT_MESSAGE_TYPES, isMe: boolean) => {
      if (isMe && type === CHAT_MESSAGE_TYPES.ACTION) {
        return;
      }

      switch (type) {
        case CHAT_MESSAGE_TYPES.TEXT:
          // eslint-disable-next-line consistent-return
          return <TextMessage message={message} isMe={isMe} author={author} />;
        case CHAT_MESSAGE_TYPES.EMOJI:
          // eslint-disable-next-line consistent-return
          return <EmojiMessage message={message} isMe={isMe} author={author} />;
        case CHAT_MESSAGE_TYPES.GIF:
          // eslint-disable-next-line consistent-return
          return (
            <GifMessage
              message={message}
              isMe={isMe}
              author={author}
              onGifLoaded={onGifLoaded}
            />
          );
        case CHAT_MESSAGE_TYPES.ACTION:
          // eslint-disable-next-line consistent-return
          return (
            <ActionMessage message={message} isMe={isMe} author={author} />
          );
        case CHAT_MESSAGE_TYPES.FILE:
          // eslint-disable-next-line consistent-return
          return (
            <FileMessage
              onImageClick={onImageClick}
              message={message}
              isMe={isMe}
              author={author}
            />
          );
        default:
      }
    };

    const currentUserId = user?.id;

    const shouldRenderAvatar =
      message.type !== CHAT_MESSAGE_TYPE && message.authorId !== currentUserId;

    const shouldRenderDeleteButton =
      hasEventManagerAccess &&
      (channelType === CHAT_TYPE.THEATER || channelType === CHAT_TYPE.ROOM);

    return (
      <div className={classes.messageContainer}>
        <div
          className={classNames(classes.messageContent, {
            [classes.sent]: message.authorId === currentUserId,
          })}
        >
          <span ref={avatarRef} className={classes.avatarContainer}>
            {shouldRenderAvatar && (
              <Avatar
                className={classes.avatar}
                user={author}
                onClick={onAvatarClick}
                size="sm"
              />
            )}
          </span>

          {renderMessageOfType(
            message.type,
            message.authorId === currentUserId,
          )}

          {shouldRenderDeleteButton && (
            <DeleteMessageButton
              message={message}
              isMe={message.authorId === currentUserId}
            />
          )}
        </div>
      </div>
    );
  },
  (nextProps, prevProps) => nextProps.message.id === prevProps.message.id,
);

export default Message;
