import { Box } from "@remo-co/ui-core/src/components/Box";
import { maxContentWidth } from "@remo-co/ui-core/src/constants";
import { ManageEventContent } from "../ManageEventContent";
import "./styles.scss";

const ManageEvent = (): JSX.Element => (
  <Box
    boxSizing="border-box"
    maxWidth={maxContentWidth}
    mx="auto"
    px={3}
    width="100%"
    height="100vh"
    className="manage-event-container"
  >
    <Box>
      <ManageEventContent />
    </Box>
  </Box>
);

export default ManageEvent;
