// Firebase Storage Paths.
export const SPACE_PATH = "Space";

export const EVENT_PATH = "Event";

export const USER_STATUS_PATH = "UserStatus";

export const USER_STATE_PATH = "UserState";

export const USER_PATH = "User";

export const ROOM_PATH = "Room";

export const INVITE_PATH = "Invite";

export const PROFILE_IMAGE = "ProfileImage";

export const VIRTUAL_BACKGROUND = "VirtualBackground";

export const CHAT_MEDIA = "ChatMedia";

export const COMPANY_LOGO = "CompanyLogo";

export const COMPANY_BADGE_IMAGE = "CompanyBadgeImage";

export const EVENT_MEDIA = "EventMedia";

export const EVENT_LOGO = "EventLogo";

export const EVENT_CUSTOM_BRANDING = "EventCustomBranding";

export const EVENT_SPONSOR = "EventSponsor";

export const LOCK_TABLE = "LockTable";

export const FLOOR_PLAN = "FloorPlan";

export const EVENT_CONTENT = "EventContent";

export const LIVE_STREAM_DESTINATIONS = "LiveStreamDestinations";

export const AGENCY_WHITE_LABEL_LOGO = "AgencyWhiteLabelLogo";
export const AGENCY_WHITE_LABEL_FAVICON = "AgencyWhiteLabelFavicon";
export const AGENCY_WHITE_LABEL_SIGN_IN_BACKGROUND =
  "AgencyWhiteLabelSignInBackground";

export enum UPLOAD_LEVEL {
  GLOBAL,
  COMPANY,
  USER,
  EVENT,
  LAYOUT,
}
