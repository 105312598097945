export const FORCED_DESKTOP_EXPERIENCE = "forcedDesktopExperience";

export const FORCED_MOBILE_EXPERIENCE = "forcedMobileExperience";

const SMARTPHONES_OR_TOUCHSCREENS = "(pointer: coarse)";

const STYLUS_SCREENS = "(hover: none) and (pointer: fine)";

export const MOBILE_DEVICE_MEDIA_QUERY = `@media screen, ${SMARTPHONES_OR_TOUCHSCREENS}, ${STYLUS_SCREENS}`;

const isForcedDesktopExperience = () =>
  localStorage.getItem(FORCED_DESKTOP_EXPERIENCE) === "true";

const isForcedMobileExperience = () =>
  localStorage.getItem(FORCED_MOBILE_EXPERIENCE) === "true";

export const isMobileDevice = () => {
  if (isForcedDesktopExperience()) {
    return false;
  }

  if (isForcedMobileExperience()) {
    return true;
  }

  return window.matchMedia(MOBILE_DEVICE_MEDIA_QUERY).matches;
};
