import { useEffect, useMemo } from "react";
import {
  selectActiveTile,
  selectLayout,
} from "modules/audioVideo/redux/selectors";
import { useLocalSessionId, useMeetingState } from "@daily-co/daily-react";
import { DisplayLayout } from "modules/audioVideo";
import { useSelector } from "react-redux";
import { selectIsMobile } from "modules/deviceInfo";
import { useDailyApi } from "../../useDailyApi";
import { usePerformanceHelpers } from "../usePerformanceHelpers";

export const TileReceiveSettings = () => {
  const layout = useSelector(selectLayout);
  const activeTile = useSelector(selectActiveTile);
  const { activeParticipantId, loadTileModeReceiveSettings } =
    usePerformanceHelpers();
  const isMobile = useSelector(selectIsMobile);
  const { updateReceiveSettings } = useDailyApi();
  const localSessionId = useLocalSessionId();
  const meetingState = useMeetingState();

  const isInMeeting = useMemo(() => {
    if (meetingState === "joined-meeting") {
      return true;
    }
    return false;
  }, [meetingState]);

  const isLowQuality = useMemo(
    () =>
      layout === DisplayLayout.Map ||
      (activeParticipantId === localSessionId &&
        layout === DisplayLayout.Focus),
    [layout, activeParticipantId, localSessionId],
  );

  useEffect(() => {
    if (isInMeeting) {
      if (isLowQuality) {
        updateReceiveSettings({
          "*": { video: { layer: 0 } },
        });
      }

      if (
        activeParticipantId &&
        activeParticipantId !== localSessionId &&
        layout === DisplayLayout.Focus
      ) {
        updateReceiveSettings({
          "*": { video: { layer: 0 } },
          [activeParticipantId]: {
            video: { layer: isMobile ? 1 : 2 },
          },
        });
      }

      if (layout === DisplayLayout.Tile) {
        updateReceiveSettings({ "*": loadTileModeReceiveSettings });
      }
    }
  }, [
    isLowQuality,
    activeParticipantId,
    localSessionId,
    layout,
    updateReceiveSettings,
    isMobile,
    loadTileModeReceiveSettings,
    activeTile?.streamId,
    isInMeeting,
  ]);

  return null;
};
