export enum ActivityStatus {
  ACTIVE = "active",
  AWAY = "away",
  FOCUSING = "focusing",
}

export interface IEventActivityStatus {
  status: ActivityStatus;
  label: string;
}

export type EventActivityStatusUserMap = Record<string, IEventActivityStatus>;
