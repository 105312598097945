import { Theme } from "@mui/material/styles";

import makeStyles from "@mui/styles/makeStyles";

export const useStyles = makeStyles<Theme>((theme) => ({
  description: {
    color: `${theme.palette.blue.darkShade1}`,
    margin: "0",
  },
  errorDescription: {
    color: `${theme.palette.red.main}`,
  },
}));
