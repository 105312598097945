import { sendBuffer } from "modules/transcription";

const convertTo16BitPayload = (event: AudioProcessingEvent) =>
  event.inputBuffer.getChannelData(0) as Float32Array;

export const handleAudioProcess = (
  event: AudioProcessingEvent,
  websocket?: WebSocket,
): void => {
  if (!websocket || websocket.readyState !== WebSocket.OPEN) {
    return;
  }
  const inputData = convertTo16BitPayload(event);

  const targetBuffer = new Int16Array(inputData.length);

  inputData.forEach((inputChunk, index) => {
    targetBuffer[index] = 32767 * Math.min(1, inputChunk);
  });

  sendBuffer(targetBuffer.buffer, websocket);
};
