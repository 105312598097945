import { getVar } from "config";

export const API_PATH = "/api/internal";

const getApiHostName = (): URL => {
  const host = getVar("REACT_APP_PREVIEW_HOST") || getVar("REACT_APP_API_HOST");
  return new URL(host);
};

const getAPIHost = (): URL["host"] => new URL(API_PATH, getApiHostName()).host;
const getAPIUrl = (): URL["href"] => new URL(API_PATH, getApiHostName()).href;
const getAPIOrigin = (): URL["origin"] =>
  new URL(API_PATH, getApiHostName()).origin;

export { getAPIHost, getAPIUrl, getAPIOrigin };
