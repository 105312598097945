import { RootState } from "store";
import { createSelector } from "@reduxjs/toolkit";

export const audioReactions = (state: RootState) => state.audioReaction;

export const selectOpenReactionDialog = createSelector(
  audioReactions,
  (audioReaction) => audioReaction.showDialog,
);

export const selectCurrentAudio = createSelector(
  audioReactions,
  (audioReaction) => audioReaction.currentAudio,
);
