import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import {
  IEventDirectoryState,
  IEventDirectoryFirestoreDoc,
  IEventDirectory,
} from "../types";

export const initialState: IEventDirectoryState = {
  current: [],
  company: [],
};

const eventDirectorySlice = createSlice({
  initialState,
  name: "eventDirectories",
  reducers: {
    // current
    loadCurrentDirectories: (
      state,
      action: PayloadAction<IEventDirectoryFirestoreDoc[]>,
    ) => ({ ...state, current: action.payload }),
    resetCurrentDirectories: (state) => ({ ...state, current: [] }),
    // company
    loadCompanyDirectories: (
      state,
      action: PayloadAction<IEventDirectory[]>,
    ) => ({ ...state, company: action.payload }),
    updateCurrentEventDirectories: (
      state,
      action: PayloadAction<IEventDirectoryFirestoreDoc>,
    ) => {
      const updatedCurrentState = [
        ...state.current.filter(
          (directory: IEventDirectoryFirestoreDoc) =>
            directory.id !== action.payload.id,
        ),
        action.payload,
      ];
      return {
        ...state,
        current: updatedCurrentState,
      };
    },
  },
});

export const {
  resetCurrentDirectories,
  loadCurrentDirectories,
  loadCompanyDirectories,
  updateCurrentEventDirectories,
} = eventDirectorySlice.actions;

export default eventDirectorySlice.reducer;
