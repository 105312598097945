import { KeyboardEvent, useContext, useState } from "react";
import { useSelector } from "react-redux";
import classNames from "classnames";
import { Button } from "@remo-co/ui-core/src/components/Button";
import { IconButtonWithTooltip } from "@remo-co/ui-core/src/components/IconButtonWithTooltip";
import { ClickAwayListener } from "@remo-co/ui-core/src/components/ClickAwayListener";
import { Dialog } from "@remo-co/ui-core/src/components/Dialog";
import { DialogTitle } from "@remo-co/ui-core/src/components/DialogTitle";
import { DialogContent } from "@remo-co/ui-core/src/components/DialogContent";
import { DialogActions } from "@remo-co/ui-core/src/components/DialogActions";
import { Typography } from "@remo-co/ui-core/src/components/Typography";
import { Close } from "@remo-co/ui-core/src/icons/Close";
import { useI18n } from "i18n";
import { ContentWithMapping } from "graphql/generated";
import { ISlot } from "modules/theater/types";
import { selectCurrentEventId } from "modules/event/selectors";
import { selectUserEmail } from "modules/auth/redux/selectors";
import { Events, TRACKING_CONTEXT } from "modules/tracking";
import useEnterLeaveEvent from "modules/event/hooks/useEnterLeaveEvent";
import { isAutojoinURL } from "../../utils";
import { DialogMedia } from "../DialogMedia";
import { useStyles } from "./styles";

interface Props {
  content: ContentWithMapping;
  slot: ISlot;
  preview?: boolean;
}

export const PopupContent = ({ content, slot, preview }: Props) => {
  const { track } = useContext(TRACKING_CONTEXT);
  const userEmail = useSelector(selectUserEmail);
  const currentEventId = useSelector(selectCurrentEventId);
  const { leaveEvent } = useEnterLeaveEvent(currentEventId);
  const [isPopupVisible, setPopupVisible] = useState(false);
  const { t } = useI18n(["common", "template"]);
  const styles = useStyles();

  const openPopup = () => {
    if (!preview) {
      track(Events.CONTENT_BANNER_CLICKED, {
        email: userEmail,
        eventId: currentEventId,
        contentId: content.id,
        name: content.name,
      });
    }

    setPopupVisible(true);
  };

  const closePopup = () => {
    setPopupVisible(false);
  };

  const handleCTAClick = () => {
    if (!preview) {
      track(Events.CONTENT_BANNER_CTA_CLICKED, {
        email: userEmail,
        eventId: currentEventId,
        contentId: content.id,
        url: content.ctaLink,
        name: content.name,
      });

      if (isAutojoinURL(content.ctaLink)) {
        leaveEvent();
      }
    }
  };

  const handleKeyPress = (e: KeyboardEvent) => {
    if (e.code !== "Enter") {
      return;
    }

    openPopup();
  };

  return (
    <>
      <div
        data-testid={`popup-content-${content.id}`}
        key={content.id}
        className={classNames(styles.root, {
          [styles.preview]: preview,
        })}
        onClick={openPopup}
        onKeyDown={handleKeyPress}
        role="button"
        tabIndex={0}
        style={{
          left: slot.x ?? "unset",
          top: slot.y ?? "unset",
          width: slot.width,
          height: slot.height,
        }}
      >
        <img
          src={content.floorImage}
          alt={content.name}
          className={styles.floorImage}
        />
      </div>
      {isPopupVisible && (
        <ClickAwayListener onClickAway={closePopup}>
          <Dialog
            data-testid="popup-dialog"
            onClose={closePopup}
            aria-labelledby={content.name}
            open
            scroll="paper"
            fullWidth
            maxWidth="sm"
          >
            <DialogTitle className={styles.header}>
              <Typography variant="h2" className={styles.title}>
                {content.name}
              </Typography>
              <IconButtonWithTooltip
                id="close-popup-dialog"
                title={t("common:button.close")}
                data-testid="cancel-button-icon"
                onClick={closePopup}
                size="small"
                className={styles.closeButton}
              >
                <Close />
              </IconButtonWithTooltip>
            </DialogTitle>
            <DialogContent className={styles.content}>
              <DialogMedia content={content} />
            </DialogContent>
            <DialogActions className={styles.actions}>
              <Button
                variant="text"
                color="dark"
                onClick={closePopup}
                className={styles.backToEventButton}
              >
                {t("template:back.event")}
              </Button>
              {content.ctaLink && content.ctaText && (
                <Button<"a">
                  variant="primary"
                  color="blue"
                  className={styles.ctaButton}
                  href={content.ctaLink}
                  onClick={handleCTAClick}
                  target={
                    preview || !isAutojoinURL(content.ctaLink)
                      ? "_blank"
                      : undefined
                  }
                  rel={
                    preview || !isAutojoinURL(content.ctaLink)
                      ? "noreferrer"
                      : undefined
                  }
                  data-testid="cta-button"
                >
                  {content.ctaText}
                </Button>
              )}
            </DialogActions>
          </Dialog>
        </ClickAwayListener>
      )}
    </>
  );
};
