import { hasAutoJoin } from "modules/queryParams/queryParamsHelper";
import { parseUrl } from "query-string";

export const isAutojoinURL = (ctaLink?: string) => {
  if (!ctaLink) {
    return false;
  }

  const { query, url } = parseUrl(ctaLink);
  return url.includes("remo.co/e") && hasAutoJoin(query);
};
