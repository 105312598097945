import {
  getPublicTemplates,
  getTemplateByCode,
  getTemplatesByCodes,
} from "./templates";

export default {
  getPublicTemplates,
  getTemplateByCode,
  getTemplatesByCodes,
};
