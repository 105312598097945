import { MIN_DOWNLOAD_SPEED_MBPS } from "modules/systemCheck/constants";

export const checkInternetConnection = (
  downloadSpeedMbps: number | null,
): boolean => {
  if (!downloadSpeedMbps) {
    return false;
  }

  return downloadSpeedMbps >= MIN_DOWNLOAD_SPEED_MBPS;
};
