import { ReactElement } from "react";
import { Box } from "@remo-co/ui-core/src/components/Box";
import { Typography } from "@remo-co/ui-core/src/components/Typography";
import { StarRounded } from "@remo-co/ui-core/src/icons/StarRounded";
import { useI18n } from "i18n";
import { useQuery } from "@apollo/client";
import { useStyles as useEventFormStyles } from "modules/eventForm/styles";
import { Errors, trackError } from "modules/monitoring";
import classNames from "classnames";
import {
  GetEventRatingQuery,
  GetEventRatingQueryVariables,
} from "graphql/generated";
import { useStyles } from "./styles";
import { GET_EVENT_RATING } from "./graphql/queries";

interface Props {
  eventId: string;
}

const GuestsEventRating = ({ eventId }: Props): ReactElement | null => {
  const efClasses = useEventFormStyles();
  const classes = useStyles();
  const { t } = useI18n(["eventForm"]);

  const { data, loading, error } = useQuery<
    GetEventRatingQuery,
    GetEventRatingQueryVariables
  >(GET_EVENT_RATING, {
    variables: { eventId },
  });

  if (loading) return null;

  if (error) {
    trackError(error, {
      label: Errors.GRAPHQL_NETWORK_ERROR,
      eventId,
    });

    return null;
  }

  if (!data?.ratings) return null;

  if (data.ratings.__typename === "GQLError") {
    trackError(data?.ratings.message, {
      label: Errors.GUEST_EXPERIENCE_SURVEY_GET_EVENT_RATING,
      eventId,
    });

    return null;
  }

  if (!data.ratings.rating) return null;

  const { rating } = data.ratings;

  return (
    <Box
      className={classNames(efClasses.box, classes.eventRating)}
      justifyContent="space-between"
    >
      <Typography variant="body1" className={classes.eventRatingText}>
        {t("guests.rated.event")}
      </Typography>
      <div className={classes.eventRatingResult}>
        <StarRounded
          className={classes.eventRatingStar}
          data-testid="rating-star"
        />
        <Typography
          data-testid="rating-result"
          variant="body1"
          component="span"
          dangerouslySetInnerHTML={{
            __html: t("guest.rating", {
              rating: `<strong class="${
                classes.eventRatingRating
              }">${rating.toFixed(2)}</strong>`,
              maxRating: 5,
            }),
          }}
        />
      </div>
    </Box>
  );
};

export default GuestsEventRating;
