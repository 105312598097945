import { KeyboardEvent, useContext } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "store/hooks";
import classNames from "classnames";
import { ContentWithMapping } from "graphql/generated";
import { ISlot } from "modules/theater/types";
import {
  DisplayItem,
  DisplayItemConfig,
  DisplayLayout,
} from "modules/audioVideo";
import { Events, TRACKING_CONTEXT } from "modules/tracking";
import { selectUserEmail } from "modules/auth/redux/selectors";
import { selectCurrentEventId } from "modules/event/selectors";
import { setActiveTile, setLayout } from "modules/audioVideo/redux/slice";
import { openFullScreenEventContent } from "../../redux";
import { useStyles } from "./styles";

interface Props {
  content: ContentWithMapping;
  slot: ISlot;
  preview?: boolean;
}

export const FullScreenContent = ({ content, slot, preview }: Props) => {
  const { track } = useContext(TRACKING_CONTEXT);
  const userEmail = useSelector(selectUserEmail);
  const currentEventId = useSelector(selectCurrentEventId);
  const dispatch = useAppDispatch();
  const styles = useStyles();

  const openDialog = () => {
    if (!preview) {
      track(Events.CONTENT_BANNER_CLICKED, {
        email: userEmail,
        eventId: currentEventId,
        contentId: content.id,
        name: content.name,
      });
      dispatch(openFullScreenEventContent(content));
      dispatch(setLayout(DisplayLayout.Focus));
      dispatch(
        setActiveTile({
          streamId: DisplayItemConfig.eventContentDisplayId,
          type: DisplayItem.eventContent,
        }),
      );
      dispatch(openFullScreenEventContent(content));
    }
    dispatch(setLayout(DisplayLayout.Focus));
    dispatch(
      setActiveTile({
        streamId: DisplayItemConfig.eventContentDisplayId,
        type: DisplayItem.eventContent,
      }),
    );
  };

  const handleKeyPress = (e: KeyboardEvent) => {
    if (e.code !== "Enter") {
      return;
    }

    openDialog();
  };

  return (
    <div
      data-testid={`fullscreen-content-${content.id}`}
      key={content.id}
      className={classNames(styles.root, {
        [styles.preview]: preview,
      })}
      onClick={openDialog}
      onKeyDown={handleKeyPress}
      role="button"
      tabIndex={0}
      style={{
        left: slot.x ?? "unset",
        top: slot.y ?? "unset",
        width: slot.width,
        height: slot.height,
      }}
    >
      <img
        src={content.floorImage}
        alt={content.name}
        className={styles.floorImage}
      />
    </div>
  );
};
