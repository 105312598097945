export const getType = (type: string) => type.split("/")[1];

export const isAudio = (type: string) => type.startsWith("audio");

export const getSize = (size: number) => {
  const fSExt = ["bytes", "KB", "MB", "GB", "TB", "PB"];
  let i = 0;
  let sizeInBytes = size;

  while (sizeInBytes > 900 && i < fSExt.length - 1) {
    sizeInBytes /= 1024;
    // eslint-disable-next-line no-plusplus
    i++;
  }

  return `${Math.floor(sizeInBytes * 100) / 100} ${fSExt[i]}`;
};

export const isImage = (type: string) => type.startsWith("image");
