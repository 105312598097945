import {
  ExtendedDailyParticipant,
  useParticipantIds,
  useScreenShare,
} from "@daily-co/daily-react";
import { DAILY_PLAYABLE_TRACK_STATES } from "modules/audioVideo/constants";

interface Props {
  participants: string[];
  isInBroadcast: boolean;
}

export const useCameraAndScreenTiles = ({
  participants,
  isInBroadcast,
}: Props) => {
  const { screens: allScreens } = useScreenShare();

  const screens = allScreens
    .filter(
      ({ local, screenAudio, screenVideo }) =>
        local ||
        // could be 'staged' but we don't want that
        screenAudio.subscribed === true ||
        screenVideo.subscribed === true,
    )
    .filter(({ local, session_id }) => {
      if (!isInBroadcast) {
        return local || (!local && participants.includes(session_id));
      }
      return participants.includes(session_id);
    })
    .map(({ screenId }) => screenId);

  const cameras = useParticipantIds({
    filter: ({ session_id, tracks }: ExtendedDailyParticipant) => {
      if (participants.includes(session_id)) {
        return !(
          !DAILY_PLAYABLE_TRACK_STATES.includes(tracks.video?.state) &&
          !DAILY_PLAYABLE_TRACK_STATES.includes(tracks.audio?.state) &&
          DAILY_PLAYABLE_TRACK_STATES.includes(tracks.screenVideo?.state)
        );
      }
      return false;
    },
  });

  return { screens, cameras };
};
