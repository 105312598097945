import { Transcription } from "@daily-co/daily-react";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { makeSelectUserById } from "modules/event/usersInEvent/selectors";
import { TranscriptionProfileInfo } from "modules/transcription";
import { selectIsMobile } from "modules/deviceInfo";
import { useTheme } from "@remo-co/ui-core/src/hooks/useTheme";
import { Typography } from "@remo-co/ui-core/src/components/Typography";
import useStyles from "./styles";

interface Props {
  message: Transcription;
}

export const TranscriptionFeedMessage = ({ message }: Props) => {
  const user = useSelector(makeSelectUserById(message.user_id));
  const isMobileView = useSelector(selectIsMobile);
  const theme = useTheme();
  const styles = useStyles();

  const [transcriptionText, setTranscriptionText] = useState("");

  const transcriptionTextId = `transcription-text-${message.user_id}`;

  useEffect(() => {
    const element = document.getElementById(transcriptionTextId);
    if (element && typeof theme.typography.h4.lineHeight === "number") {
      const maxHeight = theme.typography.h4.lineHeight * 14 * 4;
      element.style.maxHeight = `${maxHeight}px`;
    }
  }, [theme, transcriptionTextId]);

  useEffect(() => {
    if (message.text) {
      setTranscriptionText((prevText) =>
        prevText !== message.text ? message.text : prevText,
      );
    }
  }, [message.text]);

  return (
    <div className={styles.root} data-testid="transcription-message">
      <TranscriptionProfileInfo user={user} mobileView={isMobileView} />
      <div className={styles.textWrapper}>
        <div className={styles.text} id={transcriptionTextId}>
          <Typography variant="h4">{transcriptionText}</Typography>
        </div>
      </div>
    </div>
  );
};
