import { IEvent } from "modules/event/types";
import { timeDuration } from "./time/duration";

export const sleep = (milliseconds: number) => {
  const start = new Date().getTime();

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < 1e7; i++) {
    if (new Date().getTime() - start > milliseconds) {
      break;
    }
  }
};

export const getEventDurationInMinutes = ({
  startTime,
  endTime,
}: Pick<IEvent, "startTime" | "endTime">) => {
  if (endTime && startTime) {
    const { minutes } = timeDuration({ start: startTime, end: endTime });

    return Math.round(minutes);
  }

  return 0;
};
