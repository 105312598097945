import { SVGProps } from "react";

export const Chat = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="34"
    height="34"
    viewBox="0 0 34 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M20.9788 6.4231H13.0146C10.7571 6.4254 8.59273 7.31203 6.99644 8.88843C5.40015 10.4648 4.50233 12.6022 4.5 14.8316V16.1154C4.50233 18.3448 5.40015 20.4822 6.99644 22.0586C8.59273 23.635 10.7571 24.5216 13.0146 24.5239H16.1684L17.7601 26.9926C17.8728 27.1727 18.0306 27.321 18.2183 27.4233C18.4059 27.5256 18.6171 27.5785 18.8314 27.5769C19.0457 27.5753 19.256 27.5191 19.4421 27.414C19.6281 27.3088 19.7835 27.1581 19.8934 26.9763L21.4268 24.5076C23.5774 24.4648 25.6258 23.5931 27.1343 22.0788C28.6428 20.5645 29.4918 18.5277 29.5 16.4035V14.8316C29.4977 12.6011 28.5989 10.4627 27.0012 8.88612C25.4035 7.30954 23.2374 6.42367 20.9788 6.4231ZM12.833 16.8057H21.1637C21.2732 16.8057 21.3815 16.827 21.4826 16.8683C21.5837 16.9097 21.6756 16.9703 21.753 17.0467C21.8303 17.1231 21.8917 17.2139 21.9336 17.3137C21.9755 17.4135 21.997 17.5205 21.997 17.6286C21.997 17.7367 21.9755 17.8437 21.9336 17.9435C21.8917 18.0434 21.8303 18.1341 21.753 18.2105C21.6756 18.2869 21.5837 18.3475 21.4826 18.3889C21.3815 18.4302 21.2732 18.4515 21.1637 18.4515H12.8308C12.7213 18.4515 12.613 18.4302 12.5119 18.3889C12.4108 18.3475 12.3189 18.2869 12.2415 18.2105C12.1642 18.1341 12.1028 18.0434 12.0609 17.9435C12.019 17.8437 11.9975 17.7367 11.9975 17.6286C11.9975 17.5205 12.019 17.4135 12.0609 17.3137C12.1028 17.2139 12.1642 17.1231 12.2415 17.0467C12.3189 16.9703 12.4108 16.9097 12.5119 16.8683C12.613 16.827 12.7213 16.8057 12.8308 16.8057H12.833ZM11.9986 13.0705C11.9986 12.8523 12.0864 12.643 12.2426 12.4886C12.3989 12.3343 12.6109 12.2476 12.8319 12.2476H21.1626C21.3836 12.2476 21.5956 12.3343 21.7519 12.4886C21.9081 12.643 21.9959 12.8523 21.9959 13.0705C21.9959 13.2888 21.9081 13.4981 21.7519 13.6524C21.5956 13.8067 21.3836 13.8934 21.1626 13.8934H12.8308C12.6098 13.8934 12.3978 13.8067 12.2415 13.6524C12.0853 13.4981 11.9975 13.2888 11.9975 13.0705H11.9986Z"
      fill="currentColor"
    />
  </svg>
);
