import { Theme } from "@remo-co/ui-core/src/types";
import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";

export const useStyles = makeStyles<Theme>(() => ({
  defaultProfile: {
    width: "100%",
    height: "100%",
    backgroundColor: "black",
    objectFit: "contain",
  },
  userProfileImg: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
}));
