import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";

const useStyles = makeStyles(() => ({
  cameraButton: {
    height: 46,
    width: 46,
  },
  cameraIcon: {
    height: 40,
    width: 40,
  },
}));

export default useStyles;
