import { useEffect, useState, ReactElement } from "react";
import { Actions, trackAction } from "modules/monitoring";
import { useSelector } from "react-redux";
import {
  selectCurrentVersion,
  selectDeployedVersion,
  selectIsVersionLowerThanDeployed,
} from "modules/version/selectors";
import { selectIsMobile } from "modules/deviceInfo";
import VersionPrompt from "../VersionPrompt";

type Props = {
  children: ReactElement;
  placement?: "top-start" | "bottom";
};

const UpdatePrompt = ({ children, placement = "top-start" }: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const isVersionOutdated = useSelector(selectIsVersionLowerThanDeployed);
  const currentVersion = useSelector(selectCurrentVersion);
  const deployedVersion = useSelector(selectDeployedVersion);
  const isMobileView = useSelector(selectIsMobile);

  useEffect(() => {
    if (!isMobileView && isVersionOutdated) {
      trackAction(Actions.EVENT_VERSION_SHOW_PROMPT, {
        currentVersion,
        deployedVersion,
      });

      setIsOpen(true);
    }
  }, [isVersionOutdated, currentVersion, deployedVersion, isMobileView]);

  const handleUpdate = () => {
    setIsOpen(false);
    trackAction(Actions.EVENT_VERSION_UPDATE_SUCCESS);
    window.location.reload();
  };

  const handleCancel = () => {
    setIsOpen(false);
    trackAction(Actions.EVENT_VERSION_UPDATE_CANCEL);
  };

  return (
    <VersionPrompt
      open={isOpen}
      onUpdate={handleUpdate}
      onCancel={handleCancel}
      placement={placement}
    >
      {children}
    </VersionPrompt>
  );
};

export default UpdatePrompt;
