import { selectCurrentEventId } from "modules/event/selectors";
import useNotificationActions from "modules/notification/hooks/useNotificationActions";
import { selectCurrentSpaceId } from "modules/space/redux/selectors";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import { sendGetRequest, sendPostRequest } from "services/apiService";
import { useI18n } from "i18n";
import { LOCK_TABLE_QUERY_KEY } from "../constants";

interface LockTablePayload {
  tableId: string;
  floorId: string;
  denyEntry?: boolean;
  denyExit?: boolean;
  users?: string[];
  tableName: string;
  updateAllFloors?: boolean;
  roomCode?: string;
}

type LockTableResponse =
  | {
      status: false;
      message: string;
    }
  | {
      status: true;
    };

export interface LockTableInfo {
  table: string;
  denyEntry: boolean;
  denyExit: boolean;
  lockedByGuest: string | null;
}
type GetLocksResponse =
  | {
      status: false;
      message: string;
    }
  | {
      status: undefined;
      tableSettings: LockTableInfo[];
    };

// NOTE: doesn't support setting presets for now
export const useLockTable = () => {
  const eventId = useSelector(selectCurrentEventId);
  const floorId = useSelector(selectCurrentSpaceId);

  const {
    data: lockedTables = [],
    status,
    isLoading,
  } = useQuery({
    queryKey: [LOCK_TABLE_QUERY_KEY, eventId, floorId],
    queryFn: async () => {
      const resp = await sendGetRequest<GetLocksResponse>(
        `/event/${eventId}/table-settings/floor/${floorId}`,
      );

      if (resp?.status === false) {
        throw new Error(resp.message);
      }
      return resp;
    },
    select: ({ tableSettings }) =>
      tableSettings.map(
        ({ table, denyEntry = false, denyExit = false, lockedByGuest }) => ({
          denyEntry,
          denyExit,
          table,
          lockedByGuest,
        }),
      ),
  });

  return { lockedTables, status, isLoading };
};

export const useLockTableMutation = () => {
  const { addSuccessNotification } = useNotificationActions();
  const { t } = useI18n(["server"]);

  const eventId = useSelector(selectCurrentEventId);

  const {
    mutate: lockTable,
    mutateAsync: lockTableAsync,
    isPending,
    status,
  } = useMutation({
    mutationFn: async (payload: LockTablePayload) => {
      const { tableId, ...lockTablePayload } = payload;
      const resp = await sendPostRequest<
        Omit<LockTablePayload, "tableId">,
        LockTableResponse
      >(`/event/${eventId}/table-settings/table/${tableId}`, lockTablePayload);

      if (!resp.status) {
        throw new Error(resp.message);
      }
      return resp;
    },
    onSuccess: (_data, payload: LockTablePayload) => {
      if (payload.denyExit) {
        addSuccessNotification({ message: t("table.lock.movement.added") });
      } else if (payload.denyEntry) {
        addSuccessNotification({ message: t("table.lock.added") });
      } else {
        addSuccessNotification({ message: t("table.settings.updated") });
      }
    },
  });

  return { lockTable, isPending, status, lockTableAsync };
};
