import { sendDeleteRequest, sendPostRequest } from "services/apiService";

export type ClearSessionPayload = {
  eventId: string;
};

export type UpdateSessionPayload = {
  eventId: string;
  floorId: string;
};

type SessionResponse =
  | {
      status: true;
    }
  | {
      status: false;
      message: string;
    };
type CreateSessionResponse =
  | {
      status: true;
      existingSession: boolean;
    }
  | {
      status: false;
      message: string;
    };

const responseErrorHandler = async <T extends SessionResponse>(
  resp: Promise<T>,
) => {
  const data = await resp;
  if (!data.status) {
    throw new Error(data.message);
  }

  return data;
};
export const clearOtherSessionsRequest = async ({
  eventId,
}: ClearSessionPayload) =>
  responseErrorHandler(
    sendDeleteRequest<void, SessionResponse>(`/event/${eventId}/user/sessions`),
  );

export const clearCurrentSessionRequest = ({ eventId }: ClearSessionPayload) =>
  responseErrorHandler(
    sendDeleteRequest<void, SessionResponse>(`/event/${eventId}/user/session`),
  );
export const createSessionRequest = async ({
  eventId,
  ...payload
}: UpdateSessionPayload) => {
  const data = await sendPostRequest<
    Pick<UpdateSessionPayload, "floorId">,
    CreateSessionResponse
  >(`/event/${eventId}/user/session`, payload);

  if (!data.status) {
    throw new Error(data.message);
  }
  return data.existingSession;
};
