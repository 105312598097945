import { useEffect } from "react";
import { useLocalSessionId, useMediaTrack } from "@daily-co/daily-react";
import { useIsTalking, useUserData } from "modules/audioVideo/hooks";
import classNames from "classnames";
import { Container } from "@remo-co/ui-core/src/components/Container";
import { useSelector } from "react-redux";
import { selectUser } from "modules/auth/redux/selectors";
import { LocalVideo } from "modules/audioVideo/components/Tile/Video/LocalVideo";
import { DeviceToggles } from "../DeviceToggles";
import { useStyles } from "./styles";

interface Props {
  onVolumeChange?: (level: number) => void;
  aspectRatio?: "original" | "default";
  showToggles?: boolean;
  showSettings?: boolean;
  showBlur?: boolean;
}

const VideoTile = ({
  onVolumeChange,
  showToggles = false,
  showSettings = false,
  showBlur = false,
  aspectRatio = "default",
}: Props): JSX.Element => {
  const styles = useStyles();
  const localSessionId = useLocalSessionId();
  const user = useSelector(selectUser);
  const { setUserData } = useUserData();
  const profileImage = user?.profile?.picture;

  useEffect(() => {
    setUserData({ image: profileImage });
  }, [profileImage, setUserData]);

  const audioTrackState = useMediaTrack(localSessionId ?? "", "audio");

  useIsTalking({
    audioTrack: audioTrackState?.track,
    onVolumeChange,
  });

  return (
    <div
      className={classNames(styles.root, {
        [styles.cover]: aspectRatio === "default",
        [styles.contain]: aspectRatio === "original",
      })}
    >
      <div className={styles.video}>
        <LocalVideo />
      </div>
      {showToggles && (
        <Container flex fullWidth alignItems="center">
          <DeviceToggles
            className={styles.toolbar}
            showSettings={showSettings}
            showBlur={showBlur}
          />
        </Container>
      )}
    </div>
  );
};

export default VideoTile;
