import { useI18n } from "i18n";
import { Typography } from "@remo-co/ui-core/src/components/Typography";
import { Tooltip } from "@remo-co/ui-core/src/components/Tooltip";
import { Button } from "@remo-co/ui-core/src/components/Button";
import { Add } from "@remo-co/ui-core/src/icons/Add";
import TicketingSVG from "../../assets/ticketing.svg";
import { useStyles } from "./styles";

interface Props {
  onCreateClick: () => void;
  disabled: boolean;
}

export const EmptyState = ({ onCreateClick, disabled }: Props) => {
  const { t } = useI18n(["common", "eventForm"]);
  const styles = useStyles();

  return (
    <div className={styles.container}>
      <img src={TicketingSVG} alt={t("audience.view.toggle")} />
      <Typography variant="body1" className={styles.text}>
        {t("eventForm:no.tickets.added.yet")}
      </Typography>
      <Typography variant="body1" className={styles.text}>
        {t("eventForm:create.first.ticket")}
      </Typography>
      <Tooltip
        placement="top"
        title={disabled ? t("eventForm:ticket.disabled.not.upcoming") : ""}
      >
        <span>
          <Button
            variant="secondary"
            color="blue"
            size="sm"
            className={styles.button}
            disabled={disabled}
            contentClassName={styles.content}
            data-testid="create-ticket-button-empty-state"
            onClick={onCreateClick}
          >
            <Add className={styles.icon} />
            {t("eventForm:add.ticket")}
          </Button>
        </span>
      </Tooltip>
    </div>
  );
};
