import {
  GetEventFloorContentQuery,
  OnEventFloorContentDeletedSubscription,
} from "graphql/generated";

interface DeletionSubscriptionData {
  subscriptionData: {
    data: OnEventFloorContentDeletedSubscription;
  };
}

export const removeDeletedEventFloorContent = (
  prev: GetEventFloorContentQuery,
  { subscriptionData }: DeletionSubscriptionData,
): GetEventFloorContentQuery => {
  if (prev.eventFloorContent.__typename === "GQLError") {
    return prev;
  }

  const deletedContent = subscriptionData.data.onEventFloorContentDeleted;

  if (!deletedContent) {
    return prev;
  }

  return {
    eventFloorContent: {
      ...prev.eventFloorContent,
      // if incoming content is from an update, filter out the existing version
      content: prev.eventFloorContent.content.filter(
        (existingContent) =>
          !(
            existingContent.id === deletedContent.eventContent &&
            existingContent.slot === deletedContent.slot
          ),
      ),
    },
  };
};
