import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";

export const useStyles = makeStyles((theme) => ({
  appBar: {
    boxShadow: "none",
  },
  addButton: {
    margin: 0,
  },
  deleteButton: {
    padding: 0,
  },
  displayModeTitle: {
    marginTop: theme.spacing(3),
  },
}));

export const useTabsStyles = makeStyles((theme) => ({
  root: {
    borderRadius: 10,
    minHeight: 44,
  },
  flexContainer: {
    display: "inline-flex",
    position: "relative",
    zIndex: 1,
  },
  scroller: {
    display: "flex",
    justifyContent: "center",
  },
  indicator: {
    top: 3,
    bottom: 3,
    right: 3,
    height: "auto",
    background: "none",
    "&:after": {
      content: '""',
      display: "block",
      position: "absolute",
      padding: "10px 0px",
      top: 0,
      left: 5,
      right: 5,
      bottom: 0,
      borderRadius: 100,
      backgroundColor: theme.palette.gray.light,
    },
  },
}));

export const useTabItemStyles = makeStyles({
  root: {
    borderRadius: 100,
    "&:hover": {
      opacity: 1,
    },
    minHeight: 44,
    minWidth: 175,
  },
  wrapper: {
    flexDirection: "row",
    color: "#000",
    textTransform: "initial",
    "& img": {
      marginRight: 5,
    },
  },
});
