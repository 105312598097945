import { Theme } from "@remo-co/ui-core/src/types";
import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";
import { TOTAL_ARROW_BUTTON_HEIGHT } from "../hooks/constants";

interface Props {
  isMobile?: boolean;
  hasPagination?: boolean;
}

export const useStyles = makeStyles<Theme, Props>((theme) => ({
  root: {
    pointerEvents: "all",
    margin: "0 auto",
    width: "auto",
    maxWidth: "100%",
  },
  ribbon: {
    display: "flex",
    minHeight: "min-content",
    overflow: "auto",
    width: "auto",
    gap: theme.spacing(0.5),
    pointerEvents: "all",
    "& > div": {
      display: "flex",
      flexShrink: 0,
      width: "200px",
      aspectRatio: "4/3",
    },
    justifyContent: "flex-start",
  },
  banner: {
    display: "flex",
    justifyContent: "center",
  },
  viewButtonRoot: ({ hasPagination }) => ({
    display: "flex",
    backgroundColor: theme.palette.white,
    borderRadius: "10px",
    border: `1px solid ${theme.palette.secondary.main}`,
    height: "40px",
    // eslint-disable-next-line no-nested-ternary
    width: hasPagination ? "180px" : "86px",
  }),
  button: ({ isMobile, hasPagination }) => ({
    paddingBottom: isMobile ? theme.spacing(1) : 0,
    paddingTop: theme.spacing(1),
    // eslint-disable-next-line no-nested-ternary
    maxWidth: hasPagination ? "180px" : "100px",
    height: TOTAL_ARROW_BUTTON_HEIGHT,
  }),
}));
