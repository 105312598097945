import React from "react";
import { initProcessor } from "../../components/TranscriptionContext/context/helpers";
import {
  clearAudioContext,
  clearProcessor,
  updateAudioContext,
  updateProcessor,
  updateSampleRate,
} from "../../components/TranscriptionContext/context/actions";
import { TranscriptionContext } from "../../components/TranscriptionContext/TranscriptionContext";

interface TranscriptionPublisher {
  publishProcessor: (audioTrack: MediaStreamTrack) => void;
  unpublishProcessor: () => void;
}

const useTranscriptionPublisher = (): TranscriptionPublisher => {
  const { dispatch, sampleRate: currentSampleRate } =
    React.useContext(TranscriptionContext);
  const publishProcessor = React.useCallback(
    (audioTrack: MediaStreamTrack) => {
      if (!dispatch) {
        return;
      }
      const { processor, sampleRate, context } = initProcessor(audioTrack);

      dispatch(updateAudioContext(context));
      if (sampleRate !== currentSampleRate) {
        dispatch(updateSampleRate(sampleRate));
      }
      dispatch(updateProcessor(processor));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch],
  );

  const unpublishProcessor = React.useCallback(() => {
    if (!dispatch) {
      return;
    }
    dispatch(clearProcessor());
    dispatch(clearAudioContext());
  }, [dispatch]);

  return {
    publishProcessor,
    unpublishProcessor,
  };
};

export default useTranscriptionPublisher;
