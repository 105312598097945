import { Theme } from "@remo-co/ui-core/src/types";
import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";

export const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    width: "100%",
    minHeight: "394px",
    [theme.breakpoints.down("sm")]: {
      height: "auto",
      minHeight: "0",
    },
  },
  media: {
    objectFit: "contain",
    width: "100%",
    height: "100%",
    minHeight: "394px",
  },

  iframe: {
    [theme.breakpoints.down("sm")]: {
      objectFit: "unset",
    },
  },
}));
