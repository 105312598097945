import { SET_USER_PROBLEM_DATA } from "./types";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const initialState: any = {};

// eslint-disable-next-line @typescript-eslint/no-explicit-any,  @typescript-eslint/default-param-last
const userProblemReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case SET_USER_PROBLEM_DATA:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

export default userProblemReducer;
