import { Button } from "@remo-co/ui-core/src/components/Button";
import { useAppDispatch } from "store/hooks";
import { selectRightPanelState, setPanelState, TABS } from "modules/rightPanel";
import { Typography } from "@remo-co/ui-core/src/components/Typography";
import { List } from "@remo-co/ui-core/src/components/List";
import { Box } from "@remo-co/ui-core/src/components/Box";
import { ListItem } from "@remo-co/ui-core/src/components/ListItem";
import { ListItemText } from "@remo-co/ui-core/src/components/ListItemText";
import { useI18n } from "i18n";
import { useSelector } from "react-redux";
import { useCallback } from "react";
import { styles } from "./styles";
import NoConnectionImage from "../../assets/noConnection.png";

export const NoConnectionsContainer = () => {
  const { t } = useI18n("event");
  const dispatch = useAppDispatch();
  const rightPanelState = useSelector(selectRightPanelState);

  const handleAllAttendees = useCallback(() => {
    dispatch(
      setPanelState({
        isOpen:
          !rightPanelState.isOpen ||
          rightPanelState.tabId !== TABS.PARTICIPANTS,
        tabId: TABS.PARTICIPANTS,
      }),
    );
  }, [dispatch, rightPanelState.isOpen, rightPanelState.tabId]);

  const reasons = [
    t("no.matches.reason.one"),
    t("no.matches.reason.two"),
    t("no.matches.reason.three"),
  ];

  return (
    <Box
      sx={styles.noConnectionContainer}
      data-testid="no-connections-container"
    >
      <Box sx={styles.imageContainer}>
        <Box
          component="img"
          src={NoConnectionImage}
          alt="No connections available"
          sx={styles.noConnectionImage}
        />
      </Box>
      <Box>
        <Typography variant="h4" sx={styles.noMatches}>
          {t("no.matches.available")}
        </Typography>
        <Typography variant="body1">
          {t("no.matches.reasons.description")}
        </Typography>
        <List sx={styles.list}>
          {reasons.map((reason) => (
            <ListItem key={reason}>
              <ListItemText>
                <Typography variant="body1">{reason}</Typography>
              </ListItemText>
            </ListItem>
          ))}
        </List>
        <Typography variant="body1">
          {t("no.recommendations.summary")}
        </Typography>
        <Button
          fullWidth
          sx={styles.showAllParticipants}
          onClick={handleAllAttendees}
        >
          {t("show.all.attendees")}
        </Button>
      </Box>
    </Box>
  );
};
