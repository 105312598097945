import { ICameraSettingsState } from "../types";
import { CameraSettingsAction } from "./actions";
import ActionTypes from "./constants";

export const initialState: ICameraSettingsState = {
  isCameraSettingsDialogVisible: false,
};

const cameraSettingsReducer = (
  state = initialState,
  action: CameraSettingsAction,
): ICameraSettingsState => {
  switch (action.type) {
    case ActionTypes.SHOW_CAMERA_SETTINGS_DIALOG: {
      return {
        ...state,
        isCameraSettingsDialogVisible: true,
      };
    }
    case ActionTypes.HIDE_CAMERA_SETTINGS_DIALOG: {
      return {
        ...state,
        isCameraSettingsDialogVisible: false,
      };
    }
    default:
      return state;
  }
};

export default cameraSettingsReducer;
