import { createTheme, adaptV4Theme } from "@mui/material/styles";
import { lightPalette } from "./palettes";
import { overrides } from "./overrides";
import { baseTheme } from "./baseTheme";

export const lightTheme = createTheme(
  adaptV4Theme({
    ...baseTheme,
    palette: lightPalette,
    overrides,
  }),
);
