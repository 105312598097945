import { ONE_MINUTE_MS } from "helpers/time/constants";
import { getCurrentClientTime } from "helpers/firebaseTimeHelper";
import { HUNDRED_YEARS_IN_MILLISECONDS } from "modules/manageEvent/constants";
import { getInitialEventDurationMS } from "../getInitialEventDurationMS";

interface StartAndEndTimes {
  startTime: number;
  endTime: number;
}

interface GetComputedStartEndTimesProps {
  startTime: number;
  endTime: number;
  eventSettingsTimeLimit: number;
  isUnlimitedEvent?: boolean;
}

const getComputedStartEndTimes = async ({
  startTime,
  endTime,
  eventSettingsTimeLimit,
  isUnlimitedEvent,
}: GetComputedStartEndTimesProps): Promise<StartAndEndTimes> => {
  if (startTime !== 0 && endTime !== 0) return { startTime, endTime };

  const now = await getCurrentClientTime();
  const nowPlusOneMinute = new Date(now + ONE_MINUTE_MS);
  nowPlusOneMinute.setHours(
    nowPlusOneMinute.getHours(),
    nowPlusOneMinute.getMinutes(),
    0,
    0,
  );

  const eventStartTimeInMs = nowPlusOneMinute.getTime();
  const initialEventDurationInMs = getInitialEventDurationMS(
    eventSettingsTimeLimit,
  );
  const eventEndTimeInMs = eventStartTimeInMs + initialEventDurationInMs;

  return {
    startTime: eventStartTimeInMs,
    endTime: isUnlimitedEvent
      ? // Adding 100 years
        eventEndTimeInMs + HUNDRED_YEARS_IN_MILLISECONDS
      : eventEndTimeInMs,
  };
};

export default getComputedStartEndTimes;
