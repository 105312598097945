import { Avatar } from "modules/avatar";
import { AppCircleLogo } from "modules/app/branding";
import classNames from "classnames";
import { LogoVariants } from "@remo-co/ui-core/src/types";
import { RemoLogo } from "@remo-co/ui-core/src/components/RemoLogo";
import { useStyles } from "./styles";

interface IBaseLogoProps {
  logoVariant?: LogoVariants;
  avatarLogoURL?: string;
  avatarClass?: string;
  containerClass?: string;
}

const BaseLogo = ({
  avatarLogoURL,
  avatarClass,
  containerClass,
  logoVariant,
}: IBaseLogoProps) => {
  const classes = useStyles();

  return (
    <div
      className={classNames(classes.container, containerClass)}
      data-testid="logo-container"
    >
      {!avatarLogoURL && !logoVariant && (
        <div data-testid="rounded-circle-logo">
          <AppCircleLogo full width={85} />
        </div>
      )}
      {avatarLogoURL && (
        <div
          className={classNames(classes.avatar, avatarClass)}
          data-testid="logo-avatar"
        >
          <Avatar pictureUrl={avatarLogoURL} />
        </div>
      )}
      {logoVariant && (
        <div data-testid="logo-variant">
          <RemoLogo width={70} variant={logoVariant} />
        </div>
      )}
    </div>
  );
};

export default BaseLogo;
