import { MouseEvent, useContext } from "react";
import { ListItem } from "@remo-co/ui-core/src/components/ListItem";
import { PageLoader } from "@remo-co/ui-core/src/components/PageLoader";
import { IconButtonWithTooltip } from "@remo-co/ui-core/src/components/IconButtonWithTooltip";
import { Add } from "@remo-co/ui-core/src/icons/Add";
import { ArrowDropDown } from "@remo-co/ui-core/src/icons/ArrowDropDown";
import { ArrowDropUp } from "@remo-co/ui-core/src/icons/ArrowDropUp";
import { Delete } from "@remo-co/ui-core/src/icons/Delete";
import useNotificationActions from "modules/notification/hooks/useNotificationActions";
import { sendPostRequest } from "services/apiService";
import AgendaItem from "modules/eventAgendaPublic/AgendaItem";
import { useI18n } from "i18n";
import { EVENT_AGENDA_CONTEXT } from "./context/EventAgendaContext";
import useEventAgenda from "./context/useEventAgenda";
import { IEventAgenda } from "./types";

interface Props {
  item: IEventAgenda;
  editAgenda: (key: string) => void;
  addNewAfter: (key: string) => void;
  offset: number;
}

const EventAgendaItem = ({
  item,
  editAgenda,
  addNewAfter,
  offset,
}: Props): JSX.Element => {
  const { addErrorNotification, addSuccessNotification } =
    useNotificationActions();
  const { eventData, state } = useContext(EVENT_AGENDA_CONTEXT);
  const eventId = eventData?.id ?? null;
  const agendaItems = state?.agendaItems || null;
  const currentDate = state?.currentDate || null;
  const { deleteAgenda, isInProgress, setIsInProgress } = useEventAgenda();

  const { t } = useI18n(["common", "eventAgenda", "server"]);

  const addNewAgenda = (e: MouseEvent) => {
    e.stopPropagation();

    if (item.id) {
      addNewAfter(item.id);
    }
  };
  const editAgendaItem = async (e: MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();

    if (item.id) {
      editAgenda(item.id);
    }
  };

  const moveAgenda = async (e: MouseEvent, moveUp: boolean) => {
    e.stopPropagation();
    setIsInProgress(t("eventAgenda:moving.agenda.items"));
    const { isSuccess, message } = await sendPostRequest<
      { date: string | null; moveUp: boolean; rank: number },
      APIResponse
    >(`/event-agenda/${eventId}/agenda/${item.id}/move`, {
      date: currentDate,
      moveUp,
      rank: item.rank,
    });

    if (isSuccess) {
      addSuccessNotification({ message: t("eventAgenda:item.move.success") });
    } else {
      addErrorNotification({
        message: t(message ?? "eventAgenda:item.move.failure"),
      });
    }
    setIsInProgress(null);
  };

  const deleteAgendaItem = (e: MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();

    deleteAgenda(item);
  };

  const isFirst = item.rank === 0;
  const isLast = !!agendaItems && item.rank === agendaItems.size - 1;

  return (
    <ListItem
      data-testid="agenda-item"
      onClick={editAgendaItem}
      className="eva-item"
    >
      {eventData ? (
        <AgendaItem
          item={item}
          offset={offset}
          event={eventData}
          nonExpandableText
        />
      ) : null}

      <div className="eva-actions">
        <IconButtonWithTooltip
          disabled={isFirst}
          id={`${item.id}-up`}
          title={t("eventAgenda:move.item.up")}
          onClick={(e) => moveAgenda(e, true)}
          className="icon-btn-with-tooltip"
        >
          <ArrowDropUp fontSize="large" />
        </IconButtonWithTooltip>
        <IconButtonWithTooltip
          disabled={isLast}
          id={`${item.id}-dn`}
          data-testid={`${item.id}-dn`}
          title={t("eventAgenda:move.item.down")}
          onClick={(e) => moveAgenda(e, false)}
          className="icon-btn-with-tooltip"
        >
          <ArrowDropDown fontSize="large" />
        </IconButtonWithTooltip>
        <IconButtonWithTooltip
          id={`${item.id}-add`}
          title={t("eventAgenda:add.item.agenda")}
          onClick={addNewAgenda}
          className="icon-btn-with-tooltip"
        >
          <Add />
        </IconButtonWithTooltip>
      </div>
      <IconButtonWithTooltip
        id={`${item.id}-delete`}
        title={t("eventAgenda:delete.item.agenda")}
        className="icon-btn-with-tooltip eva-delete"
        onClick={deleteAgendaItem}
      >
        <Delete />
      </IconButtonWithTooltip>

      {isInProgress && <PageLoader fullScreen message={isInProgress} />}
    </ListItem>
  );
};

export default EventAgendaItem;
