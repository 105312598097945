import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export interface IMapNavigationFocus {
  type: "user" | "room";
}

export interface IMapNavigationHighlight {
  type: "user";
  id: string;
}

export interface IMapNavigationLocating {
  type: "user";
  id: string;
}

export interface IMapNavigationCoordinatesState {
  x: number | null;
  y: number | null;
  zoom: number | null;
  focus: IMapNavigationFocus | null;
  highlight: IMapNavigationHighlight | null;
  customAnimationDurationOverride?: number;
}

export interface IMapNavigationZoomingState {
  zoom: number | null;
  x: number | null;
  y: number | null;
}

export interface IMapNavigationState {
  locating: IMapNavigationLocating | null;
  current: Pick<IMapNavigationCoordinatesState, "x" | "y" | "zoom">;
  target: IMapNavigationCoordinatesState;
  isInitiallyLocated: boolean;
}

export const initialState: IMapNavigationState = {
  locating: null,
  current: {
    x: null,
    y: null,
    zoom: null,
  },
  target: {
    x: null,
    y: null,
    zoom: null,
    focus: null,
    highlight: null,
  },
  isInitiallyLocated: false,
};

export const defaultFocusType: IMapNavigationFocus = { type: "user" };

const mapNavigationSlice = createSlice({
  name: "MapNavigation",
  initialState,
  reducers: {
    updateMapCurrentState: (
      state: IMapNavigationState,
      action: PayloadAction<
        Pick<IMapNavigationCoordinatesState, "x" | "y" | "zoom">
      >,
    ) => {
      if (
        state.current.x === action.payload.x &&
        state.current.y === action.payload.y &&
        state.current.zoom === action.payload.zoom
      ) {
        return;
      }

      state.current = action.payload;
    },

    updateMapTargetState: (
      state: IMapNavigationState,
      action: PayloadAction<IMapNavigationCoordinatesState>,
    ) => {
      state.target = action.payload;
    },

    updateMapZoom: (
      state: IMapNavigationState,
      action: PayloadAction<IMapNavigationZoomingState>,
    ) => {
      state.target = {
        ...state.target,
        ...action.payload,
      };
    },

    updateLocating: (
      state: IMapNavigationState,
      action: PayloadAction<IMapNavigationLocating | null>,
    ) => {
      state.locating = action.payload;
    },
    setInitiallyLocated: (
      state: IMapNavigationState,
      action: PayloadAction<boolean>,
    ) => {
      state.isInitiallyLocated = action.payload;
    },
  },
});

export const {
  updateMapCurrentState,
  updateMapTargetState,
  updateMapZoom,
  updateLocating,
  setInitiallyLocated,
} = mapNavigationSlice.actions;

export default mapNavigationSlice.reducer;
