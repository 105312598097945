import { FormControlLabel } from "@remo-co/ui-core/src/components/FormControlLabel";
import { Switch } from "@remo-co/ui-core/src/components/Switch";
import { useI18n } from "i18n";
import { useCheckEventStatus } from "modules/event/hooks/useCheckEventStatus";
import {
  selectCurrentEvent,
  selectCurrentEventIsLobbyActive,
} from "modules/event/selectors";
import React from "react";
import { useSelector } from "react-redux";
import { addDialogNotification } from "modules/dialogNotification/redux/dialogNotificationSlice";
import { useAppDispatch } from "store/hooks";
import { selectSortedWaitingGuests } from "./redux/selectors";

interface Props {
  isTogglingLobby: boolean;
  updateEvent: (isChecked: boolean) => void;
  label: string;
  overrideToggleStatus?: boolean;
}

const LobbyToggle = ({
  isTogglingLobby,
  updateEvent,
  label,
  overrideToggleStatus,
}: Props): JSX.Element => {
  const currentEvent = useSelector(selectCurrentEvent);
  const isLobbyActive = useSelector(selectCurrentEventIsLobbyActive);
  const { t } = useI18n();
  const dispatch = useAppDispatch();
  const { checkIfEventHappeningNow } = useCheckEventStatus();
  const users = useSelector(selectSortedWaitingGuests);

  const onLobbyToggle = (e: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = e.target.checked;

    if (!isChecked) {
      // If no users, we dont need to show notification
      if (
        currentEvent &&
        checkIfEventHappeningNow(currentEvent) &&
        users.length
      ) {
        dispatch(
          addDialogNotification({
            message: t("disable.lobby.text"),
            confirmText: t("disable"),
            dismissText: t("no"),
            hideCloseButton: true,
            dismissBtnId: "lb-cancel",
            confirmBtnId: "lb-confirm",
            onConfirm: () => {
              updateEvent(isChecked);
            },
          }),
        );

        return;
      }
    }
    updateEvent(isChecked);
  };

  const status =
    overrideToggleStatus !== undefined ? overrideToggleStatus : isLobbyActive;

  return (
    <FormControlLabel
      label={label}
      labelPlacement="start"
      data-testid="lobby-toggle"
      control={
        <Switch
          color="primary"
          disabled={isTogglingLobby}
          checked={status}
          onChange={onLobbyToggle}
        />
      }
    />
  );
};

export default LobbyToggle;
