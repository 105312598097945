import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";

export const useStyles = makeStyles(() => ({
  root: {
    position: "absolute",
    display: "flex",
    cursor: "pointer",
    background: "transparent",
  },
  preview: {
    position: "relative",
  },
  imageMedia: {
    borderRadius: "4px",
    objectFit: "contain",
    width: "100%",
    height: "100%",
  },
}));
