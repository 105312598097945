export const styles = {
  header: {
    display: "flex",
    backgroundColor: "#fff",
    mb: "1.875rem",
  },
  timeContainer: {
    flex: "1 0 20%",
  },
};
