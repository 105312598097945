import { createElement } from "react";
import { I18nContext } from "../../context";

interface Props {
  message: string;
  noEscape?: boolean;
  typeName?: string;
  tagName?: string;
  [key: string]: unknown;
}

const Translate = (props: Props): JSX.Element => (
  <I18nContext.Consumer>
    {(i18n) => {
      const { message, noEscape, typeName, tagName, ...childProps } = props;

      const tagType = tagName || typeName || undefined;
      const translated = i18n.translate(message);

      if (noEscape ?? i18n.noEscape) {
        return createElement(
          tagType || "span",
          Object.assign(childProps, {
            dangerouslySetInnerHTML: { __html: translated },
          }),
        );
      }

      if (tagType) {
        return createElement(tagType, childProps, translated);
      }

      return translated;
    }}
  </I18nContext.Consumer>
);

export default Translate;
