import { useMemo } from "react";
import { useLocalSessionId } from "@daily-co/daily-react";
import { TileDraft } from "modules/audioVideo/components";
import { useSelector } from "react-redux";
import { selectActiveSpeaker } from "modules/daily/redux/selectors";

export const useActiveSpeakerTile = (
  initialTiles: TileDraft[],
  pageSize: number,
): { participantTiles: TileDraft[] } => {
  const localSessionId = useLocalSessionId();
  const activeSpeakerId = useSelector(selectActiveSpeaker);

  const participantTiles: TileDraft[] = useMemo(() => {
    if (!activeSpeakerId) {
      return initialTiles;
    }

    const indexOfActiveTile = initialTiles.findIndex(
      (tile) => tile.streamId === activeSpeakerId,
    );

    if (indexOfActiveTile === -1) {
      return initialTiles;
    }

    if (indexOfActiveTile < pageSize) {
      return initialTiles;
    }

    const updatedTiles = [...initialTiles];
    const localTile = updatedTiles.find(
      (tile) => tile.streamId === localSessionId,
    ) as TileDraft;
    const activeSpeakerTile = updatedTiles[indexOfActiveTile];

    updatedTiles.splice(indexOfActiveTile, 1);
    const newTilesOrder = [
      ...(localTile ? [localTile] : []),
      activeSpeakerTile,
      ...updatedTiles.filter((tile) => tile.streamId !== localSessionId),
    ];

    return newTilesOrder;
  }, [activeSpeakerId, initialTiles, localSessionId, pageSize]);

  return { participantTiles };
};
