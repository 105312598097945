import { datadogRum } from "@datadog/browser-rum";
import { CustomAttributes, MonitoredEventTypes } from "../../types";
import { Warnings } from "../../warnings";

const trackWarning = (warning: Warnings, context?: CustomAttributes): void => {
  datadogRum.addAction(`WARN:${warning}`, {
    ...context,
    type: MonitoredEventTypes.WARNING,
  });
};

export default trackWarning;
