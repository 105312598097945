import useSystemClock from "modules/system/hooks/useSystemClock";
import { useAppDispatch } from "store/hooks";
import { useI18n } from "i18n";
import { setChatPrefilledMessage } from "modules/chat/redux/actions";
import { CHAT_TYPE } from "../../redux/constants";
import { genChannelId } from "../../chatUtils";
import useChatFirestore from "../../firestore/useChatFirestore";
import { useChatActions } from "../useChatActions";

const useSendMessage = () => {
  const { t } = useI18n("event");
  const { getCurrentTime } = useSystemClock();
  const { updateChatChannelMeta } = useChatFirestore();
  const { openChannel } = useChatActions();
  const dispatch = useAppDispatch();

  const startDirectMessage = (fromUserId: string, toUserId: string) => {
    const time = getCurrentTime();
    const channelId = genChannelId(toUserId, fromUserId);
    const users = <Record<string, {}>>{};

    users[toUserId] = { lastAccess: time };
    users[fromUserId] = { lastAccess: time };
    const channel = {
      id: channelId,
      name: "",
      type: CHAT_TYPE.USER,
      users,
    };

    updateChatChannelMeta(channel);
    openChannel(channel.id);
  };

  const startDirectNetworkingMessage = (
    fromUserId: string,
    toUserId: string,
    toUserName?: string,
  ) => {
    const channelId = genChannelId(toUserId, fromUserId);
    startDirectMessage(fromUserId, toUserId);

    const defaultMessage = t("event:sample.networking.message", {
      name: toUserName,
    });

    // Dispatching the action
    dispatch(setChatPrefilledMessage(channelId, defaultMessage));
  };

  return {
    startDirectMessage,
    startDirectNetworkingMessage,
  };
};

export default useSendMessage;
