import { ComponentProps } from "react";
import { Theme } from "@mui/material/styles";
import { CSSProperties } from "@mui/styles/withStyles";
import makeStyles from "@mui/styles/makeStyles";
import { ChipInput } from "../ChipInput";

const useStyles = makeStyles<Theme, Partial<ComponentProps<typeof ChipInput>>>(
  (theme) => ({
    input: {
      border: "none !important",
      fontFamily: theme.typography.body1.fontFamily,
      fontWeight: theme.typography.fontWeightRegular,
    } as CSSProperties,
    chipsContainer: (props) => ({
      overflowY: "auto",
      maxHeight: 300,
      width: props.fullWidth ? "100%" : "fit-content",
    }),
    chips: {
      fontWeight: theme.typography.fontWeightRegular,
      margin: 5,
      color: theme.palette.present.greyBackground,
      fontSize: 12,
      borderRadius: 10,
      border: `solid 1px ${theme.palette.gray.medium}`,
      backgroundColor: theme.palette.gray.lightShade,
      height: "auto",
    } as CSSProperties,
    error: {
      borderColor: theme.palette.red.main,
      backgroundColor: theme.palette.red.light,
    },
    label: {
      marginBottom: theme.spacing(1),
    },
  }),
);

export default useStyles;
