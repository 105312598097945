import { IUser } from "modules/app/types";

export const getUserName = (user?: IUser, fallback?: string) => {
  if (user?.profile?.firstName && user?.profile?.lastName) {
    return `${user.profile.firstName} ${user.profile.lastName}`;
  }

  if (user?.profile?.name) {
    return user.profile.name;
  }

  return fallback || "Unknown";
};
