import { useCallback } from "react";
import { ContextMenu } from "../components";

const useContextMenu = () => {
  const openContextMenu = useCallback(
    async (
      x: number,
      y: number,
      type: string,
      userId?: string,
      roomId?: string,
      // eslint-disable-next-line max-params
    ) => {
      if (!roomId) return null;

      return (
        <ContextMenu
          type={type}
          mouse={{ x, y }}
          userId={userId}
          roomId={roomId}
        />
      );
    },
    [],
  );

  return {
    openContextMenu,
  };
};

export default useContextMenu;
