import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { EventActivityStatusUserMap } from "../types";

export interface IEventActivityStatusSlice {
  users: EventActivityStatusUserMap;
}

export const initialState: IEventActivityStatusSlice = {
  users: {},
};

const eventActivityStatusSlice = createSlice({
  initialState,
  name: "eventActivityStatus",
  reducers: {
    setUsersActivityStatus: (
      _,
      action: PayloadAction<EventActivityStatusUserMap>,
    ) => ({
      users: action.payload,
    }),
    reset: (_) => ({
      users: {},
    }),
  },
});

export const { setUsersActivityStatus, reset } =
  eventActivityStatusSlice.actions;

export default eventActivityStatusSlice.reducer;
