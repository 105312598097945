import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";

const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: theme.spacing(1.25),
  },
  dialogContent: {
    padding: 0,
  },
}));

export default useStyles;
