import { Dialog } from "@remo-co/ui-core/src/components/Dialog";
import { DialogActions } from "@remo-co/ui-core/src/components/DialogActions";
import { DialogContent } from "@remo-co/ui-core/src/components/DialogContent";
import { DialogContentText } from "@remo-co/ui-core/src/components/DialogContentText";
import { DialogTitle } from "@remo-co/ui-core/src/components/DialogTitle";
import { IconButton } from "@remo-co/ui-core/src/components/IconButton";
import { Close as CloseIcon } from "@remo-co/ui-core/src/icons/Close";
import { Typography } from "@remo-co/ui-core/src/components/Typography";
import { Button } from "@remo-co/ui-core/src/components/Button";
import { Divider } from "@remo-co/ui-core/src/components/Divider";
import { LocalVideo } from "modules/audioVideo/components/Tile/Video/LocalVideo";
import { Box } from "@remo-co/ui-core/src/components/Box";
import { Info } from "@remo-co/ui-core/src/icons/Info";
import { useI18n } from "i18n";
import { useLocalSessionId } from "@daily-co/daily-react";
import { useSelector } from "react-redux";
import { selectUserId } from "modules/auth/redux/selectors";
import { selectCurrentTheaterId } from "modules/theater/selectors";
import { useDevices } from "modules/audioVideo/hooks";
import { CameraButton, MicrophoneButton } from "modules/eventButtons";
import { useStyles } from "./styles";
import { useConversationBroadcastFirebase } from "../firebase/useConversationBroadcastFirebase";

interface Props {
  handleClose: () => void;
}

export const StartConversationBroadcastDialog = ({ handleClose }: Props) => {
  const classes = useStyles();
  const { t } = useI18n(["event", "url"]);
  const userId = useSelector(selectUserId);
  const theaterId = useSelector(selectCurrentTheaterId);
  const { cameraActive, microphoneActive } = useDevices();
  const localSessionId = useLocalSessionId();
  const { startConversationBroadcast } = useConversationBroadcastFirebase();

  const handleStartConversationBroadcast = () => {
    if (theaterId && userId) {
      startConversationBroadcast(theaterId, {
        sessionId: localSessionId,
        sender: userId,
      });
    }

    handleClose();
  };

  return (
    <Dialog
      open
      onClose={handleClose}
      classes={{
        paper: classes.startBroadcastDialog,
        root: classes.startBroadcastDialogTitle,
      }}
    >
      <DialogTitle variant="h3">{t("event:start.live.broadcast")}</DialogTitle>
      <IconButton
        onClick={handleClose}
        className={classes.closeButton}
        size="small"
        data-testid="close-icon"
      >
        <CloseIcon />
      </IconButton>
      <DialogContent className={classes.startBroadcastDialogContent}>
        <Divider orientation="horizontal" variant="fullWidth" />
        <DialogContentText className={classes.dialogContentText}>
          <Typography>{t("event:start.broadcast.description")}</Typography>
          <Typography>
            {t("event:start.broadcast.dialog.description")}
          </Typography>
          <Typography>
            <a
              href={t("url:conversation.broadcast.guide")}
              target="_blank"
              rel="noopener noreferrer"
              className={classes.urlLink}
            >
              {t("event:learn.how.to.use.this.feature")}
            </a>
          </Typography>
        </DialogContentText>
        <div className={classes.video}>
          <Box className={classes.videoContainer}>
            <LocalVideo isClickable={false} showName={false} />
            {!cameraActive && !microphoneActive && (
              <Box className={classes.overlay}>
                <Typography className={classes.overlayText}>
                  {t("event:turn.on.your.mic.and.cam.to.start.broadcasting")}
                </Typography>
              </Box>
            )}
          </Box>
        </div>
        <div className={classes.deviceControls}>
          <CameraButton animate askForConfirmation hideOptions />
          <MicrophoneButton askForConfirmation hideOptions />
        </div>
        <Box className={classes.infoBox}>
          <Info fontSize="small" className={classes.infoIcon} />
          <Typography>
            {t("event:control.your.mic.and.cam.during.broadcast")}
          </Typography>
        </Box>
        <DialogActions className={classes.dialogActionContainer}>
          <Button
            fullWidth
            onClick={handleStartConversationBroadcast}
            disabled={!cameraActive && !microphoneActive}
          >
            {t("event:start.broadcast")}
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};
