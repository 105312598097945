import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";

export const useStyles = makeStyles((theme) => ({
  detailButton: {
    width: "100%",
    background: "none",
    border: "none",
    color: theme.palette.green.main,
    padding: 0,
    textAlign: "left",
  },
  detailsList: {
    marginLeft: "0.5rem",
    padding: 0,
    listStyleType: "none",
  },
  success: {
    color: theme.palette.green.main,
    paddingRight: "0.5rem",
  },
  warning: {
    color: theme.palette.yellow.dark,
    paddingRight: "0.5rem",
  },
  listItem: {
    backgroundColor: "#EDF7ED",
    margin: "8px 0",
    display: "flex",
    alignItems: "center",
  },
  title: {
    color: theme.palette.green.main,
    textDecoration: "underline",
    marginTop: "8px 0",
    cursor: "pointer",
  },
}));
