import { Button } from "@remo-co/ui-core/src/components/Button";
import { Tooltip } from "@remo-co/ui-core/src/components/Tooltip";
import { Typography } from "@remo-co/ui-core/src/components/Typography";
import classNames from "classnames";
import { useI18n } from "i18n";
import { FLOOR_PLAN_THEMES } from "modules/customFloorPlan";
import { useStyles } from "./styles";

interface Props {
  isSelected: boolean;
  theme: string;
  count: number;
  onClick: (theme: string) => void;
}

const ThemeButton = ({
  theme,
  isSelected,
  count,
  onClick,
}: Props): JSX.Element => {
  const { t } = useI18n(["common", "eventForm"]);
  const styles = useStyles();

  return (
    <Tooltip
      placement="top"
      title={`${t("eventForm:choose.theme", {
        key: `${theme}`,
      })}`}
    >
      <Button
        data-testid={theme}
        aria-label={t("eventForm:choose.theme", { key: theme })}
        onClick={() => onClick(theme)}
        variant="secondary"
        color="dark"
        className={classNames(styles.themeButton, {
          [styles.active]: isSelected,
        })}
        contentClassName={styles.themeButtonContent}
      >
        <div
          className={classNames(
            styles.themeButtonIcon,
            styles[theme as FLOOR_PLAN_THEMES],
          )}
        />
        <div className={styles.themeText}>
          <Typography variant="body1" className={styles.themeName}>
            {t(`eventForm:type.${theme}`)}
          </Typography>
          <Typography variant="caption" className={styles.themeCount}>
            {t("eventForm:floor.plan.count", { key: count })}
          </Typography>
        </div>
      </Button>
    </Tooltip>
  );
};

export default ThemeButton;
