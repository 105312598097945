import {
  useCallback,
  useRef,
  useState,
  MouseEvent as ReactMouseEvent,
} from "react";
import { ClickAwayListener } from "@remo-co/ui-core/src/components/ClickAwayListener";
import { IconButton } from "@remo-co/ui-core/src/components/IconButton";
import { Popper } from "@remo-co/ui-core/src/components/Popper";
import { Gif as GifIcon } from "@remo-co/ui-core/src/icons/Gif";
import { GiphyContainer } from "../Giphy/GiphyContainer";

export const GifButton = (): JSX.Element => {
  const anchorEl = useRef<HTMLButtonElement | null>(null);
  const [open, setOpen] = useState(false);

  const handleGifContainerToggle = useCallback(() => {
    setOpen((prev) => !prev);
  }, []);

  const hideGifContainer = useCallback(
    (e: MouseEvent | TouchEvent | ReactMouseEvent) => {
      if (e.target instanceof Node && !anchorEl.current?.contains(e.target)) {
        setOpen(false);
      }
    },
    [],
  );

  return (
    <>
      <IconButton
        data-testid="giphy-button"
        ref={anchorEl}
        onClick={handleGifContainerToggle}
        disableRipple
        size="large"
      >
        <GifIcon />
      </IconButton>
      <ClickAwayListener onClickAway={hideGifContainer} mouseEvent="onMouseUp">
        <Popper
          open={open}
          anchorEl={anchorEl.current}
          placement="top"
          style={{
            zIndex: 1,
          }}
          popperOptions={{
            modifiers: [
              {
                name: "preventOverflow",
                enabled: true,
                options: {
                  boundariesElement: "scrollParent",
                },
              },
            ],
          }}
        >
          <GiphyContainer hideGifContainer={handleGifContainerToggle} />
        </Popper>
      </ClickAwayListener>
    </>
  );
};
