import { useI18n } from "i18n";
import { useAppDispatch } from "store/hooks";
import { TABS, setPanelState } from "modules/rightPanel";
import { setPollsTabId } from "modules/rightPanel/redux/slice";
import { Button } from "@remo-co/ui-core/src/components/Button";
import { EventPollStatus } from "graphql/generated";
import { useStyles } from "./styles";

interface Props {
  pollType: string;
}

export const PollsNotification = ({ pollType }: Props): JSX.Element => {
  const { t } = useI18n(["event"]);
  const classes = useStyles();
  const dispatch = useAppDispatch();

  return (
    <div className={classes.pollNotification} data-testid="poll-notification">
      {t("event:poll.quiz.launched", {
        pollType: t(`event:${pollType.toLowerCase()}`),
      })}
      <Button
        className={classes.notificationButton}
        onClick={() => {
          dispatch(
            setPanelState({
              isOpen: true,
              tabId: TABS.POLLS,
            }),
          );
          dispatch(setPollsTabId(EventPollStatus.Ongoing));
        }}
      >
        {t("event:button.answer")}
      </Button>
    </div>
  );
};
