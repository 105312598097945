import DailyIframe from "@daily-co/daily-js";
import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  FORCED_DESKTOP_EXPERIENCE,
  FORCED_MOBILE_EXPERIENCE,
  isMobileDevice,
} from "./utils";

export const enumerateDevices = createAsyncThunk(
  "devices/loadDevices",
  async () => {
    const callObject = DailyIframe.getCallInstance();

    return callObject
      ? callObject.enumerateDevices().then(({ devices }) => devices)
      : navigator.mediaDevices.enumerateDevices();
  },
);

export const detectMobile = createAsyncThunk<
  boolean,
  { forceDesktop: string | null; forceMobile: string | null }
>("devices/detectMobile", ({ forceDesktop, forceMobile }) => {
  const hasForceDesktopQueryParam = forceDesktop !== null;

  if (hasForceDesktopQueryParam) {
    const shouldForceDesktopExperience = forceDesktop === "true";

    if (shouldForceDesktopExperience) {
      localStorage.setItem(FORCED_DESKTOP_EXPERIENCE, "true");
    } else {
      localStorage.removeItem(FORCED_DESKTOP_EXPERIENCE);
    }
  }

  const hasForceMobileQueryParam = forceMobile !== null;

  if (hasForceMobileQueryParam) {
    const shouldForceMobileExperience = forceMobile === "true";

    if (shouldForceMobileExperience) {
      localStorage.setItem(FORCED_MOBILE_EXPERIENCE, "true");
    } else {
      localStorage.removeItem(FORCED_MOBILE_EXPERIENCE);
    }
  }

  return isMobileDevice();
});

export const askDevicesPermissions = createAsyncThunk(
  "devices/askPermissions",
  async (constraints: MediaStreamConstraints, { dispatch }) => {
    // daily seems to be calling this internally too - can consider getting rid of our own calls
    const stream = await navigator.mediaDevices.getUserMedia(constraints);
    dispatch(enumerateDevices());

    return stream;
  },
);
