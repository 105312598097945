import { useContext, useMemo } from "react";
import { Typography } from "@remo-co/ui-core/src/components/Typography";
import { useI18n } from "i18n";
import { MANAGE_EVENT_CONTEXT } from "modules/manageEvent";
import { getMappingLocation } from "../../utils";
import { ContentWithConsolidatedMapping } from "../../types";
import { useStyles } from "./styles";

interface Props {
  content: ContentWithConsolidatedMapping;
}

export const ContentListItemLocation = ({ content }: Props) => {
  const { state } = useContext(MANAGE_EVENT_CONTEXT);
  const { t } = useI18n(["eventForm"]);
  const styles = useStyles();

  const { eventData } = state ?? {};
  const theater = eventData?.theaters?.[0];
  const floors = useMemo(() => theater?.spaces ?? [], [theater?.spaces]);
  const location = useMemo(
    () => getMappingLocation(content, floors),
    [content, floors],
  );

  let display = "location.some.floors.slot";

  if (content.mappings.length === floors.length) {
    display =
      floors.length === 1
        ? "location.one.floor.slot"
        : "location.all.floors.slot";
  }

  return (
    <Typography className={styles.root}>
      {t(display, {
        floors: location.floors
          .map((floor) => floors.indexOf(floor) + 1)
          .join(", "),
        slot: +content.mappings[0].slot + 1,
      })}
    </Typography>
  );
};
