import { sendPostRequest } from "services/apiService";
import {
  NewWhiteboardPayload,
  NewWhiteboardResponse,
} from "modules/whiteboard/types";

export const createMiroWhiteboard = async (
  payload: NewWhiteboardPayload,
  maxAttempts = 0,
): Promise<NewWhiteboardResponse> => {
  try {
    const resp = await sendPostRequest<
      NewWhiteboardPayload,
      NewWhiteboardResponse
    >("/miro/board", payload);

    return resp;
  } catch (error) {
    if (maxAttempts !== 0) {
      return createMiroWhiteboard(payload, maxAttempts - 1);
    }

    throw error;
  }
};
