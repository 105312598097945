import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "store";

const selectAppSetting = (state: RootState) => state.appSetting;

export const selectAppLogo = createSelector(
  selectAppSetting,
  (substate) => substate.appLogo,
);

export const selectAppInBackground = createSelector(
  selectAppSetting,
  (substate) => substate.isAppInBackground,
);

export const selectUserOnBoard = createSelector(
  selectAppSetting,
  (substate) => substate.userOnBoard,
);

export const selectStaticAnimations = createSelector(
  selectAppSetting,
  (substate) => substate.staticAnimations,
);
