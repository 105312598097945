import ActionTypes from "./constants";

const showCamAndMicPopover = (value: boolean) => ({
  type: ActionTypes.SHOW_CAM_AND_MIC_POPOVER,
  value,
});

export default {
  showCamAndMicPopover,
};
