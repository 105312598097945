import { Box } from "@remo-co/ui-core/src/components/Box";
import { Button } from "@remo-co/ui-core/src/components/Button";
import { Typography } from "@remo-co/ui-core/src/components/Typography";
import { useI18n } from "i18n";
import { useQuery } from "@apollo/client";
import { GET_POLLS } from "modules/polls/graphql";
import {
  GetEventPollsQuery,
  GetEventPollsQueryVariables,
} from "graphql/generated";
import { useMemo } from "react";
import utils from "modules/polls/utils";
import { IEvent } from "modules/event/types";
import { useStyles } from "./styles";

interface Props {
  eventData: IEvent;
}

export const ExportPolls = ({ eventData }: Props) => {
  const styles = useStyles();
  const { t } = useI18n(["event", "url"]);
  const { loading, data } = useQuery<
    GetEventPollsQuery,
    GetEventPollsQueryVariables
  >(GET_POLLS, {
    variables: {
      eventId: eventData.id,
      includeUserAnswers: true,
    },
    fetchPolicy: "network-only",
  });

  const polls = useMemo(() => {
    if (data?.eventPolls.__typename === "GQLError") {
      return [];
    }

    return data?.eventPolls.polls ?? [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.eventPolls]);

  return (
    <Box className={styles.wrap}>
      <div>
        <Typography variant="h4" className={styles.header}>
          {t("event:export.polls.header")}
        </Typography>
        <Typography variant="body1">
          {t("event:export.polls.description")}
        </Typography>
      </div>
      <Button
        variant="secondary"
        color="blue"
        disabled={loading || polls.length === 0}
        className={styles.button}
        onClick={() => {
          utils.downloadPostEventCSV(`${eventData.name}-polls.csv`, polls);
        }}
        data-testid="polls-export-button"
      >
        {t("event:export.polls.button")}
      </Button>
    </Box>
  );
};
