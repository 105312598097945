import { selectCurrentTheaterId } from "modules/theater/selectors";
import { useEffect } from "react";
import { useQueryClient } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import { off, onValue } from "firebase/database";
import { LAYOUT_CACHE_KEY } from "./constants";
import { getLayoutTilesDbRef, parseActiveTiles } from "./dal";
import { LayoutCollection } from "./types";

export const useLayoutSubscription = () => {
  const theaterId = useSelector(selectCurrentTheaterId);
  const queryClient = useQueryClient();

  useEffect(() => {
    if (!theaterId) {
      return undefined;
    }
    const layoutRef = getLayoutTilesDbRef(theaterId);

    off(layoutRef);
    onValue(layoutRef, async (snap) => {
      const data = (await snap.val()) as LayoutCollection | null;
      if (!data) {
        queryClient.setQueryData([LAYOUT_CACHE_KEY, theaterId], () => []);
        return;
      }

      const activeTiles = parseActiveTiles(data);

      queryClient.setQueryData(
        [LAYOUT_CACHE_KEY, theaterId],
        () => activeTiles,
      );
    });

    return () => {
      off(layoutRef);
    };
  }, [theaterId, queryClient]);
};
