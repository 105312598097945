import { Theme } from "@remo-co/ui-core/src/types";
import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";

const useStyles = makeStyles<Theme, { isInBroadcast: boolean }>((theme) => ({
  groupHeadingContainer: ({ isInBroadcast }) => ({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingTop: 10,
    backgroundColor: isInBroadcast ? "#1C1F2F" : theme.palette.white,
  }),
  groupHeading: {
    margin: "8px 16px",
    fontSize: "14px",
    textTransform: "capitalize",
  },
  moreButton: {
    "& .MuiButtonBase-root": {
      marginRight: "16px",
      color: "var(--White-op-090)",
      "&:hover": {
        backgroundColor: "rgba(2, 2, 2, 0.14)",
      },
    },
  },
}));

export default useStyles;
