import { useContext, useMemo } from "react";
import { EventTrackingDispatchContext } from "./eventTrackingContext";
import { IEventTrackingState, ITrackingLog } from "../types";

const ADD_LOG = "ADD_LOG";
const RESET = "RESET";
const SIZE = 100; // number of records can be tracked

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const reducer = (state: IEventTrackingState, action: any) => {
  switch (action.type) {
    case ADD_LOG:
      // eslint-disable-next-line no-case-declarations
      const records = [...state.history, action.payload.record].sort(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (a: any, b: any) => a.date - b.date,
      );
      // eslint-disable-next-line no-case-declarations
      const size = records.length < SIZE ? records.length : SIZE;
      // eslint-disable-next-line no-case-declarations
      const history = records.slice(-size);

      return { ...state, history };
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
};

export default reducer;

export const useEventTrackingReducerActions = () => {
  const dispatch = useContext(EventTrackingDispatchContext);

  const actions = useMemo(
    () => ({
      addTrackingLog(record: ITrackingLog) {
        if (dispatch) {
          dispatch({
            type: ADD_LOG,
            payload: { record },
          });
        }
      },
      reset() {
        if (dispatch) {
          dispatch({ type: RESET });
        }
      },
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return actions;
};
