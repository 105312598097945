import { useQuery } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { selectCurrentTheaterId } from "modules/theater/selectors";
import logger from "logging/logger";
import { PRESENTERS_CACHE_KEY } from "./constants";
import { getPresenters } from "./dal";

export const useActivePresenters = () => {
  const theaterId = useSelector(selectCurrentTheaterId);

  const { data: presenters = [], error } = useQuery({
    queryKey: [PRESENTERS_CACHE_KEY, theaterId],

    queryFn: async () => {
      if (!theaterId) {
        throw new Error("missing theater Id");
      }
      return getPresenters(theaterId);
    },
    enabled: theaterId !== null,
  });

  useEffect(() => {
    if (!error) return;

    const errorMessage = error.message || "unknown";
    logger.error(`[useActivePresenters] query error ${errorMessage}`);
  }, [error]);

  return {
    presenters,
  };
};
