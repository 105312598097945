import * as Immutable from "immutable";
import { IChatState } from "types/chat";
import { ActionTypes } from "./constants";
import { IChannel, IChat } from "../types";
import { getCropData, getUpdatedUnreadCount } from "../chatUtils";
import type { ChatAction } from "./actions";

export const initialState: IChatState = {
  chats: Immutable.Map<string, Immutable.List<IChat>>(),
  newMessageCount: Immutable.Map<string, number>(),
  channels: Immutable.Map<string, IChannel>(),
  chatListeners: Immutable.Map<string, string>(),
  chatMetaListeners: Immutable.Map<string, string>(),
  activeChannel: null,
  isLoading: false,
  areListenersSetup: false,
  preFilledMessage: {},
};

const chatReducer = (state = initialState, action: ChatAction): IChatState => {
  let oldChannel;
  let updatedChats;
  let updatedCropData;

  switch (action.type) {
    case ActionTypes.SET_LISTENERS_STATUS:
      return {
        ...state,
        areListenersSetup: action.payload,
      };
    case ActionTypes.UPDATE_UNREAD_COUNT:
      return {
        ...state,
        newMessageCount: state.newMessageCount.merge(
          Immutable.fromJS(action.payload),
        ),
      };
    case ActionTypes.UNSUBSCRIBE_CHANNEL:
      return {
        ...state,
        chats: state.chats.delete(action.payload.channel), // Remove all messages from current room
        channels: state.channels.delete(action.payload.channel), // Delete room from channels list
        newMessageCount: state.newMessageCount.delete(action.payload.channel), // Unset unread count for this channel
      };
    case ActionTypes.UPDATE_CHAT_CHANNEL:
      if (!action.payload.channel) {
        return state;
      }

      // Add new channel
      oldChannel = state.channels.get(action.payload.channel.id);

      return {
        ...state,
        channels: state.channels.set(
          action.payload.channel.id,
          oldChannel
            ? { ...oldChannel, ...action.payload.channel }
            : action.payload.channel,
        ),
      };

    // Reset unread count when changing channel
    case ActionTypes.UPDATE_ACTIVE_CHANNEL:
      return {
        ...state,
        activeChannel: action.payload.channel,
        newMessageCount: action.payload.channel
          ? state.newMessageCount.set(action.payload.channel, 0)
          : state.newMessageCount,
      };
    case ActionTypes.SET_IS_LOADING:
      return {
        ...state,
        isLoading: action.payload.isLoading,
      };
    case ActionTypes.RECEIVED_MESSAGES:
      if (!action.payload.newMessage) {
        const newChatsList = Immutable.List<IChat>(
          action.payload.messages.reverse(),
        );
        // Get current messages and add at beginning of the new messages with the list
        const currentChatsInChannel = state.chats.get(
          action.payload.channel,
          Immutable.List<IChat>(),
        );

        return {
          ...state,
          chats: state.chats.set(
            action.payload.channel,
            newChatsList.concat(currentChatsInChannel) as Immutable.List<IChat>,
          ),
        };
      }

      // Push new message to existing array
      updatedChats = state.chats.updateIn(
        [action.payload.channel],
        (arr = Immutable.List<IChat>()) => arr.concat(action.payload.messages),
      );

      // If current channel is open, dont crop data
      if (
        action.payload.channel &&
        state.activeChannel !== action.payload.channel
      ) {
        const croppedData = getCropData(
          updatedChats,
          action.payload.channel,
          50,
          state.channels,
        );

        return {
          ...state,
          chats: croppedData.chats,
          channels: croppedData.channels,
          newMessageCount: getUpdatedUnreadCount(
            state.newMessageCount,
            { ...action.payload, messages: action.payload.messages.toArray() },
            state.activeChannel,
            false,
            state.channels.get(action.payload.channel),
          ),
        };
      }

      return {
        ...state,
        chats: updatedChats,
        newMessageCount: getUpdatedUnreadCount(
          state.newMessageCount,
          { ...action.payload, messages: action.payload.messages.toArray() },
          state.activeChannel,
          false,
          state.channels.get(action.payload.channel),
        ),
      };

    case ActionTypes.REMOVE_MESSAGE_FROM_CHANNEL:
      return {
        ...state,
        // Remove message from channel
        chats: state.chats.updateIn(
          [action.payload.channel],
          (arr: Immutable.List<IChat> = Immutable.List<IChat>()) =>
            arr.filter((item) => item?.id !== action.payload.message.id),
        ),
        newMessageCount: getUpdatedUnreadCount(
          state.newMessageCount,
          action.payload,
          state.activeChannel,
          true,
          state.channels.get(action.payload.channel),
        ),
      };
    // Updates firestore listening time per channel
    case ActionTypes.UPDATE_CHANNEL_PAGINATION:
      if (state.channels.get(action.payload.channel)) {
        const channel = state.channels.get(action.payload.channel);
        if (!channel) return state;

        const currentChannel: IChannel = {
          ...channel,
          listensFrom: action.payload.time,
          paginateAfter: action.payload.paginateAfter,
          stopPagination: action.payload.stopPagination,
        };

        return {
          ...state,
          channels: state.channels.set(action.payload.channel, currentChannel),
        };
      }

      return state;
    case ActionTypes.CROP_MESSAGES:
      updatedCropData = getCropData(
        state.chats,
        action.payload.channel,
        action.payload.maxMessages,
        state.channels,
      );

      return {
        ...state,
        chats: updatedCropData.chats,
        channels: updatedCropData.channels,
      };
    case ActionTypes.SET_CHAT_PREFILLED_MESSAGE: {
      const { channel, message } = action.payload;
      return {
        ...state,
        preFilledMessage: { ...state.preFilledMessage, [channel]: message },
      };
    }
    case ActionTypes.RESET_CHAT:
      return initialState;
    default:
      return state;
  }
};

export default chatReducer;
