import { useAppMessage, useScreenShare } from "@daily-co/daily-react";
import {
  CustomAppEvent,
  CustomAppEvents,
} from "modules/audioVideo/DailyEvents";
import { DailyEventObjectAppMessage } from "@daily-co/daily-js";
import { useCallback } from "react";

export const StopScreenShareEvent = () => {
  const { stopScreenShare } = useScreenShare();

  useAppMessage<CustomAppEvent>({
    onAppMessage: useCallback(
      (ev: DailyEventObjectAppMessage) => {
        if (ev.data.event === CustomAppEvents.STOP_SCREEN_SHARE) {
          stopScreenShare();
        }
      },
      [stopScreenShare],
    ),
  });

  return null;
};
