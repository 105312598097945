import { SwitchFloorErrors } from "./constants";

const getErrorMessage = (reason: unknown): string => {
  switch (reason) {
    case SwitchFloorErrors.SEAT_NOT_FOUND:
    case SwitchFloorErrors.FLOOR_CAPACITY_EXCEEDED:
      return "server:warning.floor.full";
    case SwitchFloorErrors.EXIT_DENIED:
      return "server:exit.denied";
    default:
      return "server:floor.unhandled";
  }
};

export default getErrorMessage;
