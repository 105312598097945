import { selectIsEventManager } from "modules/event/selectors";
import React from "react";
import { useSelector } from "react-redux";
import useRaiseHandActions from "./hooks/useRaiseHandActions";

interface IRaisedHandUsersContainerProps {
  theaterId: string;
}

const RaisedHandUsersContainer = ({
  theaterId,
}: IRaisedHandUsersContainerProps) => {
  const hasEventManagerAccess = useSelector(selectIsEventManager);
  const { subscribe, unsubscribe } = useRaiseHandActions();

  React.useEffect(() => {
    if (!hasEventManagerAccess) {
      return;
    }
    subscribe(theaterId);

    // eslint-disable-next-line consistent-return
    return () => {
      unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasEventManagerAccess]);

  return null;
};

export default RaisedHandUsersContainer;
