import { Theme } from "@remo-co/ui-core/src/types";
import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";

export const useStyles = makeStyles<Theme>(() => ({
  closeButton: {
    position: "absolute",
    right: "15px",
    top: "15px",
  },
  startBroadcastDialogContent: {
    padding: 0,
  },
  startBroadcastDialog: {
    width: "400px",
    borderRadius: "10px",
    padding: 0,
  },
  dialogContentText: {
    padding: "20px",
    gap: "10px",
    display: "grid",
    paddingBottom: 0,
  },
  videoContainer: {
    width: "300px",
    height: "225px",
    margin: "20px",
    paddingTop: 0,
    borderRadius: "5px",
    paddingBottom: 0,
    position: "relative",
  },
  video: {
    display: "flex",
    justifyContent: "center",
  },
  dialogActionContainer: {
    justifyContent: "center",
    padding: "20px",
    paddingTop: 0,
  },
  infoBox: {
    margin: "20px",
    background: "#E7F3FE",
    padding: "10px",
    borderRadius: "10px",
    display: "flex",
    gap: "10px",
    alignItems: "center",
  },
  infoIcon: {
    color: "#2196F3",
  },
  overlay: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.7)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  overlayText: {
    color: "#fff",
    textAlign: "center",
    width: "225px",
  },
  urlLink: {
    color: "#0E1F3C",
  },
  deviceControls: {
    display: "flex",
    justifyContent: "center",
    gap: "50px",
  },
}));
