import { useCallback } from "react";
import { useAppDispatch } from "store/hooks";
import { Notification } from "react-notification-system";
import * as sliceActions from "../redux/notificationSlice";

const useNotificationActions = () => {
  const dispatch = useAppDispatch();
  const addSuccessNotification = useCallback((notification: Notification) => {
    dispatch(sliceActions.addSuccessNotification(notification));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const addErrorNotification = useCallback((notification: Notification) => {
    dispatch(sliceActions.addErrorNotification(notification));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const addWarningNotification = useCallback((notification: Notification) => {
    dispatch(sliceActions.addWarningNotification(notification));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const addInfoNotification = useCallback((notification: Notification) => {
    dispatch(sliceActions.addInfoNotification(notification));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const removeNotification = useCallback((id: string | number) => {
    dispatch(sliceActions.removeNotification(id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const removeAllNotifications = useCallback(() => {
    dispatch(sliceActions.removeAllNotifications());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    addSuccessNotification,
    addErrorNotification,
    addWarningNotification,
    addInfoNotification,
    removeNotification,
    removeAllNotifications,
  };
};

export default useNotificationActions;
