import { useRef, useCallback } from "react";
import { useSelector } from "react-redux";
import { selectIsEventManager } from "modules/event/selectors";
import { selectIsInBroadcast } from "modules/broadcast/redux/selectors";
import { HostReplaceScreenText } from "modules/broadcastVideoView/HostReplaceScreenText";
import { FullScreenButton } from "modules/eventButtons";
import classnames from "classnames";
import {
  selectActiveTile,
  selectLayout,
} from "modules/audioVideo/redux/selectors";
import { SharedScreenText } from "../../Mode/SharedScreenText";
import {
  DisplayItem,
  DisplayItemConfig,
  DisplayLayout,
  LayoutTileProps,
} from "../Layouts/types";
import { useStyles } from "./styles";
import { TileSelector } from "./TileSelector";

export const LayoutTile = ({
  className,
  tile,
  onTileClick,
}: LayoutTileProps) => {
  const tileRef = useRef(null);

  const layout = useSelector(selectLayout);
  const activeTile = useSelector(selectActiveTile);
  const styles = useStyles({
    isWhiteBoardFocused:
      activeTile?.streamId === DisplayItemConfig.whiteboardDisplayId,
  });
  const hasEventManagerAccess = useSelector(selectIsEventManager);
  const isInBroadcast = useSelector(selectIsInBroadcast);
  const showFullScreenButton =
    layout === DisplayLayout.Focus &&
    activeTile?.streamId === tile.streamId &&
    tile.type !== DisplayItem.eventContent;

  const getElement = useCallback(() => tileRef.current, []);

  const handleTileClick = useCallback(() => {
    if (!onTileClick) return;
    onTileClick(tile.streamId);
  }, [onTileClick, tile.streamId]);

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
    <div
      key={tile.streamId}
      className={classnames(className, styles.container)}
      data-testid="layout-tile"
      ref={tileRef}
      onClick={handleTileClick}
    >
      {layout === DisplayLayout.Focus &&
        isInBroadcast &&
        activeTile?.streamId !== tile.streamId &&
        hasEventManagerAccess && (
          <HostReplaceScreenText
            isScreenType={tile.streamId.includes("-screen")}
          />
        )}
      {layout === DisplayLayout.Focus &&
        isInBroadcast &&
        activeTile?.streamId !== tile.streamId && (
          <SharedScreenText
            hasEventManagerAccess={hasEventManagerAccess}
            tileType={tile.type}
          />
        )}
      {showFullScreenButton && (
        <div
          data-testid="displayItem-fullScreenButton"
          className={styles.fullScreenButtonWrapper}
        >
          <FullScreenButton getElement={getElement} />
        </div>
      )}
      <TileSelector tile={tile} isInBroadcast={Boolean(isInBroadcast)} />
    </div>
  );
};
