import { getVar } from "config";

const getClientHostName = (): URL => {
  if (getVar("REACT_APP_PREVIEW_HOST")) {
    return new URL(getVar("REACT_APP_PREVIEW_HOST") || "") as URL;
  }

  return new URL(getVar("REACT_APP_CLIENT_HOST")) as URL;
};

export const getRemoHost = (): URL["host"] => new URL(getClientHostName()).host;

export const getRemoUrl = (): URL["href"] => new URL(getClientHostName()).href;
