import { unwrapResult } from "@reduxjs/toolkit";
import { useCallback } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "store/hooks";
import {
  askDevicesPermissions,
  selectAvailableMediaStreamConstraints,
} from "modules/deviceInfo";

type MicCamPermissionsProps = {
  onGranted: () => void;
  onDenied: () => void;
};

export const useDevicePermissions = ({
  onGranted,
  onDenied,
}: MicCamPermissionsProps) => {
  const dispatch = useAppDispatch();
  const availableMediaStreamConstraints = useSelector(
    selectAvailableMediaStreamConstraints,
  );

  const requestPermissions = async () => {
    const stream = unwrapResult(
      await dispatch(askDevicesPermissions(availableMediaStreamConstraints)),
    );
    const [track] = stream.getTracks();
    track.addEventListener("ended", onDenied);

    return stream;
  };

  const checkPermissions = useCallback(async () => {
    let currentStream: MediaStream;

    try {
      const stream = await requestPermissions();
      currentStream = stream;
      onGranted();
    } catch (error) {
      onDenied();
    }

    return () => {
      if (currentStream) {
        currentStream.getTracks().forEach((track) => track.stop());
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onDenied, onGranted]);

  return {
    checkPermissions,
  };
};
