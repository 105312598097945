import { useI18n } from "i18n";
import useNotificationActions from "modules/notification/hooks/useNotificationActions";
import eventMemberApi from "../eventMember.api";

const useAttendeeActions = () => {
  const { t } = useI18n(["server"]);
  const { addErrorNotification, addSuccessNotification } =
    useNotificationActions();

  const updateEventMemberAccess = async (
    eventId: string,
    theaterId: string | undefined,
    userId: string,
    isBlocked: boolean,
    // eslint-disable-next-line max-params
  ) => {
    const resp = await eventMemberApi.updateEventAttendeeAccess({
      eventId,
      theaterId,
      userId,
      isBlocked,
    });

    if (resp.isSuccess) {
      addSuccessNotification({
        message: "Updated member access successfully!",
        position: "tc",
        autoDismiss: 5,
      });
    } else {
      addErrorNotification({
        message: resp.message ? t(resp.message) : "Oops! Something went wrong!",
        position: "tc",
        autoDismiss: 5,
      });
    }

    return resp;
  };

  return {
    updateEventMemberAccess,
  };
};

export default useAttendeeActions;
