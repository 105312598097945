import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";

export const useStyles = makeStyles(() => ({
  soundButton: {
    "& svg": {
      width: 22,
      padding: 5,
      paddingBottom: 0,
    },
  },
}));
