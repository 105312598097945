import { RootState } from "store";
import { createSelector } from "@reduxjs/toolkit";

export const selectAudienceViewState = (state: RootState) => state.audienceView;

export const selectIsAudienceViewExpanded = createSelector(
  selectAudienceViewState,
  (audienceViewState) => audienceViewState.isExpanded,
);

export const selectAudienceViewSlots = createSelector(
  selectAudienceViewState,
  (audienceViewState) => audienceViewState.slots,
);

export const selectIsContainerMounted = createSelector(
  selectAudienceViewState,
  (audienceViewState) => audienceViewState.isContainerMounted,
);
