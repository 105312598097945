import { collection, getDocs } from "firebase/firestore";
import logger from "logging/logger";
import { EventType } from "modules/manageEvent/types";
import { firestore } from "../firebaseService/firebaseConfig";

const getEventTypesDB = () => collection(firestore, "EventTypes");

export const getEventTypeOptions = async (): Promise<EventType[]> => {
  try {
    const snapshots = await getDocs(getEventTypesDB());

    const sortedEventTypes = snapshots.docs
      .map((doc) => doc.data()?.eventTypes)
      .filter(Boolean)[0]
      .sort((first: EventType, second: EventType) =>
        first.label < second.label ? -1 : 1,
      );

    // Sort the event type options alphabetically by label
    return sortedEventTypes;
  } catch (error) {
    if (error instanceof Object) {
      logger.error("[getEventTypes] Error reading from firestore", error);
    }
  }

  return [];
};
