import { ReactElement } from "react";
import { DailyProvider, useCallObject } from "@daily-co/daily-react";

export const AudioVideoProvider = ({
  children,
}: {
  children: ReactElement;
}) => {
  const callObject = useCallObject({});

  if (!callObject) {
    return null;
  }

  return <DailyProvider callObject={callObject}>{children}</DailyProvider>;
};
