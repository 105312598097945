import { Theme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import { spacingToPx } from "../../utils/spacingToPx";
import { createResponsiveRules } from "../../utils/createResponsiveRules";
import { SizeType, SpacingType } from "../../types";
import { generateMarginMap } from "./generateMarginMap";
import { sizeMap } from "./sizeMap";
import { roundedMap } from "./roundedMap";
import { IContainerProps } from ".";

export const ALIGN_ITEMS_DEFAULT_VALUE = "center";

export const FULL_WIDTH_DEFAULT_VALUE = true;

export const JUSTIFY_CONTENT_DEFAULT_VALUE = "center";

type AlignItemsProperties =
  | "flex-start"
  | "flex-end"
  | "center"
  | "stretch"
  | "baseline";

export type JustifyContentProperties =
  | "flex-start"
  | "flex-end"
  | "center"
  | "space-between"
  | "space-around"
  | "space-evenly";

export type IContainerStylingProperties = {
  fullWidth?: boolean;
  top?: number | SpacingType;
  left?: number | SpacingType;
  right?: number | SpacingType;
  bottom?: number | SpacingType;
  margin?: number | SpacingType;
  border?: boolean;
  size?: SizeType;
  flex?: boolean;
  alignItems?: AlignItemsProperties;
  justifyContent?: JustifyContentProperties;
  column?: boolean;
  inline?: boolean;
  rounded?: SizeType;
};

export enum SpacingEnum {
  xs = 8,
  sm = 16,
  md = 24,
  lg = 32,
  xl = 40,
}

export const useStyles = makeStyles<Theme, IContainerProps>(
  ({ palette, typography: { fontSize, fontFamily }, breakpoints }) => ({
    root: ({ padding, backgroundColor }) => ({
      fontSize,
      fontFamily,
      backgroundColor: backgroundColor ?? "none",
      padding: padding ? spacingToPx(padding) : 0,
      margin: 0,
      width: "unset",
    }),
    flex: (props) =>
      createResponsiveRules({
        breakpoints,
        props,
        rule: props.flex,
        valueMapper: (flex) => ({
          display: flex ? "flex" : undefined,
          "&$inline": {
            display: flex ? "inline-flex" : undefined,
          },
        }),
      }),
    column: (props) =>
      createResponsiveRules({
        breakpoints,
        props,
        rule: props.column,
        valueMapper: (column) => ({
          flexDirection: column ? "column" : "row",
        }),
      }),
    inline: (props) =>
      createResponsiveRules({
        breakpoints,
        props,
        rule: props.inline,
        valueMapper: (inline) => ({
          display: inline ? "inline-block" : undefined,
        }),
      }),
    topMargin: (props) =>
      createResponsiveRules({
        breakpoints,
        props,
        rule: props.top,
        valueMapper: generateMarginMap("marginTop"),
      }),
    rightMargin: (props) =>
      createResponsiveRules({
        breakpoints,
        props,
        rule: props.right,
        valueMapper: generateMarginMap("marginRight"),
      }),
    bottomMargin: (props) =>
      createResponsiveRules({
        breakpoints,
        props,
        rule: props.bottom,
        valueMapper: generateMarginMap("marginBottom"),
      }),
    leftMargin: (props) =>
      createResponsiveRules({
        breakpoints,
        props,
        rule: props.left,
        valueMapper: generateMarginMap("marginLeft"),
      }),
    border: (props) =>
      createResponsiveRules({
        breakpoints,
        props,
        rule: props.border,
        valueMapper: (border) => ({
          border: border
            ? `1px solid ${props.borderColor ?? palette.gray.light}`
            : undefined,
        }),
      }),
    fullWidth: (props) =>
      createResponsiveRules({
        breakpoints,
        props,
        rule: props.fullWidth,
        valueMapper: (fullWidth) => ({
          width: fullWidth ?? FULL_WIDTH_DEFAULT_VALUE ? "100%" : "auto",
        }),
      }),

    size: (props) =>
      createResponsiveRules({
        breakpoints,
        props,
        rule: props.size,
        valueMapper: sizeMap,
      }),

    rounded: (props) =>
      createResponsiveRules({
        breakpoints,
        props,
        rule: props.rounded,
        valueMapper: roundedMap,
      }),

    alignItems: (props) =>
      createResponsiveRules({
        breakpoints,
        props,
        rule: props.alignItems,
        valueMapper: (alignItems) => ({
          alignItems: alignItems ?? ALIGN_ITEMS_DEFAULT_VALUE,
        }),
      }),

    justifyContent: (props) =>
      createResponsiveRules({
        breakpoints,
        props,
        rule: props.justifyContent,
        valueMapper: (justifyContent) => ({
          justifyContent: justifyContent ?? JUSTIFY_CONTENT_DEFAULT_VALUE,
        }),
      }),
  }),
);
