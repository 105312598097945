import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ContentWithMapping } from "graphql/generated";

interface InitialState {
  isFullScreenEventContentOpen: boolean;
  content: ContentWithMapping | null;
}

export const initialState: InitialState = {
  isFullScreenEventContentOpen: false,
  content: null,
};

const slice = createSlice({
  name: "eventContent",
  initialState,
  reducers: {
    openFullScreenEventContent: (
      state,
      action: PayloadAction<ContentWithMapping>,
    ) => {
      state.isFullScreenEventContentOpen = true;
      state.content = action.payload;
    },
    closeFullScreenEventContent: (state) => {
      state.isFullScreenEventContentOpen = false;
      state.content = null;
    },
  },
});

export const { openFullScreenEventContent, closeFullScreenEventContent } =
  slice.actions;

export default slice.reducer;
