import { useMutation } from "@tanstack/react-query";
import { useI18n } from "i18n";
import { Errors, trackError } from "modules/monitoring";
import useNotificationActions from "modules/notification/hooks/useNotificationActions";
import { useContext } from "react";
import { Events, TRACKING_CONTEXT } from "modules/tracking";
import { EnforceAssignmentErrors } from "../../constants";
import request from "./request";

const useProcessEnforceTableAssignment = (
  eventId?: string,
  presetId?: string,
) => {
  const { track } = useContext(TRACKING_CONTEXT);
  const { addErrorNotification, addSuccessNotification } =
    useNotificationActions();
  const { t } = useI18n(["manageEvent"]);

  const { mutate: process, status } = useMutation({
    mutationFn: async () => {
      if (!eventId) {
        throw new Error(EnforceAssignmentErrors.NO_EVENT_ID);
      }

      return request(eventId, presetId);
    },

    onSuccess: () => {
      addSuccessNotification({
        message: t("manageEvent:move.attendees.success"),
        position: "tc",
      });

      track(Events.ASSIGN_TABLE_MOVE_ALL_COMPLETED);
    },

    onError: (error: Error, _) => {
      trackError(error, {
        label: Errors.ASSIGN_TABLES_ALL_ATTENDEE_MOVE,
        eventId,
      });
      addErrorNotification({
        message: t("manageEvent:move.attendees.error"),
        position: "tc",
      });
    },
  });

  return {
    process,
    status,
  };
};

export default useProcessEnforceTableAssignment;
