import { createSelector } from "@reduxjs/toolkit";
import { selectUser } from "modules/auth/redux/selectors";
import { RootState } from "store";

const selectBroadcaster = (state: RootState) => state.broadcaster;

const selectBroadcasters = createSelector(
  selectBroadcaster,
  (substate) => substate.broadcasters,
);

const selectIsMeBroadcaster = createSelector(
  selectBroadcasters,
  selectUser,
  (broadcasters, user) =>
    user && Object.keys(broadcasters).indexOf(user.id) > -1,
);

export { selectBroadcaster, selectBroadcasters, selectIsMeBroadcaster };
