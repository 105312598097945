import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { AudioReactionsTypes } from "../constants";

export interface AudioReactionsState {
  showDialog: boolean;
  currentAudio: AudioReactionsTypes | undefined;
}

export const initialState: AudioReactionsState = {
  showDialog: false,
  currentAudio: undefined,
};

const audioReactionsSlice = createSlice({
  name: "audioReactions",
  initialState,
  reducers: {
    setAudioReactionDialog: (
      state: AudioReactionsState,
      action: PayloadAction<boolean>,
    ) => {
      state.showDialog = action.payload;
    },
    setCurrentAudio: (
      state: AudioReactionsState,
      action: PayloadAction<AudioReactionsTypes | undefined>,
    ) => {
      state.currentAudio = action.payload;
    },
  },
});

export const { setAudioReactionDialog, setCurrentAudio } =
  audioReactionsSlice.actions;

export default audioReactionsSlice.reducer;
