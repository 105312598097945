import { Theme } from "@mui/material/styles";

import makeStyles from "@mui/styles/makeStyles";

export const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
  },
}));
