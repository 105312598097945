import { Theme } from "@remo-co/ui-core/src/types";
import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";

export const useStyles = makeStyles<Theme>(() => ({
  pollNotification: {
    display: "flex",
    alignItems: "center",
  },
  notificationButton: {
    height: 25,
    fontSize: 13,
    left: 15,
  },
}));
