import { createSelector } from "@reduxjs/toolkit";
import { selectLiveUserInEvent } from "modules/event/usersInEvent/selectors";
import { RootState } from "store/index";

export const selectNetworkingRecommendations = (state: RootState) =>
  state.networkingRecommendations;

export const selectRecommendedUsers = createSelector(
  [selectNetworkingRecommendations, selectLiveUserInEvent],
  (networkingRecommendations, usersInEvent) =>
    networkingRecommendations.filter((user) => {
      const userId = Object.keys(user)[0];
      return usersInEvent.get(userId);
    }),
);

export const selectIsNetworkingRecommendationUser = (userId: string) =>
  createSelector(selectNetworkingRecommendations, (subState) => {
    const users = subState.map((obj) => Object.keys(obj)[0]);
    return users.find((id) => id === userId);
  });
export const selectNetworkingRecommendationUser = (userId: string) =>
  createSelector(selectNetworkingRecommendations, (subState) =>
    subState.find((obj) => Object.keys(obj)[0] === userId),
  );

export const selectNetworkingRecommendationUserCommonTopics = (
  userId: string,
) =>
  createSelector(selectNetworkingRecommendations, (recommendations) => {
    const recommendation = recommendations.find((rec) => rec[userId]);
    return recommendation?.[userId]?.commonTopics || [];
  });

export const selectNetworkingRecommendationUserRating = (userId: string) =>
  createSelector(selectNetworkingRecommendations, (recommendations) => {
    const recommendation = recommendations.find((rec) => rec[userId]);
    return recommendation?.[userId]?.rating ?? null;
  });
