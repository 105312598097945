import { Theme } from "@remo-co/ui-core/src/types";
import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";

export const useStyles = makeStyles<Theme>((theme) => ({
  createTag: {
    textDecoration: "none",
    color: theme.palette.black,
  },
  tagText: {
    padding: 10,
    height: 20,
  },
}));
