import { useState } from "react";
import { useI18n } from "i18n";
import { Box } from "@remo-co/ui-core/src/components/Box";
import { Typography } from "@remo-co/ui-core/src/components/Typography";
import { IEvent } from "modules/event/types";
import { ValidatedChipInput } from "@remo-co/ui-core/src/components/ValidatedChipInput";
import { validateDomain } from "@remo-co/url";
import { useStyles } from "./style";

const MAX_DOMAINS_LIMIT = 5;

interface Props {
  eventData: IEvent;
  updateEventData: (eventData: Partial<IEvent>) => void;
}

const EventDomain = ({ eventData, updateEventData }: Props) => {
  const { t } = useI18n(["common", "event", "eventForm"]);
  const classes = useStyles();
  const [eventDomain, setEventDomain] = useState(eventData.domain ?? "");
  const [areDomainsInvalid, setAreDomainsInvalid] = useState(true);

  const handleInputChange = (domains: string[]) => {
    const incomingValue = domains.length > 0 ? domains.join(",") : "";
    setEventDomain(incomingValue);
    if (!areDomainsInvalid && eventData.domain !== incomingValue) {
      updateEventData({ ...eventData, domain: incomingValue });
    }
  };

  const inputStartAdornment = (
    <Typography variant="subtitle1" className={classes.domainPreFix}>
      {t("eventForm:event.domain.prefix")}
    </Typography>
  );

  return (
    <Box className="mar-top-40">
      <Typography variant="h4">{t("event:privacy")}</Typography>
      <Box className="mar-top-20">
        <ValidatedChipInput
          label={t("eventForm:event.domain.title")}
          value={eventDomain.split(",").filter((domain) => domain !== "")}
          onChange={handleInputChange}
          validate={(incomingAreDomainsInvalid: boolean) =>
            setAreDomainsInvalid(incomingAreDomainsInvalid)
          }
          data-testid="event-domain"
          limit={MAX_DOMAINS_LIMIT}
          placeholder={t("eventForm:add.domain.placeholder")}
          invalidInputsMessage={t("eventForm:domain.invalid")}
          getRemainingMessage={(key) =>
            t("eventForm:domain.remaining", { key })
          }
          getExceededMessage={(key) => t("eventForm:domain.exceeded", { key })}
          customValidator={validateDomain}
          inputStartAdornment={inputStartAdornment}
        />
      </Box>
    </Box>
  );
};

export default EventDomain;
