export enum ActionTypes {
  CROP_MESSAGES = "app/chat/CROP_MESSAGES",
  OPEN_CHAT = "app/chat/OPEN_CHAT",
  RECEIVED_MESSAGES = "app/chat/RECEIVED_MESSAGES",
  REMOVE_MESSAGE_FROM_CHANNEL = "app/chat/REMOVE_MESSAGE_FROM_CHANNEL",
  RESET_CHAT = "app/chat/RESET_CHAT",
  SET_IS_LOADING = "app/chat/SET_IS_LOADING",
  SET_LISTENERS_STATUS = "app/chat/SET_LISTENERS_STATUS",
  UNSUBSCRIBE_CHANNEL = "app/chat/UNSUBSCRIBE_CHANNEL",
  UPDATE_ACTIVE_CHANNEL = "app/chat/UPDATE_ACTIVE_CHANNEL",
  UPDATE_CHANNEL_LISTENS_FROM = "app/chat/UPDATE_CHANNEL_LISTENS_FROM",
  UPDATE_CHANNEL_PAGINATION = "app/chat/UPDATE_CHANNEL_PAGINATION",
  UPDATE_CHAT_CHANNEL = "app/chat/UPDATE_CHAT_CHANNEL",
  UPDATE_UNREAD_COUNT = "app/chat/UPDATE_UNREAD_COUNT",
  SET_CHAT_PREFILLED_MESSAGE = "app/chat/SET_CHAT_PREFILLED_MESSAGE",
}

export const CHAT_MESSAGE_TYPE = "action";

export enum CHAT_MESSAGE_TYPES {
  ACTION = "action",
  EMOJI = "emoji",
  FILE = "file",
  GIF = "gif",
  TEXT = "text",
}

export const CHAT_TYPE = {
  USER: "U",
  ROOM: "R",
  SPACE: "S",
  THEATER: "T",
};

export const SPACE_CHAT_NAME = "general.chat";

export const CLIENT_MESSAGE = "client-message";

export const MAX_CHAT_MESSAGES = 50;
