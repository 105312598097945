import { CompanyRole } from "modules/company/constants";
import { EventRole } from "modules/event/constants";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { IUser } from "modules/app/types";

export interface IAuthReducerState {
  signInTime?: Date;
  user?: IUser;
  role?: string;
  roles: string[];
  isLoading: boolean;
  isAdmin: boolean;
  isManager: boolean;
  isEventHelper: boolean;
  isTokenSyncedWithServer: boolean;
  showUserPreferredLoginBypass: boolean;
  preferredLoginMethods: string[];
  inviteeEmail?: string;
  inviteeRole?: EventRole;
  magicLinkEmail?: string;
}

// Initial user state
export const initialState: IAuthReducerState = {
  signInTime: undefined,
  user: undefined,
  role: undefined,
  roles: ["user"],
  isLoading: true,
  isAdmin: false,
  isManager: false,
  isEventHelper: false,
  isTokenSyncedWithServer: false,
  showUserPreferredLoginBypass: false,
  preferredLoginMethods: [],
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAuth: (state: IAuthReducerState, action: PayloadAction<IUser>) => {
      state.user = action.payload;
      state.signInTime = new Date();
      state.inviteeEmail = undefined;
      state.inviteeRole = undefined;
      state.preferredLoginMethods = [];
      state.magicLinkEmail = undefined;
    },
    setCheckStatus: (
      state: IAuthReducerState,
      action: PayloadAction<boolean>,
    ) => {
      state.isLoading = action.payload;
    },
    setIsAdmin: (state: IAuthReducerState, action: PayloadAction<boolean>) => {
      state.isAdmin = action.payload;
    },
    setUserCompanyRole: (
      state: IAuthReducerState,
      action: PayloadAction<string>,
    ) => {
      state.role = action.payload;
      state.isManager = action.payload === CompanyRole.MANAGER;
      state.isEventHelper = action.payload === EventRole.helper;
    },
    resetAuth: () => ({ ...initialState, isLoading: false }),
    updateUser: (state: IAuthReducerState, action: PayloadAction<IUser>) => {
      state.user = action.payload;
    },
    updateTokenSyncStatus: (
      state: IAuthReducerState,
      action: PayloadAction<boolean>,
    ) => {
      state.isTokenSyncedWithServer = action.payload;
    },
    showUserPreferredLoginBypass: (
      state: IAuthReducerState,
      action: PayloadAction<string[]>,
    ) => {
      state.showUserPreferredLoginBypass = true;
      state.preferredLoginMethods = action.payload;
    },
    hideUserPreferredLoginBypass: (state: IAuthReducerState) => {
      state.showUserPreferredLoginBypass = false;
      state.preferredLoginMethods = [];
    },
    prefillPreferredLoginMethods: (
      state: IAuthReducerState,
      action: PayloadAction<string[]>,
    ) => {
      state.preferredLoginMethods = action.payload;
    },
    showPrefilledPreferredLoginMethods: (state: IAuthReducerState) => {
      state.showUserPreferredLoginBypass = true;
    },
    hidePrefilledPreferredLoginMethods: (state: IAuthReducerState) => {
      state.showUserPreferredLoginBypass = false;
    },
    setInviteeDetails: (
      state: IAuthReducerState,
      action: PayloadAction<{ inviteeEmail: string; inviteeRole: EventRole }>,
    ) => {
      state.inviteeEmail = action.payload.inviteeEmail;
      state.inviteeRole = action.payload.inviteeRole;
    },
    setInviteeRole: (
      state: IAuthReducerState,
      action: PayloadAction<EventRole>,
    ) => {
      state.inviteeRole = action.payload;
    },
    setMagicLinkEmail: (
      state: IAuthReducerState,
      action: PayloadAction<string>,
    ) => {
      state.magicLinkEmail = action.payload;
    },
    resetMagicLinkEmail: (state: IAuthReducerState) => {
      state.magicLinkEmail = undefined;
    },
  },
});

export const {
  setAuth,
  setCheckStatus,
  setIsAdmin,
  setUserCompanyRole,
  resetAuth,
  updateUser,
  updateTokenSyncStatus,
  showUserPreferredLoginBypass,
  hideUserPreferredLoginBypass,
  prefillPreferredLoginMethods,
  showPrefilledPreferredLoginMethods,
  hidePrefilledPreferredLoginMethods,
  setInviteeDetails,
  setMagicLinkEmail,
  resetMagicLinkEmail,
} = authSlice.actions;

export default authSlice.reducer;
