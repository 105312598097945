import { ITag } from "modules/company/types";
import { sendPostRequest } from "services/apiService";

interface CreateCompanyTagPayload {
  name: string;
  companyId: string;
}

type Response =
  | {
      isSuccess: false;
      reason?: string;
      message?: string;
    }
  | {
      isSuccess: true;
      data: ITag;
    };

export const createCompanyTag = async (data: CreateCompanyTagPayload) => {
  const { companyId, ...payload } = data;

  const resp = await sendPostRequest<
    Omit<CreateCompanyTagPayload, "companyId">,
    Response
  >(`/company/${companyId}/tags`, payload);

  if (!resp.isSuccess) {
    throw new Error(resp?.reason ?? resp?.message);
  }

  return resp.data;
};
