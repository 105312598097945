import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export interface IRegistrationState {
  showForm: boolean;
}

export const registrationInitialState: IRegistrationState = {
  showForm: false,
};

export const registrationSlice = createSlice({
  name: "registration",
  initialState: registrationInitialState,
  reducers: {
    setShowForm: (state, action: PayloadAction<boolean>) => {
      state.showForm = action.payload;
    },
  },
});

export const { setShowForm } = registrationSlice.actions;

export default registrationSlice.reducer;
