import { IQuestion } from "../types";
import { ActionTypes } from "./constants";

export const addQuestionsDetails = (questions: IQuestion[]) => ({
  payload: questions,
  type: ActionTypes.ADD_QUESTION_DETAILS,
});

export const clearQuestionsDetails = () => ({
  payload: [],
  type: ActionTypes.CLEAR_QUESTION_DETAILS,
});

export type ReducerActions =
  | ReturnType<typeof addQuestionsDetails>
  | ReturnType<typeof clearQuestionsDetails>;
