import React from "react";
import reducer from "./eventTrackingReducer";
import { IEventTrackingState } from "../types";

export const EventTrackingStateContext =
  React.createContext<IEventTrackingState>({ history: [] });

export const EventTrackingDispatchContext =
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  React.createContext<React.Dispatch<any> | null>(null);

const EventTrackingProvider = ({ children }: { children: React.ReactNode }) => {
  const [state, dispatch] = React.useReducer(reducer, { history: [] });

  return (
    <EventTrackingStateContext.Provider value={state}>
      <EventTrackingDispatchContext.Provider value={dispatch}>
        {children}
      </EventTrackingDispatchContext.Provider>
    </EventTrackingStateContext.Provider>
  );
};

export default EventTrackingProvider;
