import { CompanyRole } from "modules/company/constants";
import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "store";
import { ANONYMOUS_USER_EMAIL_DOMAIN } from "../constants";

const selectAuth = (state: RootState) => state.auth;
const selectUser = createSelector(selectAuth, (substate) => substate.user);

const selectRole = createSelector(selectAuth, (substate) => substate.role);

const selectUserIsSyncStatus = createSelector(
  selectAuth,
  (substate) => substate.isTokenSyncedWithServer,
);

const selectUserId = createSelector(
  selectAuth,
  (substate) => substate.user?.id ?? null,
);

const selectUserEmail = createSelector(
  selectAuth,
  (substate) => substate.user?.email ?? null,
);

const selectIsCompanyMember = createSelector(
  selectAuth,
  (substate) =>
    substate.role === CompanyRole.ASSISTANT ||
    substate.role === CompanyRole.MANAGER ||
    substate.role === CompanyRole.OWNER,
);

const selectIsCompanyOwnerOrManager = createSelector(
  selectAuth,
  (substate) =>
    substate.role === CompanyRole.MANAGER ||
    substate.role === CompanyRole.OWNER,
);

const selectIsCompanyOwner = createSelector(
  selectAuth,
  (substate) => substate.role === CompanyRole.OWNER,
);

const selectShowUserPreferredLoginBypass = createSelector(
  selectAuth,
  (substate) => substate.showUserPreferredLoginBypass,
);

const selectPreferredLoginMethods = createSelector(
  selectAuth,
  (substate) => substate.preferredLoginMethods,
);

const selectInviteeEmail = createSelector(
  selectAuth,
  (substate) => substate.inviteeEmail,
);

const selectInviteeRole = createSelector(
  selectAuth,
  (substate) => substate.inviteeRole,
);

const selectMagicLinkEmail = createSelector(
  selectAuth,
  (substate) => substate.magicLinkEmail,
);

const selectIsAnonymousUser = createSelector(
  selectUser,
  (substate) =>
    substate?.email.split("@")[1] === ANONYMOUS_USER_EMAIL_DOMAIN.split("@")[1],
);

export {
  selectAuth,
  selectUser,
  selectRole,
  selectUserIsSyncStatus,
  selectUserId,
  selectUserEmail,
  selectIsCompanyMember,
  selectIsCompanyOwner,
  selectIsCompanyOwnerOrManager,
  selectShowUserPreferredLoginBypass,
  selectPreferredLoginMethods,
  selectInviteeEmail,
  selectInviteeRole,
  selectMagicLinkEmail,
  selectIsAnonymousUser,
};
