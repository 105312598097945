import { IEvent } from "modules/event/types";
import { IEventcubeSettings } from "modules/company/types";
import { Button } from "@remo-co/ui-core/src/components/Button";
import { Typography } from "@remo-co/ui-core/src/components/Typography";
import { Add } from "@remo-co/ui-core/src/icons/Add";
import { useI18n } from "i18n";
import { useContext, useEffect, useState } from "react";
import { IEventcubeTicket } from "modules/ticketing/types";
import useNotificationActions from "modules/notification/hooks/useNotificationActions";
import { queryClient } from "services/apiService/queryClient";
import { useCheckEventStatus } from "modules/event/hooks/useCheckEventStatus";
import { TRACKING_CONTEXT, Events } from "modules/tracking";
import { EmptyState } from "../EmptyState";
import { useEventTickets } from "../../hooks/useEventTickets";
import { LoadingState } from "../LoadingState";
import useStyles from "./styles";
import { TicketItem } from "../TicketItem";
import { TicketFormDialog } from "../TicketFormDialog/TicketFormDialog";
import { deleteEventTicket } from "../../apis";
import { EVENT_TICKETS_QUERY_KEY } from "../../constants";
import { CreateEventEmptyState } from "../CreateEventEmptyState";

interface Props {
  eventData: IEvent;
  eventcubeSettings?: IEventcubeSettings;
  companyId: string | null;
}

export const Tickets = ({ eventData, eventcubeSettings, companyId }: Props) => {
  const { track } = useContext(TRACKING_CONTEXT);
  const { addErrorNotification, addSuccessNotification } =
    useNotificationActions();
  const { checkIfEventIsUpcoming } = useCheckEventStatus();
  const [isFormVisible, setIsFormVisible] = useState(false);
  const [preloadedTicket, setPreloadedTicket] =
    useState<IEventcubeTicket | null>(null);
  const { tickets, status } = useEventTickets(eventData?.id);
  const { t } = useI18n(["eventForm"]);
  const styles = useStyles();
  const isEventUpcoming = checkIfEventIsUpcoming(eventData);

  const onClose = () => {
    setIsFormVisible(false);
    setPreloadedTicket(null);
  };

  const onUpdateClick = (ticket: IEventcubeTicket) => {
    setPreloadedTicket(ticket);
    setIsFormVisible(true);
  };

  const onDeleteClick = async (ticket: IEventcubeTicket) => {
    try {
      await deleteEventTicket(eventData.id, ticket.id);
    } catch (err) {
      addErrorNotification({
        message: t("eventForm:ticket.deleted.error"),
      });
      return;
    }

    addSuccessNotification({
      message: t("eventForm:ticket.deleted.successfully"),
    });
    queryClient.invalidateQueries({
      queryKey: [EVENT_TICKETS_QUERY_KEY, eventData.id],
    });
    track(Events.TICKETING_TICKET_DELETED, {
      eventId: eventData.id,
      eventType: eventData.eventType,
      eventOutcome: eventData.eventOutcome,
    });
  };

  useEffect(() => {
    if (eventcubeSettings) {
      track(Events.TICKETING_ACCOUNT_SETUP, {
        companyIds: companyId ?? undefined,
      });
    }
  }, [companyId, eventcubeSettings, track]);

  const onCreateClick = () => {
    track(Events.TICKETING_TICKET_CREATION_STARTED, {
      eventId: eventData.id,
      eventType: eventData.eventType,
      eventOutcome: eventData.eventOutcome,
    });
    setIsFormVisible(true);
  };

  if (!eventcubeSettings) {
    return null;
  }

  if (status === "pending") {
    return <LoadingState />;
  }

  return (
    <>
      {tickets.length === 0 ? (
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <>
          {!eventData.id ? (
            <CreateEventEmptyState />
          ) : (
            <EmptyState
              onCreateClick={onCreateClick}
              disabled={!isEventUpcoming}
            />
          )}
        </>
      ) : (
        <div className={styles.tickets}>
          {tickets.map((ticket) => (
            <TicketItem
              key={ticket.id}
              eventData={eventData}
              ticket={ticket}
              currency={eventcubeSettings.currency}
              onUpdateClick={onUpdateClick}
              onDeleteClick={onDeleteClick}
              disabled={!isEventUpcoming}
            />
          ))}
          <div>
            <Button
              size="sm"
              variant="primary"
              onClick={onCreateClick}
              contentClassName={styles.buttonContent}
              data-testid="add-ticket-button"
              disabled={!isEventUpcoming}
            >
              <Add className={styles.buttonIcon} />
              <Typography variant="body1">
                {t("eventForm:add.ticket")}
              </Typography>
            </Button>
          </div>
        </div>
      )}
      {isFormVisible && eventData?.id && (
        <TicketFormDialog
          preloadedTicket={preloadedTicket}
          eventData={eventData}
          currency={eventcubeSettings.currency}
          onClose={onClose}
        />
      )}
    </>
  );
};
