import { ISponsor, IEvent } from "modules/event/types";
import { useMemo, useReducer } from "react";
import { IMapTemplate } from "types/theater";
import { EventType } from "../../types";
import {
  IManageEventActions,
  ManageEventState,
  ValidatorResponseType,
} from "../types";

export const initialState: ManageEventState = {
  pendingSpeakerInvites: new Set(),
  pendingAttendeeInvites: new Set(),
  eventData: null,
  pageId: undefined,
  sponsors: [],
  hasDirtyForm: false, // for sponsor form
  hideActionBar: false,
  upgradeMessage: "",
  tabValidator: null,
  eventTypeOptions: [], // for event type dropdown on event creation page
};

enum ActionTypes {
  HAS_DIRTY_FORM = "HAS_DIRTY_FORM",
  HIDE_ACTION_BAR = "HIDE_ACTION_BAR",
  RESET_AFTER_SAVE_DATA = "RESET_AFTER_SAVE_DATA",
  SET_EVENT_TYPE_OPTIONS = "SET_EVENT_TYPE_OPTIONS",
  SET_PAGE_ID = "SET_PAGE_ID",
  SET_PENDING_ATTENDEE_INVITES = "SET_PENDING_ATTENDEE_INVITES",
  SET_PENDING_SPEAKER_INVITES = "SET_PENDING_SPEAKER_INVITES",
  SET_SPONSORS = "SET_SPONSORS",
  SET_TAB_VALIDATOR = "SET_PAGE_VALIDATOR",
  SET_TEMPLATES = "SET_TEMPLATES",
  SET_UPGRADE_MESSAGE = "SET_UPGRADE_MESSAGE",
  UPDATE_EVENT_DATA = "UPDATE_EVENT_DATA",
  UPDATE_PARTIAL_EVENT_DATA = "UPDATE_PARTIAL_EVENT_DATA",
}

type ManageEventActions =
  | { type: ActionTypes.SET_PENDING_SPEAKER_INVITES; payload: { emails: [] } }
  | {
      type: ActionTypes.SET_PENDING_ATTENDEE_INVITES;
      payload: { emails: []; sendInvite: boolean };
    }
  | {
      type: ActionTypes.RESET_AFTER_SAVE_DATA;
    }
  | {
      type: ActionTypes.SET_PAGE_ID;
      payload: { pageId: string };
    }
  | {
      type: ActionTypes.SET_TEMPLATES;
      payload: {
        templates: { [key: string]: IMapTemplate[] };
      };
    }
  | {
      type: ActionTypes.SET_SPONSORS;
      payload: { sponsors: ISponsor[] };
    }
  | {
      type: ActionTypes.HAS_DIRTY_FORM;
      payload: { hasDirtyForm: boolean };
    }
  | {
      type: ActionTypes.HIDE_ACTION_BAR;
      payload: { hideActionBar: boolean };
    }
  | {
      type: ActionTypes.SET_UPGRADE_MESSAGE;
      payload: string;
    }
  | {
      type: ActionTypes.UPDATE_EVENT_DATA;
      payload: IEvent;
    }
  | {
      type: ActionTypes.SET_TAB_VALIDATOR;
      payload: () => ValidatorResponseType;
    }
  | {
      type: ActionTypes.SET_EVENT_TYPE_OPTIONS;
      payload: EventType[];
    }
  | {
      type: ActionTypes.UPDATE_PARTIAL_EVENT_DATA;
      payload: Partial<IEvent>;
    };

const reducer = (
  state = initialState,
  action: ManageEventActions,
): ManageEventState => {
  switch (action.type) {
    case ActionTypes.SET_PENDING_SPEAKER_INVITES: {
      return {
        ...state,
        pendingSpeakerInvites: new Set(action.payload.emails),
      };
    }
    case ActionTypes.SET_PENDING_ATTENDEE_INVITES: {
      return {
        ...state,
        pendingAttendeeInvites: new Set(action.payload.emails),
      };
    }
    case ActionTypes.RESET_AFTER_SAVE_DATA: {
      return initialState;
    }
    case ActionTypes.UPDATE_EVENT_DATA: {
      return {
        ...state,
        eventData: { ...action.payload },
      };
    }
    case ActionTypes.UPDATE_PARTIAL_EVENT_DATA: {
      return {
        ...state,
        eventData: { ...(state.eventData ?? {}), ...action.payload } as IEvent,
      };
    }
    case ActionTypes.SET_PAGE_ID: {
      return {
        ...state,
        pageId: action.payload.pageId,
      };
    }
    case ActionTypes.SET_TEMPLATES: {
      return {
        ...state,
        templates: action.payload.templates,
      };
    }
    case ActionTypes.SET_SPONSORS: {
      return {
        ...state,
        sponsors: action.payload.sponsors,
      };
    }
    case ActionTypes.HAS_DIRTY_FORM: {
      return {
        ...state,
        hasDirtyForm: action.payload.hasDirtyForm,
      };
    }
    case ActionTypes.HIDE_ACTION_BAR: {
      return {
        ...state,
        hideActionBar: action.payload.hideActionBar,
      };
    }
    case ActionTypes.SET_UPGRADE_MESSAGE: {
      return {
        ...state,
        upgradeMessage: action.payload,
      };
    }
    case ActionTypes.SET_TAB_VALIDATOR: {
      return {
        ...state,
        tabValidator: action.payload,
      };
    }
    case ActionTypes.SET_EVENT_TYPE_OPTIONS: {
      return {
        ...state,
        eventTypeOptions: action.payload,
      };
    }
    default:
      return state;
  }
};

const useManageEventReducer = (): [ManageEventState, IManageEventActions] => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const actions = useMemo(
    () => ({
      setPendingSpeakerInvites: (emails: []) => {
        dispatch({
          type: ActionTypes.SET_PENDING_SPEAKER_INVITES,
          payload: { emails },
        });
      },
      setPendingAttendeeInvites(emails: [], sendInvite: boolean) {
        dispatch({
          type: ActionTypes.SET_PENDING_ATTENDEE_INVITES,
          payload: { emails, sendInvite },
        });
      },
      resetAfterSaveData() {
        dispatch({
          type: ActionTypes.RESET_AFTER_SAVE_DATA,
        });
      },
      setEventData(event: IEvent) {
        dispatch({
          type: ActionTypes.UPDATE_EVENT_DATA,
          payload: event,
        });
      },
      updatePartialEventData(event: Partial<IEvent>) {
        dispatch({
          type: ActionTypes.UPDATE_PARTIAL_EVENT_DATA,
          payload: event,
        });
      },
      setPageId(pageId: string) {
        dispatch({
          type: ActionTypes.SET_PAGE_ID,
          payload: { pageId },
        });
      },
      setTemplates(templates: { [key: string]: IMapTemplate[] }) {
        dispatch({
          type: ActionTypes.SET_TEMPLATES,
          payload: { templates },
        });
      },
      setSponsors(sponsors: ISponsor[]) {
        dispatch({
          type: ActionTypes.SET_SPONSORS,
          payload: { sponsors },
        });
      },
      // If form values are changed, dont close/change tabs
      setHasDirtyForm(hasDirtyForm: boolean) {
        dispatch({
          type: ActionTypes.HAS_DIRTY_FORM,
          payload: { hasDirtyForm },
        });
      },
      setHideActionBar(hideActionBar: boolean) {
        dispatch({
          type: ActionTypes.HIDE_ACTION_BAR,
          payload: { hideActionBar },
        });
      },
      setUpgradeMessage(message: string) {
        dispatch({
          type: ActionTypes.SET_UPGRADE_MESSAGE,
          payload: message,
        });
      },
      setTabValidator(validator: () => ValidatorResponseType) {
        dispatch({
          type: ActionTypes.SET_TAB_VALIDATOR,
          payload: validator,
        });
      },
      setEventTypeOptions(eventTypeOptions: EventType[]) {
        dispatch({
          type: ActionTypes.SET_EVENT_TYPE_OPTIONS,
          payload: eventTypeOptions,
        });
      },
    }),
    [],
  );

  return [state, actions];
};

export default useManageEventReducer;
