import { useEffect } from "react";
import { datadogRum } from "@datadog/browser-rum";
import { useSelector } from "react-redux";
import { selectCurrentEventCode } from "modules/event/selectors";

export const TrackEventCode = () => {
  const eventCode = useSelector(selectCurrentEventCode);

  useEffect(() => {
    if (eventCode) {
      datadogRum.setGlobalContextProperty("eventCode", eventCode);
    }
  }, [eventCode]);

  return null;
};
