import { useRef } from "react";
import {
  useLocalSessionId,
  useParticipantProperty,
} from "@daily-co/daily-react";
import { Typography } from "@remo-co/ui-core/src/components/Typography";
import { useSelector } from "react-redux";
import { makeSelectUserStatusByUserId } from "modules/userStatus/redux/selectors";
import { UserData } from "modules/audioVideo/hooks/useUserData/types";
import { DisplayItem, DisplayLayout } from "modules/audioVideo";
import { BaseTile } from "modules/audioVideo/components/Tile/BaseTile";
import { DefaultProfileImage } from "modules/audioVideo/components/Tile/DefaultProfileImage";
import { selectUserConversationEmoji } from "modules/emoji/redux";
import { DAILY_PLAYABLE_TRACK_STATES } from "modules/audioVideo/constants";
import { selectNetworkingRecommendationUser } from "modules/networkingRecommendations/redux/selectors";
import { selectIsEventManager } from "modules/event/selectors";
import { selectLayout } from "modules/audioVideo/redux/selectors";
import { useStyles } from "../styles";
import { useStreams } from "../useStreams";
import { SafariAutoplayCheck } from "../SafariAutoplayCheck";
import { RecommendedTooltip } from "./components/RecommendedTooltip";

interface Props {
  id: string;
  isClickable?: boolean;
  showName?: boolean;
  showVideo?: boolean;
}

export const RemoteVideo = ({
  id,
  isClickable,
  showName = true,
  showVideo = true,
}: Props): JSX.Element | null => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const localSessionId = useLocalSessionId();
  const isEventManager = useSelector(selectIsEventManager);
  const layout = useSelector(selectLayout);

  const [
    userId,
    userName,
    userData,
    videoTrack,
    audioState,
    videoState,
    isLocal,
    isSharingScreen,
  ] = useParticipantProperty(id, [
    "user_id",
    "user_name",
    "userData",
    "tracks.video.persistentTrack",
    "tracks.audio.state",
    "tracks.video.state",
    "local",
    "screen",
  ]);

  const isOwner = useParticipantProperty(localSessionId || "", "owner");
  const userStatus = useSelector(makeSelectUserStatusByUserId(userId ?? ""));
  const isTalking = userStatus?.get("isTalking");

  const conversationEmoji = useSelector(selectUserConversationEmoji(userId));

  const networkingRecommendation = useSelector(
    selectNetworkingRecommendationUser(userId),
  );

  const styles = useStyles();

  useStreams({
    videoRef,
    videoTrack,
    videoState,
    showVideo,
  });

  const videoType = "camera";

  if (!userId) {
    return null;
  }

  return (
    <BaseTile
      hasAudio={DAILY_PLAYABLE_TRACK_STATES.includes(audioState)}
      isTalking={isTalking}
      isLocal={false}
      videoType={videoType}
      isClickable={isClickable}
      networkQuality={(userData as UserData)?.networkQuality}
      isInterrupted={
        audioState === "interrupted" || videoState === "interrupted"
      }
      showMuteButton={!isLocal && !isSharingScreen && isOwner}
      tile={{ streamId: id, type: DisplayItem.camera }}
      originalAspectRatio={(userData as UserData)?.originalAspectRatio}
      emoji={conversationEmoji}
    >
      {showName && (
        <Typography variant="body1" className={styles.userName}>
          {userName}
        </Typography>
      )}
      {showVideo && DAILY_PLAYABLE_TRACK_STATES.includes(videoState) ? (
        <video autoPlay playsInline height="100%" width="100%" ref={videoRef} />
      ) : (
        <DefaultProfileImage imageUrl={(userData as UserData)?.image} />
      )}
      {networkingRecommendation &&
        !isEventManager &&
        layout === DisplayLayout.Map && <RecommendedTooltip userId={userId} />}

      <SafariAutoplayCheck />
    </BaseTile>
  );
};
