import { useSelector } from "react-redux";
import { selectCurrentEventId } from "modules/event/selectors";
import { useI18n } from "i18n";
import { Typography } from "@remo-co/ui-core/src/components/Typography";
import { Container } from "@remo-co/ui-core/src/components/Container";
import { useMemo } from "react";
import { useStyles } from "./styles";
import { selectUserActivityStatus } from "../../redux/selectors";
import { ActivityStatus } from "../../types";

interface Props {
  small?: boolean;
  userId: string;
  onClick?: () => void;
}

export const ActivityStatusIndicator = ({ onClick, userId, small }: Props) => {
  const currentEventId = useSelector(selectCurrentEventId);
  const currentUserActivityStatus = useSelector(
    selectUserActivityStatus(userId),
  );
  const styles = useStyles({
    status: currentUserActivityStatus?.status ?? null,
    small,
  });
  const { t } = useI18n(["event"]);
  const variant = small ? "caption" : "body1";

  const activityStatus = useMemo(
    () =>
      currentUserActivityStatus ?? {
        status: ActivityStatus.ACTIVE,
        label: ActivityStatus.ACTIVE,
      },
    [currentUserActivityStatus],
  );

  if (!currentEventId) {
    return null;
  }

  return (
    <Container
      flex
      justifyContent="flex-start"
      className={styles.container}
      onClick={onClick}
      data-testid="activity-status-indicator"
    >
      <div className={styles.statusDot} />
      {activityStatus.status === activityStatus.label.toLowerCase() ? (
        <Typography variant={variant}>
          {t(`event:activity.status.${activityStatus.status}`)}
        </Typography>
      ) : (
        <Typography variant={variant}>{activityStatus.label}</Typography>
      )}
    </Container>
  );
};
