import { useContext } from "react";
import logger from "logging/logger";
import { useSelector } from "react-redux";
import { getFirstName, getLastName } from "helpers/nameHelper";
import { TRACKING_CONTEXT } from "modules/tracking";
import { getFirebaseUID } from "services/firebaseService/firebaseConfig";
import { selectUser } from "modules/auth/redux/selectors";
import { getVar } from "config";
import { FRESH_CHAT_SETTINGS } from "modules/helpChat/constants";
import { useAppDispatch } from "store/hooks";
import { setIsLoading } from "modules/helpChat";

const useNeedHelp = () => {
  // Getting from window everytime, because this object becomes undefined when going to another page
  const user = useSelector(selectUser);
  const { identify } = useContext(TRACKING_CONTEXT);
  const dispatch = useAppDispatch();

  const initUserInfo = () => {
    const { fcWidget } = window;

    if (fcWidget && user) {
      fcWidget.setExternalId(getFirebaseUID());
      fcWidget.user.setEmail(user.email);
      fcWidget.user.setFirstName(
        user.profile.firstName ?? getFirstName(`${user.profile.name}`),
      );
      fcWidget.user.setLastName(
        user.profile.lastName ?? getLastName(`${user.profile.name}`),
      );
    }
  };

  const initFreshChat = async () => {
    const { fcWidget } = window;

    if (!fcWidget) {
      return;
    }

    const fcProps = {
      token: getVar("REACT_APP_FRESH_CHAT_TOKEN"),
      host: getVar("REACT_APP_FRESH_CHAT_HOST"),
      externalId: getFirebaseUID(),
      ...FRESH_CHAT_SETTINGS,
    };

    dispatch(setIsLoading(true));
    fcWidget.init(fcProps);
    initUserInfo();
  };

  const openWidget = async () =>
    new Promise((resolve) => {
      const { fcWidget } = window;

      fcWidget.open();

      const interval = setInterval(() => {
        if (fcWidget.isOpen()) {
          dispatch(setIsLoading(false));
          clearInterval(interval);
          resolve(true);
        }
      }, 200);
    });

  const getFcWidget = () => window.fcWidget;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const callApi = async (key: string, data?: any) => {
    const fcWidget = getFcWidget();

    if (!fcWidget) {
      return;
    }

    if (key === "showNewMessage" && !fcWidget.isInitialized()) {
      dispatch(setIsLoading(true));
      initFreshChat();
    } else if (!fcWidget.isInitialized()) {
      return;
    }

    switch (key) {
      case "showNewMessage":
        await openWidget();
        break;

      case "update":
        try {
          if (data.email) {
            fcWidget.user.setEmail(data.email);
            identify(data.email, data);
          }

          if (data.name) {
            fcWidget.user.setFirstName(getFirstName(data.name));
            fcWidget.user.setLastName(getLastName(data.name));
          }
          fcWidget.user.setProperties(data);
        } catch (error) {
          const errorMessage = error instanceof Error ? error.message : "";
          logger.error(
            `useNeedHelp - error setting session data ${errorMessage}`,
            data,
          );
        }

        break;
      case "logout":
        // eslint-disable-next-line promise/catch-or-return, promise/prefer-await-to-then
        fcWidget.user.clear();
        break;
      default:
        break;
    }
  };

  return {
    callApi,
  };
};

export default useNeedHelp;
