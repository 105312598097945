export enum Warnings {
  CNAME_CHECK_FAILED = "CName Check Failed",
  ENFORCE_TIMEOUT = "Enforce Table Assignment transfers took longer than expected",
  FACEBOOK_SSO = "Facebook SSO flow",
  GEARTEST_ADBLOCK_DETECTED = "Geartest AdBlock detected",
  GEARTEST_BROWSER_NOT_SUPPORTED = "Geartest browser not supported",
  GEARTEST_FIREBASE_INACCESSIBLE = "Geartest firebase inaccessible",
  GEARTEST_FIREBASE_STORAGE_INACCESSIBLE = "Geartest firebase storage inaccessible",
  GEARTEST_FIRESTORE_INACCESSIBLE = "Geartest firestore inaccessible",
  GEARTEST_INTERNET_CONNECTION_SLOW = "Geartest internet connection slow",
  GEARTEST_OS_NOT_SUPPORTED = "Geartest OS not supported",
  GEARTEST_VPN_DETECTED = "Geartest VPN detected",
  GOOGLE_SSO = "Google SSO flow",
  LINKEDIN_SSO = "Linkedin SSO flow",
  MS_SSO = "Microsoft SSO flow",
  SHUFFLE_TIMEOUT = "Shuffle took longer than expected",
  SSO_UNVERIFIED_DOMAIN_REDIRECT = "Attempted to do a SSO redirect to an unverified domain",
  TEST = "only used for unit testing",
}
