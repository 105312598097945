import { stopTranscriptionRequest } from "modules/transcription";
import { TimedSpeechData } from "../../TranscriptionSidebar/TranscriptionSidebar";
import { TranscriptionActions } from "./constants";
import { ITranscriptionAction, ITranscriptionState } from "./types";

const reducer = (
  state: ITranscriptionState,
  action: ITranscriptionAction,
): ITranscriptionState => {
  switch (action.type) {
    case TranscriptionActions.UPDATE_TRANSCRIPT:
      if (action.payload.transcript) {
        const newTranscript = action.payload.transcript;

        return {
          ...state,
          transcript: newTranscript,
        };
      }

      return state;

    case TranscriptionActions.UPDATE_WEBSOCKET:
      if (state.websocket?.current) {
        const socketState = state.websocket.current.readyState;
        const socketReady = socketState === WebSocket.OPEN;

        if (action.payload.isSpeaker && socketReady) {
          stopTranscriptionRequest(state.websocket.current);
        }

        state.websocket.current.close();
      }
      return {
        ...state,
        websocket: { current: action.payload.websocket },
      };

    case TranscriptionActions.CLEAR_WEBSOCKET:
      if (state.websocket?.current) {
        const socketState = state.websocket.current.readyState;
        const socketReady = socketState === WebSocket.OPEN;
        const isSpeakerURL =
          !state.websocket.current.url.includes("/subscribe");
        if (isSpeakerURL && socketReady) {
          stopTranscriptionRequest(state.websocket.current);
        }

        state.websocket.current.close();
      }

      return { ...state, websocket: { current: undefined } };

    case TranscriptionActions.UPDATE_PROCESSOR:
      if (state.processor) {
        state.processor.disconnect();
      }

      return {
        ...state,
        processor: action.payload.processor,
      };

    case TranscriptionActions.CLEAR_PROCESSOR:
      if (state.processor) {
        state.processor.disconnect();

        return {
          ...state,
          processor: null,
        };
      }

      return state;

    case TranscriptionActions.UPDATE_TOKEN:
      return {
        ...state,
        token: action.payload.token,
      };

    case TranscriptionActions.UPDATE_SAMPLE_RATE:
      if (action.payload.sampleRate) {
        return {
          ...state,
          sampleRate: action.payload.sampleRate,
        };
      }
      return state;

    case TranscriptionActions.UPDATE_AUDIO_CONTEXT:
      if (action.payload.audioContext) {
        action.payload.audioContext.resume();
        return {
          ...state,
          context: action.payload.audioContext,
        };
      }
      return state;

    case TranscriptionActions.CLEAR_AUDIO_CONTEXT:
      return {
        ...state,
        context: null,
      };

    case TranscriptionActions.CLEAR_FINALIZED_TRANSCRIPT:
      return {
        ...state,
        finalizedTranscript: null,
      };

    case TranscriptionActions.UPDATE_FINALIZED_TRANSCRIPT: {
      if (!action.payload.finalizedTranscript) {
        return state;
      }

      const newTranscript: TimedSpeechData = {
        time: new Date(),
        ...action.payload.finalizedTranscript,
      };

      return {
        ...state,
        finalizedTranscript: state.finalizedTranscript
          ? [...state.finalizedTranscript, newTranscript]
          : [newTranscript],
      };
    }

    default:
      return state;
  }
};

export default reducer;
