import { Typography } from "@remo-co/ui-core/src/components/Typography";
import { Container } from "@remo-co/ui-core/src/components/Container";
import { useI18n } from "i18n";
import { memo } from "react";
import { Button } from "@remo-co/ui-core/src/components/Button";
import { useStyles } from "./styles";
import NoResult from "../../assets/noResults.svg";

export const NoFloorPlan = memo(() => {
  const { t } = useI18n(["customFloorPlan", "url"]);
  const styles = useStyles();

  return (
    <Container className={styles.container} data-testid="no-floor-plans">
      <Typography variant="body1" className={styles.title}>
        {t("no.floor.plan")}
      </Typography>

      <img src={NoResult} alt="no-result" width={220} height={220} />

      <Typography className={styles.message}>
        {t("customFloorPlan:no.floor.plan.message")}
      </Typography>
      <a
        href={t("url:floor.plan.marketplace")}
        target="_blank"
        rel="noreferrer"
      >
        <Button variant="secondary">
          {t("customFloorPlan:browse.floor.plan.marketplace")}
        </Button>
      </a>
    </Container>
  );
});
