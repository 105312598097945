import { Tooltip } from "@remo-co/ui-core/src/components/Tooltip";
import classNames from "classnames";
import { useI18n } from "i18n";
import { useStyles } from "./styles";

const BroadcastBoardOverlay = () => {
  const { t } = useI18n(["template"]);
  const classes = useStyles();

  const tooltip = t("template:whiteboard.host.invite.required");

  return (
    <div data-testid="test-overlay">
      <div className={classes.boardOverlayHorizontal} />
      <div
        className={classNames(
          classes.boardOverlayHorizontal,
          classes.overlayRightSpacing,
        )}
      />
      <Tooltip placement="bottom" title={tooltip}>
        <div className={classes.boardOverlayVertical} />
      </Tooltip>
    </div>
  );
};

export default BroadcastBoardOverlay;
