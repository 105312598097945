import logger from "logging/logger";

interface IOptionsWithTimeout extends RequestInit {
  timeout: number;
}

type ResponseType = {
  response?: unknown;
  url?: string;
};

const fetchWithTimeoutAndRetry = async (
  resource: string,
  options: IOptionsWithTimeout,
  retryCount = 0,
  // eslint-disable-next-line consistent-return
): Promise<ResponseType | undefined> => {
  const { timeout = 800 } = options;

  const controller = new AbortController();

  if (retryCount >= 0) {
    try {
      const id = setTimeout(() => controller.abort(), timeout);

      const response = await fetch(resource, {
        ...options,
        signal: controller.signal,
      });

      if (response.status >= 200 && response.status <= 299) {
        return response;
      }

      clearTimeout(id);
    } catch (error) {
      const errorMessage = error instanceof Error ? error.message : "";

      logger.warn("Request failed with an error", {
        message: errorMessage,
      });

      return fetchWithTimeoutAndRetry(resource, options, retryCount - 1);
    }
  }
};

export default fetchWithTimeoutAndRetry;
