import { MutableRefObject, useRef, useEffect, useLayoutEffect } from "react";
import { useSelector } from "react-redux";
import {
  useFloating,
  arrow,
  offset,
  autoUpdate,
} from "@floating-ui/react-dom-interactions";
import { useAppDispatch } from "store/hooks";
import { useI18n } from "i18n";
import {
  selectShowAssignedTableNudge,
  setVisible as setNudgeVisible,
} from "modules/assignTables/redux";
import { Box } from "@remo-co/ui-core/src/components/Box";
import { Typography } from "@remo-co/ui-core/src/components/Typography";
import { Button } from "@remo-co/ui-core/src/components/Button";
import useStyles from "./styles";

interface Props {
  avatarRef: MutableRefObject<HTMLElement | null>;
}

const AssignedTableNudgePopover = ({
  avatarRef,
}: Props): JSX.Element | null => {
  const isNudgeVisible = useSelector(selectShowAssignedTableNudge);
  const arrowRef = useRef<HTMLDivElement | null>(null);
  const dispatch = useAppDispatch();
  const { t } = useI18n(["manageEvent"]);
  const styles = useStyles();

  const {
    x,
    y,
    reference,
    floating,
    strategy,
    refs,
    update,
    middlewareData: { arrow: { x: arrowX, y: arrowY } = {} },
  } = useFloating({
    placement: "right-start",
    middleware: [arrow({ element: arrowRef }), offset(20)],
  });

  useLayoutEffect(() => {
    reference(avatarRef.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [avatarRef.current]);

  useEffect(() => {
    if (!avatarRef.current || !refs.floating.current) {
      return;
    }

    // Only call this when the floating element is rendered
    // eslint-disable-next-line consistent-return
    return autoUpdate(avatarRef.current, refs.floating.current, update);
  }, [avatarRef, refs.floating, update]);

  const onDismissClick = () => {
    dispatch(setNudgeVisible(false));
  };

  if (!isNudgeVisible) {
    return null;
  }

  return (
    <div
      ref={floating}
      style={{
        position: strategy,
        top: y ?? "",
        left: x ?? "",
      }}
      className={styles.popper}
    >
      <Box display="flex" flexDirection="column" className={styles.container}>
        <Typography variant="body2" className={styles.text}>
          {t("attendee.assigned.table.nudge")}
        </Typography>
        <Button
          variant="primary"
          color="dark"
          size="xs"
          onClick={onDismissClick}
          className={styles.button}
          contentClassName={styles.buttonContent}
        >
          {t("attendee.assigned.table.nudge.dismiss")}
        </Button>
      </Box>
      <div
        ref={arrowRef}
        className={styles.buttonArrow}
        style={{
          top: arrowY ?? "",
          left: arrowX ?? "",
        }}
      />
    </div>
  );
};

export default AssignedTableNudgePopover;
