import { ReactElement } from "react";
import { useI18n } from "i18n";
import { Typography } from "@remo-co/ui-core/src/components/Typography";
import classNames from "classnames";
import { EventPollStatus } from "graphql/generated";
import { useStyles } from "./styles";

export type PollStatus = "ongoing" | "draft" | "ended";
interface Props {
  status: EventPollStatus;
}

const Pill = ({ status }: Props): ReactElement => {
  const classes = useStyles();
  const { t } = useI18n("common");

  return (
    <div
      className={classNames(classes.pill, {
        [classes.pillOngoing]: status === EventPollStatus.Ongoing,
        [classes.pillDraft]: status === EventPollStatus.Draft,
        [classes.pillEnded]: status === EventPollStatus.Closed,
      })}
    >
      <Typography variant="caption">{t(status)}</Typography>
    </div>
  );
};

export default Pill;
