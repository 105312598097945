import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";
import isometricPNG from "../../assets/isometric-icon.png";
import modernPNG from "../../assets/modern-icon.png";
import realisticPNG from "../../assets/realistic-icon.png";
import classicPNG from "../../assets/classic-icon.png";

export const useStyles = makeStyles((theme) => ({
  themeButton: {
    minWidth: "125px",
    height: "44px",
    padding: "5px",
    marginRight: "6px",
    background: theme.palette.white,
    border: `1px solid ${theme.palette.gray.light}`,
    borderRadius: "5px",
    position: "relative",
    cursor: "pointer",
    display: "flex",
    justifyContent: "flex-start",
    "&:focus": {
      border: `1px solid ${theme.palette.blue.main}`,
      background: theme.palette.blue.lightShade1,
    },
  },
  themeButtonContent: {
    padding: 0,
    display: "flex",
  },
  active: {
    border: `1px solid ${theme.palette.blue.main}`,
    background: theme.palette.blue.lightShade1,
  },
  themeButtonIcon: {
    width: "46px",
    height: "36px",
    marginRight: theme.spacing(0.75),
  },
  themeText: {
    display: "flex",
    flexDirection: "column",
    textAlign: "left",
    justifyContent: "center",
  },
  themeName: {
    fontWeight: 800,
    textTransform: "lowercase",

    "&:first-letter": {
      textTransform: "uppercase",
    },
  },
  themeCount: {
    fontSize: "8px",
    color: theme.palette.blue.darkShade1,
  },
  REALISTIC: {
    background: `url(${realisticPNG}) no-repeat 0 0`,
    backgroundSize: "contain",
  },
  ISOMETRIC: {
    background: `url(${isometricPNG}) no-repeat 0 0 `,
    backgroundSize: "contain",
  },
  MODERN: {
    background: `url(${modernPNG}) no-repeat 0 0`,
    backgroundSize: "contain",
  },
  CLASSIC: {
    background: `url(${classicPNG}) no-repeat 0 0`,
    backgroundSize: "contain",
  },
  // below TODO remove
  BLUE: {
    background: `url(${classicPNG}) no-repeat 0 0`,
  },
  DEFAULT: {
    background: `url(${classicPNG}) no-repeat 0 0`,
  },
  PURPLE: {
    background: `url(${classicPNG}) no-repeat 0 0`,
  },
  RED: {
    background: `url(${classicPNG}) no-repeat 0 0`,
  },
}));
