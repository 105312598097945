import { selectIsInBroadcast } from "modules/broadcast/redux/selectors";
import { selectIsMobile } from "modules/deviceInfo";
import { useWhereAmI } from "modules/mapNavigation/hooks/useWhereAmI/useWhereAmI";
import { setInitiallyLocated } from "modules/mapNavigation/redux/mapNavigationSlice";
import { selectIsInitiallyLocated } from "modules/mapNavigation/redux/selectors";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "store/hooks";
import { selectCurrentRoom } from "store/rooms";

const LOCATE_ME_DELAY = 500;

export const LocateMeInitially = () => {
  const { showWhereAmI } = useWhereAmI();
  const isMobileView = useSelector(selectIsMobile);
  const isInitiallyLocated = useSelector(selectIsInitiallyLocated);
  const isInBroadcast = useSelector(selectIsInBroadcast);
  const dispatch = useAppDispatch();
  const currentTable = useSelector(selectCurrentRoom);

  useEffect(() => {
    if (isMobileView && !isInitiallyLocated && currentTable && !isInBroadcast) {
      // not clearing it because isInitiallyLocated will get changed
      setTimeout(() => {
        showWhereAmI();
      }, LOCATE_ME_DELAY);
      dispatch(setInitiallyLocated(true));
    }
  }, [
    dispatch,
    isMobileView,
    currentTable,
    showWhereAmI,
    isInitiallyLocated,
    isInBroadcast,
  ]);

  return null;
};
