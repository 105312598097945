import { ReactElement } from "react";
import { Slide } from "@remo-co/ui-core/src/components/Slide";
import { Toolbar } from "@remo-co/ui-core/src/components/Toolbar";
import RoomJoinButton from "modules/svgMapView/templateParts/RoomJoinButton";
import { JoinRoomStatus, selectRoomStatus } from "store/rooms";
import { useSelector } from "react-redux";
import { selectIsEventManager } from "modules/event/selectors";
import { useStyles } from "./styles";
import { RemoMobileToolbar } from "./RemoMobileToolbar";

const MobileToolbar = (): ReactElement => {
  const styles = useStyles();
  const roomStatus = useSelector(selectRoomStatus);
  const isEventManager = useSelector(selectIsEventManager);

  const shouldShowJoinRoomButtons =
    roomStatus === JoinRoomStatus.IN_PROGRESS ||
    roomStatus === JoinRoomStatus.PREPARING;

  if (shouldShowJoinRoomButtons) {
    return (
      <Slide direction="up" in timeout={300} unmountOnExit mountOnEnter>
        <div className={styles.joinRoomContainer}>
          <Toolbar>
            <RoomJoinButton />
          </Toolbar>
        </div>
      </Slide>
    );
  }

  return <RemoMobileToolbar isEventManager={isEventManager} />;
};

export default MobileToolbar;
