import { ReactNode, createContext } from "react";
import { SegmentContext } from "./types";
import { useSegment } from "./useSegment";

const contextError = new Error(
  "Do not use tacking context without wrapping it with a Provider in above component tree",
);

export const SEGMENT_CONTEXT = createContext<SegmentContext>({
  group: () => contextError,
  identify: () => contextError,
  page: () => contextError,
  setAnonymousId: () => contextError,
  track: () => contextError,
  reset: () => contextError,
});

interface SegmentProviderInterface {
  children: ReactNode;
}

const SegmentProvider = ({ children }: SegmentProviderInterface) => {
  const analytics = useSegment();

  return (
    <SEGMENT_CONTEXT.Provider value={analytics}>
      {children}
    </SEGMENT_CONTEXT.Provider>
  );
};

export default SegmentProvider;
