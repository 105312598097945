import { ClickAwayListener } from "@remo-co/ui-core/src/components/ClickAwayListener";
import { StartPresentation } from "@remo-co/ui-core/src/icons/StartPresentation";
import { ConversationBroadcast } from "@remo-co/ui-core/src/icons/ConversationBroadcast";
import { Box } from "@remo-co/ui-core/src/components/Box";
import { Typography } from "@remo-co/ui-core/src/components/Typography";
import { useI18n } from "i18n";
import { Strategy } from "@floating-ui/react-dom-interactions";
import { useStyles } from "./styles";

interface Props {
  closePopover: () => void;
  openLiveBroadcastDialog: () => void;
  handleShowConfirmBroadcast: () => void;
  floating: (node: HTMLElement | null) => void;
  y: number | null;
  x: number | null;
  strategy: Strategy;
}

export const PresentButtonPopover = ({
  closePopover,
  openLiveBroadcastDialog,
  handleShowConfirmBroadcast,
  floating,
  y,
  x,
  strategy,
}: Props): JSX.Element => {
  const { t } = useI18n();
  const styles = useStyles();

  return (
    <ClickAwayListener onClickAway={closePopover} mouseEvent="onMouseDown">
      <div
        ref={floating}
        style={{
          position: strategy,
          top: y ?? "",
          left: x ?? "",
          zIndex: 10,
        }}
        className={styles.popoverContainer}
      >
        <Box
          onClick={handleShowConfirmBroadcast}
          className={styles.popoverOption}
        >
          <div>
            <StartPresentation />
          </div>
          <Typography variant="h6">
            {t("event:start.presentation.mode")}
          </Typography>
        </Box>
        <Box onClick={openLiveBroadcastDialog} className={styles.popoverOption}>
          <div>
            <ConversationBroadcast />
          </div>
          <Typography variant="h6">
            {t("event:broadcast.in.conversation.mode")}
          </Typography>
        </Box>
      </div>
    </ClickAwayListener>
  );
};
