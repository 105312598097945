import { ChangeEvent } from "react";
import { RadioGroup } from "@remo-co/ui-core/src/components/RadioGroup";
import { Radio } from "@remo-co/ui-core/src/components/Radio";
import { FormControlLabel } from "@remo-co/ui-core/src/components/FormControlLabel";
import { EventContentMediaType } from "graphql/generated";
import { useI18n } from "i18n";

interface Props {
  mediaType?: string;
  handleChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

export const MediaTypeRadioButtons = ({ mediaType, handleChange }: Props) => {
  const { t } = useI18n(["eventForm", "common"]);

  const options = [
    {
      value: EventContentMediaType.Video,
      label: t("eventForm:form.label.video"),
    },
    {
      value: EventContentMediaType.Iframe,
      label: t("eventForm:form.label.iframe"),
    },
    {
      value: EventContentMediaType.Image,
      label: t("eventForm:form.label.image"),
    },
    {
      value: EventContentMediaType.Text,
      label: t("eventForm:form.label.text"),
    },
  ];

  return (
    <RadioGroup name="mediaType" value={mediaType} onChange={handleChange} row>
      {options.map((option) => (
        <FormControlLabel
          value={option.value}
          control={<Radio color="primary" />}
          label={option.label}
          data-testid={option.label}
          key={option.label}
        />
      ))}
    </RadioGroup>
  );
};
