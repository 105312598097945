import { useEffect, useState } from "react";
import { useI18n } from "i18n";
import { Errors, trackError } from "modules/monitoring";
import { addErrorNotification } from "modules/notification/redux/notificationSlice";
import { useInfiniteQuery } from "@tanstack/react-query";
import { PageParam } from "services/apiService/apis";
import { PAST_JOINED_EVENTS_QUERY_KEY } from "modules/event/constants";
import { getPastJoinedEvents } from "./request";

export const usePastJoinedEventsQuery = () => {
  const { t } = useI18n(["event"]);
  const [totalCount, setTotalCount] = useState(0);

  const defaultPageParam: PageParam = {
    id: "",
    endTime: new Date().toISOString(),
    countEndTime: new Date().toISOString(),
  };

  const eventsQuery = useInfiniteQuery({
    queryKey: [PAST_JOINED_EVENTS_QUERY_KEY],
    queryFn: async ({ pageParam }) => {
      const response = await getPastJoinedEvents(pageParam);

      if (!response.isSuccess) {
        throw new Error(response.message);
      }

      setTotalCount(response.totalCount);

      return response.events;
    },
    initialPageParam: defaultPageParam,
    getNextPageParam: (lastPage, allPages) => {
      if (lastPage.length === 0) {
        return undefined;
      }

      const { id } = lastPage[lastPage.length - 1];

      const { endTime } = lastPage[lastPage.length - 1];

      const countEndTime =
        allPages.length > 0 ? allPages[0][0].endTime : undefined;

      return { id, endTime, countEndTime };
    },
  });

  useEffect(() => {
    if (!eventsQuery.error) return;

    addErrorNotification({
      message: t("event:error.load.past.events", {
        error: eventsQuery.error.message,
      }),
      position: "tr",
    });

    trackError(eventsQuery.error, {
      label: Errors.GET_EVENTS,
    });
  }, [eventsQuery, eventsQuery.error, t]);

  return { totalCount, ...eventsQuery };
};
