import { useState } from "react";
import { Close } from "@remo-co/ui-core/src/icons/Close";
import { Add } from "@remo-co/ui-core/src/icons/Add";
import { useI18n } from "i18n";
import { useTheme } from "@remo-co/ui-core/src/hooks/useTheme";
import { Container } from "@remo-co/ui-core/src/components/Container";
import { Button } from "@remo-co/ui-core/src/components/Button";
import { Typography } from "@remo-co/ui-core/src/components/Typography";
import { IconButton } from "@remo-co/ui-core/src/components/IconButton";
import { useStyles } from "./styles";

export const EditAssignedGuests = ({
  assignedGuests,
  onChange,
}: {
  assignedGuests: string[];
  onChange: (guests: string[]) => void;
}): JSX.Element => {
  const styles = useStyles();
  const [guests, setGuests] = useState<{ email: string; assigned: boolean }[]>(
    assignedGuests.sort().map((guest) => ({ email: guest, assigned: true })),
  );
  const { t } = useI18n(["manageEvent"]);
  const theme = useTheme();

  const updateGuests = (newGuests: { email: string; assigned: boolean }[]) => {
    setGuests(newGuests);
    onChange(
      newGuests
        .filter((guest) => guest.assigned)
        .map((assignedGuest) => assignedGuest.email),
    );
  };

  const unassignAllGuests = () => {
    updateGuests(
      guests.map((currentGuest) => ({ ...currentGuest, assigned: false })),
    );
  };

  const toggleAssignedGuest = (user: { email: string; assigned: boolean }) => {
    updateGuests(
      guests.map((guest) => {
        if (guest.email === user.email) {
          return { email: user.email, assigned: !user.assigned };
        }

        return guest;
      }),
    );
  };

  return (
    <Container data-testid="assigned-guests" top="sm" border>
      <Container flex column>
        <Container
          border
          flex
          padding="xs"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography>{t("assigned.guests")}</Typography>
          <Button
            data-testid="unassign-all-guests"
            onClick={unassignAllGuests}
            variant="secondary"
            size="sm"
            color="gray"
          >
            {t("unassign.all")}
          </Button>
        </Container>
      </Container>
      <Container
        className={styles.guestList}
        padding="xs"
        justifyContent="flex-start"
        flex
        column
      >
        {guests.map((user) => (
          <Container
            data-testid="guest"
            className={styles.guest}
            key={user.email}
            rounded="xs"
            borderColor={
              user.assigned
                ? theme.palette.blue.main
                : theme.palette.gray.medium
            }
            fullWidth
            padding="xs"
            flex
            justifyContent="space-between"
            border
            backgroundColor={
              user.assigned
                ? theme.palette.blue.lightShade1
                : theme.palette.gray.lightShade
            }
          >
            {user.email}
            <IconButton
              data-testid="guest-button"
              onClick={() => toggleAssignedGuest(user)}
              size="small"
            >
              {user.assigned ? <Close color="secondary" /> : <Add />}
            </IconButton>
          </Container>
        ))}
      </Container>
    </Container>
  );
};
