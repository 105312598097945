import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";

export const useStyles = makeStyles((theme) => ({
  title: {
    color: theme.palette.white,
  },
  messageContainer: {
    width: 470,
    textAlign: "left",
    padding: 0,
  },
  message: {
    backgroundColor: "transparent",
    color: theme.palette.gray.medium,
    padding: 0,
  },
  icon: {
    color: theme.palette.gray.light,
  },
}));
