export const EVENT_DIRECTORY_SPLIT_FEATURE = "event-directory";

export const EVENT_DIRECTORY_DELETE_CONFIRM_DIALOG =
  "event-directory-confirm-delete-dialog";

export const DEFAULT_EVENT_LIMIT = 6;

export const DEFAULT_DIRECTORY_LIMIT = 1;

export const EVENT_DIRECTORY_REFRESH_INTERVAL = 60 * 1000;
