import { useState } from "react";
import { Container } from "@remo-co/ui-core/src/components/Container";
import { Typography } from "@remo-co/ui-core/src/components/Typography";
import { IMapTemplate } from "types/theater";
import { useCompanyPlanSettings } from "modules/companyPlanSettings";
import { Info } from "@remo-co/ui-core/src/icons/Info";
import { useI18n } from "i18n";
import CustomFloorPlanResults from "../CustomFloorPlanResults";
import CustomFloorPlanInputs from "../CustomFloorPlanInputs";
import { FloorPlanParser } from "../components/Parser";
import { useStyles } from "./styles";

interface ICustomFloorPlanValidator {
  config?: Partial<IMapTemplate> | null;
  fileSize: number;
  handleSetPlanConfig(plan?: Partial<IMapTemplate> | null): void;
  isFullscreen: boolean;
  isAPILoading: boolean;
  localFileUrl: string;
  toggleFullScreen(): void;
  handleSavePlan(name: string, color: string): void;
  handleDeletePlan(): void;
  overlapCheckResult: string[] | null;
  setOverlapCheckResult: (rooms: string[]) => void;
}

const CustomFloorPlanValidatorV2 = ({
  config,
  fileSize,
  handleDeletePlan,
  handleSavePlan,
  handleSetPlanConfig,
  isFullscreen,
  isAPILoading,
  localFileUrl,
  toggleFullScreen,
  overlapCheckResult,
  setOverlapCheckResult,
}: ICustomFloorPlanValidator): JSX.Element => {
  const { t } = useI18n(["customFloorPlan"]);
  const { isUploadCustomFloorPlanEnabled } = useCompanyPlanSettings();
  const [floorBackgroundColor, setFloorBackgroundColor] = useState("#FFFFFF");
  const styles = useStyles();

  return (
    <Container className="cfp-validator-v2" data-testid="cfp-validator-v2-root">
      {!config && localFileUrl ? (
        <FloorPlanParser
          fileUrl={localFileUrl}
          setFloorPlanConfig={handleSetPlanConfig}
          setOverlapCheckResult={setOverlapCheckResult}
        />
      ) : (
        <>
          {isUploadCustomFloorPlanEnabled ? (
            <CustomFloorPlanInputs
              handleDeletePlan={handleDeletePlan}
              handleSavePlan={handleSavePlan}
              fileSize={fileSize}
              isAPILoading={isAPILoading}
              floorBackgroundColor={floorBackgroundColor}
              setFloorBackgroundColor={setFloorBackgroundColor}
              isFullscreen={isFullscreen}
            />
          ) : (
            <Container
              flex
              alignItems="center"
              className={styles.contactSupport}
            >
              <Info className={styles.icon} />
              <Typography
                variant="body2"
                component="span"
                className={styles.contactSupportText}
              >
                {t("customFloorPlan:contact.support")}
              </Typography>
            </Container>
          )}
          <Container
            flex
            alignItems="flex-start"
            className="cfp-validator-v2-results-ctn"
          >
            <CustomFloorPlanResults
              localFileUrl={localFileUrl}
              config={config}
              fileSize={fileSize}
              toggleFullScreen={toggleFullScreen}
              isFullscreen={isFullscreen}
              floorBackgroundColor={floorBackgroundColor}
              overlapCheckResult={overlapCheckResult}
            />
          </Container>
        </>
      )}
    </Container>
  );
};

export default CustomFloorPlanValidatorV2;
