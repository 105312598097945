import React from "react";
import { useI18n } from "i18n";
import { Container } from "@remo-co/ui-core/src/components/Container";
import { Input } from "@remo-co/ui-core/src/components/Input";
import { ensureUrlHasProtocol } from "helpers/appHelper";
import { ISponsor } from "modules/event/types";
import SponsorCtaButton from "./SponsorCtaButton";
import { isCtaLabelValid, isCtaLinkValid } from "./utils";

interface ISponsorCtaContentProps {
  sponsor: ISponsor;
  setSponsor: (sponsor: ISponsor) => void;
  isFormSubmitted: boolean;
}

const SponsorCtaContent = ({
  sponsor,
  setSponsor,
  isFormSubmitted,
}: ISponsorCtaContentProps) => {
  const [isCtaEnabled, setCtaEnabled] = React.useState(!!sponsor?.ctaLabel);
  const { t } = useI18n(["common", "eventForm"]);

  const handleCtaClick = (value: boolean) => {
    if (!sponsor) {
      return;
    }

    if (!value) {
      setSponsor({
        ...sponsor,
        ctaLabel: "",
        ctaLink: "",
      });
    }

    setCtaEnabled(value);
  };

  const handleTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSponsor({ ...sponsor, [e.target.name]: e.target.value });
  };

  const handleLinkChange = (e: React.FocusEvent<HTMLInputElement>) => {
    const link = ensureUrlHasProtocol(e.target.value);

    setSponsor({ ...sponsor, [e.target.name]: link });
  };

  return (
    <>
      <SponsorCtaButton
        isCtaEnabled={isCtaEnabled}
        setCtaEnabled={handleCtaClick}
      />

      {isCtaEnabled && (
        <>
          <Container top="sm" bottom="sm">
            <Input
              fullWidth
              getRemainingCharsMessage={(key) =>
                t("character.remaining", { key })
              }
              name="ctaLabel"
              id="call-to-action-label"
              label={t("eventForm:call.action.label")}
              placeholder={t("eventForm:call.action.label")}
              value={sponsor.ctaLabel}
              onChange={handleTextChange}
              error={
                (isFormSubmitted &&
                  sponsor.ctaLabel === "" &&
                  sponsor.ctaLink) ||
                (sponsor &&
                  sponsor.ctaLabel !== "" &&
                  !isCtaLabelValid(sponsor))
                  ? (t("eventForm:enter.valid.call") as string)
                  : false
              }
              inputProps={{
                maxLength: 30,
                required: false,
                "data-testid": "cta-label-input",
              }}
            />
          </Container>

          <Container top="sm" bottom="sm">
            <Input
              fullWidth
              name="ctaLink"
              id="call-to-action-link"
              label={t("eventForm:call.action.link")}
              placeholder={t("eventForm:action.link.placeholder")}
              value={sponsor.ctaLink}
              onChange={handleTextChange}
              onBlur={handleLinkChange}
              error={
                (isFormSubmitted &&
                  sponsor.ctaLink == null &&
                  sponsor.ctaLabel == null) ||
                (sponsor &&
                  isCtaLabelValid(sponsor) &&
                  !isCtaLinkValid(sponsor))
                  ? (t("eventForm:enter.valid.call.link") as string)
                  : false
              }
              inputProps={{
                "data-testid": "cta-link-input",
              }}
            />
          </Container>
        </>
      )}
    </>
  );
};

export default SponsorCtaContent;
