import { Info } from "@remo-co/ui-core/src/icons/Info";
import { Typography } from "@remo-co/ui-core/src/components/Typography";
import { Alert } from "@remo-co/ui-core/src/components/Alert";
import { useI18n } from "i18n";
import { useStyles } from "./EmailAlertStyles";

const EmailAlert = () => {
  const classes = useStyles();
  const { t } = useI18n(["common"]);

  return (
    <Alert
      data-testid="email-alert"
      icon={<Info className={classes.infoBtn} />}
      classes={{ root: classes.alertBox, message: classes.alertMessage }}
    >
      <Typography variant="h4">{t("common:email.alert.title")}</Typography>
      <Typography variant="subtitle1">
        {t("common:email.alert.description")}
      </Typography>
    </Alert>
  );
};

export default EmailAlert;
