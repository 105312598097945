import { selectUser } from "modules/auth/redux/selectors";
import useRaiseHandActions from "modules/broadcastRaiseHand/hooks/useRaiseHandActions";
import { useI18n } from "i18n";
import { useUserRaisedHand } from "modules/broadcastRaiseHand/hooks/useUserRaiseHandState";
import { ActionButton } from "modules/actionButton";
import { selectCurrentTheaterId } from "modules/theater/selectors";
import { useSelector } from "react-redux";
import RaisedHandIcon from "../../assets/RaiseHand.png";

const RaiseHandButton = () => {
  const user = useSelector(selectUser);
  const { toggleRaiseHand } = useRaiseHandActions();
  const theaterId = useSelector(selectCurrentTheaterId);
  const isUserRaisedHand = useUserRaisedHand(theaterId, user?.id ?? null);

  const handleButtonClick = async () => {
    if (!user || !theaterId) {
      return;
    }
    await toggleRaiseHand(theaterId, user.id);
  };
  const { t } = useI18n();

  return (
    <ActionButton
      title={
        isUserRaisedHand
          ? t("request.sent.to.host")
          : t("get.your.hosts.attention")
      }
      onClick={handleButtonClick}
      label={isUserRaisedHand ? t("unraise.hand") : t("raise.hand")}
      isActive={isUserRaisedHand}
      icon={<img src={RaisedHandIcon} width="32" alt="" />}
      throttleTime={800}
      darkMode
    />
  );
};

export default RaiseHandButton;
