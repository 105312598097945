import { createSelector } from "@reduxjs/toolkit";
import { selectUser } from "modules/auth/redux/selectors";
import { RootState } from "store";

export const selectConversationBroadcast = (state: RootState) =>
  state.conversationBroadcast;

export const selectConversationBroadcastSessionId = createSelector(
  selectConversationBroadcast,
  (subState) => subState.sessionId,
);

export const selectConversationBroadcastSender = createSelector(
  selectConversationBroadcast,
  (subState) => subState.sender,
);

export const selectConversationBroadcastChannel = createSelector(
  selectConversationBroadcast,
  (subState) => subState.channel,
);

export const selectConversationBroadcastSentAt = createSelector(
  selectConversationBroadcast,
  (subState) => subState.sentAt,
);

export const selectConversationBroadcastVolume = createSelector(
  selectConversationBroadcast,
  (subState) => subState.volume,
);

export const selectIsConversationBroadcastSender = createSelector(
  selectConversationBroadcast,
  selectUser,
  (ConversationBroadcast, user) => ConversationBroadcast?.sender === user?.id,
);
