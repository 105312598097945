import { useI18n } from "i18n";
import { Typography } from "@remo-co/ui-core/src/components/Typography";
import { Button } from "@remo-co/ui-core/src/components/Button";
import { Add } from "@remo-co/ui-core/src/icons/Add";
import { TRACKING_CONTEXT, Events } from "modules/tracking";
import { useContext } from "react";
import TicketingSVG from "../../assets/ticketing.svg";
import { useStyles } from "./styles";

interface Props {
  companyId: string | null;
}

export const NoAccessState = ({ companyId }: Props) => {
  const { track } = useContext(TRACKING_CONTEXT);
  const { t } = useI18n(["common", "eventForm"]);
  const styles = useStyles();

  const trackRequestAccessClick = () => {
    track(Events.TICKETING_ACCESS_REQUESTED, {
      companyIds: companyId ?? undefined,
    });
  };

  return (
    <div className={styles.container}>
      <img src={TicketingSVG} alt={t("eventForm:ticketing.empty.state")} />
      <Typography variant="body1" className={styles.text}>
        {t("eventForm:ticketing.empty.state")}
      </Typography>
      <Button<"a">
        variant="primary"
        size="sm"
        className={styles.button}
        contentClassName={styles.content}
        data-testid="request-access-button"
        href={t("url:ticketing.access.form.link")}
        target="_blank"
        rel="noreferrer"
        onClick={trackRequestAccessClick}
      >
        <Add className={styles.icon} />
        {t("eventForm:get.ticketing.access")}
      </Button>
    </div>
  );
};
