import { IEvent } from "modules/event/types";
import {
  parseURL,
  validateURL,
  isSecureURL,
  isValidImageURL,
  buildURL,
  VALID_URL_REGEX,
} from "@remo-co/url";

export {
  parseURL,
  validateURL,
  VALID_URL_REGEX,
  isSecureURL,
  buildURL,
  isValidImageURL as isValidImage,
};

export const getInviteUrl = (event: Pick<IEvent, "company" | "code">) =>
  `${window.location.origin}/e/${event.code}`;

export const generateIncludesQueryParam = (
  params: Record<string, boolean | undefined>,
): string => {
  const includeParams: string[] = Object.keys(params)
    .filter((key) => params[key])
    .reduce((acc, key) => {
      acc.push(key);
      return acc;
    }, [] as string[]);

  return includeParams.length > 0 ? `include=${includeParams.join(",")}` : "";
};
