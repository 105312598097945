import { Button } from "@remo-co/ui-core/src/components/Button";
import { IconButtonWithTooltip } from "@remo-co/ui-core/src/components/IconButtonWithTooltip";
import { Popper } from "@remo-co/ui-core/src/components/Popper";
import { Typography } from "@remo-co/ui-core/src/components/Typography";
import { Box } from "@remo-co/ui-core/src/components/Box";
import { Lottie } from "@remo-co/ui-core/src/components/Lottie";
import { Close as CloseIcon } from "@remo-co/ui-core/src/icons/Close";
import { useI18n } from "i18n";
import { useSelector } from "react-redux";
import { selectIsMobile } from "modules/deviceInfo";
import { selectIsWaitForEnforceNoticeVisible } from "modules/assignTables/redux";
import { useAppDispatch } from "store/hooks";
import {
  setIsCountdownBannerVisible,
  setIsCountdownDialogVisible,
} from "modules/enforceTableAssignment/redux";
import { EnforceCountdownTimer } from "../EnforceCountdownTimer";
import Animation from "../../assets/animation.json";
import useStyles from "./styles";

const EnforceCountdownDialog = (): JSX.Element | null => {
  const { t } = useI18n(["event"]);
  const isWaitForEnforceNoticeVisible = useSelector(
    selectIsWaitForEnforceNoticeVisible,
  );
  const dispatch = useAppDispatch();
  const isMobileView = useSelector(selectIsMobile);
  const styles = useStyles();

  const handleClose = () => {
    dispatch(setIsCountdownDialogVisible(false));
    dispatch(setIsCountdownBannerVisible(true));
  };

  const handleCountdownEnd = async () => {
    dispatch(setIsCountdownDialogVisible(false));
  };

  if (isWaitForEnforceNoticeVisible) {
    return null;
  }

  return (
    <Popper
      anchorEl={document.body}
      placement="right"
      className={styles.container}
      open
    >
      {!isMobileView && (
        <IconButtonWithTooltip
          id="handle-close-button"
          data-testid="handle-close-button"
          title={t("aria.label.close")}
          className={styles.closeButton}
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButtonWithTooltip>
      )}
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="row"
        className={styles.animationContainer}
      >
        <Lottie animationData={Animation} />
      </Box>
      <div className={styles.content}>
        <Typography className={styles.title} variant="h4" align="center">
          {t("enforce.table.assignment.notify")}
        </Typography>
        <Typography className={styles.subtitle} variant="body1" align="center">
          {t("wrap.conversation.table")}
        </Typography>
        <EnforceCountdownTimer size={50} onCountdownEnd={handleCountdownEnd} />
        {isMobileView && (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="row"
            className={styles.buttonContainer}
          >
            <Button
              size="lg"
              variant="secondary"
              color="dark"
              onClick={handleClose}
            >
              {t("minimize")}
            </Button>
          </Box>
        )}
      </div>
    </Popper>
  );
};

export default EnforceCountdownDialog;
