import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";

export const useStyles = makeStyles(() => ({
  contextMenuItem: {
    padding: "6px 10px !important",
    "& span": {
      fontSize: "0.8rem",
      padding: 0,
    },
  },
}));
