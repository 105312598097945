import { Button } from "@remo-co/ui-core/src/components/Button";
import { Typography } from "@remo-co/ui-core/src/components/Typography";
import { Box } from "@remo-co/ui-core/src/components/Box";
import { FloorPlanLayout } from "modules/eventForm";
import { AddCircleOutline } from "@remo-co/ui-core/src/icons/AddCircleOutline";
import { useI18n } from "i18n";
import classNames from "classnames";
import { IMapTemplate } from "types/theater";
import "./CustomFloorPlanUpload.scss";
import { useCompanyPlanSettings } from "modules/companyPlanSettings";
import { FloorPlanSelectTags } from "../../components/FloorPlanSelectTags";
import { NoFloorPlan } from "../../components/NoFloorPlan";
import { useStyles } from "./styles";

export interface ICustomFloorPlanUploadProps {
  templates: IMapTemplate[];
  isAccountSettings?: boolean;
  onUserSelectLayout?: (template: IMapTemplate, theme?: string) => void;
  showUploader?: () => void;
  disabled?: boolean;
  setShowSeatWarning?: (showWarning: boolean) => void;
}

export const CustomFloorPlanUpload = ({
  templates,
  isAccountSettings,
  onUserSelectLayout,
  showUploader,
  disabled,
  setShowSeatWarning,
}: ICustomFloorPlanUploadProps): JSX.Element => {
  const { t } = useI18n(["settings", "customFloorPlan", "url"]);
  const { isUploadCustomFloorPlanEnabled } = useCompanyPlanSettings();
  const hasTemplates = templates.length > 0;
  const uploadFloorPlanButtonText = isAccountSettings
    ? "settings:custom.floor.plan.upload"
    : "settings:floor.plan.upload";
  const uploadButtonText = isUploadCustomFloorPlanEnabled
    ? uploadFloorPlanButtonText
    : "settings:validate.custom.floor.plan";
  const blankStateButtonText = isUploadCustomFloorPlanEnabled
    ? t("settings:custom.floor.plan.addition")
    : t("settings:validate.custom.floor.plan");

  const buttonText = hasTemplates ? uploadButtonText : blankStateButtonText;

  const createFloorPlanText = hasTemplates
    ? t("settings:custom.floor.plan.own.creation")
    : t("settings:custom.floor.plan.creation");

  const styles = useStyles();

  return (
    <>
      <div
        data-testid="custom-floor-plan-wrapper"
        className={classNames("custom-floor-plan-upload", {
          "in-settings": isAccountSettings,
        })}
      >
        {!isAccountSettings && (
          <FloorPlanSelectTags
            label={t("customFloorPlan:filter.by")}
            // eslint-disable-next-line i18next/no-literal-string
            labelPosition="left"
          />
        )}
        <Button
          disabled={disabled}
          onClick={showUploader}
          variant="secondary"
          size="md"
          color="blue"
          data-testid="upload-floor-plan-button"
          startAdornment={<AddCircleOutline />}
        >
          {t(buttonText)}
        </Button>
        {isAccountSettings && (
          <Typography variant="h6" className="create-floor-plan-link">
            <Button<"a">
              href={t("url:create.custom.floor.plan")}
              target="_blank"
              rel="noreferrer"
              variant="text"
              disabled={disabled}
              style={{ fontWeight: 500 }}
              data-testid="upload-floor-plan-support-link"
            >
              {createFloorPlanText}
            </Button>
          </Typography>
        )}
      </div>
      {isAccountSettings && (
        <Box className={styles.filterTagsBox}>
          <FloorPlanSelectTags
            label={t("customFloorPlan:filter.by")}
            // eslint-disable-next-line i18next/no-literal-string
            labelPosition="left"
          />
        </Box>
      )}
      {hasTemplates ? (
        <FloorPlanLayout
          templates={templates}
          isAccountSettings={isAccountSettings}
          onUserSelectLayout={onUserSelectLayout}
          setShowSeatWarning={setShowSeatWarning}
          floorCount={1}
          showDelete
        />
      ) : (
        <NoFloorPlan />
      )}
    </>
  );
};
