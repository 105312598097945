import { IUser } from "modules/app/types";
import ActionTypes from "./constants";

type AddUsersInEvent = {
  payload: {
    userId: string;
    user: IUser;
  };
  type: ActionTypes.ADD_USERS_IN_EVENT;
};

type ResetUsersInEvent = {
  payload: null;
  type: ActionTypes.RESET_USERS_IN_EVENT;
};

type UsersInEventLoaded = {
  payload: {
    isLoaded: boolean;
    eventId: string;
  };
  type: ActionTypes.USERS_IN_EVENT_LOADED;
};

export type UsersInEventAction =
  | AddUsersInEvent
  | ResetUsersInEvent
  | UsersInEventLoaded;

export const addUsersInEvent = (
  userId: string,
  user: IUser,
): AddUsersInEvent => ({
  payload: { userId, user },
  type: ActionTypes.ADD_USERS_IN_EVENT,
});

export const resetUsersInEvent = (): ResetUsersInEvent => ({
  payload: null,
  type: ActionTypes.RESET_USERS_IN_EVENT,
});

export const isUsersInEventLoaded = (
  isLoaded: boolean,
  eventId: string,
): UsersInEventLoaded => ({
  payload: { isLoaded, eventId },
  type: ActionTypes.USERS_IN_EVENT_LOADED,
});
