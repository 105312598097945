import { Typography } from "@remo-co/ui-core/src/components/Typography";
import classNames from "classnames";
import { useSelector } from "react-redux";
import { selectIsInBroadcast } from "modules/broadcast/redux/selectors";
import { getUserName } from "modules/userProfile";
import { useStyles } from "../styles";
import MessageContent from "../../MessageContent";
import MessageTime from "../MessageTime";
import { IMessageTypeProps } from "../../Message";

export const TextMessage = ({ message, author, isMe }: IMessageTypeProps) => {
  const isInBroadcast = useSelector(selectIsInBroadcast);
  const classes = useStyles({ isInBroadcast: Boolean(isInBroadcast) });

  if (isMe) {
    return (
      <div className={classes.sentWrapper}>
        <div className={classNames(classes.text, classes.sent)}>
          <MessageContent key={message.time} text={message.text} />
          <MessageTime time={message.time} />
        </div>
      </div>
    );
  }

  return (
    <div className={classes.receivedWrapper}>
      <div className={classNames(classes.text, classes.received)}>
        <Typography variant="body2" className={classes.authorName}>
          {getUserName(author)}
        </Typography>
        <MessageContent key={message.time} text={message.text} />
        <MessageTime time={message.time} />
      </div>
    </div>
  );
};
