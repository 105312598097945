import { useI18n } from "i18n";
import useNotificationActions from "modules/notification/hooks/useNotificationActions";
import eventMemberApi from "../eventMember.api";

const useSpeakerActions = () => {
  const { t } = useI18n(["server"]);
  const { addErrorNotification, addSuccessNotification } =
    useNotificationActions();

  const getEventSpeakersList = (eventId: string) =>
    eventMemberApi.getEventSpeakerList(eventId);

  const revokeSpeakerRights = async (eventId: string, email: string) => {
    const resp = await eventMemberApi.revokeSpeakerAccess(eventId, email);

    if (resp.isSuccess) {
      addSuccessNotification({
        message: "Speaker removed successfully!",
        position: "tc",
        autoDismiss: 5,
      });
    } else {
      addErrorNotification({
        message: resp.message ? t(resp.message) : "Oops! Something went wrong!",
        position: "tc",
        autoDismiss: 5,
      });
    }

    return resp;
  };

  return {
    revokeSpeakerRights,
    getEventSpeakersList,
  };
};

export default useSpeakerActions;
