import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { Announcement } from "../types";

interface AnnouncementReducerState extends Announcement {
  id?: string;
  message?: string;
  sender?: string;
  sentAt?: number;
  isShowSendDialog: boolean;
  channel?: string;
  isNew: boolean;
}

export const initialState: AnnouncementReducerState = {
  channel: undefined,
  message: undefined,
  sender: undefined,
  sentAt: undefined,
  isNew: false,
  isShowSendDialog: false,
};

const announcementSlice = createSlice({
  name: "announcement",
  initialState,
  reducers: {
    openSendDialog: (state: AnnouncementReducerState) => {
      state.isShowSendDialog = true;
    },
    closeSendDialog: (state: AnnouncementReducerState) => {
      state.isShowSendDialog = false;
    },
    receivedAnnouncement: (
      state: AnnouncementReducerState,
      { payload }: PayloadAction<Announcement>,
    ) => {
      state.isNew = !state.sentAt || state.sentAt !== payload?.sentAt;

      state.id = payload.id;
      state.sender = payload.sender;
      state.sentAt = payload.sentAt;
      state.message = payload.message;
    },
    setup: (
      state: AnnouncementReducerState,
      { payload }: PayloadAction<{ channel: string; sender: string }>,
    ) => {
      state.channel = payload.channel;
      state.sender = payload.sender;
    },
    reset: () => initialState,
  },
});

export const {
  openSendDialog,
  closeSendDialog,
  receivedAnnouncement,
  setup,
  reset,
} = announcementSlice.actions;

export default announcementSlice.reducer;
