import { sendPostRequest } from "services/apiService";

export type SwitchTablePayload = {
  eventId: string;
  floorId: string;
  tableId: string;
};

type SwitchTableResponse =
  | {
      status: false;
      reason?: string;
      message?: string;
    }
  | {
      status: true;
      tableId: string;
    };
const joinTableRequest = async (data: SwitchTablePayload) => {
  const { eventId, ...payload } = data;
  const resp = await sendPostRequest<
    Omit<SwitchTablePayload, "eventId">,
    SwitchTableResponse
  >(`/event/${eventId}/table/join`, payload);

  if (!resp.status) {
    throw new Error(resp?.reason ?? resp?.message);
  }

  return resp;
};

export default joinTableRequest;
