import { useI18n } from "i18n";
import { ReactElement } from "react";
import { EventPollOption, EventPollStatus } from "graphql/generated";
import { Container } from "@remo-co/ui-core/src/components/Container";
import { Divider } from "@remo-co/ui-core/src/components/Divider";
import { Typography } from "@remo-co/ui-core/src/components/Typography";
import { useStyles } from "./styles";

interface Props {
  options: EventPollOption[];
  status: EventPollStatus;
}

const Stats = ({ options, status }: Props): ReactElement => {
  const classes = useStyles();
  const { t } = useI18n(["event"]);
  const votes = options.reduce((total, option) => total + option.votes, 0);

  return (
    <>
      <Divider className={classes.statsDivider} />
      <Container
        flex
        justifyContent="space-between"
        className={classes.statsContainer}
      >
        {status !== EventPollStatus.Draft && (
          <Typography>{t(`polls.votes`, { votes })}</Typography>
        )}
      </Container>
    </>
  );
};

export default Stats;
