import { useSelector } from "react-redux";
import { useAppDispatch } from "store/hooks";
import {
  DatabaseReference,
  DataSnapshot,
  off,
  onValue,
} from "firebase/database";
import { useCallback, useMemo, useRef } from "react";
import useBroadcasterActions from "modules/broadcaster/hooks/useBroadcasterActions";
import useEventTracking from "modules/eventTracking/hooks/useEventTracking";
import useSystemClock from "modules/system/hooks/useSystemClock";
import { selectCurrentTheaterId } from "modules/theater/selectors";
import {
  Actions as DDActions,
  trackAction,
  trackActionStart,
  setGlobalContext,
} from "modules/monitoring";
import { usePermissions } from "modules/audioVideo/hooks/usePermissions";
import { clearEmojis } from "modules/emoji/redux";
import { liveStreamFirebase } from "services/firebaseService/liveStream";
import { setIsLiveStreaming } from "modules/liveStream/redux/slice";
import { useRecordingAsync } from "modules/recording";
import { selectStartRecording } from "modules/broadcast/redux/selectors";
import { setLayout } from "modules/audioVideo/redux/slice";
import { DisplayLayout } from "modules/audioVideo";
import { selectUserId } from "modules/auth/redux/selectors";
import { selectPresentationSpeakersSessionIds } from "modules/audioVideo/redux/selectors";
import { BroadcastStatus } from "../../redux/constants";
import broadcastFirebase from "../../broadcast.firebase";
import actions, { IStartBroadcastParams } from "../../redux/actions";
import { useBroadcastLiveStream } from "../useBroadcastLiveStream";

const useBroadcastActions = () => {
  const dispatch = useAppDispatch();
  const startRecordingInBroadcast = useSelector(selectStartRecording);
  const theaterId = useSelector(selectCurrentTheaterId);

  const { resetBroadcaster } = useBroadcasterActions();
  const docRef = useRef<DatabaseReference>();
  const userId = useSelector(selectUserId);
  const { trackPresentationStart, trackPresentationStop } = useEventTracking();
  const { getCurrentTime } = useSystemClock();
  const { setConversationPermissions, setPresentationPermissions } =
    usePermissions();
  const { startBroadcastStream } = useBroadcastLiveStream();
  const speakers = useSelector(selectPresentationSpeakersSessionIds);
  const { startRecording, isLoading, isRecording } = useRecordingAsync();

  const shouldRecordOnStart = useMemo(
    () => !isRecording && startRecordingInBroadcast && !isLoading,
    [isRecording, startRecordingInBroadcast, isLoading],
  );

  const enterBroadcast = useCallback(async () => {
    trackActionStart(DDActions.PRESENTATION_MODE_START);
    setGlobalContext("mode", "presentation");
    dispatch(actions.enterBroadcast());
    dispatch(clearEmojis());

    if (shouldRecordOnStart) {
      startRecording({ streamIds: speakers });
    }

    startBroadcastStream();
  }, [
    dispatch,
    shouldRecordOnStart,
    speakers,
    startRecording,
    startBroadcastStream,
  ]);

  const broadcastTransitionStarted = () => {
    dispatch(actions.broadcastTransitionStarted());
  };

  const broadcastTransitionComplete = useCallback(() => {
    dispatch(actions.broadcastTransitionComplete());
  }, [dispatch]);

  const stopBroadcast = useCallback(async () => {
    if (!theaterId) {
      return;
    }

    broadcastTransitionStarted();
    setGlobalContext("mode", "conversation");
    trackAction(DDActions.PRESENTATION_MODE_STOP);
    setConversationPermissions();
    await broadcastFirebase.stopBroadcast(theaterId);
    await liveStreamFirebase.stopLiveStream(theaterId);
    dispatch(setIsLiveStreaming(false));
    await resetBroadcaster({ theaterId });
    dispatch(setLayout(DisplayLayout.Map));
    trackPresentationStop();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [theaterId, setConversationPermissions]);

  const startBroadcast = useCallback(
    async ({
      delay,
      isTurnOnMicCamWhenStart,
      streams,
      recordOnEntering,
    }: Pick<
      IStartBroadcastParams,
      "delay" | "isTurnOnMicCamWhenStart" | "streams" | "recordOnEntering"
    >) => {
      if (!userId || !theaterId) {
        return;
      }
      setGlobalContext("mode", "presentation");

      dispatch(
        actions.startBroadcast({
          delay,
          startedBy: userId,
          startedAt: getCurrentTime(),
          isTurnOnMicCamWhenStart,
          streams,
          recordOnEntering,
        }),
      );

      await broadcastFirebase.startBroadcast(theaterId, {
        isBroadcasting: true,
        timeout: delay,
        startedBy: userId,
      });
      trackPresentationStart();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [theaterId, userId, setPresentationPermissions],
  );

  const subscribe = useCallback(() => {
    if (!theaterId) {
      return;
    }

    docRef.current = broadcastFirebase.getBroadcastInfoDocRef(theaterId);
    off(docRef.current);
    onValue(docRef.current, (snapshot: DataSnapshot) => {
      const val = snapshot.val();

      if (val) {
        const { isBroadcasting, startedAt, startedBy, timeout } = val;
        const broadcastStatus =
          startedAt + timeout > getCurrentTime()
            ? BroadcastStatus.PREPARING
            : BroadcastStatus.IN_PROGRESS;

        dispatch(
          actions.updateBroadcastInfo({
            isBroadcasting,
            startedAt,
            startedBy,
            delay: timeout,
            broadcastStatus,
          }),
        );

        setGlobalContext(
          "mode",
          isBroadcasting ? "presentation" : "conversation",
        );
      } else {
        trackActionStart(DDActions.PRESENTATION_MODE_END);
        dispatch(actions.stopBroadcast());
        setGlobalContext("mode", "conversation");
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [theaterId]);

  const unsubscribe = useCallback(() => {
    if (docRef.current) {
      off(docRef.current);
    }
  }, []);

  const reset = useCallback(() => {
    dispatch(actions.reset());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    startBroadcast,
    stopBroadcast,
    enterBroadcast,
    subscribe,
    unsubscribe,
    reset,
    broadcastTransitionComplete,
  };
};

export default useBroadcastActions;
