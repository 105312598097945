import { SerializedDevice } from "../types";

export const parseDeviceLabel = (deviceLabel: string): string =>
  deviceLabel.replace(/ \([a-f0-9]{4}:[a-f0-9]{4}\)$/, "");

export const encodeDevice = (device: SerializedDevice) =>
  JSON.stringify({
    deviceId: device.deviceId,
    label: device.label,
  });

const unwrapParsedJson = <T>(payload: string): T | null => {
  try {
    return JSON.parse(payload);
  } catch {
    return null;
  }
};

export const decodeDevice = (
  device: string | null,
): SerializedDevice | null => {
  if (!device) {
    return null;
  }

  const parsedDevice = unwrapParsedJson<SerializedDevice>(device);

  if (!parsedDevice) {
    return null;
  }

  const { deviceId, label } = parsedDevice;

  if (!deviceId || !label) {
    return null;
  }

  return {
    deviceId,
    label,
  };
};

let browserSupportsSetSinkId: undefined | boolean;

export const supportsSetSinkId = () => {
  // TODO: study if using this other approach is better to avoid creating an element
  // https://gist.github.com/thehunmonkgroup/1e687259167e3a48a55cd0f3260deb70
  if (browserSupportsSetSinkId === undefined) {
    browserSupportsSetSinkId = "setSinkId" in document.createElement("video");
  }

  return browserSupportsSetSinkId;
};
