import { IServerTime } from "../types";
import { syncDeviation } from "./actions";
import ActionTypes from "./constants";

export const initialState: IServerTime = {
  deviation: null,
};

const serverTimeReducer = (
  state = initialState,
  action: ReturnType<typeof syncDeviation>,
) => {
  switch (action.type) {
    /* istanbul ignore next */
    case ActionTypes.SYNC_DEVIATION:
      return {
        ...state,
        deviation: action.payload,
      };
    default:
      return state;
  }
};

export default serverTimeReducer;
