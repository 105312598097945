import { DailyInputVideoProcessorSettings } from "@daily-co/daily-js";
import { VirtualBackgrounds } from "../../constants";
import { VirtualBackgroundRequest } from "../../types";

const getImageDataURI = (blob: Blob): Promise<string | ArrayBuffer | null> =>
  new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.addEventListener("load", () => {
      resolve(fileReader.result);
    });
    fileReader.addEventListener("error", () => {
      reject(fileReader.error);
    });

    fileReader.readAsDataURL(blob);
  });

const getImageBlob = async (imageUrl: string) => {
  const imageResponse = await fetch(imageUrl);
  const imageBlob = await imageResponse.blob();
  return imageBlob;
};

export const parseRequest = async (
  request: VirtualBackgroundRequest,
): Promise<DailyInputVideoProcessorSettings> => {
  switch (request.type) {
    case VirtualBackgrounds.BLUR: {
      return {
        type: "background-blur",
        config: { strength: request.strength ?? 0.5 },
      };
    }
    case VirtualBackgrounds.IMAGE: {
      const imageBlob = await getImageBlob(request.url);
      const imageDataURI = await getImageDataURI(imageBlob);
      return {
        type: "background-image",
        config: { source: imageDataURI as string },
      };
    }
    default: {
      return {
        type: "none",
      };
    }
  }
};
