import { CheckCircle } from "../../icons/CheckCircle";
import { Error } from "../../icons/Error";
import { useStyles } from "./style";

export type statusVariant = "success" | "warning" | "error";

interface Props {
  variant: statusVariant;
}

const StatusIcon = ({ variant }: Props): JSX.Element => {
  const styles = useStyles({ variant });
  switch (variant) {
    case "success":
      return (
        <CheckCircle className={styles.status} data-testid="success-icon" />
      );
    case "warning":
      return <Error className={styles.status} data-testid="warning-icon" />;
    case "error":
    default:
      return <Error className={styles.status} data-testid="error-icon" />;
  }
};

export default StatusIcon;
