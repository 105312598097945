import { createBrowserHistory } from "history";
import { routerMiddleware } from "connected-react-router";
import { Action, ThunkDispatch, configureStore } from "@reduxjs/toolkit";
import { getVar } from "config";
import createRootReducer from "./reducers";

export const history = createBrowserHistory();

export const store = configureStore({
  reducer: createRootReducer(history),
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      thunk: true,
      immutableCheck: false, // Turn this to true once we refactor all reducers
      serializableCheck: false, // Turn this to true once we refactor all reducers
    }).concat(routerMiddleware(history)),
  devTools: getVar("REACT_APP_CLIENT_HOST") !== "live.remo.co" ? {} : false,
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = ThunkDispatch<RootState, void, Action>;

export default store;
