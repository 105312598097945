import {
  collection,
  doc,
  onSnapshot,
  QuerySnapshot,
  DocumentData,
} from "firebase/firestore";
import { useEffect } from "react";
import { selectCurrentEventId } from "modules/event/selectors";
import { useSelector } from "react-redux";
import { useAppDispatch } from "store/hooks";
import { setLiveStreams } from "modules/liveStream/redux/slice";
import { LiveStreamData } from "modules/liveStream/request";
import { firestore } from "services/firebaseService/firebaseConfig";
import {
  EVENT_PATH,
  LIVE_STREAM_DESTINATIONS,
} from "../../../../services/firebaseService/storagePaths";

export const liveStreamDestinationsRef = (eventId: string) =>
  collection(
    doc(collection(firestore, EVENT_PATH), eventId),
    LIVE_STREAM_DESTINATIONS,
  );

export const LiveStreamDestinationsSubscriber = () => {
  const eventId = useSelector(selectCurrentEventId);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!eventId) return;

    const unsubscribe = onSnapshot(
      liveStreamDestinationsRef(eventId),
      (snapshot: QuerySnapshot<DocumentData>) => {
        const livestream = snapshot.docs.reduce(
          (accumulator: DocumentData[], doc) => {
            const data = doc.data();
            const { id } = doc;

            if (data) {
              accumulator.push({ ...data, id });
            }

            return accumulator;
          },
          [],
        );
        if (!livestream) {
          dispatch(setLiveStreams([]));
          return;
        }
        dispatch(setLiveStreams(livestream as LiveStreamData[]));
      },
    );

    // eslint-disable-next-line consistent-return
    return () => {
      unsubscribe();
    };
  }, [dispatch, eventId]);

  return null;
};
