import { sendDeleteRequest, sendPostRequest } from "services/apiService";

export type SwitchFloorPayload = {
  eventId: string;
  targetFloorId: string;
  currentFloorId: string;
};

type SwitchFloorResponse =
  | {
      status: false;
      reason?: string;
      message?: string;
    }
  | SwitchFloorSuccess;

type SwitchFloorSuccess = {
  status: true;
  currentFloorId: string;
  targetFloorId: string;
  tableId: string;
};

type LeaveFloorResponse =
  | {
      status: false;
      message: string;
    }
  | {
      status: true;
    };

export const joinFloorRequest = async (
  data: SwitchFloorPayload,
): Promise<SwitchFloorSuccess> => {
  const { eventId, ...payload } = data;
  const resp = await sendPostRequest<
    Omit<SwitchFloorPayload, "eventId">,
    SwitchFloorResponse
  >(`/event/${eventId}/floor`, payload);

  if (!resp.status) {
    throw new Error(resp?.reason ?? resp?.message); // default error handler returns a message field
  }

  return resp;
};

export const leaveFloorRequest = async (data: {
  eventId: string;
  floorId: string;
  userId: string;
}) => {
  const { eventId, userId, ...payload } = data;
  const resp = await sendDeleteRequest<{ floorId: string }, LeaveFloorResponse>(
    `/event/${eventId}/user/${userId}`,
    payload,
  );

  if (!resp.status) {
    throw new Error(resp.message);
  }

  return resp;
};
