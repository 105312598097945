import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";

export const useStyles = makeStyles(() => ({
  themeContainer: {
    boxShadow: "none",
    display: "flex",
    position: "relative",
    overflow: "hidden",
    maxWidth: "415px",
    borderRadius: "10px !important",
    marginTop: "2.5rem",
  },
  isLoading: {
    display: "block",
  },
  image: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
  isImageLoading: {
    opacity: 0.5,
    width: "auto",
    height: "auto",
    objectFit: "none",
  },
  mapImageContainer: {
    height: "250px",
    width: "100%",
    position: "relative",
  },
  mapImage: {
    height: "100%",
    width: "100%",
  },
}));
