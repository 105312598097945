import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "store";

const selectServerTime = (state: RootState) => state.serverTime;

const makeSelectDeviation = () =>
  createSelector(
    selectServerTime,
    (serverTimeState) => serverTimeState.deviation,
  );

export { selectServerTime, makeSelectDeviation };
