import { gql } from "@apollo/client";

export const CREATE_EMBED_CONTENT = gql`
  mutation CreateEmbedContent(
    $eventId: ID!
    $url: String!
    $mode: EventEmbedContentMode!
  ) {
    createEmbedContent(eventId: $eventId, url: $url, mode: $mode) {
      ... on EventEmbedContent {
        __typename
        id
        url
        eventId
        createdAt
        mode
      }
      ... on GQLError {
        __typename
        message
      }
    }
  }
`;

export const DELETE_EVENT_EMBED_CONTENT = gql`
  mutation DeleteEventEmbedContent($id: Int!, $eventId: String!) {
    deleteEventEmbedContent(id: $id, eventId: $eventId) {
      ... on EventEmbedContent {
        __typename
        id
        url
        eventId
        createdAt
        mode
      }
      ... on GQLError {
        __typename
        message
      }
    }
  }
`;
