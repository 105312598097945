import { selectBroadcastStreams } from "modules/broadcast/redux/selectors";
import { useLiveStreamingAsync } from "modules/liveStream/hooks";
import { selectIsLiveStreamLoading } from "modules/liveStream/redux/selectors";
import { selectCurrentTheaterId } from "modules/theater/selectors";
import { useCallback, useContext } from "react";
import { useSelector } from "react-redux";
import { liveStreamFirebase } from "services/firebaseService/liveStream";
import { Events, TRACKING_CONTEXT } from "modules/tracking";
import { selectCurrentEventId } from "modules/event/selectors";

export const useBroadcastLiveStream = () => {
  const { track } = useContext(TRACKING_CONTEXT);
  const eventId = useSelector(selectCurrentEventId);
  const theaterId = useSelector(selectCurrentTheaterId);
  const streams = useSelector(selectBroadcastStreams);
  const isLoading = useSelector(selectIsLiveStreamLoading);

  const onLiveStreamStarted = useCallback(() => {
    if (theaterId && streams && streams.length) {
      liveStreamFirebase.startLiveStream(theaterId);
      track(Events.RTMP_OUT_SUCCESSFULLY_STREAMING, {
        eventId,
      });
    }
  }, [eventId, streams, theaterId, track]);

  const { startStreaming, isLiveStreaming } = useLiveStreamingAsync({
    onLiveStreamStarted,
  });

  const startBroadcastStream = useCallback(async () => {
    if (!isLiveStreaming && !isLoading && streams && streams.length) {
      startStreaming({
        serverUrl: streams[0].serverUrl,
        streamKey: streams[0].streamKey,
      });
    }
  }, [streams, startStreaming, isLiveStreaming, isLoading]);

  return { startBroadcastStream };
};
