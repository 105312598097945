import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "store";
import { UserSessionInteractionsState } from "./slice";

const selectUserSessionInteractionsState = (
  state: RootState,
): UserSessionInteractionsState => state.userSessionInteractions;

export const selectIsSafariAutoplayResumed = createSelector(
  selectUserSessionInteractionsState,
  (state) => state.isSafariAutoplayResumed,
);

export const selectMicrophoneActive = createSelector(
  selectUserSessionInteractionsState,
  (substate) => substate.microphoneActive,
);

export const selectCameraActive = createSelector(
  selectUserSessionInteractionsState,
  (substate) => substate.cameraActive,
);
