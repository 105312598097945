import moment, { Duration as MomentDuration } from "moment";
import { Duration } from "./types";

export const wrapMomentDuration = (duration: MomentDuration): Duration => ({
  hours: duration.hours(),
  days: duration.days(),
  minutes: duration.minutes(),
  seconds: duration.seconds(),
  milliseconds: duration.milliseconds(),
});

export const timeDuration = ({
  start,
  end,
}: {
  start: number;
  end: number;
}): Duration => wrapMomentDuration(moment.duration(end - start));
