import { Typography } from "@remo-co/ui-core/src/components/Typography";
import { Button } from "@remo-co/ui-core/src/components/Button";
import { Delete } from "@remo-co/ui-core/src/icons/Delete";
import { useI18n } from "i18n";
import { useStyles } from "../styles";

interface ISponsorCtaButtonProps {
  isCtaEnabled: boolean;
  setCtaEnabled: (isCtaEnabled: boolean) => void;
}

const SponsorCtaButton = ({
  isCtaEnabled,
  setCtaEnabled,
}: ISponsorCtaButtonProps) => {
  const { t } = useI18n(["eventForm"]);
  const styles = useStyles();

  return (
    <div className="cta-button-container">
      {!isCtaEnabled && (
        <Button
          data-testid="cta-button"
          contentClassName={styles.ctaButton}
          variant="ghost"
          color="blue"
          onClick={() => setCtaEnabled(true)}
        >
          +{t("eventForm:add.cta.button")}
        </Button>
      )}
      {isCtaEnabled && (
        <Typography variant="h4">
          {t("eventForm:call.action.button")}
        </Typography>
      )}
      {isCtaEnabled && (
        <Button
          data-testid="cta-cancel-button"
          contentClassName={styles.ctaButton}
          variant="ghost"
          color="dark"
          onClick={() => setCtaEnabled(false)}
        >
          <Delete />
        </Button>
      )}
    </div>
  );
};

export default SponsorCtaButton;
