import { sendPostRequest } from "services/apiService";

interface EnforceAssignmentsResponse extends IGenericAPIResponse {
  status?: boolean;
  reason?: string;
}

export const getRequestURL = (eventId: string, presetId?: string) =>
  `/event/${eventId}/enforce-assignments/process${
    presetId ? `?presetId=${presetId}` : ""
  }`;

const request = async (eventId: string, presetId?: string) => {
  const resp = await sendPostRequest<
    Record<string, never>,
    EnforceAssignmentsResponse
  >(getRequestURL(eventId, presetId), {});

  if (!resp.status) {
    throw new Error(resp?.reason ?? resp?.message ?? resp?.error);
  }

  return resp;
};

export default request;
