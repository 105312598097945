import { DeprecatedThemeOptions } from "@mui/material/styles";
import { Colors } from "./colors";
import {
  fontWeightBold,
  fontWeightMedium,
  fontWeightRegular,
} from "./fontWeights";

export const typography: DeprecatedThemeOptions["typography"] = {
  fontFamily: [
    "Avenir",
    "Helvetica Neue",
    "Helvetica",
    "Arial",
    "sans-serif",
  ].join(","),
  fontSize: 14,
  fontWeightRegular,
  fontWeightMedium,
  fontWeightBold,
  h1: {
    fontWeight: fontWeightMedium,
    fontSize: 36,
    lineHeight: 1.35,
  },
  h2: {
    fontWeight: fontWeightMedium,
    fontSize: 28,
    lineHeight: 1.35,
  },
  h3: {
    fontWeight: fontWeightMedium,
    fontSize: 20,
    lineHeight: 1.35,
  },
  h4: {
    fontWeight: fontWeightMedium,
    fontSize: 18,
    lineHeight: 1.35,
  },
  h5: {
    fontWeight: fontWeightMedium,
    fontSize: 16,
    lineHeight: 1.5,
  },
  h6: {
    fontWeight: fontWeightMedium,
    fontSize: 14,
    lineHeight: 1.5,
  },
  body1: {
    fontWeight: fontWeightMedium,
    fontSize: 14,
    lineHeight: 1.5,
  },
  body2: {
    fontWeight: fontWeightMedium,
    fontSize: 14,
    lineHeight: 1.5,
  },
  caption: {
    fontWeight: fontWeightMedium,
    fontSize: 12,
    lineHeight: 1.5,
  },
  subtitle1: {
    fontWeight: fontWeightRegular,
    fontSize: 14,
    lineHeight: 1.5,
  },
  subtitle2: {
    fontWeight: fontWeightBold,
    fontSize: 14,
    lineHeight: 1.5,
    color: Colors.BLUE_DARKSHADE1,
  },
};
