import { Theme } from "@remo-co/ui-core/src/types";
import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";

export const useStyles = makeStyles<Theme>(() => ({
  backButtonAdornment: {
    width: "16px",
    height: "16px",
  },
  backButtonContent: {
    paddingLeft: "8px",
  },
}));
