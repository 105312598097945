import { MiroBoard } from "modules/whiteboard/types";

export const getMiroBoardUrl = ({
  boardCode,
  miroBoardId,
}: MiroBoard): string => {
  if (miroBoardId && boardCode) {
    return `https://miro.com/app/live-embed/${miroBoardId}/?boardAccessToken=${boardCode}&embedAutoplay=true`;
  }

  if (boardCode) {
    return `https://miro.com/app/access-link/${boardCode}`;
  }

  return "";
};
