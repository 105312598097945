import { TranscriptionContext } from "modules/transcription";
import { TimedSpeechData } from "modules/transcription/components/TranscriptionSidebar/TranscriptionSidebar";
import { useContext, useMemo } from "react";

interface TranscriptionSidebarHook {
  finalizedTranscript: TimedSpeechData[] | null;
}

const useTranscriptionSidebar = (): TranscriptionSidebarHook => {
  const { finalizedTranscript } = useContext(TranscriptionContext);

  const outputTranscripts = useMemo(() => {
    if (finalizedTranscript) {
      return finalizedTranscript.sort(
        (a, b) => a.time.valueOf() - b.time.valueOf(),
      );
    }
    return null;
  }, [finalizedTranscript]);

  return { finalizedTranscript: outputTranscripts };
};

export default useTranscriptionSidebar;
