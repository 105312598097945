import { ChangeEvent } from "react";
import { Typography } from "@remo-co/ui-core/src/components/Typography";
import { useI18n } from "i18n";
import { IEvent } from "modules/event/types";
import { selectCompanySettingsFeatureToggles } from "modules/companySettings/redux/selectors";
import { useSelector } from "react-redux";
import { FeatureItem } from "modules/manageEvent/hooks/types";
import { CompanyFeatureToggles } from "modules/company/constants";
import FeatureToggle from "../FeatureToggle";
import "./Features.scss";

interface Props {
  eventData: IEvent;
  onEventDataChange: (data: Partial<IEvent>) => void;
}

const Features = ({ eventData, onEventDataChange }: Props) => {
  const disabledFeaturesAtCompanyLevel = useSelector(
    selectCompanySettingsFeatureToggles,
  );
  const { t } = useI18n(["eventForm", "manageEvent"]);

  const onToggle = (e: ChangeEvent<HTMLInputElement>, feature: FeatureItem) => {
    onEventDataChange({
      [e.target.name]: feature.negated ? !e.target.checked : e.target.checked,
    });
  };

  const features: FeatureItem[] = [
    {
      label: t("eventForm:features.show.screenshare"),
      name: "isScreenShareDisabled",
      checked: !eventData.isScreenShareDisabled,
      negated: true,
    },
    {
      label: t("eventForm:features.audience.enable"),
      name: "isAudienceViewDisabled",
      checked: !eventData.isAudienceViewDisabled,
      negated: true,
    },
    {
      label: t("eventForm:features.remoji.enable"),
      name: "isRemojiDisabled",
      checked: !eventData.isRemojiDisabled,
      negated: true,
    },
    {
      label: t("eventForm:features.qna.enable"),
      name: "isQnADisabled",
      checked: !eventData.isQnADisabled,
      negated: true,
      disabledAtCompanyLevel: disabledFeaturesAtCompanyLevel.includes(
        CompanyFeatureToggles.QA,
      ),
    },
    {
      label: t("eventForm:features.chat.enable"),
      name: "isChatDisabled",
      checked: !eventData.isChatDisabled,
      negated: true,
      disabledAtCompanyLevel: disabledFeaturesAtCompanyLevel.includes(
        CompanyFeatureToggles.Chat,
      ),
    },
    {
      label: t("eventForm:features.whiteboard.enable"),
      name: "isWhiteboardDisabled",
      checked: !eventData.isWhiteboardDisabled,
      negated: true,
      disabledAtCompanyLevel: disabledFeaturesAtCompanyLevel.includes(
        CompanyFeatureToggles.Whiteboard,
      ),
    },
    {
      label: t("eventForm:features.polls.enable"),
      name: "isPollsDisabled",
      checked: !eventData.isPollsDisabled,
      negated: true,
      disabledAtCompanyLevel: disabledFeaturesAtCompanyLevel.includes(
        CompanyFeatureToggles.Polls,
      ),
    },
    {
      label: t("eventForm:features.lock.tables.enable"),
      name: "isLockTablesDisabled",
      checked: !eventData.isLockTablesDisabled,
      negated: true,
      disabledAtCompanyLevel: disabledFeaturesAtCompanyLevel.includes(
        CompanyFeatureToggles.LockTables,
      ),
    },
    {
      label: t("eventForm:features.support.enable"),
      name: "isChatSupportDisabled",
      checked: !eventData.isChatSupportDisabled,
      visible: false,
      negated: true,
    },
    {
      label: t("eventForm:features.guest.survey.enable"),
      name: "isGuestExperienceSurveyDisabled",
      checked: !eventData.isGuestExperienceSurveyDisabled,
      visible: true,
      negated: true,
    },
    {
      label: t("eventForm:features.user.id.display"),
      name: "isUserIdDisplayed",
      checked: Boolean(eventData.isUserIdDisplayed),
      visible: true,
    },
    {
      label: t("eventForm:allow.overflow.seating"),
      name: "isOverflowSeatingDisabled",
      checked:
        eventData.isOverflowSeatingDisabled !== undefined &&
        !eventData.isOverflowSeatingDisabled,
      visible: true,
      negated: true,
    },
  ];

  const renderFeatureTogglers = () =>
    features
      .filter((feature) => ("visible" in feature ? feature.visible : true))
      .map((feature) => (
        <FeatureToggle
          {...feature}
          onToggle={(e) => onToggle(e, feature)}
          key={feature.name}
          testId={feature.name}
        />
      ));

  return (
    <div className="features-page-ctn" data-testid="event-features">
      <Typography variant="h3">{t("manageEvent:features")}</Typography>
      <Typography variant="body1">{t("eventForm:features.intro")}</Typography>
      <div className="mar-top-40">{renderFeatureTogglers()}</div>
    </div>
  );
};

export default Features;
