import { useCallback, useEffect, useRef, useState } from "react";
import { Container } from "@remo-co/ui-core/src/components/Container";
import { Loader } from "@remo-co/ui-core/src/components/Loader";
import { IRoom } from "types";
import { Colors } from "@remo-co/ui-core/theme";
import { BrokenImage } from "@remo-co/ui-core/src/icons/BrokenImage";
import { useResizeDetector } from "react-resize-detector/build/withPolyfill";
import { useStyles } from "./styles";
import { MiniMapTable } from "./MiniMapTable";

type Status = "loading" | "loaded" | "error";

export const MiniMap = ({
  selectedTableCode,
  tables,
  mapImage,
}: {
  selectedTableCode: string | null;
  tables: IRoom[];
  mapImage: string;
}): JSX.Element => {
  const imageRef = useRef<HTMLImageElement | null>(null);
  const [imageStatus, setImageStatus] = useState<Status>("loading");
  const [scale, setScale] = useState(0);

  const updateScale = useCallback(() => {
    if (imageStatus === "loaded" && imageRef.current) {
      const scaleFactor =
        imageRef.current.width / imageRef.current.naturalWidth;
      setScale(scaleFactor);
    }
  }, [imageStatus]);

  const { ref: containerRef } = useResizeDetector({ onResize: updateScale });

  useEffect(() => {
    updateScale();
  }, [updateScale]);

  const styles = useStyles(scale);

  return (
    <Container
      ref={containerRef}
      flex
      alignItems="center"
      justifyContent="center"
      className={styles.root}
    >
      <div className={styles.miniMapContainer}>
        {imageStatus !== "error" && (
          <>
            {tables.map((table) => (
              <MiniMapTable
                key={table.code}
                height={table.height}
                width={table.width}
                x={table.x}
                y={table.y}
                scale={scale}
                tableCode={table.code}
                selected={table.code === selectedTableCode}
              />
            ))}
            <img
              ref={imageRef}
              data-testid="mini-map"
              className={styles.map}
              alt="Minimap"
              src={mapImage}
              onError={() => {
                setImageStatus("error");
              }}
              onLoad={() => {
                setImageStatus("loaded");
              }}
            />
          </>
        )}
      </div>
      {imageStatus === "loading" && (
        <div className={styles.center}>
          <Loader backgroundColor={Colors.WHITE} />
        </div>
      )}
      {imageStatus === "error" && (
        <Container
          data-testid="minimap-error"
          className={styles.center}
          flex
          alignItems="center"
        >
          <BrokenImage />
        </Container>
      )}
    </Container>
  );
};
