import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { IUser } from "modules/app/types";
import throttle from "modules/throttle";
import { EventUserNotificationType } from "../constants";

export interface UserForceMoveData {
  createdAt: number;
  managerId: string;
  userId: string;
  targetTableId: string;
  currentTableId: string;
  targetFloorId: string;
  currentFloorId: string;
  type: EventUserNotificationType;
  userName: string;
  eventManagerName: string;
  floorName: string;
  tableName: string;
}

export interface UserForceMove {
  showUserForceMovePopup: boolean;
  shouldUserForceMove: boolean;
  userForceMoveData: UserForceMoveData | undefined;
  isUserMoving: boolean;
  showUserForceMoveDialog: boolean;
  user: IUser | undefined;
  delayThrottleFunction: ReturnType<typeof throttle> | undefined;
}

export const initialState: UserForceMove = {
  showUserForceMovePopup: false,
  shouldUserForceMove: false,
  userForceMoveData: undefined,
  isUserMoving: false,
  showUserForceMoveDialog: false,
  user: undefined,
  delayThrottleFunction: undefined,
};

const userForceMoveSlice = createSlice({
  name: "UserForceMove",
  initialState,
  reducers: {
    setShowUserForceMovePopup: (
      state: UserForceMove,
      action: PayloadAction<boolean>,
    ) => {
      state.showUserForceMovePopup = action.payload;
    },
    setShowUserForceMoveDialog: (
      state: UserForceMove,
      action: PayloadAction<IUser | undefined>,
    ) => {
      state.showUserForceMoveDialog = Boolean(action.payload);
      state.user = action.payload;
    },
    setUserForceMoveData: (
      state: UserForceMove,
      action: PayloadAction<UserForceMoveData>,
    ) => {
      state.userForceMoveData = action.payload;
      state.isUserMoving = true;
    },
    setUserForceMove: (
      state: UserForceMove,
      action: PayloadAction<boolean>,
    ) => {
      state.shouldUserForceMove = action.payload;
    },
    setDelayThrottleFunction: (
      state: UserForceMove,
      action: PayloadAction<ReturnType<typeof throttle>>,
    ) => {
      state.delayThrottleFunction = action.payload;
    },
    resetUserForceMove: () => initialState,
  },
});

export const {
  setShowUserForceMovePopup,
  setShowUserForceMoveDialog,
  setUserForceMoveData,
  setUserForceMove,
  setDelayThrottleFunction,
  resetUserForceMove,
} = userForceMoveSlice.actions;

export default userForceMoveSlice.reducer;
