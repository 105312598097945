import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";

export const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(1.5),
  },
  label: {
    marginBottom: theme.spacing(1),
  },
  selectedType: {
    background: theme.palette.gray.light,
  },
  type: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    borderRadius: "100px",
    padding: theme.spacing(1),
  },
  typeLabel: {
    fontWeight: 800,
    marginLeft: theme.spacing(0.5),
  },
}));
