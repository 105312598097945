import { filter } from "lodash";
import { IRoom } from "types";
import parseSvgPath from "parse-svg-path";
import { IMapTemplate } from "types/theater";
import { SVGSourceTypes } from "./constants";

export const configHasRoomName = (
  config: Partial<IMapTemplate>,
  roomName: string,
) =>
  config?.rooms &&
  filter(config?.rooms, (room: IRoom) => room?.name === roomName).length > 0;

export const bytesToSize = (bytes: number | null) => {
  const sizes = ["Bytes", "KB", "MB", "GB", "TB"];

  if (bytes === 0 || bytes === null) {
    return "0 Byte";
  }

  const i = Math.floor(Math.log(bytes) / Math.log(1024));

  return `${Math.round(bytes / 1024 ** i)}${sizes[i]}`;
};

export const convertSVGPointListToString = (
  svgPointList: SVGPointList,
  x: number,
  y: number,
  svgSource: SVGSourceTypes = SVGSourceTypes.ABSOLUTE,
  // eslint-disable-next-line max-params
) => {
  let points = "";

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < svgPointList.length; i++) {
    const point = svgPointList.getItem(i);

    if (svgSource === SVGSourceTypes.RELATIVE) {
      points += `${point.x},${point.y} `;
    } else {
      points += `${point.x - x},${point.y - y} `;
    }
  }

  return points;
};

export const getRoomName = (roomId: string | null) => {
  if (roomId) {
    const parts = roomId ? roomId.split("-") : [];

    parts.shift();

    return parts ? parts.join("-") : null;
  }

  return null;
};

export const getRoomSlug = (value: string): string =>
  value
    .toString()
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .toLowerCase()
    .trim()
    .replace(/\s+/g, "-")
    .replace(/_+/g, "-")
    .replace(/[^\w-]+/g, "")
    .replace(/--+/g, "-");

// Scale transformations require a constant vector, but CFP SVGs have varying
// dimensions. Calculating the vector here based on window innerWidth ensures the
// fullscreen preview scales to approximately 80vw.
export const getFullScreenScaleVector = (config: Partial<IMapTemplate>) => {
  if (!config?.width || !config?.height) return "";

  const vector = Math.min(1 / (config.width / (window.innerWidth * 0.8)), 0.8);

  return `scale(${vector})`;
};

/**
 * The following util functions are taken from remo-admin
 */

const convertPointsArrayToString = (pointsArray: (string | number)[][]) => {
  let convertedD = "";

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < pointsArray.length; i++) {
    convertedD += pointsArray[i][0];

    // eslint-disable-next-line no-plusplus
    for (let j = 1; j < pointsArray[i].length; j++) {
      switch (pointsArray[i][0]) {
        case "L":
        case "l":
        case "M":
        case "m":
        case "T":
        case "t":
          convertedD += `${pointsArray[i][j]},${pointsArray[i][j + 1]} `;
          // eslint-disable-next-line no-plusplus
          j++;
          break;
        case "S":
        case "s":
        case "Q":
        case "q":
          convertedD += `${pointsArray[i][j]},${pointsArray[i][j + 1]} ${
            pointsArray[i][j + 2]
          },${pointsArray[i][j + 3]} `;
          j += 3;
          break;
        case "C":
        case "c":
          convertedD += `${pointsArray[i][j]},${pointsArray[i][j + 1]} ${
            pointsArray[i][j + 2]
          },${pointsArray[i][j + 3]} ${pointsArray[i][j + 4]},${
            pointsArray[i][j + 5]
          } `;
          j += 5;
          break;
        case "A":
        case "a":
          convertedD += `${pointsArray[i][j]} ${pointsArray[i][j + 1]} ${
            pointsArray[i][j + 2]
          } ${pointsArray[i][j + 3]} ${pointsArray[i][j + 4]} ${
            pointsArray[i][j + 5]
          },${pointsArray[i][j + 6]} `;
          j += 6;
          break;
        case "H":
        case "h":
        case "V":
        case "v":
          convertedD += `${pointsArray[i][j]} `;
          break;
        default:
          break;
      }
    }
  }

  return convertedD;
};

export const convertSvgPathToRelative = (
  d: string,
  parentX: number,
  parentY: number,
) => {
  const pointsArray = parseSvgPath(d);

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < pointsArray.length; i++) {
    if (
      pointsArray[i][0] &&
      pointsArray[i][0].toLowerCase() !== pointsArray[i][0]
    ) {
      // eslint-disable-next-line no-plusplus
      for (let j = 1; j < pointsArray[i].length; j++) {
        switch (pointsArray[i][0]) {
          case "L":
          case "M":
          case "C":
          case "S":
          case "Q":
          case "T":
            if (j % 2 === 0) {
              pointsArray[i][j] -= parentY;
            } else {
              pointsArray[i][j] -= parentX;
            }
            break;
          case "H":
            pointsArray[i][j] -= parentX;
            break;
          case "V":
            pointsArray[i][j] -= parentY;
            break;
          case "A":
            if (j % 7 === 0) {
              pointsArray[i][j] -= parentY;
            } else if ((j + 1) % 7 === 0) {
              pointsArray[i][j] -= parentX;
            }
            break;
          default:
            break;
        }
      }
    }
  }

  return convertPointsArrayToString(pointsArray);
};

export const isPointInRectangle = (
  pointX: number,
  pointY: number,
  rect: DOMRect,
) =>
  rect.x < pointX &&
  pointX < rect.x + rect.width &&
  rect.y < pointY &&
  pointY < rect.y + rect.height;
