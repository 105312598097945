import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ITag } from "modules/company/types";

export interface ICustomFloorPlanState {
  selectedTags: string[];
  companyTags: ITag[];
  defaultTags: ITag[];
}

export const initialState: ICustomFloorPlanState = {
  selectedTags: [],
  companyTags: [],
  defaultTags: [],
};

const customFloorPlanSlice = createSlice({
  name: "customFloorPlan",
  initialState,
  reducers: {
    setFloorPlanSelectedTags: (state, action: PayloadAction<string[]>) => ({
      ...state,
      selectedTags: action.payload,
    }),
    setCompanyTags: (state, action: PayloadAction<ITag[]>) => ({
      ...state,
      companyTags: action.payload,
    }),
    addCompanyTag: (state, action: PayloadAction<ITag>) => ({
      ...state,
      companyTags: [...state.companyTags, action.payload],
    }),
    setDefaultTags: (state, action: PayloadAction<ITag[]>) => ({
      ...state,
      defaultTags: action.payload,
    }),
  },
});

export const {
  setFloorPlanSelectedTags,
  setCompanyTags,
  addCompanyTag,
  setDefaultTags,
} = customFloorPlanSlice.actions;

export default customFloorPlanSlice.reducer;
