import { DailyTrackState } from "@daily-co/daily-js";

export enum VirtualBackgrounds {
  BLUR = "background-blur",
  IMAGE = "background-image",
  NONE = "none",
}

export const DAILY_PLAYABLE_TRACK_STATES: DailyTrackState["state"][] = [
  "playable",
  "interrupted",
];

export const screenEncodings = [
  {
    maxBitrate: 2000000,
    maxFramerate: 24,
    scaleResolutionDownBy: 1,
  },
];

export const SCREEN_SHARE_MAX_BITRATE = 2000000;
export const SCREEN_SHARE_SCALE_RESOLUTION_DOWN_BY = 1;
export const SCREEN_SHARE_MAX_FRAMERATE = 20;

export const screenShareEncodings = {
  low: {
    maxBitrate: SCREEN_SHARE_MAX_BITRATE,
    scaleResolutionDownBy: SCREEN_SHARE_SCALE_RESOLUTION_DOWN_BY,
    maxFramerate: SCREEN_SHARE_MAX_FRAMERATE,
  },
};

export const LOCAL_MIC_KEY = "audioInput";
export const LOCAL_SPEAKER_KEY = "audioOutput";
export const LOCAL_CAM_KEY = "videoInput";
