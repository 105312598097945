import { TableData } from "../../types";

export const tableHasSeatAssignments = (
  tableData: TableData | undefined,
  floorId: string | null,
  tableId: string | null,
): boolean =>
  tableData &&
  floorId &&
  tableId &&
  tableData[floorId] &&
  tableData[floorId][tableId]
    ? Boolean(tableData[floorId][tableId].users?.length)
    : false;

export const floorHasSeatAssignments = (
  tableData: TableData | undefined,
  floorId: string | null,
): boolean =>
  tableData && floorId && tableData[floorId]
    ? Object.entries(tableData[floorId]).some(([_, table]) =>
        Boolean(table.users?.length),
      )
    : false;
