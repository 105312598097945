import { Box } from "@remo-co/ui-core/src/components/Box";
import { Typography } from "@remo-co/ui-core/src/components/Typography";
import { useI18n } from "i18n";
import useStyles from "./styles";

interface Props {
  name: string;
}

const UnassignTableContextMenuDialogContent = ({
  name,
}: Props): JSX.Element => {
  const { t } = useI18n(["event"]);
  const styles = useStyles();

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="flex-start"
      className={styles.content}
    >
      <Typography variant="h5" className={styles.title}>
        {t("event:unassign.seats.from.table", {
          name,
        })}
      </Typography>
      <Typography variant="body2" className={styles.subtitle}>
        {t("event:unassign.seats.from.table.subtitle")}
      </Typography>
    </Box>
  );
};

export default UnassignTableContextMenuDialogContent;
