import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";

export const useStyles = makeStyles((theme) => ({
  labelBox: {
    marginTop: theme.spacing(2.5),
  },
  label: {
    color: theme.palette.blue.dark,
    textAlign: "left",
  },
  sizeRecommendationsLink: {
    color: theme.palette.blue.main,
    textDecoration: "underline",
  },
  floorImageUploader: {
    height: "80px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  floorImageUploaderContent: {
    padding: 0,
  },
  floorImageUploaderPreview: {
    width: 440,
  },
}));
