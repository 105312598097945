import React, { useCallback, useRef, useState } from "react";
import { useI18n } from "i18n";
import logger from "logging/logger";
import { Container } from "@remo-co/ui-core/src/components/Container";
import { Typography } from "@remo-co/ui-core/src/components/Typography";
import useCompany from "modules/company/hooks/useCompany";
import useNotificationActions from "modules/notification/hooks/useNotificationActions";
import { Colors } from "@remo-co/ui-core/theme";
import { IMapTemplate } from "types/theater";
import { useCompanyPlanSettings } from "modules/companyPlanSettings";
import { useSelector } from "react-redux";
import {
  addDialogNotification,
  closeDialogNotification,
} from "modules/dialogNotification/redux/dialogNotificationSlice";
import { useAppDispatch } from "store/hooks";
import CustomFloorPlanUploader from "./CustomFloorPlanUploader";
import CustomFloorPlanValidatorV2 from "./validator/CustomFloorPlanValidatorV2";
import { rollbackFloorPlanUpload, uploadFloorPlan } from "./actions";
import { insertTemplate } from "./customFloorPlan.api";
import "./CustomFloorPlanContainer.scss";
import { isIsometricFloorPlan } from "./components/Parser/utils/utils";
import { selectFloorPlanTags } from "./redux/selectors";

interface Props {
  onSave: (template: IMapTemplate) => void;
  templates?: IMapTemplate[];
  handleSetDialogVisible?: (isDialogVisible: boolean) => void;
}

const CustomFloorPlanContainer = ({
  onSave,
  templates,
  handleSetDialogVisible,
}: Props): JSX.Element => {
  const { t } = useI18n(["customFloorPlan"]);
  const { company } = useCompany();
  const { isUploadCustomFloorPlanEnabled } = useCompanyPlanSettings();
  const { addErrorNotification, addSuccessNotification } =
    useNotificationActions();
  const dispatch = useAppDispatch();
  const [localFileUrl, setLocalFileUrl] = useState("");
  const [planConfig, setPlanConfig] = useState<
    undefined | null | Partial<IMapTemplate>
  >(null);
  const [fileSize, setFileSize] = useState(0);
  const [overlapCheckResult, setOverlapCheckResult] = useState<string[] | null>(
    null,
  );
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const containerRef = useRef<null | HTMLDivElement>(null);
  const selectedTags = useSelector(selectFloorPlanTags);
  const toggleFullScreen = () => {
    if (!isFullscreen) {
      containerRef?.current?.focus();
    } else {
      containerRef?.current?.blur();
    }
    setIsFullscreen(!isFullscreen);
  };

  const handleSetPlanConfig = (plan?: Partial<IMapTemplate> | null) => {
    setPlanConfig(plan);
  };

  const handleSavePlan = useCallback(
    async (name: string, color: string) => {
      if (!company) {
        addErrorNotification({
          message: t("common:unknown.error"),
        });

        return;
      }

      setLoading(true);
      const data = {
        ...planConfig,
        name,
        code: name.toUpperCase().split(" ").join("-"),
        backgroundColor: color || planConfig?.backgroundColor,
        tags: selectedTags,
      };

      logger.info(
        "[CustomFloorPlanContainer][handleSavePlan] Data to be uploaded:",
        data,
      );

      if (data.rooms?.length === 0 || data.seats === 0) {
        addErrorNotification({
          message: t("customFloorPlan:error.seats.tables.not.found"),
        });
        setLoading(false);

        return;
      }

      // below is alright to run even if privateTemplates == [] - this will return false.
      // this will only prevent template name duplication within this host's set of templates, not
      // across all other hosts
      const nameExists =
        templates &&
        templates.some((template) => template && template.name === name);

      if (nameExists) {
        addErrorNotification({
          message: t("customFloorPlan:error.name.already.exists", {
            key: data.name,
          }),
        });
        setLoading(false);

        return;
      }

      // Upload map to storage first
      // this current format will upload files to Layouts\${companyId}\${code}.svg to prevent possible name collision
      // Consider alternate route of Layouts\${code}\${filename}.svg, filename could be layout as well,
      // but this does not guarantee file placements don't overlap/overwrite
      const { success, ...uploadResult } = await uploadFloorPlan(
        localFileUrl,
        company._id,
        `${data.code}.svg`,
      );

      if (!success) {
        logger.error(
          `[CustomFloorPlanContainer][handleSavePlan] ${uploadResult.error}`,
        );
        addErrorNotification({
          message: uploadResult.error,
        });

        return;
      }

      let newTemplate;

      // API call to save template in Firestore, update company settings.
      // Be careful of not adding anything else inside this try-catch - we only want it to
      // throw if there's an API-related error. Causing a different kind of error inside this
      // will trigger the rollback inside catch
      try {
        data.image = encodeURIComponent(uploadResult.childPath ?? "");
        const { isSuccess, ...apiResult } = await insertTemplate(
          company._id,
          data,
          uploadResult.filePath ?? "", // guaranteed to not be nullish after !success check above
        );

        if (isSuccess) {
          newTemplate = apiResult.template;
          addSuccessNotification({
            message: t("customFloorPlan:upload.successful", {
              key: newTemplate.name,
            }),
          });
          setLoading(false);
          await onSave(newTemplate);
        } else {
          addErrorNotification({
            message: t("customFloorPlan:upload.failed"),
          });
          setLoading(false);
          throw new Error();
        }
      } catch (error) {
        const deleteResult = await rollbackFloorPlanUpload(
          uploadResult.childPath ?? "", // guaranteed to not be nullish after !success check above
        );

        logger.error(
          `[CustomFloorPlanContainer][handleSavePlan] Upload rollback for storage path ${
            uploadResult.childPath
          } ${deleteResult ? "succeeded" : "failed"} `,
        );
        setLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [planConfig, localFileUrl, company, selectedTags],
  );

  const handleSavePlanWithOverlapConfirm = (name: string, color: string) => {
    if (overlapCheckResult?.length) {
      if (isIsometricFloorPlan(planConfig)) {
        dispatch(
          addDialogNotification({
            message: t("customFloorPlan:error.room.blocked"),
            confirmText: t("customFloorPlan:save"),
            dismissText: t("customFloorPlan:cancel"),
            hideCloseButton: true,
            confirmColor: Colors.YELLOW_DARK,
            onConfirm: () => {
              handleSavePlan(name, color);
            },
            onDismiss: () => {
              dispatch(closeDialogNotification());
            },
          }),
        );
        return;
      }

      addErrorNotification({
        message: t("customFloorPlan:error.room.blocked"),
      });
      return;
    }

    handleSavePlan(name, color);
  };

  const handleDeletePlan = useCallback(() => {
    setPlanConfig(null);
    setLocalFileUrl("");
    setOverlapCheckResult(null);

    if (handleSetDialogVisible) handleSetDialogVisible(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (!e.shiftKey && e.key === "Escape" && isFullscreen) {
      e.preventDefault();
      toggleFullScreen();
    }
  };

  return (
    <Container
      flex
      column
      tabIndex={0}
      onKeyDown={handleKeyDown}
      justifyContent="flex-start"
      className="cfp-container"
      maxWidth="xl"
      ref={containerRef}
      data-testid="cfp-container"
    >
      <Typography variant="h3" gutterBottom className="header-text">
        {isUploadCustomFloorPlanEnabled
          ? t("customFloorPlan:upload.validate")
          : t("customFloorPlan:validate")}
      </Typography>
      {isFullscreen && <div className="fullscreen-overlay" />}
      {localFileUrl ? (
        <CustomFloorPlanValidatorV2
          config={planConfig}
          fileSize={fileSize}
          handleDeletePlan={handleDeletePlan}
          handleSavePlan={handleSavePlanWithOverlapConfirm}
          handleSetPlanConfig={handleSetPlanConfig}
          isFullscreen={isFullscreen}
          isAPILoading={isLoading}
          localFileUrl={localFileUrl}
          toggleFullScreen={toggleFullScreen}
          overlapCheckResult={overlapCheckResult}
          setOverlapCheckResult={setOverlapCheckResult}
        />
      ) : (
        <CustomFloorPlanUploader
          handleSetFileSize={setFileSize}
          handleSetLocalFileUrl={setLocalFileUrl}
          handleSetDialogVisible={handleSetDialogVisible}
        />
      )}
    </Container>
  );
};

export default CustomFloorPlanContainer;
