import { ContentWithMapping } from "graphql/generated";
import { ContentMapping, ContentWithConsolidatedMapping } from "../../types";

interface MappingValidationResults {
  exceedsLimit: boolean;
  slotsAreOccupied: boolean;
}

export const validateContentMapping = (
  mapping: ContentMapping,
  limit: number,
  existingContent: ContentWithMapping[],
  preloadedContent?: ContentWithConsolidatedMapping | null,
): MappingValidationResults => ({
  exceedsLimit:
    limit -
      (preloadedContent
        ? existingContent.filter(
            (content) => content.id !== preloadedContent.id,
          )
        : existingContent
      ).length -
      mapping.floors.length <
    0,
  slotsAreOccupied: mapping.floors.some((floorId) =>
    (preloadedContent
      ? existingContent.filter((content) => content.id !== preloadedContent.id)
      : existingContent
    ).find(
      (content) => content.floor === floorId && content.slot === mapping.slot,
    ),
  ),
});
