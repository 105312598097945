import { ChangeEvent, useCallback, useEffect, useState } from "react";
import { useI18n } from "i18n";
import { Box } from "@remo-co/ui-core/src/components/Box";
import { Container } from "@remo-co/ui-core/src/components/Container";
import { Input } from "@remo-co/ui-core/src/components/Input";
import { Typography } from "@remo-co/ui-core/src/components/Typography";
import { IEvent } from "modules/event/types";
import { maxShortDescLength } from "../../constants";
import { DescriptionEditor, EventTypeSelect } from "../../prepare";
import { EventOutcomeSelect } from "../EventOutcomeSelect";
import { EventDiscoveryOptOutCheckbox } from "../EventDiscoveryOptOutCheckbox";
import useStyles from "./styles";
import { ContactChannelSection } from "../ContactChannelSection";

interface Props {
  eventData: IEvent;
  onEventDataChange: (eventData: Partial<IEvent>) => void;
}

const EventDetailsTab = ({
  eventData,
  onEventDataChange,
}: Props): JSX.Element => {
  const { t } = useI18n(["common", "eventForm"]);
  const styles = useStyles();
  const [endTime, setEndTime] = useState(eventData.endTime);
  const { isUnlimitedEvent } = eventData;
  const [shortDesc, setShortDesc] = useState({
    error: false,
    value: eventData.shortDescription,
    message: "",
  });
  const [description, setDescription] = useState({
    error: false,
    value: eventData.description,
    message: "",
  });

  const updateTextFields = useCallback(() => {
    onEventDataChange({
      shortDescription: shortDesc.value,
      description: description.value,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [description.value, shortDesc.value]);

  useEffect(() => {
    const debouncedEventDataUpdate = setTimeout(() => updateTextFields(), 250);

    return () => {
      clearTimeout(debouncedEventDataUpdate);
    };
  }, [updateTextFields]);

  const handleShortDesc = (e: ChangeEvent<HTMLInputElement>) => {
    setShortDesc({ error: false, value: e.target.value, message: "" });
  };

  const handleDescriptionChange = (descContent: string) => {
    setDescription({ error: false, value: descContent, message: "" });
  };

  useEffect(() => {
    const data = {
      description: description.value,
    };
    onEventDataChange(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [description]);

  useEffect(() => {
    if (eventData.endTime !== endTime) {
      setEndTime(eventData.endTime);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventData.endTime]);

  return (
    <Box data-testid="basic-event-info">
      <Typography variant="h3">{t("manageEvent:event.details")}</Typography>
      <div className={styles.inputFields}>
        <Container bottom="sm">
          <Input
            fullWidth
            getRemainingCharsMessage={(key) =>
              t("character.remaining", { key })
            }
            name="eventShortDesc"
            label={t("eventForm:event.tag")}
            placeholder={t("eventForm:placeholder.event.tag")}
            value={shortDesc.value}
            inputProps={{
              maxLength: maxShortDescLength,
              "data-testid": "event-tag",
            }}
            onChange={handleShortDesc}
            id="event-tagline"
            onBlur={updateTextFields}
            size="sm"
          />
        </Container>
      </div>
      <DescriptionEditor
        onChange={handleDescriptionChange}
        content={description.value}
      />
      <Box mb={3} />

      {!isUnlimitedEvent && <EventDiscoveryOptOutCheckbox />}
      <EventTypeSelect />
      <EventOutcomeSelect />
      <ContactChannelSection />
    </Box>
  );
};

export default EventDetailsTab;
