import React, { useContext } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "store/hooks";
import { Events, TRACKING_CONTEXT } from "modules/tracking";
import { selectCurrentEventId } from "modules/event/selectors";
import {
  selectIsAudienceViewExpanded,
  selectIsContainerMounted,
} from "modules/broadcast/AudienceView/selectors";
import { selectLiveUsers } from "modules/eventUserPresence/redux/selectors";
import { Actions, setGlobalContext, trackAction } from "modules/monitoring";
import { selectIsAudienceViewEnabled } from "../../../event/selectors";
import {
  setIsContainerMounted as setIsContainerMountedAction,
  setIsExpanded as setIsExpandedAction,
  setItemsPerRow,
  updateSlots as updateSlotsAction,
} from "../actions";

const CONTAINER_SELECTOR = "#audience-view-container";
const AVATAR_MARGIN_RIGHT = 16;
const AVATAR_WIDTH = 44 + AVATAR_MARGIN_RIGHT;
const CONTAINER_HORIZONTAL_PADDING_LEFT = 20;
const CONTAINER_HORIZONTAL_PADDING_RIGHT =
  CONTAINER_HORIZONTAL_PADDING_LEFT - AVATAR_MARGIN_RIGHT;

export const useAudienceViewActions = () => {
  const liveUserIds = useSelector(selectLiveUsers);
  const dispatch = useAppDispatch();
  const { track } = useContext(TRACKING_CONTEXT);
  const eventId = useSelector(selectCurrentEventId);
  const recomputeItemsPerRow = React.useCallback(() => {
    const containerElement: HTMLDivElement | null =
      document.querySelector(CONTAINER_SELECTOR);

    if (!containerElement) return;

    dispatch(
      setItemsPerRow(
        Math.max(
          Math.floor(
            (containerElement.offsetWidth -
              CONTAINER_HORIZONTAL_PADDING_LEFT -
              CONTAINER_HORIZONTAL_PADDING_RIGHT) /
              AVATAR_WIDTH,
          ),
          0,
        ),
        liveUserIds,
      ),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [liveUserIds]);

  const setIsExpanded = React.useCallback(
    (isExpanded: boolean) => {
      if (isExpanded) {
        track(Events.AUDIENCE_VIEW_OPENED, { eventId });
        trackAction(Actions.PRESENTATION_MODE_AUDIENCE_VIEW_OPENED, {
          eventId,
        });
        setGlobalContext("audienceView", "open");
      } else {
        track(Events.AUDIENCE_VIEW_CLOSED, { eventId });
        trackAction(Actions.PRESENTATION_MODE_AUDIENCE_VIEW_CLOSED, {
          eventId,
        });
        setGlobalContext("audienceView", "closed");
      }

      dispatch(setIsExpandedAction(isExpanded));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [eventId],
  );

  const updateSlots = React.useCallback((userIds: string[]) => {
    dispatch(updateSlotsAction(userIds));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setIsContainerMounted = React.useCallback((isMounted: boolean) => {
    dispatch(setIsContainerMountedAction(isMounted));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    recomputeItemsPerRow,
    setIsExpanded,
    updateSlots,
    setIsContainerMounted,
  };
};

export const AudienceViewEffects = () => {
  const liveUserIds = useSelector(selectLiveUsers);
  const { recomputeItemsPerRow, updateSlots } = useAudienceViewActions();
  const isExpanded = useSelector(selectIsAudienceViewExpanded);
  const isContainerMounted = useSelector(selectIsContainerMounted);
  const isAudienceViewEnabled = useSelector(selectIsAudienceViewEnabled);

  React.useEffect(() => {
    window.addEventListener("resize", recomputeItemsPerRow);

    return () => {
      window.removeEventListener("resize", recomputeItemsPerRow);
    };
  }, [recomputeItemsPerRow]);

  React.useEffect(() => {
    if (!isExpanded || !isAudienceViewEnabled) return;

    updateSlots(liveUserIds);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [liveUserIds, isExpanded, updateSlots]);

  React.useEffect(() => {
    if (!isContainerMounted || !isAudienceViewEnabled) return;

    recomputeItemsPerRow();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isContainerMounted, recomputeItemsPerRow]);

  return null;
};
