import { getGooglePicBySize } from "helpers/profileHelper";
import { ReactComponent as DefaultAvatar } from "modules/audioVideo/assets/defaultAvatar.svg";
import { useStyles } from "./styles";

export const DefaultProfileImage = ({
  imageUrl = "default",
}: {
  imageUrl?: string | null;
}) => {
  const styles = useStyles();
  return imageUrl !== "default" ? (
    <img
      alt="profile"
      src={getGooglePicBySize(imageUrl ?? "", 500)}
      className={styles.userProfileImg}
    />
  ) : (
    <DefaultAvatar className={styles.defaultProfile} />
  );
};
