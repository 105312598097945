import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";
import {
  fontWeightRegular,
  fontWeightBold,
} from "@remo-co/ui-core/src/theme/fontWeights";

const useStyles = makeStyles((theme) => ({
  container: {
    maxWidth: "400px",
    paddingBottom: 0,
  },
  content: { paddingTop: theme.spacing(2), textAlign: "left" },
  directions: {
    opacity: 0.7,
    fontWeight: fontWeightRegular,
    paddingBottom: theme.spacing(1.25),
  },
  link: {
    opacity: 0.7,
    fontWeight: fontWeightBold,
    color: theme.palette.white,
  },
  form: {
    marginTop: theme.spacing(2.5),
  },
  label: {
    marginBottom: theme.spacing(0.5),
    color: theme.palette.white,
  },
  previewLabel: {
    marginTop: theme.spacing(1.5),
  },
  preview: {
    height: "250px",
    marginTop: theme.spacing(1.25),
    border: `1px solid ${theme.palette.white}`,
    borderRadius: "5px",
    cursor: "not-allowed",
    userSelect: "none",
  },
  previewIframe: {
    height: "100%",
    width: "100%",
    borderRadius: "5px",
    pointerEvents: "none",
  },
}));

export default useStyles;
