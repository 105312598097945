import { Theme } from "@remo-co/ui-core/src/types";
import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";
import { NetworkQuality } from "modules/audioVideo/types";
import zIndexes from "zIndexes.module.scss";

interface CanvasStyles {
  showMuteIcon: boolean;
  isLocal?: boolean;
  networkQuality: NetworkQuality | null;
  showReaction?: boolean;
  isInterrupted?: boolean;
}

const BORDER_WIDTH = 4;
const MUTE_BUTTON_HEIGHT = 14;

export const useStyles = makeStyles<Theme, CanvasStyles>((theme) => ({
  clickable: ({ showReaction }: CanvasStyles) => ({
    background: theme.palette.black,
    cursor: "pointer",
    outline: "none",
    width: "100%",
    height: "100%",
    border: "none",
    padding: 0,
    opacity: `${showReaction ? "0.5" : "1"}`,
  }),
  tileBackground: ({ showReaction }: CanvasStyles) => ({
    background: showReaction ? "rgba(89, 210, 44, 0.3)" : theme.palette.black,
    height: "100%",
    width: "100%",
  }),
  root: {
    textAlign: "center",
    "& > img": {
      height: "100%",
    },
    backgroundColor: "black",
    position: "relative",
    borderRadius: "3px",
    overflow: "hidden",
    width: `100%`,
    height: `100%`,
    cursor: "pointer",
    "&:hover $muteGuestButton": {
      opacity: "1",
      transform: "translate(0, 0)",
      transition: "0.3s all ease-in",
    },
    "&:hover $networkQuality": {
      display: ({ isLocal, networkQuality, isInterrupted }: CanvasStyles) =>
        isLocal || (!isLocal && (networkQuality !== "good" || isInterrupted))
          ? "block"
          : "none",
    },
    "&:before": {
      pointerEvents: "none",
      border: `${BORDER_WIDTH}px solid`,
      borderImage:
        "linear-gradient(234.58deg, rgba(14,125,255,1) 15.15%, rgba(10,255,108,1))",
      borderRadius: "4px",
      borderImageWidth: `${BORDER_WIDTH}px`,
      borderImageSlice: 4,
      borderImageRepeat: "repeat",
      opacity: 0,
      transition: "opacity 0.2s",
      zIndex: zIndexes.activeSpeakerDetectionAnimZIndex,
      content: "''",
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
    },

    "& video": { objectFit: "cover" },

    "& .canvas-ctn": { width: "100%", height: "100%" },
  },
  loading: {
    position: "absolute",
    zIndex: 2,
    display: "flex",
    width: "100%",
    height: "100%",
    alignItems: "center",
    justifyContent: "center",
  },
  muted: ({ showMuteIcon }: CanvasStyles) => ({
    display: showMuteIcon ? "block" : "none",
    color: "white",
    width: "14px !important",
    height: `${MUTE_BUTTON_HEIGHT}px !important`,
    background: "#de4c2b",
    borderRadius: "4px",
    padding: "3px",
    marginRight: "4px",
  }),
  bottomLeftIconsContainer: {
    zIndex: 2,
    position: "absolute",
    bottom: "5px",
    left: "5px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  muteGuestButton: {
    position: "absolute",
    bottom: "5px",
    right: "5px",
    height: "30px",
    zIndex: 2,
    fontSize: "12px",
    lineHeight: "16px",
    opacity: "0",
    transform: "translate(-2px, 5px)",
    transition: "0.3s all ease-out",
  },
  networkQuality: ({ networkQuality, isInterrupted }: CanvasStyles) => ({
    display: isInterrupted || networkQuality !== "good" ? "block" : "none",
    height: 20,
  }),
  original: {
    "& video": {
      objectFit: "contain",
    },
  },
  isTalking: {
    "&:before": {
      opacity: 1,
    },
  },
}));
