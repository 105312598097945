import { ReactNode, createContext, memo, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "store/hooks";
import { push } from "connected-react-router";
import useNotificationActions from "modules/notification/hooks/useNotificationActions";
import { useI18n } from "i18n";
import getWhitelabelledLogo from "modules/company/utils/getWhitelabelledLogo";
import { ICompany, ICompanyServicePlan } from "modules/company/types";
import { IEventMember } from "modules/eventMemberList/types";
import { setAppLogo } from "modules/appSetting";
import { setHasTickets } from "modules/ticketing/redux/slice";
import { selectUser } from "modules/auth/redux/selectors";
import { getEventTickets } from "modules/eventForm/ticketing/apis";
import logger from "logging/logger";
import PerformanceLogger from "helpers/performanceHelper";
import {
  attendEvent,
  getEventByCode,
  getEventMemberStatus,
} from "services/apiService/apis";
import useCompany from "../../company/hooks/useCompany";
import useRegisterToEvent from "../hooks/useRegisterToEvent";
import { useCheckEventStatus } from "../hooks/useCheckEventStatus";
import { selectIsEventManager } from "../selectors";
import {
  setCurrentEventCompany,
  setCurrentEventCompanyPlan,
} from "../eventSlice";
import { IEvent } from "../types";

export interface IEventContextProps {
  eventCompany?: ICompany | null;
  setEventCompany: (company: ICompany) => void;
  eventCompanyPlan: ICompanyServicePlan | null;
  setEventCompanyPlan: (companyPlan: ICompanyServicePlan) => void;
  eventMember: IEventMember | null;
  setEventMember: (eventMember: IEventMember | null) => void;
  isEventDataLoading: boolean;
  event: IEvent | null;
}

export const EVENT_CONTEXT = createContext<IEventContextProps>({
  eventCompany: undefined,
  setEventCompany: () => null,
  eventCompanyPlan: null,
  setEventCompanyPlan: () => null,
  eventMember: null,
  setEventMember: () => null,
  isEventDataLoading: false,
  event: null,
});

interface IEventContextProvidedProps {
  eventCode?: string;
  children: ReactNode;
}
const EventContextProvider = memo<IEventContextProvidedProps>(
  ({ eventCode, children }) => {
    const [eventCompany, setEventCompany] = useState<ICompany | null>(null);
    const [eventCompanyPlan, setEventCompanyPlan] =
      useState<ICompanyServicePlan | null>(null);
    const [eventMember, setEventMember] = useState<IEventMember | null>(null);
    const [event, setEvent] = useState<IEvent | null>(null);
    const [isLoading, setIsLoading] = useState(true);
    const user = useSelector(selectUser);
    const { company, getCompanyPlanData } = useCompany();
    const dispatch = useAppDispatch();
    const { addErrorNotification } = useNotificationActions();
    const hasEventManagerAccess = useSelector(selectIsEventManager);
    const { registerToEvent } = useRegisterToEvent();
    const { t } = useI18n(["common", "event", "server"]);
    const { checkIsAllowedToJoinEvent } = useCheckEventStatus();

    useEffect(
      () => () => {
        if (!company) {
          dispatch(setAppLogo(null));

          return;
        }
        const whitelabelLogo = getWhitelabelledLogo(company);

        if (whitelabelLogo) {
          dispatch(setAppLogo(whitelabelLogo));
        } else {
          dispatch(setAppLogo(null));
        }
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [],
    );

    useEffect(() => {
      if (event && hasEventManagerAccess && !eventMember) {
        logger.info(`[EVContext] admin user registering to event ${event?.id}`);
        registerToEvent(event?.id);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [event, hasEventManagerAccess, eventMember]);

    // Get event details
    // Get company plan
    // Get event member
    const loadEventData = async () => {
      setIsLoading(true);

      if (!eventCode) {
        setIsLoading(false);

        return;
      }
      PerformanceLogger.start("GetEventByCode");
      const data = await getEventByCode(eventCode);

      PerformanceLogger.end("GetEventByCode");

      if (data && data.event) {
        setEvent(data.event);

        if (data.event.eventcubeEventId) {
          const result = await getEventTickets(data.event.id);

          if (result.message) {
            logger.error(
              `[Ticketing][EventViewContext] Error getting tickets: ${result.message}`,
            );

            dispatch(setHasTickets(false));
          } else {
            dispatch(setHasTickets(result.tickets.length > 0));
          }
        }

        if (data.event.company && typeof data.event.company !== "string") {
          dispatch(setAppLogo(getWhitelabelledLogo(data.event.company)));
          dispatch(setCurrentEventCompany(data.event.company));
          setEventCompany(data.event.company);
        }

        if (!user && typeof data.event.company !== "string") {
          const plan = await getCompanyPlanData(data.event.company);
          setEventCompanyPlan(plan);
          dispatch(setCurrentEventCompanyPlan(plan));
        }

        if (user && typeof data.event.company !== "string") {
          PerformanceLogger.start("GetEventOther");
          // eslint-disable-next-line promise/catch-or-return
          Promise.all([
            getCompanyPlanData(data.event.company),
            getEventMemberStatus(data.event.id),

            // eslint-disable-next-line promise/prefer-await-to-then
          ]).then(([plan, { data: member }]) => {
            if (!data || !data.event) {
              return;
            }

            setEventCompanyPlan(plan);
            dispatch(setCurrentEventCompanyPlan(plan));

            // eslint-disable-next-line promise/always-return
            if (
              member &&
              member.status !== "attended" &&
              checkIsAllowedToJoinEvent(data.event)
            ) {
              logger.info(`[EVContext] user attending event ${data.event.id}`);
              attendEvent(data.event.id)
                // eslint-disable-next-line promise/prefer-await-to-then
                .then(({ member: updatedMember, message = "" }) => {
                  // eslint-disable-next-line promise/always-return
                  if (updatedMember) {
                    setEventMember(updatedMember);
                  } else {
                    logger.error(
                      `[EVContext] user not able to attend event ${data?.event?.id} message: ${message}`,
                    );
                    addErrorNotification({
                      message: t(message) || t("try.again.later"),
                    });
                    dispatch(push(`/e/${data?.event?.code}/register`));
                  }
                })
                // eslint-disable-next-line promise/prefer-await-to-then
                .catch((err) => {
                  logger.error(
                    `[EVContext] user not able to attend event ${data?.event?.id}`,
                    err,
                  );
                  addErrorNotification({
                    message: err ? t(err.message) : t("try.again.later"),
                  });
                  dispatch(push(`/e/${data?.event?.code}/register`));
                });
            }
            PerformanceLogger.end("GetEventOther");
          });
        }
      }
      setIsLoading(false);
    };

    useEffect(() => {
      if (eventCode && !event) {
        loadEventData();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [eventCode]);

    useEffect(
      () => () => {
        dispatch(setCurrentEventCompany(null));
        dispatch(setCurrentEventCompanyPlan(null));
      },
      [dispatch],
    );

    return (
      <EVENT_CONTEXT.Provider
        // eslint-disable-next-line react/jsx-no-constructed-context-values
        value={{
          event,
          isEventDataLoading: isLoading,
          eventCompany,
          setEventCompany,
          eventCompanyPlan,
          setEventCompanyPlan,
          eventMember,
          setEventMember,
        }}
      >
        {children}
      </EVENT_CONTEXT.Provider>
    );
  },
);

export default EventContextProvider;
