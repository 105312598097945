import { IconButtonWithTooltip } from "@remo-co/ui-core/src/components/IconButtonWithTooltip";
import { useI18n } from "i18n";
import { selectIsBroadcasting } from "modules/broadcast/redux/selectors";
import SendIcon from "modules/eventButtons/icons/SendIcon";
import { useSelector } from "react-redux";
import useStyles from "./styles";

export const SendChatButton = ({
  type,
  onSend,
}: {
  type: string;
  onSend?: () => void;
}) => {
  const isBroadcasting = useSelector(selectIsBroadcasting);
  const styles = useStyles({ isBroadcasting: Boolean(isBroadcasting) });
  const { t } = useI18n();
  return (
    <div className={styles.button}>
      <IconButtonWithTooltip
        id="chat-send"
        title={t("message.send")}
        type={type}
        data-testid="chat-send"
        onClick={onSend}
      >
        <SendIcon className={styles.sendButton} color="primary" />
      </IconButtonWithTooltip>
    </div>
  );
};
