import { Ref, forwardRef, useImperativeHandle, useRef } from "react";
import DOMPurify from "dompurify";
import useSVGWrapperStyles from "./styles";

export interface ISVGWrapperHandle {
  ref: HTMLDivElement | null;
}

interface ISVGWrapperProps {
  localFileUrl: string;
  floorBackgroundColor?: string;
  testId?: string;
  disableSanitize?: boolean;
}

const SVGWrapper = (
  {
    localFileUrl,
    floorBackgroundColor,
    testId = "cfp-svg-wrapper",
    disableSanitize = false,
  }: ISVGWrapperProps,
  ref: Ref<ISVGWrapperHandle> | undefined,
) => {
  const svgRef = useRef(null);
  const styles = useSVGWrapperStyles();
  useImperativeHandle(ref, () => ({
    get ref() {
      return svgRef.current;
    },
  }));
  return (
    <div
      className={styles.svgWrap}
      style={{ backgroundColor: floorBackgroundColor }}
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{
        __html: DOMPurify.sanitize(localFileUrl, {
          SANITIZE_DOM: !disableSanitize,
        }),
      }}
      data-testid={testId}
      ref={svgRef}
    />
  );
};

export default forwardRef<ISVGWrapperHandle, ISVGWrapperProps>(SVGWrapper);
