import { sendPostRequest } from "services/apiService";
import { IEventAgenda } from "./types";

const upsertEventAgenda = (id: string, date: string, agenda: IEventAgenda) =>
  sendPostRequest<{ date: string; agenda: IEventAgenda }, IGenericAPIResponse>(
    `/event-agenda/${id}/upsert`,
    { date, agenda },
  );

export default {
  upsertEventAgenda,
};
