import {
  ComponentProps,
  ReactNode,
  useCallback,
  useEffect,
  useState,
} from "react";
import { Typography } from "../Typography";
import { Box } from "../Box";
import { ChipInput } from "../ChipInput";

const separateInputStrings = (val: string) => {
  const separator = val.includes(",") ? "," : " ";
  return val.split(separator) ?? [];
};

interface Props
  extends Omit<
    ComponentProps<typeof ChipInput>,
    | "onBlur"
    | "error"
    | "value"
    | "onChange"
    | "validate"
    | "data"
    | "onAdd"
    | "onDelete"
  > {
  limit?: number;
  name?: string;
  value: string[];
  onChange?: (inputs: string[]) => void;
  validate?: (someInvalid: boolean) => void;
  placeholder: string;
  showLimit?: boolean;
  invalidInputsMessage: string;
  getRemainingMessage: (remaining: string) => string;
  getExceededMessage: (remaining: string) => string;
  customValidator: (input: string) => boolean;
  inputStartAdornment?: ReactNode;
}

const ValidatedChipInput = ({
  value = [],
  onChange,
  validate,
  placeholder,
  limit = NaN,
  showLimit = true,
  invalidInputsMessage,
  getRemainingMessage,
  getExceededMessage,
  customValidator,
  inputStartAdornment,
  ...rest
}: Props): JSX.Element => {
  const unlimited = Number.isNaN(limit);
  const [inputs, setInputs] = useState<string[]>(value);

  const checkAllInputs = useCallback(
    (allInputs: string[]) => allInputs.some((input) => !customValidator(input)),
    [customValidator],
  );

  const updateValue = useCallback(
    (updatedInputs: string[]) => {
      setInputs(updatedInputs);
      const limitExceeded = !unlimited && updatedInputs.length > limit;
      if (!limitExceeded) {
        onChange?.(updatedInputs);
        validate?.(checkAllInputs(updatedInputs));
      }
    },
    [unlimited, limit, onChange, validate, checkAllInputs],
  );

  const addInputs = (values: string[]) => {
    const cleanedValues = values
      .map((val) => val.trim().toLocaleLowerCase())
      .filter(Boolean);
    updateValue([...new Set([...inputs, ...cleanedValues])]);
  };

  const handleBlur = (input: string) => {
    if (input) {
      addInputs(input?.split(","));
    }
  };

  const handleAddChip = (input: string) => {
    addInputs(separateInputStrings(input));
  };

  const handleDeleteChip = (deletedInput: string) => {
    updateValue(inputs.filter((input) => deletedInput !== input));
  };

  useEffect(() => {
    if (Array.isArray(value)) {
      updateValue(value);
    } else if (value) {
      setInputs(separateInputStrings(value));
    }
  }, [value, updateValue]);

  const remaining = limit - (inputs?.length ?? 0);
  const description =
    showLimit && remaining > 0 ? getRemainingMessage(`${remaining}`) : "";

  const errors = [];
  if (!unlimited && remaining < 0) {
    errors.push(getExceededMessage(`${Math.abs(remaining)}`));
  }
  if (checkAllInputs(inputs)) {
    errors.push(invalidInputsMessage);
  }

  const error =
    errors.length > 0 ? (
      <>
        {errors.map((err) => (
          <Typography display="block" color="error" component="span" key={err}>
            {err}
          </Typography>
        ))}
      </>
    ) : undefined;

  return (
    <Box width="100%" marginBottom={0.5}>
      <ChipInput
        {...rest}
        fullWidth
        data={inputs}
        validate={customValidator}
        onAdd={(chip) => handleAddChip(chip)}
        onDelete={handleDeleteChip}
        onBlur={handleBlur}
        placeholder={placeholder}
        description={description}
        error={error}
        inputStartAdornment={inputStartAdornment}
      />
    </Box>
  );
};

export default ValidatedChipInput;
