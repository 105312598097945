import React from "react";
import { v4 } from "uuid";
import { selectUser } from "modules/auth/redux/selectors";
import { selectCurrentEventId } from "modules/event/selectors";
import { useSelector } from "react-redux";
import {
  cleanAppInstanceUuid,
  onAppInstanceUuidChange,
  updateAppInstanceUuid,
} from "./ensureSingleAppInstance.firebase";

const APP_INSTANCE_UUID: string = v4();

// eslint-disable-next-line import/no-mutable-exports
export let isMasterAppInstance = true;
let lastUuidFromFirestore: string | undefined;

export const useIsMasterAppInstance = (): boolean => {
  const [isMasterAppInstanceLocal, setIsMasterAppInstance] =
    React.useState<boolean>(isMasterAppInstance);
  const user = useSelector(selectUser);
  const eventId = useSelector(selectCurrentEventId);

  React.useEffect(() => {
    if (!user || !eventId) {
      return;
    }

    const unsubscribePromise = updateAppInstanceUuid(
      user.id,
      eventId,
      APP_INSTANCE_UUID,
      // eslint-disable-next-line promise/prefer-await-to-then
    ).then(() =>
      onAppInstanceUuidChange(
        user.id,
        eventId,
        (appInstanceUuid: string | undefined) => {
          if (appInstanceUuid && lastUuidFromFirestore !== appInstanceUuid) {
            lastUuidFromFirestore = appInstanceUuid;
            setIsMasterAppInstance(lastUuidFromFirestore === APP_INSTANCE_UUID);
          }
        },
      ),
    );

    // eslint-disable-next-line consistent-return
    return () => {
      // eslint-disable-next-line promise/catch-or-return, promise/prefer-await-to-then
      unsubscribePromise.then((unsubscribe) => unsubscribe());
      cleanAppInstanceUuid(user.id, eventId);
    };
  }, [user, eventId]);

  isMasterAppInstance = isMasterAppInstanceLocal;

  return isMasterAppInstanceLocal;
};
