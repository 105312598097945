export function createNarrowedSlots(
  oldSlots: (null | string)[],
  itemsPerRow: number,
) {
  const prevItemsPerRow = oldSlots.length / 2;
  const slots = [];

  const start = Math.floor((oldSlots.length / 2 - itemsPerRow) / 2);

  // eslint-disable-next-line no-plusplus
  for (let i = start; i < start + itemsPerRow; i++) {
    slots.push(oldSlots[i]);
  }

  for (
    let i = start + prevItemsPerRow;
    i < start + prevItemsPerRow + itemsPerRow;
    // eslint-disable-next-line no-plusplus
    i++
  ) {
    slots.push(oldSlots[i]);
  }

  return slots;
}
