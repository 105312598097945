import { useSelector } from "react-redux";
import { selectIsMobile } from "modules/deviceInfo";
import { Theme } from "@remo-co/ui-core/src/types";
import { useTheme } from "@remo-co/ui-core/src/hooks/useTheme";
import { useMediaQuery } from "@remo-co/ui-core/src/hooks/useMediaQuery";

const getSmallDesktopMediaQuery = (theme: Theme) =>
  theme.breakpoints.down("xl");

/** @deprecated use [theme breakpoints](https://mui.com/material-ui/customization/breakpoints/) instead */
export const useSmallDesktop = () => {
  const isMobileView = useSelector(selectIsMobile);
  const theme = useTheme();
  const isSmallDesktop = useMediaQuery(getSmallDesktopMediaQuery(theme), {
    noSsr: true,
  });

  return isSmallDesktop && !isMobileView;
};
