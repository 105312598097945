import { useMemo } from "react";
import { useSelector } from "react-redux";
import { Container } from "@remo-co/ui-core/src/components/Container";
import { getAssignableTables } from "modules/assignTables/utils";
import { IRoom } from "types";
import { selectCompany } from "modules/company/redux/selectors";
import { Loader } from "@remo-co/ui-core/src/components/Loader";
import { Colors } from "@remo-co/ui-core/theme";
import { TableData } from "../../types";
import {
  AttendeeTableUpdatePayload,
  AttendeeTableMovePayload,
} from "../../utils";
import { TableItem } from "../TableItem";
import useStyles from "./styles";

interface Props {
  selectedFloorId: string;
  tableData: TableData;
  eventId: string;
  hasMultipleFloors: boolean;
  templateTables: IRoom[];
  selectedPreset: string;
  setHoveredTableCode: (tableId: string | null) => void;
  addAttendeeToTable: (payload: AttendeeTableUpdatePayload) => void;
  moveAttendeeToTable: (payload: AttendeeTableMovePayload) => void;
  unassignAttendeesFromTable: (floorId: string, tableId: string) => void;
  isInEvent?: boolean;
  setHoveredUserId?: (id: string | null) => void;
}

const TableGrid = ({
  selectedFloorId,
  tableData,
  eventId,
  hasMultipleFloors,
  templateTables,
  selectedPreset,
  setHoveredTableCode,
  addAttendeeToTable,
  moveAttendeeToTable,
  unassignAttendeesFromTable,
  isInEvent,
  setHoveredUserId,
}: Props): JSX.Element => {
  const styles = useStyles({
    isInEvent,
  });
  const { companyPlan } = useSelector(selectCompany);
  const floorData = tableData[selectedFloorId];

  const sortedTables = useMemo(
    () =>
      floorData
        ? getAssignableTables(
            floorData,
            templateTables,
            companyPlan?.settings.theater.maxInTable,
          )
        : [],
    [floorData, templateTables, companyPlan],
  );

  if (sortedTables.length === 0) {
    return (
      <Container>
        <Loader
          loaderColor={Colors.BLUE_PRIMARY}
          backgroundColor={Colors.WHITE}
        />
      </Container>
    );
  }

  return (
    <Container className={styles.tableGrid}>
      {sortedTables.map((table) => (
        <TableItem
          key={table.code}
          table={table}
          floorId={selectedFloorId}
          eventId={eventId}
          hasMultipleFloors={hasMultipleFloors}
          selectedPreset={selectedPreset}
          setHoveredTableCode={setHoveredTableCode}
          addAttendeeToTable={addAttendeeToTable}
          moveAttendeeToTable={moveAttendeeToTable}
          unassignAttendeesFromTable={unassignAttendeesFromTable}
          setHoveredUserId={setHoveredUserId}
        />
      ))}
    </Container>
  );
};

export default TableGrid;
