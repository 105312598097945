import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectUser } from "modules/auth/redux/selectors";
import { getUserName } from "modules/userProfile";
import { usePrevious } from "helpers/reactHooksUtils";
import { selectHelpChatEvent } from "modules/helpChat/selectors";
import { useNeedHelp } from "../../hooks";

interface Props {
  children: JSX.Element;
}

interface IHelpChatUser {
  [key: string]: string;
}

const HelpChatWrapper = ({ children }: Props) => {
  const [helpChatUser, setHelpChatUser] = useState<IHelpChatUser | null>({});
  const loginUser = useSelector(selectUser);
  const { callApi } = useNeedHelp();
  const helpChatEvent = useSelector(selectHelpChatEvent);
  const prevHelpChatEvent = usePrevious(helpChatEvent);

  useEffect(() => {
    if (!helpChatUser) {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      updateHelpChatUser();
    }

    // Listen for any external event - ex: successful invite sent/copied
    if (
      (!prevHelpChatEvent && helpChatEvent) || // new helpChat event
      (prevHelpChatEvent && prevHelpChatEvent.key) !==
        (helpChatEvent && helpChatEvent.key) // another event
    ) {
      // Update only if this key is not added to helpChat yet
      if (
        helpChatUser &&
        helpChatEvent.key &&
        (!helpChatEvent.skipUpdate || !helpChatUser[helpChatEvent.key])
      ) {
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        updateHelpChat({
          ...helpChatUser,
          [helpChatEvent.key]: helpChatEvent.value,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [helpChatEvent]);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    updateHelpChatUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginUser]);

  const updateHelpChatUser = async () => {
    const user = {
      email: loginUser ? loginUser.email : "",
      name: loginUser ? getUserName(loginUser) : "",
    };

    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    updateHelpChat(user);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const updateHelpChat = (helpChatUserData: any) => {
    if (helpChatUserData) {
      setHelpChatUser(helpChatUserData);
      callApi("update", helpChatUserData);
    }
  };

  return children;
};

export default HelpChatWrapper;
