import { Theme } from "@remo-co/ui-core/src/types";
import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";

interface IPresentationTitleStyles {
  hostView?: boolean;
}

export const useStyles = makeStyles<Theme, IPresentationTitleStyles>(
  (theme) => ({
    presentationDisplayItemText: {
      position: "absolute",
      width: "100%",
      textAlign: "center",
      bottom: "0",
      display: "flex",
      color: theme.palette.white,
      zIndex: 1,
    },
    text: {
      textOverflow: "ellipsis",
      overflow: "hidden",
      whiteSpace: "nowrap",
    },
    displayTitleText: ({ hostView }) => ({
      overflow: "hidden",
      width: "100%",
      display: hostView ? "flex" : "block",
      background: theme.palette.blue.darkShade2,
      height: "27px",
      padding: "0px 10px",
      borderRadius: "3px",
      fontSize: "14px",
      justifyContent: "center",
      justifyItems: "center",
      alignItems: "center",
      maxWidth: "inherit",
    }),
  }),
);
