import { Theme } from "@remo-co/ui-core/src/types";
import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";

export const useStyles = makeStyles<Theme>((theme) => ({
  fullScreenControl: {
    display: "flex",
    height: "20px",
    width: "20px",
    margin: "6px",
  },
  fullScreenButton: {
    color: theme.palette.white,
    height: "100%",
    width: "100%",
    padding: "0",
    backgroundColor: "rgba(0, 0, 0, 0.08)",
    boxShadow: "1px 1px 4px -1px rgba(0, 0, 0, 0.2)",
  },
}));
