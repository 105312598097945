import React from "react";
import { SvgIcon, SvgIconProps } from "@remo-co/ui-core/src/components/SvgIcon";

const SendIcon = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 15.912 15.911">
    <g transform="translate(7.956) rotate(45)">
      <path
        d="M10.933.327A1.087,1.087,0,0,0,9.847.044L.792,2.677a1.08,1.08,0,0,0-.778.856
        A1.268,1.268,0,0,0,.578,4.716l2.831,1.74a.734.734,0,0,0,.906-.109L7.557,3.085
        a.413.413,0,0,1,.6,0,.429.429,0,0,1,0,.6L4.905,6.948a.745.745,0,0,0-.109.911l1.73,2.86
        a1.075,1.075,0,0,0,.934.532,1.169,1.169,0,0,0,.141-.006,1.1,1.1,0,0,0,.917-.781L11.2,1.42a1.105,1.105,0,0,0-.27-1.093"
        transform="translate(0 0)"
      />
    </g>
  </SvgIcon>
);

export default React.memo(SendIcon);
