import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";

export const useStyles = makeStyles((theme) => ({
  root: {
    position: "absolute",
    display: "flex",
    cursor: "pointer",
    background: "transparent",
  },
  preview: {
    position: "relative",
  },
  floorImage: {
    borderRadius: "4px",
    objectFit: "contain",
    width: "100%",
    height: "100%",
  },
  closeButton: {
    position: "absolute",
    top: theme.spacing(2),
    right: theme.spacing(2),
  },
  header: {
    padding: `${theme.spacing(4)} ${theme.spacing(2)} ${theme.spacing(
      3.5,
    )} ${theme.spacing(2)}`,
  },
  title: {
    textAlign: "center",
    overflowWrap: "break-word",
    [theme.breakpoints.down("md")]: {
      fontSize: theme.typography.h4.fontSize,
    },
  },
  content: {
    position: "relative",
    padding: `${theme.spacing(0)} ${theme.spacing(4)} ${theme.spacing(1)}`,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  actions: {
    padding: `${theme.spacing(3)} ${theme.spacing(4)}`,
    justifyContent: "space-between",
    [theme.breakpoints.down("md")]: {
      justifyContent: "center",
      flexWrap: "wrap",
    },
  },
  backToEventButton: {
    // centers this if cta button is not present
    "&:only-child": {
      margin: "0 auto",
    },
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  ctaButton: {
    color: theme.palette.white,
    [theme.breakpoints.down("md")]: {
      fontSize: theme.typography.body1.fontSize,
    },
  },
}));
