import { useCallback, useContext, useRef } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "store/hooks";
import { selectUser } from "modules/auth/redux/selectors";
import { selectCurrentTheaterId } from "modules/theater/selectors";
import { Actions, trackAction } from "modules/monitoring";
import { Events, TRACKING_CONTEXT } from "modules/tracking";
import {
  DatabaseReference,
  DataSnapshot,
  off,
  onValue,
} from "firebase/database";
import {
  closeWhiteboard as actionCloseWhiteboard,
  openWhiteboard as actionOpenWhiteboard,
} from "../../redux/slice";
import broadcastWhiteboardFirebase from "../../whiteboard.firebase";
import logger from "../../../../logging/logger";

const useBroadcastWhiteboardActions = () => {
  const docRef = useRef<DatabaseReference>();
  const dispatch = useAppDispatch();
  const user = useSelector(selectUser);
  const { track } = useContext(TRACKING_CONTEXT);
  const theaterId = useSelector(selectCurrentTheaterId);

  const openWhiteboard = useCallback(async () => {
    if (!theaterId || !user) {
      return;
    }

    dispatch(actionOpenWhiteboard());
    broadcastWhiteboardFirebase
      .openWhiteboard(theaterId, user.id)
      // eslint-disable-next-line promise/prefer-await-to-then
      .catch((error) => {
        logger.error(
          `[BroadcastWhiteboardFirebase][openWhiteboard] ${error.message}`,
        );
      });
    track(Events.WHITE_BOARD_START);
    trackAction(Actions.WHITEBOARD_OPEN, {
      theaterId,
      user,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [theaterId, user]);

  const closeWhiteboard = useCallback(() => {
    if (!theaterId) {
      return;
    }

    dispatch(actionCloseWhiteboard());
    trackAction(Actions.WHITEBOARD_CLOSE, {
      theaterId,
      user,
    });

    // eslint-disable-next-line promise/prefer-await-to-then
    broadcastWhiteboardFirebase.closeWhiteboard(theaterId).catch((error) => {
      logger.error(
        `[BroadcastWhiteboardFirebase][closeWhiteboard] ${error.message}`,
      );
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [theaterId]);

  const subscribe = useCallback(() => {
    if (!theaterId) {
      return;
    }

    docRef.current =
      broadcastWhiteboardFirebase.getBroadcastWhiteboardDocRef(theaterId);
    off(docRef.current);
    onValue(docRef.current, (snapshot: DataSnapshot) => {
      const val = snapshot.val();

      if (val) {
        const { isOpen } = val;

        if (isOpen) {
          dispatch(actionOpenWhiteboard());
        } else {
          dispatch(actionCloseWhiteboard());
        }
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [theaterId]);

  const unsubscribe = () => {
    if (docRef.current) {
      off(docRef.current);
    }
  };

  return {
    openWhiteboard,
    closeWhiteboard,
    subscribe,
    unsubscribe,
  };
};

export default useBroadcastWhiteboardActions;
