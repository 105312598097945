import { DatabaseReference, ref } from "firebase/database";
import { database } from "services/firebaseService/firebaseConfig";

const refMap = new Map<string, DatabaseReference>();

export const generateDocRef =
  <T extends unknown[]>(getRefHash: (...args: T) => string) =>
  (...args: T) => {
    const refHash = getRefHash(...args);

    let dbRef = refMap.get(refHash);

    if (!dbRef) {
      dbRef = ref(database, refHash);
      refMap.set(refHash, dbRef);
    }

    return dbRef;
  };
