import { useContext, useEffect } from "react";
import { TRACKING_CONTEXT } from "modules/tracking";
import { PAGES } from "modules/tracking/segment/constants";
import { traitType } from "../../segment/types";

export interface PageTrackingParams {
  manual?: boolean;
  metadata?: traitType;
}

export const usePageTracking = (
  pageName: PAGES,
  params?: PageTrackingParams,
) => {
  const { page } = useContext(TRACKING_CONTEXT);

  useEffect(() => {
    if (!params?.manual) {
      page(pageName, { ...params?.metadata });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const trackPage = (metadata?: traitType) => {
    page(pageName, { ...params?.metadata, ...metadata });
  };

  return {
    trackPage,
  };
};
