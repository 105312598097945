import { BroadcasterState } from "../types";
import ActionTypes from "./constants";

const initialState: BroadcasterState = {
  broadcasters: {},
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const reducer = (state = initialState, action: any): BroadcasterState => {
  const { payload } = action;

  switch (action.type) {
    case ActionTypes.UPDATE_BROADCASTERS:
      return {
        ...state,
        broadcasters: payload.broadcasters,
      };

    case ActionTypes.RESET:
      return initialState;
    default:
      return state;
  }
};

export default reducer;
