import { Theme } from "@remo-co/ui-core/src/types";
import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";

const useStyles = makeStyles<Theme, { isInBroadcast?: boolean }>((theme) => ({
  row: {
    display: "flex",
    marginBottom: 0,
    padding: "0 1rem",
    maxWidth: "100%",
    alignItems: "center",
    justifyContent: "flex-start",
    cursor: "pointer",
    height: "57px",
  },
  avatar: {
    display: "flex",
    justifyContent: "center",
  },
  textContainer: {
    display: "flex",
    flex: 1,
    flexFlow: "column",
    maxWidth: "100%",
    minWidth: 0,
    fontSize: "0.9rem",
  },
  text: {
    fontWeight: 700,
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    position: "relative",
    overflow: "hidden",
    color: ({ isInBroadcast }) =>
      isInBroadcast ? theme.palette.white : theme.palette.text.primary,
    height: "20px",
  },
  message: {
    fontWeight: "normal",
    paddingRight: "25px",
    paddingBottom: "1px",
    color: `${theme.palette.text.primary} !important`,
    opacity: 0.9,
  },
  countBadge: {
    position: "absolute",
    top: "8px",
    right: "14px",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    borderRadius: "50%",
    width: "18px",
    height: "18px",
    background: "#ff4646",
    color: "white",
    textAlign: "center",
    margin: "auto",
    fontSize: "0.7rem",
    fontStyle: "normal",
    zIndex: 1,
  },
  moreBadge: {
    borderRadius: "10px",
    width: "25px",
  },
}));

export default useStyles;
