import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";
import zIndexes from "zIndexes.module.scss";

const useStyles = makeStyles(() => ({
  button: {
    marginLeft: 10,
  },
  tooltip: {
    zIndex: zIndexes.modalZIndex + 1,
  },
}));

export default useStyles;
