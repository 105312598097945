import { useEffect, useMemo, useRef, RefObject } from "react";
import { Container } from "@remo-co/ui-core/src/components/Container";
import { closeFullScreenEventContent } from "modules/eventContent";
import { closeRoomWhiteboard } from "modules/roomWhiteboard/redux/slice";
import { TranscriptionFeed } from "modules/transcription";
import { TranscriptionFeed as TranscriptionFeedV2 } from "modules/transcriptionV2";
import { useAppDispatch } from "store/hooks";
import { useSelector } from "react-redux";
import {
  selectActiveTile,
  selectLayout,
} from "modules/audioVideo/redux/selectors";
import { SpeakerJoinSoonText } from "modules/broadcastVideoView/SpeakerJoinSoonText";
import { selectIsInBroadcast } from "modules/broadcast/redux/selectors";
import { LocateMeInitially } from "modules/conversation/components/LocateMeInitially";
import useEventSplitTreatment from "modules/split/useEventSplitTreatment";
import { EventFeatures } from "services/splitService/features";
import { useStyles } from "./styles";
import {
  LayoutProps,
  TileDraft,
  DisplayItem,
  DisplayLayout,
  DisplayItemConfig,
} from "./types";
import { RibbonLayout } from "./Ribbon";
import { GridLayout } from "./Grid";
import { FocusLayout } from "./Focus";
import { AudioGroup } from "../../Tile/Audio/AudioGroup";

interface Props {
  layout: DisplayLayout;
  tiles?: TileDraft[];
  onTileClick?: (streamId: string) => void;
  switchLayout?: (layout: DisplayLayout) => void;
  containerRef: RefObject<HTMLDivElement>;
}

const PaginationLayoutSwitcher = ({
  layout,
  tiles = [],
  onTileClick,
  containerRef,
  switchLayout,
}: Props) => {
  switch (layout) {
    case DisplayLayout.Map: {
      return (
        <RibbonLayout
          tiles={tiles}
          onTileClick={onTileClick}
          switchLayout={switchLayout}
        />
      );
    }
    case DisplayLayout.Tile: {
      return (
        <GridLayout
          tiles={tiles}
          onTileClick={onTileClick}
          switchLayout={switchLayout}
        />
      );
    }
    case DisplayLayout.Focus: {
      return (
        <FocusLayout
          tiles={tiles}
          onTileClick={onTileClick}
          switchLayout={switchLayout}
          containerRef={containerRef}
        />
      );
    }
    default: {
      throw new Error("no such layout.");
    }
  }
};

export const LayoutContainer = ({
  tiles = [],
  onTileClick,
  switchLayout,
}: LayoutProps) => {
  const { featureEnabled: transcriptionV2Enabled } = useEventSplitTreatment(
    EventFeatures.TRANSCRIPTION_V2,
  );
  const styles = useStyles();
  const containerRef = useRef<HTMLDivElement>(null);
  const isInBroadcast = useSelector(selectIsInBroadcast);
  const layout = useSelector(selectLayout);
  const activeTile = useSelector(selectActiveTile);

  const dispatch = useAppDispatch();

  useEffect(() => {
    const checkTileDefocused = (uid: string) =>
      (activeTile?.streamId !== uid || layout !== DisplayLayout.Focus) &&
      tiles.some(({ streamId }) => streamId === uid);

    if (checkTileDefocused(DisplayItemConfig.whiteboardDisplayId)) {
      dispatch(closeRoomWhiteboard());
    }

    if (checkTileDefocused(DisplayItemConfig.eventContentDisplayId)) {
      dispatch(closeFullScreenEventContent());
    }
  }, [dispatch, tiles, layout, activeTile]);

  const activeLayout = useMemo(() => {
    if (layout === DisplayLayout.Focus) {
      if (
        activeTile !== null &&
        tiles.length > 1 &&
        tiles.some(({ streamId }) => streamId === activeTile.streamId)
      ) {
        return DisplayLayout.Focus;
      }

      return DisplayLayout.Tile;
    }

    return layout;
  }, [activeTile, layout, tiles]);

  const subscribedStreamIds = useMemo(
    () =>
      tiles
        .filter(
          (tileDraft) =>
            tileDraft.type === DisplayItem.camera && !tileDraft.local,
        )
        .map(({ streamId }) => streamId),
    [tiles],
  );

  if (isInBroadcast && tiles.length === 0) {
    return <SpeakerJoinSoonText />;
  }

  return (
    <Container
      ref={containerRef}
      className={styles.container}
      data-testid="layout-container"
      id="layout-container"
    >
      <LocateMeInitially />
      <PaginationLayoutSwitcher
        layout={activeLayout}
        tiles={tiles}
        onTileClick={onTileClick}
        containerRef={containerRef}
        switchLayout={switchLayout}
      />
      <AudioGroup sessionIds={subscribedStreamIds} />
      {transcriptionV2Enabled ? <TranscriptionFeedV2 /> : <TranscriptionFeed />}
    </Container>
  );
};
