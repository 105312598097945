import { filter, map } from "lodash";
import moment from "moment-timezone";
import i18n from "i18next";
import { IExportAttendeesColumnsMeta } from "modules/eventForm/types";
import { IEvent } from "modules/event/types";
import { getUserName } from "modules/userProfile";
import { ICustomRegistrationAnswer } from "modules/registration/types";
import { IEventMemberDataResults } from "./types";

const convertEventMemberData = (
  event: Pick<IEvent, "name">,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  records: any[],
): IEventMemberDataResults[] =>
  map(
    records,
    ({
      _id: id,
      invite,
      user,
      sessionData,
      createdAt,
      isBlocked,
      status,
      role,
      answers,
    }) => {
      const formatTime = (time: Date) =>
        moment(time).format("YYYY-MM-DD h:mm:ss a");
      const enteredEventAt = sessionData ? sessionData.enteredEventAt : null;
      const leftEventAt = sessionData ? sessionData.leftEventAt : null;
      const timeSpent = sessionData ? sessionData.timeSpent : null;
      const invited = !!invite;
      const addedvia = invite && invite.addedvia;
      const registered = status === "registered" || status === "attended";
      const attended = status === "attended";
      let email;
      let invitedText;

      if (invite) {
        email = invite.email;
        invitedText = registered
          ? i18n.t("confirmed.attendance")
          : i18n.t("yes");
      } else {
        email = user ? user.email : i18n.t("manageEvent:no.event");

        if (registered) {
          invitedText = i18n.t("manageEvent:joined.public");
        } else {
          invitedText = addedvia ? `Added via ${addedvia}` : i18n.t("no");
        }
      }

      let registeredAnswers = {};
      if (answers) {
        registeredAnswers = (answers as ICustomRegistrationAnswer[]).reduce(
          (record, answer) => ({
            ...record,
            [`question-${answer.question}`]: answer.answer,
          }),
          {},
        );
      }

      return {
        isBlocked,
        eventName: event.name,
        userId: user ? user.id : false,
        attendeeId: id,
        inviteId: invite ? invite._id : "",
        name: user && user.profile ? getUserName(user) : "",
        email,
        emailStatus:
          invite && invite.emailStatus ? invite.emailStatus.status : null,
        invited,
        invitedText,
        registered,
        registeredText: registered ? "yes" : "no",
        attended,
        attendedText: attended ? "yes" : "no",
        enteredEventAt: enteredEventAt ? formatTime(enteredEventAt) : "",
        leftEventAt: leftEventAt ? formatTime(leftEventAt) : "",
        registeredAt: createdAt ? formatTime(createdAt) : "",
        timeSpent: timeSpent || "",
        user,
        role,
        ...registeredAnswers,
      };
    },
  );

const convertArrayOfObjectsToCSV = (
  columns: IExportAttendeesColumnsMeta[],
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any,
) => {
  const exportableColumns = filter(columns, (column) => column.export);
  const keys = map(exportableColumns, (column) => column.field);
  const titles = map(exportableColumns, (column) => `"${column.title}"`);
  const render = map(exportableColumns, (column) => column.renderExport);
  const columnDelimiter = ",";
  const lineDelimiter = "\n";
  const header = titles.join(columnDelimiter);
  const lines = map(data, (row) =>
    map(keys, (key, index) => {
      const renderFunction = render[index];
      if (renderFunction) {
        return renderFunction({ key: `"${row[key] || ""}"` });
      }
      return `"${row[key] || ""}"`;
    }).join(columnDelimiter),
  );
  const result = [header, ...lines].join(lineDelimiter);

  return result;
};

const downloadCSV = (
  filename: string,
  array: IExportAttendeesColumnsMeta[],
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any,
) => {
  const link = document.createElement("a");
  const csv = convertArrayOfObjectsToCSV(array, data);

  if (csv === null) {
    return;
  }

  link.setAttribute(
    "href",
    `data:text/csv;charset=utf-8,%EF%BB%BF${encodeURIComponent(csv)}`,
  );
  link.setAttribute("download", filename);
  link.click();
};

export default {
  convertEventMemberData,
  downloadCSV,
  convertArrayOfObjectsToCSV,
};
