import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "store";
import { selectUser } from "../../auth/redux/selectors";

const selectUserPreferences = (state: RootState) => state.userPreferences;

const selectCurrentUserPreferences = createSelector(
  selectUserPreferences,
  selectUser,
  (substate, user) => (user ? substate.userPreferences.get(user.id) : null),
);

const selectAspectRatioPreference = createSelector(
  selectCurrentUserPreferences,
  (preferences) => preferences?.cameraSettings?.originalAspectRatio ?? false,
);

export { selectCurrentUserPreferences, selectAspectRatioPreference };
