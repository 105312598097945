import { Theme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import { statusVariant } from "./StatusIcon";

export const useStyles = makeStyles<Theme, { variant: statusVariant }>(
  (theme) => ({
    status: ({ variant }) => ({
      color: theme.palette.status[variant],
      marginRight: "10px",
    }),
  }),
);
