import { isEqual } from "lodash";

export const isFormDirty = <I extends object, C extends object>(
  initialVal: I | undefined | null,
  currentValue: C | undefined | null,
) => {
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  const currentHasValue = hasValue(currentValue);
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  const initialHasValue = hasValue(initialVal);

  // If both has no value, then form is not dirty
  if (!currentHasValue && !initialHasValue) {
    return false;
  }

  // If anyone has value and other has no value, then form is dirty
  if (currentHasValue !== initialHasValue) {
    return true;
  }

  return !isEqual(initialVal, currentValue);
};

const hasValue = <T>(data: T) => {
  if (!data) {
    return false;
  }

  // eslint-disable-next-line no-restricted-syntax
  for (const key in data) {
    if (Object.prototype.hasOwnProperty.call(data, key) && data[key] != null) {
      return true;
    }
  }

  return false;
};

type IValidator = (value: string) => string | undefined;

export const composeValidators =
  (...validators: IValidator[]) =>
  (value: string) =>
    validators.reduce(
      (error: string | undefined, validator) => error || validator(value),
      undefined,
    );
