import { Button } from "@remo-co/ui-core/src/components/Button";
import { Dialog } from "@remo-co/ui-core/src/components/Dialog";
import { DialogActions } from "@remo-co/ui-core/src/components/DialogActions";
import { Paper } from "@remo-co/ui-core/src/components/Paper";
import { useI18n } from "i18n";
import { getMapImageWithTheme } from "modules/theater/theater.helper";
import { IMapTemplate } from "types/theater";
import { Close as CloseIcon } from "@remo-co/ui-core/src/icons/Close";
import { useStyles } from "./styles";

export interface ILayoutPreviewProps {
  template: IMapTemplate;
  isHidden?: boolean;
  theme: string;
  onSelect?: (template: IMapTemplate, theme?: string) => void;
  onClose: () => void;
}

const LayoutPreview = ({
  template,
  theme,
  isHidden,
  onSelect,
  onClose,
}: ILayoutPreviewProps) => {
  const { t } = useI18n();
  const imageUrl = getMapImageWithTheme(template, theme);
  const layoutName = theme ? `${theme} ${template.name}` : template.name;
  const styles = useStyles();

  return (
    <Dialog open onClose={onClose} className="ctl-preview">
      <Paper className={styles.previewPaper}>
        <img src={imageUrl} alt={layoutName} data-testid="preview-image" />
      </Paper>
      <DialogActions className="ctl-actions">
        {!isHidden && (
          <>
            <Button
              size="lg"
              onClick={onClose}
              color="blue"
              variant="ghost"
              className={isHidden ? "close-btn" : ""}
            >
              {t("button.cancel")}
            </Button>
            <Button
              size="lg"
              onClick={() => onSelect && onSelect(template, theme)}
              variant="primary"
              color="blue"
            >
              {t("button.select")}
            </Button>
          </>
        )}
        {isHidden && (
          <Button
            onClick={onClose}
            className="close-btn"
            data-testid="close-preview"
            isIconButton
            color="gray"
            variant="ghost"
          >
            <CloseIcon />
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default LayoutPreview;
