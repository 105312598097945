import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface UserSessionInteractionsState {
  isSafariAutoplayResumed: boolean;
  microphoneActive: boolean | null; // This is only set when user presses mic button, not if mic/cam permissions were revoked
  cameraActive: boolean | null; // This is only set when user presses cam button, not if mic/cam permissions were revoked
}

const initialState: UserSessionInteractionsState = {
  isSafariAutoplayResumed: false,
  microphoneActive: null,
  cameraActive: null,
};

const slice = createSlice({
  name: "userSessionInteractions",
  initialState,
  reducers: {
    setIsSafariAutoplayResumed: (state, action: PayloadAction<boolean>) => {
      state.isSafariAutoplayResumed = action.payload;
    },
    setMicrophoneActive: (state, action: PayloadAction<boolean>) => {
      state.microphoneActive = action.payload;
    },
    setCameraActive: (state, action: PayloadAction<boolean>) => {
      state.cameraActive = action.payload;
    },
    resetUserSessionInteractions: () => initialState,
  },
});

export const {
  setIsSafariAutoplayResumed,
  setMicrophoneActive,
  setCameraActive,
  resetUserSessionInteractions,
} = slice.actions;

export default slice.reducer;
