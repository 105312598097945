import { startCase } from "lodash";
import { CheckCircle } from "@remo-co/ui-core/src/icons/CheckCircle";
import { Warning } from "@remo-co/ui-core/src/icons/Warning";
import { Typography } from "@remo-co/ui-core/src/components/Typography";
import { useI18n } from "i18n";
import { IRoom } from "types";
import { useStyles } from "./styles";

interface Props {
  rooms: IRoom[];
  overlappingRooms: string[];
  overcapacityRooms: string[];
}

const TableDetails = ({
  rooms,
  overlappingRooms,
  overcapacityRooms,
}: Props) => {
  const { t } = useI18n(["common", "customFloorPlan"]);
  const styles = useStyles();

  const filteredTables = rooms?.filter(({ order }) => Boolean(order));

  if (!filteredTables?.length) return null;

  return (
    <ul className={styles.detailsList} data-testid="cfp-results-table-details">
      {filteredTables.map(({ code, name, positions }) => (
        <li className={styles.listItem} key={code}>
          {overlappingRooms.some((roomName) => startCase(roomName) === name) ||
          overcapacityRooms.some((roomName) => roomName === name) ? (
            <Warning className={styles.warning} data-testid="warning" />
          ) : (
            <CheckCircle className={styles.success} data-testid="success" />
          )}
          {t("customFloorPlan:added.table", {
            name,
            seats: positions.length,
          })}
        </li>
      ))}
    </ul>
  );
};

export const TableDetailsButton = ({
  handleClick,
  showTableDetails,
}: {
  handleClick: () => void;
  showTableDetails: boolean;
}) => {
  const { t } = useI18n(["common", "customFloorPlan"]);
  const styles = useStyles();

  return (
    <button type="button" className={styles.detailButton} onClick={handleClick}>
      <Typography variant="h6" className={styles.title}>
        {showTableDetails
          ? t("customFloorPlan:show.less")
          : t("customFloorPlan:show.details")}
      </Typography>
    </button>
  );
};

export default TableDetails;
