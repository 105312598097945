import { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { selectIsEventManager } from "modules/event/selectors";
import { useRecordingAsync } from "modules/recording";
import {
  DisplayItem,
  DisplayItemConfig,
  DisplayLayout,
} from "modules/audioVideo";
import { useAppDispatch } from "store/hooks";
import {
  selectActiveTile,
  selectLayout,
} from "modules/audioVideo/redux/selectors";
import { setActiveTile, setLayout } from "modules/audioVideo/redux/slice";
import {
  useLocalSessionId,
  useActiveSpeakerId,
  useRecording,
} from "@daily-co/daily-react";
import { useLiveStreamingAsync } from "modules/liveStream/hooks";
import { useAvailableStreamIds } from "./useAvailableStreamIds";
import { Tile } from "./types";

const getStreamIdType = (streamId: string) => {
  if (streamId.endsWith("screen")) return DisplayItem.screenShare;

  if (streamId.includes(DisplayItemConfig.whiteboardDisplayId))
    return DisplayItem.whiteboard;

  if (streamId.includes(DisplayItemConfig.presentContentDisplayId))
    return DisplayItem.presentContent;

  if (streamId.includes(DisplayItemConfig.externalVideoDisplayId))
    return DisplayItem.externalVideo;

  return DisplayItem.camera;
};

// remove when we no longer have a strange dependence
// on redux, context, etc.
// active streams should change the layout within the component that
// specifies the layout.
interface Props {
  focusedTiles: Tile[];
  screens: string[];
  cameras: string[];
  speakers: string[];
}

export const usePresentationLayoutEffects = ({
  focusedTiles,
  screens,
  cameras,
  speakers,
}: Props): void => {
  const dispatch = useAppDispatch();
  const layout = useSelector(selectLayout);
  const activeTile = useSelector(selectActiveTile);
  const { isRecording } = useRecording();
  const { isLiveStreaming, updateStreaming } = useLiveStreamingAsync();
  const localSessionId = useLocalSessionId();
  const speaker = useActiveSpeakerId();
  const hasEventManagerAccess = useSelector(selectIsEventManager);

  const { contentStreamIds, availableStreamIds } = useAvailableStreamIds({
    screens,
    cameras,
  });

  const { updateRecording } = useRecordingAsync();

  // Find the last focused tile with an active stream Id.
  // we've already sorted by primary and last active.
  const lastFocusedTile = useMemo(() => {
    const lastFocusedStreamId = focusedTiles.find(
      (focusedTile) =>
        availableStreamIds.includes(focusedTile.streamId) ||
        focusedTile.streamId === "speaker",
    )?.streamId;

    if (lastFocusedStreamId === "speaker") {
      if (screens.length) {
        return {
          streamId: screens[0],
          type: getStreamIdType(screens[0]),
          local: false,
        };
      }
      if (speaker) {
        return {
          streamId: speaker,
          type: DisplayItem.camera,
          local: speaker === localSessionId,
        };
      }
      return {
        streamId: localSessionId,
        type: DisplayItem.camera,
        local: true,
      };
    }

    return lastFocusedStreamId
      ? {
          streamId: lastFocusedStreamId,
          type: getStreamIdType(lastFocusedStreamId),
          local:
            getStreamIdType(lastFocusedStreamId) === DisplayItem.camera
              ? lastFocusedStreamId === localSessionId
              : false,
        }
      : undefined;
  }, [focusedTiles, localSessionId, availableStreamIds, screens, speaker]);

  useEffect(() => {
    if (!lastFocusedTile) {
      dispatch(setLayout(DisplayLayout.Tile));

      return;
    }

    if (layout === DisplayLayout.Tile) {
      dispatch(setLayout(DisplayLayout.Focus));
    }

    if (lastFocusedTile.streamId !== activeTile?.streamId) {
      dispatch(setActiveTile(lastFocusedTile));
    }
  }, [
    lastFocusedTile,
    hasEventManagerAccess,
    updateRecording,
    layout,
    dispatch,
    activeTile?.streamId,
  ]);

  useEffect(() => {
    if (!hasEventManagerAccess || !isLiveStreaming || speakers.length === 0) {
      return;
    }

    if (
      lastFocusedTile &&
      !contentStreamIds.includes(lastFocusedTile.streamId)
    ) {
      const isScreenShare = lastFocusedTile.type === DisplayItem.screenShare;
      updateStreaming({
        mode: "focus",
        streamIds: speakers,
        preferredParticipant: lastFocusedTile.streamId.replace("-screen", ""),
        preferScreenShare: isScreenShare,
      });
      return;
    }

    updateStreaming({ mode: "grid", streamIds: speakers });
  }, [
    contentStreamIds,
    hasEventManagerAccess,
    isLiveStreaming,
    lastFocusedTile,
    speakers,
    updateStreaming,
  ]);

  useEffect(() => {
    if (!hasEventManagerAccess || !isRecording || speakers.length === 0) {
      return;
    }

    if (
      lastFocusedTile &&
      !contentStreamIds.includes(lastFocusedTile.streamId)
    ) {
      const isScreenShare = lastFocusedTile.type === DisplayItem.screenShare;
      updateRecording({
        mode: "focus",
        streamIds: speakers,
        preferredParticipant: lastFocusedTile.streamId.replace("-screen", ""),
        preferScreenShare: isScreenShare,
      });
      return;
    }

    updateRecording({ mode: "grid", streamIds: speakers });
  }, [
    hasEventManagerAccess,
    updateRecording,
    isRecording,
    lastFocusedTile,
    contentStreamIds,
    speakers,
  ]);
};
