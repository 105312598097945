import { tz } from "moment-timezone";
import { Time } from "./types";

export const format = (time: Time, timezone: string, formatString: string) =>
  tz(time, timezone).format(formatString);

export const toTimezone = (time: Time, timezone: string) =>
  tz(time, timezone).toString();

export const getCurrentTimezone = () => {
  const timezone = tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format(
    "z",
  );

  // For some countries like brazil, moment is not returning timezone. but returns offset. So add GMT before to format time
  return timezone.indexOf("-") === 0 ? `GMT${timezone}` : timezone;
};
