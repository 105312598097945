import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";

const useStyles = makeStyles((theme) => ({
  tickets: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(1.25),
    background: theme.palette.gray.lightShade,
    borderRadius: "10px",
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1.25),
  },
  buttonIcon: {
    fontSize: "14px",
    marginRight: theme.spacing(0.5),
  },
  buttonContent: {
    display: "flex",
    alignItems: "center",
  },
}));

export default useStyles;
