import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";

const useStyles = makeStyles(() => ({
  dialog: {
    "& .MuiDialogContent-root": {
      padding: "28px 24px 0 24px",
    },
  },
}));

export default useStyles;
