import {
  equalTo,
  off,
  onValue,
  orderByChild,
  query,
  ref,
  runTransaction,
  update,
} from "firebase/database";
import store from "store";
import { database } from "../../services/firebaseService/firebaseConfig";
import { addQuestionsDetails } from "./redux/actions";
import { IQuestion } from "./types";

const prefix = "BroadcastQA";

export const askQuestion = (
  eventId: string,
  question: string,
  authorName: string,
) => {
  const id = new Date().getTime();
  const qaRef = ref(database, `${prefix}/${eventId}/questions/${id}`);

  update(qaRef, {
    id,
    question,
    authorName,
    voteCount: 0,
    isAnswered: false,
    voters: {},
  });
};

export const updateVoteForQuestion = (
  eventId: string,
  questionId: number,
  userId: string,
  isDownvote = false,
  // eslint-disable-next-line max-params
) => {
  const qaRef = ref(database, `${prefix}/${eventId}/questions/${questionId}`);

  runTransaction(qaRef, (data) => {
    const question = data as IQuestion;

    if (question && !isDownvote) {
      if (question.voteCount && question.voters[userId]) {
        return question;
      }
      // eslint-disable-next-line no-plusplus
      question.voteCount++;

      if (!data.voters) {
        question.voters = {};
      }
      question.voters[userId] = true;
    } else if (question && isDownvote) {
      if (!question.voters) {
        // eslint-disable-next-line consistent-return
        return;
      }
      question.voters[userId] = false;
      // eslint-disable-next-line no-plusplus
      question.voteCount--;
    }

    return data;
  });
};

export const questionAnswered = (eventId: string, questionId: number) => {
  const qaRef = ref(database, `${prefix}/${eventId}/questions/${questionId}`);

  update(qaRef, { isAnswered: true });
};

export const listQuestions = (eventId: string) => {
  const qaRef = ref(database, `${prefix}/${eventId}/questions`);
  const qaQuery = query(qaRef, orderByChild("isAnswered"), equalTo(false));

  off(qaQuery);

  onValue(qaQuery, (snapshot) => {
    const values = snapshot.val();
    const questions: IQuestion[] = [];

    if (values) {
      Object.keys(values).forEach((key) => questions.push(values[key]));
    }

    questions.sort((a, b) => b.voteCount - a.voteCount);

    store.dispatch(addQuestionsDetails(questions));
  });

  return qaQuery;
};
