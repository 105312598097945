import filter from "lodash/filter";
import { IRoom } from "types";
import { ICompanyServicePlan } from "modules/company/types";
import { IUserRoomStatus } from "modules/room/userRoomStatus";
import { MAX_OVERFLOW } from "modules/event";
import { getReducerState, getCurrentEvent } from "./reduxHelper";

export const getOverflowSeats = (tableSeats: number) =>
  Math.min(Math.floor(tableSeats * 0.5), MAX_OVERFLOW);

export const isRoomFull = (
  room: IRoom | null,
  currentEventPlan: ICompanyServicePlan | null,
) => {
  if (room) {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    const usersInRoom = getUsersInRoom(room.id);
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    let maxAllowed = getNonAdminSeatsCount(room, currentEventPlan);

    const event = getCurrentEvent();

    if (event && !event.isOverflowSeatingDisabled) {
      const overFlowSeats = getOverflowSeats(maxAllowed);

      maxAllowed += overFlowSeats;
    }

    const isFull = maxAllowed <= (usersInRoom ? usersInRoom.length : 0);

    return {
      isFull,
      maxAllowed,
    };
  }

  return {
    isFull: false,
  };
};

export const getNonAdminSeatsCount = (
  room: IRoom,
  currentEventPlan: ICompanyServicePlan | null,
): number => {
  const totalSeatsInRoom: number = room.positions.length;

  return currentEventPlan
    ? Math.min(totalSeatsInRoom, currentEventPlan.settings.theater.maxInTable)
    : totalSeatsInRoom;
};

export const getUsersInRoom = (roomId: string | null): string[] | null => {
  if (roomId) {
    const userRoomStatus = getReducerState("userRoomStatus");
    const users = userRoomStatus[roomId];

    return users ? Object.keys(users) : null;
  }

  return null;
};

export const getUserRoomStatus = (
  roomId: string,
  userId: string,
): IUserRoomStatus | null => {
  if (roomId) {
    const userRoomStatus = getReducerState("userRoomStatus");
    const users = userRoomStatus[roomId];

    return users && users[userId] ? users[userId] : null;
  }

  return null;
};

export const getUsersRoomId = (userId: string): string | null => {
  const userRoomStatus = getReducerState("userRoomStatus");
  const roomId = Object.keys(userRoomStatus).find((userRoomId) => {
    const userIds = Object.keys(userRoomStatus[userRoomId]);

    return userIds.indexOf(userId) > -1;
  });

  return roomId ?? null;
};

export const getTotalUsersInRoom = (roomId: string): number => {
  const usersInRoom = getUsersInRoom(roomId) || [];

  return usersInRoom.length;
};

export const roomHasFreeAdminSeat = (roomId: string) => {
  const userRoomStatus = getReducerState("userRoomStatus");
  const { rooms } = getReducerState("rooms");
  const usersInside = userRoomStatus[roomId];
  const room = rooms[roomId];
  const { adminSeats } = room;

  if (adminSeats) {
    const occupied = filter(usersInside, (o) => o.isAdminSeat);

    if (occupied.length < adminSeats.length) {
      return true;
    }
  }

  return false;
};
