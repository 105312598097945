import { Theme } from "@remo-co/ui-core/src/types";
import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";

export const useStyles = makeStyles<Theme>((theme) => ({
  alertBox: {
    background: theme.palette.yellow.lightShade1,
    margin: "8px 0",
  },
  alertMessage: {
    alignItems: "center",
  },
  infoBtn: {
    color: theme.palette.yellow.dark,
  },
}));
