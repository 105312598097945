import { Transcription } from "@daily-co/daily-react";
import { createSlice } from "@reduxjs/toolkit";

interface InitialState {
  isActive: boolean;
  transcriptions: Transcription[];
}

export const initialState: InitialState = {
  isActive: false,
  transcriptions: [],
};

const transcriptionV2Slice = createSlice({
  name: "transcriptionV2",
  initialState,
  reducers: {
    startTranscribing: (state) => ({
      ...state,
      isActive: true,
    }),
    stopTranscribing: () => ({
      transcriptions: [], // reset transcriptions on store
      isActive: false,
    }),
    setTranscriptions: (state, action) => ({
      ...state,
      transcriptions: action.payload,
    }),
  },
});

export const { startTranscribing, stopTranscribing, setTranscriptions } =
  transcriptionV2Slice.actions;

export default transcriptionV2Slice.reducer;
