import { off, Query } from "firebase/database";
import { useRef } from "react";
import { listQuestions } from "../firebase";

const useQAActions = () => {
  const qaRef = useRef<Query>();

  const subscribe = (eventId: string) => {
    qaRef.current = listQuestions(eventId);
  };
  const unsubscribe = () => {
    if (qaRef.current) {
      off(qaRef.current);
    }
  };

  return {
    subscribe,
    unsubscribe,
  };
};

export default useQAActions;
