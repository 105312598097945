import { gql } from "@apollo/client";

export const GET_EVENT_RATING = gql`
  query GetEventRating($eventId: ObjectID!) {
    ratings(eventId: $eventId) {
      ... on EventRatingAverage {
        rating
      }
      ... on GQLError {
        __typename
        message
      }
    }
  }
`;
