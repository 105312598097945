import { useSelector } from "react-redux";
import {
  selectCurrentEvent,
  selectCurrentEventCompanyLogoUrl,
  selectCurrentEventCompanyWhiteLabelledLogo,
} from "modules/event/selectors";
import useCompany from "modules/company/hooks/useCompany";
import { IEvent } from "modules/event/types";
import {
  selectEnableAgencyWhiteLabelling,
  selectWhiteLabelLogoURL,
} from "modules/settings/redux/selectors";

export enum WhitelabelMode {
  WHITE_LABEL_BRANDING_LOGO = 0, // current event's agency white label logo
  CUSTOM_COMPANY_LOGO = 1, // current user's company's logo
  CUSTOM_EVENT_BRANDING_LOGO = 2, // current event's top bar event branding logo
  CUSTOM_EVENT_COMPANY_LOGO = 3, // current event's company logo
  OFF = 4,
}

// By order of priority, we want to return:
// 1. if in event, and agency white label is enabled + either company or white label logo is present = WHITE_LABEL_BRANDING_LOGO
// 2. if in event, and custom event branding logo is present = CUSTOM_EVENT_BRANDING_LOGO
// 3. if in event, and custom company logo is present = CUSTOM_EVENT_COMPANY_LOGO
// 4. if company logo is present = CUSTOM_COMPANY_LOGO
// 5. by default, return OFF
const useWhitelabelMode = (): [WhitelabelMode, string | undefined] => {
  const { company } = useCompany();
  const currentEvent = useSelector(selectCurrentEvent);
  const enableAgencyWhiteLabelling = useSelector(
    selectEnableAgencyWhiteLabelling,
  );
  const whiteLabelLogoUrl = useSelector(selectWhiteLabelLogoURL);
  const eventCompanyLogoURL = useSelector(selectCurrentEventCompanyLogoUrl);
  const isEventCompanyWhitelabelEnabled = useSelector(
    selectCurrentEventCompanyWhiteLabelledLogo,
  );

  // Some entries in event Firebase have string 'null' value due to a previous bug
  // cleanup work @ GH-4718, remove this function once that's done
  const hasEventBrandingLogo = (event: IEvent) =>
    !!(event.eventBrandingLogoURL && event.eventBrandingLogoURL !== "null");

  if (
    currentEvent &&
    enableAgencyWhiteLabelling &&
    (whiteLabelLogoUrl || eventCompanyLogoURL)
  ) {
    return [
      WhitelabelMode.WHITE_LABEL_BRANDING_LOGO,
      whiteLabelLogoUrl || eventCompanyLogoURL,
    ];
  }

  // replace this with a simpler condition after cleanup
  // e.g. if (currentEvent && currentEvent.eventBrandingLogoURL)
  if (
    currentEvent &&
    isEventCompanyWhitelabelEnabled &&
    hasEventBrandingLogo(currentEvent)
  ) {
    return [
      WhitelabelMode.CUSTOM_EVENT_BRANDING_LOGO,
      currentEvent.eventBrandingLogoURL,
    ];
  }

  if (currentEvent && isEventCompanyWhitelabelEnabled && eventCompanyLogoURL) {
    return [WhitelabelMode.CUSTOM_EVENT_COMPANY_LOGO, eventCompanyLogoURL];
  }

  if (company && company.enabledWhiteLabel && company.logoURL) {
    return [WhitelabelMode.CUSTOM_COMPANY_LOGO, company.logoURL];
  }

  return [WhitelabelMode.OFF, undefined];
};

export default useWhitelabelMode;
