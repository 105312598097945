import { isValidEmailAddress } from "helpers/emailHelper";

export const parseAndReturnEmails = (input: string) => {
  const rows = input.split("\n");

  const filteredRows = rows
    .map((row) => row.replace(/[\r\s,]/g, ""))
    .filter((row) => isValidEmailAddress(row));

  if (filteredRows.length === 0) {
    throw new Error("eventForm:invite.upload.invalid");
  }

  return filteredRows;
};
