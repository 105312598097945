import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";

const headerHeight = 32;

const useStyles = makeStyles((theme) => ({
  header: {
    height: headerHeight,
    backgroundColor: "#444444",
    paddingLeft: theme.spacing(0.75),
  },
  openInNewTabButton: {
    display: "flex",
    alignItems: "center",
    color: "white",
  },
  openInNewTabButtonIcon: {
    fontSize: "16px",
    marginLeft: theme.spacing(0.5),
  },
  iframe: {
    width: "100%",
    height: `calc(100% - ${headerHeight}px)`,
  },
}));

export default useStyles;
