import { ReactNode, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { I18nContext } from "./context";
import { I18nContextType } from "./types";

interface Props {
  language: string;
  noEscape?: boolean;
  resources?: Record<string, Record<string, unknown>>;
  children: ReactNode;
}

const I18nProvider = ({
  language: baseLanguage,
  noEscape = false,
  children,
  resources,
}: Props): JSX.Element => {
  const [language, setLanguage] = useState(baseLanguage);
  const [, setNameSpace] = useState(false);

  const { t, i18n } = useTranslation();

  if (i18n.language !== language) {
    i18n.changeLanguage(language);
  }

  useEffect(() => {
    // resources are only available when rendering Provider in storybook
    if (resources) {
      Object.entries(resources).forEach(([resourceLanguage, namespaces]) => {
        Object.entries(namespaces).forEach(
          ([namespace, namespaceResources]) => {
            i18n.addResources(resourceLanguage, namespace, namespaceResources);
          },
        );
      });
    }
  }, [resources, i18n]);

  useEffect(() => {
    setLanguage(baseLanguage);
  }, [baseLanguage]);

  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const context: I18nContextType = {
    language,
    noEscape,
    ns: i18n.reportNamespaces.getUsedNamespaces(),
    setLanguage,
    addNameSpaces: async (ns) => {
      await i18n.loadNamespaces(ns);
      setNameSpace((v) => !v);
    },
    translate: (message: string) => {
      if (!message) {
        return "";
      }

      return t(message);
    },
  };

  return (
    <I18nContext.Provider value={context}>{children}</I18nContext.Provider>
  );
};

export default I18nProvider;
