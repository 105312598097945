import { compact, map } from "lodash";
import { IUser } from "modules/app/types";
import { RaisedHandUserRecord } from "modules/broadcastRaiseHand/redux/broadcastRaiseHandSlice";
import { getUserName } from "modules/userProfile";
import { IPermissions, IUserWithRaisedHand, UserItem } from "../types";

export const filterUsers = (
  query: string,
  users: IUser[],
  isManager?: boolean,
) =>
  users
    .filter((o) => {
      const queryText = query.trim().toLowerCase();
      const nameText = getUserName(o).toLowerCase().trim();

      return nameText.indexOf(queryText) !== -1;
    })
    .map((user) => ({ ...user, manager: isManager })) as UserItem[];

export const mapUsers = (
  isManager: boolean,
  loginUserId: string | null,
  userIdsToMap: string[],
  userMap: Immutable.Map<string, IUser>,
  permissions: IPermissions = {},
  // eslint-disable-next-line max-params
) =>
  compact(
    map(userIdsToMap, (userId) => {
      const user = userMap.get(userId);

      if (user) {
        // View permissions
        const canUnraiseHand = user.id === loginUserId || isManager;
        const canToggleBroadcastRight =
          user.id !== loginUserId &&
          isManager &&
          !permissions.speaker &&
          !permissions.manager;

        return {
          ...user,
          canUnraiseHand,
          canToggleBroadcastRight,
          ...permissions,
        };
      }

      return null;
    }),
  );

export const sortByRaisedHandFirst = (
  raisedHandUsers: RaisedHandUserRecord,
  users: IUserWithRaisedHand[],
): IUserWithRaisedHand[] =>
  users.sort((user, other_user) => {
    if (raisedHandUsers[user.id].time > raisedHandUsers[other_user.id].time) {
      return 1;
    }
    if (raisedHandUsers[user.id].time < raisedHandUsers[other_user.id].time) {
      return -1;
    }

    return 0;
  });

export function nameSortFunc<T extends IUser>(o: T) {
  return getUserName(o).toLowerCase();
}
