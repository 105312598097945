import { useEffect } from "react";
import { datadogRum } from "@datadog/browser-rum";
import { useSelector } from "react-redux";
import { selectAuth } from "modules/auth/redux/selectors";

export const TrackCompanyRole = () => {
  const { role: companyRole } = useSelector(selectAuth);

  useEffect(() => {
    if (companyRole) {
      datadogRum.setGlobalContextProperty("companyRole", companyRole);
    }
  }, [companyRole]);

  return null;
};
