export enum Actions {
  CAMERA_START_CLICK = "Camera Start Click",
  CAMERA_STOP_CLICK = "Camera Stop Click",
  CHANGE_CAMERA = "Change Camera",
  CHANGE_MICROPHONE = "Change Microphone",
  CONVERSATION_MODE_AUDIENCE_REACTION_CLICKED = "Conversation Mode Audience Reaction Clicked",
  DAILY_MICROPHONE_START_DURATION = "Microphone Start Duration",
  DAILY_TIME_TO_RENDER_STREAM = "Display Item Render",
  DIRECTORY_CREATED = "Directory Created",
  DIRECTORY_DELETED = "Directory Deleted",
  DIRECTORY_EVENT_CLICK = "Directory Event Click",
  DIRECTORY_UPDATED = "Directory Updated",
  EVENT_BRANDING_LOGO_CHANGE_FAILED = "Event Branding Logo Change Failed",
  EVENT_BRANDING_LOGO_CHANGE_SUCCESS = "Event Branding Logo Change Success",
  EVENT_ENTERED = "Event Entered",
  EVENT_REJOIN = "Event Rejoin",
  EVENT_VERSION_SHOW_PROMPT = "Event Version Show Prompt",
  EVENT_VERSION_UPDATE_CANCEL = "Event Version Update Cancel",
  EVENT_VERSION_UPDATE_SUCCESS = "Event Version Update Success",
  FIRESTORE_CONNECTION_ERROR = "Firestore Connection Error",
  GEARTEST_INTERNET_CONNECTION_OK = "Geartest Internet Connection OK",
  GEARTEST_SYSCHECK = "Geartest System Check Duration",
  GET_USER_MEDIA_SUCCESS = "Get User Media Success",
  GHOST_USER = "Ghost User",
  GRAPHQL_RETRY = "Retrying graphql operation",
  INVITE_TO_TABLE = "Invite To Table",
  INVITE_TO_TABLE_FAILURE = "Invite To Table Failure",
  INVITE_TO_TABLE_SUCCESS = "Invite To Table Success",
  JOIN_FLOOR = "Join Floor",
  JOIN_FLOOR_FAILURE = "Join Floor Failure",
  JOIN_FLOOR_SUCCESS = "Join Floor Success",
  JOIN_ROOM = "Join Room",
  JOIN_ROOM_FAILURE = "Join Room Failure",
  JOIN_ROOM_SUCCESS = "Join Room Success",
  MAP_NAVIGATION_USER_LOCATED_SELF = "Map Navigation - Looked up own location",
  MAP_NAVIGATION_ZOOM_IN = "Map Navigation - Zoomed in",
  MAP_NAVIGATION_ZOOM_OUT = "Map Navigation - Zoomed out",
  MEDIA_DEVICE_ENUM_SUCCESS = "Media Device Enumeration Success",
  MICROPHONE_START_CLICK = "Microphone Start Click",
  MICROPHONE_STOP_CLICK = "Microphone Stop Click",
  MOVED_FLOORS = "Moved Floors",
  MOVED_TABLES = "Moved Tables",
  POLLS_CHANGE_STATUS = "Polls: poll status",
  POLLS_CREATE = "Polls: create",
  POLLS_DELETE = "Polls: delete",
  POLLS_EDIT = "Polls: edit",
  POLLS_SUBMIT_ANSWER = "Polls: submit answer",
  PRESENTATION_MODE_AUDIENCE_REACTION_CLICKED = "Presentation Mode Audience Reaction Clicked",
  PRESENTATION_MODE_AUDIENCE_VIEW_CLOSED = "Presentation Mode Audience View Closed",
  PRESENTATION_MODE_AUDIENCE_VIEW_OPENED = "Presentation Mode Audience View Opened",
  PRESENTATION_MODE_END = "Presentation Mode End",
  PRESENTATION_MODE_GET_ATTENDEE_ON_STAGE = "Presentation Mode Get Attendee OnStage",
  PRESENTATION_MODE_START = "Presentation Mode Start",
  PRESENTATION_MODE_STOP = "Presentation Mode Stop",
  REFRESHED = "Manual Refresh",
  ROLLBACK_SHOW_PROMPT = "Rollback Show Prompt",
  ROLLBACK_UPDATE_CANCEL = "Rollback Update Cancel",
  ROLLBACK_UPDATE_REMINDER = "Rollback Update Reminder",
  ROLLBACK_UPDATE_SUCCESS = "Rollback Update Success",
  SCREEN_SHARE_START = "Screen Share Start",
  SCREEN_SHARE_STOP = "Screen Share Stop",
  SHARE_VIDEO_DELAY_MANY_SEEKS = "Share Video - Too many times video needing sync",
  SHARE_VIDEO_DELAY_SYNC_ACTION = "Share Video - Delay detected, seeked the video",
  SHUFFLE_COUNTDOWN_ENDED = "Shuffle Countdown Ended",
  SHUFFLE_COUNTDOWN_STARTED = "Shuffle Countdown Started",
  TEST = "only used for unit testing",
  VIRTUAL_BACKGROUND_START = "Virtual Background Start",
  WHITEBOARD_CLOSE = "Whiteboard Close",
  WHITEBOARD_OPEN = "Whiteboard Open",
}
