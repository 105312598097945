/* eslint-disable jsx-a11y/media-has-caption */
import { useRef, useEffect } from "react";
import { Typography } from "@remo-co/ui-core/src/components/Typography";
import { useIsTalking, useUserData } from "modules/audioVideo/hooks";
import { useSelector } from "react-redux";
import { selectUser } from "modules/auth/redux/selectors";
import { getUserName } from "modules/userProfile";
import { useTranscriptionPublisher } from "modules/transcription";
import { selectIsInBroadcast } from "modules/broadcast/redux/selectors";
import { UserData } from "modules/audioVideo/hooks/useUserData/types";
import {
  useLocalSessionId,
  useParticipantProperty,
} from "@daily-co/daily-react";
import {
  MeetingState,
  useMeetingState,
} from "modules/audioVideo/hooks/useMeetingState";
import { selectAspectRatioPreference } from "modules/userPreferences/redux/selectors";
import { BaseTile } from "modules/audioVideo/components/Tile/BaseTile";
import useUserMute from "modules/audioVideo/components/Tile/MuteGuestButton/hooks/useUserMute";
import { DefaultProfileImage } from "modules/audioVideo/components/Tile/DefaultProfileImage";
import { DisplayItem } from "modules/audioVideo";
import { selectBrowserName } from "modules/systemCheck/redux/selectors";
import { BROWSER_NAMES } from "modules/systemCheck/constants";
import { useI18n } from "i18n";
import { selectShouldProcessCaptions } from "modules/transcription/redux/selectors";
import { DAILY_PLAYABLE_TRACK_STATES } from "modules/audioVideo/constants";
import { useDailyLocalNetwork } from "modules/audioVideo/hooks/useDailyLocalNetwork";
import useEventSplitTreatment from "modules/split/useEventSplitTreatment";
import { EventFeatures } from "services/splitService/features";
import { selectCurrentUserConversationEmoji } from "modules/emoji/redux/selectors";
import { useStyles } from "../styles";
import { useStreams } from "../useStreams";

const useUserProfile = ({ isLocal }: { isLocal: boolean }) => {
  const user = useSelector(selectUser);
  const { setUserData, setUserName } = useUserData();

  useEffect(() => {
    if (isLocal && user) {
      setUserName(getUserName(user));
      setUserData({
        image: user.profile.picture,
      });
    }
  }, [isLocal, user, setUserName, setUserData]);
};

interface Props {
  showLoader?: boolean;
  isClickable?: boolean;
  showName?: boolean;
}

export const LocalVideo = ({
  showLoader = false,
  showName = true,
  isClickable,
}: Props): JSX.Element | null => {
  const { featureEnabled: transcriptionV2Enabled } = useEventSplitTreatment(
    EventFeatures.TRANSCRIPTION_V2,
  );
  const { t } = useI18n(["event"]);
  const id = useLocalSessionId();
  const [
    userName,
    localUserData,
    audioTrack,
    videoTrack,
    audioState,
    videoState,
    isLocal,
  ] = useParticipantProperty(id, [
    "user_name",
    "userData",
    "tracks.audio.persistentTrack",
    "tracks.video.persistentTrack",
    "tracks.audio.state",
    "tracks.video.state",
    "local",
  ]);
  const browserName = useSelector(selectBrowserName);
  const videoRef = useRef<HTMLVideoElement>(null);
  const { networkQuality, isInterrupted: isNetworkConnectionInterrupted } =
    useDailyLocalNetwork();
  const { userData, setUserData } = useUserData();
  const meetingState = useMeetingState();

  const isInBroadcast = useSelector(selectIsInBroadcast);
  const originalAspectRatioPreference = useSelector(
    selectAspectRatioPreference,
  );
  const shouldProcessCaptions = useSelector(selectShouldProcessCaptions);

  const conversationEmoji = useSelector(selectCurrentUserConversationEmoji);

  const styles = useStyles();
  useUserMute();
  useUserProfile({
    isLocal,
  });

  const { publishProcessor, unpublishProcessor } = useTranscriptionPublisher();

  useEffect(() => {
    if (networkQuality !== userData.networkQuality) {
      setUserData({ networkQuality });
    }
  }, [networkQuality, setUserData, userData]);

  useEffect(() => {
    // ensure preference is forwarded as userdata on load
    if (
      originalAspectRatioPreference !== Boolean(userData.originalAspectRatio)
    ) {
      setUserData({ originalAspectRatio: originalAspectRatioPreference });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (audioTrack && isInBroadcast && !transcriptionV2Enabled) {
      if (shouldProcessCaptions) {
        publishProcessor(audioTrack);
      } else {
        unpublishProcessor();
      }
    }

    return () => {
      if (!transcriptionV2Enabled) {
        unpublishProcessor();
      }
    };
  }, [
    audioTrack,
    isInBroadcast,
    shouldProcessCaptions,
    publishProcessor,
    unpublishProcessor,
    transcriptionV2Enabled,
  ]);

  const { isTalking } = useIsTalking({
    audioTrack,
  });

  useStreams({
    videoRef,
    videoTrack,
    videoState,
  });

  const videoType = "camera";

  const isMobileSafari = browserName === BROWSER_NAMES.MOBILE_SAFARI;

  const showVideoUnPausePrompt =
    [BROWSER_NAMES.SAFARI, BROWSER_NAMES.MOBILE_SAFARI].includes(browserName) &&
    (audioState === "interrupted" || videoState === "interrupted");

  return (
    <BaseTile
      hasAudio={DAILY_PLAYABLE_TRACK_STATES.includes(audioState)}
      isTalking={isTalking}
      isLocal
      videoType={videoType}
      networkQuality={networkQuality}
      showMuteButton={false}
      tile={{ streamId: id, local: true, type: DisplayItem.camera }}
      loading={showLoader || meetingState === MeetingState.connecting}
      originalAspectRatio={originalAspectRatioPreference}
      isClickable={isClickable}
      emoji={conversationEmoji}
      isInterrupted={isNetworkConnectionInterrupted}
    >
      {showVideoUnPausePrompt ? (
        <Typography variant="caption" className={styles.tileOverlay}>
          {t(
            `event:safari.paused.${
              videoState === "interrupted" ? "video" : "audio"
            }.${isMobileSafari ? "ios" : "mac"}`,
          )}
        </Typography>
      ) : (
        <>
          {showName && (
            <Typography variant="body1" className={styles.userName}>
              {userName}
            </Typography>
          )}
          {DAILY_PLAYABLE_TRACK_STATES.includes(videoState) ? (
            <video
              autoPlay
              playsInline
              height="100%"
              width="100%"
              ref={videoRef}
              style={{
                transform: "scale(-1, 1)",
              }}
            />
          ) : (
            <DefaultProfileImage
              imageUrl={(localUserData as UserData)?.image}
            />
          )}
        </>
      )}
    </BaseTile>
  );
};
