import { useMutation } from "@tanstack/react-query";
import { useAppDispatch } from "store/hooks";
import {
  addErrorNotification,
  addSuccessNotification,
} from "modules/notification/redux/notificationSlice";
import {
  deleteLiveStreamRequest,
  DeleteLiveStreamPayload,
} from "modules/liveStream/request";
import { deleteLiveStreamData } from "modules/liveStream/redux/slice";
import { liveStreamFirebase } from "services/firebaseService/liveStream";
import { useSelector } from "react-redux";
import { selectCurrentTheaterId } from "modules/theater/selectors";
import { useI18n } from "i18n";
import { Events, TRACKING_CONTEXT } from "modules/tracking";
import { MANAGE_EVENT_CONTEXT } from "modules/manageEvent";
import { useCallback, useContext } from "react";
import { useIsInEvent } from "modules/event";
import { useLiveStreamingAsync } from "../useLiveStreaming";

export const useDeleteLiveStream = () => {
  const { track } = useContext(TRACKING_CONTEXT);
  const { state } = useContext(MANAGE_EVENT_CONTEXT);
  const eventData = state?.eventData ?? undefined;
  const isInEvent = useIsInEvent();

  const dispatch = useAppDispatch();
  const { t } = useI18n(["manageEvent"]);

  const onLiveStreamStopped = useCallback(() => {
    track(Events.RTMP_OUT_STREAM_ENDED_SUCCESSFULLY, {
      eventId: eventData?.id,
    });
  }, [eventData?.id, track]);

  const { isLiveStreaming, stopStreaming } = useLiveStreamingAsync({
    onLiveStreamStopped,
  });
  const theaterId = useSelector(selectCurrentTheaterId);

  const {
    mutate: deleteLiveStream,
    isPending: isDeleteStreamLoading,
    status,
  } = useMutation({
    mutationKey: ["deleteLiveStream"],
    mutationFn: (payload: DeleteLiveStreamPayload) =>
      deleteLiveStreamRequest(payload),
    onSuccess: (_data, { streamId }) => {
      if (!isInEvent) dispatch(deleteLiveStreamData(streamId));
      track(Events.RTMP_OUT_DESTINATION_DELETED, {
        eventId: eventData?.id,
      });

      if (isLiveStreaming && theaterId) {
        stopStreaming();
        liveStreamFirebase.stopLiveStream(theaterId);
      }

      dispatch(
        addSuccessNotification({
          message: t("manageEvent:stream.deleted.success"),
        }),
      );
    },
    onError: (error: Error) => {
      dispatch(
        addErrorNotification({
          message: error.message,
        }),
      );
    },
  });
  return { deleteLiveStream, isDeleteStreamLoading, status };
};
