import store from "store";
import { database } from "services/firebaseService/firebaseConfig";
import { THEATER_PATH } from "modules/theater/firebase";
import {
  setIsLiveStreamLoading,
  setIsLiveStreaming,
} from "modules/liveStream/redux/slice";
import { off, onValue, ref, remove, set } from "firebase/database";

const getLiveStreamInfoDocRef = (theaterId: string) =>
  ref(database, `${THEATER_PATH}/${theaterId}/Broadcast/LiveStream`);

export interface LiveStreams {
  serverUrl: string;
  streamKey: string;
}

interface LiveStreamData {
  isLiveStreaming?: boolean;
}

const startLiveStream = async (theaterId: string) => {
  await set(getLiveStreamInfoDocRef(theaterId), {
    isLiveStreaming: true,
  });

  getLiveStreamInfoDocRef(theaterId);
};

const stopLiveStream = async (theaterId: string) =>
  remove(getLiveStreamInfoDocRef(theaterId));

export const subscribeEventLiveStream = (theaterId: string) => {
  off(getLiveStreamInfoDocRef(theaterId));

  // eslint-disable-next-line consistent-return
  onValue(getLiveStreamInfoDocRef(theaterId), async (snapshot) => {
    const livestream: LiveStreamData = snapshot.val();

    if (!livestream) {
      store.dispatch(setIsLiveStreamLoading(false));
      store.dispatch(setIsLiveStreaming(false));
      return undefined;
    }

    const { isLiveStreaming } = livestream;

    store.dispatch(setIsLiveStreaming(Boolean(isLiveStreaming)));
  });
};

export const unsubscribeEventLiveStream = (theaterId: string) => {
  off(getLiveStreamInfoDocRef(theaterId));
};
export default {
  getLiveStreamInfoDocRef,
  startLiveStream,
  stopLiveStream,
  subscribeEventLiveStream,
  unsubscribeEventLiveStream,
};
