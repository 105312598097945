import { Typography } from "@remo-co/ui-core/src/components/Typography";
import { useI18n } from "i18n";
import { IChannel } from "modules/chat/types";
import { useSelector } from "react-redux";
import { selectUnreadCount } from "modules/chat/redux/selectors";
import { IUser } from "modules/app/types";
import { ChatRow } from "../ChatRow";
import useStyles from "./styles";

interface Props {
  context?: { publicChannels: IChannel[]; user: IUser | undefined };
}

export const ChatListHeader = ({ context }: Props) => {
  const { publicChannels, user } = context ?? {};
  const newMessageCount = useSelector(selectUnreadCount);
  const { t } = useI18n(["micCamCheck"]);
  const classes = useStyles();

  const getChannelUnreadCount = (channelId: string) =>
    newMessageCount.get(channelId) || 0;

  if (!publicChannels || publicChannels.length === 0) {
    return null;
  }

  return (
    <div className={classes.chatList}>
      <Typography variant="h5" className={classes.sectionTitle}>
        {t("micCamCheck:public.chat")}
      </Typography>
      {publicChannels.map((channel: IChannel) => (
        <ChatRow
          key={channel.id}
          channel={channel}
          lastMessage={channel.lastMessage}
          user={user}
          unread={getChannelUnreadCount(channel.id)}
        />
      ))}
      <Typography variant="h5" className={classes.sectionTitle}>
        {t("micCamCheck:private.chat")}
      </Typography>
    </div>
  );
};
