import { supportedLanguages } from "../../constants";

export default (languages: Navigator["languages"]): string => {
  const sanitizedPreferredLanguages = [...languages, "en"].map(
    (language) => language.split("-")[0],
  );

  const [lang] = sanitizedPreferredLanguages
    .filter(
      (language, position) =>
        sanitizedPreferredLanguages.indexOf(language) === position,
    )
    .filter((language) => supportedLanguages.includes(language));

  return lang;
};
