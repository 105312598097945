import { Container } from "@remo-co/ui-core/src/components/Container";
import { useMemo, RefObject } from "react";
import { useSelector } from "react-redux";
import { selectIsMobile } from "modules/deviceInfo";
import { selectActiveTile } from "modules/audioVideo/redux/selectors";
import { PaginationButton } from "modules/audioVideo/components/DailyContainer/PaginationButton";
import { ChangeLayoutButton } from "modules/audioVideo/components/DailyContainer/ChangeLayoutButton";
import { PopperPlacement } from "modules/audioVideo/components/DailyContainer/ChangeLayoutButton/constants";
import { TileGroup } from "modules/audioVideo/components/DailyContainer/Tiles/TileGroup";
import { usePagination } from "modules/audioVideo/components/DailyContainer/Layouts/hooks/usePagination";
import { LayoutProps } from "modules/audioVideo/components/DailyContainer/Layouts/types";
import {
  TOTAL_ARROW_BUTTON_HEIGHT,
  MAX_PAGE_SIZE_FOCUS_DESKTOP,
  MAX_PAGE_SIZE_FOCUS_MOBILE,
  FOCUS_MODE_TILE_HEIGHT,
  FOCUS_MODE_TILE_WIDTH,
} from "modules/audioVideo/components/DailyContainer/Layouts/hooks/constants";
import { selectIsInBroadcast } from "modules/broadcast/redux/selectors";
import { useActiveSpeakerTile } from "modules/audioVideo/hooks/useActiveSpeakerTile";
import { useStyles } from "./styles";

export const FocusLayout = ({
  tiles,
  onTileClick,
  containerRef,
  switchLayout,
}: LayoutProps & {
  containerRef: RefObject<HTMLDivElement>;
}) => {
  const isMobile = useSelector(selectIsMobile);

  const height = containerRef.current?.offsetHeight;
  const width = containerRef.current?.offsetWidth;
  const activeTile = useSelector(selectActiveTile);
  const isInBroadcast = useSelector(selectIsInBroadcast);

  const maxPageSize = useMemo(() => {
    if (height && !isMobile) {
      return (
        Math.floor(
          (height - TOTAL_ARROW_BUTTON_HEIGHT) / FOCUS_MODE_TILE_HEIGHT,
        ) || MAX_PAGE_SIZE_FOCUS_MOBILE
      );
    }

    if (width && isMobile) {
      return (
        Math.floor(width / FOCUS_MODE_TILE_WIDTH) || MAX_PAGE_SIZE_FOCUS_MOBILE
      );
    }

    if (isMobile) {
      return MAX_PAGE_SIZE_FOCUS_MOBILE;
    }

    return MAX_PAGE_SIZE_FOCUS_DESKTOP;
  }, [height, isMobile, width]);

  const nonActiveTiles = tiles.filter(
    ({ streamId }) => streamId !== activeTile?.streamId,
  );

  const { participantTiles } = useActiveSpeakerTile(
    nonActiveTiles,
    maxPageSize,
  );

  const {
    currentPage,
    canPaginateBackward,
    canPaginateForward,
    paginateBack,
    paginateForward,
    pageIndex,
    totalPages,
    hasPagination,
  } = usePagination(participantTiles, maxPageSize);

  const styles = useStyles({
    isMobile,
    hasPagination,
  });

  return (
    <Container data-testid="focus-layout" className={styles.root}>
      {nonActiveTiles.length > 0 && (
        <>
          <Container className={styles.leftColumnWrapper}>
            <Container className={styles.leftColumn}>
              <TileGroup
                className={styles.tile}
                tiles={currentPage}
                onTileClick={onTileClick}
              />
            </Container>
            {!isMobile && (!isInBroadcast || tiles.length > 1) && (
              <div className={styles.banner}>
                <Container className={styles.button}>
                  <Container className={styles.viewButtonRoot}>
                    {hasPagination && (
                      <PaginationButton
                        canPaginateForward={canPaginateForward}
                        canPaginateBackward={canPaginateBackward}
                        paginateForward={paginateForward}
                        paginateBackward={paginateBack}
                        totalPages={totalPages}
                        currentPage={pageIndex}
                        direction="vertical"
                      />
                    )}
                    <ChangeLayoutButton
                      placement={PopperPlacement.topStart}
                      tiles={tiles}
                      hasPagination={hasPagination}
                      switchLayout={switchLayout}
                    />
                  </Container>
                </Container>
              </div>
            )}
          </Container>
          {isMobile && (!isInBroadcast || tiles.length > 1) && (
            <div className={styles.banner}>
              <Container className={styles.button}>
                <Container className={styles.viewButtonRoot}>
                  {hasPagination && (
                    <PaginationButton
                      canPaginateForward={canPaginateForward}
                      canPaginateBackward={canPaginateBackward}
                      paginateForward={paginateForward}
                      paginateBackward={paginateBack}
                      totalPages={totalPages}
                      currentPage={pageIndex}
                      direction="horizontal"
                    />
                  )}
                  <ChangeLayoutButton
                    placement={
                      isMobile
                        ? PopperPlacement.bottom
                        : PopperPlacement.bottomStart
                    }
                    tiles={tiles}
                    hasPagination={hasPagination}
                    switchLayout={switchLayout}
                  />
                </Container>
              </Container>
            </div>
          )}
        </>
      )}
      {activeTile && (
        <Container className={styles.focusedTile}>
          <TileGroup tiles={[activeTile]} onTileClick={onTileClick} />
        </Container>
      )}
    </Container>
  );
};
