import { NetworkQuality } from "modules/audioVideo/types";
import { Tooltip } from "@remo-co/ui-core/src/components/Tooltip";
import { SignalStrengthLow } from "@remo-co/ui-core/src/icons/SignalStrengthLow";
import { SignalStrengthMedium } from "@remo-co/ui-core/src/icons/SignalStrengthMedium";
import { SignalStrengthHigh } from "@remo-co/ui-core/src/icons/SignalStrengthHigh";
import { SignalStrengthDefault } from "@remo-co/ui-core/src/icons/SignalStrengthDefault";
import { Warning } from "@remo-co/ui-core/src/icons/Warning";
import { useI18n } from "i18n";
import { useStyles } from "./styles";

interface Props {
  networkQuality: NetworkQuality | null;
  isInterrupted: boolean;
  isLocal: boolean;
}

export const NetworkQualityIndicator = ({
  networkQuality,
  isInterrupted,
  isLocal,
}: Props) => {
  const { t } = useI18n(["event"]);
  const styles = useStyles();

  if (isInterrupted) {
    const tooltipText = t(
      `event:network.connection.interrupted.${isLocal ? "local" : "remote"}`,
    );

    return (
      <Tooltip title={tooltipText}>
        <div
          className={styles.interrupted}
          data-testid="network-indicator-interrupted"
        >
          <Warning className={styles.warningIcon} />
          <SignalStrengthDefault className={styles.icon} />
        </div>
      </Tooltip>
    );
  }

  switch (networkQuality) {
    case "very-low":
      return (
        <Tooltip title={t("event:network.connection.low")}>
          <SignalStrengthLow
            data-testid="network-indicator-low-quality"
            className={styles.icon}
          />
        </Tooltip>
      );
    case "low":
      return (
        <Tooltip title={t("event:network.connection.medium")}>
          <SignalStrengthMedium
            data-testid="network-indicator-medium-quality"
            className={styles.icon}
          />
        </Tooltip>
      );
    default:
      return (
        <Tooltip title={t("event:network.connection.high")}>
          <SignalStrengthHigh
            data-testid="network-indicator-high-quality"
            className={styles.icon}
          />
        </Tooltip>
      );
  }
};
