import { useI18n } from "i18n";
import { addDialogNotification } from "modules/dialogNotification/redux/dialogNotificationSlice";
import { useAppDispatch } from "store/hooks";

type Confirm = (onConfirm: () => void, onCancel?: () => void) => void;

const useConfirmationDialog = ({
  message,
  confirmLabel,
  cancelLabel,
}: {
  message: string;
  confirmLabel?: string;
  cancelLabel?: string;
}): Confirm => {
  const { t } = useI18n();
  const dispatch = useAppDispatch();

  return (onConfirm, onCancel) =>
    dispatch(
      addDialogNotification({
        message,
        hideCloseButton: true,
        onConfirm,
        onDismiss: onCancel,
        confirmText: confirmLabel || t("confirm.dialog.leave"),
        dismissText: cancelLabel || t("confirm.dialog.stay"),
      }),
    );
};

export default useConfirmationDialog;
