import { Theme } from "@remo-co/ui-core/src/types";
import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";

const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    width: "100%",
    color: theme.palette.white,
  },
  switchContainer: {
    height: theme.spacing(4),
    justifyContent: "space-between",
    alignItems: "center",
    display: "flex",
    paddingLeft: 20,
    paddingRight: 22,
    paddingTop: 10,
    paddingBottom: 10,
    borderTop: "1px solid rgba(255, 255, 255, 0.1)",
    borderBottom: "1px solid rgba(255, 255, 255, 0.1)",
  },
  switchTrack: {
    color: "#DBE4FF",
  },
  switchBase: {
    color: "#DBE4FF",
    opacity: 0.5,
  },
  switchPrimary: {
    color: theme.palette.white,
  },
  switchSecondary: {
    color: theme.palette.white,
  },
  captionOffText: {
    opacity: "70%",
  },
  messagesContainer: {
    height: "100%",
    padding: "10px",
    overflowY: "scroll",
    "&::-webkit-scrollbar": {
      width: "10px",
    },

    "&::-webkit-scrollbar-track": {
      background: "var(--White-op-030)",
      borderRadius: "5px",
    },

    "&::-webkit-scrollbar-thumb": {
      background: "#45475e",
      borderRadius: "5px",
    },

    scrollbarColor: "#45475e var(--White-op-030)",
    display: "flex",
    flexDirection: "column-reverse",
  },
  withoutMessages: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
}));

export default useStyles;
