import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { makeSelectUserById } from "modules/event/usersInEvent/selectors";
import { TranscriptionProfileInfo } from "modules/transcription";
import { selectIsMobile } from "modules/deviceInfo";
import { useTheme } from "@remo-co/ui-core/src/hooks/useTheme";
import { Typography } from "@remo-co/ui-core/src/components/Typography";
import TranscriptionStyles from "./styles";

interface TranscriptionProps {
  message: RealtimeSpeechData;
}

const Transcription = ({ message }: TranscriptionProps): JSX.Element => {
  const user = useSelector(makeSelectUserById(message.user.userId));
  const theme = useTheme();
  const classes = TranscriptionStyles();
  const isMobileView = useSelector(selectIsMobile);

  const [transcriptionText, setTranscriptionText] = useState<string>("");

  const transcriptionTextId = `transcription-text-${message.user.userId}`;

  useEffect(() => {
    const element = document.getElementById(transcriptionTextId);
    if (element && typeof theme.typography.h4.lineHeight === "number") {
      const maxHeight = theme.typography.h4.lineHeight * 14 * 4;
      element.style.maxHeight = `${maxHeight}px`;
    }
  }, [theme, transcriptionTextId]);

  useEffect(() => {
    if (message.punctuated.transcript) {
      setTranscriptionText((prevText) =>
        prevText !== message.punctuated.transcript
          ? message.punctuated.transcript
          : prevText,
      );
    }
  }, [message.punctuated.transcript]);

  return (
    <div className={classes.root} data-testid="transcription-message">
      <TranscriptionProfileInfo user={user} mobileView={isMobileView} />
      <div className={classes.textWrapper}>
        <div className={classes.text} id={transcriptionTextId}>
          <Typography variant="h4">{transcriptionText}</Typography>
        </div>
      </div>
    </div>
  );
};

export default Transcription;
