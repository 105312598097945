import { SwapHorizontalCircle } from "@remo-co/ui-core/src/icons/SwapHorizontalCircle";
import { ActionButton } from "modules/actionButton";
import { useI18n } from "i18n";
import { useAppDispatch } from "store/hooks";
import useStyles from "./styles";
import { setDialogVisible } from "../../redux";

interface Props {
  closePopover: () => void;
}

export const InEventSeatManagementButton = ({
  closePopover,
}: Props): JSX.Element => {
  const dispatch = useAppDispatch();
  const { t } = useI18n(["event"]);
  const styles = useStyles();

  const openDialog = () => {
    closePopover();
    dispatch(setDialogVisible(true));
  };

  return (
    <ActionButton
      title={t("assigned.shuffle")}
      label={t("assigned.shuffle")}
      onClick={openDialog}
      isPopoverButton
      icon={<SwapHorizontalCircle className={styles.icon} />}
    />
  );
};
