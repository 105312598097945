import { useDailyEvent } from "@daily-co/daily-react";
import { useCallback, useState } from "react";

export enum MeetingState {
  connected = "connected",
  connecting = "connecting",
  disconnected = "disconnected",
}

/** @deprecated prefer useMeetingState from daily-react */
export const useMeetingState = () => {
  const [meetingState, setMeetingState] = useState<MeetingState>(
    MeetingState.disconnected,
  );
  useDailyEvent(
    "joining-meeting",
    useCallback(() => {
      setMeetingState(MeetingState.connecting);
    }, []),
  );

  useDailyEvent(
    "joined-meeting",
    useCallback(() => {
      setMeetingState(MeetingState.connected);
    }, []),
  );

  useDailyEvent(
    "left-meeting",
    useCallback(() => {
      setMeetingState(MeetingState.disconnected);
    }, []),
  );

  return meetingState;
};
