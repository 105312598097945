import { useEffect, useMemo, useState } from "react";
import {
  selectActiveTile,
  selectIsFocusLayoutManually,
  selectLayout,
  selectTileSelected,
} from "modules/audioVideo/redux/selectors";
import { useSelector } from "react-redux";
import { DisplayItem, DisplayLayout, TileDraft } from "modules/audioVideo";
import {
  setActiveTile,
  setIsLayoutManually,
  setIsTileSelected,
  setLayout,
} from "modules/audioVideo/redux/slice";
import { useAppDispatch } from "store/hooks";
import { usePrevious } from "helpers/reactHooksUtils";
import { selectCurrentRoomId } from "store/rooms";
import { useLocalSessionId } from "@daily-co/daily-react";
import { selectActiveSpeaker } from "modules/daily/redux/selectors";
import { useAvailableStreamIds } from "../useLayout/useAvailableStreamIds";

interface Props {
  screens: string[];
  cameras: string[];
}

export const INVALIDATION_RESET_INTERVAL = 300;

export const useConversationLayoutEffects = ({ screens, cameras }: Props) => {
  const dispatch = useAppDispatch();
  const { availableStreamIds } = useAvailableStreamIds({ screens, cameras });
  const layout = useSelector(selectLayout);
  const tableId = useSelector(selectCurrentRoomId);
  const prevTableId = usePrevious(tableId);
  const [invalidateNextFocusChange, setInvalidateNextFocusChange] =
    useState(false);
  const [focusTileQueue, setFocusTileQueue] = useState<TileDraft[]>([]);
  const [preFocusLayout, setPreFocusLayout] = useState(DisplayLayout.Map);
  const [previousTilesCount, setPreviousTilesCount] = useState(0);
  const tileSelected = useSelector(selectTileSelected);
  const isLayoutSetManually = useSelector(selectIsFocusLayoutManually);
  const localSessionId = useLocalSessionId();
  const activeTile = useSelector(selectActiveTile);

  const screenIds = availableStreamIds.filter((stream) =>
    stream.endsWith("screen"),
  );
  const whiteboard = availableStreamIds.filter((stream) =>
    stream.endsWith("whiteboard"),
  );

  const focusableTiles: TileDraft[] = useMemo(
    () => [
      ...whiteboard.map((whiteboardStreamId) => ({
        streamId: whiteboardStreamId,
        type: DisplayItem.whiteboard,
      })),
      ...screenIds.map((screenId) => ({
        streamId: screenId,
        type: DisplayItem.screenShare,
      })),
    ],
    [whiteboard, screenIds],
  );
  const previousFocusableTiles = usePrevious(focusableTiles);

  useEffect(() => {
    // skip initial render - do not want to focus anything when we've just landed
    if (!previousFocusableTiles) {
      return;
    }

    // if there are any tiles in focusTileQueue but there are actually none that are focusable, clear queue
    if (focusableTiles.length === 0 && focusTileQueue.length > 0) {
      setFocusTileQueue([]);
      return;
    }

    // if there are differences between current focusable and previous focusable tiles, update queue
    // to make this fast, we're only checking length changes, unlikely that we'll have constant length but different content in the same render
    if (focusableTiles.length !== previousFocusableTiles.length) {
      const activeTilesInQueue = focusTileQueue.filter((queuedTile) =>
        focusableTiles.some(
          (focusableTile) => focusableTile.streamId === queuedTile.streamId,
        ),
      );
      const newActiveTiles = focusableTiles.filter(
        (focusableTile) =>
          !activeTilesInQueue.some(
            (queuedTile) => queuedTile.streamId === focusableTile.streamId,
          ),
      );

      setFocusTileQueue([...newActiveTiles, ...activeTilesInQueue]);
    }
  }, [focusTileQueue, focusableTiles, previousFocusableTiles]);

  // when moving across tables, invalidate the next upcoming focus change
  useEffect(() => {
    if (!prevTableId || tableId === prevTableId) {
      return;
    }

    setInvalidateNextFocusChange(true);
    setTimeout(() => {
      setInvalidateNextFocusChange(false);
    }, INVALIDATION_RESET_INTERVAL);
  }, [tableId, prevTableId]);

  // keep a record of the previous layout before focus changes were committed
  useEffect(() => {
    if (layout === DisplayLayout.Focus) {
      return;
    }

    setPreFocusLayout(layout);
  }, [dispatch, layout]);

  useEffect(() => {
    if (!availableStreamIds.length && layout !== DisplayLayout.Map) {
      dispatch(setLayout(DisplayLayout.Map));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [availableStreamIds.length]);

  useEffect(() => {
    if (previousTilesCount === focusTileQueue.length) {
      return;
    }
    if (previousTilesCount < focusTileQueue.length) {
      dispatch(setIsLayoutManually(false));
    }
    dispatch(setIsTileSelected(false));
    setPreviousTilesCount(focusTileQueue.length);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [focusTileQueue]);

  const speaker = useSelector(selectActiveSpeaker);

  useEffect(() => {
    if (tileSelected && previousTilesCount === focusTileQueue.length) {
      return;
    }
    if (focusTileQueue.length === 0) {
      if (isLayoutSetManually) {
        if (speaker) {
          if (speaker !== localSessionId || !activeTile) {
            dispatch(
              setActiveTile({
                streamId: speaker,
                type: DisplayItem.camera,
                local: speaker === localSessionId,
              }),
            );
          }
        } else {
          dispatch(
            setActiveTile({
              streamId: localSessionId,
              type: DisplayItem.camera,
              local: speaker === localSessionId,
            }),
          );
        }
        return;
      }
      if (layout !== preFocusLayout) {
        dispatch(setLayout(preFocusLayout));
        dispatch(setIsLayoutManually(false));
      }
      return;
    }

    if (invalidateNextFocusChange) {
      setInvalidateNextFocusChange(false);
      return;
    }

    if (layout !== DisplayLayout.Focus && !isLayoutSetManually) {
      dispatch(setLayout(DisplayLayout.Focus));
      dispatch(setActiveTile(focusTileQueue[0]));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [focusTileQueue, speaker, tileSelected, isLayoutSetManually]);

  useEffect(() => {
    const handleEscapeKeyPress = (event: KeyboardEvent) => {
      if (
        (layout === DisplayLayout.Focus || layout === DisplayLayout.Tile) &&
        event.key === "Escape"
      ) {
        dispatch(setLayout(DisplayLayout.Map));
      }
    };

    window.addEventListener("keydown", handleEscapeKeyPress);

    // eslint-disable-next-line consistent-return
    return () => {
      window.removeEventListener("keydown", handleEscapeKeyPress);
    };
  }, [layout, dispatch, focusTileQueue]);
};
