import { useContext, useState } from "react";
import { useI18n } from "i18n";
import { ActionButton } from "modules/actionButton";
import { ClickAwayListener } from "@remo-co/ui-core/src/components/ClickAwayListener";
import { Smiley } from "@remo-co/ui-core/src/icons/Smiley";
import { ConversationEmojiContainer } from "modules/emoji/ConversationEmoji";
import {
  autoUpdate,
  offset,
  useFloating,
} from "@floating-ui/react-dom-interactions";
import { Events, TRACKING_CONTEXT } from "modules/tracking";
import { useSelector } from "react-redux";
import { selectCurrentEventId } from "modules/event/selectors";
import { selectUserId } from "modules/auth/redux/selectors";
import useStyles from "./styles";

export const ReactionButton = (): JSX.Element => {
  const { t } = useI18n();
  const [isOpen, setIsOpen] = useState(false);
  const { track } = useContext(TRACKING_CONTEXT);
  const eventId = useSelector(selectCurrentEventId);
  const userId = useSelector(selectUserId);

  const styles = useStyles({ isOpen });

  const closePopper = () => {
    setIsOpen(false);
  };

  const handlePopper = () => {
    if (!isOpen) {
      track(Events.REACTION_OPENED, { eventId, userId: userId ?? "" });
    }

    setIsOpen(!isOpen);
  };

  const { x, y, floating, strategy, reference } =
    useFloating<HTMLButtonElement>({
      whileElementsMounted: autoUpdate,
      placement: "top",
      middleware: [offset({ mainAxis: 14, crossAxis: 0 })],
    });

  return (
    <ClickAwayListener onClickAway={closePopper} mouseEvent="onMouseDown">
      <div className={styles.container}>
        <ActionButton
          onClick={handlePopper}
          ref={reference}
          label={t("reaction.button")}
          isActive={isOpen}
          icon={<Smiley />}
        />
        {isOpen && (
          <div
            ref={floating}
            style={{
              position: strategy,
              top: y ?? "",
              left: x ?? "",
              zIndex: 10,
            }}
          >
            <div className={styles.popoverContent}>
              <ConversationEmojiContainer />
            </div>
          </div>
        )}
      </div>
    </ClickAwayListener>
  );
};
