import { compareVersions as compareSemver } from "compare-versions";
import logger from "logging/logger";
import { VersionComparisonResult } from "../../types";

const compareVersions = (
  deployedVersion: string,
  currentVersion: string,
): VersionComparisonResult => {
  try {
    const versionComparison = compareSemver(deployedVersion, currentVersion);

    if (versionComparison === 1) {
      return VersionComparisonResult.CURRENT_VERSION_LOWER_THAN_DEPLOYED;
    }

    if (versionComparison === 0) {
      return VersionComparisonResult.VERSIONS_MATCH;
    }

    return VersionComparisonResult.CURRENT_VERSION_HIGHER_THAN_DEPLOYED;
  } catch (error) {
    if (error instanceof Object) {
      logger.warn("[join-event-autoupdate] Error comparing versions", error);
    }
    return VersionComparisonResult.VERSIONS_MATCH;
  }
};

export default compareVersions;
