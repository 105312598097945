import {
  syncUserRoomStatus,
  unsubscribeUserRoomStatus,
} from "modules/room/userRoom.firebase";
import {
  handleSyncUserStatus,
  unsubscribeUserStatus,
} from "services/firebaseService/userStatus.firebase";
import { useAppDispatch } from "store/hooks";
import {
  handleSyncRoomStatus,
  reset as resetRooms,
} from "services/firebaseService/room";
import userStatusActions from "modules/userStatus/redux/actions";
import { resetUserRoomStatus } from "modules/room/redux/roomSlice";
import { setSpaceId } from "modules/space/redux/spaceSlice";
import { setVisible as setAssignedTableNudgeVisible } from "modules/assignTables/redux";
import { useCallback } from "react";

export const useClearFloorDataAndRegisterListeners = () => {
  const appDispatch = useAppDispatch();

  const clearFloorDataAndListeners = useCallback(() => {
    unsubscribeUserRoomStatus();
    resetRooms();
    unsubscribeUserStatus();
    appDispatch(resetUserRoomStatus());
    appDispatch(userStatusActions.resetUserStatus());
  }, [appDispatch]);

  const registerFloorListeners = useCallback(() => {
    syncUserRoomStatus(); // user positions at tables
    handleSyncUserStatus(); // camera, microphone and screen sharing state
    handleSyncRoomStatus();
  }, []);

  const clearFloorDataAndRegisterListeners = useCallback(
    (targetSpaceId: string) => {
      clearFloorDataAndListeners();
      appDispatch(setSpaceId(targetSpaceId));
      appDispatch(setAssignedTableNudgeVisible(false));
      registerFloorListeners();
    },
    [appDispatch, clearFloorDataAndListeners, registerFloorListeners],
  );

  return { clearFloorDataAndRegisterListeners };
};
