import { useMutation } from "@tanstack/react-query";
import { useAppDispatch } from "store/hooks";
import {
  addErrorNotification,
  addSuccessNotification,
} from "modules/notification/redux/notificationSlice";
import {
  updateLiveStreamRequest,
  LiveStreamPayload,
} from "modules/liveStream/request";
import { updateLiveStreamData } from "modules/liveStream/redux/slice";
import { useI18n } from "i18n";
import { useIsInEvent } from "modules/event";

export const useUpdateLiveStream = () => {
  const dispatch = useAppDispatch();
  const { t } = useI18n(["manageEvent"]);
  const isInEvent = useIsInEvent();

  const {
    mutate: updateLiveStream,
    isPending: isLiveStreamUpdating,
    status,
  } = useMutation({
    mutationKey: ["updateLiveStream"],
    mutationFn: (payload: LiveStreamPayload) =>
      updateLiveStreamRequest(payload),
    onSuccess: (data) => {
      if (data.data && !isInEvent) {
        dispatch(updateLiveStreamData(data.data));
      }
      dispatch(
        addSuccessNotification({
          message: t("manageEvent:stream.updated.success"),
        }),
      );
    },
    onError: (error: Error) => {
      dispatch(
        addErrorNotification({
          message: error.message,
        }),
      );
    },
  });
  return { updateLiveStream, isLiveStreamUpdating, status };
};
