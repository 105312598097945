import { Typography } from "@remo-co/ui-core/src/components/Typography";
import { PersonAdd } from "@remo-co/ui-core/src/icons/PersonAdd";
import { isRoomFull } from "helpers/roomHelper";
import { useI18n } from "i18n";
import logger from "logging/logger";
import { selectCurrentEventCompanyPlan } from "modules/event/selectors";
import { makeSelectUserById } from "modules/event/usersInEvent/selectors";
import { useInviteToTable } from "modules/inviteToTable";
import { addWarningNotification } from "modules/notification/redux/notificationSlice";
import { UserItem } from "modules/participants/types";
import { makeSelectUserRoomStatusByRoomId } from "modules/room/redux/selectors";
import { useSelector } from "react-redux";
import { useAppDispatch } from "store/hooks";
import { selectCurrentRoom } from "store/rooms/selectors";
import { IRoom } from "types";

export const GetUser = ({
  user,
  className,
  onGetUser,
}: {
  user: UserItem;
  className: string;
  onGetUser: () => void;
}) => {
  const { t } = useI18n(["common"]);
  const dispatch = useAppDispatch();
  const toRoom = useSelector(selectCurrentRoom);
  const { inviteToTable } = useInviteToTable();
  const eventCompanyPlan = useSelector(selectCurrentEventCompanyPlan);
  const userInSpace = useSelector(makeSelectUserById(user.id));
  const usersInMyRoom = useSelector(
    makeSelectUserRoomStatusByRoomId(toRoom?.id ?? ""),
  );

  const weAreInSameRoom =
    usersInMyRoom && userInSpace && usersInMyRoom[userInSpace.id];

  const onInviteUserToRoom = (toId: string, room: IRoom) => {
    if (!room) {
      logger.warn("[onInviteUserToRoom] No Room ");
      return;
    }

    const { isFull } = isRoomFull(room, eventCompanyPlan);

    if (isFull) {
      dispatch(
        addWarningNotification({
          message: t("table.full.message"),
        }),
      );
    } else {
      inviteToTable({ targetUserId: toId, targetTableId: room.id });
    }
  };

  const handleInviteUser = () => {
    if (userInSpace && toRoom) {
      onInviteUserToRoom(userInSpace.id, toRoom);
    }
    onGetUser();
  };

  if (weAreInSameRoom) return null;
  if (!userInSpace) return null;

  return (
    <Typography
      className={className}
      data-testid="get-user"
      onClick={handleInviteUser}
    >
      <PersonAdd width="20" height="20" />
      {t("get.user")}
    </Typography>
  );
};
