import useSystemClock from "modules/system/hooks/useSystemClock";
import { EventStatus } from "../constants";
import { IEvent } from "../types";
import { checkIsAllowedToJoinEventLobby } from "./checkIsAllowedToJoinEventLobby";

export const useCheckEventStatus = () => {
  const { deviation } = useSystemClock();

  const checkIsAllowedToJoinEventLobbyWithDeviation = (event: IEvent) =>
    checkIsAllowedToJoinEventLobby(event, deviation);
  /**
   * Event status should be active and time should be within range
   */
  const checkIfEventHappeningNow = ({ startTime, endTime, status }: IEvent) => {
    const nowTime = Date.now() + deviation;

    if (startTime && endTime) {
      return (
        status === EventStatus.active &&
        startTime < nowTime &&
        endTime > nowTime
      );
    }

    return status === EventStatus.active;
  };

  /**
   * Event status either inactive or time passed
   */
  const checkIfEventEnded = ({ endTime, status }: IEvent) => {
    if (status === EventStatus.inactive) {
      return true;
    }
    const nowTime = Date.now() + deviation;

    return endTime !== undefined && endTime < nowTime;
  };

  /**
   * Event status should be active and start time should be in future
   */
  const checkIfEventIsUpcoming = ({ startTime, status }: IEvent) => {
    const nowTime = Date.now() + deviation;

    if (startTime) {
      return status === EventStatus.active && startTime > nowTime;
    }

    return status === EventStatus.active;
  };

  const checkIsAllowedToJoinEvent = (event: IEvent) => {
    if (event.isLobbyActive) {
      return checkIsAllowedToJoinEventLobbyWithDeviation(event);
    }

    return !checkIfEventIsUpcoming(event);
  };

  return {
    checkIsAllowedToJoinEvent,
    checkIsAllowedToJoinEventLobby: checkIsAllowedToJoinEventLobbyWithDeviation,
    checkIfEventEnded,
    checkIfEventHappeningNow,
    checkIfEventIsUpcoming,
  };
};
