import React from "react";
import moment from "moment";
import { EVENT_AGENDA_CONTEXT } from "modules/eventAgenda/context/EventAgendaContext";
import "./EventAgendaEnd.scss";
import { Tooltip } from "@remo-co/ui-core/src/components/Tooltip";
import { Typography } from "@remo-co/ui-core/src/components/Typography";
import { useI18n } from "i18n";

interface IEventAgendaEndProps {
  theme: "light" | "dark";
  tooltip?: string;
}

const EventAgendaEnd = ({ tooltip = "", theme }: IEventAgendaEndProps) => {
  const { state, eventData } = React.useContext(EVENT_AGENDA_CONTEXT);
  const { t } = useI18n(["common", "eventAgenda"]);

  if (!state || !state.agendaItems || !state.agendaItems.size) {
    return null;
  }

  return (
    <Tooltip title={tooltip} placement="top">
      <Typography
        color="inherit"
        component="div"
        className={`event-end ${theme}`}
      >
        <span>{moment(eventData?.endTime).format("hh:mm A")}</span>{" "}
        {t("eventAgenda:tooltip.end.event")}
      </Typography>
    </Tooltip>
  );
};

export default EventAgendaEnd;
