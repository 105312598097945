import { Theme } from "@remo-co/ui-core/src/types";
import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";

export const useStyles = makeStyles<Theme>((theme) => ({
  eventRating: {
    display: "flex",
    marginBottom: "2.4rem",
    padding: "14px 18px",
  },
  eventRatingText: {
    display: "inline-block",
    fontSize: "1rem",
    fontWeight: 100,
    lineHeight: "2.7rem",
  },
  eventRatingResult: {
    display: "inline-block",
    fontSize: "1rem",
  },
  eventRatingStar: {
    fill: theme.palette.yellow.dark,
    fontSize: "2rem",
    marginRight: "0.4rem",
    position: "relative",
    top: 5,
  },
  eventRatingRating: {
    fontSize: "1.5rem",
  },
}));
