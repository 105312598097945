import { ActionTypes } from "./constants";
import { ReducerActions } from "./actions";
import { IQuestion } from "../types";

export interface IQAReducerState {
  questions: IQuestion[];
}

export const qaInitialState: IQAReducerState = {
  questions: [],
};

const qaReducer = (
  state = qaInitialState,
  action: ReducerActions,
): IQAReducerState => {
  switch (action.type) {
    case ActionTypes.ADD_QUESTION_DETAILS:
      return {
        ...state,
        questions: action.payload,
      };
    case ActionTypes.CLEAR_QUESTION_DETAILS:
      return {
        ...state,
        questions: [],
      };
    default:
      return state;
  }
};

export default qaReducer;
