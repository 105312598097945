import { useI18n } from "i18n";
import { Box } from "@remo-co/ui-core/src/components/Box";
import { Button } from "@remo-co/ui-core/src/components/Button";
import { Typography } from "@remo-co/ui-core/src/components/Typography";
import { useStyles } from "./styles";

const Analytics = (): JSX.Element | null => {
  const styles = useStyles();
  const { t } = useI18n(["event", "url"]);

  return (
    <Box className={styles.wrap}>
      <div>
        <Typography variant="h4" className={styles.header}>
          {t("event:analytics")}
        </Typography>
        <Typography variant="body1">
          {t("event:analytics.description")}
        </Typography>
      </div>
      <Button<"a">
        href={t("url:request.analytics.mailto")}
        variant="secondary"
        color="blue"
        className={styles.button}
      >
        {t("event:analytics.button.text")}
      </Button>
    </Box>
  );
};

export default Analytics;
