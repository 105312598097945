import { Theme } from "@remo-co/ui-core/src/types";
import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";

export const useStyles = makeStyles<Theme>((theme) => ({
  userDetailsContainer: {
    gap: "7.82px",
    borderRadius: 10,
    padding: 10,
    display: "flex",
    flexDirection: "column",
    border: `0.78px solid ${theme.palette.gray.medium}`,
    margin: "10px",
  },
  textBold: {
    fontWeight: 800,
  },
  commonTopicHeading: {
    fontWeight: 800,
  },
}));
