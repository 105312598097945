export const getFirstName = (name: string): string => {
  if (!name) {
    return "";
  }

  return name.split(" ")[0];
};

export const getLastName = (name: string): string => {
  if (!name) {
    return "";
  }
  const words = name.split(" ");

  return words[words.length - 1];
};
