import { selectCurrentEvent } from "modules/event/selectors";
import { selectLiveUserInEvent } from "modules/event/usersInEvent/selectors";
import { isEventManager, isEventSpeaker } from "modules/event/utils/eventUtils";
import { createSelector } from "@reduxjs/toolkit";

export const selectOnlineUsers = createSelector(
  selectLiveUserInEvent,
  (onlineUsers) => [...onlineUsers.values()],
);

export const selectOnlineGuests = createSelector(
  selectOnlineUsers,
  selectCurrentEvent,
  (onlineUsers, event) => {
    const onlineUsersArray = Object.values(onlineUsers);

    return onlineUsersArray.filter(
      (user) =>
        !(
          isEventManager(event ?? undefined, user.id) ||
          isEventSpeaker(event ?? undefined, user.id, user.email)
        ),
    );
  },
);

export const selectOnlineHosts = createSelector(
  selectOnlineUsers,
  selectCurrentEvent,
  (onlineUsers, event) => {
    const onlineUsersArray = Object.values(onlineUsers);

    return onlineUsersArray.filter((user) =>
      isEventManager(event ?? undefined, user.id),
    );
  },
);

export const selectOnlineSpeakers = createSelector(
  selectOnlineUsers,
  selectCurrentEvent,
  (onlineUsers, event) => {
    const onlineUsersArray = Object.values(onlineUsers);

    return onlineUsersArray.filter((user) =>
      isEventSpeaker(event ?? undefined, user.id, user.email),
    );
  },
);
