import { LoginOptionsConfiguration } from "@remo-co/types";
import { ICompanySettings } from "modules/company/types";
import ActionTypes from "./actionTypes";

const setCompanySettings = (companySettings: ICompanySettings) => ({
  type: ActionTypes.SET_COMPANY_SETTINGS,
  payload: { companySettings },
});

const resetCompanySettings = () => ({
  type: ActionTypes.RESET_COMPANY_SETTINGS,
});

const setLoginOptions = (loginOptions: LoginOptionsConfiguration) => ({
  type: ActionTypes.SET_LOGIN_OPTIONS,
  payload: loginOptions,
});

export default {
  setCompanySettings,
  resetCompanySettings,
  setLoginOptions,
};
