import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { getVar } from "config";

interface AppSettingState {
  appVersion: string;
  appLogo: string | null;
  userOnBoard: boolean;
  isAppInBackground: boolean;
  staticAnimations?: boolean;
}

export const initialState: AppSettingState = {
  appVersion: getVar("REACT_APP_VERSION") ?? "",
  appLogo: null,
  isAppInBackground: false,
  staticAnimations: false,
  userOnBoard: false,
};

const appSettingsSlice = createSlice({
  name: "appSetting",
  initialState,
  reducers: {
    setAppLogo: (
      state: AppSettingState,
      { payload }: PayloadAction<string | null>,
    ) => {
      state.appLogo = payload;
    },
    onBoard: (state: AppSettingState, { payload }: PayloadAction<boolean>) => {
      state.userOnBoard = payload;
    },
    updateAppVersion: (
      state: AppSettingState,
      { payload }: PayloadAction<string>,
    ) => {
      state.appVersion = payload;
    },
    setStaticAnimations: (
      state: AppSettingState,
      { payload }: PayloadAction<boolean>,
    ) => {
      state.staticAnimations = payload;
    },
    reset: () => initialState,
  },
});

export const {
  setAppLogo,
  onBoard,
  updateAppVersion,
  setStaticAnimations,
  reset,
} = appSettingsSlice.actions;

export default appSettingsSlice.reducer;
