import { database, serverValue } from "services/firebaseService/firebaseConfig";
import { THEATER_PATH } from "modules/theater/firebase";
import { ref, set } from "firebase/database";

const getBroadcastWhiteboardDocRef = (theaterId: string) =>
  ref(database, `${THEATER_PATH}/${theaterId}/Broadcast/Whiteboard`);

const openWhiteboard = (theaterId: string, userId: string): Promise<void> =>
  set(getBroadcastWhiteboardDocRef(theaterId), {
    isOpen: true,
    startedBy: userId,
    startedAt: serverValue.TIMESTAMP,
  });

const closeWhiteboard = (theaterId: string): Promise<void> =>
  set(getBroadcastWhiteboardDocRef(theaterId), {
    isOpen: false,
    startedBy: null,
    startedAt: null,
  });

export default {
  openWhiteboard,
  closeWhiteboard,
  getBroadcastWhiteboardDocRef,
};
