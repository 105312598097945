import { useSelector } from "react-redux";
import { Typography } from "@remo-co/ui-core/src/components/Typography";
import { useI18n } from "i18n";
import { BulbIcon } from "@remo-co/ui-core/src/icons/Bulb";
import { selectRecommendedUsers } from "./redux/selectors";
import useStyles from "./styles";
import { NetworkingParticipantDetails } from "./components/NetworkingParticipantDetails";
import { NoConnectionsContainer } from "./components/NoConnectionsContainer";

export const NetworkingRecommendationsContainer = () => {
  const networkingRecommendations = useSelector(selectRecommendedUsers);
  const { t } = useI18n("event");
  const styles = useStyles();

  if (!networkingRecommendations.length) {
    return (
      <div className={styles.container}>
        <NoConnectionsContainer />
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <div className={styles.text}>
        <Typography variant="h5">
          {t("we.think.you.would.connect.well.with")}
        </Typography>
      </div>
      <div className={styles.tipContainer}>
        <BulbIcon data-testid="networking-recommendations-tip-icon" />
        <Typography variant="body1">
          {t("networking.recommendations.tip")}
        </Typography>
      </div>
      {networkingRecommendations.map((user) => (
        <NetworkingParticipantDetails user={user} />
      ))}
    </div>
  );
};
