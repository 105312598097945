import { useLiveStreaming } from "@daily-co/daily-react";
import { useCallback, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "store/hooks";
import logger from "logging/logger";
import { setIsLiveStreamLoading } from "modules/liveStream/redux/slice";
import { selectIsLiveStreamLoading } from "modules/liveStream/redux/selectors";
import {
  DailyEventObjectLiveStreamingStarted,
  DailyEventObjectLiveStreamingStopped,
} from "@daily-co/daily-js";
import {
  focusLayoutConfig,
  gridLayoutConfig,
} from "modules/audioVideo/hooks/useLayout/dal";

type PromiseFunction = (value?: unknown) => void;

interface PromiseRef {
  resolve: PromiseFunction;
  reject: PromiseFunction;
}

interface StartStreaming {
  streamKey: string;
  serverUrl: string;
}

interface Props {
  onLiveStreamStarted?: (ev: DailyEventObjectLiveStreamingStarted) => void;
  onLiveStreamStopped?: (ev: DailyEventObjectLiveStreamingStopped) => void;
}

type UpdateStreamingRequest =
  | {
      mode: "grid";
      streamIds: string[];
    }
  | {
      mode: "focus";
      streamIds: string[];
      preferScreenShare: boolean;
      preferredParticipant: string;
    };

export const useLiveStreamingAsync = ({
  onLiveStreamStarted,
  onLiveStreamStopped,
}: Props = {}): {
  isLiveStreaming: boolean;
  updateStreaming: (request: UpdateStreamingRequest) => Promise<void>;
  startStreaming: ({
    serverUrl,
    streamKey,
  }: StartStreaming) => Promise<unknown>;
  stopStreaming: () => void;
} => {
  const dispatch = useAppDispatch();
  const isLoading = useSelector(selectIsLiveStreamLoading);
  const actionRef = useRef<PromiseRef>();

  const resolveAction = () => {
    actionRef.current?.resolve();
    actionRef.current = undefined;
  };

  useEffect(() => {
    if (isLoading) return;
    resolveAction();
  }, [isLoading]);

  const handleOnLiveStreamingStarted = (
    ev: DailyEventObjectLiveStreamingStarted,
  ) => {
    onLiveStreamStarted?.(ev);
    dispatch(setIsLiveStreamLoading(false));
  };

  const handleOnLiveStreamingStopped = (
    ev: DailyEventObjectLiveStreamingStopped,
  ) => {
    onLiveStreamStopped?.(ev);
    dispatch(setIsLiveStreamLoading(false));
  };

  const handleOnLiveStreamingError = () => {
    if (actionRef.current) {
      actionRef.current.reject();
      actionRef.current = undefined;
    }

    dispatch(setIsLiveStreamLoading(false));
  };
  const {
    isLiveStreaming,
    updateLiveStreaming,
    startLiveStreaming,
    stopLiveStreaming,
  } = useLiveStreaming({
    onLiveStreamingError: handleOnLiveStreamingError,
    onLiveStreamingStarted: handleOnLiveStreamingStarted,
    onLiveStreamingStopped: handleOnLiveStreamingStopped,
  });

  const startStreaming = useCallback(
    ({ serverUrl, streamKey }: StartStreaming) =>
      new Promise((resolve, reject) => {
        actionRef.current = { resolve, reject };
        startLiveStreaming({
          rtmpUrl: `${serverUrl}/${streamKey}`,
        });

        dispatch(setIsLiveStreamLoading(true));
      }),
    [dispatch, startLiveStreaming],
  );

  const stopStreaming = useCallback(
    () =>
      new Promise((resolve, reject) => {
        actionRef.current = { resolve, reject };

        stopLiveStreaming();

        dispatch(setIsLiveStreamLoading(true));
      }),
    [dispatch, stopLiveStreaming],
  );

  const updateStreaming = useCallback(
    async (request: UpdateStreamingRequest) => {
      logger.info(
        `[liveStreaming] layout change: ${JSON.stringify(
          request,
          undefined,
          4,
        )}`,
      );
      switch (request.mode) {
        case "grid": {
          const layout = gridLayoutConfig(request.streamIds);
          updateLiveStreaming(layout);
          break;
        }
        case "focus": {
          const layout = focusLayoutConfig({
            streamIds: request.streamIds,
            preferredParticipant: request.preferredParticipant,
            preferScreenShare: request.preferScreenShare,
          });
          updateLiveStreaming(layout);
          break;
        }
        default: {
          break;
        }
      }
    },
    [updateLiveStreaming],
  );

  return {
    isLiveStreaming,
    updateStreaming,
    startStreaming,
    stopStreaming,
  };
};
