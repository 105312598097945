import { getVar } from "config";
import { getAudioContext } from "modules/audioVideo/context";

export const getWebsocketEndpoint = (
  baseUrl: string,
  eventId: string,
  token: string,
  isSpeaker: boolean,
): string => {
  if (isSpeaker) {
    return `${baseUrl}/realtime/insights/${eventId}?access_token=${token}`;
  }
  return `${baseUrl}/subscribe/${eventId}?access_token=${token}`;
};

export const initProcessor = (
  audioTrack: MediaStreamTrack,
): {
  processor: ScriptProcessorNode;
  sampleRate: number | undefined;
  context: AudioContext;
} => {
  const stream = new MediaStream();
  stream.addTrack(audioTrack);

  const context = getAudioContext();
  const source = context.createMediaStreamSource(stream);
  const processor = context.createScriptProcessor(4096, 1, 1);
  const gainNode = context.createGain();

  source.connect(gainNode);
  gainNode.connect(processor);
  processor.connect(context.destination);

  const { sampleRate } = context;

  return { processor, sampleRate, context };
};

export const initWebSocket = (
  token: string,
  eventId: string,
  isSpeaker: boolean,
): WebSocket => {
  const basePath = getVar("REACT_APP_SYMBL_WEBSOCKET_BASE_PATH");
  const endpoint = getWebsocketEndpoint(basePath, eventId, token, isSpeaker);
  const newWebSocket = new WebSocket(endpoint);

  return newWebSocket;
};
