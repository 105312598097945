type IPerformanceLogger = {
  logs: {
    [key: string]: number;
  };
  start(key: string, id?: string): void;
  end(key: string, id?: string): number | null;
};

// id is helpful here for creating multiple timed actions
// with the same action name that could exist concurrently
const PerformanceLogger: IPerformanceLogger = {
  logs: {},
  start: (key: string, id?: string) => {
    if (id) {
      const newKey = key + id;

      PerformanceLogger.logs[newKey] = performance.now();
    } else {
      PerformanceLogger.logs[key] = performance.now();
    }
  },
  end: (key: string, id?: string) => {
    if (!id) {
      const diff = PerformanceLogger.logs[key]
        ? performance.now() - PerformanceLogger.logs[key]
        : null;

      delete PerformanceLogger.logs[key];

      return diff;
    }
    const newKey = key + id;
    const diff = PerformanceLogger.logs[newKey]
      ? performance.now() - PerformanceLogger.logs[newKey]
      : null;

    delete PerformanceLogger.logs[newKey];

    return diff;
  },
};

export default PerformanceLogger;
