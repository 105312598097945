import { ActivityStatus } from "./types";

export const ACTIVITY_STATUS_REF = "ActivityStatusUsers";
export const ACTIVITY_STATUS_EVENTS_REF = "ActivityStatusEvents";

export const ActivityStatusColors = {
  [ActivityStatus.ACTIVE]: "#00613F",
  [ActivityStatus.AWAY]: "#FFAE34",
  [ActivityStatus.FOCUSING]: "#CB1901",
};
