import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export interface EventMemberState {
  eventMember: Record<string, string[]>;
}

export const initialState: EventMemberState = {
  eventMember: {},
};

const eventMemberSlice = createSlice({
  name: "eventMember",
  initialState,
  reducers: {
    setNewEventMember: (
      state,
      action: PayloadAction<Record<string, string[]>>,
    ) => {
      state.eventMember = { ...state.eventMember, ...action.payload };
    },
    resetEventMember: (state) => {
      state.eventMember = {};
    },
  },
});

export const { setNewEventMember, resetEventMember } = eventMemberSlice.actions;

export default eventMemberSlice.reducer;
