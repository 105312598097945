import { selectUserId } from "modules/auth/redux/selectors";
import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "store/index";

const selectBroadcast = (state: RootState) => state.broadcast;

const selectIsBroadcasting = createSelector(
  selectBroadcast,
  (broadcast) => broadcast.isBroadcasting,
);

const selectIsInBroadcast = createSelector(
  selectBroadcast,
  (broadcast) => broadcast.isInBroadcast,
);

const selectStartRecording = createSelector(
  selectBroadcast,
  (broadcast) => broadcast.recordOnEntering,
);

const selectBroadcastStatus = createSelector(
  selectBroadcast,
  (broadcast) => broadcast.broadcastStatus,
);

const selectDelay = createSelector(
  selectBroadcast,
  (broadcast) => broadcast.delay,
);

const selectStartedAt = createSelector(
  selectBroadcast,
  (broadcast) => broadcast.startedAt,
);

const selectStartedBy = createSelector(
  selectBroadcast,
  (broadcast) => broadcast.startedBy,
);

const selectIsTurnOnMicCamWhenStart = createSelector(
  selectBroadcast,
  (broadcast) => broadcast.isTurnOnMicCamWhenStart,
);

const selectBroadcastTransitionInProgress = createSelector(
  selectBroadcast,
  (broadcast) => broadcast.broadcastTransitionInProgress,
);

const selectIsMeStartBroadcast = createSelector(
  selectStartedBy,
  selectUserId,
  (startedBy, userId) => startedBy && userId && startedBy === userId,
);

const selectVideoURL = createSelector(
  selectBroadcast,
  (broadcast) => broadcast.videoURL,
);

const selectVideoShareTime = createSelector(
  selectBroadcast,
  (broadcast) => broadcast.videoShareTime,
);

const selectIsVideoControlsEnabled = createSelector(
  selectBroadcast,
  (broadcast) => broadcast.isVideoControlsEnabled,
);

const selectBroadcastStreams = createSelector(
  selectBroadcast,
  (broadcast) => broadcast.streams,
);

export {
  selectBroadcast,
  selectIsBroadcasting,
  selectIsInBroadcast,
  selectBroadcastStatus,
  selectDelay,
  selectStartedAt,
  selectStartedBy,
  selectIsTurnOnMicCamWhenStart,
  selectBroadcastTransitionInProgress,
  selectIsMeStartBroadcast,
  selectVideoURL,
  selectVideoShareTime,
  selectIsVideoControlsEnabled,
  selectBroadcastStreams,
  selectStartRecording,
};
