import * as Immutable from "immutable";
import { IUser } from "modules/app/types";
import { getUserName } from "modules/userProfile";
import { IChannel, IChat } from "./types";
import { CHAT_MESSAGE_TYPE, CHAT_TYPE } from "./redux/constants";

/**
 * Creates a channel ID based on to and from user
 * Assuming last 5 characters of user ID will be different for each mongoID and then sort the user ids and concat to channel ID
 * @param toUserId User to user
 * @param fromUserId User from user
 * @returns {string}
 */
export const genChannelId = (toUserId: string, fromUserId: string): string => {
  const uids = [toUserId.slice(-5), fromUserId.slice(-5)].sort();

  return uids.join("");
};

export const buildChannel = (
  toUser: { id: string },
  fromUserId?: string,
): IChannel | undefined => {
  // If any data is missing dont build the channel
  if (!toUser || !fromUserId) {
    return;
  }

  // eslint-disable-next-line consistent-return
  return {
    id: genChannelId(toUser.id, fromUserId),
    name: "",
    type: CHAT_TYPE.USER,
    userIds: [fromUserId, toUser.id],
    newChannel: true,
  };
};

export const getChannelName = (
  channel: IChannel,
  user: IUser | undefined,
  usersInSpace: Immutable.Map<string, IUser> | null,
): string => {
  if (channel.name) {
    return channel.name;
  }

  const channelUser = usersInSpace
    ? usersInSpace.find((chnlUser) => user?.id !== chnlUser?.id)
    : null;

  return channelUser && channelUser.profile
    ? getUserName(channelUser).replace(/,\s*$/, "")
    : "";
};

// eslint-disable-next-line max-params
export function getUpdatedUnreadCount(
  oldCountMap: Immutable.Map<string, number>,
  payload: {
    channel: string;
    messages?: IChat[];
    message?: IChat; // for delete
    sentByMe?: boolean;
  },
  activeChannel: string | null,
  isDelete: boolean,
  channel?: IChannel,
) {
  const roomId = payload && payload.channel;
  // If sent by me dont update the count
  // If sent to room currently i am in, dont update the count

  if (!channel || (!payload.messages && !payload.message)) {
    return oldCountMap;
  }

  let messages: IChat[] = [];

  if (payload.messages) {
    messages = payload.messages;
  } else if (payload.message) {
    messages = [payload.message];
  }

  let newCountMap = oldCountMap;

  messages.forEach((message) => {
    // No need to update count for action messages
    if (message.type !== CHAT_MESSAGE_TYPE) {
      if (
        isDelete &&
        channel.listensFrom &&
        message.time > channel.listensFrom
      ) {
        let oldNewMessageCount = newCountMap.get(roomId) || 0;

        // eslint-disable-next-line no-plusplus
        oldNewMessageCount--;

        newCountMap = newCountMap.set(roomId, Math.max(0, oldNewMessageCount));
      }

      if (!isDelete && roomId !== activeChannel && !payload.sentByMe) {
        let oldNewMessageCount = newCountMap.get(roomId) || 0;

        // eslint-disable-next-line no-plusplus
        oldNewMessageCount++;

        newCountMap = newCountMap.set(roomId, Math.max(0, oldNewMessageCount));
      }
    } else if (roomId !== activeChannel && !payload.sentByMe) {
      let oldNewMessageCount = newCountMap.get(roomId) || 0;

      // eslint-disable-next-line no-plusplus
      oldNewMessageCount++;

      newCountMap = newCountMap.set(roomId, oldNewMessageCount);
    }
  });

  return newCountMap;
}

export function getNewMessagesCount(newMessageCountMap: Array<number>) {
  return newMessageCountMap.reduce(
    (counter: number, channelCount: number) => counter + channelCount,
    0,
  );
}

export const getCropData = (
  chats: Immutable.Map<string, Immutable.List<IChat>>,
  channel: string,
  maxMessages: number,
  channels: Immutable.Map<string, IChannel>,
  // eslint-disable-next-line max-params
) => {
  if (!channel || !channels.get(channel)) {
    return {
      chats,
      channels,
    };
  }
  const slicedMessages = chats.updateIn(
    [channel],
    (arr = Immutable.List<IChat>()) => arr.slice(-maxMessages),
  );

  const oldestMessage = slicedMessages.get(channel)?.first();

  if (!oldestMessage) {
    return { chats, channels };
  }

  return {
    chats: slicedMessages,
    channels: channels.updateIn([channel], (chan: IChannel) => ({
      ...chan,
      stopPagination: false,
      paginateAfter: oldestMessage.id,
      listensFrom: oldestMessage.time,
    })),
  };
};

export const shouldRequestProfileInfo = (
  message: IChat,
  user: IUser,
  userInSpace: IUser,
) => {
  const amIReceiver = message.authorId !== user?.id;

  if (!amIReceiver) return false;

  const hasReceiverUpdatedProfile = Boolean(
    userInSpace.profile.headline && userInSpace.profile.company,
  );

  return !hasReceiverUpdatedProfile;
};

export function getUserIds(channel: IChannel) {
  if ("userIds" in channel && channel.userIds) return channel.userIds;

  if ("users" in channel && channel.users) return Object.keys(channel.users);

  return [];
}
