import { useState, MouseEvent, useEffect } from "react";
import screenfull from "screenfull";
import { IconButton } from "@remo-co/ui-core/src/components/IconButton";
import { Tooltip } from "@remo-co/ui-core/src/components/Tooltip";
import { useI18n } from "i18n";
import { ZoomOutMap as FullScreenIcon } from "@remo-co/ui-core/src/icons/ZoomOutMap";
import logger from "logging/logger";
import { FullScreenExitIcon } from "../../icons/FullScreenExitIcon";
import { useStyles } from "./styles";

interface Props {
  getElement: () => Element | null;
  onChange?: (isFullScreen: boolean) => void;
}

export const FullScreenButton = ({ getElement, onChange }: Props) => {
  const [isFullScreen, setIsFullScreen] = useState(false);
  const styles = useStyles();
  const { t } = useI18n();

  const toggleFullScreen = (e: MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();

    const element = getElement();

    // Show as full screen in window
    if (screenfull.isEnabled && element) {
      screenfull
        .toggle(element)
        // eslint-disable-next-line promise/prefer-await-to-then
        .catch((error) =>
          logger.error(`[FullscreenButton] error: ${error?.message}`),
        );
    }
  };

  useEffect(() => {
    const element = getElement();
    if (!element || !screenfull.isEnabled) return undefined;

    const onScreenfullChange = () => {
      const { isFullscreen } = screenfull;

      if (element) {
        const oldClassName = element.className;

        if (isFullscreen) {
          if (oldClassName.indexOf("fullscreen") === -1) {
            element.classList.add("fullscreen");
          }
        } else {
          element.classList.remove("fullscreen");
        }
      }

      setIsFullScreen(isFullscreen);
    };

    screenfull.on("change", onScreenfullChange);

    return () => {
      if (screenfull.isEnabled) {
        screenfull.off("change", onScreenfullChange);
      }
    };
  }, [getElement]);

  useEffect(() => {
    if (onChange) {
      onChange(isFullScreen);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFullScreen]);

  return (
    <div className={styles.fullScreenControl}>
      <Tooltip
        title={
          isFullScreen
            ? (t("exit.full.screen") as string)
            : (t("get.full.screen") as string)
        }
        placement="bottom-end"
      >
        <IconButton
          className={styles.fullScreenButton}
          onClick={toggleFullScreen}
          data-testid="fullscreen-button"
          size="large"
        >
          {isFullScreen ? (
            <FullScreenExitIcon data-testid="fullscreen-exit-icon" />
          ) : (
            <FullScreenIcon data-testid="fullscreen-icon" />
          )}
        </IconButton>
      </Tooltip>
    </div>
  );
};
