import { useState } from "react";
import { Dialog } from "@remo-co/ui-core/src/components/Dialog";
import { DialogContent } from "@remo-co/ui-core/src/components/DialogContent";
import { getMapImageWithTheme } from "modules/theater/theater.helper";
import { IMapTemplate } from "types/theater";
import { useEventTableSettingPresets } from "../../hooks/useEventTableSettingPresets";
import { useEventTableSettings } from "../../hooks/useEventTableSettings";
import { useUpdateEventTableSettings } from "../../hooks/useUpdateEventTableSettings";
import { AttendeeList } from "../AttendeeList";
import { TableManagerDialogHeader as DialogHeader } from "../TableManagerDialogHeader";
import { TableGrid } from "../TableGrid";
import { MiniMap } from "../MiniMap";
import useStyles from "./styles";
import { useEventTableSettingPresetOperations } from "../../hooks/useEventTableSettingPresetOperations";

interface Props {
  eventId: string;
  eventName?: string;
  floors: string[];
  floorNames: Record<string, string>;
  template: IMapTemplate;
  theme?: string;
  preselectedFloorId: string | null;
  onClose: () => void;
}

const TableManagerDialog = ({
  eventId,
  eventName,
  floors,
  floorNames,
  template,
  theme,
  preselectedFloorId,
  onClose,
}: Props): JSX.Element => {
  const [selectedPreset, setSelectedPreset] = useState("default");
  const { presets } = useEventTableSettingPresets(eventId);
  const { tableData, assignedAttendees } = useEventTableSettings(
    eventId,
    selectedPreset === "default" ? undefined : selectedPreset,
  );
  const {
    isLoading: operationsLoading,
    createPreset,
    deletePreset,
  } = useEventTableSettingPresetOperations(eventId, {
    onCreateSuccess: (incomingPreset: string) => {
      setSelectedPreset(incomingPreset);
    },
    onDeleteSuccess: () => {
      setSelectedPreset("default");
    },
  });
  const {
    addAttendeeToTable,
    moveAttendeeToTable,
    removeAttendeeFromTable,
    unassignAttendeesFromTable,
    unassignAttendeesFromFloor,
    unassignAttendeesFromEvent,
    isPending: isUpdateLoading,
    status: updateStatus,
  } = useUpdateEventTableSettings(
    eventId,
    selectedPreset === "default" ? undefined : selectedPreset,
  );
  const styles = useStyles();

  const [selectedFloorId, setFloorId] = useState(
    preselectedFloorId || floors[0],
  );
  const [hoveredTableCode, setHoveredTableCode] = useState<string | null>(null);

  return (
    <Dialog
      fullWidth
      open
      maxWidth={false}
      id="assign-tables-dialog"
      data-testid="table-manager-dialog"
      classes={{
        paper: styles.dialog,
      }}
    >
      <DialogHeader
        eventId={eventId}
        eventName={eventName}
        floors={floors}
        floorNames={floorNames}
        selectedFloorId={selectedFloorId}
        isUpdateLoading={isUpdateLoading}
        updateStatus={updateStatus}
        presets={presets}
        selectedPreset={selectedPreset}
        handlePresetChange={(preset) => setSelectedPreset(preset)}
        handleClose={onClose}
        handleFloorChange={setFloorId}
        unassignAttendeesFromFloor={unassignAttendeesFromFloor}
        unassignAttendeesFromEvent={unassignAttendeesFromEvent}
        operationsLoading={operationsLoading}
        createPreset={createPreset}
        deletePreset={deletePreset}
        assignedAttendeesCount={assignedAttendees.length}
      />
      <DialogContent className={styles.content}>
        <div className={styles.leftPanel}>
          <AttendeeList
            eventId={eventId}
            assignedAttendees={assignedAttendees}
            removeAttendeeFromTable={removeAttendeeFromTable}
          />
          <MiniMap
            selectedTableCode={hoveredTableCode}
            tables={template.rooms}
            mapImage={getMapImageWithTheme(template, theme)}
          />
        </div>
        <TableGrid
          selectedFloorId={selectedFloorId}
          tableData={tableData}
          eventId={eventId}
          hasMultipleFloors={floors.length > 1}
          templateTables={template.rooms}
          selectedPreset={selectedPreset}
          setHoveredTableCode={setHoveredTableCode}
          addAttendeeToTable={addAttendeeToTable}
          moveAttendeeToTable={moveAttendeeToTable}
          unassignAttendeesFromTable={unassignAttendeesFromTable}
        />
      </DialogContent>
    </Dialog>
  );
};

export default TableManagerDialog;
