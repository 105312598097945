import { IRoom } from "types";
import { useSelector } from "react-redux";
import {
  MAP_NAVIGATION_LOCATE_USER_ZOOM_LEVEL,
  MAP_NAVIGATION_ZOOMING,
  MAP_NAVIGATION_ZOOMING_VARIANT,
  SEAT_BORDER_WIDTH,
  SEAT_RADIUS,
} from "modules/event";
import { selectTheaterTemplate } from "modules/theater/selectors";
import { Transform } from "panzoom";
import { getUserRoomStatus } from "helpers/roomHelper";
// eslint-disable-next-line you-dont-need-lodash-underscore/is-string
import { isString } from "lodash";
import {
  IMapNavigationCoordinatesState,
  IMapNavigationFocus,
  IMapNavigationZoomingState,
  defaultFocusType,
} from "../redux/mapNavigationSlice";

const useMapNavigation = () => {
  const spaceConfig = useSelector(selectTheaterTemplate);

  /**
   * Get x & y positions for focus, depending on the zoom level (ratio)
   * @param yPosition current x position of the room
   * @param width room's width
   * @param zoom target zoom level
   * @returns new x position
   */
  const getCoordinatesForFocus = (
    room: IRoom,
    position: number,
    focus: IMapNavigationFocus,
  ) => {
    const zoom = MAP_NAVIGATION_LOCATE_USER_ZOOM_LEVEL;
    const borderWidth = SEAT_BORDER_WIDTH;

    const roomPosition = room.positions[position];
    const coordinates: Pick<
      IMapNavigationCoordinatesState,
      "x" | "y" | "zoom"
    > = { x: null, y: null, zoom };

    if (isString(spaceConfig)) {
      return coordinates;
    }
    const radius = spaceConfig?.seatRadius ?? SEAT_RADIUS;
    const avatarSizePx = (radius + borderWidth) * 2;

    if (focus && focus.type === "user") {
      coordinates.x =
        -1 *
        ((room.x + roomPosition.x) * zoom -
          (window.innerWidth - avatarSizePx * zoom) / 2);
      coordinates.y =
        -1 *
        ((room.y + roomPosition.y) * zoom -
          (window.innerHeight - avatarSizePx * zoom) / 2);
    }

    if (focus && focus.type === "room") {
      coordinates.x =
        -1 * (room.x * zoom - (window.innerWidth - room.width * zoom) / 2);
      coordinates.y =
        -1 * (room.y * zoom - (window.innerHeight - room.height * zoom) / 2);
    }

    return coordinates;
  };

  /**
   * Get coordinates to move to, depending on the user action (regardless of zoom in or zoom out)
   * @param transform Transform object to retrieve the current position and scale details of the PanZoom library
   * @param currentZoom current zoom level (eg: 1.4)
   * @param nextZoom target zoom level (eg: 1.7 or 1.1)
   * @returns x, y and zoom level (coordinates for the next position / zoom level)
   */
  const getCoordinatesForZoom = (
    transform: Transform,
    currentZoom: number,
    nextZoom: number,
  ) => {
    // Calculate current
    const xAtCurrentZoom = transform.x;
    const yAtCurrentZoom = transform.y;
    const currentMidpointX = xAtCurrentZoom - window.innerWidth / 2;
    const currentMidpointY = yAtCurrentZoom - window.innerHeight / 2;

    // Calculate next
    const x =
      (currentMidpointX / currentZoom) * nextZoom + window.innerWidth / 2;
    const y =
      (currentMidpointY / currentZoom) * nextZoom + window.innerHeight / 2;

    const coordinates: IMapNavigationZoomingState = { x, y, zoom: nextZoom };

    return coordinates;
  };

  /**
   * Get user's coordinates for navigating the map to that point
   *
   * Note: We may want to center on the user if the table is too large
   * Currently we have small tables, so show where the user is placed along with other guests (navigate to center of the table)
   * @param userId user's ID
   * @param room Room object which has position and size details
   * @returns x,y coordinates with the zoom intended zoom level
   */
  const getCoordinates = (
    userId: string,
    room: IRoom | null = null,
  ): IMapNavigationCoordinatesState => {
    let coordinates: IMapNavigationCoordinatesState = {
      x: null,
      y: null,
      zoom: null,
      // Focus / Center on: 'user' (currently supporting 'user' and 'room')
      focus: defaultFocusType,
      // Show a highlight effect (currently supporting only 'user')
      highlight: { type: "user", id: userId },
    };

    if (room !== null) {
      const currentUserRoomStatus = getUserRoomStatus(room?.id, userId);
      const { x, y, zoom } = getCoordinatesForFocus(
        room,
        currentUserRoomStatus?.position || 0,
        coordinates.focus || defaultFocusType,
      );

      coordinates = {
        ...coordinates,
        x,
        y,
        zoom,
      };
    }

    return coordinates;
  };

  /**
   * Get the next zooming value whether zooming in or out
   * @param index Zoom IN or Zoom OUT
   * @param currentZoom current zoom level (eg: 1.4)
   * @param zoomLimit if zooming in, this is the maxZoom limit, minZoom limit otherwise
   * @returns next zooming value
   * @example
   * Action: Zoom In
   * Current zoom level: 1.1
   * return next zoom level: 1.4 (1.1 + zooming variant which is 0.3 currently)
   */
  const getNextZoomValue = (
    index: number,
    currentZoom: number,
    zoomLimit: number,
  ) =>
    index === MAP_NAVIGATION_ZOOMING.IN
      ? Math.min(currentZoom + MAP_NAVIGATION_ZOOMING_VARIANT, zoomLimit)
      : Math.max(currentZoom - MAP_NAVIGATION_ZOOMING_VARIANT, zoomLimit);

  return {
    getCoordinates,
    getCoordinatesForZoom,
    getNextZoomValue,
  };
};

export default useMapNavigation;
