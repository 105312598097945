import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export interface StreamingReducerState {
  connectionAttempts: number;
  micOnDuration: number;
}

export const streamingReducerInitialState: StreamingReducerState = {
  connectionAttempts: 0,
  micOnDuration: 0,
};

export const streamingSlice = createSlice({
  name: "streaming",
  initialState: streamingReducerInitialState,
  reducers: {
    incrementConnectionAttempts: (state) => {
      state.connectionAttempts += 1;
    },
    increaseMicOnDurationBy: (state, action: PayloadAction<number>) => {
      state.micOnDuration += action.payload;
    },
    resetMicOnDuration: (state) => {
      state.micOnDuration = 0;
    },
    resetStreaming: () => streamingReducerInitialState,
  },
});

export const {
  incrementConnectionAttempts,
  resetStreaming,
  increaseMicOnDurationBy,
  resetMicOnDuration,
} = streamingSlice.actions;

export default streamingSlice.reducer;
