import {
  EventPoll,
  GetEventPollsQuery,
  OnPollChangedSubscription,
  OnPollDeletedSubscription,
} from "graphql/generated";

export const updatePoll = (
  currentPolls: GetEventPollsQuery,
  pollToUpdateIndex: number,
  updatedPoll: EventPoll,
) => {
  if (currentPolls.eventPolls.__typename === "GQLError") {
    return currentPolls;
  }

  const newPolls = [...currentPolls.eventPolls.polls];

  newPolls[pollToUpdateIndex] = {
    ...currentPolls.eventPolls.polls[pollToUpdateIndex],
    ...updatedPoll,
  };

  const { selectedAnswer } = newPolls[pollToUpdateIndex];

  if (selectedAnswer) {
    if (
      !newPolls[pollToUpdateIndex].options.some(
        (option) => option.id === selectedAnswer,
      )
    ) {
      newPolls[pollToUpdateIndex] = {
        ...newPolls[pollToUpdateIndex],
        selectedAnswer: undefined,
      };
    }
  }

  return {
    ...currentPolls,
    eventPolls: {
      ...currentPolls.eventPolls,
      polls: newPolls,
    },
  };
};

const createPoll = (currentPolls: GetEventPollsQuery, newPoll: EventPoll) => {
  if (currentPolls.eventPolls.__typename === "GQLError") {
    return currentPolls;
  }

  return {
    ...currentPolls,
    eventPolls: {
      ...currentPolls.eventPolls,
      polls: [...currentPolls.eventPolls.polls, newPoll],
    },
  };
};

const deletePoll = (
  currentPolls: GetEventPollsQuery,
  pollToDelete: OnPollDeletedSubscription,
) => {
  if (currentPolls.eventPolls.__typename === "GQLError") {
    return currentPolls;
  }

  if (pollToDelete.pollDeleted?.__typename !== "EventPollDelete") {
    return currentPolls;
  }

  const pollId = pollToDelete.pollDeleted.id;

  return {
    ...currentPolls,
    eventPolls: {
      ...currentPolls.eventPolls,
      polls: currentPolls.eventPolls.polls.filter((poll) => poll.id !== pollId),
    },
  };
};

export const updateEventPoll = (
  prev: GetEventPollsQuery,
  {
    subscriptionData,
  }: {
    subscriptionData: {
      data: OnPollChangedSubscription | OnPollDeletedSubscription | undefined;
    };
  },
) => {
  const currentPolls = { ...prev };
  if (!subscriptionData.data) return prev;
  if ("pollChanged" in subscriptionData.data) {
    const newPoll = subscriptionData.data.pollChanged;

    if (!newPoll) {
      return prev;
    }

    if (currentPolls.eventPolls.__typename === "GQLError") {
      return prev;
    }

    if (newPoll.__typename === "GQLError") {
      return prev;
    }

    const pollToUpdateIndex = currentPolls.eventPolls.polls.findIndex(
      (poll) => poll.id === newPoll.id,
    );

    if (pollToUpdateIndex > -1) {
      return updatePoll(currentPolls, pollToUpdateIndex, newPoll);
    }

    return createPoll(currentPolls, newPoll);
  }

  if ("pollDeleted" in subscriptionData.data) {
    return deletePoll(currentPolls, subscriptionData.data);
  }

  return currentPolls;
};
