import { DataSnapshot, off, onValue } from "firebase/database";
import { serverValue } from "services/firebaseService/firebaseConfig";
import { useCallback, useContext } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "store/hooks";
import { getCurrentClientTime } from "helpers/firebaseTimeHelper";
import { getEmbeddedVideoURL } from "helpers/videoHelper";
import { selectCurrentTheaterId } from "modules/theater/selectors";
import {
  selectVideoURL,
  selectIsVideoControlsEnabled,
} from "modules/broadcast/redux/selectors";
import { EVENT_AGENDA_CONTEXT } from "modules/eventAgenda/context/EventAgendaContext";
import { selectUserId } from "modules/auth/redux/selectors";
import broadcastVideoFirebase from "../../broadcastVideo.firebase";
import actions from "../../redux/actions";

interface Props {
  updateVideoURL(videoUrl: string | null): void;
  updateIsVideoControlsEnabled(isEnabled: boolean): void;
  subscribe(): void;
  unsubscribe(): void;
  reset?(): void;
}

export const useBroadcastVideoActions = (): Props => {
  const dispatch = useAppDispatch();
  const theaterId = useSelector(selectCurrentTheaterId);
  const { eventData } = useContext(EVENT_AGENDA_CONTEXT);
  const userId = useSelector(selectUserId);
  const reduxVideoURL = useSelector(selectVideoURL);
  const reduxIsVideoControlsEnabled = useSelector(selectIsVideoControlsEnabled);

  const eventTheaterId = eventData?.theaters?.[0]?.id;

  const updateVideoURL = useCallback(
    async (videoURL: string) => {
      if (!userId || !theaterId) {
        return;
      }

      await broadcastVideoFirebase.updateBroadcastVideoURL(theaterId, {
        videoURL,
        startTime: serverValue.TIMESTAMP,
        userId,
        isVideoControlsEnabled: !!reduxIsVideoControlsEnabled,
      });
    },
    [userId, theaterId, reduxIsVideoControlsEnabled],
  );

  const updateIsVideoControlsEnabled = useCallback(
    async (isEnabled: boolean) => {
      // handles cases where current theater is null, such as when updating
      const theaterIdToUpdate = theaterId || eventTheaterId;

      if (!userId || !theaterIdToUpdate) {
        return;
      }

      await broadcastVideoFirebase.updateIsVideoControlsEnabled(
        theaterIdToUpdate,
        {
          videoURL: reduxVideoURL,
          startTime: serverValue.TIMESTAMP,
          userId,
          isVideoControlsEnabled: isEnabled,
        },
      );
      dispatch(actions.updateIsVideoControlsEnabled(isEnabled));
    },
    [theaterId, eventTheaterId, userId, reduxVideoURL, dispatch],
  );

  const subscribe = useCallback(() => {
    if (!theaterId) {
      return;
    }

    const docRef = broadcastVideoFirebase.getBroadcastVideoURLDocRef(theaterId);

    off(docRef);
    onValue(docRef, async (snapshot: DataSnapshot) => {
      const val = snapshot.val();

      if (val) {
        const { videoURL, startTime, userId: id, isVideoControlsEnabled } = val;
        const newVideoURL = getEmbeddedVideoURL(
          videoURL,
          true,
          !isVideoControlsEnabled,
        );

        if (id !== userId || reduxVideoURL == null) {
          const clientTime = await getCurrentClientTime();
          const timeElapsed = Math.ceil((clientTime - startTime) / 1000);
          let syncVideoUrl = newVideoURL;

          if (
            newVideoURL?.includes("youtube") &&
            !newVideoURL.includes("start=")
          ) {
            // If not a live YT stream add start param
            if (!newVideoURL?.includes("isLive")) {
              syncVideoUrl += `&start=${timeElapsed}`;
            }
          } else if (
            newVideoURL?.includes("vimeo") &&
            !newVideoURL.includes("#t=")
          ) {
            syncVideoUrl += `#t=${timeElapsed}`;
          } else if (
            newVideoURL?.includes("twitch") &&
            !newVideoURL.includes("&t=")
          ) {
            const minutes = Math.floor(timeElapsed / 60);
            const seconds = timeElapsed % 60;

            syncVideoUrl += `&t=${minutes}m${seconds}s`;
          }
          dispatch(actions.updateVideoShareTime(startTime));
          dispatch(actions.updateVideoURL(syncVideoUrl));

          dispatch(
            actions.updateIsVideoControlsEnabled(val.isVideoControlsEnabled),
          );
        } else {
          dispatch(actions.updateVideoShareTime(startTime));
          dispatch(actions.updateVideoURL(newVideoURL));
        }
      }
    });
  }, [dispatch, reduxVideoURL, theaterId, userId]);

  const unsubscribe = useCallback(() => {
    if (!theaterId) {
      return;
    }

    const docRef = broadcastVideoFirebase.getBroadcastVideoURLDocRef(theaterId);
    off(docRef);
  }, [theaterId]);

  return {
    updateVideoURL,
    updateIsVideoControlsEnabled,
    subscribe,
    unsubscribe,
  };
};
