import { useI18n } from "i18n";
import { Typography } from "@remo-co/ui-core/src/components/Typography";
import { Tooltip } from "@remo-co/ui-core/src/components/Tooltip";
import { Button } from "@remo-co/ui-core/src/components/Button";
import { Add } from "@remo-co/ui-core/src/icons/Add";
import RegistrationSVG from "../../../assets/registration.svg";
import { useStyles } from "./styles";

interface Props {
  eventId?: string;
  onCreateClick: () => void;
}

export const EmptyState = ({ eventId, onCreateClick }: Props) => {
  const { t } = useI18n(["common", "eventForm"]);
  const styles = useStyles();

  return (
    <div className={styles.container}>
      <img src={RegistrationSVG} alt={t("audience.view.toggle")} />
      <Typography variant="body1" className={styles.text}>
        {t("eventForm:custom.question.empty.state")}
      </Typography>
      <Tooltip
        placement="top"
        title={
          !eventId
            ? t("manageEvent:menu.item.disable.text", {
                name: t("eventForm:event.content"),
              })
            : ""
        }
      >
        <span>
          <Button
            variant="secondary"
            color="blue"
            size="sm"
            className={styles.button}
            disabled={!eventId}
            contentClassName={styles.content}
            data-testid="create-question-button-empty-state"
            onClick={onCreateClick}
          >
            <Add className={styles.icon} />
            {t("eventForm:add.question")}
          </Button>
        </span>
      </Tooltip>
    </div>
  );
};
