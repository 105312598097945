import {
  DataSnapshot,
  get,
  off,
  onChildAdded,
  onChildChanged,
  onChildRemoved,
  ref,
} from "firebase/database";
import store from "store";
import { SPACE_PATH } from "services/firebaseService/storagePaths";
import logger from "../../logging/logger";
import { database } from "../../services/firebaseService/firebaseConfig";
import { getCurrentSpaceId } from "../../helpers/reduxHelper";
import { removeUserFromRoom, updateUserRoomStatus } from "./redux/roomSlice";
import { IUserRoomStatus } from "./userRoomStatus";

export const USER_ROOM_STATUS_PATH = "UserRoomStatus";

export const unsubscribeUserRoomStatus = () => {
  const spaceId = getCurrentSpaceId();
  const dbRef = ref(
    database,
    `${SPACE_PATH}/${spaceId}/${USER_ROOM_STATUS_PATH}`,
  );

  off(dbRef);
  logger.info(
    `[unsubscribeUserRoomStatus] unsubscribed from room statuses of all rooms in current space (${spaceId})`,
  );
};

type UserStatusResult = {
  key: string | null;
  val: () => Record<string, IUserRoomStatus> | null;
};

export const syncUserRoomStatus = () => {
  const spaceId = getCurrentSpaceId();
  const dbRef = ref(
    database,
    `${SPACE_PATH}/${spaceId}/${USER_ROOM_STATUS_PATH}`,
  );

  off(dbRef);

  const handler = async (userStatusResult: UserStatusResult) => {
    if (userStatusResult && userStatusResult.key) {
      const users = userStatusResult.val() ?? {};

      store.dispatch(
        updateUserRoomStatus({
          roomId: userStatusResult.key,
          status: users,
        }),
      );
    }
  };

  // Listen for changes only after getting all values
  onChildChanged(dbRef, handler);
  onChildAdded(dbRef, handler);
  onChildRemoved(dbRef, async (userStatusResult: DataSnapshot) => {
    const { key = null } = userStatusResult;
    const val = userStatusResult.val();
    const users = Object.keys(val);

    users?.forEach((userId) => {
      if (key) {
        store.dispatch(removeUserFromRoom({ roomId: key, userId }));
      } else {
        logger.warn("[userRoom] Unknown room", {
          roomId: userStatusResult.key,
          userId,
        });
      }
    });
  });
};

export const fetchTableIdForUserInSpace = async (
  floorId: string,
  userId: string,
): Promise<string | null> => {
  const dbRef = ref(
    database,
    `${SPACE_PATH}/${floorId}/${USER_ROOM_STATUS_PATH}`,
  );

  const floorTablesStatus = (await get(dbRef)).val() as Record<
    string,
    Record<string, unknown>
  >;

  if (!floorTablesStatus) {
    return null;
  }

  const entry = Object.entries(floorTablesStatus).find(([_, userMap]) =>
    Object.keys(userMap).includes(userId),
  );

  if (!entry) {
    return null;
  }

  return entry[0];
};
