import React from "react";
import { useCompanyPlanSettings } from "modules/companyPlanSettings";
import { isTypeOfIMapTemplate } from "modules/event/template";
import { IMapTemplate } from "types/theater";

const useFloorsUtils = (
  capacity: number | undefined,
  template: IMapTemplate | string | null,
) => {
  const { getMaxAllowedInSpace } = useCompanyPlanSettings();

  const noOfFloors = React.useMemo(() => {
    if (!template || !capacity) {
      return 0;
    }

    if (!isTypeOfIMapTemplate(template)) {
      return 1;
    }

    const floors = Math.ceil(capacity / getMaxAllowedInSpace(template));

    return Number.isFinite(floors) ? floors : 0;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [capacity, template]);

  return {
    noOfFloors,
  };
};

export default useFloorsUtils;
