import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { IUserRoomStatus } from "../userRoomStatus";

export interface IUserRoomState {
  [key: string]: Record<string, IUserRoomStatus>;
}

export const initialState: IUserRoomState = {};

const roomSlice = createSlice({
  name: "UserRoomStatus",
  initialState,
  reducers: {
    updateUserRoomStatus: (
      state: IUserRoomState,
      action: PayloadAction<{
        roomId: string;
        status: Record<string, IUserRoomStatus>;
      }>,
    ) => {
      state[action.payload.roomId] = action.payload.status;
    },
    removeUserFromRoom: (
      state: IUserRoomState,
      action: PayloadAction<{ roomId: string; userId: string }>,
    ) => {
      const {
        payload: { roomId, userId },
      } = action;

      delete state?.[roomId]?.[userId];
    },
    resetUserRoomStatus: () => initialState,
  },
});

export const { updateUserRoomStatus, removeUserFromRoom, resetUserRoomStatus } =
  roomSlice.actions;

export default roomSlice.reducer;
