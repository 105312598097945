import {
  USER_PATH,
  VIRTUAL_BACKGROUND,
} from "services/firebaseService/storagePaths";
import { firestore } from "services/firebaseService/firebaseConfig";
import { collection, doc } from "firebase/firestore";

const collectionName = "UserVirtualCameraSettings";
const backgroundCollectionName = "VirtualBackgrounds";

const getUserVideoBackgroundSettingsCollection = (userId: string) =>
  doc(collection(firestore, collectionName), userId);

const getUserVideoBackgroundCollection = (userId: string) =>
  collection(
    getUserVideoBackgroundSettingsCollection(userId),
    backgroundCollectionName,
  );

const getNewDocId = (userId: string) =>
  doc(getUserVideoBackgroundCollection(userId)).id;

const createUserFilePath = (userId: string, filename: string) =>
  `${USER_PATH}/${VIRTUAL_BACKGROUND}/${userId}/${filename}`;

export default {
  getNewDocId,
  createUserFilePath,
  getUserVideoBackgroundCollection,
};
