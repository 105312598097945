import { createContext } from "react";
import { ITranscriptionContext, ITranscriptionState } from "./context/types";

export const initialState: ITranscriptionState = {
  token: null,
  websocket: { current: undefined },
  processor: null,
  transcript: null,
  finalizedTranscript: null,
  dispatch: undefined,
  context: null,
  sampleRate: 16000,
};

export const TranscriptionContext = createContext<ITranscriptionContext>({
  ...initialState,
});
