export const Chevron = () => (
  <svg
    width={12}
    height={7}
    viewBox="0 0 12 7"
    fill="currentColor"
    className="chevron-icon"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.98 6.34l4.93-4.93c.24-.24.24-.62 0-.86l-.36-.36a.604.604 0 00-.86 0L5.55 4.32 1.4.18a.604.604 0 00-.86 0L.18.54c-.24.24-.24.62 0 .86l4.94 4.94c.23.23.62.23.86 0z"
      // fill='#0E1F3C'
    />
  </svg>
);
