import { Theme } from "@remo-co/ui-core/src/types";
import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";

export const useStyles = makeStyles<Theme>((theme) => ({
  list: {
    margin: 0,
    paddingLeft: theme.spacing(3.125),
  },
  seeMore: {
    cursor: "pointer",
    textDecorationLine: "underline",
  },
}));
