import useCompany from "modules/company/hooks/useCompany";
import {
  ReactNode,
  createContext,
  useState,
  useEffect,
  useCallback,
} from "react";
import { useLocation } from "react-router";
import { useSelector } from "react-redux";
import { Userpilot } from "userpilot";
import { getChargebeeSubscriptionDetail } from "services/apiService/apis";
import { selectUser } from "modules/auth/redux/selectors";
import logger from "logging/logger";
import { IChargebeeSubscriptionResponse } from "modules/settings/types";
import { getVar } from "config";
import { ICompany, ICompanyServicePlan } from "modules/company/types";
import { IUser } from "modules/app/types";
import { getUserName } from "modules/userProfile";
import useEventSplitTreatment from "modules/split/useEventSplitTreatment";
import { EventFeatures } from "services/splitService/features";

export interface IUserpilotContext {
  isIdentified: boolean;
  isInitialized: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  chargebeeInfo: any;
  companyInfo: unknown;
  companyPlanInfo: ICompany;
  updateUserpilotIdentity: (userData?: IUser) => void;
  trackUserpilotEvent: (event: string, data?: Record<string, unknown>) => void;
  children?: ReactNode;
}

export interface IUserpilotContextProviderProps {
  children?: ReactNode;
}

const initialState = {
  isIdentified: false,
  isInitialized: false,
  chargebeeInfo: null,
  companyInfo: null,
  companyPlanInfo: null,
  updateUserpilotIdentity: (): void => {},
  trackUserpilotEvent: (): void => {},
} as unknown as IUserpilotContext;

export const USERPILOT_CONTEXT = createContext<IUserpilotContext>(initialState);

const UserpilotContextProvider = ({
  children,
}: IUserpilotContextProviderProps) => {
  const { featureEnabled: userpilotEventIntakeEnabled } =
    useEventSplitTreatment(EventFeatures.USERPILOT);
  const [isIdentified, setIsIdentified] = useState(false);
  const [isInitialized, setIsInitialized] = useState(false);
  const [companyPlanInfo, setCompanyPlanInfo] =
    useState<ICompanyServicePlan | null>(null);
  const [fetchingCompanyPlanInfo, setFetchingCompanyPlanInfo] = useState(false);
  const [chargebeeInfo, setChargebeeInfo] =
    useState<Partial<IChargebeeSubscriptionResponse> | null>(null);
  const [fetchingChargebeeInfo, setFetchingChargebeeInfo] = useState(false);
  const location = useLocation();
  const { company, getCompanyPlanData } = useCompany();
  const user = useSelector(selectUser);
  const isHost = !!company;

  /**
   * getCompanyInfo
   *
   * Loads and sets company plan info from the server using the company details
   * Also loads and sets chargebee info from chargebee
   *
   * @returns void
   */
  const getCompanyInfo = useCallback(async () => {
    if (company && !companyPlanInfo && !fetchingCompanyPlanInfo) {
      setFetchingCompanyPlanInfo(true);
      try {
        const response = await getCompanyPlanData(company);

        if (response) {
          setCompanyPlanInfo(response);
        } else {
          logger.error(
            `[UserpilotContext][getCompanyInfo] unable to get company plan data ${company.id}`,
          );
        }
      } catch (error) {
        if (error instanceof Object) {
          logger.error(
            "[UserpilotContext][getCompanyInfo] getCompanyPlan error:",
            error,
          );
        }
      }
      setFetchingCompanyPlanInfo(false);
    }

    if (!chargebeeInfo && !fetchingChargebeeInfo) {
      setFetchingChargebeeInfo(true);
      try {
        const response: IChargebeeSubscriptionResponse =
          await getChargebeeSubscriptionDetail();

        if (response.isSuccess) {
          setChargebeeInfo({
            plan: response.plan,
            subscription: response.subscription,
            customer: response.customer,
          });
        } else {
          setChargebeeInfo({
            plan: {
              name: companyPlanInfo?.name || "N/A",
            },
          });
        }
      } catch (error) {
        if (error instanceof Object) {
          logger.error(
            "[UserpilotContext][getCompanyInfo] getChargebeeSubscriptionDetail error:",
            error,
          );
        }
      }

      setFetchingChargebeeInfo(false);
    }
  }, [
    companyPlanInfo,
    fetchingCompanyPlanInfo,
    chargebeeInfo,
    fetchingChargebeeInfo,
    company,
    getCompanyPlanData,
  ]);

  /**
   * Update a user's identity in userpilot
   *
   * The userData passed should contain an id for the user to update
   * @param {Object} userData JSON data to be updated on user
   */
  const updateUserpilotIdentity = useCallback(
    async (userData?: IUser) => {
      if (isInitialized && userData?.id) {
        Userpilot.identify(userData.id, userData);
      }
    },
    [isInitialized],
  );

  /**
   * Track an event in userpilot for identified user
   *
   * @param {String} event Same events as amplitude
   * @param {unknown} data Optional
   */
  const trackUserpilotEvent = (
    userpilotEvent: string,
    data?: Record<string, unknown>,
  ) => {
    if (isInitialized && isIdentified) {
      Userpilot.track(userpilotEvent, data);
    }
  };

  useEffect(() => {
    const appToken = getVar("REACT_APP_USERPILOT_APP_TOKEN");

    if (!isInitialized && isHost && appToken) {
      Userpilot.initialize(appToken);
      setIsInitialized(true);

      getCompanyInfo();
      return;
    }

    if (!isInitialized && !isHost && userpilotEventIntakeEnabled && appToken) {
      Userpilot.initialize(appToken);
      setIsInitialized(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isHost, isInitialized, userpilotEventIntakeEnabled, getCompanyInfo]);

  useEffect(() => {
    if (!isIdentified) {
      // chargebeeInfo and companyPlanInfo are only present when getCompanyInfo is ran
      if (user && isHost && company && chargebeeInfo && companyPlanInfo) {
        Userpilot.identify(user.id, {
          name: getUserName(user),
          email: user.email,
          created_at: (new Date(user.createdAt).getTime() / 1000).toFixed(0),
          plan: companyPlanInfo.name || "Trial",
          company: {
            id: company.id,
            name: company.name,
            created_at: (new Date(company.createdAt).getTime() / 1000).toFixed(
              0,
            ),
            plan: companyPlanInfo.name,
            chargebee_plan: chargebeeInfo?.plan?.name || "",
          },
        });
        setIsIdentified(true);
        return;
      }

      if (user && !isHost && userpilotEventIntakeEnabled) {
        Userpilot.identify(user.id, {
          name: getUserName(user),
          email: user.email,
          created_at: (new Date(user.createdAt).getTime() / 1000).toFixed(0),
        });
        setIsIdentified(true);
      }
    }
  }, [
    chargebeeInfo,
    company,
    companyPlanInfo,
    user,
    isHost,
    isIdentified,
    userpilotEventIntakeEnabled,
  ]);

  // Reload Userpilot on every path change
  useEffect(() => {
    if (isInitialized) {
      Userpilot.reload(location.pathname);
    }
  }, [location, isInitialized]);

  return (
    <USERPILOT_CONTEXT.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        ...initialState,
        updateUserpilotIdentity,
        trackUserpilotEvent,
      }}
    >
      {children}
    </USERPILOT_CONTEXT.Provider>
  );
};

export default UserpilotContextProvider;
