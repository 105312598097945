import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";

const useStyles = makeStyles((theme) => ({
  contentsContainer: {
    width: "auto",
    [theme.breakpoints.up("sm")]: {
      width: 480,
    },
  },
  defaultCursor: {
    cursor: "default",
  },
}));

export default useStyles;
