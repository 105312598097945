import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";

export const useStyles = makeStyles(() => ({
  roomWhiteboardContainer: {
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    pointerEvents: "none",
    zIndex: 4,
  },
  whiteboardContainer: {
    pointerEvents: "all",
  },
}));
