import { useContext, memo } from "react";
import { useHistory } from "react-router";
import useNotificationActions from "modules/notification/hooks/useNotificationActions";
import getEventLink from "modules/manageEvent/utils/getEventLink";
import { useI18n } from "i18n";
import { Box } from "@remo-co/ui-core/src/components/Box";
import { Button } from "@remo-co/ui-core/src/components/Button";
import { ChevronLeft } from "@remo-co/ui-core/src/icons/ChevronLeft";
import { useSelector } from "react-redux";
import { selectCurrentEventCode } from "modules/event/selectors";
import { MANAGE_EVENT_CONTEXT } from "modules/manageEvent";
import { BASIC_SETTINGS } from "../../constants";
import useManageEventPages from "../../hooks/useManageEventPages";
import { useStyles } from "./styles";

const ActionBar = memo(() => {
  const styles = useStyles();
  const { state, saveEvent, onTabChange } = useContext(MANAGE_EVENT_CONTEXT);

  const pageId = state?.pageId ?? undefined;
  const eventData = state?.eventData ?? undefined;
  const hideActionBar = state?.hideActionBar ?? false;
  const { getNextPageId } = useManageEventPages();
  const eventId = eventData && eventData.id;
  const isNewEvent = !eventId;
  const currentEventCode = useSelector(selectCurrentEventCode);
  const { addErrorNotification } = useNotificationActions();
  const { t } = useI18n(["common", "manageEvent"]);

  const history = useHistory();

  const onBack = () => {
    history.goBack();
  };

  const getNextPage = async () => {
    if (pageId === BASIC_SETTINGS.pageId) {
      const updatedEventData = await saveEvent();

      /**
       * If user is in event view page, just close the popup
       * if user is creating event, take him into event view page
       * if user is editing event from dashboard, close the popup and take to my events
       */
      if (updatedEventData) {
        // Adding timeout so that dirty form check state variable in context is reset
        // TODO: Remove this setTimeout and Prompt logic in ManageEventContext after react-router v6 upgrade
        setTimeout(() => {
          history.push(
            currentEventCode ? `/e/${currentEventCode}` : "/my-events",
          );
        }, 100);
      }
      return undefined;
    }

    return getNextPageId(
      pageId,
      !isNewEvent,
      Boolean(state?.sponsors?.length),
      state?.eventData?.isUnlimitedEvent,
    );
  };

  const onNext = async () => {
    if (pageId === BASIC_SETTINGS.pageId) {
      if (!eventData?.name) {
        addErrorNotification({
          message: t("manageEvent:event.title.invalid"),
        });
        return;
      }

      if (eventData?.name && eventData.name.length > 100) {
        // TODO - move event name length
        addErrorNotification({
          message: t("manageEvent:event.title.long"),
        });
        return;
      }
    }

    const nextPageId = await getNextPage();

    if (nextPageId) {
      onTabChange((isConfirmed?: boolean) => {
        if (isConfirmed) {
          history.push(getEventLink(nextPageId, eventId || ""));
        }
      });
    }
  };

  const isBackBtnDisabled = pageId === BASIC_SETTINGS.pageId;

  if (hideActionBar) {
    return null;
  }

  const getNextBtnText = () => {
    if (pageId === BASIC_SETTINGS.pageId) {
      return eventId
        ? t("manageEvent:save.event")
        : t("manageEvent:save.publish.event");
    }

    return t("manageEvent:move.next");
  };

  return (
    <Box className="me-actions" data-testid="manage-event-action-bar">
      <Button
        id="manage-event-back"
        data-testid={`${pageId}-back-btn`}
        variant="secondary"
        color="dark"
        onClick={onBack}
        startAdornment={<ChevronLeft />}
        contentClassName={styles.backButtonContent}
        startAdornmentClassName={styles.backButtonAdornment}
        size="md"
      >
        {isBackBtnDisabled ? t("button.cancel") : t("button.back")}
      </Button>

      <Button
        id="manage-event-next"
        data-testid={`${pageId}-submit-btn`}
        variant="primary"
        color="blue"
        onClick={onNext}
        size="md"
      >
        {getNextBtnText()}
      </Button>
    </Box>
  );
});

export default ActionBar;
