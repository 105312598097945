import {
  ICustomRegistrationQuestion,
  IQuestionOrder,
} from "modules/registration/types";
import { useDrop } from "react-dnd";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Button } from "@remo-co/ui-core/src/components/Button";
import { Box } from "@remo-co/ui-core/src/components/Box";
import { Typography } from "@remo-co/ui-core/src/components/Typography";
import { Add as AddIcon } from "@remo-co/ui-core/src/icons/Add";
import { useI18n } from "i18n";
import { AutosaveStatus } from "modules/assignTables/components/AutosaveStatus";
import useCompanySplitTreatment from "modules/split/useCompanySplitTreatment";
import { CompanyFeatures } from "services/splitService/features";
import { addDialogNotification } from "modules/dialogNotification/redux/dialogNotificationSlice";
import { useAppDispatch } from "store/hooks";
import { selectIsNetworkingRecommendationAddon } from "modules/company/redux/selectors";
import { useSelector } from "react-redux";
import { useStyles } from "./styles";
import { QuestionItem } from "../QuestionItem";
import {
  CUSTOM_QUESTION_LIMIT,
  DRAGGABLE_QUESTION_TYPE,
} from "../../constants";

interface Props {
  questions: ICustomRegistrationQuestion[];
  onCreateClick: () => void;
  onUpdateClick: (question: ICustomRegistrationQuestion) => void;
  reorderQuestions: (
    ordered: IQuestionOrder[],
  ) => Promise<ICustomRegistrationQuestion[]>;
  deleteQuestion: (id: string) => Promise<ICustomRegistrationQuestion[]>;
  isLoading: boolean;
  status: string;
  isNetworkingRecommendationsEnabled: boolean;
}

export const QuestionsList = ({
  questions,
  onCreateClick,
  onUpdateClick,
  reorderQuestions,
  deleteQuestion,
  isLoading,
  status,
  isNetworkingRecommendationsEnabled,
}: Props) => {
  const { t } = useI18n(["eventForm"]);
  const dispatch = useAppDispatch();
  const [localQuestionState, setLocalQuestionState] = useState(questions);
  const [, drop] = useDrop(() => ({
    accept: DRAGGABLE_QUESTION_TYPE,
  }));
  const { featureEnabled: networkRecommendationsFeatures } =
    useCompanySplitTreatment(CompanyFeatures.NETWORKING_RECOMMENDATIONS);
  const isNetworkingRecommendationAddon = useSelector(
    selectIsNetworkingRecommendationAddon,
  );
  const styles = useStyles({
    networkRecommendationsFeatures: Boolean(
      networkRecommendationsFeatures && isNetworkingRecommendationAddon,
    ),
  });

  useEffect(() => {
    setLocalQuestionState(questions);
  }, [questions]);

  const moveQuestion = useCallback(
    (id: string, newOrder: number) => {
      const { order: currentOrder } = localQuestionState.find(
        (question) => question._id === id,
      ) as ICustomRegistrationQuestion;

      setLocalQuestionState(
        localQuestionState.map((question) => {
          let { order } = question;

          if (question._id === id) {
            order = newOrder;
          } else if (
            newOrder > currentOrder &&
            question.order > currentOrder &&
            question.order <= newOrder
          ) {
            order = question.order - 1;
          } else if (
            newOrder < currentOrder &&
            question.order < currentOrder &&
            question.order >= newOrder
          ) {
            order = question.order + 1;
          }

          return { ...question, order };
        }),
      );
    },
    [localQuestionState],
  );

  const handleOrderChange = async () => {
    await reorderQuestions(
      localQuestionState.map(({ _id, order }) => ({ _id, order })),
    );
  };

  const handleQuestionDelete = (id: string) => {
    dispatch(
      addDialogNotification({
        actionsClassName: styles.deleteDialogActions,
        confirmBtnClassName: styles.deleteDialogActionButtons,
        dismissBtnClassName: styles.deleteDialogActionButtons,

        confirmText: t("common:yes"),
        dismissText: t("common:no"),
        message: t("delete.question.confirmation"),
        onConfirm: async () => {
          await deleteQuestion(id);
        },
        hideCloseButton: true,
      }),
    );
  };

  const networkingRecommendationsQuestions = questions.filter(
    (question) => question?.isNetworkingQuestion,
  );

  const questionsLength = useMemo(
    () =>
      isNetworkingRecommendationsEnabled
        ? Math.max(
            questions.length - networkingRecommendationsQuestions.length,
            0,
          )
        : questions.length,
    [
      isNetworkingRecommendationsEnabled,
      networkingRecommendationsQuestions.length,
      questions.length,
    ],
  );

  return (
    <>
      <div ref={drop} className={styles.container}>
        {localQuestionState
          .sort((a, b) => a.order - b.order)
          .map((question) => (
            <QuestionItem
              key={question._id}
              question={question}
              moveQuestion={moveQuestion}
              onOrderChange={handleOrderChange}
              onQuestionUpdate={onUpdateClick}
              onQuestionDelete={handleQuestionDelete}
            />
          ))}
        <div className={styles.bottomBar}>
          <Button
            size="sm"
            variant="primary"
            disabled={questionsLength === CUSTOM_QUESTION_LIMIT}
            onClick={onCreateClick}
            data-testid="create-question-button"
            contentClassName={
              networkRecommendationsFeatures && isNetworkingRecommendationAddon
                ? styles.buttonContent
                : ""
            }
          >
            {networkRecommendationsFeatures &&
            isNetworkingRecommendationAddon ? (
              <Box className={styles.buttonText}>
                <AddIcon />
                <Typography variant="body1">{t("add.question")}</Typography>
              </Box>
            ) : (
              <Typography variant="body1">{t("add.question")}</Typography>
            )}
          </Button>
          <AutosaveStatus status={status} isLoading={isLoading} />
        </div>
      </div>
      <Typography variant="body1" className={styles.count}>
        {t("custom.questions.created", {
          limit: CUSTOM_QUESTION_LIMIT,
          count: questionsLength,
        })}
      </Typography>
    </>
  );
};
