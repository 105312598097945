import { useI18n } from "i18n";
import { useQuery } from "@tanstack/react-query";
import useNotificationActions from "modules/notification/hooks/useNotificationActions";
import { trackError, Errors } from "modules/monitoring";
import { useEffect } from "react";
import { COMPANY_SETTINGS_QUERY_KEY } from "../../constants";
import api from "../../company.api";

// retrieves public settings, which is an important distinction
// consider revising the endpoint used if you need private settings
// or create a different hook for it
export const useCompanySettingsQuery = (companyId?: string | null) => {
  const { t } = useI18n(["eventForm"]);
  const { addErrorNotification } = useNotificationActions();

  const { data, status, error } = useQuery({
    queryKey: [COMPANY_SETTINGS_QUERY_KEY, companyId],
    queryFn: async () => {
      if (!companyId) {
        return undefined;
      }

      const response = await api.getCompanyPublicSettings(companyId);

      if (!response.isSuccess) {
        throw new Error(response.message);
      }

      return response.companySettings;
    },
    enabled: Boolean(companyId),
  });

  useEffect(() => {
    if (!error) return;

    addErrorNotification({
      message: t("common:company.settings.query.error"),
      position: "tc",
    });

    trackError(error, {
      label: Errors.COMPANY_SETTING_QUERY,
    });
  }, [error, addErrorNotification, t]);

  return {
    companySettings: data,
    status,
  };
};
