import { memo } from "react";
import { useSelector } from "react-redux";
import { selectIsEventManager } from "modules/event/selectors";
import { selectCurrentTheaterId } from "modules/theater/selectors";
import { selectUser } from "modules/auth/redux/selectors";
import { getUserName } from "modules/userProfile";
import { QA } from "../QA";

interface Props {
  setIsClickAwayListenerDisabled?: (isDisabled: boolean) => void;
}

const QAContainer = memo(({ setIsClickAwayListenerDisabled }: Props) => {
  const user = useSelector(selectUser);
  const currentTheaterId = useSelector(selectCurrentTheaterId);
  const isEventManager = useSelector(selectIsEventManager);

  if (!user || !currentTheaterId) {
    return null;
  }

  return (
    <QA
      authorName={getUserName(user)}
      isEventManager={isEventManager}
      eventId={currentTheaterId}
      userId={user.id}
      setIsClickAwayListenerDisabled={setIsClickAwayListenerDisabled}
    />
  );
});

export default QAContainer;
