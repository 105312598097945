import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { WhiteboardDataStatus } from "modules/whiteboard/constants";
import { MiroBoard } from "modules/whiteboard/types";

export type RoomWhiteboardState = {
  isWhiteboardOpen: boolean;
  startedBy?: string;
  startedAt?: string;
  boardUrlStatus?: WhiteboardDataStatus;
  boardCode?: string;
  miroBoardId?: string;
};

export const initialState: RoomWhiteboardState = {
  isWhiteboardOpen: false,
  boardUrlStatus: WhiteboardDataStatus.NOT_STARTED,
};

const roomWhiteboardSlice = createSlice({
  name: "roomWhiteboard",
  initialState,
  reducers: {
    openRoomWhiteboard: (state: RoomWhiteboardState) => {
      state.isWhiteboardOpen = true;
    },
    closeRoomWhiteboard: (state: RoomWhiteboardState) => {
      state.isWhiteboardOpen = false;
    },
    updateRoomWhiteboardState: (
      state: RoomWhiteboardState,
      action: PayloadAction<{
        startedBy?: string;
        startedAt?: string;
        boardUrlStatus?: WhiteboardDataStatus;
      }>,
    ) => {
      state.startedBy = action.payload.startedBy;
      state.startedAt = action.payload.startedAt;
      if (action.payload.boardUrlStatus) {
        state.boardUrlStatus = action.payload.boardUrlStatus;
      }
    },
    updateRoomWhiteboard: (
      state: RoomWhiteboardState,
      action: PayloadAction<MiroBoard>,
    ) => {
      state.boardCode = action.payload.boardCode;
      state.miroBoardId = action.payload.miroBoardId;
    },
    resetRoomWhiteboardState: (state: RoomWhiteboardState) => {
      state.startedBy = initialState.startedBy;
      state.startedAt = initialState.startedAt;
      state.boardUrlStatus = initialState.boardUrlStatus;
      state.miroBoardId = initialState.miroBoardId;
    },
  },
});

export const {
  openRoomWhiteboard,
  closeRoomWhiteboard,
  resetRoomWhiteboardState,
  updateRoomWhiteboardState,
  updateRoomWhiteboard,
} = roomWhiteboardSlice.actions;

export default roomWhiteboardSlice.reducer;
