import { ChangeEvent } from "react";
import classnames from "classnames";
import { useI18n } from "i18n";
import { Container } from "@remo-co/ui-core/src/components/Container";
import { RadioGroup } from "@remo-co/ui-core/src/components/RadioGroup";
import { Radio } from "@remo-co/ui-core/src/components/Radio";
import { Typography } from "@remo-co/ui-core/src/components/Typography";
import { FormControlLabel } from "@remo-co/ui-core/src/components/FormControlLabel";
import useStyles from "./styles";

export enum UNASSIGN_OPTIONS {
  EVENT = "event",
  FLOOR = "floor",
  TABLE = "table",
}

interface Props {
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  selectedOption: string;
  showTableOption: boolean;
  showFloorOption: boolean;
}

const UnassignMenuDialogContent = ({
  onChange,
  selectedOption,
  showTableOption,
  showFloorOption,
}: Props): JSX.Element | null => {
  const { t } = useI18n(["event"]);
  const styles = useStyles();

  return (
    <Container>
      <Typography variant="h5" className={styles.title}>
        {t("manageEvent:remove.assigned.attendees")}
      </Typography>
      <RadioGroup value={selectedOption} onChange={onChange}>
        {showTableOption && (
          <FormControlLabel
            className={classnames({
              [styles.activeOption]: selectedOption === UNASSIGN_OPTIONS.TABLE,
            })}
            value={UNASSIGN_OPTIONS.TABLE}
            control={<Radio color="primary" />}
            label={t("event:current.table")}
          />
        )}
        {showFloorOption && (
          <FormControlLabel
            className={classnames({
              [styles.activeOption]: selectedOption === UNASSIGN_OPTIONS.FLOOR,
            })}
            value={UNASSIGN_OPTIONS.FLOOR}
            control={<Radio color="primary" />}
            label={t("event:current.floor")}
          />
        )}
        <FormControlLabel
          className={classnames({
            [styles.activeOption]: selectedOption === UNASSIGN_OPTIONS.EVENT,
          })}
          value={UNASSIGN_OPTIONS.EVENT}
          control={<Radio color="primary" />}
          label={t("event:entire.event")}
        />
      </RadioGroup>
    </Container>
  );
};

export default UnassignMenuDialogContent;
