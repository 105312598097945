import { Theme } from "@remo-co/ui-core/src/types";
import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";

export const useStyles = makeStyles<Theme>(() => ({
  root: {
    "& .MuiPaper-root.MuiDialog-paper": {
      height: "auto",
    },
  },
  icon: {
    maxWidth: "100%",
  },
}));
