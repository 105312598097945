import { Map } from "immutable";
import { useContext, useMemo } from "react";
import { IPageSettingState, PageSettingContext } from "./pageSettingContext";

enum ActionTypes {
  RESET = "app/page/RESET",
  SET_PAGE_TITLE = "app/page/SET_PAGE_TITLE",
}

interface IAction {
  type: string;
  payload?: {
    pageTitle?: string;
  };
}

export const initialPageSettingsState: Map<
  string,
  Map<string, IPageSettingState>
> = Map();

const reducer = (state: typeof initialPageSettingsState, action: IAction) => {
  switch (action.type) {
    case ActionTypes.SET_PAGE_TITLE:
      return { ...state, ...action.payload };
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
};

export default reducer;

export const usePageSettingReducerActions = () => {
  const { dispatch } = useContext(PageSettingContext);

  if (!dispatch) {
    throw new Error("PageSettingProvider required");
  }
  const actions = useMemo(
    () => ({
      setPageTitle(pageTitle?: string) {
        dispatch({
          type: ActionTypes.SET_PAGE_TITLE,
          payload: { pageTitle },
        });
      },
      reset() {
        dispatch({ type: ActionTypes.RESET });
      },
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return actions;
};
