import classNames from "classnames";
import { useTableStyles } from "./styles";

export interface MiniMapTableProps {
  x: number;
  y: number;
  width: number;
  height: number;
  selected: boolean;
  scale: number;
  tableCode: string;
}

export const MiniMapTable = ({
  selected,
  tableCode,
  ...rest
}: MiniMapTableProps): JSX.Element => {
  const styles = useTableStyles(rest);
  const classes = classNames(styles.root, {
    [styles.hidden]: !selected,
  });

  return <div data-testid={`minimap-${tableCode}`} className={classes} />;
};
