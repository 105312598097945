import { IEventPage } from "./types";

export const BASIC_SETTINGS: IEventPage = {
  pageId: "settings",
  title: "manageEvent:basic.settings",
};
export const TICKETING: IEventPage = {
  pageId: "ticketing",
  title: "manageEvent:ticketing",
};
export const POST_EVENT: IEventPage = {
  title: "manageEvent:post.event",
  pageId: "post-event",
};
export const INVITATIONS: IEventPage = {
  pageId: "invitations",
  title: "manageEvent:invitations",
};

export const ATTENDEE_LIST_QUERY_KEY = "event-attendee-list";
export const MANAGE_EVENT_PAGES: IEventPage[] = [
  {
    pageId: "details",
    title: "manageEvent:event.details",
    isHidden: false,
  },
  {
    pageId: "registration",
    title: "eventForm:registration.label",
  },
  { pageId: "lobby-and-welcome", title: "manageEvent:lobby.and.welcome" },
  {
    pageId: "floor-content",
    title: "manageEvent:floor.content",
  },
  { pageId: "sponsors", title: "sponsors" },
  {
    pageId: "branding",
    title: "manageEvent:branding",
    isHidden: true,
  },
  {
    title: "manageEvent:features",
    pageId: "features",
  },
  {
    title: "manageEvent:event.agenda",
    pageId: "agenda",
    isDisabledInCreateEvent: true,
  },
  {
    pageId: "assign-tables",
    title: "manageEvent:assign.tables",
    isDisabledInCreateEvent: true,
  },
  {
    pageId: "stream",
    title: "manageEvent:stream",
    isDisabledInCreateEvent: true,
  },
];

export const HUNDRED_YEARS_IN_MILLISECONDS = 100 * 365 * 24 * 60 * 60 * 1000;
