import { Button } from "@remo-co/ui-core/src/components/Button";
import { useI18n } from "i18n";
import { useState } from "react";
import { Typography } from "@remo-co/ui-core/src/components/Typography";
import { useCreateNewTag } from "modules/customFloorPlan/hooks/useCreateNewTag";
import { useStyles } from "./styles";

interface Props {
  name: string;
  setSearch: (search: string | undefined) => void;
}

export const CreateNewTag = ({ name, setSearch }: Props) => {
  const { t } = useI18n(["customFloorPlan"]);
  const [isLoading, setIsLoading] = useState(false);
  const styles = useStyles();
  const { createNewTag } = useCreateNewTag();

  const addNewCompanyTag = async () => {
    setIsLoading(true);
    try {
      await createNewTag(name);
    } finally {
      setIsLoading(false);
      setSearch(undefined);
    }
  };

  return (
    <Button
      variant="text"
      onMouseDown={(e) => {
        e.preventDefault();
        addNewCompanyTag();
      }}
      size="sm"
      disabled={isLoading}
      className={styles.createTag}
      data-testid="create-tag-button"
    >
      <Typography className={styles.tagText}>
        {t("customFloorPlan:create.tag", { name: `"${name}"` })}
      </Typography>
    </Button>
  );
};
