import { get, ref, remove, set } from "firebase/database";
import { database, serverValue } from "services/firebaseService/firebaseConfig";
import { THEATER_PATH } from "modules/theater/firebase";

const getBroadcastInfoDocRef = (theaterId: string) =>
  ref(database, `${THEATER_PATH}/${theaterId}/Broadcast/BroadcastInfo`);

interface IBroadcastDataProps {
  isBroadcasting?: boolean;
  timeout: number;
  streamerName?: string;
  startedAt?: number;
  startedBy: string;
}

export const isInPresentationMode = async (
  theaterId: string,
): Promise<boolean> => {
  const broadcastInfoRef = getBroadcastInfoDocRef(theaterId);
  const broadcastInfoData = await get(broadcastInfoRef);

  const broadcastInfo =
    (await broadcastInfoData.val()) as IBroadcastDataProps | null;
  return broadcastInfo?.isBroadcasting ?? false;
};

const startBroadcast = async (theaterId: string, data: IBroadcastDataProps) => {
  await set(getBroadcastInfoDocRef(theaterId), {
    ...data,
    networkIssue: false,
    startedAt: serverValue.TIMESTAMP,
  });
};

const stopBroadcast = async (theaterId: string) => {
  await remove(getBroadcastInfoDocRef(theaterId));
};

export default {
  getBroadcastInfoDocRef,
  startBroadcast,
  stopBroadcast,
};
