import { ReactNode } from "react";
import { Box } from "@remo-co/ui-core/src/components/Box";
import { Typography } from "@remo-co/ui-core/src/components/Typography";
import { CheckCircle } from "@remo-co/ui-core/src/icons/CheckCircle";
import { HighlightOff } from "@remo-co/ui-core/src/icons/HighlightOff";
import { Info } from "@remo-co/ui-core/src/icons/Info";
import { useStyles } from "./styles";

interface IResultCase {
  status: string;
  condition: boolean;
  content: ReactNode;
}

interface IResultsListItemProps {
  title: string;
  cases: IResultCase[];
  defaultCase?: ReactNode;
}

const ResultsListItem = ({
  title,
  cases,
  defaultCase,
  ...props
}: IResultsListItemProps) => {
  const styles = useStyles();
  const firstResult = cases.find((resultCase) => resultCase.condition);
  const renderStatus = (status?: string) => {
    if (status === "error")
      return <HighlightOff className={styles.error} data-testid="error-icon" />;
    if (status === "warning")
      return <Info className={styles.warning} data-testid="warning-icon" />;

    return (
      <CheckCircle className={styles.success} data-testid="success-icon" />
    );
  };

  return (
    <Box className={styles.root}>
      <Typography variant="h6" component="div" className={styles.title}>
        {title}
      </Typography>
      <Typography variant="body2" component="div">
        <div className={styles.listItem} {...props}>
          {firstResult ? (
            <>
              {renderStatus(firstResult.status)}
              {firstResult.content}
            </>
          ) : null}
          {!firstResult && defaultCase ? (
            <>
              {renderStatus()}
              {defaultCase}
            </>
          ) : null}
        </div>
      </Typography>
    </Box>
  );
};

export default ResultsListItem;
