import { RootState } from "store";

const selectIsCountdownDialogVisible = (state: RootState) =>
  state.enforceTableAssignment.isCountdownDialogVisible;

const selectIsCountdownBannerVisible = (state: RootState) =>
  state.enforceTableAssignment.isCountdownBannerVisible;

const selectIsInProgressDialogVisible = (state: RootState) =>
  state.enforceTableAssignment.isInProgressDialogVisible;

const selectCountdownStartTime = (state: RootState) =>
  state.enforceTableAssignment.countdownStartTime;

const selectIsEnforceTableAssignmentScheduled = (state: RootState) =>
  state.enforceTableAssignment.isCountdownDialogVisible ||
  state.enforceTableAssignment.isCountdownBannerVisible;

export {
  selectIsCountdownDialogVisible,
  selectIsCountdownBannerVisible,
  selectIsInProgressDialogVisible,
  selectCountdownStartTime,
  selectIsEnforceTableAssignmentScheduled,
};
