import { firestore } from "services/firebaseService/firebaseConfig";
// eslint-disable-next-line you-dont-need-lodash-underscore/is-undefined
import isUndefined from "lodash/isUndefined";
import omitBy from "lodash/omitBy";
import {
  collection,
  deleteDoc,
  doc,
  serverTimestamp,
  setDoc,
} from "firebase/firestore";

export interface ConversationBroadcastFirebase {
  readonly sessionId?: string;
  readonly sender?: string;
  readonly sentAt?: {
    seconds: number;
  };
  readonly channel?: string;
}

export const CONVERSATION_BROADCAST_PATH = "ConversationBroadcast";

export const useConversationBroadcastFirebase = () => {
  const getConversationBroadcastDocRef = (channel: string) =>
    doc(collection(firestore, CONVERSATION_BROADCAST_PATH), channel);

  const startConversationBroadcast = (
    channel: string,
    broadcast: ConversationBroadcastFirebase,
  ) => {
    const docRef = getConversationBroadcastDocRef(channel);
    const announcementWithoutUndefinedProps = omitBy(broadcast, isUndefined);

    setDoc(docRef, {
      ...announcementWithoutUndefinedProps,
      sentAt: serverTimestamp(),
    });
  };

  const stopConversationBroadcast = (channel: string) => {
    const docRef = getConversationBroadcastDocRef(channel);
    deleteDoc(docRef);
  };

  return {
    getConversationBroadcastDocRef,
    startConversationBroadcast,
    stopConversationBroadcast,
  };
};
