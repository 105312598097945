import { LiveStreams } from "services/firebaseService/liveStream";
import ActionTypes, { BroadcastStatus } from "./constants";
import { BroadcastAction } from "./actions";

export type State = {
  delay?: number;
  videoURL?: string;
  videoShareTime?: number;
  isBroadcasting?: boolean;
  isInBroadcast: boolean | null;
  startedAt?: number;
  startedBy?: string;
  broadcastStatus?: BroadcastStatus;
  isTurnOnMicCamWhenStart: boolean;
  broadcastTransitionInProgress: boolean;
  isVideoControlsEnabled?: boolean;
  streams?: LiveStreams[];
  recordOnEntering?: boolean;
};

export const initialState: State = {
  delay: 0,
  isBroadcasting: undefined,
  videoURL: undefined,
  videoShareTime: undefined,
  isInBroadcast: null,
  startedAt: undefined,
  startedBy: undefined,
  broadcastStatus: undefined,
  isTurnOnMicCamWhenStart: false,
  /** We set the transition flag below to true by default because
   * 1. There are a lot of reset actions being called from different places
   * 2. So if we set it to false in the initialState, we need to change the flag to true
   *    every time there is a reset.
   * 3. Therefore, I've set it to true by default. We set the transition to false when all the processing
   *    is complete and we are ready to show the broadcast view or the conversation view.
   * 4. This works because reset is called only when we are transitioning between broadcast view
   *    conversation view.
   */
  // NOTE: I can't find any indication that this is in use anywhere, or being relied upon.
  // TODO: remove this and related code
  broadcastTransitionInProgress: true,
  streams: [],
  recordOnEntering: false,
};

const reducer = (state = initialState, action: BroadcastAction): State => {
  switch (action.type) {
    case ActionTypes.UPDATE_VIDEO_URL:
      return {
        ...state,
        videoURL: action.payload.videoURL ?? undefined,
      };
    case ActionTypes.UPDATE_VIDEO_SHARE_TIME:
      return {
        ...state,
        videoShareTime: action.payload.videoShareTime,
      };
    case ActionTypes.UPDATE_BROADCAST_INFO:
      return {
        ...state,
        ...action.payload,
      };
    case ActionTypes.UPDATE_IS_VIDEO_CONTROLS_ENABLED:
      return {
        ...state,
        isVideoControlsEnabled: action.payload.isVideoControlsEnabled,
      };
    case ActionTypes.ENTER_BROADCAST:
      return {
        ...state,
        isInBroadcast: true,
        broadcastStatus: BroadcastStatus.IN_PROGRESS,
        broadcastTransitionInProgress: false,
      };
    case ActionTypes.LEAVE_BROADCAST:
      return {
        ...state,
        isInBroadcast: false,
      };
    case ActionTypes.START_BROADCAST:
      return {
        ...state,
        ...action.payload,
        isBroadcasting: true,
        broadcastStatus: BroadcastStatus.PREPARING,
      };
    case ActionTypes.STOP_BROADCAST:
      return {
        ...initialState,
        isBroadcasting: false,
        isInBroadcast: false,
        broadcastTransitionInProgress: false,
      };

    case ActionTypes.BROADCAST_TRANSITION_STARTED:
      return {
        ...state,
        broadcastTransitionInProgress: true,
      };

    case ActionTypes.BROADCAST_TRANSITION_COMPLETE:
      return {
        ...state,
        broadcastTransitionInProgress: false,
      };
    case ActionTypes.RESET: {
      const { isInBroadcast } = state;
      return { ...initialState, isInBroadcast };
    }
    default:
      return state;
  }
};

export default reducer;
