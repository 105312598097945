import { RetryLink } from "@apollo/client/link/retry";
import { split, HttpLink } from "@apollo/client";
import { getMainDefinition } from "@apollo/client/utilities";
import { onError } from "@apollo/client/link/error";
import { setContext } from "@apollo/client/link/context";
import { getVar } from "config";
import logger from "logging/logger";
import { Actions, Errors, trackAction, trackError } from "modules/monitoring";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { createClient } from "graphql-ws";
import { getToken } from "services/apiService";

interface GqlHeaders {
  Authorization?: string;
}

const graphqlHttpUrl = getVar("REACT_APP_GRAPHQL_HTTP_ENDPOINT");
const graphqlWsUrl = getVar("REACT_APP_GRAPHQL_WS_ENDPOINT");

export const errorLink = onError(
  ({ graphQLErrors, networkError, operation }) => {
    if (graphQLErrors)
      graphQLErrors.forEach(({ message, locations, path }) => {
        logger.error(
          `[GraphQL error]: Message: ${message}, Operation Name: ${operation.operationName}, Location: ${locations}, Path: ${path}`,
        );
      });
    if (networkError)
      logger.error(
        `[Network error]: ${operation.operationName} - ${networkError}`,
      );
  },
);

export const retryLink = new RetryLink({
  delay: {
    initial: 300,
    max: Infinity,
    jitter: true,
  },
  attempts: (count, operation, error) => {
    // Total attempts will run 8 times over the course of 2.5 minutes.
    // Each subsequent retry will be twice the length of the last retry
    if (count > 9) {
      trackError(Errors.GRAPHQL_RETRY_MAX, {
        label: Errors.GRAPHQL_RETRY_MAX,
        context: {
          operation: operation.operationName,
        },
      });
      return false;
    }
    trackAction(Actions.GRAPHQL_RETRY, {
      operation: operation.operationName,
      count,
    });
    return !!error;
  },
});

const graphqlHttpLink = new HttpLink({ uri: graphqlHttpUrl });
const graphqlWsLink = new GraphQLWsLink(
  createClient({
    url: graphqlWsUrl,
    connectionParams: async () => {
      const headers: GqlHeaders = {};
      const token = await getToken();

      if (token) {
        headers.Authorization = `Bearer ${token}`;
      }

      return { headers };
    },
  }),
);

const authMiddleware = setContext(async (_operation) => {
  // TODO: use operation above to decide whether we need auth for this req or not
  const headers: GqlHeaders = {};
  const token = await getToken();

  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }

  return { headers };
});

const splitGraphqlLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    );
  },
  graphqlWsLink,
  graphqlHttpLink,
);

export const authLinks = [
  retryLink,
  errorLink,
  authMiddleware,
  splitGraphqlLink,
];
