import { EventPoll } from "graphql/generated";
import { IUser } from "modules/app/types";
import { getUserName } from "modules/userProfile";

const getInEventPollCSVContents = (
  poll: EventPoll,
  usersInEvent: Immutable.Map<string, IUser>,
) => {
  const lineDelimiter = "\n";
  const columnDelimiter = ",";
  const header = "Email,Name,Question,Voted Option";

  const userData: Record<string, IUser> = {};
  usersInEvent.forEach((value) => {
    userData[value.email] = value;
  });

  const pollOptions: Record<string, string> = poll.options.reduce(
    (accumulator, option) => ({ ...accumulator, [option.id]: option.option }),
    {},
  );

  const lines = (poll.userAnswers ?? [])
    .map((userAnswer) => {
      const user = userData[userAnswer.userId];

      return [
        userAnswer.userId,
        getUserName(user),
        poll.title,
        pollOptions[userAnswer.optionId],
      ].join(columnDelimiter);
    })
    .join(lineDelimiter);

  return [header, lines].join(lineDelimiter);
};

const getPostEventPollCSVContents = (polls: EventPoll[]) => {
  const lineDelimiter = "\n";
  const columnDelimiter = ",";
  const header = "Type,Question,Option,Number of Votes,Percentage";

  const lines = polls
    .map((poll) => {
      const { type, title, options } = poll;
      const totalVotes = options.reduce((sum, option) => sum + option.votes, 0);

      return options
        .map((opt) => {
          const { option, votes } = opt;
          const percentage =
            totalVotes > 0 ? ((votes / totalVotes) * 100).toFixed(2) : "0.00";
          return [type, title, option, votes, `${percentage}%`].join(
            columnDelimiter,
          );
        })
        .join(lineDelimiter);
    })
    .join(lineDelimiter);

  return [header, lines].join(lineDelimiter);
};

const downloadInEventCSV = (
  filename: string,
  poll: EventPoll,
  usersInEvent: Immutable.Map<string, IUser>,
) => {
  const link = document.createElement("a");
  const csv = getInEventPollCSVContents(poll, usersInEvent);

  if (csv === null) {
    return;
  }

  link.setAttribute(
    "href",
    `data:text/csv;charset=utf-8,%EF%BB%BF${encodeURIComponent(csv)}`,
  );
  link.setAttribute("download", filename);
  link.click();
};

const downloadPostEventCSV = (filename: string, polls: EventPoll[]) => {
  const link = document.createElement("a");
  const csv = getPostEventPollCSVContents(polls);

  if (csv === null) {
    return;
  }

  link.setAttribute(
    "href",
    `data:text/csv;charset=utf-8,%EF%BB%BF${encodeURIComponent(csv)}`,
  );
  link.setAttribute("download", filename);
  link.click();
};

export default {
  getInEventPollCSVContents,
  getPostEventPollCSVContents,
  downloadInEventCSV,
  downloadPostEventCSV,
};
