import { useState } from "react";
import { Card } from "@remo-co/ui-core/src/components/Card";
import { CardContent } from "@remo-co/ui-core/src/components/CardContent";
import { Divider } from "@remo-co/ui-core/src/components/Divider";
import { Grid } from "@remo-co/ui-core/src/components/Grid";
import { useI18n } from "i18n";
import { IMapTemplate } from "types/theater";
import { MAX_ALLOWED_PER_FLOOR } from "@remo-co/constants";
import { bytesToSize, configHasRoomName } from "./utils";
import {
  ConfigRoomNames,
  maxFileSize,
  maxRecommendedFileSize,
} from "./constants";
import { ResultListItem, TableDetails, TableDetailsButton } from "./components";
import RemoHelpLink, { RemoCFPLinkEndpoints } from "./RemoHelpLink";
import { useComputeForOverCapacity } from "./hooks";

interface IResultsListProps {
  config?: Partial<IMapTemplate> | null;
  fileSize: number;
  roomsCount: number;
  overlapCheckResult: string[] | null;
}

const ResultsList = ({
  config,
  fileSize,
  roomsCount,
  overlapCheckResult,
}: IResultsListProps) => {
  const { t } = useI18n(["common", "customFloorPlan"]);
  const [showTableDetails, setShowTableDetails] = useState(false);
  const { roomsOverCapacity } = useComputeForOverCapacity(
    (config && config.rooms) ?? [],
  );

  if (!config) {
    return null;
  }

  const { STAGE } = ConfigRoomNames;
  const formattedFileSize = bytesToSize(fileSize);
  const formattedMaxFileSize = bytesToSize(maxFileSize);
  const recommendedCapacity = config.seats
    ? Math.min(
        MAX_ALLOWED_PER_FLOOR,
        Math.ceil(config.seats - config.seats * 0.2),
      )
    : MAX_ALLOWED_PER_FLOOR;

  const toggleShowTableDetails = () => {
    setShowTableDetails(!showTableDetails);
  };

  return (
    <Grid className="cfp-results-grid-root" data-testid="cfp-results-list">
      <Card>
        <CardContent>
          <ResultListItem
            title={t("file.size")}
            cases={[
              {
                condition: fileSize <= maxRecommendedFileSize,
                status: "success",
                content: <div>{formattedFileSize}</div>,
              },
              {
                condition:
                  fileSize > maxRecommendedFileSize && fileSize <= maxFileSize,
                status: "warning",
                content: (
                  <div>
                    {t("current.file.size", {
                      size: formattedFileSize,
                    })}
                    <br />
                    {t("recommend.file.size", {
                      size: bytesToSize(maxRecommendedFileSize),
                    })}
                  </div>
                ),
              },
              {
                condition: fileSize > maxFileSize,
                status: "error",
                content: (
                  <div>
                    {t("current.file.size", {
                      size: formattedFileSize,
                    })}{" "}
                    {t("customFloorPlan:max.file.size", {
                      key: formattedMaxFileSize,
                    })}
                  </div>
                ),
              },
            ]}
            data-testid="cfp-result-item-filesize"
          />
          <Divider />
          <ResultListItem
            title={t("customFloorPlan:conference.area")}
            cases={[
              {
                condition: !config.hasConferenceArea,
                status: "warning",
                content: (
                  <div>
                    {t("customFloorPlan:missing.conference.area.layers")}
                    <br />
                    <RemoHelpLink endpoint={RemoCFPLinkEndpoints.CFP} />{" "}
                  </div>
                ),
              },
            ]}
            defaultCase={<div>{t("good")}</div>}
            data-testid="cfp-result-item-has-conference"
          />
          <Divider />
          <ResultListItem
            title={t("customFloorPlan:tables.title")}
            cases={[
              {
                condition: !(config.rooms && config.rooms.length > 0),
                status: "error",
                content: (
                  <div>
                    {t("customFloorPlan:table.not.found.message")}
                    <br />
                    <RemoHelpLink endpoint={RemoCFPLinkEndpoints.apply} />
                  </div>
                ),
              },
              {
                condition:
                  !!overlapCheckResult?.length && !!roomsOverCapacity.length,
                status: "warning",
                content: (
                  <div className="table-details-btn-ctn">
                    {roomsCount} {t("customFloorPlan:tables.found")}{" "}
                    {`(${t(
                      "customFloorPlan:over.capacity.overlapping.tables.found",
                      {
                        number: overlapCheckResult && overlapCheckResult.length,
                        number2: roomsOverCapacity.length,
                      },
                    )})`}
                    <TableDetailsButton
                      handleClick={toggleShowTableDetails}
                      showTableDetails={showTableDetails}
                    />
                  </div>
                ),
              },
              {
                condition:
                  !!overlapCheckResult?.length || !!roomsOverCapacity.length,
                status: "warning",
                content: (
                  <div className="table-details-btn-ctn">
                    {roomsCount} {t("customFloorPlan:tables.found")}{" "}
                    {`(${
                      overlapCheckResult && overlapCheckResult.length
                        ? t("customFloorPlan:overlapping.tables.found", {
                            number: overlapCheckResult.length,
                          })
                        : t("customFloorPlan:over.capacity.tables.found", {
                            number: roomsOverCapacity.length,
                          })
                    })`}
                    <TableDetailsButton
                      handleClick={toggleShowTableDetails}
                      showTableDetails={showTableDetails}
                    />
                  </div>
                ),
              },
            ]}
            defaultCase={
              <div className="table-details-btn-ctn">
                {roomsCount} {t("customFloorPlan:tables.found")}
                <br />
                <TableDetailsButton
                  handleClick={toggleShowTableDetails}
                  showTableDetails={showTableDetails}
                />
              </div>
            }
            data-testid="cfp-result-item-rooms"
          />
          {showTableDetails && config.rooms && (
            <TableDetails
              rooms={config?.rooms}
              overlappingRooms={overlapCheckResult ?? []}
              overcapacityRooms={roomsOverCapacity}
            />
          )}
          <Divider />
          <ResultListItem
            title={t("customFloorPlan:seats")}
            cases={[
              {
                condition: !(config.seats && config.seats > 0),
                status: "error",
                content: (
                  <div>
                    {t("customFloorPlan:seats.not.found")}
                    <br />
                    <RemoHelpLink endpoint={RemoCFPLinkEndpoints.apply} />
                  </div>
                ),
              },
              {
                condition: !!(config.seats && config.seats > 130),
                status: "warning",
                content: (
                  <div>
                    {config.seats} {t("customFloorPlan:seats.found")}
                    <br />
                    {t("customFloorPlan:seats.guest.allowed", {
                      key: recommendedCapacity,
                    })}
                  </div>
                ),
              },
            ]}
            defaultCase={
              <div>
                {config.seats} {t("customFloorPlan:seats.found")}
                <br />
                {t("customFloorPlan:seats.guest.allowed", {
                  key: recommendedCapacity,
                })}
              </div>
            }
            data-testid="cfp-result-item-seats"
          />
          <Divider />
          <ResultListItem
            title={t("customFloorPlan:banners")}
            cases={[
              {
                condition: !(config.sponsors && config.sponsors.length > 0),
                status: "warning",
                content: (
                  <div>
                    {t("customFloorPlan:banners.not.found")}
                    <br />
                    <RemoHelpLink endpoint={RemoCFPLinkEndpoints.create} />
                  </div>
                ),
              },
            ]}
            defaultCase={t("customFloorPlan:banners.found", {
              key: config.sponsors?.length,
            })}
            data-testid="cfp-result-item-sponsor"
          />
          <Divider />
          <ResultListItem
            title={t("customFloorPlan:stage")}
            cases={[
              {
                condition: !configHasRoomName(config, STAGE),
                status: "warning",
                content: (
                  <div>
                    {t("customFloorPlan:stage.not.found")}
                    <br />
                    <RemoHelpLink endpoint={RemoCFPLinkEndpoints.create} />
                  </div>
                ),
              },
            ]}
            defaultCase={t("customFloorPlan:stage.found")}
            data-testid="cfp-result-item-stage"
          />
        </CardContent>
      </Card>
    </Grid>
  );
};

export default ResultsList;
