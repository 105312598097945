import { Theme } from "@remo-co/ui-core/src/types";
import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";
import zIndexes from "zIndexes.module.scss";

const useStyles = makeStyles<Theme>((theme) => ({
  chatContainer: {
    position: "unset",
    width: "100%",
    height: "100%",
    minHeight: "30vh",
    backgroundColor: theme.palette.background.default,
    maxHeight: "100%",
    boxSizing: "border-box",
    boxShadow: "0px 7px 40px 2px rgba(148, 149, 150, 0.3)",
    display: "flex",
    flexDirection: "column",
    transition: "0.3s ease-in-out",
    zIndex: parseInt(zIndexes.broadcastDialogZIndex, 10) + 1,
    pointerEvents: "auto",
    borderRadius: "4px",
    overflow: "hidden",
  },
}));

export default useStyles;
