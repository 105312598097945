import {
  AUDIO_LEVEL_THRESHOLD,
  AUDIO_SAMPLE_INTERVAL,
  MIN_SILENCE_DURATION,
  MIN_TALKING_DURATION,
  SAMPLE_WINDOW,
} from "./constants";

export const calculateRMS = (
  analyser: AnalyserNode,
  dataArray: Float32Array,
) => {
  analyser.getFloatTimeDomainData(dataArray);
  const sumSquares = dataArray.reduce(
    (sum, amplitude) => sum + amplitude * amplitude,
    0.0,
  );
  const rms = Math.sqrt(sumSquares / dataArray.length);
  return rms * 100;
};

const rollingWindow = (numberSamples: number) => {
  const sampleWindow = new Array<number>(numberSamples).fill(0);

  const addSample = (sample: number) => {
    sampleWindow.shift();
    sampleWindow.push(sample);
  };

  const calculateAverage = () => {
    const sum = sampleWindow.reduce((prev, current) => prev + current);
    const average = sum / numberSamples;
    return average;
  };

  const calculatePeak = () => {
    const peak = sampleWindow.reduce((prev, current) =>
      Math.max(prev, current),
    );
    return peak;
  };

  return { addSample, calculateAverage, calculatePeak };
};

enum AudioStates {
  ABOVE_THRESHOLD = "aboveThreshold",
  BELOW_THRESHOLD = "belowThreshold",
  FALLING = "falling",
  RISING = "rising",
}

const audioStateTransition = (
  threshold: number,
  talkingDuration: number,
  silenceDuration: number,
  onTransition: (audioState: AudioStates) => void,
) => {
  let previousAudioState = AudioStates.BELOW_THRESHOLD;
  let audioState = AudioStates.BELOW_THRESHOLD;
  let timestamp = Date.now();

  const setAboveThreshold = () => {
    switch (audioState) {
      case AudioStates.RISING: {
        const timeDifference = Date.now() - timestamp;
        if (timeDifference >= talkingDuration) {
          audioState = AudioStates.ABOVE_THRESHOLD;
          timestamp = Date.now();
        }
        break;
      }
      case AudioStates.BELOW_THRESHOLD: {
        audioState = AudioStates.RISING;
        timestamp = Date.now();
        break;
      }
      default: {
        break;
      }
    }
  };

  const setBelowThreshold = () => {
    switch (audioState) {
      case AudioStates.FALLING: {
        const timeDifference = Date.now() - timestamp;
        if (timeDifference >= silenceDuration) {
          audioState = AudioStates.BELOW_THRESHOLD;
        }
        break;
      }
      case AudioStates.ABOVE_THRESHOLD: {
        audioState = AudioStates.FALLING;
        timestamp = Date.now();
        break;
      }
      default: {
        break;
      }
    }
  };

  const transition = (audioLevel: number) => {
    if (audioLevel > threshold) {
      setAboveThreshold();
    } else {
      setBelowThreshold();
    }
    if (audioState !== previousAudioState) {
      onTransition(audioState);
    }
    previousAudioState = audioState;
  };

  const getCurrentState = () => audioState;

  return { transition, getCurrentState };
};

const connectAnalyser = (
  audioContext: AudioContext,
  mediaStream: MediaStream,
) => {
  const analyser = audioContext.createAnalyser();
  analyser.fftSize = 1024;
  const source = audioContext.createMediaStreamSource(mediaStream);
  source.connect(analyser);
  return analyser;
};

export const trackAudioLevel = ({
  audioContext,
  mediaStream,
  onTalkingStarted,
  onTalkingStopped,
  onVolumeChange,
}: {
  audioContext: AudioContext;
  mediaStream: MediaStream;
  onTalkingStarted: () => void;
  onTalkingStopped: () => void;
  onVolumeChange?: (audioLevel: number) => void;
}) => {
  const analyser = connectAnalyser(audioContext, mediaStream);
  const pcmArray = new Float32Array(analyser.fftSize);

  const { transition } = audioStateTransition(
    AUDIO_LEVEL_THRESHOLD,
    MIN_TALKING_DURATION,
    MIN_SILENCE_DURATION,
    (audioState) => {
      switch (audioState) {
        case AudioStates.ABOVE_THRESHOLD: {
          onTalkingStarted();
          break;
        }
        case AudioStates.BELOW_THRESHOLD: {
          onTalkingStopped();
          break;
        }
        default: {
          break;
        }
      }
    },
  );

  audioContext.resume();

  const { calculatePeak, addSample } = rollingWindow(SAMPLE_WINDOW);

  const interval = window.setInterval(() => {
    const rms = calculateRMS(analyser, pcmArray);
    onVolumeChange?.(rms);
    addSample(rms);
    transition(calculatePeak());
  }, AUDIO_SAMPLE_INTERVAL);

  return {
    cleanup: () => {
      window.clearInterval(interval);
    },
  };
};
