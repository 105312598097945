import { IUser } from "modules/app/types";
import { Typography } from "@remo-co/ui-core/src/components/Typography";
import { LocateParticipantButton } from "modules/participants/components/LocateParticipantButton";
import { useI18n } from "i18n";
import { useSelector } from "react-redux";
import { selectCurrentEventId } from "modules/event/selectors";
import { useUpdateRecommendedUserConnections } from "modules/networkingRecommendations/hooks/useUpdateRecommendedUserConnections";
import { InviteRecommendedGuest } from "../InviteRecommendedGuest";
import { useStyles } from "./styles";

interface Props {
  user: IUser;
}

export const NetworkingButtons = ({ user }: Props) => {
  const currentEventId = useSelector(selectCurrentEventId);
  const { t } = useI18n("event");
  const styles = useStyles();
  const { updateConnection } = useUpdateRecommendedUserConnections(user.id);

  const handleRequestContactInfoClick = () => {
    if (user.id && currentEventId) {
      updateConnection({
        connected: true,
        eventId: currentEventId,
        recommendedUser: user.id,
      });
    }
  };

  return (
    <div
      className={styles.networkingButtons}
      data-testid="networking-recommendations-buttons"
    >
      <LocateParticipantButton user={user} />
      <InviteRecommendedGuest userId={user.id} />
      <Typography
        variant="h6"
        className={styles.requestContactIfo}
        onClick={handleRequestContactInfoClick}
        role="button"
        tabIndex={0}
        onKeyPress={(e) => {
          if (e.key === "Enter") handleRequestContactInfoClick();
        }}
      >
        {t("request.contact.info")}
      </Typography>
    </div>
  );
};
