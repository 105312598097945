import useSystemClock from "modules/system/hooks/useSystemClock";
import { useEffect, useState } from "react";

const useTicker = (
  isTicking: boolean,
  interval = 1000,
  initialValue?: number,
) => {
  const { getCurrentTime } = useSystemClock();
  const [now, setNow] = useState(
    initialValue === undefined ? getCurrentTime() : initialValue,
  );

  useEffect(() => {
    if (!isTicking) {
      return;
    }

    const intervalId = setInterval(() => {
      setNow(getCurrentTime());
    }, interval);

    // eslint-disable-next-line consistent-return
    return () => {
      window.clearInterval(intervalId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTicking, interval]);

  // As soon as the ticker is turned on/off, it must update the `now` value
  useEffect(() => {
    if (initialValue === undefined) {
      // this would override the initialValue otherwise
      setNow(getCurrentTime());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTicking]);

  return now;
};

export default useTicker;
