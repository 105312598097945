import { gql } from "@apollo/client";

export const CREATE_EVENT_CONTENT = gql`
  mutation CreateEventContent(
    $companyId: ObjectID!
    $name: String!
    $type: EventContentType!
    $mediaType: EventContentMediaType!
    $media: String!
    $ctaLink: String
    $ctaText: String
    $floorImage: String
    $hideOpenInNewTabButton: Boolean
  ) {
    createEventContent(
      companyId: $companyId
      name: $name
      type: $type
      mediaType: $mediaType
      media: $media
      ctaLink: $ctaLink
      ctaText: $ctaText
      floorImage: $floorImage
      hideOpenInNewTabButton: $hideOpenInNewTabButton
    ) {
      ... on EventContent {
        __typename
        id
        company
        name
        type
        mediaType
        media
        ctaLink
        ctaText
        floorImage
        hideOpenInNewTabButton
      }
      ... on GQLError {
        __typename
        message
      }
    }
  }
`;

export const UPDATE_EVENT_CONTENT = gql`
  mutation UpdateEventContent(
    $id: ObjectID!
    $companyId: ObjectID!
    $name: String
    $type: EventContentType
    $mediaType: EventContentMediaType
    $media: String
    $ctaLink: String
    $ctaText: String
    $floorImage: String
    $hideOpenInNewTabButton: Boolean
  ) {
    updateEventContent(
      id: $id
      companyId: $companyId
      name: $name
      type: $type
      mediaType: $mediaType
      media: $media
      ctaLink: $ctaLink
      ctaText: $ctaText
      floorImage: $floorImage
      hideOpenInNewTabButton: $hideOpenInNewTabButton
    ) {
      ... on EventContent {
        __typename
        id
        company
        name
        type
        mediaType
        media
        ctaLink
        ctaText
        floorImage
        hideOpenInNewTabButton
      }
      ... on GQLError {
        __typename
        message
      }
    }
  }
`;

export const DELETE_EVENT_CONTENT = gql`
  mutation DeleteEventContent($id: ObjectID!, $companyId: ObjectID!) {
    deleteEventContent(id: $id, companyId: $companyId) {
      ... on EventContent {
        __typename
        id
        company
        name
        type
        mediaType
        media
        ctaLink
        ctaText
        floorImage
        hideOpenInNewTabButton
      }
      ... on GQLError {
        __typename
        message
      }
    }
  }
`;

export const PLACE_CONTENT_ON_SLOT = gql`
  mutation PlaceContentOnSlot(
    $companyId: ObjectID!
    $eventId: ObjectID!
    $floorId: ObjectID!
    $slot: String!
    $eventContent: ObjectID!
  ) {
    placeContentOnSlot(
      companyId: $companyId
      eventId: $eventId
      floorId: $floorId
      slot: $slot
      eventContent: $eventContent
    ) {
      ... on ContentWithMapping {
        __typename
        id
        company
        event
        floor
        slot
        name
        type
        mediaType
        media
        ctaLink
        ctaText
        floorImage
        hideOpenInNewTabButton
      }
      ... on GQLError {
        __typename
        message
      }
    }
  }
`;

export const REMOVE_CONTENT_ON_SLOT = gql`
  mutation RemoveContentOnSlot(
    $companyId: ObjectID!
    $eventId: ObjectID!
    $floorId: ObjectID!
    $slot: String!
  ) {
    removeContentOnSlot(
      companyId: $companyId
      eventId: $eventId
      floorId: $floorId
      slot: $slot
    ) {
      ... on EventContentMapping {
        __typename
        event
        floor
        slot
        eventContent
      }
      ... on GQLError {
        __typename
        message
      }
    }
  }
`;
