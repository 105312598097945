/* eslint-disable typescript-sort-keys/string-enum */
export const CUSTOM_QUESTIONS_QUERY_KEY = "custom-registration-questions";
export const CUSTOM_ANSWERS_QUERY_KEY = "custom-registration-answers";
export const NETWORKING_RECOMMENDATIONS_USERS_QUERY_KEY =
  "networking-recommendations-users";

export enum QuestionType {
  SINGLE_LINE_TEXT = "singleLineText",
  PARAGRAPH_TEXT = "paragraphText",
  SINGLE_SELECT = "singleSelect",
  MULTI_SELECT = "multiSelect",
}

export enum NetworkingRecommendationQuestionType {
  companyUrl = "companyUrl",
  demands = "demands",
  linkedIn = "linkedIn",
  supplies = "supplies",
}

export enum CustomRegistrationQuestionsErrors {
  NO_EVENT_ID = "NO_EVENT_ID",
  NO_QUESTION_ID = "NO_QUESTION_ID",
  NO_USER_ID = "NO_USER_ID",
}

export const NETWORKING_RECOMMENDATIONS_QUERY_KEY =
  "networking-recommendations-switch";

export enum NetworkingRecommendationsErrors {
  NO_EVENT_ID = "NO_EVENT_ID",
}
