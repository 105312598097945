import { Theme } from "@mui/material/styles";

import makeStyles from "@mui/styles/makeStyles";

export const useStyles = makeStyles<Theme>(() => ({
  iconButtonWithTooltip: () => ({
    background: "transparent",

    "& svg": {
      width: "1.15em",
      height: "1.15em",
    },

    "&:hover": {
      background: "transparent",
    },
  }),
}));
