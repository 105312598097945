import { Theme } from "@remo-co/ui-core/src/types";
import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";

export const useStyles = makeStyles<Theme>(() => ({
  root: {
    maxWidth: "30rem",
    padding: "1rem",
    position: "relative",
  },
  accordion: {
    width: "100%",
  },
  bold: {
    fontWeight: 800,
  },
}));
