import { useMutation, useQuery } from "@apollo/client";
import { useSelector } from "react-redux";
import { selectUser } from "modules/auth/redux/selectors";
import {
  AnswerPollMutation,
  AnswerPollMutationVariables,
  EventPoll,
  EventPollOption,
  EventPollStatus,
  EventPollType,
  GetEventPollsQuery,
  GetEventPollsQueryVariables,
} from "graphql/generated";
import { ANSWER_POLL, GET_POLLS } from "modules/polls/graphql";
import { useI18n } from "i18n";
import { addErrorNotification } from "modules/notification/redux/notificationSlice";
import { useAppDispatch } from "store/hooks";
import { Actions, trackAction } from "modules/monitoring";
import { useContext, useEffect } from "react";
import { Events, TRACKING_CONTEXT } from "modules/tracking";
import {
  selectCurrentEvent,
  selectIsEventManager,
} from "modules/event/selectors";
import { PollTabContainer } from "../PollTabContainer/PollTabContainer";
import { updatePoll } from "../../graphql/cacheOperations";

export const PollsAndQuizzesWrapper = ({ eventId }: { eventId: string }) => {
  const { t } = useI18n(["event"]);
  const user = useSelector(selectUser);
  const isHost = useSelector(selectIsEventManager);
  const dispatch = useAppDispatch();
  const [answerPoll, _mutation] = useMutation<
    AnswerPollMutation,
    AnswerPollMutationVariables
  >(ANSWER_POLL);

  const { track } = useContext(TRACKING_CONTEXT);
  const currentEvent = useSelector(selectCurrentEvent);

  const { loading, data, error, refetch, updateQuery } = useQuery<
    GetEventPollsQuery,
    GetEventPollsQueryVariables
  >(GET_POLLS, {
    variables: {
      eventId,
      includeUserAnswers: isHost,
    },
  });

  useEffect(() => {
    if (isHost === undefined) {
      return;
    }

    refetch({ includeUserAnswers: isHost });
  }, [isHost, refetch]);

  if (loading) {
    return null;
  }

  if (error) {
    return null;
  }

  if (!data || data.eventPolls.__typename !== "EventPolls") {
    if (data?.eventPolls.__typename === "GQLError") {
      return <div> {data.eventPolls.message} </div>;
    }
    return null;
  }
  if (!user) {
    return null;
  }

  const onSubmit = async (option: EventPollOption, pollId: number) => {
    trackAction(Actions.POLLS_SUBMIT_ANSWER);

    const results = await answerPoll({
      variables: {
        optionId: option.id,
        pollId,
      },
    });

    if (results.data?.answerPoll.__typename === "EventPoll") {
      const userAnswer = results.data?.answerPoll.selectedAnswer;
      const pollType = results.data?.answerPoll.type;

      track(Events.POLL_ANSWERED, {
        eventId,
        eventType: currentEvent?.eventType,
        pollType,
        answer: userAnswer,
        ...(userAnswer !== undefined &&
          pollType !== EventPollType.Poll && {
            isQuizCorrect:
              results.data?.answerPoll.correctAnswers.includes(userAnswer),
          }),
      });
    }

    updateQuery((prev, _options) => {
      if (
        prev.eventPolls.__typename !== "EventPolls" ||
        results.data?.answerPoll.__typename !== "EventPoll"
      ) {
        dispatch(
          addErrorNotification({
            message: t("poll.answer.error"),
          }),
        );

        return prev;
      }

      const answeredPollId = results.data.answerPoll.id;

      const pollIndex = prev.eventPolls.polls.findIndex(
        (poll) => poll.id === answeredPollId,
      );

      return updatePoll(prev, pollIndex, results.data.answerPoll);
    });

    return results.data;
  };

  const pollsAndQuizzes: Record<
    EventPollStatus,
    Record<EventPollType, EventPoll[]>
  > = data.eventPolls.polls.reduce(
    (quizzesAndPolls, pollOrQuiz) => ({
      ...quizzesAndPolls,
      [pollOrQuiz.status]: {
        ...quizzesAndPolls[pollOrQuiz.status],
        [pollOrQuiz.type]: [
          ...quizzesAndPolls[pollOrQuiz.status][pollOrQuiz.type],
          pollOrQuiz,
        ],
      },
    }),
    {
      [EventPollStatus.Closed]: { POLL: [], QUIZ: [] },
      [EventPollStatus.Draft]: { POLL: [], QUIZ: [] },
      [EventPollStatus.Ongoing]: { POLL: [], QUIZ: [] },
    },
  );

  return (
    <PollTabContainer
      eventId={eventId}
      onSubmit={onSubmit}
      pollsAndQuizzes={pollsAndQuizzes}
    />
  );
};
