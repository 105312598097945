import { EventAgendaColorKeys } from "./types";

export interface IEventAgendaColor {
  label: string;
  color: string;
  hidden: boolean;
}

export const EventAgendaColor: Record<EventAgendaColorKeys, IEventAgendaColor> =
  {
    BLUE: { label: "eventAgenda:color.blue", color: "#5FC8FF", hidden: false },
    ORANGE: {
      label: "eventAgenda:color.orange",
      color: "#FF905F",
      hidden: false,
    },
    VIOLET: {
      label: "eventAgenda:color.violet",
      color: "#655FFF",
      hidden: false,
    },
    PINK: { label: "eventAgenda:color.pink", color: "#F95FFF", hidden: false },
    // RED: {label: 'Red', color: '#FF715F'},
    RED: { label: "eventAgenda:color.green", color: "#07BF00", hidden: true }, // for users who already created items with red
    GREEN: {
      label: "eventAgenda:color.green",
      color: "#07BF00",
      hidden: false,
    },
    BREAK: {
      label: "eventAgenda:color.break",
      color: "#BEBEBE",
      hidden: false,
    },
  };

export const MIN_AGENDA_DURATION = 5;
export const MAX_AGENDA_DURATION = 12 * 60;
