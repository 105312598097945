import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "store";

export const selectTheaterState = (state: RootState) => state.theater;

export const selectCurrentTheater = createSelector(
  selectTheaterState,
  (theaterState) => theaterState.currentTheater,
);

export const selectTheaterSpaces = createSelector(
  selectTheaterState,
  (theaterState) => theaterState.theaterSpaces,
);

export const selectTheaterTemplate = createSelector(
  selectCurrentTheater,
  (currentTheater) => currentTheater?.template,
);

export const selectCurrentTheaterId = createSelector(
  selectCurrentTheater,
  (currentTheater) => currentTheater?.id ?? null,
);

export const selectThemeAndTemplate = createSelector(
  selectCurrentTheater,
  (currentTheater) => ({
    theme: currentTheater?.theme,
    template: currentTheater?.template,
  }),
);

export const selectIsQaRequired = createSelector(
  selectCurrentTheater,
  (currentTheater) => currentTheater?.isQARequired ?? null,
);

export const selectTheaterCompany = createSelector(
  selectCurrentTheater,
  (currentTheater) => currentTheater?.company ?? null,
);

export const selectIsInHostOnboarding = createSelector(
  selectTheaterState,
  (theaterState) => theaterState.isInHostOnboarding,
);
