import { SerializedError } from "@reduxjs/toolkit";

export const deserializeError = (serializedError: SerializedError): Error => {
  const error = new Error(serializedError.message);

  error.stack = serializedError.stack;

  if (typeof serializedError.name === "string") {
    error.name = serializedError.name;
  }

  return error;
};
