import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import isEqual from "lodash/isEqual";

export interface EventUser {
  userId: string;
  spaceId: string;
}

export interface EventUserPresenceState {
  isOnline: boolean;
  eventUsers: EventUser[];
}

export const initialState: EventUserPresenceState = {
  isOnline: false,
  eventUsers: [],
};

const eventUserPresenceSlice = createSlice({
  initialState,
  name: "eventUserPresence",
  reducers: {
    resetPresenceInfo: () => ({ ...initialState }),
    setEventUsers: (state, action: PayloadAction<EventUser[]>) => {
      if (!isEqual(state.eventUsers, action.payload)) {
        state.eventUsers = action.payload;
      }
    },
  },
});

export const { resetPresenceInfo, setEventUsers } =
  eventUserPresenceSlice.actions;

export default eventUserPresenceSlice.reducer;
