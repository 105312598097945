import { Typography } from "@remo-co/ui-core/src/components/Typography";
import { Divider } from "@remo-co/ui-core/src/components/Divider";
import { ArrowForwardIos } from "@remo-co/ui-core/src/icons/ArrowForwardIos";
import classNames from "classnames";
import { useStyles } from "./styles";

export const PaginationButton = ({
  canPaginateForward,
  canPaginateBackward,
  paginateForward,
  paginateBackward,
  currentPage,
  totalPages,
  direction,
}: {
  direction: "vertical" | "horizontal";
  canPaginateForward: boolean;
  canPaginateBackward: boolean;
  paginateForward: () => void;
  paginateBackward: () => void;
  currentPage: number;
  totalPages: number;
}): JSX.Element => {
  const styles = useStyles();

  return (
    <div className={styles.root} data-testid="pagination-button">
      <button
        type="submit"
        disabled={!canPaginateBackward}
        onClick={paginateBackward}
        className={classNames(styles.arrowButton, {
          [styles.leftIcon]: direction === "horizontal",
          [styles.upIcon]: direction === "vertical",
          [styles.iconDisabled]: !canPaginateBackward,
        })}
      >
        <ArrowForwardIos
          className={classNames(styles.icon, {
            [styles.iconDisabled]: !canPaginateBackward,
          })}
        />
      </button>
      <Typography color="textSecondary" className={styles.label}>
        {currentPage} / {totalPages}
      </Typography>
      <button
        type="submit"
        disabled={!canPaginateForward}
        onClick={paginateForward}
        className={classNames(styles.arrowButton, {
          [styles.rightIcon]: direction === "horizontal",
          [styles.downIcon]: direction === "vertical",
          [styles.iconDisabled]: !canPaginateForward,
        })}
      >
        <ArrowForwardIos
          className={classNames(styles.icon, {
            [styles.iconDisabled]: !canPaginateForward,
          })}
        />
      </button>
      <Divider
        orientation="vertical"
        variant="middle"
        flexItem
        className={styles.divider}
      />
    </div>
  );
};
