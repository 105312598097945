import { CSSProperties, ReactNode } from "react";

export interface IExportAttendeesColumnsMeta {
  title: string;
  field: string;
  export: boolean;
  hidden?: boolean;
  render?: (args: Record<string, string>) => ReactNode;
  renderExport?: (args: Record<string, string>) => string;
  cellStyle?: CSSProperties;
}

export enum ContactChannelTypes {
  EMAIL = "email",
  OTHER = "other",
  PHONE = "phone",
}

export interface IContactChannel {
  type: ContactChannelTypes;
  value: string;
}
