import { Tooltip } from "@remo-co/ui-core/src/components/Tooltip";
import { Autorenew } from "@remo-co/ui-core/src/icons/Autorenew";
import { Block } from "@remo-co/ui-core/src/icons/Block";
import { CheckCircleOutline } from "@remo-co/ui-core/src/icons/CheckCircleOutline";
import { Drafts } from "@remo-co/ui-core/src/icons/Drafts";
import { HighlightOff } from "@remo-co/ui-core/src/icons/HighlightOff";
import { HourglassEmpty } from "@remo-co/ui-core/src/icons/HourglassEmpty";
import { useI18n } from "i18n";
import { ReactComponent as EmailClickIcon } from "../assets/email-clicked.inline.svg";

export interface IEmailStatusProps {
  emailStatus: EventMemberInviteEmailStatus;
}

// Same as in EventMemberInvite.interfaces.ts in server

export enum EventMemberInviteEmailStatus {
  BOUNCED = "bounce",
  CLICKED = "click",
  DEFERRED = "deferred",
  DELIVERED = "delivered",
  DROPPED = "dropped",
  OPENED = "open",
  PROCESSING = "processing",
}

const EMAIL_STATUS_CONTENT = {
  [EventMemberInviteEmailStatus.PROCESSING]: "manageEvent:email.sending",
  [EventMemberInviteEmailStatus.DROPPED]: "manageEvent:email.user.blocked",
  [EventMemberInviteEmailStatus.DEFERRED]: "manageEvent:email.delivery.failed",
  [EventMemberInviteEmailStatus.BOUNCED]: "manageEvent:email.invalid",
  [EventMemberInviteEmailStatus.DELIVERED]:
    "manageEvent:email.deliver.spam.inbox",
  [EventMemberInviteEmailStatus.OPENED]: "manageEvent:email.opened",
  [EventMemberInviteEmailStatus.CLICKED]:
    "manageEvent:registration.link.checked",
};
const EmailStatus = ({ emailStatus }: IEmailStatusProps) => {
  const { t } = useI18n(["manageEvent"]);

  if (emailStatus) {
    let icon;

    switch (emailStatus) {
      case EventMemberInviteEmailStatus.PROCESSING:
        icon = <HourglassEmpty />;
        break;
      case EventMemberInviteEmailStatus.DROPPED:
        icon = <Block color="error" />;
        break;
      case EventMemberInviteEmailStatus.DEFERRED:
        icon = <Autorenew />;
        break;
      case EventMemberInviteEmailStatus.BOUNCED:
        icon = <HighlightOff color="error" />;
        break;
      case EventMemberInviteEmailStatus.DELIVERED:
        icon = <CheckCircleOutline color="primary" />;
        break;
      case EventMemberInviteEmailStatus.OPENED:
        icon = <Drafts color="primary" />;
        break;
      case EventMemberInviteEmailStatus.CLICKED:
        icon = <EmailClickIcon />;
        break;
      default:
        break;
    }

    if (icon) {
      return (
        <Tooltip
          title={t(EMAIL_STATUS_CONTENT[emailStatus]) ?? ""}
          placement="top"
          data-testid={`email-status-${emailStatus}`}
        >
          {icon}
        </Tooltip>
      );
    }
  }

  return null;
};

export default EmailStatus;
