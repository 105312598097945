import { getVar } from "config";
import { DEFAULT_LOGIN_OPTIONS } from "@remo-co/types";
import { ICompanySettings } from "modules/company/types";
import ActionTypes from "./actionTypes";
import { defaultMessage } from "../../eventForm/constants";

export const initialState: Partial<ICompanySettings> & { isLoaded: boolean } = {
  isLoaded: false,
  exitURL: getVar("REACT_APP_EXIT_PAGE"),
  disableVPNCheck: false,
  discoveryDisabledByDefault: false,
  defaultOnboardText: defaultMessage,
  defaultOnboardMedia: {
    type: "image",
    url: "",
  },
  loginOptions: DEFAULT_LOGIN_OPTIONS,
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const reducer = (state = initialState, action: any = {}) => {
  const { payload } = action;

  switch (action.type) {
    case ActionTypes.SET_COMPANY_SETTINGS:
      return {
        ...state,
        ...payload.companySettings,
        isLoaded: true,
      };
    case ActionTypes.RESET_COMPANY_SETTINGS:
      return initialState;
    case ActionTypes.SET_LOGIN_OPTIONS:
      return { ...state, loginOptions: payload };
    default:
      return state;
  }
};

export default reducer;
