import { Theme } from "@remo-co/ui-core/src/types";
import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";

export const useStyles = makeStyles<Theme>((theme) => ({
  hostControls: {
    marginTop: theme.spacing(2),
  },
  hostControlsIcons: {
    color: theme.palette.dark.main,
    display: "flex",
    justifyContent: "space-between",
    width: 70,
  },
}));
