const TOTAL_ROWS = 2;

export function traverseSlots(
  originalSlots: (string | null)[],
  transform: (slot: string | null) => string | null,
): (string | null)[] {
  if (originalSlots.length === 0) return originalSlots;

  const slots = [...originalSlots];
  const itemsPerRow = slots.length / 2;

  // eslint-disable-next-line no-plusplus
  for (let rowIndex = TOTAL_ROWS - 1; rowIndex >= 0; rowIndex--) {
    const middle = rowIndex * itemsPerRow + Math.floor(itemsPerRow / 2);

    slots[middle] = transform(slots[middle]);

    // eslint-disable-next-line no-plusplus
    for (let i = 1; i <= Math.floor(itemsPerRow / 2); i++) {
      slots[middle - i] = transform(slots[middle - i]);

      if (itemsPerRow % 2 === 0 && i === Math.floor(itemsPerRow / 2)) {
        break;
      }

      slots[middle + i] = transform(slots[middle + i]);
    }
  }

  return slots;
}
