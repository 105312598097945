import { OS_NAMES } from "modules/systemCheck/constants";

export const getMicCamTroubleshootLink = (os: OS_NAMES) => {
  switch (os) {
    case OS_NAMES.MAC:
      return "url:troubleshoot.mic.cam.macos";
    case OS_NAMES.WINDOWS:
      return "url:troubleshoot.mic.cam.windows";
    case OS_NAMES.IOS:
      return "url:troubleshoot.mic.cam.ios";
    case OS_NAMES.ANDROID:
      return "url:troubleshoot.mic.cam.android";

    default:
      return "url:troubleshoot.mic.cam.windows";
  }
};
