import moment from "moment";

const determineEventRecency = ({
  eventTime,
  currentTime,
  maxAgeInMinutes: limit,
}: {
  eventTime: Date;
  currentTime: number;
  maxAgeInMinutes: number;
}): boolean => {
  const formattedEventTime = moment(eventTime);
  const maxAge = moment(currentTime).add(-limit, "minutes");
  return formattedEventTime.isAfter(maxAge);
};

export default determineEventRecency;
