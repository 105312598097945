import { ContentWithMapping } from "graphql/generated";
import { ContentWithConsolidatedMapping } from "../../types";

export const consolidateContentMapping = (
  contents: ContentWithMapping[],
): ContentWithConsolidatedMapping[] =>
  Object.values(
    contents.reduce((record, content) => {
      const { floor, slot, __typename, ...contentData } = content;

      const existingRecord = record[content.id] ?? {};

      return {
        ...record,
        [content.id]: {
          ...existingRecord,
          ...contentData,
          mappings: [...(existingRecord.mappings || []), { floor, slot }],
        },
      };
    }, {} as Record<string, ContentWithConsolidatedMapping>),
  );
