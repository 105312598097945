import { useEffect } from "react";
import { useSelector } from "react-redux";
import { selectCurrentEventId } from "modules/event/selectors";
import {
  selectIsCountdownDialogVisible,
  selectIsInProgressDialogVisible,
} from "modules/enforceTableAssignment/redux";
import { useEnforceTableAssignmentFirestore } from "../../hooks/useEnforceTableAssignmentFirestore";
import { EnforceCountdownDialog } from "../EnforceCountdownDialog";
import { EnforceInProgressDialog } from "../EnforceInProgressDialog";

const EnforceTableAssignmentContainer = (): JSX.Element | null => {
  const currentEventId = useSelector(selectCurrentEventId);
  const isCountdownDialogVisible = useSelector(selectIsCountdownDialogVisible);
  const isInProgressDialogVisible = useSelector(
    selectIsInProgressDialogVisible,
  );
  const { subscribeToEvent, unsubscribeFromEvent } =
    useEnforceTableAssignmentFirestore(currentEventId);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (currentEventId) {
      subscribeToEvent();

      return unsubscribeFromEvent;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentEventId]);

  return (
    <>
      {isCountdownDialogVisible && <EnforceCountdownDialog />}
      {isInProgressDialogVisible && <EnforceInProgressDialog />}
    </>
  );
};

export default EnforceTableAssignmentContainer;
