import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";

export const useStyles = makeStyles((theme) => ({
  description: {
    color: theme.palette.blue.darkShade1,
    marginBottom: 40,
  },
  disabled: {
    border: `1px solid ${theme.palette.gray.light}`,
    borderRadius: "5px",
    padding: `${theme.spacing(2)} ${theme.spacing(4)}`,
    marginTop: "2rem",
  },
  promptToSave: {
    border: `1px solid ${theme.palette.gray.light}`,
    borderRadius: "5px",
    padding: `${theme.spacing(2)} ${theme.spacing(4)}`,
    margin: "1rem 0",
  },
  message: {
    marginLeft: "1rem",
  },
  unassignedCount: {
    color: theme.palette.blue.darkShade1,
  },
}));

export default useStyles;
