import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import {
  ICompany,
  ICompanyReduxState,
  ICompanyServicePlan,
  IUserCompany,
} from "../types";

export const initialState: ICompanyReduxState = {
  company: null,
  companies: [],
  companyPlan: null,
  companySettings: null,
  isInternalCompany: false,
  multipleCompanyView: false,
  isLoading: false,
};

export const companySlice = createSlice({
  initialState,
  name: "company",
  reducers: {
    setCompany: (state, action: PayloadAction<ICompany | null>) => ({
      ...state,
      company: action.payload,
    }),
    setCompanies: (state, action: PayloadAction<IUserCompany[]>) => ({
      ...state,
      companies: action.payload,
    }),

    setMultipleCompanyView: (state, action: PayloadAction<boolean>) => ({
      ...state,
      multipleCompanyView: action.payload,
    }),

    setCompanyPlanSettings: (
      state,
      action: PayloadAction<ICompanyServicePlan | null>,
    ) => ({ ...state, companyPlan: action.payload }),

    setIsInternalCompany: (state, action: PayloadAction<boolean>) => ({
      ...state,
      isInternalCompany: action.payload,
    }),
    setIsLoading: (state, action: PayloadAction<boolean>) => ({
      ...state,
      isLoading: action.payload,
    }),
    reset: () => initialState,
  },
});

export const {
  setCompany,
  setCompanies,
  setMultipleCompanyView,
  setCompanyPlanSettings,
  setIsInternalCompany,
  setIsLoading,
  reset,
} = companySlice.actions;

export default companySlice.reducer;
