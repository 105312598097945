import { asyncResult } from "services/firebaseService/firebase";
import { ref, update } from "firebase/database";
import { database } from "services/firebaseService";
import { WhiteboardDataStatus } from "./constants";

// Whiteboard
const getMiroBoardDocRef = (usageId: string) =>
  ref(database, `Miro/Whiteboard/Board/${usageId}`);

const getMiroBoardStatusDocRef = (usageId: string) =>
  ref(database, `Miro/Whiteboard/Board/${usageId}/status`);

const getMiroBoard = async (
  usageId: string,
): Promise<{ boardCode?: string; miroBoardId?: string }> => {
  const docRef = getMiroBoardDocRef(usageId);

  return asyncResult(docRef);
};

const updateMiroBoardStatus = async (
  usageId: string,
  status: WhiteboardDataStatus,
): Promise<unknown> =>
  update(getMiroBoardDocRef(usageId), {
    usageId,
    status,
  });

const updateMiroBoardCode = async (
  usageId: string,
  boardCode: string,
  miroBoardId?: string,
  meta = {},
  // eslint-disable-next-line max-params
): Promise<unknown> => {
  if (!usageId && !boardCode) {
    return;
  }
  // eslint-disable-next-line consistent-return
  return update(getMiroBoardDocRef(usageId), {
    usageId,
    boardCode,
    miroBoardId: miroBoardId || "",
    meta,
    status: "SUCCESS",
  });
};

export default {
  getMiroBoardDocRef,
  getMiroBoard,
  updateMiroBoardCode,
  updateMiroBoardStatus,
  getMiroBoardStatusDocRef,
};
