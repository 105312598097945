import { useSelector } from "react-redux";
import { selectIsInBroadcast } from "modules/broadcast/redux/selectors";
import { DailyTracker } from "modules/audioVideo/components/DailyTracker";
import { DailyTranscription } from "modules/transcriptionV2";
import useEventSplitTreatment from "modules/split/useEventSplitTreatment";
import { EventFeatures } from "services/splitService/features";
import { ActiveSpeakerSubscriber } from "modules/daily/ActiveSpeakerSubscriber";
import { TileReceiveSettings } from "modules/audioVideo/hooks/useDailyPerformance";
import { Conversation } from "./Views/Conversation/Conversation";
import { Presentation } from "./Views/Presentation/Presentation";
import { PresentationSpeakersCalculator } from "../PresentationSpeakersCalculator";

const DailyContainer = (): JSX.Element => {
  const { featureEnabled: transcriptionV2Enabled } = useEventSplitTreatment(
    EventFeatures.TRANSCRIPTION_V2,
  );
  const isInBroadcast = useSelector(selectIsInBroadcast);

  return (
    <>
      {isInBroadcast ? <Presentation /> : <Conversation />}
      <DailyTracker />
      <PresentationSpeakersCalculator />
      {transcriptionV2Enabled && <DailyTranscription />}
      <ActiveSpeakerSubscriber />
      <TileReceiveSettings />
    </>
  );
};

export default DailyContainer;
