import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "store/index";

const selectTranscriptionV2 = (state: RootState) => state.transcriptionV2;

export const selectIsTranscriptionActive = createSelector(
  selectTranscriptionV2,
  ({ isActive }) => isActive,
);

export const selectTranscriptions = createSelector(
  selectTranscriptionV2,
  ({ transcriptions }) => transcriptions,
);
