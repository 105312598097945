import { useI18n } from "i18n";
import { useSelector } from "react-redux";
import {
  selectHasAudioPermissions,
  selectHasVideoPermissions,
} from "modules/deviceInfo/slice/selectors";
import { useAppDispatch } from "store/hooks";
import { useCallback, MouseEvent } from "react";
import { BROWSER_NAMES } from "modules/systemCheck/constants";
import { Button } from "@remo-co/ui-core/src/components/Button";
import { setIsSafariAutoplayResumed } from "modules/session/redux/slice";
import { selectIsSafariAutoplayResumed } from "modules/session/redux/selectors";
import { selectBrowserName } from "modules/systemCheck/redux/selectors";
import { VolumeUp } from "@remo-co/ui-core/src/icons/VolumeUp";
import { useDevices } from "modules/audioVideo/hooks/useDevices/useDevices";
import { useStyles } from "./styles";

export const SafariAutoplayCheck = () => {
  const { t } = useI18n();
  const styles = useStyles();
  const dispatch = useAppDispatch();
  const browser = useSelector(selectBrowserName);
  const hasAudioPermissions = useSelector(selectHasAudioPermissions);
  const hasVideoPermissions = useSelector(selectHasVideoPermissions);
  const isSafariAutoplayResumed = useSelector(selectIsSafariAutoplayResumed);
  const { microphones, cameras } = useDevices();

  const onPlay = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      dispatch(setIsSafariAutoplayResumed(true));
    },
    [dispatch],
  );

  const isSafari = [BROWSER_NAMES.SAFARI, BROWSER_NAMES.MOBILE_SAFARI].includes(
    browser,
  );
  const hasDevices = microphones.length > 0 || cameras.length > 0;
  const hasPermissionsAndDevices =
    hasDevices && (hasAudioPermissions || hasVideoPermissions);

  if (!isSafari || hasPermissionsAndDevices || isSafariAutoplayResumed)
    return null;

  return (
    <div className={styles.backdrop}>
      <Button startAdornment={<VolumeUp />} onClick={onPlay}>
        {t("common:play.audio")}
      </Button>
    </div>
  );
};
