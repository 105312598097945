import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";

export const useStyles = makeStyles((theme) => ({
  heading: {
    paddingBottom: theme.spacing(1),
  },
  closeButton: {
    position: "absolute",
    top: theme.spacing(1),
    right: theme.spacing(1),
  },
  title: {
    fontWeight: 700,
  },
  paper: {
    borderRadius: 10,
    padding: theme.spacing(2),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(3),
  },
  helpLink: {
    marginTop: theme.spacing(2),
  },
  assurance: {
    marginTop: theme.spacing(2),
  },
  followUp: {
    marginTop: theme.spacing(2),
  },
  promptContent: {
    paddingBottom: theme.spacing(1),
  },
}));
