import { Theme } from "@remo-co/ui-core/src/types";
import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";

export const BOTTOM_BAR_HEIGHT = 90;

export const useStyles = makeStyles<Theme>((theme) => ({
  mobileContainer: {
    display: "inline-flex",
    justifyContent: "center",
    height: `${BOTTOM_BAR_HEIGHT}px`,
    alignItems: "center",
    backgroundColor: theme.palette.white,
    pointerEvents: "all",
    bottom: 0,
    left: 0,
    right: 0,
    position: "fixed",
  },
  joinRoomContainer: {
    pointerEvents: "all",
    justifyContent: "center",
    width: "100vw",
    margin: "0 auto",
    backgroundColor: theme.palette.white,
    boxShadow: "0px 0px 0px 0px #00000033",
    display: "inline-flex",
    flexDirection: "row",
    borderRadius: 0,
    minHeight: 90,
    alignItems: "center",
    position: "fixed",
    bottom: 0,
  },
}));
