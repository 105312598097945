import React from "react";
import "./Sponsors.scss";
import { Dialog } from "@remo-co/ui-core/src/components/Dialog";
import { DialogContent } from "@remo-co/ui-core/src/components/DialogContent";
import { DialogTitle } from "@remo-co/ui-core/src/components/DialogTitle";
import { IconButton } from "@remo-co/ui-core/src/components/IconButton";
import { Typography } from "@remo-co/ui-core/src/components/Typography";
import { Button } from "@remo-co/ui-core/src/components/Button";
import { Container } from "@remo-co/ui-core/src/components/Container";
import { ArrowBack } from "@remo-co/ui-core/src/icons/ArrowBack";
import { Close } from "@remo-co/ui-core/src/icons/Close";
import { SHOW_MIC_CAM_PAGE } from "modules/eventButtons/components/LeaveEventButton";
import { useI18n } from "i18n";
import classNames from "classnames";
import { useCompanyPlanSettings } from "modules/companyPlanSettings";
import useEventTracking from "modules/eventTracking/hooks/useEventTracking";
import { ISponsor } from "modules/event/types";
import { ISlot } from "modules/theater/types";
import SponsorsMedia from "../svgMapView/templateParts/SponsorsMedia";
import { addMemberToSponsor } from "../../services/apiService/apis";
import { useStyles } from "./styles";

interface Props {
  sponsors: ISponsor[];
  slots: ISlot[];
  showSlots?: boolean; // For display purpose in create sponsor form
  highlight?: number;
  showSelected?: (index: number) => void;
  eventId?: string;
}

const Sponsors = ({
  sponsors,
  slots,
  showSlots,
  highlight,
  showSelected = () => {}, // default if nothing is passed
  eventId,
}: Props): JSX.Element | null => {
  const { t } = useI18n(["template"]);
  const [selectedSponsor, setSelectedSponsor] = React.useState<number | null>(
    null,
  );
  const { getMaxAdsPerEvent } = useCompanyPlanSettings();
  const maxAllowedAds = getMaxAdsPerEvent();
  const { trackSponsorBannerClick } = useEventTracking();

  const styles = useStyles();

  const openSponsorModal = (sponsor: number) => {
    if (!showSlots) {
      setSelectedSponsor(sponsor);
      const sponsorId = sponsors[sponsor]._id;
      if (sponsorId) {
        trackSponsorBannerClick(sponsorId);
      }
    }
  };

  const handleClose = () => {
    setSelectedSponsor(null);
  };

  const openCTALink = () => {
    // Track clicks if it is not from event settings
    if (!showSlots) {
      // to avoid breaking behavior here, just casting types.
      // probably should guard against eventId and selectedSponsor being nullable
      addMemberToSponsor(
        eventId as string,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error hotfix issue on a saturday, can't resolve types
        sponsors[selectedSponsor as number]._id,
      );
    }
    // If live site, open in same window
    // If the url is the same origin and without ending with "/register" then dont open in new window.
    const { ctaLink } = sponsors[selectedSponsor as number];

    if (!ctaLink) {
      handleClose();

      return;
    }
    const prefix = `${window.location.origin}/e/`;
    const suffix = "/register";
    const isSameDomain = ctaLink.startsWith(prefix);
    const isRegisterPage = ctaLink.endsWith(suffix);
    const opener = isSameDomain && !isRegisterPage ? "_self" : "_blank";

    // Do not show MIC / CAM when joining other remo event from sponsor tab.
    // NOTE: this if condition should be deleted when we decide on not to show MIC/CAM page on entering every event.
    if (opener === "_self") {
      sessionStorage.setItem(SHOW_MIC_CAM_PAGE, "false");
    }

    window.open(
      sponsors[selectedSponsor as number].ctaLink,
      opener,
      "noopener",
    );
  };

  // This event does not have any info about the sponsors, so dont build anything
  if (!showSlots && !sponsors) {
    return null;
  }

  return (
    <>
      {showSlots
        ? slots.map((slot, index: number) => (
            <div
              data-testid="sponsor-slot"
              className={classNames(
                styles.root,
                styles.rootSlot,
                styles.active,
                {
                  highlight: highlight === index,
                },
              )}
              style={{
                left: slot.x,
                top: slot.y,
                width: slot.width,
                height: slot.height,
              }}
              key={`${slot.x}-${slot.y}`}
              onClick={() => showSelected(index)}
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  showSelected(index);
                }
              }}
              role="button"
              tabIndex={0}
            >
              {index + 1}
            </div>
          ))
        : null}
      {sponsors.map((sponsor: ISponsor, index: number) => {
        const slot = slots[Number(sponsor.slot)];
        const isMaxed = maxAllowedAds <= index;

        // If more ads are added/or changed layout from 6 to 4, thn we might have extra ads, which we should not show
        // If more than allowed as per company plan
        if (!slots[index] || !slot || isMaxed) {
          return null;
        }

        return (
          <div
            data-testid={`sponsors-${index}`}
            onClick={() => openSponsorModal(index)}
            onKeyDown={(event) => {
              if (event.key === "Enter") {
                openSponsorModal(index);
              }
            }}
            role="button"
            tabIndex={0}
            key={sponsor._id}
            className={styles.root}
            style={{
              left: slot.x,
              top: slot.y,
              width: slot.width,
              height: slot.height,
            }}
          >
            {sponsor.logo ? (
              <img
                src={sponsor.logo}
                alt={sponsor.name}
                className={styles.sponsorImg}
              />
            ) : (
              sponsor.logo
            )}
          </div>
        );
      })}
      {selectedSponsor !== null && sponsors[selectedSponsor] && (
        <Dialog
          data-testid="sponsor-dialog"
          onClose={handleClose}
          aria-labelledby={t("template:sponsor.modal")}
          open
          className="sponsor-modal"
          scroll="paper"
          fullWidth
          maxWidth="sm"
        >
          <DialogTitle>
            <Typography variant="h2">
              {sponsors[selectedSponsor].name}
            </Typography>
            <IconButton
              data-testid="cancel-button-icon"
              onClick={handleClose}
              size="large"
            >
              <Close />
            </IconButton>
          </DialogTitle>
          <DialogContent style={{ overflow: "overlay" }}>
            <SponsorsMedia selectedSponsor={sponsors[selectedSponsor]} />
          </DialogContent>
          <Container
            className="sponsors-footer-buttons"
            style={
              !sponsors[selectedSponsor].ctaLabel
                ? { justifyContent: "center" }
                : {}
            }
          >
            <Button
              data-testid="cancel-button-bottom"
              startAdornment={<ArrowBack className="back-arrow" />}
              variant="ghost"
              color="dark"
              onClick={handleClose}
              size="md"
            >
              {t("template:back.event")}
            </Button>
            {sponsors[selectedSponsor].ctaLabel ? (
              <Button
                data-testid="cta-test-id"
                variant="primary"
                color="blue"
                onClick={openCTALink}
                size="md"
                style={{ minWidth: "150px" }}
              >
                {sponsors[selectedSponsor].ctaLabel}
              </Button>
            ) : null}
          </Container>
        </Dialog>
      )}
    </>
  );
};

export default Sponsors;
