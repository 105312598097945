import { Map } from "immutable";
import { IUserStatus } from "types";
import ActionTypes from "./constants";

export const initialState: Map<string, IUserStatus> = Map();
const userStatusReducer = (
  state = initialState,
  action: { type: string; payload: Map<string, IUserStatus> | string },
): Map<string, IUserStatus> => {
  if (!action || !action.payload) {
    return state;
  }
  switch (action.type) {
    case ActionTypes.SYNC_USER_STATUS:
      return state.merge(action.payload as Map<string, IUserStatus>);
    case ActionTypes.REMOVE_USER_STATUS:
      return state.delete(action.payload as string);
    case ActionTypes.RESET:
      return Map<string, IUserStatus>();
    default:
      return state;
  }
};

export default userStatusReducer;
