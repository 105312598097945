import { useState, useRef } from "react";
import { Button } from "@remo-co/ui-core/src/components/Button";
import { Popover } from "@remo-co/ui-core/src/components/Popover";
import { Typography } from "@remo-co/ui-core/src/components/Typography";
import { ListItem } from "@remo-co/ui-core/src/components/ListItem";
import { MoreVert } from "@remo-co/ui-core/src/icons/MoreVert";
import { useI18n } from "i18n";
import { queryClient } from "services/apiService/queryClient";
import { useRenameRoom } from "modules/room/hooks";
import { EVENT_ASSIGN_TABLES_QUERY_KEY } from "modules/assignTables/constants";
import zIndexes from "zIndexes.module.scss";
import { TableWithId } from "../../types";

interface Props {
  unassignTableAttendees: () => void;
  table: TableWithId;
  eventId: string;
  hasMultipleFloors: boolean;
  selectedPreset: string;
}

const TableActionsButton = ({
  unassignTableAttendees,
  table,
  eventId,
  hasMultipleFloors,
  selectedPreset,
}: Props): JSX.Element => {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const { showDialog } = useRenameRoom();
  const buttonRef = useRef<null | HTMLButtonElement>(null);
  const { t } = useI18n("manageEvent");

  const onRenameDialogClose = () => {
    queryClient.refetchQueries({
      queryKey: [EVENT_ASSIGN_TABLES_QUERY_KEY, eventId, selectedPreset],
    });
  };

  const handleUnassignTableAttendees = () => {
    unassignTableAttendees();
    setMenuOpen(false);
  };

  const handleEditTableName = () => {
    showDialog({
      currentRoomName: table.name,
      roomId: table.id,
      roomCode: table.code,
      currentEventId: eventId,
      hasMultipleFloors,
      onClose: onRenameDialogClose,
    });
    setMenuOpen(false);
  };

  return (
    <>
      <Button
        ref={buttonRef}
        variant="secondary"
        color="gray"
        size="sm"
        isIconButton
        onClick={() => setMenuOpen(true)}
        data-testid="actions-button"
      >
        <MoreVert fontSize="small" />
      </Button>
      <Popover
        open={isMenuOpen}
        onClose={() => setMenuOpen(false)}
        anchorEl={buttonRef.current}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        style={{ zIndex: zIndexes.modalZIndex }}
      >
        <ListItem button onClick={handleUnassignTableAttendees}>
          <Typography variant="h6">{t("unassign.all.attendees")}</Typography>
        </ListItem>
        <ListItem button onClick={handleEditTableName}>
          <Typography variant="h6">{t("edit.table.name")}</Typography>
        </ListItem>
      </Popover>
    </>
  );
};

export default TableActionsButton;
