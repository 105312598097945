import {
  sendDeleteRequest,
  sendGetRequest,
  sendPostRequest,
} from "services/apiService";
import { generateIncludesQueryParam } from "helpers/urlHelper";
import { EventRole } from "modules/event/constants";
import { Attendee, IInvitee } from "./types";

interface IEventAttendeesListResponse extends IGenericAPIResponse {
  attendees?: Attendee[];
  message?: string;
}

interface IEventSpeakersListResponse extends IGenericAPIResponse {
  speakers?: IInvitee[];
  message?: string;
}

interface IEventHelpersListResponse extends IGenericAPIResponse {
  helpers?: IInvitee[];
  message?: string;
}

const getEventAttendeeList = (
  eventId: string,
  withAttendance?: boolean,
  withRegistrationAnswers?: boolean,
  role?: EventRole,
): Promise<IEventAttendeesListResponse> =>
  sendGetRequest(`/event-member/${eventId}/attendee/
list?${role ? `role=${role}&` : ""}${generateIncludesQueryParam({
    attendance: withAttendance,
    registrationAnswers: withRegistrationAnswers,
  })}`);

export const checkEmailInvited = (
  eventId: string,
  email: string,
): Promise<{
  isSuccess: boolean;
  data: { invited: boolean; isSpeaker?: boolean; isPrivate: boolean };
}> => sendGetRequest(`/event-member/check-email-invited/${eventId}/${email}`);

const updateEventAttendeeAccess = (data: {
  eventId: string;
  theaterId: string | undefined;
  userId: string;
  isBlocked: boolean;
}) =>
  sendPostRequest<typeof data, IGenericAPIResponse>(
    `/event-member/${data.eventId}/update-access`,
    data,
  );

const getEventSpeakerList = (
  eventId: string,
): Promise<IEventSpeakersListResponse> =>
  sendGetRequest(`/event-member/${eventId}/speaker/list`);
const revokeSpeakerAccess = (eventId: string, speakerEmail: string) =>
  sendPostRequest<{ speakerEmail: string }, IGenericAPIResponse>(
    `/event-member/${eventId}/speaker/revoke`,
    { speakerEmail },
  );

const getEventHelpersList = (
  eventId: string,
): Promise<IEventHelpersListResponse> =>
  sendGetRequest(`/event-member/${eventId}/helpers/list`);

const removeEventHelper = (eventId: string, email: string) =>
  sendDeleteRequest<{ email: string }, { isSuccess: boolean; message: string }>(
    `/event-member/${eventId}/remove-event-helper`,
    { email },
  );

const addEventHelper = (eventId: string, emails: string[]) =>
  sendPostRequest<
    { emails: string[] },
    {
      isSuccess: boolean;
      message: string;
      helpers: string[];
      managers: string[];
    }
  >(`/event-member/${eventId}/invite-event-helper`, { emails });

export default {
  getEventAttendeeList,
  checkEmailInvited,
  updateEventAttendeeAccess,
  getEventSpeakerList,
  revokeSpeakerAccess,
  getEventHelpersList,
  removeEventHelper,
  addEventHelper,
};
