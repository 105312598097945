import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { LiveStreamData } from "../request";

export interface LiveStreamState {
  streams: LiveStreamData[];
  isLoading: boolean;
  isLiveStreaming: boolean;
}

export const initialState: LiveStreamState = {
  streams: [],
  isLoading: false,
  isLiveStreaming: false,
};

const liveStreamingSlice = createSlice({
  name: "liveStream",
  initialState,
  reducers: {
    setIsLiveStreamLoading: (
      state: LiveStreamState,
      action: PayloadAction<boolean>,
    ) => {
      state.isLoading = action.payload;
    },
    setLiveStreams: (
      state: LiveStreamState,
      action: PayloadAction<LiveStreamData[]>,
    ) => {
      state.streams = action.payload;
    },
    addLiveStreamData: (
      state: LiveStreamState,
      action: PayloadAction<LiveStreamData>,
    ) => {
      state.streams = [...state.streams, action.payload];
    },
    updateLiveStreamData: (
      state: LiveStreamState,
      action: PayloadAction<LiveStreamData>,
    ) => {
      const newState = state.streams.filter(
        (data) => data.id !== action.payload.id,
      );
      state.streams = [...newState, action.payload];
    },
    deleteLiveStreamData: (
      state: LiveStreamState,
      action: PayloadAction<string>,
    ) => {
      const data = state.streams.filter(
        (streamData) => streamData.id !== action.payload,
      );
      state.streams = data;
    },
    setIsLiveStreaming: (
      state: LiveStreamState,
      action: PayloadAction<boolean>,
    ) => {
      state.isLiveStreaming = action.payload;
    },
    resetLiveStream: () => initialState,
  },
});

export const {
  setIsLiveStreamLoading,
  setLiveStreams,
  addLiveStreamData,
  updateLiveStreamData,
  deleteLiveStreamData,
  setIsLiveStreaming,
  resetLiveStream,
} = liveStreamingSlice.actions;

export default liveStreamingSlice.reducer;
