import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";

export const useStyles = makeStyles(() => ({
  content: {
    padding: "0 10px",
  },
  disableHover: {
    "&::before": {
      display: "none",
    },
  },
  disabled: {
    opacity: 0.2,
  },
  menuButton: {
    justifyContent: "space-between",
  },
}));
