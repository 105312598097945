import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "store";

const selectEventContent = (state: RootState) => state.eventContent;

export const selectIsFullScreenEventContentOpen = createSelector(
  selectEventContent,
  (state) => state.isFullScreenEventContentOpen,
);

export const selectFullScreenEventContent = createSelector(
  selectEventContent,
  (state) => state.content,
);
