import moment from "moment";
import { Time, Unit } from "./types";
import { wrapMomentDuration } from "./duration";

const validUnits = ["day", "hour", "minute", "second", "millisecond"] as const;

type ValidUnits = (typeof validUnits)[number];
type AddTimeArg = {
  [key in ValidUnits]?: number;
};

export const add = (time: Time, addTime: AddTimeArg) => {
  const newTime = moment(time);

  Object.entries(addTime).forEach(([unit, amount]: [string, number?]) => {
    const validUnit = validUnits.find((valid) => valid === unit);

    if (validUnit) {
      newTime.add(amount, validUnit);
    }
  });

  return newTime.toDate();
};

export const timeFrom = (startDate: Time, targetDate: Time) =>
  wrapMomentDuration(moment.duration(moment(startDate).diff(targetDate)));

export const deltaToDuration = (time: Time) => timeFrom(time, 0);

// defaults to milliseconds
export const difference = (
  start: number,
  end: number,
  unit: Exclude<Unit, "year" | "day">,
) => {
  let diff = 0;

  switch (unit) {
    case "hour": {
      diff = moment.duration(end - start, "milliseconds").asHours();
      break;
    }
    case "minute": {
      diff = moment.duration(end - start, "milliseconds").asMinutes();
      break;
    }
    case "second": {
      diff = moment.duration(end - start, "milliseconds").asSeconds();
      break;
    }
    default: {
      diff = moment.duration(end - start, "milliseconds").asMilliseconds();
      break;
    }
  }

  return diff;
};

export const utcOffset = (time: Time, offset: number) =>
  moment(time).utcOffset(offset).toString();
