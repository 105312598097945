import React from "react";
import useRaiseHandActions from "modules/broadcastRaiseHand/hooks/useRaiseHandActions";
import { selectCurrentTheaterId } from "modules/theater/selectors";
import { useSelector } from "react-redux";
import { useI18n } from "i18n";
import { ActionButton } from "modules/actionButton";
import { selectRaisedHandCount } from "modules/broadcastRaiseHand/redux/selectors";
import { ReactComponent as UnraiseIcon } from "./icons/unraise-hand-icon.inline.svg";

const UnraiseHandsButton = () => {
  const { unraiseAllHands } = useRaiseHandActions();
  const raisedHandUsersCount = useSelector(selectRaisedHandCount);
  const theaterId = useSelector(selectCurrentTheaterId);

  const handleUnraiseClick = React.useCallback(() => {
    if (theaterId) {
      unraiseAllHands(theaterId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [theaterId]);

  const { t } = useI18n();

  return (
    <ActionButton
      title={t("lower.all.hands")}
      label={t("lower.all.hands")}
      onClick={handleUnraiseClick}
      disabled={!raisedHandUsersCount}
      isPopoverButton
      icon={<UnraiseIcon />}
      darkMode
    />
  );
};

export default UnraiseHandsButton;
