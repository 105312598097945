import {
  deleteObject,
  getDownloadURL,
  ref,
  uploadBytesResumable,
  UploadMetadata,
  UploadTask,
} from "firebase/storage";
import { storage } from "./firebaseConfig";
import logger from "../../logging/logger";
import { UPLOAD_LEVEL } from "./storagePaths";

export const getUpdatedFileName = (filename: string) => {
  const random = Math.random().toString(36).slice(2);

  return `${random}/${filename}`;
};

export const upload = (
  formData: FormData,
  meta: UploadMetadata,
  path: string,
  level: UPLOAD_LEVEL,
  callback: (
    err: Error | null,
    status?: {
      progress: number[];
      status: string;
      url?: string;
    },
  ) => void,
  // eslint-disable-next-line max-params
): UploadTask | null => {
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  const storageRef = getStoragePath(level, path);

  if (!storageRef) {
    callback(new Error("Invalid userId."));

    return null;
  }
  const file = formData.get("file");

  if (!file || typeof file === "string") {
    callback(new Error("Invalid file."));

    return null;
  }
  const fileName = getUpdatedFileName(file.name);
  // Upload file and metadata to the object 'images/mountains.jpg'
  const uploadTask = uploadBytesResumable(
    ref(storageRef, fileName),
    file,
    meta,
  );

  // Listen for state changes, errors, and completion of the upload.
  uploadTask.on(
    "state_changed",
    (snapshot) => {
      // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
      // var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      // callback(null, {progress});
      switch (snapshot.state) {
        case "paused":
          callback(null, {
            progress: [snapshot.bytesTransferred, snapshot.totalBytes],
            status: "paused",
          });
          break;
        case "running": // or 'running'
          callback(null, {
            progress: [snapshot.bytesTransferred, snapshot.totalBytes],
            status: "running",
          });
          break;
        default:
          break;
      }
    },
    (error) => {
      // A full list of error codes is available at
      // https://firebase.google.com/docs/storage/web/handle-errors

      switch (error.code) {
        case "storage/unauthorized":
          // User doesn't have permission to access the object
          break;

        case "storage/canceled":
          // User canceled the upload
          break;

        case "storage/unknown":
          // Unknown error occurred, inspect error.serverResponse
          break;
        default:
      }

      if (error.code !== "storage/canceled") {
        callback(
          /* eslint no-underscore-dangle: ["error", { "allow": ["serverResponse_"] }] */
          new Error(`Error: ${error.code}: ${error.serverResponse} `),
          { status: "error", progress: [0, 0] },
        );
      }
    },
    () => {
      // Upload completed successfully, now we can get the download URL
      // eslint-disable-next-line promise/catch-or-return, promise/always-return, promise/prefer-await-to-then
      getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
        callback(null, {
          progress: [100, 100],
          status: "success",
          url: downloadURL,
        });
      });
    },
  );

  return uploadTask;
};

// todo add path for different levels
const getStoragePath = (level: UPLOAD_LEVEL, path: string) => {
  switch (level) {
    case UPLOAD_LEVEL.GLOBAL:
      return ref(storage, `Global/${path}`);
    case UPLOAD_LEVEL.COMPANY:
      return ref(storage, `Company/${path}`);
    case UPLOAD_LEVEL.EVENT:
      return ref(storage, `Event/${path}`);
    case UPLOAD_LEVEL.USER:
      return ref(storage, `User/${path}`);
    case UPLOAD_LEVEL.LAYOUT:
      return ref(storage, `Layouts/${path}`);
    default:
      return ref(storage, `Temp/${path}`);
  }
};

export const deleteFile = async (url: string) => {
  const fileRef = ref(storage, url);

  try {
    await deleteObject(fileRef);
    logger.info(`[Firebase-files][delete] File Deleted Successfully ${url}`);
  } catch (error) {
    logger.error(
      `[Firebase-files][delete] Failed to delete file from URL ${error}`,
    );
  }
};
