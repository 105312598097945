import { Button } from "@remo-co/ui-core/src/components/Button";
import { Dialog } from "@remo-co/ui-core/src/components/Dialog";
import { DialogActions } from "@remo-co/ui-core/src/components/DialogActions";
import { DialogContent } from "@remo-co/ui-core/src/components/DialogContent";
import { DialogTitle } from "@remo-co/ui-core/src/components/DialogTitle";
import { IconButtonWithTooltip } from "@remo-co/ui-core/src/components/IconButtonWithTooltip";
import { Input } from "@remo-co/ui-core/src/components/Input";
import { Typography } from "@remo-co/ui-core/src/components/Typography";
import { Container } from "@remo-co/ui-core/src/components/Container";
import { Close } from "@remo-co/ui-core/src/icons/Close";
import { Info } from "@remo-co/ui-core/src/icons/Info";
import { useI18n } from "i18n";
import { ChangeEvent, useState } from "react";
import {
  useUpdateLiveStream,
  useAddLiveStream,
} from "modules/liveStream/hooks";
import { selectLiveStreams } from "modules/liveStream/redux/selectors";
import { useSelector } from "react-redux";
import { useStyles } from "./style";

export interface StreamFormData {
  serverUrl: string;
  streamKey: string;
  playbackUrl: string;
  destinationName: string;
  id?: string;
}

interface Props {
  handleClose: () => void;
  eventId: string;
  initialFormData: StreamFormData;
}

export const LiveStreamForm = ({
  handleClose,
  eventId,
  initialFormData,
}: Props): JSX.Element | null => {
  const [streamData, setStreamData] = useState(initialFormData);
  const styles = useStyles();
  const liveStreams = useSelector(selectLiveStreams);

  const { t } = useI18n(["eventForm", "common", "manageEvent"]);
  const { addLiveStream, isLiveStreamAdding } = useAddLiveStream();
  const { updateLiveStream, isLiveStreamUpdating } = useUpdateLiveStream();
  const handleSubmit = () => {
    if (initialFormData.id) {
      updateLiveStream({
        eventId,
        streamId: initialFormData.id,
        ...streamData,
      });
    } else {
      addLiveStream({ eventId, ...streamData });
    }

    setStreamData(initialFormData);
    handleClose();
  };

  const handleFormInputs = (event: ChangeEvent<HTMLInputElement>) => {
    setStreamData({
      ...streamData,
      [event.target.name]: event.target.value,
    });
  };

  const isLoading = isLiveStreamAdding || isLiveStreamUpdating;
  const disableSave =
    !streamData.destinationName ||
    !streamData.serverUrl ||
    !streamData.streamKey;

  if (liveStreams.length === 1 && !initialFormData.id) {
    return null;
  }

  return (
    <Dialog
      onClose={handleClose}
      fullWidth
      open
      classes={{
        paper: styles.root,
      }}
      data-testid="manage-live-stream-dialog"
    >
      <DialogTitle className={styles.header} variant="h3">
        {initialFormData.id
          ? t("manageEvent:edit.rtmp.stream.destination")
          : t("manageEvent:add.rtmp.stream.destination")}
      </DialogTitle>
      <IconButtonWithTooltip
        id="close-popup-dialog"
        title={t("common:button.close")}
        data-testid="cancel-button-icon"
        onClick={handleClose}
        size="small"
        className={styles.closeButton}
      >
        <Close />
      </IconButtonWithTooltip>
      <DialogContent>
        <Container flex alignItems="center" className={styles.promptToSave}>
          <Info className={styles.promptIcon} color="primary" />
          <div className={styles.message}>
            <Typography
              variant="body1"
              component="span"
              className={styles.infoFont}
            >
              {t("manageEvent:rtmp.info.message")}
            </Typography>
          </div>
        </Container>
        <div className={styles.inputContainer}>
          <div className={styles.formInput}>
            <Input
              size="sm"
              inputClassName={styles.inputClass}
              fullWidth
              description={t("manageEvent:rtmp.url.message")}
              label={t("manageEvent:stream.server.url")}
              name="serverUrl"
              placeholder={t("manageEvent:enter.stream.url")}
              inputProps={{ "data-testid": "server-url" }}
              onChange={handleFormInputs}
              value={streamData.serverUrl}
              descriptionClassName={styles.helperText}
            />
          </div>
          <div className={styles.formInput}>
            <Input
              size="sm"
              fullWidth
              inputClassName={styles.inputClass}
              description={t("manageEvent:stream.key.message")}
              label={t("manageEvent:stream.key")}
              name="streamKey"
              placeholder={t("manageEvent:enter.stream.key")}
              inputProps={{ "data-testid": "stream-key" }}
              onChange={handleFormInputs}
              value={streamData.streamKey}
              descriptionClassName={styles.helperText}
            />
          </div>
          <div className={styles.formInput}>
            <Input
              size="sm"
              fullWidth
              inputClassName={styles.inputClass}
              description={t("manageEvent:payback.url.message")}
              label={t("manageEvent:playback.url")}
              name="playbackUrl"
              placeholder={t("manageEvent:enter.playback.url")}
              inputProps={{ "data-testid": "playback-url" }}
              onChange={handleFormInputs}
              value={streamData.playbackUrl}
              descriptionClassName={styles.helperText}
            />
          </div>
          <div className={styles.formInput}>
            <Input
              size="sm"
              fullWidth
              inputClassName={styles.inputClass}
              description={t("manageEvent:destination.name.message")}
              label={t("manageEvent:destination.name")}
              name="destinationName"
              placeholder={t("manageEvent:enter.destination.name")}
              inputProps={{ "data-testid": "destination-name" }}
              onChange={handleFormInputs}
              value={streamData.destinationName}
              descriptionClassName={styles.helperText}
            />
          </div>
        </div>
      </DialogContent>
      <DialogActions className={styles.actions}>
        <Button
          variant="secondary"
          color="blue"
          onClick={handleClose}
          className={styles.buttons}
          loading={isLoading}
        >
          {t("common:button.cancel")}
        </Button>
        <Button
          variant="primary"
          color="blue"
          className={styles.buttons}
          disabled={disableSave}
          onClick={handleSubmit}
          loading={isLoading}
        >
          {t("manageEvent:save.destination")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
