import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { EventPollStatus } from "graphql/generated";
import { TABS } from "./constants";

enum EditState {
  Edit = "EDIT",
}

export type State = {
  tabId: TABS;
  isOpen: boolean;
  pollsTabId: EventPollStatus | EditState | null;
};

export const initialState: State = {
  isOpen: false,
  tabId: TABS.CHAT,
  pollsTabId: null,
};

const rightPanelSlice = createSlice({
  initialState,
  name: "rightPanel",
  reducers: {
    setPanelState: (
      state,
      action: PayloadAction<{ isOpen: boolean; tabId: TABS }>,
    ) => ({
      ...state,
      isOpen: action.payload.isOpen,
      tabId: action.payload.tabId,
    }),
    setPollsTabId: (
      state,
      action: PayloadAction<EventPollStatus | EditState>,
    ) => {
      state.pollsTabId = action.payload;
    },
  },
});

export const { setPanelState, setPollsTabId } = rightPanelSlice.actions;

export default rightPanelSlice.reducer;
