import { traverseSlots } from "./traverseSlots";

export function populateSlots(slots: (string | null)[], liveUserIds: string[]) {
  const unseetedLiveUsers = liveUserIds.filter(
    (id: string) => !slots.includes(id),
  );

  return traverseSlots(slots, (slotValue: string | null) => {
    if (slotValue !== null) {
      if (liveUserIds.includes(slotValue)) return slotValue;

      if (unseetedLiveUsers.length !== 0) {
        return unseetedLiveUsers.shift() as string;
      }

      return null;
    }

    if (unseetedLiveUsers.length !== 0) {
      return unseetedLiveUsers.shift() as string;
    }

    return null;
  });
}
