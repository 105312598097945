import { IEvent } from "../types";

let isListeningUnloadEvent = false;

const onBeforeUnload = (beforeUnloadEvent: BeforeUnloadEvent) => {
  beforeUnloadEvent.preventDefault();

  // This text is not actually prompted on newer browsers
  const returnValue = "Do you really want to leave this event?";

  beforeUnloadEvent.returnValue = returnValue;

  return returnValue;
};

export const listenEventUnload = () => {
  if (!isListeningUnloadEvent) {
    window.addEventListener("beforeunload", onBeforeUnload);

    isListeningUnloadEvent = true;
  }
};

export const stopListeningEventUnload = () => {
  if (isListeningUnloadEvent) {
    window.removeEventListener("beforeunload", onBeforeUnload);

    isListeningUnloadEvent = false;
  }
};

export const isEventManager = (event?: IEvent, userId?: string): boolean => {
  if (!event || !userId) {
    return false;
  }

  return Boolean(event.managers && event.managers.includes(userId));
};

export const isEventSpeaker = (
  event?: IEvent,
  userId?: string,
  email?: string | null,
): boolean => {
  if (!event || !userId) {
    return false;
  }

  if (email && event.speakers?.includes(email)) {
    return true;
  }

  const isSpeaker = event.speakers?.includes(userId) ?? false;

  return isSpeaker;
};

export const getEventRole = (isManager: boolean, isSpeaker: boolean) => {
  if (isManager) {
    return "manager";
  }

  if (isSpeaker) {
    return "speaker";
  }

  return "guest";
};
