import logger from "logging/logger";
import * as FirebaseConfig from "services/firebaseService/firebaseConfig";
import store from "store";
import { push } from "connected-react-router";
import { getAPIUrl } from "config";
import { getCurrentEvent, getCurrentUserId } from "../../helpers/reduxHelper";
import { getCachedToken } from "../firebaseService/getCachedToken";

export const getToken = async (): Promise<string | null> => {
  let token = await getCachedToken();

  if (!token) {
    try {
      token = await FirebaseConfig.initAuthToken();
    } catch (e) {
      // This is info not warn because token will error when user is logged out.
      logger.info(`[getToken] Fail to get token. Error: ${e}`);
    }
  }

  return token;
};

const populateHeaders = (headers: Record<string, string>) => {
  const user = getCurrentUserId();
  const event = getCurrentEvent();

  if (event?.code) {
    headers["X-Event-Code"] = event?.code;
  }

  if (user) {
    headers["X-User-Id"] = user;
  }
};

const apiUrl = getAPIUrl();

export const sendGetRequest = async <T>(endpoint: string): Promise<T> => {
  try {
    const fullUrl =
      endpoint.indexOf(apiUrl) === -1 ? apiUrl + endpoint : endpoint;

    const token = await getToken();
    const headers: HeadersInit = {
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
    };

    populateHeaders(headers);

    if (token) {
      headers.Authorization = `Bearer ${token}`;
    }

    const rawResponse = await fetch(fullUrl, {
      headers,
      method: "GET",
      mode: "cors",
    }).then((result) => {
      if (result.status === 401) {
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        goToSigninUrl();
      } else if (result.status === 404) {
        // window.open(`/404`, '_self');
        store.dispatch(push("/404"));
      }

      return result;
    });

    return await rawResponse.json();
  } catch (e) {
    logger.error(
      `[sendGetRequest] Failed to send request to ${endpoint}. Error:${e}`,
    );
    throw e;
  }
};

export const sendPutRequest = async <P, R>(
  endpoint: string,
  data: P,
  method = "PUT",
): Promise<R> => {
  const fullUrl =
    endpoint.indexOf(apiUrl) === -1 ? apiUrl + endpoint : endpoint;
  const token = await getToken();
  const headers: HeadersInit = {
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json",
  };

  populateHeaders(headers);

  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }
  try {
    const rawResponse = await fetch(fullUrl, {
      body: JSON.stringify(data),
      headers,
      method,
      mode: "cors",
    }).then((result) => {
      if (result.status === 401) {
        logger.info("LOGOUT BY 401");

        if (endpoint.indexOf("/auth") === -1) {
          // eslint-disable-next-line @typescript-eslint/no-use-before-define
          goToSigninUrl();
        }
      }

      return result;
    });

    return await rawResponse.json();
  } catch (e) {
    logger.error(
      `[sendGetRequest] Failed to send request to ${endpoint}. Error:${e}`,
    );
    throw e;
  }
};

export const sendDeleteRequest = async <P, R>(
  endpoint: string,
  data?: P,
): Promise<R> => {
  const fullUrl =
    endpoint.indexOf(apiUrl) === -1 ? apiUrl + endpoint : endpoint;
  const token = await getToken();
  const headers: HeadersInit = {
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json",
  };

  populateHeaders(headers);

  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }
  try {
    const rawResponse = await fetch(fullUrl, {
      body: JSON.stringify(data ?? {}),
      headers,
      method: "DELETE",
      mode: "cors",
    }).then((result) => {
      if (result.status === 401) {
        logger.info("LOGOUT BY 401");

        if (endpoint.indexOf("/auth") === -1) {
          // eslint-disable-next-line @typescript-eslint/no-use-before-define
          goToSigninUrl();
        }
      }

      return result;
    });

    return await rawResponse.json();
  } catch (e) {
    logger.error(
      `[sendDeleteRequest] Failed to send request to ${endpoint}. Error:${e}`,
    );
    throw e;
  }
};

export const sendPostRequest = async <P, R>(
  endpoint: string,
  data: P,
  customToken?: string,
): Promise<R> => {
  const fullUrl =
    endpoint.indexOf(apiUrl) === -1 ? apiUrl + endpoint : endpoint;
  const token = await getToken();
  const headers: HeadersInit = {
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json",
  };

  populateHeaders(headers);

  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }

  if (customToken) {
    headers.Authorization = `Bearer ${customToken}`;
  }

  try {
    const rawResponse = await fetch(fullUrl, {
      body: JSON.stringify(data),
      headers,
      method: "POST",
      mode: "cors",
    }).then((result) => {
      if (result.status === 401) {
        logger.info("LOGOUT BY 401");

        if (endpoint.indexOf("/auth") === -1) {
          // eslint-disable-next-line @typescript-eslint/no-use-before-define
          goToSigninUrl();
        }
      }

      if (result.status === 500 && endpoint.includes("user-session")) {
        throw new Error("Internal server error");
      }

      return result;
    });

    return await rawResponse.json();
  } catch (e) {
    logger.error(
      `[sendPostRequest] Failed to send request to ${endpoint}. Error:${e}`,
    );
    throw e;
  }
};

export const request = async (
  url: string,
  method: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data?: any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  headers?: any,
  mode?: Modes,
  isJsonResponse = false,
  // eslint-disable-next-line max-params
) => {
  try {
    return await fetch(url, {
      method,
      body: method.toLowerCase() === "get" ? undefined : JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
      mode,
    })
      .then((result) =>
        isJsonResponse && result.status === 200 ? result.json() : result,
      )
      .catch((err) => {
        throw err;
      });
  } catch (e) {
    logger.error(`[request] Failed to send request to ${url}. Error:${e}`);
    throw e;
  }
};

export const goToSigninUrl = () => {
  const url = `/signin?redirectTo=${window.location.pathname}`;

  store.dispatch(push(url));
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const sendBeacon = (url: string, data: any) => {
  const params = new URLSearchParams({ ...data });

  if (navigator.sendBeacon) {
    navigator.sendBeacon(`${apiUrl}${url}`, params);
  }
};
