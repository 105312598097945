import { Theme } from "@remo-co/ui-core/src/types";
import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";

export const useStyles = makeStyles<Theme>(() => ({
  youtubeWrapper: {
    position: "relative",
    overflow: "hidden",
    "& > div": {
      height: "100%",
    },
  },
  unmuteButtonWrapper: {
    top: 0,
    backgroundColor: "#353535b5",
    position: "absolute",
    height: "100%",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 100,
  },
}));
