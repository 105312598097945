import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";
import errorPageMaskDesktop from "./assets/error-page-mask-desktop.svg";
import errorPageMaskMobile from "./assets/error-page-mask-mobile.svg";
import errorPageLogoGroupDesktop from "./assets/error-page-logo-group-desktop.svg";

export const useStyles = makeStyles((theme) => ({
  errorPageContainer: {
    position: "absolute",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    justifyContent: "center",
    background: "white",

    [theme.breakpoints.up("sm")]: {
      height: "100vh",
      width: "100vw",
      display: "flex",
      flexDirection: "column",
    },
  },
  contactSupport: {
    textAlign: "left",
    alignSelf: "flex-start",

    [theme.breakpoints.down("md")]: {
      textAlign: "center",
      width: "100%",
    },
  },
  logoContainer: {
    position: "fixed",
    top: 30,
    left: 30,

    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  contentContainer: {
    [theme.breakpoints.down("md")]: {
      display: "block",
      padding: "13vw",
      height: "45vh",
    },

    [theme.breakpoints.up("sm")]: {
      marginTop: "265px",
      marginLeft: "13vw",
      width: "50vw",
      height: "100%",
    },
  },
  titleContainer: {
    [theme.breakpoints.down("md")]: {
      color: "#484a56",
      letterSpacing: 0,
      textAlign: "center",
      maxHeight: "40vh",
      width: "100%",
      marginTop: "10vh",
      marginBottom: 31,
    },
  },
  title: {
    fontSize: 28,

    [theme.breakpoints.down("md")]: {
      width: "100%",
      fontSize: 24,
      fontWeight: 500,
      marginBottom: 15,
    },
  },
  subTitle: {
    fontSize: 14,
    fontWeight: 400,

    [theme.breakpoints.down("md")]: {
      width: "100%",
      fontWeight: "normal",
    },
  },
  buttonsContainer: {
    [theme.breakpoints.up("sm")]: {
      display: "flex",
      flexDirection: "column",
      textAling: "center",
      width: "400px",
    },
  },
  buttons: {
    display: "flex",
    marginTop: 60,
    width: "100%",

    [theme.breakpoints.down("md")]: {
      margin: 0,
      display: "flex",
      flexDirection: "column-reverse",
    },
  },
  button: {
    textDecoration: "none",

    [theme.breakpoints.down("md")]: {
      width: "100%",
      marginBottom: 15,
    },
  },
  buttonRefresh: {
    [theme.breakpoints.up("sm")]: {
      marginRight: 20,
    },
  },
  leftMaskContainer: {
    width: 302,
    height: 205,
    position: "absolute",
    opacity: 1,
    top: 0,
    left: 0,
    backgroundImage: `url(${errorPageLogoGroupDesktop})`,
    backgroundSize: "cover",
    backgroundOrigin: "padding-box",

    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  maskContainer: {
    [theme.breakpoints.up("sm")]: {
      position: "absolute",
      right: 0,
      bottom: 0,
      top: 0,
      pointerEvents: "none",
      width: "62vw",
      backgroundImage: `url(${errorPageMaskDesktop})`,
      backgroundSize: "cover",
      backgroundOrigin: "padding-box",
    },

    [theme.breakpoints.down("md")]: {
      width: "100vw",
      height: "55vh",
      backgroundImage: `url(${errorPageMaskMobile})`,
      backgroundSize: "cover",
      backgroundOrigin: "padding-box",
    },
  },
}));
