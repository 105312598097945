import { useSelector } from "react-redux";
import { useEffect, useMemo, useState } from "react";
import { useAppDispatch } from "store/hooks";
import { setFloorPlanSelectedTags } from "modules/customFloorPlan/redux/slice";
import {
  selectCompanyTags,
  selectDefaultTags,
} from "modules/customFloorPlan/redux/selectors";
import { useI18n } from "i18n";

interface SelectedTags {
  label: string;
  value: string;
}

export const useCompanyFloorPlanTags = () => {
  const companyTags = useSelector(selectCompanyTags);
  const defaultTags = useSelector(selectDefaultTags);
  const [selectedTags, setSelectedTags] = useState<SelectedTags[]>([]);
  const dispatch = useAppDispatch();
  const { t } = useI18n(["tags"]);

  useEffect(() => {
    const tags = selectedTags.map((tag) => tag.value);

    dispatch(setFloorPlanSelectedTags(tags));
  }, [selectedTags, dispatch]);

  const tags = useMemo(() => {
    const privates = companyTags.map((tag) => ({
      label: tag.name,
      value: tag._id,
    }));
    const defaults = defaultTags.map((tag) => ({
      label: t(`tags:${tag.slug}`),
      value: tag._id,
    }));

    return [...privates, ...defaults];
  }, [companyTags, defaultTags, t]);

  return {
    tags,
    selectedTags,
    setSelectedTags,
  };
};
