import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isPopupVisible: false,
};

const switchCompaniesSlice = createSlice({
  name: "switchCompanyPopup",
  initialState,
  reducers: {
    showPopup: (state) => {
      state.isPopupVisible = true;
    },
    hidePopup: (state) => {
      state.isPopupVisible = false;
    },
  },
});

export const { showPopup, hidePopup } = switchCompaniesSlice.actions;

export default switchCompaniesSlice.reducer;
