export const getEnvironment = () => {
  const { hostname } = window.location;

  const isPreview = /^\d{5}\.remo\.co$/.test(hostname);

  if (isPreview) {
    return "development";
  }

  switch (hostname) {
    case "live.remo.co":
      return "production";
    case "dev.remo.co":
      return "development";
    case "localhost":
      return "localhost";
    default:
      return "test";
  }
};
