const parseURL = (url: string) => {
  const cleanedURL = url.startsWith("http") ? url : `https://${url}`;
  const a = document.createElement("a");

  a.href = cleanedURL;

  return {
    source: url,
    protocol: a.protocol.replace(":", ""),
    host: a.hostname,
    port: a.port,
    search: a.search,
    path: a.pathname,
    params: (() => new URLSearchParams(a.search))(),
  };
};

export default parseURL;
