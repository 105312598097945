const RESET = "app/userStatus/RESET";
const SYNC_USER_STATUS = "app/userStatus/SYNC_USER_STATUS";
const REMOVE_USER_STATUS = "app/userStatus/REMOVE_USER_STATUS";
const UPDATE_USER_STATUS = "app/userStatus/UPDATE_USER_STATUS";
const UPDATE_TUTORIAL_STATUS = "app/userStatus/UPDATE_TUTORIAL_STATUS";

export default {
  SYNC_USER_STATUS,
  REMOVE_USER_STATUS,
  UPDATE_USER_STATUS,
  UPDATE_TUTORIAL_STATUS,
  RESET,
};
