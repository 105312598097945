import { RootState } from "store";

export const selectShowAssignedShuffleDialog = (state: RootState) =>
  state.assignedShuffle.isDialogVisible;

export const selectHoveredTableCode = (state: RootState) =>
  state.assignedShuffle.hoveredTableCode;

export const selectHoveredUserId = (state: RootState) =>
  state.assignedShuffle.hoveredUserId;

export const selectIsWaitForEnforceNoticeVisible = (state: RootState) =>
  state.assignedShuffle.isWaitForEnforceNoticeVisible;
