import { useI18n } from "i18n";
import { Typography } from "@remo-co/ui-core/src/components/Typography";
import { Tooltip } from "@remo-co/ui-core/src/components/Tooltip";
import { Button } from "@remo-co/ui-core/src/components/Button";
import { Add } from "@remo-co/ui-core/src/icons/Add";
import Step1Image from "../../assets/step-1.png";
import Step2Image from "../../assets/step-2.png";
import Step3Image from "../../assets/step-3.png";
import { useStyles } from "./styles";

export const CreateEventEmptyState = () => {
  const { t } = useI18n(["common", "eventForm"]);
  const styles = useStyles();

  return (
    <div className={styles.container}>
      <Typography variant="body1" className={styles.text}>
        {t("eventForm:ticket.create.event.placeholder.title")}
      </Typography>
      <div className={styles.stepImages}>
        <div className={styles.step1Image}>
          <Typography variant="body1" className={styles.stepText}>
            {t("eventForm:ticket.create.event.placeholder.number", {
              index: "1",
            })}
          </Typography>
          <img
            src={Step1Image}
            alt={t("eventForm:ticket.create.event.placeholder.step.1")}
          />
        </div>
        <div className={styles.step2Image}>
          <Typography variant="body1" className={styles.stepText}>
            {t("eventForm:ticket.create.event.placeholder.number", {
              index: "2",
            })}
          </Typography>
          <img
            src={Step2Image}
            alt={t("eventForm:ticket.create.event.placeholder.step.2")}
          />
        </div>
        <div className={styles.step3Image}>
          <Typography variant="body1" className={styles.stepText}>
            {t("eventForm:ticket.create.event.placeholder.number", {
              index: "3",
            })}
          </Typography>
          <img
            src={Step3Image}
            alt={t("eventForm:ticket.create.event.placeholder.step.3")}
          />
        </div>
      </div>
      <div className={styles.steps}>
        <Typography variant="body1" className={styles.text}>
          {t("eventForm:ticket.create.event.placeholder.step.1")}
        </Typography>
        <Typography variant="body1" className={styles.text}>
          {t("eventForm:ticket.create.event.placeholder.step.2")}
        </Typography>
        <Typography variant="body1" className={styles.text}>
          {t("eventForm:ticket.create.event.placeholder.step.3")}
        </Typography>
      </div>
      <Tooltip
        placement="top"
        title={t("manageEvent:menu.item.disable.text", {
          name: t("manageEvent:ticketing"),
        })}
      >
        <span>
          <Button
            variant="secondary"
            color="blue"
            size="sm"
            className={styles.button}
            disabled
            contentClassName={styles.content}
            data-testid="create-ticket-button-event-empty-state"
          >
            <Add className={styles.icon} />
            {t("eventForm:add.ticket")}
          </Button>
        </span>
      </Tooltip>
    </div>
  );
};
