import { selectCompanyId } from "modules/company/redux/selectors";
import { addCompanyTag } from "modules/customFloorPlan/redux/slice";
import { createCompanyTag } from "modules/customFloorPlan/request";
import { useCallback } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "store/hooks";

export const useCreateNewTag = () => {
  const companyId = useSelector(selectCompanyId);
  const dispatch = useAppDispatch();

  const createNewTag = useCallback(
    async (name: string) => {
      if (!companyId) {
        return undefined;
      }

      const data = await createCompanyTag({ companyId, name });
      dispatch(addCompanyTag(data));

      return data;
    },
    [dispatch, companyId],
  );

  return {
    createNewTag,
  };
};
