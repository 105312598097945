import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ContentData } from "graphql/generated";

interface InitialState {
  conversationModeContent: ContentData[];
  conversationModeViewedContentIDs: number[];
  presentationModeContent: ContentData | null;
}

export const initialState: InitialState = {
  conversationModeContent: [],
  conversationModeViewedContentIDs: [],
  presentationModeContent: null,
};

const slice = createSlice({
  name: "embedContent",
  initialState,
  reducers: {
    updateConversationModeContent: (
      state,
      action: PayloadAction<ContentData[]>,
    ) => {
      state.conversationModeContent = action.payload;
    },
    dismissConversationModeContent: (state, action: PayloadAction<number>) => {
      state.conversationModeViewedContentIDs = [
        ...state.conversationModeViewedContentIDs,
        action.payload,
      ];
    },
    updatePresentationModeContent: (
      state,
      action: PayloadAction<ContentData | null>,
    ) => {
      state.presentationModeContent = action.payload;
    },
    reset: () => initialState,
  },
});

export const {
  updateConversationModeContent,
  dismissConversationModeContent,
  updatePresentationModeContent,
  reset,
} = slice.actions;

export default slice.reducer;
