import { Typography } from "@remo-co/ui-core/src/components/Typography";
import { useI18n } from "i18n";

export const SpeakerJoinSoonText = () => {
  const { t } = useI18n();
  return (
    <div className="empty-state-message-ctn">
      <strong>
        <Typography>{t("presentation.start.soon")}</Typography>
      </strong>
      <p>
        <Typography>{t("speaker.join.soon")}</Typography>
      </p>
    </div>
  );
};
