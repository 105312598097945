import ButtonBase, { ButtonBaseProps } from "@mui/material/ButtonBase";
import CircularProgress from "@mui/material/CircularProgress";
import React, { ElementType, forwardRef } from "react";
import classnames from "classnames";
import { LG, MD, SM, XS } from "../../types";
import { useStyles } from "./styles";

type StyleProps = {
  variant?: "secondary" | "primary" | "ghost" | "text";
  color?: "blue" | "dark" | "red" | "green" | "darkModeBlue" | "gray" | "white";
};

interface BaseProps extends ButtonBaseProps {
  autoFocus?: boolean;
  className?: string;
  component?: ElementType;
  /**  @deprecated */
  contentClassName?: string;
  endAdornment?: React.ReactNode;
  fullWidth?: boolean;
  id?: string;
  /**  @deprecated use the IconButtonWithTooltip component instead */
  isIconButton?: boolean;
  loading?: boolean;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  size?: MD | SM | LG | XS;
  startAdornment?: React.ReactNode;
  /**  @deprecated */
  startAdornmentClassName?: string;
  style?: React.CSSProperties;
  type?: "button" | "reset" | "submit";
  verticalContent?: boolean;
  target?: "_blank" | "_self" | "_parent" | "_top";
  rel?: string;
  isExternal?: boolean;
}

export type IButtonProps = BaseProps & StyleProps;

const Button = forwardRef(
  (props: IButtonProps, ref: React.Ref<HTMLButtonElement>) => {
    const {
      variant = "primary",
      color = "blue",
      disabled = false,
      fullWidth = false,
      size = "lg",
      loading = false,
      children,
      component = "button",
      className,
      style,
      startAdornment,
      endAdornment,
      tabIndex,
      startAdornmentClassName = "",
      contentClassName = "",
      isIconButton: _isIconButton,
      verticalContent: _verticalContent,
      ...rest
    } = props;

    const classes = useStyles({ ...props, color, variant, size });
    const rootClassName = classnames(
      {
        [classes.fullWidth]: fullWidth,
        [classes.disabled]: loading,
        disabled,
      },
      classes.root,
      classes[size],
      classes[variant],
      className,
    );

    const content = (
      <div className={classes.contentWrapper}>
        {startAdornment && (
          <div
            className={classnames(classes.startAdornment, {
              [startAdornmentClassName]: startAdornmentClassName,
            })}
          >
            {startAdornment}
          </div>
        )}
        <div
          className={classnames(classes.content, {
            [contentClassName]: contentClassName,
          })}
        >
          {children}
        </div>
        {endAdornment && (
          <div className={classes.endAdornment}>{endAdornment}</div>
        )}
      </div>
    );

    return (
      <ButtonBase
        classes={{
          root: rootClassName,
        }}
        component={component as React.ElementType}
        disabled={disabled || loading}
        {...rest}
        ref={ref}
        style={style}
        tabIndex={tabIndex}
        color={color}
      >
        {loading ? (
          <>
            <div className={classes.loading}>{content}</div>
            <div data-testid="loading" className={classes.progressContainer}>
              <CircularProgress size={25} thickness={5} color="inherit" />
            </div>
          </>
        ) : (
          content
        )}
      </ButtonBase>
    );
  },
) as <T>(
  props: Omit<
    T extends "a"
      ? React.HTMLProps<HTMLAnchorElement>
      : T extends React.ElementType<infer U>
      ? U
      : T,
    keyof IButtonProps
  > &
    IButtonProps & {
      ref?: React.Ref<T extends "a" ? HTMLAnchorElement : HTMLButtonElement>;
    },
) => React.ReactElement;

export default Button;
