import { makeSelectUserById } from "modules/event/usersInEvent/selectors";
import Avatar from "modules/userAvatar/Avatar";
import { useSelector } from "react-redux";
import { Typography } from "@remo-co/ui-core/src/components/Typography";
import { TimedSpeechData } from "../TranscriptionSidebar/TranscriptionSidebar";
import { useStyles } from "./styles";

interface TranscriptionSidebarMessageProps {
  message: TimedSpeechData;
}

const TranscriptionSidebarMessage = ({
  message,
}: TranscriptionSidebarMessageProps): JSX.Element => {
  const classes = useStyles();
  const { userId } = message.user;
  const user = useSelector(makeSelectUserById(userId));
  const text = message.punctuated.transcript;
  return (
    <div className={classes.root} data-testid="transcription-sidebar-message">
      <div className={classes.messageUser}>
        <Avatar user={user} className={classes.avatar} />
        <Typography variant="h6">{message.user.name}</Typography>
      </div>
      <div className={classes.messageBody}>
        <Typography variant="caption" className={classes.messageTime}>
          {message.time.toLocaleTimeString()}
        </Typography>
        <Typography variant="h6" className={classes.messageText}>
          {text}
        </Typography>
      </div>
    </div>
  );
};

export default TranscriptionSidebarMessage;
