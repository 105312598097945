import { ReactElement } from "react";
import { Fade } from "@remo-co/ui-core/src/components/Fade";
import { Toolbar } from "@remo-co/ui-core/src/components/Toolbar";
import {
  CameraButton,
  MicrophoneButton,
  ChatButton,
  ParticipantsButton,
  LobbyButton,
} from "modules/eventButtons";
import { useSelector } from "react-redux";
import {
  selectIsChatEnabled,
  selectIsNetworkingRecommendationsEnabled,
} from "modules/event/selectors";
import { DevicePromptPopup } from "modules/audioVideo/components";
import { ConnectionsButton } from "modules/eventButtons/components/ConnectionsButton";
import { selectIsInBroadcast } from "modules/broadcast/redux/selectors";
import { useStyles } from "../styles";

interface Props {
  isEventManager: boolean;
}

export const RemoMobileToolbar = ({ isEventManager }: Props): ReactElement => {
  const isChatEnabled = useSelector(selectIsChatEnabled);
  const isNetworkingRecommendationEnabled = useSelector(
    selectIsNetworkingRecommendationsEnabled,
  );
  const styles = useStyles();

  const isInBroadcast = useSelector(selectIsInBroadcast);

  const showConnectionsButton =
    isNetworkingRecommendationEnabled && !isInBroadcast;

  return (
    <Fade in timeout={300}>
      <div>
        <Toolbar className={styles.mobileContainer}>
          <CameraButton />
          <MicrophoneButton />
          {isChatEnabled && <ChatButton />}
          {showConnectionsButton ? (
            <ConnectionsButton />
          ) : (
            <ParticipantsButton />
          )}
          {isEventManager && <LobbyButton />}
        </Toolbar>
        <DevicePromptPopup />
      </div>
    </Fade>
  );
};
