import { DataSnapshot, off, onValue, ref } from "firebase/database";
import store from "store";
import { hashData } from "helpers/hashHelper";
import { getReducerState } from "helpers/reduxHelper";
import { reduce } from "lodash";
import { resetRooms, syncRooms } from "store/rooms";
import { database } from "./firebaseConfig";
import { ROOM_PATH, SPACE_PATH } from "./storagePaths";
import logger from "../../logging/logger";
import { getCurrentSpaceId } from "../../helpers/reduxHelper";
import { getCurrentTheaterId } from "../../modules/theater/theater.helper";
import { THEATER_PATH } from "../../modules/theater/firebase";
import { getMapTemplateByType } from "../../modules/event/template";

let lastRoomHash: undefined | string;

interface IAcc {
  [key: string]: Record<string, unknown>;
}

export const init = () => {
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  handleSyncRoomStatus();
};

export const handleSyncRoomStatus = () => {
  const spaceId = getCurrentSpaceId();
  const roomsRef = ref(database, `${SPACE_PATH}/${spaceId}/${ROOM_PATH}`);

  logger.info(
    `[Firebase][handleSyncRoomStatus] Start to handle room status sync. spaceId: ${spaceId}`,
  );

  onValue(roomsRef, async (roomsResult: DataSnapshot) => {
    const roomsResultVal = roomsResult.val();

    if (roomsResultVal) {
      // const rooms = Object.keys(roomsResultVal).map((k) => roomsResultVal[k]);
      const newRoomHash = await hashData(JSON.stringify(roomsResultVal));

      if (lastRoomHash !== newRoomHash) {
        lastRoomHash = newRoomHash;

        if (roomsResultVal) {
          const roomsMap = reduce(
            roomsResultVal,
            (acc: IAcc, room, id) => {
              acc[room.code] = { ...room, id };

              return acc;
            },
            {},
          );
          // Merge the rooms data from template and firebase
          const theater = getReducerState("theater");
          const evTemplate = getMapTemplateByType(
            theater?.currentTheater?.template,
          );

          if (evTemplate) {
            const templateRooms = evTemplate.rooms;

            if (templateRooms) {
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              const templateRoomsMap: any = reduce(
                templateRooms,
                (acc: IAcc, room) => {
                  const match: { id?: number } = roomsMap[room.code];

                  if (match?.id) {
                    // Adding positions from db template because rooms in old events in firebase will have positions, which we dont need
                    acc[match.id] = {
                      ...room,
                      ...match,
                      positions: room.positions,
                    };
                  }

                  return acc;
                },
                {},
              );

              store.dispatch(syncRooms(templateRoomsMap));
            } else {
              store.dispatch(syncRooms(roomsResultVal));
            }
          }
        }
      }
    }
  });
};

export const reset = () => {
  const spaceId = getCurrentSpaceId();
  const roomsRef = ref(database, `${SPACE_PATH}/${spaceId}/${ROOM_PATH}`);
  off(roomsRef);

  const theaterId = getCurrentTheaterId();
  const roomNameRef = ref(database, `${THEATER_PATH}/${theaterId}/RoomNames`);
  off(roomNameRef);

  lastRoomHash = undefined;
  store.dispatch(resetRooms());
};
