import React from "react";

export const UnraiseIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    className="MuiSvgIcon-root"
    width="32"
    height="24"
    viewBox="0 0 32 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M28 14C28 6.27 21.73 0 14 0C6.27 0 0 6.27 0 14C0 8.477 4.477 4 10 4C15.523 4 20 8.477 20 14V16H16L24 24L32 16H28V14Z"
      fill="white"
    />
  </svg>
);
