import { useSelector } from "react-redux";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { selectCurrentEventId } from "modules/event/selectors";
import { selectCurrentSpaceId } from "modules/space/redux/selectors";
import useNotificationActions from "modules/notification/hooks/useNotificationActions";
import { useI18n } from "i18n";
import {
  removeLocksFromEventRequest,
  removeLocksFromFloorRequest,
} from "./removeLockRequests";
import { LOCK_TABLE_QUERY_KEY } from "../constants";

export const useRemoveLocksFromFloor = () => {
  const { addSuccessNotification, addErrorNotification } =
    useNotificationActions();
  const { t } = useI18n(["server"]);
  const eventId = useSelector(selectCurrentEventId);
  const floorId = useSelector(selectCurrentSpaceId);
  const queryClient = useQueryClient();

  const {
    mutate: removeLocksOnFloor,
    status,
    isPending,
  } = useMutation({
    mutationFn: async () => {
      if (eventId === null || floorId === null) {
        throw new Error("floor or event id not found");
      }
      const resp = await removeLocksFromFloorRequest({ eventId, floorId });
      if (!resp.status) {
        throw new Error(resp.reason);
      }
    },
    onError: (e) => {
      if (e instanceof Error && "message" in e) {
        addErrorNotification({ message: t(e.message) });
      } else {
        addErrorNotification({ message: t("unknown.error") });
      }
    },
    onSuccess: () => {
      queryClient.setQueryData([LOCK_TABLE_QUERY_KEY, eventId, floorId], []);
      addSuccessNotification({
        message: t("manageEvent:all.tables.floor.unlocked"),
      });
    },
  });

  return { removeLocksOnFloor, status, isPending };
};

export const useRemoveLocksFromEvent = () => {
  const eventId = useSelector(selectCurrentEventId);
  const { t } = useI18n();
  const queryClient = useQueryClient();
  const { addSuccessNotification, addErrorNotification } =
    useNotificationActions();

  const {
    mutate: removeLocksOnEvent,
    status,
    isPending,
  } = useMutation({
    mutationFn: async () => {
      if (eventId === null) {
        throw new Error("event id not found");
      }
      const resp = await removeLocksFromEventRequest({ eventId });
      if (!resp.status) {
        throw new Error(resp.reason);
      }
    },
    onError: (e) => {
      if (e instanceof Error && "message" in e) {
        addErrorNotification({ message: e.message });
      } else {
        addErrorNotification({ message: t("unknown.error") });
      }
    },
    onSuccess: () => {
      queryClient.setQueryData([LOCK_TABLE_QUERY_KEY, eventId], []);
      addSuccessNotification({
        message: t("manageEvent:all.tables.event.unlocked"),
      });
    },
  });

  return { removeLocksOnEvent, status, isPending };
};
