import color from "color";
import { Theme } from "@mui/material/styles";
import { CreateCSSProperties } from "@mui/styles/withStyles";
import makeStyles from "@mui/styles/makeStyles";
import { IButtonProps } from "./Button";

const DISABLED_BUTTON_OPACITY = 0.2;

interface IGeneratePrimaryButtonParams {
  baseColor: string;
  textColor: string;
  disabledColor: string;
}

export const generatePrimaryButton = ({
  baseColor,
  textColor,
  disabledColor,
}: IGeneratePrimaryButtonParams): CreateCSSProperties => {
  const activeColor = color(baseColor).darken(0.25).rgb().string();

  return {
    backgroundColor: baseColor,
    border: `1px solid ${baseColor}`,
    color: textColor,
    "&:hover,&.hover,&:focus": {
      backgroundColor: activeColor,
      borderColor: activeColor,
      color: textColor,
    },
    "&:disabled,&.disabled": {
      backgroundColor: disabledColor,
      borderColor: disabledColor,
    },
  };
};

interface IGenerateSecondaryButtonParams {
  textColor: string;
  baseColor: string;
  backgroundColor: string;
  disabledColor: string;
  activeColorFade: number;
}

export const generateSecondaryButton = ({
  textColor,
  baseColor,
  backgroundColor,
  disabledColor,
  activeColorFade,
}: IGenerateSecondaryButtonParams): CreateCSSProperties => {
  const activeColor = color(baseColor).fade(activeColorFade).rgb().string();

  return {
    backgroundColor,
    color: textColor,
    border: `1px solid ${baseColor}`,
    "&:hover,&.hover,&:focus": {
      backgroundColor: activeColor,
    },
    "&:disabled,&.disabled": {
      color: disabledColor,
      borderColor: disabledColor,
    },
  };
};

interface IGenerateGhostButtonParams {
  baseColor: string;
  backgroundColor: string;
  disabledColor: string;
}

export const generateGhostButton = ({
  baseColor,
  backgroundColor,
  disabledColor,
}: IGenerateGhostButtonParams): CreateCSSProperties => {
  const activeColor = color(baseColor).fade(0.95).rgb().string();

  return {
    backgroundColor,
    borderWidth: "1px",
    borderStyle: "solid",
    borderColor: backgroundColor,
    color: baseColor,
    "&:hover,&.hover,&:focus": {
      backgroundColor: activeColor,
      borderColor: activeColor,
    },
    "&:disabled, &.disabled": {
      color: disabledColor,
    },
  };
};

interface IGenerateTextButtonParams {
  baseColor: string;
}

export const generateTextButton = ({
  baseColor,
}: IGenerateTextButtonParams): CreateCSSProperties => ({
  background: "none",
  border: "none",
  color: baseColor,
  textDecoration: "underline",
  "&:hover,&.hover,&:focus": {
    cursor: "pointer",
  },
});

export const useStyles = makeStyles<Theme, IButtonProps>((theme) => ({
  root: {
    borderRadius: "5px",
    boxSizing: "border-box",
    transition: "background-color .3s",
    "&:focus-visible": {
      boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
      outline: `2px solid ${theme.palette.blue.main}`,
      outlineOffset: "3px",
    },
  },
  xs: {
    height: "30px",
    fontSize: 14,
    padding: theme.spacing(1),
  },
  sm: {
    height: "30px",
    fontSize: 14,
    width: (props) => (props.isIconButton ? "30px" : undefined),
    minWidth: (props) => (props.isIconButton ? "30px" : "80px"),
  },
  md: {
    height: "40px",
    fontSize: 14,
    width: (props) => (props.isIconButton ? "40px" : undefined),
  },
  lg: {
    height: "50px",
    fontSize: 16,
    width: (props) => (props.isIconButton ? "50px" : undefined),
  },
  fullWidth: {
    width: "100%",
  },
  primary: (props) =>
    generatePrimaryButton({
      baseColor:
        props.color === "white"
          ? theme.palette.common.white
          : theme.palette[props.color!]?.main ?? theme.palette.blue.main,
      textColor:
        props.color === "white" ? theme.palette.black : theme.palette.white,
      disabledColor: theme.palette.blue.disabled,
    }),
  secondary: (props) =>
    generateSecondaryButton({
      baseColor:
        props.color === "white"
          ? theme.palette.common.white
          : theme.palette[props.color!]?.main ?? theme.palette.blue.main,
      backgroundColor: theme.palette.background.default,
      disabledColor: theme.palette.blue.disabled,
      textColor:
        // eslint-disable-next-line no-nested-ternary
        props.color === "white"
          ? theme.palette.darkModeBlue.main
          : props.color === "gray"
          ? theme.palette.black
          : theme.palette[props.color!]?.main,
      activeColorFade: props.color === "gray" ? 0.2 : 0.8,
    }),
  ghost: (props) =>
    generateGhostButton({
      baseColor:
        props.color === "white"
          ? theme.palette.common.white
          : theme.palette[props.color!]?.main ?? theme.palette.blue.main,
      backgroundColor: "transparent",
      disabledColor: theme.palette.blue.disabled,
    }),
  text: (props) =>
    generateTextButton({
      baseColor:
        props.color === "white"
          ? theme.palette.common.white
          : theme.palette[props.color!].main,
    }),

  loading: {
    opacity: DISABLED_BUTTON_OPACITY,
  },
  startAdornment: {
    marginLeft: 16,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  endAdornment: {
    marginRight: 16,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  contentWrapper: {
    display: "flex",
    alignItems: "center",
    height: "100%",
  },
  content: {
    padding: (props) => {
      if (props.variant === "text") {
        return "0px 0px";
      }

      if (props.startAdornment || props.endAdornment) {
        return "0 16px";
      }

      if (props.isIconButton) {
        return "0";
      }

      switch (props.size) {
        case "sm":
          return "0 20px";
        case "md":
          return "0 16px";
        default:
          return "0 30px";
      }
    },
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightBold,
    display: "flex",
    textAlign: "center",
    alignContent: "center",
    background: "transparent",
  } as CreateCSSProperties<IButtonProps>,
  disabled: {
    pointerEvents: "none",
  },
  progressContainer: {
    display: "flex",
    position: "absolute",
    left: 0,
    top: 0,
    width: "100%",
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
}));
