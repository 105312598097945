import { Theme } from "@remo-co/ui-core/src/types";
import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";

export const useStyles = makeStyles<Theme>((theme) => ({
  greenThumbsUpIcon: {
    width: "30px",
    height: "30px",
    cursor: "pointer",
    color: "#1C5E46",
  },
  redThumbsDownIcon: {
    width: "30px",
    height: "30px",
    cursor: "pointer",
    color: theme.palette.error.main,
  },
  userNetworkingRowContainer: {
    "& .MuiListItem-gutters": {
      paddingLeft: 0,
    },
  },
  bcuAvatar: {
    width: "40px !important",
    height: "40px !important",
    "&.avatar-initial": { fontSize: "100%" },
    "&.map-av-remo": { left: "38px" },
  },
  networkingUserDetails: {
    width: "100%",
  },
  userName: {
    maxWidth: "160px",
    maxHeight: "45px",
    display: "-webkit-box",
    webkitLineClamp: "2",
    webkitBoxOrient: "vertical",

    "& > span": {
      maxWidth: "160px",
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
    },
    margin: 0,
  },
  ratingIconsBox: {
    display: "flex",
    justifyContent: "flex-end",
    width: "100%",
    gap: "20px",
    alignItems: "center",
  },
}));
