import { IEvent } from "modules/event/types";
import { sendGetRequest, sendPostRequest } from "services/apiService";
import { IMapTemplate } from "types/theater";

export const getEventsUsingTemplate = (companyId: string, code: string) =>
  sendGetRequest<
    APIResponse<{
      events: IEvent[];
    }>
  >(`/event-templates/${companyId}/${code}/events`);

export const softDeleteTemplate = (companyId: string, code: string) =>
  sendGetRequest<
    APIResponse<{
      theatersModified: number;
    }>
  >(`/event-templates/${companyId}/${code}/delete`);

export const insertTemplate = (
  companyId: string,
  data: Partial<IMapTemplate>,
  filePath: string,
) =>
  sendPostRequest<
    { data: typeof data; filePath: typeof filePath },
    APIResponse<{
      template: IMapTemplate;
    }>
  >(`/event-templates/${companyId}`, {
    data,
    filePath,
  });
