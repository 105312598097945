import { setIn } from "final-form";
import { ReactNode, useMemo } from "react";
import { Form as BaseForm, FormProps as BaseFormProps } from "react-final-form";
import { ObjectSchema, ValidationError } from "yup";
import { AnyObject } from "yup/lib/types";

export interface IFormProps<T> extends BaseFormProps<T> {
  initialValues: T;
  onSubmit(values: T): void;
  children: ReactNode;
  validationSchema?: ObjectSchema<AnyObject>;
}

const Form = <T,>({
  initialValues,
  onSubmit,
  children,
  validationSchema,
  ...props
}: IFormProps<T>) => {
  const validate = useMemo(() => {
    if (!validationSchema) {
      return;
    }

    // eslint-disable-next-line consistent-return
    return async (values: T) => {
      try {
        await validationSchema.validate(values, { abortEarly: false });
      } catch (err) {
        const errors = (err as ValidationError).inner.reduce(
          (formError, innerError) =>
            setIn(formError, innerError.path ?? "", innerError.message),
          {},
        );

        return errors;
      }
    };
  }, [validationSchema]);

  return (
    <BaseForm<T>
      {...props}
      initialValues={initialValues}
      onSubmit={onSubmit}
      validate={validate}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>{children}</form>
      )}
    />
  );
};

export default Form;
