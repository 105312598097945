import { Theme } from "@remo-co/ui-core/src/types";
import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";

export const useStyles = makeStyles<Theme>((theme) => ({
  header: {
    padding: `${theme.spacing(1)} ${theme.spacing(3)}`,
    borderRadius: 6,
  },
  root: {
    height: "100%",
    width: "100%",
  },
  tabContainer: {
    backgroundColor: theme.palette.polls.background,
    padding: "10px 10px 140px 10px",
    height: "100%",
    width: "100%",
    overflowY: "hidden",
  },
  tab: {
    minWidth: "25%",
  },
  tabs: {
    "& .Mui-selected": {
      color: theme.palette.text.primary,
    },
  },
}));
