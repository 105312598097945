import { useEffect, useState } from "react";
import { Actions, trackAction } from "modules/monitoring";
import { useSelector } from "react-redux";
import {
  selectCurrentVersion,
  selectDeployedVersion,
  selectVersionComparisonResult,
} from "modules/version/selectors";
import RollbackPrompt from "../RollbackPrompt";
import { VersionComparisonResult } from "../../types";
import { SHOW_UPDATE_REMINDER_INTERVAL } from "../../constants";

const RollbackDetector = () => {
  const [isOpen, setIsOpen] = useState(false);
  const versionComparisonResult = useSelector(selectVersionComparisonResult);
  const currentVersion = useSelector(selectCurrentVersion);
  const deployedVersion = useSelector(selectDeployedVersion);

  const [hasCancelledUpdate, setHasCancelledUpdate] = useState(false);

  useEffect(() => {
    if (
      versionComparisonResult ===
      VersionComparisonResult.CURRENT_VERSION_HIGHER_THAN_DEPLOYED
    ) {
      trackAction(Actions.ROLLBACK_SHOW_PROMPT, {
        currentVersion,
        deployedVersion,
      });

      setIsOpen(true);
    }
  }, [versionComparisonResult, currentVersion, deployedVersion]);

  useEffect(() => {
    if (!hasCancelledUpdate) {
      return;
    }

    const timeoutId = window.setTimeout(() => {
      trackAction(Actions.ROLLBACK_UPDATE_REMINDER);
      setIsOpen(true);
    }, SHOW_UPDATE_REMINDER_INTERVAL);

    // eslint-disable-next-line consistent-return
    return () => {
      window.clearInterval(timeoutId);
    };
  }, [hasCancelledUpdate]);

  const handleUpdate = () => {
    setIsOpen(false);
    trackAction(Actions.ROLLBACK_UPDATE_SUCCESS);
    window.location.reload();
  };

  const handleCancel = () => {
    setIsOpen(false);
    setHasCancelledUpdate(true);
    trackAction(Actions.ROLLBACK_UPDATE_CANCEL);
  };

  return (
    <RollbackPrompt
      open={isOpen}
      onUpdate={handleUpdate}
      onCancel={handleCancel}
    />
  );
};

export default RollbackDetector;
