import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "store";
import { SideMenus } from "./slice";

const selectActiveMenu = (state: RootState) => state.sideFeatureMenu;

export const selectIsLobbyMenuOpen = createSelector(
  selectActiveMenu,
  (substate) => substate.activeMenu === SideMenus.LOBBY,
);
