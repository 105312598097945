import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";

export const useStyles = makeStyles(() => ({
  root: {
    height: "calc(100% - 16px)",
  },
  promptText: {
    width: "60%",
  },
}));
