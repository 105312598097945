import React, { useState } from "react";
import { Container } from "@remo-co/ui-core/src/components/Container";
import classNames from "classnames";
import { useI18n } from "i18n";
import logger from "logging/logger";
import UploadIcon from "./assets/upload.svg";
import "./CustomFloorPlanUploader.scss";
import {
  amendFloorPlanSVG,
  documentToString,
  getUploadedFloorPlanContents,
  stringToDocument,
} from "./actions";
import useNotificationActions from "../notification/hooks/useNotificationActions";

interface ICustomFloorPlanUploader {
  handleSetFileSize: (fileSize: number) => void;
  handleSetLocalFileUrl: (url: string) => void;
  handleSetDialogVisible?: (isDialogVisible: boolean) => void;
}

const CustomFloorPlanUploader = ({
  handleSetLocalFileUrl,
  handleSetFileSize,
  handleSetDialogVisible,
}: ICustomFloorPlanUploader) => {
  const { t } = useI18n(["common", "customFloorPlan"]);
  const { addErrorNotification } = useNotificationActions();
  const [isHighlighted, setIsHighlighted] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const className = classNames("root", {
    isHighlighted,
  });

  const handleDropZoneHighlight = (e: React.MouseEvent) => {
    e.preventDefault();

    if (!isHighlighted) {
      setIsHighlighted(true);
    }
  };

  const handleDropZoneUnhighlight = (e: React.MouseEvent) => {
    e.preventDefault();

    if (isHighlighted) {
      setIsHighlighted(false);
    }
  };

  // eslint-disable-next-line consistent-return
  const handleFileDrop = async (e: React.DragEvent<HTMLInputElement>) => {
    setLoading(true);
    e.preventDefault();
    const file = e?.dataTransfer?.files?.[0];

    if (!file) {
      setLoading(false);
    }
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    await handleFileSelect(file);
  };

  // eslint-disable-next-line consistent-return
  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    setLoading(true);
    const file = e.target.files?.[0];

    if (!file) {
      setLoading(false);

      return;
    }

    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    await handleFileSelect(file);
  };

  // eslint-disable-next-line consistent-return
  const handleFileSelect = async (file: File) => {
    if (file.type !== "image/svg+xml") {
      addErrorNotification({
        message: t("customFloorPlan:error.invalid.file.format"),
      });
      setLoading(false);

      return;
    }

    try {
      const fileContents = await getUploadedFloorPlanContents(file);
      const amendedDocument = amendFloorPlanSVG(stringToDocument(fileContents));

      if (amendedDocument) {
        handleSetLocalFileUrl(documentToString(amendedDocument));
        handleSetFileSize(file.size);

        if (handleSetDialogVisible) handleSetDialogVisible(true);
        setLoading(false);
      } else {
        logger.error(
          "[CustomFloorPlanUploader][handleFileSelect] amendFloorPlanSVG returned a null document",
        );
        addErrorNotification({
          message: t("customFloorPlan:error.template.validation.failed"),
        });
        setLoading(false);
      }
    } catch (error) {
      logger.error(
        "[CustomFloorPlanUploader][handleFileSelect] Error encountered during file amendment",
      );
      addErrorNotification({
        message: t("customFloorPlan:error.template.validation.failed"),
      });
      setLoading(false);
    }
  };

  return (
    <Container
      className={className}
      onDrop={handleFileDrop}
      onDragEnter={handleDropZoneHighlight}
      onDragOver={handleDropZoneHighlight}
      onDragLeave={handleDropZoneUnhighlight}
      flex
      style={{
        flexDirection: "column",
      }}
    >
      <img
        src={UploadIcon}
        alt="upload icon"
        style={{
          marginBottom: "1rem",
        }}
      />
      <form>
        <label
          htmlFor="fileUpload"
          className={classNames("upload-label", {
            loading: isLoading,
          })}
          data-testid="cfp-file-upload-label"
        >
          {!isLoading
            ? t("customFloorPlan:choose.svg.file")
            : t("customFloorPlan:upload.loading")}
        </label>
        <input
          accept=".svg"
          onChange={handleFileChange}
          id="fileUpload"
          type="file"
          disabled={isLoading}
          className={classNames("upload-input")}
          data-testid="cfp-file-upload-input"
        />
        <p className={classNames("drop-svg-copy")}>
          {t("customFloorPlan:drop.svg.file")}
        </p>
      </form>
    </Container>
  );
};

export default CustomFloorPlanUploader;
