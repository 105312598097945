import ActionTypes from "./constants";

const showShuffleAttendeesDialog = () => ({
  type: ActionTypes.SHOW_SHUFFLE_ATTENDEES_DIALOG,
});

const hideShuffleAttendeesDialog = () => ({
  type: ActionTypes.HIDE_SHUFFLE_ATTENDEES_DIALOG,
});

const showShuffleAttendeesCountdownDialog = () => ({
  type: ActionTypes.SHOW_SHUFFLE_ATTENDEES_CNTDWN_DIALOG,
});

const hideShuffleAttendeesCountdownDialog = () => ({
  type: ActionTypes.HIDE_SHUFFLE_ATTENDEES_CNTDWN_DIALOG,
});

const showShuffleAttendeesCountdownBanner = () => ({
  type: ActionTypes.SHOW_SHUFFLE_ATTENDEES_CNTDWN_BANNER,
});

const hideShuffleAttendeesCountdownBanner = () => ({
  type: ActionTypes.HIDE_SHUFFLE_ATTENDEES_CNTDWN_BANNER,
});

const hideInitShuffleBanner = () => ({
  type: ActionTypes.HIDE_INIT_SHUFFLE_BANNER,
});

const showInitShuffleBanner = () => ({
  type: ActionTypes.SHOW_INIT_SHUFFLE_BANNER,
});

const setShuffleStartTime = (startTime: number) => ({
  payload: { startTime },
  type: ActionTypes.SET_SHUFFLE_START_TIME,
});

export {
  showShuffleAttendeesDialog,
  hideShuffleAttendeesDialog,
  showShuffleAttendeesCountdownDialog,
  hideShuffleAttendeesCountdownDialog,
  showShuffleAttendeesCountdownBanner,
  hideShuffleAttendeesCountdownBanner,
  setShuffleStartTime,
  showInitShuffleBanner,
  hideInitShuffleBanner,
};
