import { useCallback } from "react";
import { useSelector } from "react-redux";
import { useParticipantLocation } from "modules/participants/hooks";
import { useI18n } from "i18n";
import { UserItem } from "modules/participants/types";
import { useAppDispatch } from "store/hooks";
import { updateMapTargetState } from "modules/mapNavigation/redux/mapNavigationSlice";
import { setLayout } from "modules/audioVideo/redux/slice";
import { DisplayLayout } from "modules/audioVideo";
import { selectIsMobile } from "modules/deviceInfo";
import useNotificationActions from "modules/notification/hooks/useNotificationActions";
import { selectLayout } from "modules/audioVideo/redux/selectors";
import { TABS, setPanelState } from "modules/rightPanel";
import { getUserName } from "modules/userProfile";
import { useFloorNames } from "modules/floors/hooks/useFloorNames";
import { selectCurrentTheaterId } from "modules/theater/selectors";

export const useLocateParticipant = (user: UserItem) => {
  const dispatch = useAppDispatch();
  const { t } = useI18n(["participants"]);
  const isMobileView = useSelector(selectIsMobile);
  const layout = useSelector(selectLayout);
  const currentTheaterId = useSelector(selectCurrentTheaterId);
  const floorNames = useFloorNames(currentTheaterId);
  const { addInfoNotification } = useNotificationActions();
  const { locateUser } = useParticipantLocation();

  const userLocation = locateUser(user.id);

  const locateParticipant = useCallback(() => {
    if (userLocation.status === "differentFloor") {
      addInfoNotification({
        message: floorNames[userLocation.floorId]
          ? t("userLocator:notification.user.another.named.floor", {
              floor: floorNames[userLocation.floorId],
              name: getUserName(user),
            })
          : t("userLocator:notification.user.another.floor", {
              floor: userLocation.floor,
              name: getUserName(user),
            }),
      });
    } else if (userLocation.status === "currentFloor") {
      dispatch(updateMapTargetState(userLocation.coordinates));
    }
    if (isMobileView) {
      dispatch(
        setPanelState({
          isOpen: false,
          tabId: TABS.PARTICIPANTS,
        }),
      );
    }

    if (layout !== DisplayLayout.Map) {
      dispatch(setLayout(DisplayLayout.Map));
    }
  }, [
    addInfoNotification,
    dispatch,
    isMobileView,
    layout,
    t,
    user,
    userLocation,
    floorNames,
  ]);

  return {
    locateParticipant,
  };
};
