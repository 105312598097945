import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";

export const useStyles = makeStyles(() => ({
  container: {
    boxShadow: "none !important",
  },
  list: {
    display: "flex",
    listStyle: "none",
    paddingLeft: "0px",
    position: "relative",
    margin: "0px",
  },
}));
