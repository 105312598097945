import { Theme } from "@remo-co/ui-core/src/types";
import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";

const transitionDuration = "0.1s";
const rightPanelWidth = "416px";

export const useStyles = makeStyles<Theme, { isBroadcasting: boolean }>(
  (theme) => ({
    tabPanel: {
      padding: 0,
    },
    rightPanelContainer: {
      width: rightPanelWidth,
      marginLeft: theme.spacing(0.75),
      display: "flex",
      flexDirection: "column",
      transition: `width ${transitionDuration} ease-out, transform ${transitionDuration} ease-out, opacity ${transitionDuration} ease-out`,
      overflow: "hidden",
      borderTopLeftRadius: "5px",
      opacity: 1,
      borderBottomLeftRadius: "5px",
      height: "100%",
      pointerEvents: "auto",
      boxShadow: ({ isBroadcasting }) =>
        !isBroadcasting ? "2px 4px 20px rgb(0 0 0 / 10%)" : "none",
      [theme.breakpoints.down("sm")]: {
        boxShadow: "none",
      },
    },
    attendee: {
      minWidth: "200px",
      maxWidth: "40%",

      [`@media (max-width: ${rightPanelWidth})`]: {
        minWidth: "200px",
        maxWidth: "100%",
        margin: 0,
        borderRadius: 0,
      },
      [`@media (min-width: ${rightPanelWidth})`]: {
        minWidth: "200px",
        maxWidth: rightPanelWidth,
      },
    },
    hidden: {
      // Safari:
      // Shrinking parent width to 0px makes children reset scrollTop to 0
      // We leave 1px width to preserve scroll position and hide container
      // using opacity and delayed transition
      width: "1px",
      transition: `width ${transitionDuration} ease-in, transform ${transitionDuration} ease-in, opacity ${transitionDuration} ease-in`,
      opacity: 0,
      marginLeft: 0,
    },
    rightPanel: {
      backgroundColor: theme.palette.background.default,
      width: "100%",
      height: "100%",
      boxSizing: "border-box",
      display: "flex",
      flexDirection: "column",
    },
    tabsContent: {
      flexGrow: 1,
      boxSizing: "border-box",
      "& > div, .react-swipeable-view-container, .react-swipeable-view-container > div, .react-swipeable-view-container > div > div":
        {
          height: "100%",
        },
    },
    chatContainer: {
      height: "100%",
      color: "var(--VeryLightGrey)",
    },
  }),
);
