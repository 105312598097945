import { ChangeEvent } from "react";
import { Box } from "@remo-co/ui-core/src/components/Box";
import { Typography } from "@remo-co/ui-core/src/components/Typography";
import { FormTextInput } from "@remo-co/ui-core/src/components/FormTextInput";
import { useI18n } from "i18n";
import { IEvent } from "modules/event/types";
import LobbyToggle from "modules/lobby/LobbyToggle";
import { maxMessageLength } from "modules/eventForm/constants";
import "./styles.scss";
import { useStyles } from "./styles";

interface Props {
  isActive: boolean;
  message: string;
  setMessage: (message: string) => void;
  onEventDataChange: (data: Partial<IEvent>) => void;
}

const LobbySettings = ({
  isActive,
  message,
  setMessage,
  onEventDataChange,
}: Props): JSX.Element => {
  const { t } = useI18n(["common", "eventForm", "event"]);
  const styles = useStyles();

  const toggleIsActive = (checked: boolean) => {
    onEventDataChange({
      isLobbyActive: checked,
      lobbyWelcomeMessage: message,
    } as unknown as IEvent);
  };

  const handleMessage = (e: ChangeEvent<HTMLInputElement>) => {
    setMessage(e.target.value);
  };

  const updateLobbyMessage = () => {
    onEventDataChange({ lobbyWelcomeMessage: message } as unknown as IEvent);
  };

  return (
    <Box>
      <div>
        <Typography variant="h3">{t("eventForm:lobby.title")}</Typography>
        <Typography variant="body1">
          {t("eventForm:lobby.enable.message")}
        </Typography>
        <div className="mar-top-40">
          <LobbyToggle
            label={t("eventForm:enable.guest.lobby")}
            updateEvent={toggleIsActive}
            isTogglingLobby={false}
            overrideToggleStatus={isActive}
          />
        </div>
      </div>
      {isActive && (
        <>
          <Box
            display="flex"
            flexDirection="row"
            flexWrap="wrap"
            alignItems="center"
            justifyContent="space-between"
            className={styles.heading}
          >
            <Typography variant="h4" className={styles.title}>
              {t("eventForm:customize.message")}
            </Typography>
          </Box>
          <FormTextInput
            className="mar-top-20"
            disabled={!isActive}
            multiline
            rows={3}
            variant="outlined"
            fullWidth
            getRemainingCharsMessage={(key) =>
              t("character.remaining", { key })
            }
            InputLabelProps={{ shrink: true }}
            placeholder={t("eventForm:lobby.empty.placeholder")}
            value={message}
            onChange={handleMessage}
            onBlur={updateLobbyMessage}
            inputProps={{
              maxLength: maxMessageLength,
              style: { lineHeight: "1.2rem", minHeight: "160px" },
            }}
          />
        </>
      )}
    </Box>
  );
};

export default LobbySettings;
