import { Map } from "immutable";
import { IUser } from "modules/app/types";
import { UsersInEventAction } from "./actions";
import ActionTypes from "./constants";

export interface IUsersInEventState {
  users: Map<string, IUser>;
  isLoaded: boolean;
  eventId?: string;
}

export const usersInEventInitialState: IUsersInEventState = {
  users: Map<string, IUser>(),
  isLoaded: false,
  eventId: undefined, // Event Id for which the users are loaded
};

export const usersInEventReducer = (
  state = usersInEventInitialState,
  action: UsersInEventAction,
) => {
  switch (action.type) {
    case ActionTypes.ADD_USERS_IN_EVENT:
      return {
        ...state,
        users: state.users.set(action.payload.userId, action.payload.user), // Concat new users and update state
      };
    case ActionTypes.RESET_USERS_IN_EVENT:
      return {
        ...state,
        isLoaded: false,
        eventId: undefined,
        users: Map<string, IUser>(),
      };
    case ActionTypes.USERS_IN_EVENT_LOADED:
      return {
        ...state,
        eventId: action.payload.eventId,
        isLoaded: action.payload.isLoaded,
      };

    default:
      return state;
  }
};
