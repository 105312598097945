import { Theme } from "@remo-co/ui-core/src/types";
import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";

export const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    color: theme.palette.white,
    display: "block",
    padding: "10px",
    marginBottom: "10px",
    backgroundColor: "#272A39",
    borderRadius: "5px",
  },
  messageUser: {
    width: "100%",
    display: "inline-flex",
    alignItems: "center",
    fontWeight: 800,
  },
  avatar: {
    width: "30px !important",
    height: "30px !important",
    marginRight: "10px",
  },
  messageBody: {
    marginTop: "10px",
    marginBottom: "10px",
    color: theme.palette.white,
  },
  messageTime: {
    opacity: 0.7,
    marginBottom: "5px",
    fontWeight: 800,
  },
  messageText: {
    opacity: 0.9,
  },
}));
