import React from "react";

export const FullScreenExitIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="36px"
    height="36px"
    viewBox="0 0 36 36"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
    className="MuiSvgIcon-root"
  >
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-6.000000, -6.000000)">
        <rect x="0" y="0" width="48" height="48" />
        <path
          d="M40.82,19.18 L36.22,14.58 L42,8.84 L39.16,6 L33.42,11.78 L28.82,7.18 L28.82,19.18
            L40.82,19.18 Z M19.18,7.18 L14.58,11.78 L8.84,6 L6,8.84 L11.78,14.58 L7.18,19.18 L19.18,19.18
            L19.18,7.18 Z M7.18,28.82 L11.78,33.42 L6,39.16 L8.84,42 L14.58,36.22 L19.18,40.82 L19.18,28.82
            L7.18,28.82 Z M28.82,40.82 L33.42,36.22 L39.16,42 L42,39.16 L36.22,33.42 L40.82,28.82 L28.82,
            28.82 L28.82,40.82 Z"
          id="Shape"
          fill="#FFFFFF"
          fillRule="nonzero"
        />
      </g>
    </g>
  </svg>
);
