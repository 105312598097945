import { useI18n } from "i18n";
import { ActionButton } from "modules/actionButton";
import { useAppDispatch } from "store/hooks";
import { setAudioReactionDialog } from "modules/audioReactions/redux/slice";
import { SoundIcon } from "@remo-co/ui-core/src/icons/Sound";
import { selectCurrentAudio } from "modules/audioReactions/redux/selectors";
import { useSelector } from "react-redux";
import { useContext } from "react";
import { Events, TRACKING_CONTEXT } from "modules/tracking";
import { selectCurrentEvent } from "modules/event/selectors";
import { useStyles } from "./styles";

export const AudioReactionsButton = () => {
  const { t } = useI18n();
  const dispatch = useAppDispatch();
  const currentAudio = useSelector(selectCurrentAudio);
  const { track } = useContext(TRACKING_CONTEXT);
  const currentEvent = useSelector(selectCurrentEvent);
  const classes = useStyles();

  const handleSoundEffectsButtonClick = () => {
    dispatch(setAudioReactionDialog(true));
    track(Events.AUDIO_REACTIONS_OPENED, {
      eventId: currentEvent?.id,
      eventType: currentEvent?.eventType,
    });
  };

  return (
    <ActionButton
      title={t("play.sound.effects")}
      label={t("play.sound.effects")}
      onClick={handleSoundEffectsButtonClick}
      isPopoverButton
      icon={
        <span className={classes.soundButton}>
          <SoundIcon />
        </span>
      }
      darkMode
      disabled={Boolean(currentAudio)}
      data-testid="audio-reactions-button"
    />
  );
};
