import { PackedGrid } from "react-packed-grid";
import { Container } from "@remo-co/ui-core/src/components/Container";
import { useSelector } from "react-redux";
import { selectIsMobile } from "modules/deviceInfo";
import { useResizeDetector } from "react-resize-detector";
import { useCallback, useEffect, useMemo, useRef } from "react";
import { LayoutTile } from "modules/audioVideo/components/DailyContainer/Tiles/LayoutTile";
import { PaginationButton } from "modules/audioVideo/components/DailyContainer/PaginationButton";
import { ChangeLayoutButton } from "modules/audioVideo/components/DailyContainer/ChangeLayoutButton";
import { PopperPlacement } from "modules/audioVideo/components/DailyContainer/ChangeLayoutButton/constants";
import { usePagination } from "modules/audioVideo/components/DailyContainer/Layouts/hooks/usePagination";
import { LayoutProps } from "modules/audioVideo/components/DailyContainer/Layouts/types";
import {
  MAX_PAGE_SIZE_GRID_DESKTOP,
  MAX_PAGE_SIZE_GRID_MOBILE,
} from "modules/audioVideo/components/DailyContainer/Layouts/hooks/constants";
import { selectIsInBroadcast } from "modules/broadcast/redux/selectors";
import { useActiveSpeakerTile } from "modules/audioVideo/hooks/useActiveSpeakerTile";

import { useStyles } from "./styles";

export const GridLayout = ({
  tiles,
  onTileClick,
  switchLayout,
}: LayoutProps) => {
  const isMobile = useSelector(selectIsMobile);
  const isInBroadcast = useSelector(selectIsInBroadcast);

  const maxPageSize = useMemo(() => {
    if (isMobile) {
      return MAX_PAGE_SIZE_GRID_MOBILE;
    }

    return MAX_PAGE_SIZE_GRID_DESKTOP;
  }, [isMobile]);

  const { participantTiles } = useActiveSpeakerTile(tiles, maxPageSize);

  const {
    currentPage,
    canPaginateBackward,
    canPaginateForward,
    paginateBack,
    paginateForward,
    pageIndex,
    totalPages,
    hasPagination,
  } = usePagination(participantTiles, maxPageSize);

  const showViewButton = !(isInBroadcast && tiles.length === 1);

  const styles = useStyles({
    hasPagination,
    isMobile,
    showViewButton,
  });

  const updateLayoutRef = useRef<() => void>();

  const onResize = useCallback(() => {
    updateLayoutRef.current?.();
  }, []);

  useEffect(() => {
    onResize();
  }, [hasPagination, onResize]);

  const { ref } = useResizeDetector({ onResize });

  return (
    <Container ref={ref} className={styles.root} data-testid="grid-layout">
      <PackedGrid
        updateLayoutRef={updateLayoutRef}
        className={styles.grid}
        boxAspectRatio={4 / 3}
      >
        {currentPage.map((tile) => (
          <LayoutTile
            className={styles.gridItem}
            key={tile.streamId}
            tile={tile}
            onTileClick={onTileClick}
          />
        ))}
      </PackedGrid>
      {(!isInBroadcast || tiles.length > 1) && (
        <div className={styles.banner}>
          <Container className={styles.button}>
            <Container className={styles.viewButtonRoot}>
              {hasPagination && (
                <PaginationButton
                  canPaginateForward={canPaginateForward}
                  canPaginateBackward={canPaginateBackward}
                  paginateForward={paginateForward}
                  paginateBackward={paginateBack}
                  totalPages={totalPages}
                  currentPage={pageIndex}
                  direction="horizontal"
                />
              )}
              {showViewButton && (
                <ChangeLayoutButton
                  placement={
                    isMobile ? PopperPlacement.top : PopperPlacement.topStart
                  }
                  tiles={tiles}
                  hasPagination={hasPagination}
                  switchLayout={switchLayout}
                />
              )}
            </Container>
          </Container>
        </div>
      )}
    </Container>
  );
};
