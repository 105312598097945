import { BROWSER_NAMES } from "modules/systemCheck/constants";

export const SUPPORTED_BROWSERS_NAMES_MAPPING: {
  [browserName: string]: BROWSER_NAMES;
} = {
  chrome: BROWSER_NAMES.CHROME,
  safari: BROWSER_NAMES.SAFARI,
  edge: BROWSER_NAMES.EDGE,
  ios_saf: BROWSER_NAMES.MOBILE_SAFARI,
  firefox: BROWSER_NAMES.FIREFOX,
};

export const SUPPORTED_BEFORE_LATEST: { [key in BROWSER_NAMES]?: number } = {
  [BROWSER_NAMES.CHROME]: 2,
  [BROWSER_NAMES.EDGE]: 2,
  [BROWSER_NAMES.FIREFOX]: 2,
  [BROWSER_NAMES.SAFARI]: 1,
  [BROWSER_NAMES.MOBILE_SAFARI]: 1,
};

export const PARTIALLY_SUPPORTED_BROWSERS = [BROWSER_NAMES.FIREFOX];
