import { usePrevious } from "helpers/reactHooksUtils";
import useDebounce from "modules/common/hooks/useDebounce";
import throttle from "modules/throttle";
import { TranscriptionContext } from "modules/transcription/components";
import React, { useContext } from "react";

type UserTranscript = {
  userId: string;
  data: RealtimeSpeechData;
  time: number;
};

interface TranscriptionFeed {
  transcripts: UserTranscript[];
  activeSpeaker: string | null;
}

export const NEW_MESSAGE_THROTTLE_TIME = 100;
export const TRANSCRIPT_OLD_TIME = 3000;

export const TRANSCRIPT_REMOVAL_INTERVAL = 1000;
export const ACTIVE_SPEAKER_DEBOUNCE_TIME = 500;

const useTranscriptionFeed = (): TranscriptionFeed => {
  const { transcript } = useContext(TranscriptionContext);
  const previousTranscript = usePrevious(transcript);

  const userId = transcript?.user.userId;

  const [activeSpeaker, setActiveSpeaker] = React.useState<string | null>(null);

  const debouncedActiveSpeaker = useDebounce(
    activeSpeaker,
    ACTIVE_SPEAKER_DEBOUNCE_TIME,
  );

  const [throttledTranscripts, setThrottledTranscripts] = React.useState<
    UserTranscript[]
  >([]);

  const isOld = (ts: UserTranscript) =>
    ts.time <= Date.now() - TRANSCRIPT_OLD_TIME;

  const throttledSet = React.useMemo(
    () => throttle(setThrottledTranscripts, NEW_MESSAGE_THROTTLE_TIME),
    [setThrottledTranscripts],
  );

  React.useEffect(() => {
    // filters old transcripts and adds new transcript
    if (
      transcript &&
      userId &&
      previousTranscript &&
      transcript.punctuated.transcript !==
        previousTranscript.punctuated.transcript
    ) {
      throttledSet((prev) => {
        const newTranscripts = prev.filter((ts) => {
          const isDuplicate = ts.userId === userId;
          return !isDuplicate && !isOld(ts);
        });
        const currentTime = Date.now();
        newTranscripts.push({
          userId,
          data: transcript,
          time: currentTime,
        });

        return newTranscripts;
      });
      setActiveSpeaker(userId);
    }
  }, [transcript, previousTranscript, userId, throttledSet]);

  React.useEffect(() => {
    const removeOldTranscripts = (transcripts: UserTranscript[]) =>
      transcripts.filter((ts) => !isOld(ts));

    const interval = setInterval(() => {
      const newTranscripts = removeOldTranscripts(throttledTranscripts);
      throttledSet(newTranscripts);
    }, TRANSCRIPT_REMOVAL_INTERVAL);
    return () => clearInterval(interval);
  }, [throttledTranscripts, throttledSet]);

  return {
    transcripts: throttledTranscripts,
    activeSpeaker: debouncedActiveSpeaker,
  };
};

export default useTranscriptionFeed;
