import {
  GetEventContentQuery,
  OnEventContentChangedSubscription,
} from "graphql/generated";

interface UpdateSubscriptionData {
  subscriptionData: {
    data: OnEventContentChangedSubscription;
  };
}

export const updateEventContentList = (
  prev: GetEventContentQuery,
  { subscriptionData }: UpdateSubscriptionData,
): GetEventContentQuery => {
  if (prev.eventContent.__typename === "GQLError") {
    return prev;
  }

  const newOrUpdatedContent = subscriptionData.data.onEventContentChanged;

  if (!newOrUpdatedContent) {
    return prev;
  }

  return {
    eventContent: {
      ...prev.eventContent,
      // if incoming content is from an update, filter out the existing version
      content: [
        ...prev.eventContent.content.filter(
          (existingContent) =>
            !(
              existingContent.id === newOrUpdatedContent.id &&
              existingContent.floor === newOrUpdatedContent.floor &&
              existingContent.slot === newOrUpdatedContent.slot
            ),
        ),
        newOrUpdatedContent,
      ],
    },
  };
};
