import { useEffect, useRef, useState } from "react";
import { Paper } from "@remo-co/ui-core/src/components/Paper";
import { PageLoader } from "@remo-co/ui-core/src/components/PageLoader";
import classNames from "classnames";
import { Sponsors } from "modules/sponsors";
import { IMapTemplate } from "types/theater";
import { ISponsor } from "modules/event/types";
import { useI18n } from "i18n";
import { useStyles } from "./styles";

interface Props {
  theme: string;
  mapImage: string;
  selectedTemplate: IMapTemplate;
  allSponsors?: ISponsor[];
  highlight?: number;
  showSelected?: (index: number) => void;
  eventId?: string;
  className?: string;
}

const MapPreview = ({
  theme = "",
  mapImage,
  selectedTemplate,
  allSponsors = [],
  highlight,
  showSelected,
  eventId,
  className,
}: Props): JSX.Element => {
  const [isImageLoading, setIsImageLoading] = useState(false);
  const mapRef = useRef<HTMLDivElement | null>(null);
  const styles = useStyles();
  const { t } = useI18n(["common"]);

  const themeImageOnLoad = () => {
    setIsImageLoading(false);

    // Added setTimeout because mapRef offsetWidth value not loaded at first
    setTimeout(() => {
      if (mapRef?.current) {
        const { parentNode, offsetWidth, style } = mapRef.current;
        const ratio =
          ((parentNode as HTMLDivElement)?.offsetWidth ?? 0 + 10) / offsetWidth;

        style.transform = `scale(${ratio})`;
        style.transformOrigin = "top left";
      }
    }, 0);
  };

  useEffect(() => {
    if (mapImage) {
      setIsImageLoading(true);
    }
  }, [mapImage]);

  return (
    <Paper
      className={classNames(
        theme,
        styles.themeContainer,
        {
          [styles.isLoading]: isImageLoading,
        },
        className,
      )}
    >
      {mapImage && (
        <div data-testid="map-image" className={styles.mapImageContainer}>
          <div
            ref={mapRef}
            style={{
              height: selectedTemplate.height,
              width: selectedTemplate.width,
            }}
            className={styles.mapImage}
          >
            <img
              onLoad={themeImageOnLoad}
              src={mapImage}
              alt={`${theme} ${selectedTemplate.name}`}
              className={classNames(styles.image, {
                [styles.isImageLoading]: isImageLoading,
              })}
            />
            {selectedTemplate.sponsors && (
              <Sponsors
                eventId={eventId}
                showSelected={showSelected}
                highlight={highlight}
                sponsors={allSponsors}
                slots={selectedTemplate.sponsors}
                showSlots
              />
            )}
          </div>
        </div>
      )}
      {isImageLoading ? <PageLoader message={t("loading")} /> : null}
    </Paper>
  );
};

export default MapPreview;
