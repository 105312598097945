import { useState, ReactElement, useContext } from "react";
import { Button } from "@remo-co/ui-core/src/components/Button";
import { useI18n } from "i18n";
import {
  MutationCreateEventPollArgs,
  EventPollType,
  EventPollStatus,
  EventPollOptionInput,
} from "graphql/generated";
import { PollForm } from "modules/polls/components/PollForm";
import { Actions, trackAction } from "modules/monitoring";
import { Events, TRACKING_CONTEXT } from "modules/tracking";
import { selectCurrentEvent } from "modules/event/selectors";
import { useSelector } from "react-redux";
import { useAppMessage } from "@daily-co/daily-react";
import {
  CustomAppEvent,
  CustomAppEvents,
} from "modules/audioVideo/DailyEvents";

export const CreatePollForm = ({
  onSubmit,
  loading,
  pollType,
  initialForm = {
    eventId: "",
    status: EventPollStatus.Draft,
    timer: 30,
    title: "",
    type: EventPollType.Poll,
  },
}: {
  loading: boolean;
  onSubmit: (poll: MutationCreateEventPollArgs) => Promise<void>;
  pollType: EventPollType;
  initialForm?: MutationCreateEventPollArgs;
}): ReactElement => {
  const { t } = useI18n(["event"]);
  const [pollState, setPollState] = useState<
    Omit<MutationCreateEventPollArgs, "options" | "timer"> & { timer?: number }
  >(initialForm);
  const { track } = useContext(TRACKING_CONTEXT);
  const currentEvent = useSelector(selectCurrentEvent);

  const [options, setOptions] = useState<
    Array<EventPollOptionInput & { index: number }>
  >(
    initialForm?.options?.map((option, index) => ({
      ...option,
      index,
    })) ?? [],
  );
  const sendMessage = useAppMessage<CustomAppEvent>();

  const handleSubmit = (status: EventPollStatus) => {
    setPollState({ ...pollState, status });
    trackAction(Actions.POLLS_CREATE);
    track(Events.POLL_CREATED, {
      eventId: currentEvent?.id,
      eventType: currentEvent?.eventType,
      pollType,
    });

    if (status === EventPollStatus.Ongoing) {
      sendMessage({
        event: CustomAppEvents.POLL_QUIZ_LAUNCHED,
        pollType,
      });
      track(Events.POLL_STARTED, {
        eventId: currentEvent?.id,
        eventType: currentEvent?.eventType,
        pollType,
      });
    }
    onSubmit({
      ...pollState,
      status,
      timer: pollState.timer ?? 30,
      options: options.map((option) => ({
        option: option.option,
        correctAnswer: option.correctAnswer ?? false,
      })),
    });
  };

  return (
    <PollForm<MutationCreateEventPollArgs, unknown>
      options={options}
      setOptions={setOptions}
      pollState={pollState}
      pollType={pollType}
      onPollStateChange={(newPollState) => setPollState(newPollState)}
      Buttons={
        <>
          <Button
            disabled={loading}
            loading={loading && pollState.status === EventPollStatus.Draft}
            onClick={() => {
              handleSubmit(EventPollStatus.Draft);
            }}
            variant="secondary"
            color="blue"
          >
            {t("save.as.draft")}
          </Button>
          <Button
            disabled={loading}
            loading={loading && pollState.status === EventPollStatus.Ongoing}
            onClick={() => {
              handleSubmit(EventPollStatus.Ongoing);
            }}
            variant="primary"
            color="blue"
          >
            {t("start.poll")}
          </Button>
        </>
      }
    />
  );
};
