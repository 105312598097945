import {
  doc,
  getDoc,
  setDoc,
  updateDoc,
  onSnapshot,
  collection,
  deleteField,
} from "firebase/firestore";
import logger from "logging/logger";
import {
  firebaseAuth,
  firestore,
} from "../../services/firebaseService/firebaseConfig";

export const USER_APPLICATION_COLLECTION = "UserApplications";

export const updateAppInstanceUuid = async (
  userId: string,
  eventId: string,
  appInstanceUuid: string,
) => {
  const docRef = doc(
    collection(firestore, USER_APPLICATION_COLLECTION),
    userId,
  );
  const docSnap = await getDoc(docRef);

  if (!docSnap.exists()) {
    return setDoc(docRef, { [eventId]: appInstanceUuid });
  }

  return updateDoc(docRef, { [eventId]: appInstanceUuid });
};

export const onAppInstanceUuidChange = async (
  userId: string,
  eventId: string,
  onChange: (appInstanceUuid: string | undefined) => void,
) =>
  onSnapshot(
    doc(collection(firestore, USER_APPLICATION_COLLECTION), userId),
    (doc) => {
      const data = doc.data() || {};

      if (eventId in data) {
        onChange(data[eventId]);
      }
    },
  );

export const cleanAppInstanceUuid = async (userId: string, eventId: string) => {
  try {
    if (userId && eventId && firebaseAuth.currentUser) {
      const docRef = doc(
        collection(firestore, USER_APPLICATION_COLLECTION),
        userId,
      );
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        await updateDoc(docRef, {
          [eventId]: deleteField(),
        });
      }
    }
  } catch (error) {
    const errorMessage = error instanceof Error ? error.message : "";
    logger.error(
      `[cleanAppInstanceUuid] userId: ${userId} event: ${eventId} error: ${errorMessage}`,
    );
  }
};
