import { Theme } from "@remo-co/ui-core/src/types";
import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";
import { LabelPosition } from "./FloorPlanSelectTags";

interface Props {
  labelPosition?: LabelPosition;
}

export const useStyles = makeStyles<Theme, Props>(() => ({
  filterText: {
    minWidth: 65,
    textAlign: "initial",
    fontWeight: 400,
  },
  selectBox: ({ labelPosition }) => ({
    flexDirection: labelPosition === "top" ? "column" : "row",
    alignItems: labelPosition === "top" ? "left" : "center",
  }),
  selectCreateText: {
    paddingLeft: 10,
    paddingTop: 10,
    fontWeight: 600,
  },
  selectOption: {
    padding: 0,
    "& span": {
      margin: "0px !important",
    },
  },
  selectList: {
    padding: 0,
  },
  selectField: {
    maxWidth: 280,
    minWidth: 180,
  },
  tagsInput: {
    border: "1px solid black",
    borderRadius: 5,
    minHeight: 40,
    paddingLeft: 10,
    paddingRight: "20px !important",
    "&:before, &:after": {
      border: "0 !important",
    },
    "&:focus, &:hover": {
      borderColor: "black !important",
    },
  },
}));
