import { useEffect } from "react";
import { useI18n } from "i18n";
import { Colors } from "@remo-co/ui-core/theme";
import { Warnings, trackWarning } from "modules/monitoring";
import { useTicker } from "modules/dateTime";
import { Popover } from "@remo-co/ui-core/src/components/Popover";
import { Container } from "@remo-co/ui-core/src/components/Container";
import { Loader } from "@remo-co/ui-core/src/components/Loader";
import { Typography } from "@remo-co/ui-core/src/components/Typography";
import { useTheme } from "@remo-co/ui-core/src/hooks/useTheme";
import { useAppDispatch } from "store/hooks";
import { useSelector } from "react-redux";
import {
  selectCountdownStartTime,
  setIsInProgressDialogVisible,
} from "modules/enforceTableAssignment/redux";
import { ENFORCE_COUNTDOWN_DURATION, ENFORCE_TIMEOUT } from "../../constants";

const EnforceInProgressDialog = (): JSX.Element => {
  const { t } = useI18n(["event"]);
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const countdownStartTime = useSelector(selectCountdownStartTime);

  const now = useTicker(!!countdownStartTime, 1000);

  useEffect(() => {
    if (!countdownStartTime) {
      return;
    }
    const secondsUntilTimeout =
      countdownStartTime +
      ENFORCE_COUNTDOWN_DURATION +
      ENFORCE_TIMEOUT -
      now / 1000;
    if (secondsUntilTimeout < 0) {
      trackWarning(Warnings.ENFORCE_TIMEOUT);
      dispatch(setIsInProgressDialogVisible(false));
    }
  }, [countdownStartTime, now, dispatch]);

  return (
    <Popover
      anchorEl={document.body}
      BackdropProps={{
        color: theme.palette.blue.disabled,
      }}
      transformOrigin={{
        vertical: "center",
        horizontal: "center",
      }}
      anchorOrigin={{
        vertical: "center",
        horizontal: "center",
      }}
      open
    >
      <Container size="md" flex column alignItems="center">
        <Container inline size="sm">
          <Loader
            loaderColor={Colors.BLUE_PRIMARY}
            backgroundColor={Colors.WHITE}
          />
        </Container>
        <Typography variant="h5">
          {t("enforce.table.assignment.progress")}
        </Typography>
        <Typography variant="caption">{t("wait.few.seconds")}</Typography>
      </Container>
    </Popover>
  );
};

export default EnforceInProgressDialog;
