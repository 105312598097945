import { gql } from "@apollo/client";

export const ANSWER_POLL = gql`
  mutation AnswerPoll($pollId: Int!, $optionId: Int!) {
    answerPoll(pollId: $pollId, optionId: $optionId) {
      ... on EventPoll {
        __typename
        id
        correctAnswers
        createdBy
        createdAt
        eventId
        options {
          option
          id
          votes
        }
        selectedAnswer
        userAnswers {
          pollId
          userId
          optionId
        }
        status
        title
        timer
        type
        updatedAt
        resultDisplayMode
      }
      ... on GQLError {
        __typename
        message
      }
    }
  }
`;
export const DELETE_POLL = gql`
  mutation DeleteEventPoll($pollId: Int!, $eventId: ObjectID!) {
    deleteEventPoll(pollId: $pollId, eventId: $eventId) {
      ... on EventPollDelete {
        __typename
        id
        correctAnswers
        createdBy
        createdAt
        eventId
        status
        options {
          id
          option
          votes
        }
        title
        timer
        type
        updatedAt
        resultDisplayMode
      }
      ... on GQLError {
        __typename
        message
      }
    }
  }
`;

export const CREATE_POLL = gql`
  mutation CreateEventPoll(
    $eventId: ObjectID!
    $status: EventPollStatus!
    $timer: Int!
    $title: String!
    $type: EventPollType!
    $options: [EventPollOptionInput!]
    $resultDisplayMode: EventPollResultDisplayMode
  ) {
    createEventPoll(
      eventId: $eventId
      status: $status
      timer: $timer
      title: $title
      type: $type
      options: $options
      resultDisplayMode: $resultDisplayMode
    ) {
      ... on EventPoll {
        __typename
        id
        correctAnswers
        createdBy
        createdAt
        eventId
        options {
          option
          id
          votes
        }
        selectedAnswer
        timer
        status
        title
        type
        updatedAt
        resultDisplayMode
      }
      ... on GQLError {
        __typename
        message
      }
    }
  }
`;

export const UPDATE_POLL = gql`
  mutation UpdateEventPoll(
    $id: Int!
    $eventId: ObjectID!
    $status: EventPollStatus
    $timer: Int
    $title: String
    $type: EventPollType
    $options: [EventPollOptionUpdateInput!]
    $resultDisplayMode: EventPollResultDisplayMode
  ) {
    updateEventPoll(
      id: $id
      eventId: $eventId
      status: $status
      timer: $timer
      title: $title
      type: $type
      options: $options
      resultDisplayMode: $resultDisplayMode
    ) {
      ... on EventPoll {
        __typename
        id
        correctAnswers
        createdBy
        createdAt
        eventId
        options {
          option
          id
          votes
        }
        selectedAnswer
        userAnswers {
          pollId
          userId
          optionId
        }
        timer
        status
        title
        type
        updatedAt
        resultDisplayMode
      }
      ... on GQLError {
        __typename
        message
      }
    }
  }
`;

export const CHANGE_POLL_STATUS = gql`
  mutation ChangePollStatus(
    $pollId: Int!
    $eventId: ObjectID!
    $pollStatus: EventPollStatus!
  ) {
    updateEventPoll(id: $pollId, eventId: $eventId, status: $pollStatus) {
      ... on EventPoll {
        __typename
        id
        updatedAt
        type
        status
        options {
          option
          id
          votes
        }
        selectedAnswer
        userAnswers {
          pollId
          userId
          optionId
        }
        eventId
        createdBy
        createdAt
        correctAnswers
        title
        timer
        resultDisplayMode
      }
      ... on GQLError {
        __typename
        message
      }
    }
  }
`;
