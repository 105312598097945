import { useMutation } from "@tanstack/react-query";
import { useI18n } from "i18n";
import { Errors, trackError } from "modules/monitoring";
import useNotificationActions from "modules/notification/hooks/useNotificationActions";
import { Events, TRACKING_CONTEXT } from "modules/tracking";
import { useContext } from "react";
import { EnforceAssignmentErrors } from "../../constants";
import request from "./request";

const useNotifyBeforeEnforce = (eventId?: string) => {
  const { track } = useContext(TRACKING_CONTEXT);
  const { addErrorNotification, addSuccessNotification } =
    useNotificationActions();
  const { t } = useI18n(["manageEvent"]);

  const { mutate: notifyBeforeEnforce, status } = useMutation({
    mutationFn: async () => {
      if (!eventId) {
        throw new Error(EnforceAssignmentErrors.NO_EVENT_ID);
      }

      return request(eventId);
    },

    onSuccess: () => {
      addSuccessNotification({
        message: t("manageEvent:notify.attendees.success"),
        position: "tc",
      });

      track(Events.ASSIGN_TABLE_MOVE_ALL_STARTED);
    },

    onError: (error: Error, _) => {
      trackError(error, {
        label: Errors.ASSIGN_TABLES_ALL_ATTENDEE_MOVE,
        eventId,
      });
      addErrorNotification({
        message: t("manageEvent:notify.attendees.error"),
        position: "tc",
      });
    },
  });

  return {
    notifyBeforeEnforce,
    status,
  };
};

export default useNotifyBeforeEnforce;
