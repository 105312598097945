import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";

export const useStyles = makeStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.blue.dark,
    color: theme.palette.white,
    width: theme.spacing(37.5),
    gap: 0,
    opacity: 1,
    textAlign: "left",
    padding: theme.spacing(1.25),
    "& .MuiTooltip-arrow": {
      color: theme.palette.blue.dark,
    },
  },
}));
