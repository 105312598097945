import { RemoLogo } from "@remo-co/ui-core/src/components/RemoLogo";
import { useI18n } from "i18n";
import { Typography } from "@remo-co/ui-core/src/components/Typography";
import { Button } from "@remo-co/ui-core/src/components/Button";
import "./SingleAppInstanceMessage.scss";

function refresh() {
  window.location.reload();
}

const SingleAppInstanceMessage = () => {
  const { t } = useI18n();

  return (
    <div className="secondary-instance-container">
      <div
        data-testid="secondary-instance-message"
        className="secondary-instance"
      >
        <div className="secondary-instance-content">
          <RemoLogo width={225} />
          <div className="secondary-instance-text-muted mt-40">
            <Typography>{t("using.remo.app")}</Typography>
            <Typography>{t("conference.multiple.tabs.device")}</Typography>
          </div>
          <div className="secondary-instance-text mt-25">
            <Typography>{t("now.remo.works")}</Typography>
            <Typography>{t("reliable.single.tab.device")}</Typography>
          </div>
          <div className="secondary-instance-text-muted mt-25">
            <Typography>{t("disable.remo.instance")}</Typography>
            <Typography>{t("refresh.page")}</Typography>
          </div>
          <Button
            onClick={refresh}
            className="secondary-instance-button mt-50"
            variant="primary"
            color="blue"
          >
            {t("disable.other.remo.app")}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default SingleAppInstanceMessage;
