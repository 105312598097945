import { useCallback, MutableRefObject, useContext } from "react";
import { useI18n } from "i18n";
import { useSelector } from "react-redux";
import { Popper } from "@remo-co/ui-core/src/components/Popper";
import { Typography } from "@remo-co/ui-core/src/components/Typography";
import { Mic } from "@remo-co/ui-core/src/icons/Mic";
import { MicOff } from "@remo-co/ui-core/src/icons/MicOff";
import { Info as ProfileCardIcon } from "@remo-co/ui-core/src/icons/Info";
import { StopScreenShare } from "@remo-co/ui-core/src/icons/StopScreenShare";
import { VideocamOff } from "@remo-co/ui-core/src/icons/VideocamOff";
import { Chat as ChatIcon } from "@remo-co/ui-core/src/icons/Chat";
import { MoveParticipant } from "@remo-co/ui-core/src/icons/MoveParticipant";
import { RemoveCircle } from "@remo-co/ui-core/src/icons/RemoveCircle";
import {
  selectCurrentEventId,
  selectIsChatEnabled,
  selectIsEventManager,
} from "modules/event/selectors";
import { PresentModeIcon } from "modules/eventButtons/icons/PresentModeIcon";
import { selectIsBroadcasting } from "modules/broadcast/redux/selectors";
import { usePermissions } from "modules/audioVideo/hooks/usePermissions";
import { useParticipant } from "modules/audioVideo/hooks/useParticipant";
import { setShowUserForceMoveDialog } from "modules/userForceMove/redux/slice";
import { useAppDispatch } from "store/hooks";
import { selectIsUserMoving } from "modules/userForceMove/redux/selectors";
import { Events, TRACKING_CONTEXT } from "modules/tracking";
import { getUserName } from "modules/userProfile";
import { selectCurrentTheaterId } from "modules/theater/selectors";
import { useRemoveUserFromFloor } from "modules/joinFloor";
import useAttendeeActions from "modules/eventMemberList/hooks/useAttendeeActions";
import { useParticipantLocation } from "modules/participants/hooks";
import { openUserProfileCard } from "modules/userProfileCard/redux/slice";
import { selectUser } from "modules/auth/redux/selectors";
import { addDialogNotification } from "modules/dialogNotification/redux/dialogNotificationSlice";
import { ReactComponent as LocateIcon } from "../assets/locate.svg";
import useStyles from "./styles";
import { UserItem } from "../../types";
import { useLocateParticipant } from "../../hooks/useLocateParticipant";
import { GetUser } from "../GetUser";

interface Props {
  user: UserItem;
  anchorElementRef: MutableRefObject<HTMLButtonElement | null>;
  toggleBroadcast(): void;
  sendMessage(): void;
  isOpen: boolean;
  closePopper(): void;
}

const UserDropdownActions = ({
  user,
  anchorElementRef,
  toggleBroadcast,
  sendMessage,
  isOpen,
  closePopper,
}: Props): JSX.Element => {
  const styles = useStyles();
  const isBroadcasting = useSelector(selectIsBroadcasting);

  const {
    audio: microphoneActive,
    video: cameraActive,
    screenVideo: hasScreenShare,
  } = useParticipant({ userId: user.id });
  const hasEventManagerAccess = useSelector(selectIsEventManager);
  const isUserMoving = useSelector(selectIsUserMoving);
  const { track } = useContext(TRACKING_CONTEXT);
  const eventId = useSelector(selectCurrentEventId);
  const loginUser = useSelector(selectUser);
  const { findUserInEvent } = useParticipantLocation();
  const { locateParticipant } = useLocateParticipant(user);
  const floorId = findUserInEvent(user.id);
  const isSelf = user.id === loginUser?.id;
  const { t } = useI18n(["common", "micCamCheck", "participants"]);
  const isChatEnabled = useSelector(selectIsChatEnabled);
  const dispatch = useAppDispatch();

  const {
    setCameraPermissions,
    setMicrophonePermissions,
    promptActivateMicrophone,
    stopScreenShare,
  } = usePermissions();

  const handleOpenProfileCard = useCallback(() => {
    dispatch(
      openUserProfileCard({ userId: user.id, anchorEl: anchorElementRef }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const handleMute = useCallback(() => {
    setMicrophonePermissions(user.id, false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const handleUnmute = useCallback(() => {
    promptActivateMicrophone(user.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const handleDisableVideo = useCallback(() => {
    setCameraPermissions(user.id, false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const handleDisableScreenShare = useCallback(() => {
    stopScreenShare(user.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const openUserForceMoveDialog = () => {
    track(Events.MOVE_USER_INTENT, { eventId, userId: user.id });
    dispatch(setShowUserForceMoveDialog(user));
    closePopper();
  };
  const theaterId = useSelector(selectCurrentTheaterId);

  const { removeUserFromFloor } = useRemoveUserFromFloor();
  const { updateEventMemberAccess } = useAttendeeActions();

  const handleRemoveUserFromEvent = useCallback(async () => {
    closePopper();

    if (eventId && theaterId && user && floorId) {
      dispatch(
        addDialogNotification({
          message: t("confirm.remove"),
          confirmText: t("remove.user"),
          dismissText: t("block.guest"),
          dismissTestId: "dismiss-button",
          onConfirm: () => {
            removeUserFromFloor({ eventId, floorId, userId: user.id });
          },
          onDismiss: async () => {
            const { isSuccess } = await updateEventMemberAccess(
              eventId,
              theaterId,
              user.id,
              true,
            );

            if (isSuccess) {
              removeUserFromFloor({ eventId, floorId, userId: user.id });
            }
          },
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventId, theaterId, floorId]);

  return (
    <Popper
      open={isOpen}
      style={{ zIndex: 1501 }}
      placement="bottom-end"
      disablePortal
      anchorEl={anchorElementRef.current}
      className={styles.popper}
    >
      <div className={styles.container}>
        <div className={styles.arrow} />
        <Typography
          className={styles.actionItem}
          data-testid="view-profile"
          onClick={handleOpenProfileCard}
        >
          <ProfileCardIcon />
          {t("view.profile")}
        </Typography>
        {!isSelf && !isBroadcasting && (
          <GetUser
            user={user}
            className={styles.actionItem}
            onGetUser={closePopper}
          />
        )}
        {isChatEnabled && (
          <Typography
            className={styles.actionItem}
            data-testid="send-message"
            onClick={sendMessage}
          >
            <ChatIcon width="20" height="20" />
            {t("message")}
          </Typography>
        )}
        {!isBroadcasting && (
          <Typography
            className={styles.actionItem}
            data-testid="locate"
            onClick={locateParticipant}
          >
            <LocateIcon />
            {t("participants:more.options.locate")}
          </Typography>
        )}
        {isBroadcasting &&
          user.canToggleBroadcastRight &&
          hasEventManagerAccess &&
          !user.manager && (
            <Typography
              className={styles.actionItem}
              data-testid="sw-stage"
              onClick={toggleBroadcast}
            >
              <PresentModeIcon />
              {user.isAllowBroadcast
                ? t("micCamCheck:remove.stage")
                : t("micCamCheck:add.stage")}
            </Typography>
          )}
        {isBroadcasting && hasScreenShare && hasEventManagerAccess && (
          <Typography
            className={styles.actionItem}
            data-testid="stop-ss"
            onClick={handleDisableScreenShare}
          >
            <StopScreenShare />
            {t("micCamCheck:stop.screen.share")}
          </Typography>
        )}
        {isBroadcasting && hasEventManagerAccess && (
          <Typography
            className={styles.actionItem}
            data-testid="remove-participant"
            onClick={handleRemoveUserFromEvent}
          >
            <RemoveCircle />
            {t("remove.guest", {
              key: getUserName(user),
            })}
          </Typography>
        )}
        {isBroadcasting && cameraActive && hasEventManagerAccess && (
          <Typography
            className={styles.actionItem}
            data-testid="stop-video"
            onClick={handleDisableVideo}
          >
            <VideocamOff />
            {t("micCamCheck:stop.video")}
          </Typography>
        )}
        {isBroadcasting && microphoneActive && hasEventManagerAccess && (
          <Typography
            className={styles.actionItem}
            data-testid="stop-audio"
            onClick={handleMute}
          >
            <MicOff />
            {t("micCamCheck:mute")}
          </Typography>
        )}
        {isBroadcasting &&
          user.isAllowBroadcast &&
          hasEventManagerAccess &&
          !microphoneActive &&
          cameraActive && (
            <Typography className={styles.actionItem} onClick={handleUnmute}>
              <Mic />
              {t("micCamCheck:ask.mute")}
            </Typography>
          )}
        {!isBroadcasting && hasEventManagerAccess && !isUserMoving && (
          <Typography
            className={styles.actionItem}
            onClick={openUserForceMoveDialog}
            data-testid="move-user-button"
          >
            <MoveParticipant />
            {t("conversation:move.user.button", {
              name: getUserName(user),
            })}
          </Typography>
        )}
      </div>
    </Popper>
  );
};

export default UserDropdownActions;
