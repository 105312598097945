import React from "react";
import { MANAGE_EVENT_CONTEXT } from "modules/manageEvent";
import logger from "logging/logger";
import moment from "moment";
import { useSelector } from "react-redux";
import { selectCurrentEvent } from "modules/event/selectors";
import { IEvent } from "modules/event/types";
import { subscribeToEventAgenda, unsubscribeToEventAgenda } from "../firestore";
import useEventAgendaReducer from "./useEventAgendaReducer";
import { IEventAgenda } from "../types";
import { IEventAgendaState } from "./types";

interface IEventAgendaContextProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  actions: any;
  state: IEventAgendaState | null;
  isLoading: boolean;
  inEventSettings: boolean;
  eventData: IEvent | null;
  children?: React.ReactNode;
}

export const EVENT_AGENDA_CONTEXT =
  React.createContext<IEventAgendaContextProps>({
    actions: null,
    state: null,
    isLoading: true,
    inEventSettings: false,
    eventData: null,
  });

interface IEventAgendaContextProviderProps {
  isInEventSettings: boolean;
  children?: React.ReactNode;
}

const EventAgendaContextProvider = ({
  isInEventSettings,
  children,
}: IEventAgendaContextProviderProps) => {
  const [state, actions] = useEventAgendaReducer();
  const { state: eventState } = React.useContext(MANAGE_EVENT_CONTEXT);
  const event = useSelector(selectCurrentEvent);
  const eventData = eventState ? eventState.eventData : event;
  const [isLoading, setIsLoading] = React.useState(false);

  const getDateString = (date: moment.Moment) => {
    if (!eventData?.startTime) {
      return "";
    }

    return `day-${date.diff(
      moment(eventData.startTime).set({
        hour: 0,
        minute: 0,
        second: 0,
        millisecond: 0,
      }),
      "days",
    )}`;
  };

  React.useEffect(() => {
    setIsLoading(true);
  }, [state.currentDate]);

  // triggers agendaIems to be loaded
  React.useEffect(() => {
    if (eventData && actions) {
      const newStartDate = moment(eventData.startTime);

      actions.setCurrentDate(getDateString(newStartDate));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actions, eventData]);

  React.useEffect(() => {
    // Do it only for created events
    if (eventData && eventData.id && state.currentDate) {
      // unsubscribe to prev date
      unsubscribeToEventAgenda();
      // subscribe to new date
      subscribeToEventAgenda(
        eventData.id,
        state.currentDate,
        (key?: string, data?: IEventAgenda, eventType?: string) => {
          if (key && data) {
            logger.info(
              `[EventAgendaContext] key: ${key} eventType: ${eventType}`,
              { data },
            );
            setIsLoading(false);

            switch (eventType) {
              case "added":
              case "modified":
                actions.upsertAgendaItem(key, data);
                break;
              case "removed":
                actions.removeAgendaItem(key);
                break;
              default:
                break;
            }
          } else {
            setIsLoading(false);
          }
        },
      );
    }

    return () => {
      unsubscribeToEventAgenda();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventData, state.currentDate]);

  return (
    <EVENT_AGENDA_CONTEXT.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        state,
        actions,
        isLoading,
        inEventSettings: isInEventSettings,
        eventData: eventData ?? null,
      }}
    >
      {children}
    </EVENT_AGENDA_CONTEXT.Provider>
  );
};

export default EventAgendaContextProvider;
