import { Theme } from "@remo-co/ui-core/src/types";
import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";

export const useStyles = makeStyles<Theme>((theme) => ({
  recordingItem: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderRadius: 20,
    padding: theme.spacing(2),
    "&:nth-child(odd)": {
      background: "#e8f3fc",
    },
  },
}));
