import React from "react";
import { SerializedDevice } from "modules/deviceInfo";
import { useI18n } from "i18n";
import { Actions, trackAction } from "modules/monitoring";
import { DeviceSelector } from "modules/conversation/components";
import { useDevices } from "modules/audioVideo/hooks";

interface Props {
  dark?: boolean;
}
const MicrophoneSelector = ({ dark }: Props): JSX.Element => {
  const { microphones, activeDevices, setMicrophone } = useDevices();

  const selectedMicrophone = activeDevices.audioInput;

  const handleMicrophoneChange = React.useCallback(
    (device: SerializedDevice) => {
      setMicrophone(device);

      trackAction(Actions.CHANGE_MICROPHONE, {
        newMic: device,
        currentMic: selectedMicrophone,
        availableMics: microphones,
      });
    },
    [setMicrophone, selectedMicrophone, microphones],
  );

  const { t } = useI18n(["conversation"]);

  return (
    <DeviceSelector
      devices={microphones}
      title={t("conversation:select.device.microphone")}
      onSelect={handleMicrophoneChange}
      selectedDevice={selectedMicrophone}
      dark={dark ?? false}
    />
  );
};

export default MicrophoneSelector;
