import { sendPostRequest } from "services/apiService";
import { TableData, SettingUser } from "../../types";

export type UpdateTableSettingsPayload = {
  eventId: string;
  tableData: TableData;
  assignedAttendees: SettingUser[];
  presetId?: string;
};

interface UpdateTableSettingsResponse {
  status: boolean;
  reason?: string;
  message?: string;
}

export const request = async ({
  eventId,
  tableData,
  assignedAttendees,
  presetId,
}: UpdateTableSettingsPayload) => {
  const resp = await sendPostRequest<
    Omit<UpdateTableSettingsPayload, "eventId">,
    UpdateTableSettingsResponse
  >(
    `/event/${eventId}/table-settings${
      presetId ? `?presetId=${presetId}` : ""
    }`,
    {
      tableData,
      assignedAttendees,
    },
  );

  if (!resp.status) {
    throw new Error(resp?.reason);
  }

  return resp;
};
