import { MouseEvent, useCallback } from "react";
import { Button } from "@remo-co/ui-core/src/components/Button";
import useNotificationActions from "modules/notification/hooks/useNotificationActions";
import { useAppMessage } from "@daily-co/daily-react";
import { useSelector } from "react-redux";
import { selectIsInBroadcast } from "modules/broadcast/redux/selectors";
import { useI18n } from "i18n";
import {
  CustomAppEvent,
  CustomAppEvents,
} from "modules/audioVideo/DailyEvents";

interface IMuteGuestButtonProps {
  isMuted: boolean;
  streamId?: string;
  className?: string;
}

const MuteGuestButton = ({
  isMuted,
  streamId,
  className,
}: IMuteGuestButtonProps) => {
  const { t } = useI18n(["common", "micCamCheck"]);
  const isInBroadcast = useSelector(selectIsInBroadcast);
  const { addSuccessNotification } = useNotificationActions();
  const sendMessage = useAppMessage<CustomAppEvent>();

  const handleClick = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();

      if (!streamId) {
        return;
      }

      if (isMuted) {
        sendMessage(
          {
            event: CustomAppEvents.MUTE_USER,
            setAudio: true,
          },
          streamId,
        );
        const messageInConversation = t("common:unmute.guest");
        const messageInBroadcast = t("common:unmute.speaker");

        addSuccessNotification({
          message: isInBroadcast ? messageInBroadcast : messageInConversation,
        });
      } else {
        sendMessage(
          {
            event: CustomAppEvents.MUTE_USER,
            setAudio: false,
          },
          streamId,
        );
      }
    },
    [addSuccessNotification, isInBroadcast, isMuted, sendMessage, streamId, t],
  );

  return (
    <Button
      className={className}
      data-testid="muteguestbutton"
      onClick={handleClick}
      variant="primary"
      color={isMuted ? "green" : "red"}
      size="sm"
    >
      {isMuted ? t("micCamCheck:unmute") : t("micCamCheck:mute")}
    </Button>
  );
};

export default MuteGuestButton;
