import { Analytics, AnalyticsBrowser, ID } from "@segment/analytics-next";
import { useEffect, useRef, useState } from "react";
import { getVar } from "config";
import { analyticsReturnType, traitType, ContextType } from "./types";

interface OfflineEvents {
  name: Function;
  args: (string | traitType | ContextType | undefined)[];
}

const writeKey = getVar("REACT_APP_SEGMENT_WRITE_KEY");

export const useSegment = () => {
  const offlineEvents = useRef<OfflineEvents[]>([]);
  const analyticsRef = useRef<Analytics | undefined>(undefined);
  const [fireStackedEvents, setFireStackedEvents] = useState(false);

  const loadAnalytics = async () => {
    const [response] = await AnalyticsBrowser.load({ writeKey });
    analyticsRef.current = response;
    setFireStackedEvents(true);
  };

  useEffect(() => {
    if (!fireStackedEvents && writeKey) {
      loadAnalytics();
    }

    if (fireStackedEvents && offlineEvents.current.length) {
      offlineEvents.current.forEach((eve) => {
        eve.name?.(...eve.args);
      });
      offlineEvents.current = [];
    }
  }, [fireStackedEvents]);

  const identify = (
    userEmail: string,
    traits: traitType,
  ): analyticsReturnType => {
    if (analyticsRef.current?.identify) {
      return analyticsRef.current.identify(userEmail, traits);
    }

    offlineEvents.current.push({ name: identify, args: [userEmail, traits] });

    return null;
  };

  const setAnonymousId = (id: string): ID => {
    if (analyticsRef.current?.setAnonymousId) {
      return analyticsRef.current.setAnonymousId(id);
    }

    offlineEvents.current.push({ name: setAnonymousId, args: [id] });

    return null;
  };

  const page = (pageName: string, data?: traitType): analyticsReturnType => {
    if (analyticsRef.current?.page) {
      return analyticsRef.current.page(pageName, data);
    }

    offlineEvents.current.push({ name: page, args: [pageName, data] });

    return null;
  };

  const track = (
    eventName: string,
    data?: traitType,
    context?: ContextType,
  ): analyticsReturnType => {
    if (analyticsRef.current?.track) {
      return analyticsRef.current.track(eventName, data, { context });
    }

    offlineEvents.current.push({
      name: track,
      args: [eventName, data, context],
    });

    return null;
  };

  const group = (groupId: string, traits?: traitType): analyticsReturnType => {
    if (analyticsRef.current?.group) {
      return analyticsRef.current.group(groupId, traits);
    }

    offlineEvents.current.push({ name: group, args: [groupId, traits] });

    return null;
  };

  const reset = () => {
    if (analyticsRef.current?.reset) {
      return analyticsRef.current.reset();
    }

    offlineEvents.current.push({ name: reset, args: [] });

    return null;
  };

  return {
    group,
    identify,
    page,
    setAnonymousId,
    track,
    reset,
  };
};
