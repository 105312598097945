import { IMessageTypeProps } from "../Message";
import MessageTime from "./MessageTime";

const ActionMessage = ({ message }: IMessageTypeProps) => {
  const { text } = message;

  return (
    <div>
      <b>{text}</b>
      <MessageTime time={message.time} />
    </div>
  );
};

export default ActionMessage;
