import { useEffect } from "react";
import { datadogRum } from "@datadog/browser-rum";
import { useSelector } from "react-redux";
import { selectCompany } from "modules/company/redux/selectors";

export const TrackCompanyPlan = () => {
  const { companyPlan } = useSelector(selectCompany);

  useEffect(() => {
    if (companyPlan) {
      datadogRum.setGlobalContextProperty("companyPlan", companyPlan);
    }
  }, [companyPlan]);

  return null;
};
