import {
  sendGetRequest,
  sendPostRequest,
  sendPutRequest,
} from "services/apiService";
import { CompanyRole } from "./constants";
import {
  CompanyFeatureToggle,
  ICompany,
  ICompanyServicePlan,
  ICompanySettings,
  IUserCompany,
  CompanyPublicSettings,
  ITag,
} from "./types";

interface IUserCompaniesResponse {
  isSuccess: boolean;
  companies: IUserCompany[];
}

const isValidCompany = (name: string) =>
  sendGetRequest<IGenericAPIResponse>(`/company/validate-by-name?name=${name}`);

export type ICompanyAPIResponse = APIResponse<{ company: ICompany }>;

type ICompanyInfoAPIResponse = APIResponse<{
  company: ICompany;
  role: CompanyRole;
  plan: ICompanyServicePlan;
  companySettings: ICompanySettings;
  tags: {
    defaultTags: ITag[];
    companyTags: ITag[];
  };
}>;

const getMyCompany = () =>
  sendGetRequest<ICompanyInfoAPIResponse>("/company/me");

const updateMyCompany = (
  data: Partial<ICompany>,
  skipSubscriptionUpdate?: boolean,
): Promise<ICompanyAPIResponse> =>
  sendPutRequest<Partial<ICompany>, ICompanyAPIResponse>(
    `/company/me?skipSubscriptionUpdate=${skipSubscriptionUpdate ?? false}`,
    data,
  );

const getCompanyById = (companyId: string) => {
  if (!companyId) {
    return Promise.resolve({} as ICompanyInfoAPIResponse);
  }

  return sendGetRequest<ICompanyInfoAPIResponse>(`/company/${companyId}`);
};

const getMyCompanies = (): Promise<IUserCompaniesResponse> =>
  sendGetRequest("/user/company");

const createCompany = (data: ICompany) =>
  sendPostRequest<ICompany, ICompanyAPIResponse>("/company", data);

const getCompanyPlan = (companyId: string) =>
  sendGetRequest<APIResponse<{ company: ICompany; plan: ICompanyServicePlan }>>(
    `/company-plan/${companyId}`,
  );

const getCustomDomainCompanyName = (customDomain: string) =>
  sendGetRequest<APIResponse<{ companyName: string }>>(
    `/custom-domain-company-name/${customDomain}`,
  );

const enableWhiteLabel = () =>
  sendGetRequest<ICompanyAPIResponse>("/company/enable-white-label");

const disableWhiteLabel = () =>
  sendGetRequest<ICompanyAPIResponse>("/company/disable-white-label");

const getCompanyPublicSettings = (companyId: string) =>
  sendGetRequest<APIResponse<{ companySettings: CompanyPublicSettings }>>(
    `/company/${companyId}/public/settings`,
  );

const updateDisabledFeatureToggles = (
  companyId: string,
  disabledFeatures: CompanyFeatureToggle[],
) =>
  sendPutRequest<
    { disabledFeatures: CompanyFeatureToggle[] },
    APIResponse<{ companySettings: ICompanySettings }>
  >(`/company/${companyId}/settings/feature-toggles`, {
    disabledFeatures,
  });

export default {
  isValidCompany,
  getMyCompany,
  getMyCompanies,
  getCompanyById,
  createCompany,
  updateMyCompany,
  getCompanyPlan,
  enableWhiteLabel,
  disableWhiteLabel,
  getCompanyPublicSettings,
  updateDisabledFeatureToggles,
  getCustomDomainCompanyName,
};
