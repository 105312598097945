import { ChangeEvent, useState } from "react";
import classNames from "classnames";
import validator from "validator";
import { Button } from "@remo-co/ui-core/src/components/Button";
import { Box } from "@remo-co/ui-core/src/components/Box";
import { Container } from "@remo-co/ui-core/src/components/Container";
import { Input } from "@remo-co/ui-core/src/components/Input";
import { InputLabel } from "@remo-co/ui-core/src/components/InputLabel";
import { Typography } from "@remo-co/ui-core/src/components/Typography";
import { Select } from "@remo-co/ui-core/src/components/Select";
import { Link } from "@remo-co/ui-core/src/components/Link";
import { Close as CloseIcon } from "@remo-co/ui-core/src/icons/Close";
import { useI18n } from "i18n";
import { CONTENT_TYPES } from "modules/eventEmbedContent/constants";
import useStyles from "./styles";

interface Props {
  onChange: (
    url: string,
    contentType: string,
    confirmDisabled: boolean,
  ) => void;
  onClose: () => void;
}

const LaunchEmbedContentDialogContent = ({ onChange, onClose }: Props) => {
  const [previewURL, setPreviewURL] = useState("");
  const [contentType, setContentType] = useState<string>("");
  const [touched, setTouched] = useState(false);
  const { t } = useI18n(["event", "url"]);
  const styles = useStyles();

  const isValidURL = (url: string) => {
    const urlValidatorOptions = {
      protocols: ["http", "https"],
      require_protocol: true,
    };

    return validator.isURL(url, urlValidatorOptions);
  };

  const handleURLChange = (evt: ChangeEvent<HTMLInputElement>) => {
    const incomingURL = evt.target.value;
    setTouched(true);
    setPreviewURL(incomingURL);
    onChange(
      incomingURL,
      contentType,
      !isValidURL(incomingURL) || !contentType,
    );
  };

  const handleContentTypeChange = (incomingContentType: string) => {
    setContentType(incomingContentType);
    onChange(
      previewURL,
      incomingContentType,
      !isValidURL(previewURL) || !incomingContentType,
    );
  };

  return (
    <Container className={styles.container}>
      <Box
        display="flex"
        alignItems="flex-end"
        justifyContent="space-between"
        className={styles.header}
      >
        <Typography variant="h3" className={styles.title}>
          {t("event:embed.content.title")}
        </Typography>
        <Button
          onClick={onClose}
          className="close-btn"
          data-testid="close-preview"
          isIconButton
          color="dark"
          variant="ghost"
          size="sm"
        >
          <CloseIcon />
        </Button>
      </Box>
      <Box
        display="flex"
        alignItems="flex-start"
        justifyContent="space-between"
        flexDirection="column"
        className={styles.content}
      >
        <Typography variant="body1" className={styles.directions}>
          {t("event:embed.content.directions")}
        </Typography>
        <Link
          href={t("url:embed.content")}
          openInNewTab
          className={styles.learnLink}
        >
          {t("event:learn.this.feature")}
        </Link>
        <Container className={styles.form}>
          <InputLabel htmlFor="content-type" className={styles.label}>
            {t("event:content.type")}
          </InputLabel>
          <Select
            size="small"
            fullWidth
            name="content-type"
            onChange={handleContentTypeChange}
            options={CONTENT_TYPES.map(({ label, value }) => ({
              value,
              label: t(label),
            }))}
            placeholder={t("event:choose.your.content.type")}
            multiple={false}
            value={contentType}
            // eslint-disable-next-line i18next/no-literal-string
            inputTestId="content-type-select"
          />
          <InputLabel
            htmlFor="url"
            className={classNames(styles.label, styles.middleLabel)}
          >
            {t("event:embed.content.url")}
          </InputLabel>
          <Input
            id="url"
            fullWidth
            size="sm"
            placeholder={t("event:embed.content.url.placeholder")}
            onChange={handleURLChange}
            error={
              touched && !isValidURL(previewURL)
                ? `${t("event:embed.content.invalid.url")}`
                : false
            }
          />
          <InputLabel className={classNames(styles.label, styles.middleLabel)}>
            {t("event:preview")}
          </InputLabel>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
            className={styles.preview}
          >
            {isValidURL(previewURL) ? (
              <iframe
                src={previewURL}
                frameBorder={0}
                title="URL preview"
                className={styles.previewIframe}
              />
            ) : (
              <Typography variant="subtitle1">
                {t("event:embed.content.preview")}
              </Typography>
            )}
          </Box>
        </Container>
      </Box>
    </Container>
  );
};

export default LaunchEmbedContentDialogContent;
