import logger from "logging/logger";
import { deleteObject, ref, UploadTask } from "firebase/storage";
import { storage } from "services/firebaseService/firebaseConfig";
import { upload } from "services/firebaseService/files";
import * as FirebaseStoragePaths from "services/firebaseService/storagePaths";

interface IUploadResults {
  success: boolean;
  error?: string;
  filePath?: string;
  childPath?: string;
}

/**
 *
 * @param floorPlanSVG string
 * @param folderPath string
 * @param fileName string
 * @returns results object containing { success: boolean, error?: string, filePath?: string }
 *
 * The returned `filePath` (if the upload succeeds) can be passed to `getFileDownloadURL` from `useFireStorage` to get download URL
 */
export const uploadFloorPlan = async (
  floorPlanSVG: string,
  folderPath: string,
  fileName: string,
): Promise<IUploadResults> => {
  const alteredFile = new File([floorPlanSVG], fileName, {
    type: "image/svg+xml",
  });

  const formData = new FormData();

  formData.set("file", alteredFile);

  return new Promise((resolve) => {
    const uploadTask: UploadTask | null = upload(
      formData,
      {},
      folderPath,
      FirebaseStoragePaths.UPLOAD_LEVEL.LAYOUT,
      (error, response) => {
        if (error && error.message) {
          logger.error("[uploadFloorPlan] an error occurred.", { error });

          resolve({
            success: false,
            error: `[uploadFloorPlan] an error occurred. ${error}`,
          });
        }

        if (response && response.status === "success") {
          const filePath = uploadTask?.snapshot?.ref?.fullPath;

          resolve({
            success: true,
            filePath,
            childPath: filePath && filePath.replace("Layouts/", ""),
          });
        }
      },
    );
  });
};

/**
 * @param refPath string
 * @returns boolean denoting the status of removal
 *
 * Do note that we do not use this for deleting MapTemplates for the user!
 * We're only using this to ensure we don't have any stray floor plan SVGs
 * on storage if the API to save them to Firestore as a doc failed
 */
export const rollbackFloorPlanUpload = async (refPath: string) => {
  try {
    const floorPlanRef = ref(storage, `Layouts/${refPath}`);

    await deleteObject(floorPlanRef);

    return true;
  } catch (error) {
    logger.error("[uploadFloorPlan] an error occurred.", { error });
  }

  return false;
};

/**
 *
 * @param dom SVG Document object
 * @returns altered Document object, for saving to file
 *
 * if using JSDOM to test, pass `[JSDOM object].window.document`
 */
export const amendFloorPlanSVG = (dom: Document) => {
  try {
    // we may need to add other ways to check for invalid documents,
    // based on the parser we'll generally use.
    // this expects DOMParser (which fails for the majority of our floor plans...)
    if (dom.documentElement.tagName !== "svg") {
      // root element is not svg. documentElement.textContent is guaranteed to be an error message of sorts
      throw new Error(
        `Parse error found in document: ${dom.documentElement.textContent}`,
      );
    }

    const invalidElements = dom.querySelectorAll('[data-name^="<"]');

    if (invalidElements && invalidElements.length) {
      invalidElements.forEach((el) => {
        el.removeAttribute("data-name");
      });
    }
    const tables = dom.querySelectorAll("[id^=table-]");

    tables.forEach((table: Element) => {
      const nameEl = table.querySelector("[id^=name]");

      if (nameEl) {
        // the Element typing for table above is accurate.
        // weird, I know, that SVGElement is expected to have the style attribute
        // while Element does not which makes us resort to this awkward cast.
        // see https://github.com/microsoft/TypeScript/issues/3263
        (nameEl as SVGElement).style.opacity = "0";
      }
    });
    const svgEl = dom.querySelector("svg");

    if (svgEl) {
      // If width and height attr missing in svg tag, take it from viewbox
      const viewBox =
        svgEl.getAttribute("viewBox") || svgEl.getAttribute("viewbox");
      const parts = viewBox?.split(" ");

      if (parts) {
        svgEl.setAttribute("width", `${parts[2]}px`);
        svgEl.setAttribute("height", `${parts[3]}px`);
      }
    }

    return dom;
  } catch (error) {
    logger.error(
      "[amendFloorPlanSVG] an error occurred during SVG DOM manipulation",
      { error },
    );

    return null;
  }
};

/**
 * Promise-wrapped FileReader for easier use with async/await. Do leave a note if we have file helpers that this belongs in
 *  @param inputFile to extract contents from
 * @returns UTF-8 encoded string contents of inputFile.
 */
export const getUploadedFloorPlanContents = (
  inputFile: File,
): Promise<string> => {
  try {
    const temporaryFileReader = new FileReader();

    return new Promise((resolve, reject) => {
      temporaryFileReader.onerror = () => {
        temporaryFileReader.abort();
        logger.error(
          "[getUploadedFloorPlanContents] Problem parsing input file.",
          { file: inputFile },
        );
        reject(new DOMException("Problem parsing input file."));
      };

      temporaryFileReader.onload = () => {
        resolve(temporaryFileReader.result as string);
      };
      temporaryFileReader.readAsText(inputFile, "utf-8");
    });
  } catch (error) {
    logger.error(
      "[getUploadedFloorPlanContents] Problem during input file content extraction.",
      { file: inputFile },
    );
    throw new Error("Problem during input file content extraction");
  }
};

export const stringToDocument = (toParse: string) => {
  const parser = new DOMParser();

  return parser.parseFromString(toParse, "image/svg+xml");
};

export const documentToString = (toStringify: Document) =>
  toStringify.documentElement.outerHTML;

export default {
  uploadFloorPlan,
  rollbackFloorPlanUpload,
  amendFloorPlanSVG,
  getUploadedFloorPlanContents,
  stringToDocument,
  documentToString,
};
