import { gql } from "@apollo/client";

export const GET_POLLS = gql`
  query GetEventPolls($eventId: ObjectID!, $includeUserAnswers: Boolean) {
    eventPolls(eventId: $eventId, includeUserAnswers: $includeUserAnswers) {
      ... on EventPolls {
        __typename
        polls {
          correctAnswers
          createdBy
          createdAt
          eventId
          id
          options {
            id
            option
            votes
          }
          selectedAnswer
          userAnswers {
            pollId
            userId
            optionId
          }
          status
          timer
          title
          updatedAt
          type
          resultDisplayMode
        }
      }
      ... on GQLError {
        __typename
        message
      }
    }
  }
`;
