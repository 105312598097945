import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface TableLinkState {
  tableId?: string;
  floorId?: string;
}

const initialState: TableLinkState = {
  tableId: undefined,
  floorId: undefined,
};

const directTableLinkSlice = createSlice({
  name: "directTableLink",
  initialState,
  reducers: {
    updateTableLink: (
      state,
      action: PayloadAction<{ tableId: string; floorId: string }>,
    ) => {
      state.floorId = action.payload.floorId;
      state.tableId = action.payload.tableId;
    },
    reset: () => initialState,
  },
});

export const { updateTableLink, reset } = directTableLinkSlice.actions;

export default directTableLinkSlice.reducer;
