import { useEffect } from "react";
import { useQuery } from "@apollo/client";
import {
  EventEmbedContentMode,
  GetEmbedContentQuery,
  GetEmbedContentQueryVariables,
  OnEmbedContentCreatedSubscription,
  OnEmbedContentCreatedSubscriptionVariables,
  OnEventEmbedContentDeletedSubscription,
  OnEventEmbedContentDeletedSubscriptionVariables,
} from "graphql/generated";
import { useAppDispatch } from "store/hooks";
import { Errors, trackError } from "modules/monitoring";
import {
  GET_EMBED_CONTENT,
  ON_EMBED_CONTENT_CREATED,
  ON_EVENT_EMBED_CONTENT_DELETED,
} from "../../graphql";
import { updatePresentationModeContent } from "../../redux/slice";
import {
  updateEmbedContentQuery,
  deleteEventEmbedContentQuery,
} from "../../utils";

const useEmbedContentSubscriptionPresentation = (eventId: string) => {
  const dispatch = useAppDispatch();

  const { subscribeToMore, loading, data, error } = useQuery<
    GetEmbedContentQuery,
    GetEmbedContentQueryVariables
  >(GET_EMBED_CONTENT, {
    variables: {
      eventId,
    },
  });

  if (error) {
    trackError(error, { label: Errors.EMBED_CONTENT_QUERY });
  }

  useEffect(() => {
    const unsubscribe = subscribeToMore<
      OnEmbedContentCreatedSubscription,
      OnEmbedContentCreatedSubscriptionVariables
    >({
      document: ON_EMBED_CONTENT_CREATED,
      variables: { eventId },
      onError: (error) => {
        trackError(error, { label: Errors.EMBED_CONTENT_SUBSCRIPTION });
      },
      updateQuery: updateEmbedContentQuery,
    });

    return () => {
      unsubscribe();
    };
  }, [eventId, subscribeToMore]);

  useEffect(() => {
    const unsubscribe = subscribeToMore<
      OnEventEmbedContentDeletedSubscription,
      OnEventEmbedContentDeletedSubscriptionVariables
    >({
      document: ON_EVENT_EMBED_CONTENT_DELETED,
      variables: { eventId },
      onError: (error) => {
        trackError(error, { label: Errors.EMBED_CONTENT_SUBSCRIPTION });
      },
      updateQuery: deleteEventEmbedContentQuery,
    });

    return () => {
      unsubscribe();
    };
  }, [eventId, subscribeToMore]);

  useEffect(() => {
    if (
      !loading &&
      !error &&
      data &&
      data.embedContent.__typename === "EventEmbedContentList"
    ) {
      const presentationContent = data.embedContent.content.filter(
        (content) => content.mode === EventEmbedContentMode.Presentation,
      );

      dispatch(
        updatePresentationModeContent(
          presentationContent.length > 0 ? presentationContent[0] : null,
        ),
      );
    }
  }, [dispatch, data, loading, error]);

  return {
    data,
    loading,
    error,
  };
};

export default useEmbedContentSubscriptionPresentation;
