import { PermissionState } from "modules/deviceInfo";

export const getButtonTitle = (
  device: string,
  permissionState: PermissionState,
  isDeviceOn: boolean,
  isInBroadcast?: boolean,
): string => {
  if (permissionState === PermissionState.BLOCKED) {
    return `micCamCheck:${device}.needs.permission`;
  }

  if (
    permissionState === PermissionState.NOT_FOUND ||
    permissionState === PermissionState.PENDING
  ) {
    return `micCamCheck:${device}.not.found`;
  }

  if (!isInBroadcast && !isDeviceOn && device === "mic") {
    return `turn.on.${device}.conversation.mode`;
  }

  if (isDeviceOn) {
    return `turn.off.${device}`;
  }

  return `turn.on.${device}`;
};
