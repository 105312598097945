import { Theme } from "@remo-co/ui-core/src/types";
import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";

const useStyles = makeStyles<Theme>((theme) => ({
  container: {
    height: "100%",
    overflow: "hidden",
    overflowY: "scroll",
    display: "flex",
    flexDirection: "column",
    gap: "5px",
    color: theme.palette.text.primary,
    "& .MuiInput-underline": {
      "&:before,&:after": {
        content: "none",
      },
    },
    "& .MuiListItemText-root, & .MuiTypography-body2": {
      fontSize: "16px !important",
      fontWeight: "400 !important",
    },
    "& .MuiButtonBase-root": {
      color: "#515268",
      position: "relative",
      "& .Mui-disabled": {
        color: "rgba(81, 82, 104, 0.4)",
      },
    },
  },
  text: {
    display: "flex",
    justifyContent: "center",
    fontSize: "16px",
  },
  tipContainer: {
    display: "flex",
    alignItems: "center",
    width: "380px",
    height: "30px",
    padding: " 5px 10px 5px 10px",
    gap: "10px",
    borderRadius: "5px 0px 0px 0px",
    background: theme.palette.yellow.lightShade1,
    boxShadow: "2px 4px 20px 0px #0000001A",
    marginLeft: "10px",
  },
}));

export default useStyles;
