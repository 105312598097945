import { useMutation } from "@tanstack/react-query";
import { Events, TRACKING_CONTEXT } from "modules/tracking";
import { useContext } from "react";
import { makeSelectUserById } from "modules/event/usersInEvent/selectors";
import { selectUser } from "modules/auth/redux/selectors";
import { useAppDispatch } from "store/hooks";
import { useSelector } from "react-redux";
import { selectCurrentEventId } from "modules/event/selectors";
import { Errors, trackError } from "modules/monitoring";
import { addErrorNotification } from "modules/notification/redux/notificationSlice";
import { useI18n } from "i18n";
import { useSendMessage } from "modules/chat";
import {
  UpdateRecommendedUserConnectionPayload,
  updateRecommendedUserConnection,
} from "../../request";

export const useUpdateRecommendedUserConnections = (userId?: string) => {
  const { track } = useContext(TRACKING_CONTEXT);
  const dispatch = useAppDispatch();
  const currentUser = useSelector(selectUser);
  const { t } = useI18n(["event"]);
  const userDetails = useSelector(makeSelectUserById(userId));
  const currentEventId = useSelector(selectCurrentEventId);
  const { startDirectNetworkingMessage } = useSendMessage();

  const { mutate: updateConnection } = useMutation({
    mutationFn: (payload: UpdateRecommendedUserConnectionPayload) =>
      updateRecommendedUserConnection(payload),
    onSuccess: (data) => {
      if (data?.data) {
        startDirectNetworkingMessage(
          data.data.recommendedUser,
          data.data.recommendedToUser,
          userDetails?.profile?.firstName ?? userDetails?.profile?.name,
        );
        track(Events.RECOMMENDED_CONNECTION_CONTACTED, {
          eventId: currentEventId,
          recommendedUser: userDetails?.email,
          currentUser: currentUser?.email,
        });
      }
    },
    onError: (error: Error, _) => {
      dispatch(
        addErrorNotification({
          message: t("update.recommended.user.error"),
          position: "tc",
        }),
      );
      trackError(error, {
        label: Errors.UPDATE_RECOMMENDED_USER_CONNECTION,
      });
    },
  });
  return { updateConnection };
};
