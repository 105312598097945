import { ComponentProps } from "react";
import { Theme } from "@mui/material/styles";
import { StyleRulesCallback } from "@mui/styles/withStyles";
import { Input } from ".";

export const styles: StyleRulesCallback<Theme, ComponentProps<typeof Input>> = (
  theme: Theme,
) => ({
  container: (props) => ({
    width: props.fullWidth ? "100%" : "auto",
  }),
  root: {
    fontSize: 14,
    borderRadius: 5,
    margin: "5px 0",
    overflow: "hidden",
    borderWidth: 1,
    borderStyle: "solid",
    position: "relative",
  },
  value: {
    borderColor: theme.palette.blue.dark,
  },
  input: {
    padding: "8px 15px",
    "&::placeholder": {
      color: "currentColor",
    },
  },
  inputDisabled: {
    "&::placeholder": {
      color: theme.palette.blue.darkShade1,
      opacity: "100%",
    },
  },
  sm: {
    height: "40px",
  },
  lg: {
    height: "50px",
  },
  error: {
    borderColor: theme.palette.red.main,
    "&:hover": {
      borderColor: theme.palette.red.main,
    },
  },
  label: {
    fontSize: 14,
    color: `${theme.palette.blue.dark} !important`,
    padding: 0,
    margin: 0,
  },
  description: {
    fontSize: "12px !important",
    color: `${theme.palette.blue.darkShade1} !important`,
    padding: "0 !important",
    margin: "0 !important",
  },
  errorDescription: {
    color: `${theme.palette.red.main} !important`,
  },
});
