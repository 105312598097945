import { useCallback, useEffect, useRef, useState } from "react";
import { trackAudioLevel } from "./audioOperations";
import { getAudioContext } from "../../context";

export const useIsTalking = ({
  audioTrack,
  onVolumeChange,
  trackAudio = false,
}: {
  audioTrack: false | MediaStreamTrack | undefined;
  onVolumeChange?: (audioLevel: number) => void;
  trackAudio?: boolean;
}) => {
  const [isTalking, setIsTalking] = useState(false);
  const [audioLevel, setAudioLevel] = useState(0);
  const mediaStream = useRef<MediaStream>(new MediaStream());
  const audioContext = useRef<AudioContext>(getAudioContext());

  const volumeChangeHandler = useCallback(
    (audioLevel: number) => {
      onVolumeChange?.(audioLevel);
      if (trackAudio) {
        setAudioLevel(audioLevel);
      }
    },
    [onVolumeChange, trackAudio],
  );

  useEffect(() => {
    if (!audioTrack) {
      setIsTalking(false);
      return undefined;
    }
    const currentMediaStream = mediaStream.current;

    currentMediaStream.addTrack(audioTrack);

    const onTalkingStarted = () => {
      setIsTalking(true);
    };
    const onTalkingStopped = () => {
      setIsTalking(false);
    };

    const { cleanup } = trackAudioLevel({
      audioContext: audioContext.current,
      mediaStream: currentMediaStream,
      onVolumeChange: volumeChangeHandler,
      onTalkingStarted,
      onTalkingStopped,
    });

    return () => {
      cleanup();
      currentMediaStream.removeTrack(audioTrack);
    };
  }, [audioTrack, volumeChangeHandler]);

  return { isTalking, audioLevel };
};
