import { useContext, useState } from "react";
import { Button } from "@remo-co/ui-core/src/components/Button";
import { Dialog } from "@remo-co/ui-core/src/components/Dialog";
import { DialogActions } from "@remo-co/ui-core/src/components/DialogActions";
import { DialogContent } from "@remo-co/ui-core/src/components/DialogContent";
import { DialogTitle } from "@remo-co/ui-core/src/components/DialogTitle";
// import { FormControlLabel } from "@remo-co/ui-core/src/components/FormControlLabel";
// import { Radio } from "@remo-co/ui-core/src/components/Radio";
// import { RadioGroup } from "@remo-co/ui-core/src/components/RadioGroup";
import { InputAdornment } from "@remo-co/ui-core/src/components/InputAdornment";
import { IconButtonWithTooltip } from "@remo-co/ui-core/src/components/IconButtonWithTooltip";
import { Input } from "@remo-co/ui-core/src/components/Input";
import { Typography } from "@remo-co/ui-core/src/components/Typography";
// import { PickerProvider } from "@remo-co/ui-core/src/components/PickerProvider";
import { Close } from "@remo-co/ui-core/src/icons/Close";
import { useI18n } from "i18n";
import { IEventcubeTicket } from "modules/ticketing/types";
import { IEvent } from "modules/event/types";
// import { DateTimePicker } from "@remo-co/ui-core/src/components/DateTimePicker";
// import moment, { Moment } from "moment";
import useNotificationActions from "modules/notification/hooks/useNotificationActions";
import { queryClient } from "services/apiService/queryClient";
import { Events, TRACKING_CONTEXT } from "modules/tracking";
import { useStyles } from "./styles";
import { TicketPriceType } from "../TicketPriceType";
import { EVENT_TICKETS_QUERY_KEY, TICKET_PRICE } from "../../constants";
import { TicketPriceFees } from "../TicketPriceFees";
import { createEventTicket, updateEventTicket } from "../../apis";

// lots of comments in this file because Eventcube decided they will only do the bare minimum for their API
// so we're stuck getting rid of the sales schedule handling we have here until they tackle it in their roadmap, sigh

// const enum SALE_SCHEDULE_TYPES {
//   SYNCED,
//   MANUAL,
// }

interface Props {
  eventData: IEvent;
  preloadedTicket: IEventcubeTicket | null;
  currency: string;
  onClose: () => void;
}

const defaultTicketState: Partial<IEventcubeTicket> = {
  title: "",
  description: "",
  quantity: 0,
  price: 0,
};

export const TicketFormDialog = ({
  eventData,
  preloadedTicket,
  onClose,
  currency,
}: Props) => {
  const { track } = useContext(TRACKING_CONTEXT);
  // const getAbsTime = (time: Moment) => Math.abs(time.valueOf() / 1000) * 1000;

  const { addErrorNotification, addSuccessNotification } =
    useNotificationActions();
  const [priceType, setPriceType] = useState(
    preloadedTicket && !preloadedTicket.price
      ? TICKET_PRICE.FREE
      : TICKET_PRICE.PAID,
  );
  // const [saleStartTime, setSaleStartTime] = useState(
  //   preloadedTicket && preloadedTicket.sale_schedule
  //     ? getAbsTime(moment(preloadedTicket.sale_schedule.starts_on))
  //     : eventData.startTime,
  // );
  // const [saleEndTime, setSaleEndTime] = useState(
  //   preloadedTicket && preloadedTicket.sale_schedule
  //     ? getAbsTime(moment(preloadedTicket.sale_schedule.ends_on))
  //     : eventData.endTime,
  // );
  // const [saleScheduleSetting, setSaleScheduleSetting] = useState(
  //   (preloadedTicket && !preloadedTicket.sale_schedule) || !preloadedTicket
  //     ? SALE_SCHEDULE_TYPES.SYNCED
  //     : SALE_SCHEDULE_TYPES.MANUAL,
  // );
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useI18n(["eventForm", "common", "event"]);
  const styles = useStyles();

  const [ticketState, setTicketState] = useState<Partial<IEventcubeTicket>>(
    preloadedTicket
      ? {
          title: preloadedTicket.title,
          description: preloadedTicket.description,
          quantity: preloadedTicket.quantity,
          price: preloadedTicket.price,
        }
      : defaultTicketState,
  );

  const handleSubmit = async () => {
    track(Events.TICKETING_SAVE_TICKET_CLCIKED, {
      eventId: eventData.id,
      eventType: eventData.eventType,
      eventOutcome: eventData.eventOutcome,
    });
    setIsLoading(true);

    // const ticketStateWithSaleSchedule = {
    //   ...ticketState,
    //   sale_schedule:
    //     saleScheduleSetting === SALE_SCHEDULE_TYPES.SYNCED
    //       ? null
    //       : {
    //           starts_on: moment(saleStartTime).format("YYYY-MM-DD HH:mm:ss"),
    //           ends_on: moment(saleEndTime).format("YYYY-MM-DD HH:mm:ss"),
    //         },
    // };

    try {
      if (preloadedTicket) {
        await updateEventTicket(eventData.id, preloadedTicket.id, ticketState);
      } else {
        await createEventTicket(eventData.id, ticketState);
      }
    } catch (err) {
      addErrorNotification({
        message: t(
          preloadedTicket
            ? "eventForm:ticket.updated.error"
            : "eventForm:ticket.created.error",
        ),
      });
      setIsLoading(false);
      return;
    }

    queryClient.invalidateQueries({
      queryKey: [EVENT_TICKETS_QUERY_KEY, eventData.id],
    });
    setIsLoading(false);
    addSuccessNotification({
      message: t(
        preloadedTicket
          ? "eventForm:ticket.updated.successfully"
          : "eventForm:ticket.created.successfully",
      ),
    });
    if (preloadedTicket) {
      track(Events.TICKETING_TICKET_EDITED, {
        eventId: eventData.id,
        eventType: eventData.eventType,
        eventOutcome: eventData.eventOutcome,
      });
    } else {
      track(Events.TICKETING_TICKET_CREATED, {
        eventId: eventData.id,
        eventType: eventData.eventType,
        eventOutcome: eventData.eventOutcome,
        name: ticketState.title,
        quantity: ticketState.quantity,
        price: ticketState.price,
        type: priceType,
        description: ticketState.description,
      });
    }
    onClose();
  };

  const changePriceType = async (type: TICKET_PRICE) => {
    if (type === TICKET_PRICE.FREE) {
      setTicketState({
        ...ticketState,
        price: 0,
      });
    }
    setPriceType(type);
  };

  // const changeSaleSchedule = async (e: ChangeEvent<HTMLInputElement>) => {
  //   setSaleScheduleSetting(Number(e.target.value));
  // };

  // const setStartTime = (time: Moment | null) => {
  //   if (!time) return;

  //   const newStartTime = getAbsTime(time);

  //   if (newStartTime > saleEndTime) {
  //     addErrorNotification({
  //       message: t("event:error.start.notify"),
  //     });
  //     return;
  //   }

  //   setSaleStartTime(newStartTime);
  // };

  // const setEndTime = (time: Moment | null) => {
  //   if (!time) return;

  //   const newEndTime = getAbsTime(time);

  //   if (saleStartTime > newEndTime) {
  //     addErrorNotification({
  //       message: t("event:error.start.notify"),
  //     });
  //     return;
  //   }

  //   setSaleEndTime(newEndTime);
  // };

  const hasFormErrors =
    !ticketState.title ||
    ticketState.quantity === 0 ||
    (priceType === TICKET_PRICE.PAID && ticketState.price === 0);

  return (
    <Dialog
      data-testid="popup-dialog"
      onClose={onClose}
      aria-labelledby={t(
        preloadedTicket ? "eventForm:update.ticket" : "eventForm:create.ticket",
      )}
      fullWidth
      open
      classes={{
        paper: styles.root,
      }}
    >
      <DialogTitle className={styles.header}>
        <Typography variant="h3">
          {t(
            preloadedTicket
              ? "eventForm:update.ticket"
              : "eventForm:create.ticket",
          )}
        </Typography>
        <IconButtonWithTooltip
          id="close-popup-dialog"
          title={t("common:button.close")}
          data-testid="icon-close-button"
          onClick={onClose}
          size="small"
          className={styles.closeButton}
        >
          <Close />
        </IconButtonWithTooltip>
      </DialogTitle>
      <DialogContent className={styles.content}>
        <div className={styles.inputRow}>
          <div className={styles.titleInput}>
            <Input
              required
              size="sm"
              fullWidth
              getRemainingCharsMessage={(key) =>
                t("common:character.remaining", { key })
              }
              label={t("eventForm:ticket.name")}
              name="title"
              placeholder={t("eventForm:ticket.name.placeholder")}
              value={ticketState.title}
              onChange={(event) =>
                setTicketState({
                  ...ticketState,
                  title: event.target.value,
                })
              }
              inputProps={{
                maxLength: 50,
                "data-testid": "title-field",
              }}
            />
          </div>
          <div className={styles.quantityInput}>
            <Input
              required
              size="sm"
              fullWidth
              label={t("eventForm:quantity")}
              name="quantity"
              placeholder={t("eventForm:ticket.quantity.placeholder")}
              value={
                ticketState.quantity === 0
                  ? ""
                  : ticketState.quantity?.toString()
              }
              onChange={(event) => {
                if (!Number.isNaN(Number(event.target.value))) {
                  setTicketState({
                    ...ticketState,
                    quantity: Number(event.target.value),
                  });
                }
              }}
              inputProps={{
                "data-testid": "quantity-field",
              }}
            />
          </div>
        </div>
        <TicketPriceType priceType={priceType} handleChange={changePriceType} />
        {priceType === TICKET_PRICE.PAID && (
          <Input
            required
            size="sm"
            fullWidth
            name="price"
            placeholder={t("eventForm:ticket.price.paid.placeholder")}
            value={ticketState.price === 0 ? "" : ticketState.price?.toString()}
            onChange={(event) => {
              if (!Number.isNaN(Number(event.target.value))) {
                setTicketState({
                  ...ticketState,
                  price: Number(event.target.value),
                });
              }
            }}
            inputProps={{
              "data-testid": "price-field",
            }}
            inputStartAdornment={
              <InputAdornment
                className={styles.priceInputAdornment}
                position="start"
              >
                {currency}
              </InputAdornment>
            }
            inputEndAdornment={<TicketPriceFees />}
          />
        )}
        {/* <Typography className={styles.salesScheduleTitle}>
          {t("eventForm:sales.start.end.time")}
        </Typography> */}
        {/* <div className={styles.radioGroup}>
          <RadioGroup value={saleScheduleSetting} onChange={changeSaleSchedule}>
            <FormControlLabel
              value={SALE_SCHEDULE_TYPES.SYNCED}
              control={
                <Radio className={styles.radio} size="small" color="primary" />
              }
              label={t("on.sale.now")}
            />
            <FormControlLabel
              value={SALE_SCHEDULE_TYPES.MANUAL}
              control={
                <Radio className={styles.radio} size="small" color="primary" />
              }
              label={t("eventForm:manual.set.date")}
            />
          </RadioGroup>
        </div> */}
        {/* {saleScheduleSetting === SALE_SCHEDULE_TYPES.MANUAL && (
          <div className={styles.dateTimePickerRow}>
            <PickerProvider>
              <div className={styles.startTime}>
                <Typography>{t("eventForm:sale.starts.at")}</Typography>
                <DateTimePicker
                  required
                  id="event-start-time-field"
                  value={saleStartTime}
                  onChange={setStartTime}
                  className={styles.dateTimePicker}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    className: styles.dateTimePickerInput,
                  }}
                  DialogProps={{
                    id: "event-start-time-dialog",
                  }}
                  inputVariant="outlined"
                  fullWidth
                />
              </div>
              <div className={styles.endTime}>
                <Typography>{t("eventForm:sale.ends.at")}</Typography>
                <DateTimePicker
                  required
                  id="event-end-time-field"
                  value={saleEndTime}
                  onChange={setEndTime}
                  className={styles.dateTimePicker}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    className: styles.dateTimePickerInput,
                  }}
                  DialogProps={{
                    id: "event-end-time-dialog",
                  }}
                  inputVariant="outlined"
                  fullWidth
                />
              </div>
            </PickerProvider>
          </div>
        )} */}
        <div className={styles.description}>
          <Input
            fullWidth
            multiline
            size="multi"
            rows={3}
            getRemainingCharsMessage={(key) =>
              t("common:character.remaining", { key })
            }
            label={t("eventForm:ticket.description")}
            name="description"
            placeholder={t("eventForm:ticket.description.placeholder")}
            value={ticketState.description}
            onChange={(event) =>
              setTicketState({
                ...ticketState,
                description: event.target.value,
              })
            }
            inputProps={{
              maxLength: 200,
              "data-testid": "title-field",
            }}
          />
        </div>
      </DialogContent>
      <DialogActions className={styles.actions}>
        <Button
          variant="secondary"
          color="blue"
          disabled={isLoading}
          onClick={onClose}
          data-testid="close-button"
        >
          {t("common:button.close")}
        </Button>
        <Button
          variant="primary"
          color="blue"
          disabled={hasFormErrors || isLoading}
          loading={isLoading}
          onClick={handleSubmit}
          data-testid="save-button"
        >
          {t("eventForm:save.ticket")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
