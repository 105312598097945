import { Errors, trackError } from "modules/monitoring";
import { useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { useI18n } from "i18n";
import useNotificationActions from "modules/notification/hooks/useNotificationActions";
import { selectUserId } from "modules/auth/redux/selectors";
import { useSelector } from "react-redux";
import { CUSTOM_ANSWERS_QUERY_KEY } from "../../constants";
import { getEventRegistrationAnswers } from "./request";

export const useRegistrationQuestionAnswers = (eventId?: string) => {
  const userId = useSelector(selectUserId);
  const { t } = useI18n(["eventForm"]);
  const { addErrorNotification } = useNotificationActions();

  const {
    data: answers,
    status,
    fetchStatus,
    isLoading,
    error,
  } = useQuery({
    queryKey: [CUSTOM_ANSWERS_QUERY_KEY, eventId, userId],

    queryFn: async () => {
      if (!eventId) return [];
      if (!userId) return [];

      return getEventRegistrationAnswers(eventId);
    },
  });

  useEffect(() => {
    if (!error) return;

    addErrorNotification({
      message: t("eventForm:custom.registration.question.query.error", {
        error: error.message || "unknown",
      }),
      position: "tc",
    });

    trackError(error, {
      label: Errors.CUSTOM_REGISTRATION_ANSWERS_QUERY,
      eventId,
    });
  }, [error, addErrorNotification, t, eventId]);

  return {
    status,
    isLoading,
    fetchStatus,
    answers: answers ?? [],
  };
};
