// eslint-disable-next-line @typescript-eslint/no-explicit-any,  @typescript-eslint/default-param-last
export const checkEventExists = (history: any[] = [], eventName: string) => {
  const events = history.map((x) => x.event);

  if (events.length === 0) {
    return false;
  }

  return events.indexOf(eventName) !== -1;
};
