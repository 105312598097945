import { useState, useRef } from "react";
import { Button } from "@remo-co/ui-core/src/components/Button";
import { Typography } from "@remo-co/ui-core/src/components/Typography";
import { useI18n } from "i18n";
import { parseAndReturnEmails } from "../utils/csvEmailsParser";

interface Props {
  limit: number;
  onImport: (emails: string[]) => void;
}

const ImportFromCSV = ({ limit, onImport }: Props) => {
  const [displayFileUpload, setDisplayFileUpload] = useState(false);
  const [fileSelected, setFileSelected] = useState(false);
  const [error, setError] = useState("");
  const fileRef = useRef<HTMLInputElement>(null);
  const { t } = useI18n(["eventForm", "server"]);

  const readFile = () => {
    if (!fileRef.current || !fileRef.current.files) {
      return;
    }

    const reader = new FileReader();

    reader.onload = () => {
      try {
        const emails = parseAndReturnEmails(String(reader.result));

        if (emails.length > limit) {
          setError(t("eventForm:invite.upload.limit", { limit }));
          return;
        }

        onImport(emails);
        setDisplayFileUpload(false);
        setFileSelected(false);
      } catch (e) {
        setError(t(e instanceof Error ? e.message : "server:unexpected.error"));
      }
    };
    // start reading the file. When it is done, calls the onload event defined above.
    reader.readAsText(fileRef.current.files[0]);
  };

  return (
    <div>
      {displayFileUpload ? (
        <>
          <input
            id="csv"
            type="file"
            accept=".csv,text/csv"
            data-testid="csv-file"
            onChange={() => setFileSelected(true)}
            ref={fileRef}
          />
          <Button
            variant="secondary"
            size="md"
            color="dark"
            disabled={!fileSelected}
            onClick={readFile}
            data-testid="upload-csv"
          >
            {t("eventForm:invite.upload.csv")}
          </Button>
          {error && <Typography data-testid="import-error">{error}</Typography>}
        </>
      ) : (
        <Button
          data-testid="import-from-file"
          variant="secondary"
          size="md"
          color="dark"
          onClick={() => setDisplayFileUpload(true)}
        >
          {t("eventForm:invite.import.csv")}
        </Button>
      )}
    </div>
  );
};

export default ImportFromCSV;
