import { Typography } from "@remo-co/ui-core/src/components/Typography";
import * as DOMPurify from "dompurify";
import { useI18n } from "i18n";
import { useState } from "react";
import { LiveStreamData } from "modules/liveStream/request";
import { LiveStreamForm, LiveStreamItem } from "modules/liveStream/components";
import { useSelector } from "react-redux";

import { useStyles } from "./styles";
import { selectLiveStreams } from "./redux/selectors";
import { AddLiveStream } from "./components/AddLiveStream";
import { StreamFormData } from "./components/LiveStreamForm";

interface Props {
  eventId: string;
}

const initialState = {
  serverUrl: "",
  streamKey: "",
  playbackUrl: "",
  destinationName: "",
};

export const LiveStream = ({ eventId }: Props): JSX.Element => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [streamData, setStreamData] = useState<StreamFormData>(initialState);
  const { t } = useI18n(["manageEvent"]);
  const styles = useStyles();
  const liveStreams = useSelector(selectLiveStreams);

  const closeStreamDialog = () => {
    setIsDialogOpen(false);
    setStreamData(initialState);
  };

  const handleStreamDialog = () => {
    setIsDialogOpen(!isDialogOpen);
  };

  const handleEditForm = (stream: LiveStreamData) => {
    setStreamData(stream);
    setIsDialogOpen(true);
  };

  const showContactSales =
    isDialogOpen && liveStreams.length === 1 && !streamData.id;

  return (
    <>
      <Typography variant="h3" className={styles.heading}>
        {t("manageEvent:live.stream.your.presentation")}
      </Typography>
      <Typography variant="body1">
        {t("manageEvent:stream.description")}
      </Typography>

      <Typography
        variant="body1"
        className={styles.link}
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(t("manageEvent:stream.not.sure"), {
            ALLOWED_ATTR: ["target", "href", "class", "rel"],
          }),
        }}
      />

      <div className="mar-top-40">
        {liveStreams.map((stream) => (
          <LiveStreamItem
            key={stream.id}
            stream={stream}
            eventId={eventId}
            openEditForm={handleEditForm}
          />
        ))}
      </div>
      <AddLiveStream
        showContactSales={showContactSales}
        handleStreamDialog={handleStreamDialog}
        closeStreamDialog={closeStreamDialog}
      />
      {isDialogOpen && (
        <LiveStreamForm
          handleClose={closeStreamDialog}
          eventId={eventId}
          initialFormData={streamData}
        />
      )}
    </>
  );
};
