import { JoinTableErrors } from "./constants";

export const getErrorMessage = (reason: unknown) => {
  switch (reason) {
    case JoinTableErrors.RESERVED_TABLE:
      return "template:host.invite.required";
    case JoinTableErrors.TABLE_PLAN_CAPACITY_EXCEEDED:
    case JoinTableErrors.TABLE_FULL:
      return "server:table.full";
    case JoinTableErrors.USER_AT_TABLE:
      return null;
    case JoinTableErrors.ENTRY_DENIED:
      return "server:entry.denied";
    case JoinTableErrors.EXIT_DENIED:
      return "server:exit.denied";
    default:
      return "server:table.unhandled";
  }
};

export const shouldRequestHostSeat = (reason: unknown): boolean =>
  reason === JoinTableErrors.TABLE_PLAN_CAPACITY_EXCEEDED ||
  reason === JoinTableErrors.TABLE_FULL;
