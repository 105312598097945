import { Theme } from "@remo-co/ui-core/src/types";
import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";

export const useStyles = makeStyles<Theme>((theme) => ({
  broadcastRecordingsList: {
    overflowY: "auto",
    maxHeight: "310px",

    "&::-webkit-scrollbar": { width: "8px", height: "8px" },
    "&::-webkit-scrollbar-track": {
      width: "8px",
      height: "312px",
      borderRadius: "4px",
      backgroundColor: theme.palette.gray.light,
    },
    "&::-webkit-scrollbar-thumb": {
      width: "8px",
      height: "59px",
      borderRadius: "4px",
    },
  },
}));
