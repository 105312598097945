import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "store";
import { RoomWhiteboardState } from "modules/roomWhiteboard/redux/slice";
import { selectUser } from "../../auth/redux/selectors";

const selectRoomWhiteboard = (state: RootState): RoomWhiteboardState =>
  state.roomWhiteboard;

const selectIsRoomWhiteboardOpen = createSelector(
  selectRoomWhiteboard,
  (substate) => substate.isWhiteboardOpen,
);

const selectIsMeStartedWhiteboard = createSelector(
  selectRoomWhiteboard,
  selectUser,
  (roomWhiteboard, user) => {
    const userId = user ? user.id : null;

    if (!userId || !roomWhiteboard || !roomWhiteboard.startedBy) {
      return false;
    }

    return roomWhiteboard.startedBy === userId;
  },
);

const selectWhiteBoardUrlStatus = createSelector(
  selectRoomWhiteboard,
  (substate) => substate.boardUrlStatus,
);

const selectWhiteBoardCode = createSelector(
  selectRoomWhiteboard,
  (substate) => substate.boardCode,
);

const selectWhiteBoardMicroBoardId = createSelector(
  selectRoomWhiteboard,
  (substate) => substate.miroBoardId,
);

const selectIsWhiteboardLoading = createSelector(
  selectRoomWhiteboard,
  (roomWhiteboard) => roomWhiteboard.boardUrlStatus === "IN_PROGRESS",
);

export {
  selectRoomWhiteboard,
  selectIsRoomWhiteboardOpen,
  selectIsMeStartedWhiteboard,
  selectIsWhiteboardLoading,
  selectWhiteBoardUrlStatus,
  selectWhiteBoardMicroBoardId,
  selectWhiteBoardCode,
};
