import { ICompany } from "../types";

/**
 * Checks if current company plan has whitelabelling enabled
 * Checks if company has whitelabelling enabled
 * and if enabled and if company has logo, send logo
 * also considers per-event custom logo
 */
const getWhitelabelledLogo = (
  companyData?: Pick<ICompany, "enabledWhiteLabel" | "logoURL">,
  eventBrandingLogoURL?: string | null,
) => {
  if (!companyData) return null;

  // prefer event-specific branding logo over company logo, if the former exists
  if (companyData.enabledWhiteLabel && eventBrandingLogoURL) {
    return eventBrandingLogoURL;
  }

  return (companyData.enabledWhiteLabel && companyData.logoURL) || null;
};

export default getWhitelabelledLogo;
