import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "store";
import { BroadcastWhiteboardState } from "modules/broadcastWhiteboard/redux/slice";

const selectBroadcastWhiteboard = (
  state: RootState,
): BroadcastWhiteboardState => state.broadcastWhiteboard;

const selectIsWhiteboardOpen = createSelector(
  selectBroadcastWhiteboard,
  (substate) => substate.isWhiteboardOpen,
);

export { selectBroadcastWhiteboard, selectIsWhiteboardOpen };
