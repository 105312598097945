import {
  useState,
  useEffect,
  useCallback,
  useContext,
  useRef,
  useMemo,
  ChangeEvent,
  FocusEvent,
  FormEvent,
  MouseEvent,
} from "react";
import { Box } from "@remo-co/ui-core/src/components/Box";
import { FormControlLabel } from "@remo-co/ui-core/src/components/FormControlLabel";
import { FormHelperText } from "@remo-co/ui-core/src/components/FormHelperText";
import { Grid } from "@remo-co/ui-core/src/components/Grid";
import { Radio } from "@remo-co/ui-core/src/components/Radio";
import { RadioGroup } from "@remo-co/ui-core/src/components/RadioGroup";
import { Tooltip } from "@remo-co/ui-core/src/components/Tooltip";
import { Typography } from "@remo-co/ui-core/src/components/Typography";
import { Button } from "@remo-co/ui-core/src/components/Button";
import { Card } from "@remo-co/ui-core/src/components/Card";
import { Container } from "@remo-co/ui-core/src/components/Container";
import { Input } from "@remo-co/ui-core/src/components/Input";
import { Select } from "@remo-co/ui-core/src/components/Select";
import { IconButtonWithTooltip } from "@remo-co/ui-core/src/components/IconButtonWithTooltip";
import { difference, range, times } from "lodash";
import { Info } from "@remo-co/ui-core/src/icons/Info";
import { ensureUrlHasProtocol } from "helpers/appHelper";
import { isFormDirty } from "helpers/formHelper";
import { getMaskedUrl, validateVideoURL } from "helpers/videoHelper";
import { isValidImage } from "helpers/urlHelper";
import { MANAGE_EVENT_CONTEXT } from "modules/manageEvent";
import { useCompanyPlanSettings } from "modules/companyPlanSettings";
import useNotificationActions from "modules/notification/hooks/useNotificationActions";
import { useI18n } from "i18n";
import { getMapImageWithTheme } from "modules/theater/theater.helper";
import { getMapTemplateByType } from "modules/event/template";
import UploadCaption, {
  BestUploadSizes,
  MaxUploadSizes,
} from "modules/uploader/UploadCaption";
import { ISponsor, IEvent } from "modules/event/types";
import * as FileStoragePaths from "../../../services/firebaseService/storagePaths";
import useSponsors from "../../event/hooks/useSponsors";
import UploadService, {
  IAfterFileUpload,
} from "../../../services/uploadService/uploadService";
import { MapPreview } from "../components";
import SponsorUploadAndPreview from "./SponsorUploadAndPreview";
import SponsorCtaContent from "./sponsorCtaContent/SponsorCtaContent";
import { useStyles } from "./styles";
import { isCtaLinkAndLabelValid } from "./sponsorCtaContent/utils";
import { EVENT_BANNER_MAX_FILE_SIZE } from "../constants";
import SponsorList from "./SponsorList";

interface Props {
  updateSponsors?: (allSponsors: ISponsor[]) => void;
  eventData?: IEvent | null;
  sponsor?: ISponsor | null;
  availableSlots: number;
  onCancel: (e?: MouseEvent) => void;
  allSponsors: ISponsor[];
  editSponsor: (sponsor: ISponsor) => void;
  showSponsorForm: boolean;
}

type SponsorWithCurrent = ISponsor & {
  current?: string;
};

export const UPLOAD_ELEMENT_ID = "sponsor-uploader";
const RADIX_DEFAULT_BASE = 10;

const SponsorForm = ({
  showSponsorForm,
  sponsor: propsSponsor,
  updateSponsors,
  eventData,
  availableSlots,
  onCancel,
  allSponsors,
  editSponsor,
}: Props): JSX.Element => {
  const { t } = useI18n(["common", "eventForm"]);
  const { addSponsor, updateSponsor } = useSponsors();
  const [sponsor, setSponsor] = useState<ISponsor | null>();
  const [isFormSubmitted, setIsFormSubmitted] = useState<boolean>(false);
  const [highlight, setHighlight] = useState<number>();
  const { addErrorNotification } = useNotificationActions();
  const eventId = eventData?.id;
  const theater = eventData?.theaters?.[0];
  const evTemplate = getMapTemplateByType(theater?.template);
  const [uploader, setUploader] =
    useState<UploadService<SponsorWithCurrent> | null>(null);
  const formRef = useRef<HTMLFormElement | null>(null);
  const { setLoading, loadingMessage, actions } =
    useContext(MANAGE_EVENT_CONTEXT);
  const slots = evTemplate?.sponsors;
  const ref = useRef<HTMLDivElement | null>(null);
  const { getMaxAdsPerEvent } = useCompanyPlanSettings();
  const maxAllowedAdsPerEvent = getMaxAdsPerEvent();
  const defaultMediaType = "video";
  const defaultSponsor = { mediaType: defaultMediaType } as ISponsor;
  const styles = useStyles();

  const handleTextChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (!sponsor) {
      return;
    }

    setSponsor({ ...sponsor, [e.target.name]: e.target.value });
  };

  const handleSponsorUpdate = useCallback(
    (key: string, value: string) => {
      setSponsor((prev) => {
        if (prev) {
          return { ...prev, [key]: value };
        }

        return { ...defaultSponsor, [key]: value };
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [sponsor],
  );

  const handleSlotChange = (slot: string) => {
    handleSponsorUpdate("slot", slot);
  };

  useEffect(() => {
    actions?.setHasDirtyForm(isFormDirty(propsSponsor, sponsor));

    if (sponsor) {
      setHighlight(parseInt(sponsor.slot ?? "-1", RADIX_DEFAULT_BASE));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sponsor]);

  useEffect(() => {
    setSponsor(propsSponsor || defaultSponsor);

    if (showSponsorForm && ref?.current) {
      setTimeout(() => {
        ref.current?.scrollIntoView();
      }, 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [propsSponsor]);

  /**
   * Get sponsor data and set again as it is getting reset whenever uploading
   */
  const handleUpload: IAfterFileUpload<SponsorWithCurrent> = (
    err,
    currentSponsor,
    url,
    // eslint-disable-next-line consistent-return
  ) => {
    if (err) {
      return addErrorNotification({
        message: t("eventForm:upload.image.error"),
      });
    }

    if (currentSponsor?.current && url) {
      const data = { ...currentSponsor, [currentSponsor.current]: url };

      delete data.current;
      handleSponsorUpdate(currentSponsor.current, url);
    }
  };

  const chooseAvailableSlot = () => {
    if (!propsSponsor && !sponsor) {
      // eslint-disable-next-line radix
      const alloted = allSponsors.map((ad) => parseInt(ad.slot ?? "-1"));
      const allowed = difference(range(availableSlots), alloted);

      if (allowed.length > 0) {
        setSponsor({
          _id: undefined,
          ctaLabel: "",
          ctaLink: "",
          logo: "",
          media: "",
          name: "",
          slot: `${allowed[0]}`,
          mediaType: defaultMediaType,
        });
      }
    }
  };

  useEffect(() => {
    if (!showSponsorForm) {
      setSponsor(null);
      return;
    }
    const uploaderInstance = new UploadService<SponsorWithCurrent>(
      UPLOAD_ELEMENT_ID,
      {
        afterFileUpload: handleUpload,
        level: FileStoragePaths.UPLOAD_LEVEL.EVENT,
        path: FileStoragePaths.EVENT_SPONSOR,
        acceptedFileTypes: ["image/*"],
        fileValidateTypeLabelExpectedTypes: t("eventForm:img.format.info.full"),
        maxFileSize: EVENT_BANNER_MAX_FILE_SIZE,
        labelMaxFileSizeExceeded: t("eventForm:img.size.error"),
        labelMaxFileSize: t("eventForm:img.size.info"),
        labelFileProcessingComplete: t("eventForm:img.upload.success"),
      },
    );

    setUploader(uploaderInstance);
    actions?.setHideActionBar(true);
    chooseAvailableSlot();

    // eslint-disable-next-line consistent-return
    return () => {
      actions?.setHasDirtyForm(false);
      actions?.setHideActionBar(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showSponsorForm]);
  /**
   * Update or add sponsor
   * @param e
   */
  const saveSponsor = async (e?: FormEvent) => {
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }

    setIsFormSubmitted(true);
    /**
     * Check if form is valid
     * uses inbuilt validity check for input elements
     * and custom checks for radio and image uploads
     */
    const isFormRefValid = formRef.current?.checkValidity();
    const isValidSponsor =
      isFormRefValid &&
      sponsor?.logo &&
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      isValidMediaUrl &&
      sponsor?.media &&
      sponsor?.slot &&
      sponsor?.name &&
      isCtaLinkAndLabelValid(sponsor);

    if (isValidSponsor) {
      setLoading(t("eventForm:save.sponsor.ad"));
      // Update or add sponsor
      let sponsors = null;

      if (sponsor?._id && eventId) {
        sponsors = await updateSponsor(sponsor, eventId);
      } else if (sponsor) {
        sponsors = await addSponsor(sponsor, eventId);
      }

      if (sponsors && updateSponsors) {
        updateSponsors(sponsors);
        onCancel();
        setIsFormSubmitted(false);
      }
      actions?.setHasDirtyForm(false);
      setLoading("");
    } else {
      addErrorNotification({ message: t("eventForm:fix.error") });
    }
  };

  const isOccupied = (index: number) => {
    let isOcc = false;

    allSponsors.forEach((ad) => {
      // if ad._id - ad for new/cloned event,cannot check if occupied
      if (
        (!ad._id || (sponsor && sponsor._id !== ad._id)) &&
        ad.slot === `${index}`
      ) {
        isOcc = true;
      }
    });

    return isOcc;
  };

  /**
   * The sponsor state is reset when receiving url after upload. Could not figure out why
   * So passing the sponsor data to uploader and getting it back
   * @param key
   */
  const onFileInputClick = (key: string) => {
    if (sponsor) {
      uploader?.browse({ ...sponsor, current: key });
    }
  };

  const onDelete = (key: string) => {
    if (!sponsor) {
      return;
    }

    setSponsor({ ...sponsor, [key]: undefined });
  };
  const selectFromMap = (index: number) => {
    if (!sponsor) {
      return;
    }

    setSponsor({ ...sponsor, slot: `${index}` });
  };

  const handleLinkChange = (e: FocusEvent<HTMLInputElement>) => {
    if (!sponsor) {
      return;
    }

    const link = ensureUrlHasProtocol(e.target.value);

    setSponsor({ ...sponsor, [e.target.name]: link });
  };

  const isValidMediaUrl = useMemo(() => {
    if (!sponsor?.media || sponsor.mediaType === "upload") {
      return true;
    }
    const isValidImageUrl = isValidImage(sponsor.media);
    const isValidVideoUrl = validateVideoURL(getMaskedUrl(sponsor.media));

    if (!sponsor.mediaType || sponsor.mediaType === "video") {
      return isValidVideoUrl;
    }

    if (sponsor.mediaType === "custom") {
      return isValidImageUrl || isValidVideoUrl;
    }

    if (sponsor.mediaType === "image") {
      return isValidImageUrl;
    }
    return false;
  }, [sponsor?.media, sponsor?.mediaType]);

  const getImageErrorText = useCallback(() => {
    if (isValidMediaUrl) {
      return "";
    }

    if (sponsor?.mediaType === "custom") {
      return t("eventForm:video.url.invalid");
    }

    if (
      sponsor?.mediaType === "video" &&
      !validateVideoURL(getMaskedUrl(sponsor?.media ?? ""))
    ) {
      return t("eventForm:url.invalid");
    }

    return t("eventForm:image.url.invalid");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sponsor]);

  const sponsorCountText = useMemo(() => {
    let text = t("eventForm:limit.floor.plans", {
      key: `${maxAllowedAdsPerEvent}`,
    });

    if (slots && slots.length < maxAllowedAdsPerEvent) {
      text += t("eventForm:floor.plan.extend.deny", {
        key: `${slots.length}`,
      });
    }

    return text;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [maxAllowedAdsPerEvent, slots]);

  return (
    <div className="sponsor-form mar-top-20" ref={ref}>
      {showSponsorForm && sponsor && (
        <>
          <div className={styles.buttons}>
            <Card elevation={1}>
              <Container flex justifyContent="space-around">
                <Button
                  data-testid="sponsorform--submit"
                  disabled={!!loadingMessage}
                  size="md"
                  onClick={saveSponsor}
                  variant="primary"
                  color="blue"
                  type="submit"
                >
                  {t("eventForm:button.save.sponsor")}
                </Button>
                <Button
                  variant="secondary"
                  color="blue"
                  size="md"
                  onClick={onCancel}
                >
                  {t("eventForm:button.cancel.action")}
                </Button>
              </Container>
            </Card>
          </div>
          <form noValidate ref={formRef}>
            <Typography variant="h4" className="pad-top-40">
              {t("eventForm:sponsor.name.banner.title")}
            </Typography>
            <Grid className="sponsor-grid" container spacing={0}>
              {/** Left section */}
              <Grid item xs={12} sm={12} className="left">
                <Input
                  fullWidth
                  getRemainingCharsMessage={(key) =>
                    t("character.remaining", { key })
                  }
                  name="name"
                  id="sponsor-company-name"
                  placeholder={t("eventForm:placeholder.sponsor.name")}
                  value={sponsor.name}
                  label={t("eventForm:sponsor.company.name")}
                  onChange={handleTextChange}
                  error={
                    isFormSubmitted &&
                    (!sponsor.name || sponsor.name.length > 30)
                      ? (t("eventForm:company.name.invalid") as string)
                      : false
                  }
                  inputProps={{
                    maxLength: 30,
                    required: true,
                    "data-testid": "sponsor-company-name",
                  }}
                />
              </Grid>
              {/** Right section */}
              <Grid item xs={12} sm={12} className="right">
                <Container top="sm">
                  <Typography variant="h5">
                    {t("eventForm:banner.image")}
                    <IconButtonWithTooltip
                      id="sponsor-logo-help"
                      color="primary"
                      title={t("eventForm:logo.floor.plan")}
                    >
                      <Info fontSize="small" color="primary" />
                    </IconButtonWithTooltip>
                  </Typography>
                </Container>
                <SponsorUploadAndPreview
                  onDelete={onDelete}
                  onFileInputClick={onFileInputClick}
                  dataKey="logo"
                  uploadedImage={sponsor.logo ?? ""}
                  helpText={
                    !isFormSubmitted ? (
                      <FormHelperText>
                        <UploadCaption
                          allowGif
                          maxSize={MaxUploadSizes.big}
                          bestSize={
                            slots
                              ? `${slots[0]?.width}x${slots[0]?.height}`
                              : BestUploadSizes.small
                          }
                        />
                      </FormHelperText>
                    ) : (
                      !sponsor.logo && (
                        <FormHelperText error>
                          {t("eventForm:upload.banner.image")}
                        </FormHelperText>
                      )
                    )
                  }
                />
              </Grid>
            </Grid>

            <Typography variant="h4">
              {t("eventForm:sponsor.ad.display.title")}
            </Typography>
            <Grid className="sponsor-grid" container spacing={0}>
              {/** Left section */}
              <Grid item xs={12} sm={12} className="left">
                <RadioGroup
                  defaultValue="video"
                  aria-label={t("eventForm:area.label.media")}
                  name="mediaType"
                  value={sponsor.mediaType}
                  onChange={handleTextChange}
                  row
                >
                  <FormControlLabel
                    value="video"
                    control={<Radio color="primary" />}
                    label={t("eventForm:video.url.label")}
                    labelPlacement="end"
                  />
                  <FormControlLabel
                    value="image"
                    control={<Radio color="primary" />}
                    label={t("eventForm:image.url")}
                    labelPlacement="end"
                  />
                  <FormControlLabel
                    value="upload"
                    control={<Radio color="primary" />}
                    label={t("eventForm:upload.image")}
                    labelPlacement="end"
                  />
                </RadioGroup>
                {sponsor.mediaType === "upload" ? (
                  <SponsorUploadAndPreview
                    onDelete={onDelete}
                    onFileInputClick={onFileInputClick}
                    dataKey="media"
                    uploadedImage={sponsor.media ?? ""}
                    helpText={
                      <FormHelperText>
                        <UploadCaption
                          allowGif
                          maxSize={MaxUploadSizes.big}
                          bestSize="435x300"
                        />
                      </FormHelperText>
                    }
                  />
                ) : (
                  <Tooltip
                    title={t("eventForm:invalid.video.url") as string}
                    placement="top"
                  >
                    <Container bottom="sm">
                      <Input
                        fullWidth
                        name="media"
                        placeholder=""
                        value={sponsor.media}
                        error={
                          isFormSubmitted && !sponsor.media
                            ? (t("eventForm:upload.add.media") as string)
                            : false
                        }
                        description={getImageErrorText()}
                        onBlur={handleLinkChange}
                        onChange={handleTextChange}
                      />
                    </Container>
                  </Tooltip>
                )}

                {/** CTA Button and Content section */}
                <SponsorCtaContent
                  sponsor={sponsor}
                  setSponsor={setSponsor}
                  isFormSubmitted={isFormSubmitted}
                />
              </Grid>
            </Grid>

            <Grid className="sponsor-grid" container spacing={0}>
              {/** Left section */}
              <Grid item xs={12} sm={12} className="right">
                <Box className="sponsor-slot-title">
                  <Typography variant="h4">
                    {t("eventForm:select.banner.location")}
                  </Typography>
                  <Typography>
                    {t("eventForm:count.sponsors", {
                      key: `${allSponsors.length}/${Math.min(
                        slots ? slots.length : 0,
                        maxAllowedAdsPerEvent,
                      )}`,
                    })}
                    <IconButtonWithTooltip
                      id="sponsor-slot-help"
                      color="primary"
                      title={sponsorCountText}
                    >
                      <Info fontSize="small" color="primary" />
                    </IconButtonWithTooltip>
                  </Typography>
                </Box>
                <Select
                  classes={{ root: styles.bannerSelect }}
                  name="slot"
                  value={sponsor.slot}
                  onChange={handleSlotChange}
                  inputProps={{ "data-testid": "sponsor-slot-select" }}
                  options={times(availableSlots, (index: number) => ({
                    label: t("eventForm:banner.slot", {
                      key: `${index + 1}`,
                    }),
                    value: `${index}`,
                    disabled: isOccupied(index),
                  }))}
                />
              </Grid>
              {/** Right section */}
              <Grid item xs={12} sm={12} className="right">
                {theater && evTemplate && (
                  <MapPreview
                    theme={theater.theme}
                    mapImage={getMapImageWithTheme(evTemplate, theater.theme)}
                    showSelected={selectFromMap}
                    highlight={highlight}
                    allSponsors={allSponsors}
                    selectedTemplate={evTemplate}
                    eventId={eventData.id}
                  />
                )}
              </Grid>
            </Grid>
          </form>
        </>
      )}
      <div className="file-uploader-container">
        <input
          data-testid={UPLOAD_ELEMENT_ID}
          id={UPLOAD_ELEMENT_ID}
          type="file"
          name="filepond"
        />
      </div>
      <SponsorList
        isClone={!!(eventData && eventData.clonedFrom)}
        eventId={eventData?.id}
        sponsors={allSponsors}
        updateSponsors={actions?.setSponsors}
        editSponsor={editSponsor}
      />
    </div>
  );
};

export default SponsorForm;
