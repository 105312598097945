import parseURL from "../parse";
import validateURL from "../validate";

const buildURL = (url: string) => {
  if (!validateURL(url)) {
    return url;
  }
  const result = parseURL(url);

  if (result) {
    const { host, path, search } = parseURL(url);
    const protocol = "https";

    return `${protocol}://${host}${path && path.length > 1 ? path : ""}${
      search && search.length > 0 ? search : ""
    }`;
  }

  return url;
};

export default buildURL;
