import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "store";
import { selectUserId } from "modules/auth/redux/selectors";
import { IEventActivityStatusSlice } from "./slice";

const selectEventActivityStatusSlice = (
  state: RootState,
): IEventActivityStatusSlice => state.eventActivityStatus;

export const selectUsersMap = createSelector(
  selectEventActivityStatusSlice,
  (eventActivityStatusSlice) => eventActivityStatusSlice.users,
);

export const selectUserActivityStatus = (userId: string) =>
  createSelector(selectUsersMap, (usersMap) => usersMap[userId]);

export const selectCurrentUserActivityStatus = createSelector(
  selectUsersMap,
  selectUserId,
  (usersMap, userId) => (userId ? usersMap[userId] : null),
);
