import { useCallback, useRef, useState } from "react";
import { Divider } from "@remo-co/ui-core/src/components/Divider";
import { IconButton } from "@remo-co/ui-core/src/components/IconButton";
import { ListItem } from "@remo-co/ui-core/src/components/ListItem";
import { Popper } from "@remo-co/ui-core/src/components/Popper";
import { Tooltip } from "@remo-co/ui-core/src/components/Tooltip";
import { Typography } from "@remo-co/ui-core/src/components/Typography";
import { MoreVert } from "@remo-co/ui-core/src/icons/MoreVert";
import { useI18n } from "i18n";
import { selectIsInBroadcast } from "modules/broadcast/redux/selectors";
import { useSelector } from "react-redux";
import useStyles from "./styles";
import { useDropDownStyles } from "../UserDropdownActions";
import { useClickOutside } from "../../hooks";
import { SectionMoreAction } from "../../types";

interface Props {
  heading: string;
  groupLength: number;
  moreActions: SectionMoreAction[] | undefined;
}

export const GroupHeader = ({ heading, groupLength, moreActions }: Props) => {
  const dropDownStyles = useDropDownStyles();
  const isInBroadcast = useSelector(selectIsInBroadcast);
  const [isDropdownActionsOpen, setDropdownActionsOpen] = useState(false);
  const elementRef = useRef<HTMLButtonElement | null>(null);
  const { t } = useI18n();

  const styles = useStyles({ isInBroadcast: Boolean(isInBroadcast) });

  const handleMoreClick = useCallback(() => {
    setDropdownActionsOpen((prevState) => !prevState);
  }, []);

  // TODO: implement this
  const clickOutsideRef = useClickOutside(() => setDropdownActionsOpen(false));

  return (
    <>
      <div ref={clickOutsideRef} className={styles.groupHeadingContainer}>
        <Typography variant="h5" className={styles.groupHeading}>
          {heading} ({groupLength})
        </Typography>
        {moreActions && moreActions.length > 0 && (
          <Tooltip title={t("more.actions") as string} placement="top">
            <IconButton
              className={styles.moreButton}
              data-testid="section-more-btn"
              onClick={handleMoreClick}
              ref={elementRef}
              size="large"
            >
              <MoreVert />
              {isDropdownActionsOpen && (
                <Popper
                  open
                  style={{ zIndex: 1501 }}
                  placement="bottom-end"
                  disablePortal={false}
                  anchorEl={elementRef.current}
                  className={dropDownStyles.popper}
                >
                  <div className={dropDownStyles.container}>
                    <div className={dropDownStyles.arrow} />
                    {moreActions.map(
                      (action: SectionMoreAction, index: number) => (
                        <ListItem
                          button
                          autoFocus={index === 0}
                          key={action.label}
                          className={dropDownStyles.actionItem}
                          onClick={action.onClick}
                        >
                          {action.icon}
                          <Typography>{action.label}</Typography>
                        </ListItem>
                      ),
                    )}
                  </div>
                </Popper>
              )}
            </IconButton>
          </Tooltip>
        )}
      </div>
      <Divider />
    </>
  );
};
