import React from "react";
import { sendDeleteRequest } from "services/apiService";
import useNotificationActions from "modules/notification/hooks/useNotificationActions";
import { useI18n } from "i18n";
import { addDialogNotification } from "modules/dialogNotification/redux/dialogNotificationSlice";
import { useAppDispatch } from "store/hooks";
import { EVENT_AGENDA_CONTEXT } from "./EventAgendaContext";
import { IEventAgenda } from "../types";

const useEventAgenda = () => {
  const dispatch = useAppDispatch();
  const { addErrorNotification, addSuccessNotification } =
    useNotificationActions();
  const { state, eventData } = React.useContext(EVENT_AGENDA_CONTEXT);
  const [isInProgress, setIsInProgress] = React.useState<string | null>(null);
  const { t } = useI18n(["common", "eventAgenda", "server"]);

  const deleteAgenda = async (item: IEventAgenda, cb?: () => void) => {
    dispatch(
      addDialogNotification({
        hideCloseButton: true,
        message: t("eventAgenda:delete.agenda.item.message"),
        confirmText: t("eventAgenda:confirm.message.affirm"),
        dismissText: t("eventAgenda:confirm.message.dismiss"),
        onConfirm: async () => {
          setIsInProgress(t("eventAgenda:deleting.agenda"));
          const { isSuccess, message } = await sendDeleteRequest<
            { date: string | null },
            APIResponse
          >(`/event-agenda/${eventData?.id}/agenda/${item.id}`, {
            date: state && state.currentDate,
          });

          if (isSuccess) {
            addSuccessNotification({
              message: t("eventAgenda:agenda.delete.success"),
            });

            if (cb) {
              cb();
            }
          } else {
            addErrorNotification({
              message: t(message ?? "Error deleting event agenda"),
            });
          }
          setIsInProgress(null);
        },
      }),
    );
  };

  return {
    deleteAgenda,
    isInProgress,
    setIsInProgress,
  };
};

export default useEventAgenda;
