import { useState, ChangeEvent, ReactElement } from "react";
import { ScrollableView } from "@remo-co/ui-core/src/components/ScrollableView";
import { AppBar } from "@remo-co/ui-core/src/components/AppBar";
import { Tabs } from "@remo-co/ui-core/src/components/Tabs";
import { Tab } from "@remo-co/ui-core/src/components/Tab";
import { Box } from "@remo-co/ui-core/src/components/Box";
import { TabPanel } from "@remo-co/ui-core/src/components/TabPanel";
import { useI18n } from "i18n";
import useNotificationActions from "modules/notification/hooks/useNotificationActions";
import {
  CreateEventPollMutation,
  CreateEventPollMutationVariables,
  EventPoll,
  EventPollStatus,
  EventPollType,
} from "graphql/generated";
import { useMutation } from "@apollo/client";
import { CREATE_POLL } from "modules/polls/graphql";
import { CreatePollForm } from "./CreatePollForm";
import { useTabItemStyles, useTabsStyles, useStyles } from "./styles";
import { PollIcon, QuizIcon } from "../../assets";

export const CreatePoll = ({
  eventId,
  onCreatePoll,
}: {
  eventId: string;
  onCreatePoll?: (poll: EventPoll) => void;
}): ReactElement => {
  const styles = useStyles();
  const tabsStyles = useTabsStyles();
  const tabItemStyles = useTabItemStyles();
  const [pollType, setPollType] = useState<EventPollType>(EventPollType.Poll);
  const { t } = useI18n(["event", "polls"]);
  const { addSuccessNotification } = useNotificationActions();
  const [createPoll, { loading }] = useMutation<
    CreateEventPollMutation,
    CreateEventPollMutationVariables
  >(CREATE_POLL);

  const handleChange = (
    _event: ChangeEvent<unknown>,
    newPollType: EventPollType,
  ) => {
    setPollType(newPollType);
  };

  return (
    <Box>
      <AppBar position="static" color="transparent" className={styles.appBar}>
        <Tabs
          value={pollType}
          variant="fullWidth"
          onChange={handleChange}
          textColor="secondary"
          indicatorColor="secondary"
          classes={tabsStyles}
        >
          <Tab
            disableRipple
            value={EventPollType.Poll}
            label={t("poll")}
            icon={<PollIcon color="action" />}
            classes={tabItemStyles}
          />
          <Tab
            disableRipple
            value={EventPollType.Quiz}
            label={t("quiz")}
            icon={<QuizIcon color="action" />}
            classes={tabItemStyles}
          />
        </Tabs>
      </AppBar>
      <ScrollableView>
        <TabPanel value={pollType} index={pollType}>
          <CreatePollForm
            loading={loading}
            pollType={pollType}
            initialForm={{
              eventId,
              status: EventPollStatus.Draft,
              timer: 30,
              title: "",
              type: pollType,
            }}
            onSubmit={async (poll) => {
              const res = await createPoll({
                variables: poll,
              });

              if (res.data?.createEventPoll.__typename === "EventPoll") {
                onCreatePoll?.(res.data?.createEventPoll);

                if (pollType === EventPollType.Poll) {
                  addSuccessNotification({ message: t("polls:poll.created") });
                } else if (pollType === EventPollType.Quiz) {
                  addSuccessNotification({ message: t("polls:quiz.created") });
                }
              }
            }}
          />
        </TabPanel>
      </ScrollableView>
    </Box>
  );
};
