import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";

export const useStyles = makeStyles((theme) => ({
  networkingRatingContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: theme.spacing(1.25),
  },
  greenThumbsUpIcon: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    cursor: "pointer",
    color: "#1C5E46",
  },
  redThumbsDownIcon: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    cursor: "pointer",
    color: theme.palette.error.main,
  },
}));
