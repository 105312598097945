// eslint-disable-next-line prefer-regex-literals
export const VALID_URL_REGEX = new RegExp(
  '^(http[s]?:\\/\\/)?[^\\/\\s(["<,>]*\\.[^\\s[",><]*$',
  "i",
); // fragment locator

const validateURL = (str?: string) => {
  if (str) {
    return !!VALID_URL_REGEX.test(str);
  }

  return false;
};

export const validateDomain = (url: string) => {
  const domainRegex = /^[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z]{2,}$/;

  return domainRegex.test(url);
};

export default validateURL;
