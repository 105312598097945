import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { Actions, Errors, trackAction, trackError } from "modules/monitoring";
import { DeviceInfoState, PermissionState } from "../types";
import {
  askDevicesPermissions,
  detectMobile,
  enumerateDevices,
} from "./actions";
import { deserializeError } from "./utils";

export const deviceInfoInitialState: DeviceInfoState = {
  devices: [],
  permissions: {
    audio: PermissionState.PENDING,
    video: PermissionState.PENDING,
  },
  isMobile: false,
};

export const deviceInfo = createSlice({
  name: "devices",
  initialState: deviceInfoInitialState,
  reducers: {
    setDevicePermissions: (
      state,
      action: PayloadAction<{
        audio: PermissionState;
        video: PermissionState;
      }>,
    ) => {
      state.permissions.audio = action.payload.audio;
      state.permissions.video = action.payload.video;
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(
        askDevicesPermissions.fulfilled,
        (state, { meta: { arg: availableMediaStreamConstraints } }) => {
          trackAction(Actions.GET_USER_MEDIA_SUCCESS, {
            constraints: availableMediaStreamConstraints,
          });
        },
      )
      .addCase(askDevicesPermissions.rejected, (state, action) => {
        const { error } = action;

        trackError(deserializeError(error), {
          label: Errors.GET_USER_MEDIA,
        });
      })
      .addCase(enumerateDevices.rejected, (state, action) => {
        const { error } = action;

        trackError(deserializeError(error), {
          label: Errors.MEDIA_DEVICE_ENUM,
        });
      })
      .addCase(enumerateDevices.fulfilled, (state, action) => {
        state.devices = action.payload;

        trackAction(
          Actions.MEDIA_DEVICE_ENUM_SUCCESS,
          JSON.parse(JSON.stringify(state)),
        );
      })
      .addCase(detectMobile.fulfilled, (state, action) => {
        state.isMobile = action.payload;
      }),
});

export const { setDevicePermissions } = deviceInfo.actions;

export default deviceInfo.reducer;
