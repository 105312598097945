import { DocumentData, QueryDocumentSnapshot } from "firebase/firestore";
import { ActionTypes } from "./constants";
import { IChannel, IChat } from "../types";

type UpdateUnreadCountAction = {
  type: ActionTypes.UPDATE_UNREAD_COUNT;
  payload: Immutable.Map<string, number>;
};

export const updateUnreadCount = (
  unreadCountMap: Immutable.Map<string, number>,
): UpdateUnreadCountAction => ({
  payload: unreadCountMap,
  type: ActionTypes.UPDATE_UNREAD_COUNT,
});

type UpdateChatChannelAction = {
  type: ActionTypes.UPDATE_CHAT_CHANNEL;
  payload: { channel: IChannel | null };
};

export const updateChatChannel = (
  channel: IChannel | null,
): UpdateChatChannelAction => ({
  payload: {
    channel,
  },
  type: ActionTypes.UPDATE_CHAT_CHANNEL,
});

type UpdateChannelListensFromAction = {
  type: ActionTypes.UPDATE_CHANNEL_LISTENS_FROM;
  payload: { channel: string; time: number };
};

export const updateChannelListensFrom = (
  channel: string,
  time: number,
): UpdateChannelListensFromAction => ({
  payload: {
    channel,
    time,
  },
  type: ActionTypes.UPDATE_CHANNEL_LISTENS_FROM,
});

type RemoveMessageFromChannelAction = {
  type: ActionTypes.REMOVE_MESSAGE_FROM_CHANNEL;
  payload: { channel: string; message: IChat };
};

export const removeMessageFromChannel = (
  channel: string,
  message: IChat,
): RemoveMessageFromChannelAction => ({
  payload: { channel, message },
  type: ActionTypes.REMOVE_MESSAGE_FROM_CHANNEL,
});

type UpdateActiveChannelAction = {
  type: ActionTypes.UPDATE_ACTIVE_CHANNEL;
  payload: { channel: string | null };
};

export const updateActiveChannel = (
  channelId: string | null,
): UpdateActiveChannelAction => ({
  payload: {
    channel: channelId,
  },
  type: ActionTypes.UPDATE_ACTIVE_CHANNEL,
});

type SetIsLoadingAction = {
  type: ActionTypes.SET_IS_LOADING;
  payload: { isLoading: boolean };
};

export const setIsLoading = (isLoading: boolean): SetIsLoadingAction => ({
  payload: {
    isLoading,
  },
  type: ActionTypes.SET_IS_LOADING,
});

type ReceivedMessagesAction = {
  type: ActionTypes.RECEIVED_MESSAGES;
  payload: {
    messages: Immutable.List<IChat>;
    channel: string;
    newMessage: boolean;
  };
};

export const receivedMessages = (
  messages: Immutable.List<IChat>,
  channel: string,
  newMessage: boolean,
): ReceivedMessagesAction => ({
  payload: { messages, channel, newMessage },
  type: ActionTypes.RECEIVED_MESSAGES,
});

type UpdateChannelPaginationAction = {
  type: ActionTypes.UPDATE_CHANNEL_PAGINATION;
  payload: {
    channel: string;
    time: number;
    paginateAfter: QueryDocumentSnapshot<DocumentData>;
    stopPagination?: boolean;
  };
};

export const updateChannelPagination = (
  channel: string,
  time: number,
  paginateAfter: QueryDocumentSnapshot<DocumentData>,
  stopPagination = false,
  // eslint-disable-next-line max-params
): UpdateChannelPaginationAction => ({
  payload: {
    channel,
    time,
    paginateAfter,
    stopPagination,
  },
  type: ActionTypes.UPDATE_CHANNEL_PAGINATION,
});

type UnsubscribeFromChannelAction = {
  type: ActionTypes.UNSUBSCRIBE_CHANNEL;
  payload: { channel: string };
};

export const unsubscribeFromChannel = (
  channel: string,
): UnsubscribeFromChannelAction => ({
  payload: {
    channel,
  },
  type: ActionTypes.UNSUBSCRIBE_CHANNEL,
});

type ResetChatAction = {
  type: ActionTypes.RESET_CHAT;
};

export const resetChat = (): ResetChatAction => ({
  type: ActionTypes.RESET_CHAT,
});

type SetListenersStatusAction = {
  type: ActionTypes.SET_LISTENERS_STATUS;
  payload: boolean;
};

export const setListenersStatus = (
  payload: boolean,
): SetListenersStatusAction => ({
  type: ActionTypes.SET_LISTENERS_STATUS,
  payload,
});

type CropMessagesAction = {
  type: ActionTypes.CROP_MESSAGES;
  payload: { channel: string; maxMessages: number };
};

export const cropMessages = (
  channel: string,
  maxMessages: number,
): CropMessagesAction => ({
  payload: {
    channel,
    maxMessages,
  },
  type: ActionTypes.CROP_MESSAGES,
});

type SetChatPrefilledMessageAction = {
  type: ActionTypes.SET_CHAT_PREFILLED_MESSAGE;
  payload: { channel: string; message: string };
};

export const setChatPrefilledMessage = (
  channel: string,
  message: string,
): SetChatPrefilledMessageAction => ({
  type: ActionTypes.SET_CHAT_PREFILLED_MESSAGE,
  payload: { channel, message },
});

export type ChatAction =
  | UpdateUnreadCountAction
  | UpdateChatChannelAction
  | UpdateChannelListensFromAction
  | RemoveMessageFromChannelAction
  | UpdateActiveChannelAction
  | SetIsLoadingAction
  | ReceivedMessagesAction
  | UpdateChannelPaginationAction
  | UnsubscribeFromChannelAction
  | ResetChatAction
  | SetListenersStatusAction
  | CropMessagesAction
  | SetChatPrefilledMessageAction;
