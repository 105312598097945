import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";
import zIndexes from "zIndexes.module.scss";

export const useStyles = makeStyles((theme) => ({
  popper: {
    zIndex: zIndexes.modalZIndex,
    pointerEvents: "all",
  },
  container: {
    width: 200,
    borderRadius: 5,
    padding: 10,
    backgroundColor: theme.palette.blue.dark,
  },
  text: {
    color: theme.palette.white,
    fontWeight: 400,
  },
  button: {
    marginTop: 10,
    marginLeft: "auto",
    border: `1px solid ${theme.palette.white}`,
    "&:hover, &:focus": {
      border: `1px solid ${theme.palette.white}`,
    },
  },
  buttonContent: {
    padding: 0,
    fontWeight: 400,
  },
  buttonArrow: {
    position: "absolute",
    fontSize: 7,
    left: 0,
    marginLeft: "-0.9em",
    height: "3em",
    width: "1em",
    "&::before": {
      content: '""',
      margin: "auto",
      display: "block",
      width: 0,
      height: 0,
      borderStyle: "solid",
      borderWidth: "1em 1em 1em 0",
      borderColor: `transparent ${theme.palette.blue.dark} transparent transparent`,
    },
  },
}));

export default useStyles;
