import React from "react";
import { Typography } from "@remo-co/ui-core/src/components/Typography";
import classNames from "classnames";
import { useStyles } from "./PresentationDisplayItemTitle.styles";

const PresentationDisplayItemTitle = ({
  hostView,
  children,
}: {
  hostView?: boolean;
  children: React.ReactNode;
}) => {
  const styles = useStyles({ hostView });

  return (
    <div
      className={classNames(styles.presentationDisplayItemText)}
      data-testid="display-item-title"
    >
      <div className={styles.displayTitleText}>
        <Typography className={styles.text} color="inherit">
          {children}
        </Typography>
      </div>
    </div>
  );
};

export default PresentationDisplayItemTitle;
