import classNames from "classnames";
import { Fade } from "@remo-co/ui-core/src/components/Fade";
import { useSelector } from "react-redux";
import { TransitionGroup } from "react-transition-group";
import { selectIsInBroadcast } from "modules/broadcast/redux/selectors";
import { selectShowLiveCaptions } from "modules/transcription/redux/selectors";
import { useTranscriptionFeed } from "../../hooks/useTranscriptionFeed";
import useStyles from "./styles";
import { TranscriptionFeedMessage } from "../TranscriptionFeedMessage";

export const TranscriptionFeed = () => {
  const { activeSpeaker, transcripts } = useTranscriptionFeed();
  const styles = useStyles();
  const showLiveCaptions = useSelector(selectShowLiveCaptions);
  const isInBroadcast = useSelector(selectIsInBroadcast);

  if (!isInBroadcast || !showLiveCaptions) return null;

  return (
    <div data-testid="transcription-wrapper" className={styles.root}>
      <ul>
        <TransitionGroup
          data-testid="transcription-transition-group"
          className={styles.messageFeed}
        >
          {transcripts.map((transcript) => {
            const transcriptionKey = transcript.userId;
            return (
              <Fade
                key={transcriptionKey}
                in
                timeout={{ enter: 150, exit: 150 }}
                mountOnEnter
                unmountOnExit
              >
                <div
                  key={transcriptionKey}
                  className={classNames(styles.transcription, {
                    [styles.activeTranscription]:
                      transcript.userId === activeSpeaker,
                  })}
                >
                  <li className={styles.message}>
                    <TranscriptionFeedMessage
                      key={transcriptionKey}
                      message={transcript.data}
                    />
                  </li>
                </div>
              </Fade>
            );
          })}
        </TransitionGroup>
      </ul>
    </div>
  );
};
