import moment from "moment";
import { Duration, Time } from "./types";
import { pluralize } from "./language";

export const getUnixTimestamp = (date?: Time) => {
  const targetDate = date ?? new Date();

  return moment(targetDate).unix();
};

export const format = (time: Time, formatString: string) =>
  moment(time).format(formatString);

export const getCalendarTime = (time: Time) =>
  moment(time).calendar(new Date(), {
    sameDay: "LT [| Today]",
    nextDay: "LT [| Tomorrow]",
    nextWeek: "LT [| ] dddd",
    lastDay: "LT [| Yesterday]",
    lastWeek: "LT [| Last] dddd",
    sameElse: "llll",
  });

export const startOfHour = (time: Time) =>
  moment(time).startOf("hour").toDate();

export const startOfDay = (time: Time) =>
  moment(time).utc().startOf("day").toDate();

export const durationTimestamp = (duration: Duration, includeHours = true) => {
  const padZeros = (amount: number) => amount.toString().padStart(2, "0");

  const { hours, minutes, seconds } = duration;

  const dateParts = [];

  if (includeHours) {
    dateParts.push(hours);
  }
  dateParts.push(minutes);
  dateParts.push(seconds);

  const addPrev = (prev: string) => (prev === "" ? "" : `${prev}:`);

  return dateParts.reduce(
    (prev, current) => `${addPrev(prev)}${padZeros(current)}`,
    "",
  );
};

export const formatDuration = (durationInSeconds: number) => {
  if (durationInSeconds < 60) {
    return `${durationInSeconds} s`;
  }

  const minutes = Math.floor(durationInSeconds / 60);

  if (minutes < 60) {
    return pluralize(minutes, "min");
  }

  const hours = Math.floor(minutes / 60);
  const remainingMinutes = Math.floor(minutes % 60);

  return [
    hours > 0 ? pluralize(hours, "hr") : "",
    hours > 0 && remainingMinutes > 0 ? "and" : "",
    remainingMinutes > 0 ? pluralize(remainingMinutes, "min") : "",
  ]
    .filter(Boolean)
    .join(" ");
};

export const formatTimeAgo = (timestamp: number): string =>
  moment(timestamp).fromNow();
