import { makeSelectUserById } from "modules/event/usersInEvent/selectors";
import { useSelector } from "react-redux";
import { Typography } from "@remo-co/ui-core/src/components/Typography";
import { useMemo } from "react";
import { useI18n } from "i18n";
import { NetworkingUserDetails } from "../../redux/slice";
import { NetworkingUserRow } from "../NetworkingUserRow";
import { DiscussionContent } from "../DiscussionContent";
import { NetworkingButtons } from "../NetworkingButtons";
import { useStyles } from "./styles";

interface Props {
  user: Record<string, NetworkingUserDetails>;
}

export const NetworkingParticipantDetails = ({ user }: Props) => {
  const userId = useMemo(() => Object.keys(user)[0], [user]);
  const userDetails = useSelector(makeSelectUserById(userId));
  const { t } = useI18n("event");
  const styles = useStyles();

  const userInfo = useMemo(() => user[userId], [user, userId]);

  return (
    <div
      className={styles.userDetailsContainer}
      data-testid="networking-participant-details"
    >
      <NetworkingUserRow user={userDetails} rating={userInfo.rating} />
      <div>
        <Typography variant="body1">
          <Typography variant="body1" className={styles.textBold}>
            {t("about")}
          </Typography>
          {userInfo?.about}
        </Typography>
      </div>
      {userInfo.commonTopics.length && (
        <div>
          <Typography variant="body1" className={styles.commonTopicHeading}>
            {t("both.of.you.can.talk.about")}
          </Typography>
          <DiscussionContent topics={userInfo.commonTopics} />
        </div>
      )}
      {userDetails && <NetworkingButtons user={userDetails} />}
    </div>
  );
};
