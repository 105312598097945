import { Theme } from "@remo-co/ui-core/src/types";
import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";

export const useStyles = makeStyles<Theme>((theme) => ({
  tooltip: {
    background: theme.palette.white,
    maxWidth: theme.spacing(17.5),
    border: `1px solid ${theme.palette.gray.light}`,
    padding: theme.spacing(1.25),
  },
  arrow: {
    color: theme.palette.common.white,
  },
}));
