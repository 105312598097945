import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";

const useStyles = makeStyles(() => ({
  container: {
    cursor: "pointer",
  },
  button: {
    marginLeft: 25,
  },
  mobileText: {
    marginLeft: 4,
  },
}));

export default useStyles;
