import { memo, useCallback, useMemo, useState, ChangeEvent } from "react";
import { difference, filter, map } from "lodash";
import * as Immutable from "immutable";
import { useSelector } from "react-redux";
import {
  makeSelectChat,
  makeSelectChatChannels,
  selectUnreadCount,
} from "modules/chat/redux/selectors";
import { IChannel, IChat } from "modules/chat/types";
import { CHAT_MESSAGE_TYPE, CHAT_TYPE } from "modules/chat/redux/constants";
import { buildChannel } from "modules/chat/chatUtils";
import { selectIsInBroadcast } from "modules/broadcast/redux/selectors";
import {
  makeSelectLoginUserInEvent,
  makeSelectUsersInEvent,
} from "modules/event/usersInEvent/selectors";
import { getUserName } from "modules/userProfile";
import { Virtuoso } from "react-virtuoso";
import { IUser } from "modules/app/types";
import { SearchBox } from "../SearchBox";
import useStyles from "./styles";
import { getUserIds } from "../../chatUtils";
import { ChatRow } from "../../components";
import { ChatListHeader } from "../ChatListHeader";

interface Props {
  onlineUsers: IUser[];
}

const ChatsList = memo(({ onlineUsers }: Props) => {
  const usersInEvent = useSelector(makeSelectUsersInEvent);
  const chats = useSelector(makeSelectChat());
  const user = useSelector(makeSelectLoginUserInEvent());
  const newMessageCount = useSelector(selectUnreadCount);
  const channels = useSelector(makeSelectChatChannels());
  const [query, setQuery] = useState("");

  const isInBroadcast = useSelector(selectIsInBroadcast);
  const classes = useStyles({ isInBroadcast: Boolean(isInBroadcast) });

  // debounce change for filter
  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setQuery(e.target.value);
    },
    [setQuery],
  );

  // handle filter
  const filteredUserIds = useMemo(
    () =>
      map(
        filter(onlineUsers, (o) => {
          const queryText = query.trim().toLowerCase();
          const nameText = o.profile ? getUserName(o).toLowerCase().trim() : "";

          return nameText.indexOf(queryText) !== -1;
        }),
        (o) => o.id,
      ),
    [onlineUsers, query],
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getLastMessage = (channelId: string) => {
    const allMessages = chats.get(channelId, Immutable.List<IChat>());
    // eslint-disable-next-line @typescript-eslint/no-use-before-define

    return allMessages.findLast(
      (chat) => typeof chat !== "undefined" && chat.type !== CHAT_MESSAGE_TYPE,
    );
  };

  const publicChannels = useMemo(
    () =>
      channels
        .filter((channel: IChannel, channelId: string) => {
          if (channel.type !== CHAT_TYPE.USER) {
            const lastMessage = getLastMessage(channelId);

            const thisChannel = channel;

            thisChannel.lastMessage = lastMessage;

            return thisChannel;
          }

          return null;
        })
        .sort((a: IChannel) => (a.type === CHAT_TYPE.THEATER ? -1 : 1)), // Sort to get general chat at top always
    [channels, getLastMessage],
  );

  const getChannelUnreadCount = (channelId: string) =>
    newMessageCount.get(channelId) || 0;

  const privateChannels = useMemo<Immutable.Map<string, IChannel>>(() => {
    if (query && query.length) {
      const filteredChannels = filteredUserIds.reduce(
        (acc: Immutable.Map<string, IChannel>, userId: string) => {
          const userInEvent = usersInEvent.get(userId);
          if (!userInEvent) return acc;

          const newChannel = buildChannel(userInEvent, user?.id);

          if (newChannel) return acc.set(newChannel?.id, newChannel);

          return acc;
        },
        Immutable.Map<string, IChannel>(),
      );

      return filteredChannels;
    }

    const availableChannels = channels.filter(
      (channel: IChannel) => channel && channel.type === CHAT_TYPE.USER,
    );

    const activeUserIds: string[] = [];
    // Get already messaged user channels
    const activeChannels = availableChannels.reduce(
      (acc: Immutable.Map<string, IChannel>, channel: IChannel) => {
        if (user && channel) {
          const otherUserIds = difference<string>(getUserIds(channel), [
            user?.id,
          ]);
          const lastMessage = getLastMessage(channel.id);
          otherUserIds.flat().forEach((id) => {
            activeUserIds.push(id);
          });

          return acc.set(channel.id, {
            ...channel,
            lastMessage,
          });
        }

        return acc;
      },
      Immutable.Map<string, IChannel>(),
    );

    const remainUsers = difference(filteredUserIds, activeUserIds);

    const remainUserChannels = remainUsers.reduce(
      (acc: Immutable.Map<string, IChannel>, userId: string) => {
        const userInEvent = usersInEvent.get(userId);
        if (!userInEvent) return acc;

        const newChannel = buildChannel(userInEvent, user?.id);

        if (newChannel) return acc.set(newChannel?.id, newChannel);

        return acc;
      },
      Immutable.Map<string, IChannel>(),
    );

    return remainUserChannels.merge(activeChannels);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chats, channels, filteredUserIds, usersInEvent])
    .filter((channel?: IChannel) => Boolean(channel))
    // Sort users by last message time
    .sortBy(
      (channel?: IChannel) => channel?.lastMessage && -channel.lastMessage.time,
    )
    // Sort users by unread messages first
    .sortBy(
      (channel?: IChannel) => channel && -getChannelUnreadCount(channel.id),
    );

  return (
    <>
      <div className={classes.searchContainer}>
        <SearchBox value={query} onChange={handleChange} />
      </div>
      <div className={classes.chatListContainer}>
        {/* TODO: implement restoreStateFrom if renders from suspended state are slow */}
        <Virtuoso
          data-testid="sc-chat-list"
          data={privateChannels
            .valueSeq()
            .toArray()
            .filter(
              (channel) =>
                user && getUserIds(channel).some((id) => id !== user.id),
            )}
          context={{
            publicChannels: publicChannels.valueSeq().toArray().filter(Boolean),
            user,
          }}
          components={{
            Header: ChatListHeader,
          }}
          // eslint-disable-next-line react/no-unstable-nested-components
          itemContent={(_, channel) => (
            <ChatRow
              key={channel.id}
              channel={channel}
              lastMessage={channel.lastMessage}
              user={user}
              unread={getChannelUnreadCount(channel.id)}
            />
          )}
        />
      </div>
    </>
  );
});

export default ChatsList;
