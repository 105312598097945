import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "store";

const selectCustomFloorPlan = (state: RootState) => state.customFloorPlan;

const selectFloorPlanTags = createSelector(
  selectCustomFloorPlan,
  (substate) => substate.selectedTags,
);

const selectCompanyTags = createSelector(
  selectCustomFloorPlan,
  (substate) => substate.companyTags,
);

const selectDefaultTags = createSelector(
  selectCustomFloorPlan,
  (substate) => substate.defaultTags,
);

export { selectFloorPlanTags, selectCompanyTags, selectDefaultTags };
