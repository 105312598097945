import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";

export const useStyles = makeStyles((theme) => ({
  iframe: {
    width: "100%",
  },
  button: {
    minWidth: 0,
    position: "absolute",
    right: theme.spacing(0.5),
  },
  loading: {
    position: "absolute",
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
  },
  openInNewTab: {
    width: `calc(100% - ${theme.spacing(3)})`,
    backgroundColor: theme.palette.blue.dark,
    padding: `${theme.spacing(0.5)} ${theme.spacing(1.5)}`,
  },
  openInNewTabButtonContent: {
    color: theme.palette.white,
    display: "flex",
    alignItems: "center",
  },
  openInNewTabButtonIcon: {
    fontSize: "16px",
    marginLeft: theme.spacing(0.5),
  },
}));
