import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { MiroBoard } from "modules/whiteboard/types";

export interface BroadcastWhiteboardState {
  isWhiteboardOpen: boolean;
  boardCode?: string;
  miroBoardId?: string;
}

export const initialState: BroadcastWhiteboardState = {
  isWhiteboardOpen: false,
};

const broadcastWhiteboardSlice = createSlice({
  name: "broadcastWhiteboard",
  initialState,
  reducers: {
    openWhiteboard: (state: BroadcastWhiteboardState) => {
      state.isWhiteboardOpen = true;
    },
    closeWhiteboard: (state: BroadcastWhiteboardState) => {
      state.isWhiteboardOpen = false;
    },
    updateBroadcastWhiteboard: (
      state: BroadcastWhiteboardState,
      action: PayloadAction<MiroBoard>,
    ) => {
      state.miroBoardId = action.payload.miroBoardId;
      state.boardCode = action.payload.boardCode;
    },
  },
});

export const { openWhiteboard, closeWhiteboard, updateBroadcastWhiteboard } =
  broadcastWhiteboardSlice.actions;

export default broadcastWhiteboardSlice.reducer;
