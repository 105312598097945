import { selectIsEventManager } from "modules/event/selectors";
import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "store";

const selectTimer = (state: RootState) => state.timer;

export const selectIsTimerRunning = createSelector(
  selectTimer,
  ({ startedAt }) => startedAt >= 0,
);

export const selectTimerSetupActive = createSelector(
  selectTimer,
  (substate) => substate.setupActive,
);

export const selectShowTimer = createSelector(
  selectTimerSetupActive,
  selectIsTimerRunning,
  selectIsEventManager,
  (isSetupVisible, isTimerRunning, isEventManager) =>
    (isSetupVisible && isEventManager) || isTimerRunning,
);

export const selectTimerSetupParams = createSelector(
  selectTimer,
  ({ rounds, roundDuration, breakDuration }) => ({
    rounds,
    roundDuration,
    breakDuration,
  }),
);

export const selectTimerParams = createSelector(
  selectTimer,
  ({ rounds, roundDuration, breakDuration, startedAt }) => ({
    rounds,
    roundDuration,
    breakDuration,
    startedAt,
  }),
);
