import { useContext } from "react";
import { useSelector } from "react-redux";
import { useMutation } from "@apollo/client";
import { FlipToFront } from "@remo-co/ui-core/src/icons/FlipToFront";
import { useI18n } from "i18n";
import { ActionButton } from "modules/actionButton";
import { DialogType } from "modules/dialogNotification/constants";
import { CREATE_EMBED_CONTENT } from "modules/eventEmbedContent/graphql";
import { selectCurrentEvent } from "modules/event/selectors";
import { Errors, trackError } from "modules/monitoring";
import { Events, TRACKING_CONTEXT } from "modules/tracking";
import useNotificationActions from "modules/notification/hooks/useNotificationActions";
import {
  CreateEmbedContentMutation,
  CreateEmbedContentMutationVariables,
  EventEmbedContentMode,
} from "graphql/generated";
import {
  addDialogNotification,
  closeDialogNotificationsByType,
  updateDialogNotificationParams,
} from "modules/dialogNotification/redux/dialogNotificationSlice";
import { useAppDispatch } from "store/hooks";
import { LaunchEmbedContentDialogContent } from "../LaunchEmbedContentDialogContent";
import useStyles from "./styles";

interface Props {
  closePopover: () => void;
  enableClickAway: () => void;
  disableClickAway: () => void;
}

const LaunchEmbedContentButton = ({
  closePopover,
  enableClickAway,
  disableClickAway,
}: Props): JSX.Element | null => {
  const { track } = useContext(TRACKING_CONTEXT);
  const currentEvent = useSelector(selectCurrentEvent);
  const [createEmbedContent] = useMutation<
    CreateEmbedContentMutation,
    CreateEmbedContentMutationVariables
  >(CREATE_EMBED_CONTENT);

  const dispatch = useAppDispatch();
  const { addErrorNotification, addSuccessNotification } =
    useNotificationActions();

  const { t } = useI18n(["event", "common"]);
  const styles = useStyles();

  const handleClose = () => {
    enableClickAway();
    dispatch(closeDialogNotificationsByType(DialogType.EMBED_CONTENT_LAUNCH));
    closePopover();
  };

  const handleConfirm = async (url: string, contentType: string) => {
    if (!currentEvent) {
      return;
    }

    try {
      const { data } = await createEmbedContent({
        variables: {
          eventId: currentEvent.id,
          url,
          mode: EventEmbedContentMode.Conversation,
        },
      });

      if (!data) {
        trackError("No data received", {
          label: Errors.EMBED_CONTENT_CREATE_MUTATION,
          url,
        });

        addErrorNotification({
          message: t("event:embed.content.queue.error"),
          position: "tc",
        });

        return;
      }

      if (data.createEmbedContent?.__typename === "GQLError") {
        trackError(data.createEmbedContent.message, {
          label: Errors.EMBED_CONTENT_CREATE_MUTATION,
          url,
        });

        addErrorNotification({
          message: t("event:embed.content.queue.error"),
          position: "tc",
        });

        return;
      }

      track(Events.EMBED_CONTENT_CREATE, {
        eventId: currentEvent.id,
        eventType: currentEvent.eventType,
        url,
        urlContentType: contentType,
      });
      addSuccessNotification({
        message: t("event:embed.content.queued"),
        position: "tc",
      });
    } catch (error) {
      trackError(error, {
        label: Errors.GRAPHQL_NETWORK_ERROR,
        url,
      });
    }

    handleClose();
  };

  const handleOptionChange = (
    url: string,
    contentType: string,
    confirmDisabled: boolean,
  ) => {
    dispatch(
      updateDialogNotificationParams({
        type: DialogType.EMBED_CONTENT_LAUNCH,
        data: {
          content: (
            <LaunchEmbedContentDialogContent
              onChange={handleOptionChange}
              onClose={handleClose}
            />
          ),
          confirmDisabled,
          onConfirm: () => handleConfirm(url, contentType),
        },
      }),
    );
  };

  const handleClick = () => {
    if (!currentEvent) {
      return;
    }

    track(Events.EMBED_CONTENT_CREATE_START, {
      eventId: currentEvent.id,
      eventType: currentEvent.eventType,
    });
    disableClickAway();
    dispatch(
      addDialogNotification({
        confirmTestId: "toggle-confirm",
        dismissTestId: "toggle-dismiss",
        contentClassName: styles.dialogContent,
        content: (
          <LaunchEmbedContentDialogContent
            onChange={handleOptionChange}
            onClose={handleClose}
          />
        ),
        confirmDisabled: true,
        onDismiss: handleClose,
        confirmText: t("event:launch"),
        dismissText: t("common:button.cancel"),
        hideCloseButton: true,
        type: DialogType.EMBED_CONTENT_LAUNCH,
      }),
    );
  };

  return (
    <ActionButton
      title={t("event:content.popup")}
      label={t("event:content.popup")}
      onClick={handleClick}
      isPopoverButton
      icon={<FlipToFront className={styles.icon} />}
    />
  );
};

export default LaunchEmbedContentButton;
