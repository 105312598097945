import { gql } from "@apollo/client";

export const ON_EVENT_CONTENT_CHANGED = gql`
  subscription OnEventContentChanged($event: ObjectID!) {
    onEventContentChanged(event: $event) {
      ... on ContentWithMapping {
        __typename
        id
        event
        floor
        slot
        company
        name
        ctaLink
        ctaText
        floorImage
        hideOpenInNewTabButton
        media
        mediaType
        type
      }
    }
  }
`;

export const ON_EVENT_CONTENT_DELETED = gql`
  subscription OnEventContentDeleted($event: ObjectID!) {
    onEventContentDeleted(event: $event) {
      ... on EventContentMapping {
        __typename
        eventContent
        floor
        event
        slot
      }
    }
  }
`;

export const ON_EVENT_FLOOR_CONTENT_CHANGED = gql`
  subscription OnEventFloorContentChanged(
    $event: ObjectID!
    $floor: ObjectID!
  ) {
    onEventFloorContentChanged(event: $event, floor: $floor) {
      ... on ContentWithMapping {
        __typename
        id
        event
        floor
        slot
        company
        name
        ctaLink
        ctaText
        floorImage
        hideOpenInNewTabButton
        media
        mediaType
        type
      }
    }
  }
`;

export const ON_EVENT_FLOOR_CONTENT_DELETED = gql`
  subscription OnEventFloorContentDeleted(
    $event: ObjectID!
    $floor: ObjectID!
  ) {
    onEventFloorContentDeleted(event: $event, floor: $floor) {
      ... on EventContentMapping {
        __typename
        eventContent
        floor
        event
        slot
      }
    }
  }
`;
