export interface SerializedDevice {
  deviceId: string;
  label: string;
}

type MediaStreamType = "audio" | "video";

export interface DeviceInfoState {
  devices: MediaDeviceInfo[];
  permissions: Record<MediaStreamType, PermissionState>;
  isMobile: boolean;
}

// literal values are in use - avoid changing without checking references
export const enum PermissionState {
  BLOCKED = "blocked",
  GRANTED = "granted",
  IDLE = "idle",
  IN_USE = "in-use",
  NOT_FOUND = "not-found",
  PENDING = "pending",
  UNKNOWN = "unknown",
}

export const enum BlockedBy {
  BROWSER = "browser",
  OS = "os",
}
