import { useContext, useState } from "react";
import { useSelector } from "react-redux";
import { useI18n } from "i18n";
import { useMutation } from "@apollo/client";
import {
  CreateEventContentMutation,
  CreateEventContentMutationVariables,
  DeleteEventContentMutation,
  DeleteEventContentMutationVariables,
  PlaceContentOnSlotMutation,
  PlaceContentOnSlotMutationVariables,
  RemoveContentOnSlotMutation,
  RemoveContentOnSlotMutationVariables,
  UpdateEventContentMutation,
  UpdateEventContentMutationVariables,
} from "graphql/generated";
import { selectCompanyId } from "modules/company/redux/selectors";
import { Errors, trackError } from "modules/monitoring";
import useNotificationActions from "modules/notification/hooks/useNotificationActions";
import { Events, TRACKING_CONTEXT } from "modules/tracking";
import {
  CREATE_EVENT_CONTENT,
  UPDATE_EVENT_CONTENT,
  DELETE_EVENT_CONTENT,
  PLACE_CONTENT_ON_SLOT,
  REMOVE_CONTENT_ON_SLOT,
} from "../../graphql/mutations";
import { ContentMapping, ContentWithConsolidatedMapping } from "../../types";

export const useEventContentActions = (eventId?: string) => {
  const { addErrorNotification, addSuccessNotification } =
    useNotificationActions();
  const { t } = useI18n(["eventForm", "common"]);
  const { track } = useContext(TRACKING_CONTEXT);
  const companyId = useSelector(selectCompanyId);
  const [createEventContentMutation] = useMutation<
    CreateEventContentMutation,
    CreateEventContentMutationVariables
  >(CREATE_EVENT_CONTENT);
  const [updateEventContentMutation] = useMutation<
    UpdateEventContentMutation,
    UpdateEventContentMutationVariables
  >(UPDATE_EVENT_CONTENT);
  const [deleteEventContentMutation] = useMutation<
    DeleteEventContentMutation,
    DeleteEventContentMutationVariables
  >(DELETE_EVENT_CONTENT);
  const [placeContentOnSlot] = useMutation<
    PlaceContentOnSlotMutation,
    PlaceContentOnSlotMutationVariables
  >(PLACE_CONTENT_ON_SLOT);
  const [removeContentOnSlot] = useMutation<
    RemoveContentOnSlotMutation,
    RemoveContentOnSlotMutationVariables
  >(REMOVE_CONTENT_ON_SLOT);
  const [isLoading, setIsLoading] = useState(false);

  const processContentMapping = async (
    contentId: string,
    incomingMapping: ContentMapping,
    existingMappings?: ContentWithConsolidatedMapping["mappings"],
  ) => {
    if (!companyId || !eventId) {
      return false;
    }

    const promises = [
      // place slots - if new, all incomingMapping, else compare from existing and only add non-existing entries
      ...incomingMapping.floors
        .filter((floor) =>
          existingMappings
            ? !existingMappings.some(
                (existingMapping) =>
                  existingMapping.floor === floor &&
                  existingMapping.slot === incomingMapping.slot,
              )
            : true,
        )
        .map((floor) =>
          placeContentOnSlot({
            variables: {
              companyId,
              eventId,
              floorId: floor,
              slot: incomingMapping.slot,
              eventContent: contentId,
            },
          }),
        ),
      // remove slots - existing, process incoming and existing mapping
      ...(existingMappings
        ? existingMappings
            .filter(
              (existingMapping) =>
                !incomingMapping.floors.some(
                  (floor) =>
                    existingMapping.floor === floor &&
                    existingMapping.slot === incomingMapping.slot,
                ),
            )
            .map(({ floor, slot }) =>
              removeContentOnSlot({
                variables: {
                  companyId,
                  eventId,
                  floorId: floor,
                  slot,
                },
              }),
            )
        : []),
    ];

    const results = await Promise.all(promises);

    // false on first found erroneous response
    const status = !results.some(
      ({ data }) =>
        data &&
        (("removeContentOnSlot" in data &&
          data.removeContentOnSlot.__typename === "GQLError") ||
          ("placeContentOnSlot" in data &&
            data.placeContentOnSlot.__typename === "GQLError")),
    );

    if (!status) {
      results.forEach(({ data }) => {
        if (
          data &&
          "placeContentOnSlot" in data &&
          data.placeContentOnSlot.__typename === "GQLError"
        ) {
          trackError(data.placeContentOnSlot.message, {
            label: Errors.EVENT_CONTENT_MAPPING,
          });
        }
        if (
          data &&
          "removeContentOnSlot" in data &&
          data.removeContentOnSlot.__typename === "GQLError"
        ) {
          trackError(data.removeContentOnSlot.message, {
            label: Errors.EVENT_CONTENT_MAPPING,
          });
        }
      });
    }

    return status;
  };

  const createEventContent = async (
    contentData: Omit<CreateEventContentMutationVariables, "companyId">,
    mapping: ContentMapping,
  ) => {
    if (!companyId || !eventId) {
      return false;
    }

    setIsLoading(true);

    const { data } = await createEventContentMutation({
      variables: {
        ...contentData,
        companyId,
      },
    });

    if (!data) {
      trackError("No data received", {
        label: Errors.EVENT_CONTENT_CREATE_MUTATION,
      });

      addErrorNotification({
        message: t("event:content.create.error"),
        position: "tc",
      });
      setIsLoading(false);
      return false;
    }

    if (data.createEventContent?.__typename === "GQLError") {
      trackError(data.createEventContent.message, {
        label: Errors.EVENT_CONTENT_CREATE_MUTATION,
      });

      addErrorNotification({
        message: t("event:content.create.error"),
        position: "tc",
      });
      setIsLoading(false);
      return false;
    }

    const status = await processContentMapping(
      data.createEventContent.id,
      mapping,
    );

    if (status) {
      track(Events.CONTENT_BANNER_CREATED, {
        eventId,
        contentId: data.createEventContent.id,
        mediaType: contentData.mediaType,
        type: contentData.type,
        floorIds: mapping.floors.join(","),
        slot: mapping.slot,
      });
    } else {
      addErrorNotification({
        message: t("eventForm:content.create.error"),
        position: "tc",
      });
    }

    setIsLoading(false);
    return status;
  };

  const updateEventContent = async (
    contentId: string,
    contentData: Omit<UpdateEventContentMutationVariables, "companyId" | "id">,
    mapping: ContentMapping,
    existingMappings: ContentWithConsolidatedMapping["mappings"],
  ) => {
    if (!companyId || !eventId) {
      return false;
    }

    setIsLoading(true);

    const { data } = await updateEventContentMutation({
      variables: {
        ...contentData,
        id: contentId,
        companyId,
      },
    });

    if (!data) {
      trackError("No data received", {
        label: Errors.EVENT_CONTENT_UPDATE_MUTATION,
      });

      addErrorNotification({
        message: t("event:content.update.error"),
        position: "tc",
      });
      setIsLoading(false);
      return false;
    }

    if (data.updateEventContent?.__typename === "GQLError") {
      trackError(data.updateEventContent.message, {
        label: Errors.EVENT_CONTENT_UPDATE_MUTATION,
      });

      addErrorNotification({
        message: t("event:content.update.error"),
        position: "tc",
      });
      setIsLoading(false);
      return false;
    }

    const status = await processContentMapping(
      data.updateEventContent.id,
      mapping,
      existingMappings,
    );

    if (status) {
      track(Events.CONTENT_BANNER_UPDATED, {
        eventId,
        contentId,
        mediaType: contentData.mediaType,
        type: contentData.type,
        floorIds: mapping.floors.join(","),
        slot: mapping.slot,
      });
    } else {
      addErrorNotification({
        message: t("eventForm:content.update.error"),
        position: "tc",
      });
    }

    setIsLoading(false);
    return status;
  };

  const deleteEventContent = async (contentId: string) => {
    if (!companyId || !eventId) {
      return false;
    }
    setIsLoading(true);

    const { data } = await deleteEventContentMutation({
      variables: {
        id: contentId,
        companyId,
      },
    });

    setIsLoading(false);

    if (!data) {
      trackError("No data received", {
        label: Errors.EVENT_CONTENT_DELETE_MUTATION,
      });

      addErrorNotification({
        message: t("event:content.delete.error"),
        position: "tc",
      });
      return false;
    }

    if (data.deleteEventContent?.__typename === "GQLError") {
      trackError(data.deleteEventContent.message, {
        label: Errors.EVENT_CONTENT_DELETE_MUTATION,
      });

      addErrorNotification({
        message: t("event:content.delete.error"),
        position: "tc",
      });
      return false;
    }

    track(Events.CONTENT_BANNER_DELETED, {
      eventId,
      contentId,
    });

    addSuccessNotification({
      message: t("eventForm:content.deleted.successfully"),
    });
    return true;
  };

  return {
    createEventContent,
    updateEventContent,
    deleteEventContent,
    isLoading,
  };
};
