import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export interface ITicketingState {
  showTickets: boolean;
  hasTickets: boolean;
  hasCurrentUserPlacedTicketOrder: boolean;
  isLoading: boolean;
  shouldPayForEvent: boolean;
}

export const ticketingInitialState: ITicketingState = {
  showTickets: false,
  hasTickets: false,
  hasCurrentUserPlacedTicketOrder: false,
  isLoading: true,
  shouldPayForEvent: true,
};

export const ticketingSlice = createSlice({
  name: "ticketing",
  initialState: ticketingInitialState,
  reducers: {
    setShowTickets: (state, action: PayloadAction<boolean>) => {
      state.showTickets = action.payload;
    },
    setHasTickets: (state, action: PayloadAction<boolean>) => {
      state.hasTickets = action.payload;
    },
    setHasCurrentUserPlacedTicketOrder: (
      state,
      action: PayloadAction<boolean>,
    ) => {
      state.hasCurrentUserPlacedTicketOrder = action.payload;
    },
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setShouldPayForEvent: (state, action: PayloadAction<boolean>) => {
      state.shouldPayForEvent = action.payload;
    },
    reset: () => ticketingInitialState,
  },
});

export const {
  setShowTickets,
  setHasTickets,
  setHasCurrentUserPlacedTicketOrder,
  setIsLoading,
  setShouldPayForEvent,
  reset,
} = ticketingSlice.actions;

export default ticketingSlice.reducer;
