import { useEffect, useState } from "react";
import { datadogRum } from "@datadog/browser-rum";
import { IUser } from "modules/app/types";
import { initUser } from "../../helpers/initUser";
import { config } from "../../config";
import { TrackRefreshes } from "../TrackRefreshes";
import { TrackCompanyPlan } from "../TrackCompanyPlan";
import { TrackIframe } from "../TrackIframe";
import { TrackEventCode } from "../TrackEventCode";
import { TrackEventRole } from "../TrackEventRole";
import { TrackCompanyRole } from "../TrackCompanyRole";

interface Props {
  user?: IUser;
}

export const Monitoring = ({ user }: Props) => {
  const [isInit, setInit] = useState(
    Boolean(datadogRum.getInternalContext()?.session_id),
  );

  useEffect(() => {
    if (isInit) {
      return;
    }

    datadogRum.init(config);
    setInit(true);
  }, [isInit]);

  useEffect(() => {
    if (!isInit || !user) {
      return;
    }

    initUser(user);
  }, [user, isInit]);

  if (!isInit) {
    return null;
  }

  return (
    <>
      <TrackCompanyPlan />
      <TrackIframe />
      <TrackEventCode />
      <TrackRefreshes />
      <TrackEventRole />
      <TrackCompanyRole />
    </>
  );
};
