export const CHARGEBEE = "chargebee";

export const CURRENT_COMPANY_ID = "current-companyid";

export enum CompanyRole {
  ASSISTANT = "assistant",
  MANAGER = "manager",
  OWNER = "owner",
}

export enum CompanyFeatureToggles {
  Chat = "Chat",
  ChatUpload = "ChatUpload",
  LockTables = "LockTablesByGuests",
  Polls = "Polls",
  QA = "QA",
  Whiteboard = "Whiteboard",
}

export enum SubscriptionTerms {
  ANNUAL = "ANNUAL",
  CUSTOM = "CUSTOM",
  MONTHLY = "Monthly",
  QUARTERLY = "Quarterly",
}

export const COMPANY_SETTINGS_QUERY_KEY = "company-settings";
