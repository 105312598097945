import { Theme } from "@remo-co/ui-core/src/types";
import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";

export const useStyles = makeStyles<Theme>(() => ({
  listItemHighlight: {
    opacity: "1 !important",
  },
  listItemHighlightText: {
    fontWeight: 700,
  },
}));
