import { datadogRum } from "@datadog/browser-rum";
import { datadogLogs } from "@datadog/browser-logs";
import { IUser } from "modules/app/types";
import { getUserName } from "modules/userProfile";

export const initUser = (user: IUser) => {
  const { id, email, profile } = user;

  const name = getUserName(user);

  const screenResolution = `${
    window.screen.width * window.devicePixelRatio
  } x ${window.screen.height * window.devicePixelRatio}`;

  datadogRum.setUser({
    id,
    name,
    email,
    screenResolution,
    devicePixelRatio: window.devicePixelRatio,
  });

  datadogLogs.logger.setContextProperty("user", {
    id,
    email,
    profile,
  });
};
