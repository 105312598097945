import { useHistory } from "react-router";
import { useAppDispatch } from "store/hooks";
import logger from "logging/logger";
import { SOCIAL_PROVIDERS_SSO_PATHS } from "modules/auth/constants";
import companyApi from "../company.api";
import actions from "../redux/actions";
import { CURRENT_COMPANY_ID } from "../constants";

const useCompanyActions = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();

  const loadCompanies = async () => {
    const resp = await companyApi.getMyCompanies();

    if (!resp?.companies?.length) {
      localStorage.removeItem(CURRENT_COMPANY_ID);

      return null;
    }

    const { companies: userCompanies } = resp;

    dispatch(actions.setCompanies(userCompanies));
    if (userCompanies.length > 1) {
      dispatch(actions.setMultipleCompanyView(true));
    }

    const currentlySelectedCompanyId = localStorage.getItem(CURRENT_COMPANY_ID);

    const currentlySelectedCompany = userCompanies.find(
      ({ company }) => company.id === currentlySelectedCompanyId,
    );

    if (currentlySelectedCompany) {
      return userCompanies;
    }

    if (userCompanies.length === 1) {
      localStorage.setItem(CURRENT_COMPANY_ID, userCompanies[0].company.id);

      return userCompanies;
    }

    if (
      history.location.pathname !== "/select-company" &&
      history.location.pathname !== "/company-register" &&
      !SOCIAL_PROVIDERS_SSO_PATHS.includes(history.location.pathname)
    ) {
      localStorage.removeItem(CURRENT_COMPANY_ID);
      history.push(
        `/select-company?redirect=${history.location.pathname}${history.location.search}`,
      );
    }

    return resp.companies;
  };

  const getCurrentCompanyId = async () => {
    try {
      await loadCompanies();

      return localStorage.getItem(CURRENT_COMPANY_ID);
    } catch (error) {
      const errorMessage = error instanceof Error ? error.message : "";
      logger.error(`[getCurrentCompanyId] ${errorMessage}`);
    }

    return null;
  };

  return { loadCompanies, getCurrentCompanyId };
};

export default useCompanyActions;
