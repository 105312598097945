import { ref, get, update, onValue, off } from "firebase/database";
import { getCurrentEventId } from "helpers/reduxHelper";
import store from "store";
import { setIsAdmin } from "modules/auth/redux/authSlice";
import { database } from "../../services/firebaseService/firebaseConfig";
import { getReducerState } from "../../helpers/reduxHelper";
import { addEventDetails, updateEventSpeakers } from "./eventSlice";

const EVENT_PATH = "Event";

export const getEventPrefix = () => EVENT_PATH;

const getEventAdminsDocRef = (eventId: string) =>
  ref(database, `${getEventPrefix()}/${eventId}/Admins`);
export const getCurrentAdmin = async (eventId: string) => {
  const adminRef = getEventAdminsDocRef(eventId);
  const snapshot = await get(adminRef);
  const admins = snapshot.val();

  if (!Array.isArray(admins) || !admins.length) {
    return;
  }

  const { user, isManager } = getReducerState("auth");
  const isAdmin = admins.includes(user?.id) || isManager;

  store.dispatch(setIsAdmin(isAdmin));
};

export const changeEventStatus = async (eventId: string, status: string) => {
  if (!eventId) {
    return;
  }

  const eventRef = ref(database, `${getEventPrefix()}/${eventId}/`);
  await update(eventRef, { status });
};

export const listenForEventDetails = () => {
  const eventId = getCurrentEventId();

  if (!eventId) {
    return;
  }

  const eventRef = ref(database, `${getEventPrefix()}/${eventId}/Details/`);

  off(eventRef);
  onValue(eventRef, (snapshot) => {
    const eventData = snapshot.val();

    store.dispatch(addEventDetails({ ...eventData, id: eventId }));

    if (eventData) {
      store.dispatch(
        updateEventSpeakers({
          eventId,
          speakers: eventData.speakers,
        }),
      );
    }
  });
};

export const stopListenEventDetails = () => {
  const eventId = getCurrentEventId();

  if (!eventId) {
    return;
  }

  const eventRef = ref(database, `${getEventPrefix()}/${eventId}/Details/`);
  off(eventRef);
};
