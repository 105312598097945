import { Theme } from "@remo-co/ui-core/src/types";
import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";

export const useStyles = makeStyles<Theme>((theme) => ({
  icon: {
    height: 20,
    width: 20,
  },
  background: {
    width: "14px",
    height: "14px",
    background: theme.palette.blue.main,
    borderRadius: "4px",
    padding: "3px",
    marginRight: "4px",
  },
  interrupted: {
    position: "relative",
  },
  warningIcon: {
    color: theme.palette.yellow.dark,
    position: "absolute",
    height: 12,
    width: 12,
  },
}));
