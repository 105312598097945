import { ComponentProps } from "react";
import { Theme } from "@remo-co/ui-core/src/types";
import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";
import UserRow from "./UserRow";

export const useStyles = makeStyles<
  Theme,
  Partial<ComponentProps<typeof UserRow>>
>((theme) => ({
  row: {
    padding: "5.5px 10px",
    background: theme.palette.gray.lightShade,
    borderRadius: 5,
    border: `1px solid ${theme.palette.gray.light}`,
    cursor: "pointer",
    marginBottom: "5px",
  },
  rowBuffer: {
    margin: (props) => (props.size === "sm" ? "0px 8px" : "0px 15px"),
  },
  details: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  name: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  email: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  role: {
    marginLeft: "auto",
    fontSize: "12px",
    lineHeight: "16px",
    fontWeight: 800,
    padding: "2px 4px",
    borderRadius: 4,
    color: theme.palette.blue.dark,
    backgroundColor: theme.palette.gray.main,
    textTransform: "uppercase",
  },
}));

export default useStyles;
