import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";

const useStyles = makeStyles((theme) => ({
  title: {
    color: theme.palette.blue.dark,
    marginBottom: 10,
    fontWeight: 600,
  },
  subtitle: {
    color: theme.palette.blue.darkShade1,
  },
  content: {
    width: 260,
    textAlign: "left",
  },
}));

export default useStyles;
