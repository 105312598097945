import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { VirtualBackgrounds } from "../constants";
import { UserData } from "../hooks/useUserData/types";
import { VirtualBackgroundRequest, DevicePromptPopupState } from "../types";
import {
  DisplayLayout,
  TileDraft,
} from "../components/DailyContainer/Layouts/types";

export interface AudioVideoState {
  currentBackground: VirtualBackgroundRequest;
  userData: Partial<UserData>;
  devicePromptPopup: DevicePromptPopupState;
  layout: DisplayLayout;
  activeTile: TileDraft | null;
  isTileSelected: boolean;
  isLayoutSetManually: boolean;
  presentationSpeakersSessionIds: string[];
}

const initialState: AudioVideoState = {
  currentBackground: { type: VirtualBackgrounds.NONE },
  userData: {},
  devicePromptPopup: {
    visible: false,
  },
  layout: DisplayLayout.Map,
  activeTile: null,
  isTileSelected: false,
  isLayoutSetManually: false,
  presentationSpeakersSessionIds: [],
};

const slice = createSlice({
  name: "audioVideo",
  initialState,
  reducers: {
    setCurrentBackground: (
      state,
      action: PayloadAction<VirtualBackgroundRequest>,
    ) => {
      state.currentBackground = action.payload;
    },
    updateUserData: (state, action: PayloadAction<Partial<UserData>>) => {
      const { conversationId, originalAspectRatio, networkQuality, image } =
        action.payload;
      if (conversationId) {
        state.userData.conversationId = conversationId;
      }
      if (originalAspectRatio !== undefined && originalAspectRatio !== null) {
        state.userData.originalAspectRatio = originalAspectRatio;
      }
      if (networkQuality) {
        state.userData.networkQuality = networkQuality;
      }
      if (image) {
        state.userData.image = image;
      }
    },
    resetUserData: (state) => {
      state.userData = initialState.userData;
    },
    showDevicePromptPopup: (
      state,
      action: PayloadAction<
        Omit<
          Exclude<
            DevicePromptPopupState,
            {
              visible: false;
            }
          >,
          "visible"
        >
      >,
    ) => {
      state.devicePromptPopup = {
        ...action.payload,
        visible: true,
      } as DevicePromptPopupState;
    },
    hideDevicePromptPopup: (state) => {
      state.devicePromptPopup = { visible: false };
    },
    setLayout: (state, action: PayloadAction<DisplayLayout>) => {
      state.layout = action.payload;
      if (state.layout !== DisplayLayout.Focus) {
        state.activeTile = null;
      }
    },
    setActiveTile: (state, action: PayloadAction<TileDraft | null>) => {
      state.activeTile = action.payload;
    },
    setIsTileSelected: (state, action: PayloadAction<boolean>) => {
      state.isTileSelected = action.payload;
    },
    setIsLayoutManually: (state, action: PayloadAction<boolean>) => {
      state.isLayoutSetManually = action.payload;
    },
    setPresentationSpeakersSessionIds: (
      state,
      action: PayloadAction<string[]>,
    ) => {
      state.presentationSpeakersSessionIds = action.payload;
    },
  },
});

export const {
  setCurrentBackground,
  updateUserData,
  resetUserData,
  showDevicePromptPopup,
  hideDevicePromptPopup,
  setActiveTile,
  setLayout,
  setIsTileSelected,
  setIsLayoutManually,
  setPresentationSpeakersSessionIds,
} = slice.actions;

export default slice.reducer;
