import { ISlot } from "modules/theater/types";
import { ContentWithMapping } from "graphql/generated";
import { ContentMapping } from "../../types";

export const getDefaultMapping = (
  contents: ContentWithMapping[],
  floors: string[],
  slots: ISlot[],
  remainingLimit: number,
): ContentMapping => {
  if (floors.length <= remainingLimit) {
    // look for slots that can fit all floors
    const availableSlot = slots
      .map((_, index) => `${index}`)
      .find((slot) => !contents.some((content) => slot === content.slot));

    return {
      floors,
      slot: availableSlot || "0",
    };
  }

  // bit of a hackish shortcut to decide which floor is free, where we compare same-floor content entries with slot count
  // because otherwise it's a chicken-and-egg scenario:
  // will need to check all slots to find a free floor, and check all floors to find a free slot
  const availableFloor = floors.find(
    (floor) =>
      contents.filter((content) => floor === content.floor).length !==
      slots.length,
  );
  const availableSlot = slots
    .map((_, index) => `${index}`)
    .find(
      (slot) =>
        !contents.some(
          (content) =>
            slot === content.slot && availableFloor === content.floor,
        ),
    );

  return {
    floors: [availableFloor || floors[0]],
    slot: availableSlot || "0",
  };
};
