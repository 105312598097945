import i18n, { InitOptions } from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
import { getVar } from "config";
import { parse } from "query-string";
import { supportedLanguages } from "./constants";

const queryParams = parse(window.location.search);
let devMode = false;

if (queryParams?.devMode) {
  devMode = true;
}
// VERSION no added to cache burst json files that are loaded on fly
let options: InitOptions["backend"] = {
  queryStringParams: { v: getVar("REACT_APP_VERSION") as string },
};

if (devMode) {
  options = {
    ...options,
    loadPath: "/fake",
  };
}

i18n
  /*
     load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
     learn more: https://github.com/i18next/i18next-http-backend
    */
  .use(Backend)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    saveMissing: true,
    fallbackLng: "en",
    fallbackNS: "settings",
    lng: navigator.language,
    backend: options,
    supportedLngs: supportedLanguages,
    keySeparator: false, // we do not use keys in form messages.welcome
    ns: ["common"],
    defaultNS: "common",
    interpolation: {
      escapeValue: false, // react already safes from xss
      skipOnVariables: false,
    },
    react: {
      useSuspense: false,
    },
  });

export default i18n;
