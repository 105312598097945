import { TypePolicies } from "@apollo/client";

export const typePolicies: TypePolicies = {
  ContentWithMapping: {
    keyFields: false,
  },
  Query: {
    fields: {
      eventPolls: {
        // Don't cache separate results based on arguments
        // if this causes an issue during runtime, add a merge function
        keyArgs: false,
      },
    },
  },
};
