import {
  GetEventContentQuery,
  OnEventContentDeletedSubscription,
} from "graphql/generated";

interface DeletionSubscriptionData {
  subscriptionData: {
    data: OnEventContentDeletedSubscription;
  };
}

export const removeDeletedEventContent = (
  prev: GetEventContentQuery,
  { subscriptionData }: DeletionSubscriptionData,
): GetEventContentQuery => {
  if (prev.eventContent.__typename === "GQLError") {
    return prev;
  }

  const deletedContent = subscriptionData.data.onEventContentDeleted;

  if (!deletedContent) {
    return prev;
  }

  return {
    eventContent: {
      ...prev.eventContent,
      // if incoming content is from an update, filter out the existing version
      content: prev.eventContent.content.filter(
        (existingContent) =>
          !(
            existingContent.id === deletedContent.eventContent &&
            existingContent.floor === deletedContent.floor &&
            existingContent.slot === deletedContent.slot
          ),
      ),
    },
  };
};
