import { RootState } from "../../../store";

const selectEventDirectories = (state: RootState) => state.eventDirectories;

const selectCurrentEventDirectories = (state: RootState) =>
  state.eventDirectories.current;
const selectCompanyEventDirectories = (state: RootState) =>
  state.eventDirectories.company;

export {
  selectEventDirectories,
  selectCurrentEventDirectories,
  selectCompanyEventDirectories,
};
