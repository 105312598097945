import { useEffect } from "react";
import { useI18n } from "i18n";
import { Errors, trackError } from "modules/monitoring";
import useNotificationActions from "modules/notification/hooks/useNotificationActions";
import { useQuery } from "@tanstack/react-query";
import { getEventTableSettingPresets } from "modules/assignTables/apis";
import { EVENT_ASSIGN_TABLE_PRESETS_QUERY_KEY } from "../../constants";

export const useEventTableSettingPresets = (eventId?: string) => {
  const { t } = useI18n(["manageEvent"]);
  const { addErrorNotification } = useNotificationActions();
  // accommodating "" as a potential value here given how the ManageEventContext initializes this
  const isEventIdMissing = !eventId || eventId === "";

  const { data, status, isLoading, error } = useQuery({
    queryKey: [EVENT_ASSIGN_TABLE_PRESETS_QUERY_KEY, eventId],

    queryFn: async () => {
      if (isEventIdMissing) {
        return undefined;
      }

      const result = await getEventTableSettingPresets(eventId);

      if (result.message) {
        throw new Error(result.message);
      }

      return result.presets;
    },

    placeholderData: [],
    enabled: !isEventIdMissing,
  });

  useEffect(() => {
    if (!error) return;

    addErrorNotification({
      message: t("error.get.table.settings", {
        error: error.message,
      }),
      position: "tc",
    });

    trackError(error, {
      eventId,
      label: Errors.EVENT_TABLE_SETTING_PRESETS,
    });
  }, [error, addErrorNotification, t, eventId]);

  return {
    presets: data,
    isLoading,
    status,
  };
};
