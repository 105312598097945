import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export enum SideMenus {
  LOBBY = "lobby",
}

interface SidebarMenuState {
  activeMenu: SideMenus | "inactive";
}
export const initialState: SidebarMenuState = {
  activeMenu: "inactive",
};

const sidebarMenuSlice = createSlice({
  name: "participants",
  initialState,
  reducers: {
    setActiveMenu: (state, { payload }: PayloadAction<SideMenus>) => {
      state.activeMenu = state.activeMenu === payload ? "inactive" : payload;
    },
    closeMenu: (state) => {
      state.activeMenu = "inactive";
    },
  },
});

export const { setActiveMenu, closeMenu } = sidebarMenuSlice.actions;

export default sidebarMenuSlice.reducer;
