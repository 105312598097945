export const MAX_PAGE_SIZE_DESKTOP = 6;
export const MAX_PAGE_SIZE_MOBILE = 1;
export const MAX_PAGE_SIZE_GRID_MOBILE = 3;
export const MAX_PAGE_SIZE_GRID_DESKTOP = 12;
export const MAX_PAGE_SIZE_FOCUS_DESKTOP = 3;
export const MAX_PAGE_SIZE_FOCUS_MOBILE = 1;
export const MAX_MOBILE_HEIGHT = 900;
export const TILE_WIDTH = 200;
export const TILE_HEIGHT = 150;
export const FOCUS_MODE_TILE_WIDTH = 180;
export const FOCUS_MODE_TILE_HEIGHT = (FOCUS_MODE_TILE_WIDTH * 3) / 4;
// total because the values are the summation of forward and backward buttons
export const TOTAL_ARROW_BUTTON_HEIGHT = 53;
export const TOTAL_ARROW_BUTTON_WIDTH = 202;
