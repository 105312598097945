import { Button } from "@remo-co/ui-core/src/components/Button";
import { Container } from "@remo-co/ui-core/src/components/Container";
import { Select } from "@remo-co/ui-core/src/components/Select";
import { Typography } from "@remo-co/ui-core/src/components/Typography";
import { Divider } from "@remo-co/ui-core/src/components/Divider";
import { Close } from "@remo-co/ui-core/src/icons/Close";
import { useI18n } from "i18n";
import { useSelector } from "react-redux";
import { selectIsEnforceTableAssignmentScheduled } from "modules/enforceTableAssignment/redux";
import { AutosaveStatus } from "../AutosaveStatus";
import { AttendeeManagementActions } from "../AttendeeManagementActions";
import { UnassignAllGuestsButton } from "../UnassignAllGuestsButton";
import useStyles from "./styles";
import { PresetSelect } from "../PresetSelect";

interface Props {
  eventId: string;
  eventName?: string;
  floors: string[];
  floorNames: Record<string, string>;
  selectedFloorId: string;
  isUpdateLoading: boolean;
  updateStatus: string;
  handleFloorChange: (id: string) => void;
  handleClose: () => void;
  presets?: string[];
  selectedPreset: string;
  handlePresetChange: (preset: string) => void;
  // TODO since eventId is now passed to this component,
  // remove these two below as passed-down props, pass eventId instead
  // child components can call `useUpdateEventTableSettings` directly
  unassignAttendeesFromFloor: (floorId: string) => void;
  unassignAttendeesFromEvent: () => void;
  operationsLoading: boolean;
  createPreset: () => void;
  deletePreset: (presetId: string) => void;
  assignedAttendeesCount: number;
}

const TableManagerDialogHeader = ({
  eventId,
  eventName,
  selectedFloorId,
  floors,
  floorNames,
  isUpdateLoading,
  updateStatus,
  presets,
  selectedPreset,
  handlePresetChange,
  handleFloorChange,
  handleClose,
  unassignAttendeesFromFloor,
  unassignAttendeesFromEvent,
  operationsLoading,
  createPreset,
  deletePreset,
  assignedAttendeesCount,
}: Props): JSX.Element => {
  const isEnforceTableAssignmentScheduled = useSelector(
    selectIsEnforceTableAssignmentScheduled,
  );
  const { t } = useI18n(["common", "manageEvent"]);
  const styles = useStyles();

  return (
    <Container
      className={styles.header}
      flex
      justifyContent="space-between"
      alignItems="center"
    >
      <Container
        flex
        alignItems="center"
        justifyContent="flex-start"
        className={styles.toolbar}
      >
        <Typography variant="h5">
          {t("manageEvent:assign.tables.for")}
        </Typography>
        <Typography
          className={styles.eventName}
          variant="h5"
        >{`${eventName}`}</Typography>
        <Divider orientation="vertical" variant="middle" flexItem />
        <PresetSelect
          presets={presets}
          selectedPreset={selectedPreset}
          handlePresetChange={handlePresetChange}
          createPreset={createPreset}
          deletePreset={deletePreset}
          isLoading={operationsLoading}
          disabled={isEnforceTableAssignmentScheduled}
        />
        <Divider orientation="vertical" variant="middle" flexItem />
        <Select
          size="small"
          value={selectedFloorId}
          onChange={handleFloorChange}
          disabled={isEnforceTableAssignmentScheduled}
          options={floors.map((key, index) => ({
            value: key,
            key,
            label:
              floorNames[key] ||
              t("manageEvent:floor.number", {
                number: index + 1,
              }),
          }))}
          data-testid="floor-select"
        />
        <Divider orientation="vertical" variant="middle" flexItem />
        <UnassignAllGuestsButton
          unassignAttendeesFromFloor={() =>
            unassignAttendeesFromFloor(selectedFloorId)
          }
          unassignAttendeesFromEvent={unassignAttendeesFromEvent}
          disabled={isEnforceTableAssignmentScheduled}
        />
        <AttendeeManagementActions
          eventId={eventId}
          selectedPreset={selectedPreset}
          disabled={
            assignedAttendeesCount < 2 || isEnforceTableAssignmentScheduled
          }
        />
      </Container>
      <Container
        flex
        justifyContent="flex-end"
        className={styles.buttonsContainer}
      >
        <AutosaveStatus isLoading={isUpdateLoading} status={updateStatus} />
        <Button
          className={styles.closeButton}
          variant="ghost"
          color="dark"
          size="sm"
          onClick={handleClose}
          isIconButton
          data-testid="close-button"
        >
          <Close />
        </Button>
      </Container>
    </Container>
  );
};

export default TableManagerDialogHeader;
