import { IUser } from "modules/app/types";
import { getLinkedInInfo } from "./linkedInHelper";

export const ensureUrlHasProtocol = (url = ""): string => {
  if (!url) {
    return "";
  }

  const strippedOfWhitespaceUrl = url.replace(/\s/g, "");

  if (/^https?:\/\//i.test(strippedOfWhitespaceUrl)) {
    return strippedOfWhitespaceUrl;
  }

  return `https://${strippedOfWhitespaceUrl}`;
};

export const getFileSizeText = (size: number) => {
  // bytes
  if (size < 1024) {
    return `${size} B`;
  }
  const kb = size / 1024;

  if (kb < 1024) {
    return `${kb.toFixed(2)} KB`;
  }
  const mb = kb / 1024;

  if (mb < 1024) {
    return `${mb.toFixed(2)} MB`;
  }
  const gb = mb / 1024;

  return `${gb.toFixed(2)} GB`;
};

/**
 * If user does not have profile picture, then check if user has linkedin connected,
 * then show linkedin pic
 * https://remoapp.atlassian.net/browse/RC-870
 * @param user IUser
 */
export const getUserPic = (user: IUser) => {
  if (user.profile?.picture === "default") {
    const linkedinInfo = getLinkedInInfo(user);

    if (linkedinInfo?.pictureUrl) {
      return linkedinInfo.pictureUrl;
    }
  }

  return user.profile?.picture ?? null;
};
