import { ReducerActions } from "./actions";
import ActionTypes, { initialState } from "./constants";

const reducer = (state = initialState, action: ReducerActions) => {
  switch (action.type) {
    case ActionTypes.START_RECORDING:
      return {
        ...state,
        isRecording: true as const,
        archiveId: action.payload.archiveId,
        recordingStartedAt: action.payload.recordingStartedAt,
      };
    case ActionTypes.STOP_RECORDING:
      return {
        ...state,
        isRecording: false as const,
        recordingStartedAt: null,
      };
    case ActionTypes.IS_PROCESSING:
      return {
        ...state,
        isProcessing: action.payload.isProcessing,
      };
    case ActionTypes.IS_LOADING:
      return {
        ...state,
        isLoading: action.payload.isLoading,
      };
    case ActionTypes.RESET:
      return initialState;
    default:
      return state;
  }
};

export default reducer;
