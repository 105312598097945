import { useI18n } from "i18n";
import { Typography } from "@remo-co/ui-core/src/components/Typography";
import { Popper } from "@remo-co/ui-core/src/components/Popper";
import { Card } from "@remo-co/ui-core/src/components/Card";
import { useRef, useState } from "react";
import { Info } from "@remo-co/ui-core/src/icons/Info";
import { useStyles } from "./styles";
import { remoTicketFee, stripeTicketFee } from "../../constants";

export const TicketPriceFees = () => {
  const textRef = useRef<HTMLParagraphElement | null>(null);
  const [showPopover, setShowPopover] = useState(false);
  const { t } = useI18n(["eventForm"]);
  const styles = useStyles();

  const handleMouseEnter = () => {
    setShowPopover(true);
  };
  const handleMouseLeave = () => {
    setShowPopover(false);
  };

  return (
    <>
      <Typography
        ref={textRef}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        className={styles.text}
      >
        {t("eventForm:view.ticket.fees")}
      </Typography>
      <Popper
        open={showPopover}
        anchorEl={textRef.current}
        popperOptions={{
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [5, 5],
              },
            },
          ],
        }}
        placement="bottom-start"
        className={styles.popper}
      >
        <Card className={styles.container} size="sm">
          <Typography variant="body2" className={styles.noteTitle}>
            {t("eventForm:ticket.fees.title")}
          </Typography>
          <div className={styles.breakdown}>
            <Typography variant="body2" className={styles.breakdownText}>
              <div className={styles.indent}> </div>
              {t("eventForm:remo.fee")}
            </Typography>
            <Typography variant="body2" className={styles.breakdownText}>
              {remoTicketFee}
            </Typography>
          </div>
          <div className={styles.breakdown}>
            <Typography variant="body2" className={styles.breakdownText}>
              <div className={styles.indentPlus}>+</div>
              {t("eventForm:stripe.fee")}
            </Typography>
            <Typography
              variant="body2"
              className={styles.breakdownText}
            >{`${stripeTicketFee}*`}</Typography>
          </div>
          <Card className={styles.noteContainer} size="sm">
            <div className={styles.note}>
              <Info className={styles.noteIcon} />
              <Typography variant="body2" className={styles.noteText}>
                {t("eventForm:stripe.fee.note")}
              </Typography>
            </div>
          </Card>
        </Card>
      </Popper>
    </>
  );
};
