import { Box } from "@remo-co/ui-core/src/components/Box";
import { Typography } from "@remo-co/ui-core/src/components/Typography";
import { Tooltip } from "@remo-co/ui-core/src/components/Tooltip";
import { Button } from "@remo-co/ui-core/src/components/Button";
import { Add } from "@remo-co/ui-core/src/icons/Add";
import { useI18n } from "i18n";
import { useStyles } from "./styles";

interface Props {
  eventId?: string;
  showForm: () => void;
  unsavedCapacityChanges: boolean;
  hasSponsors: boolean;
}

export const NoContentPlaceholder = ({
  eventId,
  showForm,
  unsavedCapacityChanges,
  hasSponsors,
}: Props) => {
  const { t } = useI18n(["eventForm", "manageEvent"]);
  const styles = useStyles();

  const disableButton = !eventId || unsavedCapacityChanges || hasSponsors;

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      className={styles.root}
    >
      <Typography>{t("eventForm:event.content.placeholder")}</Typography>
      <Tooltip
        placement="top"
        title={
          !eventId
            ? t("manageEvent:menu.item.disable.text", {
                name: t("eventForm:event.content"),
              })
            : ""
        }
      >
        <span>
          <Button
            variant="secondary"
            color="blue"
            size="sm"
            className={styles.addContentButton}
            disabled={disableButton}
            contentClassName={styles.addContentButtonContent}
            onClick={showForm}
            data-testid="add-content-button"
          >
            <Add className={styles.addContentButtonIcon} />
            {t("eventForm:add.content")}
          </Button>
        </span>
      </Tooltip>
    </Box>
  );
};
