import { EventRole } from "modules/event";
import { IEventMemberDataResults } from "modules/eventMemberList/types";
import { getUserName } from "modules/userProfile";
import { SettingUser } from "../../types";

const filterAttendees = (
  attendeeList: IEventMemberDataResults[],
  assigned: SettingUser[],
  searchQuery: string,
): SettingUser[] =>
  attendeeList
    .filter(
      ({ user }) =>
        // user matches search query
        (user.email.includes(searchQuery) ||
          getUserName(user).includes(searchQuery)) &&
        // user is not found on assigned user array
        !assigned.some(
          (assignedAttendee) => assignedAttendee.email === user.email,
        ),
    )
    .map(({ user, role }) => ({
      email: user.email,
      role: role as EventRole,
      name: getUserName(user),
      id: user.id,
    }));

export default filterAttendees;
