import { IUser } from "modules/app/types";
import { getUserName } from "modules/userProfile";

export const startTranscriptionRequest = (
  websocket: WebSocket,
  user?: IUser,
  sampleRate?: number,
): void => {
  websocket.send(
    JSON.stringify({
      type: "start_request",
      meetingTitle: "Remo Transcription",
      insightTypes: [],
      config: {
        confidenceThreshold: 0.7,
        languageCode: "en-US",
        speechRecognition: {
          encoding: "LINEAR16",
          sampleRateHertz: sampleRate || 16000,
        },
      },
      speaker: {
        userId: `${user?.id}`,
        name: getUserName(user),
      },
      // these values ensure the websocket stays idle for a while even when all speakers send stop requests
      disconnectOnStopRequest: false,
      disconnectOnStopRequestTimeout: 1800,
      noConnectionTimeout: 1800,
    }),
  );
};
