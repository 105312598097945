import { useState } from "react";
import { Button } from "@remo-co/ui-core/src/components/Button";
import { Tooltip } from "@remo-co/ui-core/src/components/Tooltip";
import { Sync } from "@remo-co/ui-core/src/icons/Sync";
import { useI18n } from "i18n";
import {
  WaitForEnforceDialog,
  useNotifyBeforeEnforce,
} from "modules/enforceTableAssignment";
import { useAppDispatch } from "store/hooks";
import useStyles from "./styles";
import { setIsWaitForEnforceNoticeVisible } from "../../redux";

interface Props {
  eventId: string;
  selectedPreset: string;
  onClick: (onAssignmentCompleteClick: () => void) => void;
  disabled: boolean;
}

const MoveAllToAssignedTableButton = ({
  eventId,
  selectedPreset,
  onClick,
  disabled,
}: Props): JSX.Element => {
  const dispatch = useAppDispatch();
  const { notifyBeforeEnforce } = useNotifyBeforeEnforce(eventId);
  const { t } = useI18n(["common", "manageEvent"]);
  const styles = useStyles();
  const [showWaitForEnforceDialog, setShowWaitForEnforceDialog] =
    useState(false);

  const queueEnforce = async () => {
    await notifyBeforeEnforce();
    setShowWaitForEnforceDialog(true);
    dispatch(setIsWaitForEnforceNoticeVisible(true));
  };

  const handleClose = () => {
    setShowWaitForEnforceDialog(false);
    dispatch(setIsWaitForEnforceNoticeVisible(false));
  };

  return (
    <>
      <Tooltip
        title={
          disabled
            ? t("manageEvent:attendee.management.move.disabled")
            : t("manageEvent:move.attendees.to.assigned.tables")
        }
        placement="bottom"
        PopperProps={{
          className: styles.tooltip,
        }}
      >
        <span>
          <Button
            variant="secondary"
            color="dark"
            size="md"
            isIconButton
            onClick={() => onClick(queueEnforce)}
            data-testid="move-button"
            className={styles.button}
            disabled={disabled}
          >
            <Sync />
          </Button>
        </span>
      </Tooltip>
      {showWaitForEnforceDialog && (
        <WaitForEnforceDialog
          eventId={eventId}
          presetId={selectedPreset !== "default" ? selectedPreset : undefined}
          closeDialog={handleClose}
        />
      )}
    </>
  );
};

export default MoveAllToAssignedTableButton;
