import { Divider } from "@remo-co/ui-core/src/components/Divider";
import { Toolbar } from "@remo-co/ui-core/src/components/Toolbar";
import { CameraButton, MicrophoneButton } from "modules/eventButtons";
import { useIsInEvent } from "modules/event";
import { BlurButton } from "../BlurButton";
import { SettingsButton } from "../SettingsButton";

export const DeviceToggles = ({
  className,
  showSettings = false,
  showBlur = false,
}: {
  className?: string;
  showSettings?: boolean;
  showBlur?: boolean;
}) => {
  const isInEvent = useIsInEvent();

  return (
    <div className={className}>
      <Toolbar>
        <MicrophoneButton hideOptions askForConfirmation={isInEvent} />
        <CameraButton hideOptions askForConfirmation={isInEvent} />
        <Divider orientation="vertical" />
        {showBlur && <BlurButton />}
        {showSettings && <SettingsButton />}
      </Toolbar>
    </div>
  );
};
