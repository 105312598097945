import React from "react";
import { useAppDispatch } from "store/hooks";
import { push } from "connected-react-router";
import { useI18n } from "i18n";
import {
  addDialogNotification,
  closeDialogNotificationsByType,
} from "modules/dialogNotification/redux/dialogNotificationSlice";
import { DialogType } from "../dialogNotification/constants";

export interface IUpgradePlanProps {
  upgradeMessage: string | null;
  onClose: () => void;
  onUpgrade?: () => void;
}

const UpgradePlanPopup = ({
  upgradeMessage,
  onClose,
  onUpgrade,
}: IUpgradePlanProps) => {
  const { t } = useI18n();

  const dispatch = useAppDispatch();

  const onUpgradeConfirm = () => {
    if (onUpgrade) {
      onUpgrade();
    }
    setTimeout(() => {
      onClose();

      dispatch(push("/account-settings/billing", { open: true }));
    }, 0);
  };

  React.useEffect(() => {
    dispatch(closeDialogNotificationsByType(DialogType.UPGRADE_PLAN));

    if (upgradeMessage) {
      dispatch(
        addDialogNotification({
          message: upgradeMessage,
          hideCloseButton: true,
          confirmText: t("upgrade"),
          dismissText: t("button.ok"),
          onDismiss: onClose,
          onConfirm: onUpgradeConfirm,
          type: DialogType.UPGRADE_PLAN,
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [upgradeMessage]);

  return null;
};

export default UpgradePlanPopup;
