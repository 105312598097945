import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "store";

const selectBroadcastRaiseHand = (state: RootState) => state.broadcastRaiseHand;
const selectRaisedHandUsers = createSelector(
  selectBroadcastRaiseHand,
  (substate) => substate.raisedHandUsers,
);

const selectRaisedHandCount = createSelector(
  selectBroadcastRaiseHand,
  (substate) =>
    Object.values(substate.raisedHandUsers).filter(
      (isHandRaised) => isHandRaised.raised,
    ).length,
);

const makeIsUserRaisedHandByUserId = (userId: string) =>
  createSelector(
    selectRaisedHandUsers,
    (raisedHandUsers) => raisedHandUsers[userId]?.raised,
  );

export {
  selectRaisedHandUsers,
  selectRaisedHandCount,
  makeIsUserRaisedHandByUserId,
};
