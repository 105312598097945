import { useI18n } from "i18n";
import { Typography } from "@remo-co/ui-core/src/components/Typography";
import { Button } from "@remo-co/ui-core/src/components/Button";
import { Dialog } from "@remo-co/ui-core/src/components/Dialog";
import { DialogActions } from "@remo-co/ui-core/src/components/DialogActions";
import { DialogContent } from "@remo-co/ui-core/src/components/DialogContent";
import { DialogTitle } from "@remo-co/ui-core/src/components/DialogTitle";
import { IconButtonWithTooltip } from "@remo-co/ui-core/src/components/IconButtonWithTooltip";
import { Close } from "@remo-co/ui-core/src/icons/Close";
import { useStyles } from "./styles";

interface Props {
  handleSubmit: () => void;
  handleClose: () => void;
}

export const DeleteLiveStream = ({
  handleClose,
  handleSubmit,
}: Props): JSX.Element => {
  const { t } = useI18n(["common", "manageEvent"]);

  const styles = useStyles();

  return (
    <Dialog
      onClose={handleClose}
      fullWidth
      open
      classes={{
        paper: styles.root,
      }}
      data-testid="delete-live-stream-dialog"
    >
      <DialogTitle className={styles.header}>
        <Typography variant="h4">
          {t("manageEvent:delete.stream.confirmation")}
        </Typography>
        <IconButtonWithTooltip
          id="close-popup-dialog"
          title={t("common:button.close")}
          data-testid="cancel-button-icon"
          onClick={handleClose}
          size="small"
          className={styles.closeButton}
        >
          <Close />
        </IconButtonWithTooltip>
      </DialogTitle>
      <DialogContent>
        <Typography variant="body1" className={styles.dialogDescription}>
          {t("manageEvent:delete.stream.description")}
        </Typography>
      </DialogContent>
      <DialogActions className={styles.actions}>
        <Button
          variant="secondary"
          color="blue"
          onClick={handleClose}
          className={styles.button}
        >
          {t("common:button.cancel")}
        </Button>
        <Button
          variant="primary"
          color="blue"
          className={styles.button}
          onClick={handleSubmit}
        >
          {t("common:button.delete")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
