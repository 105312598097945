import { useI18n } from "i18n";
import { IUser, IUserProfile } from "modules/app/types";
import { ISignUpParams } from "modules/auth/types";
import userProfileApi from "../userProfile.api";

const useUserProfile = () => {
  const { t } = useI18n(["server"]);
  const getMyProfile = async () => {
    const resp = await userProfileApi.getMyProfile();

    if (resp.isSuccess) {
      return resp.user;
    }

    throw Error(t(resp.message));
  };

  const createProfile = async (data: Omit<ISignUpParams, "password">) => {
    const resp = await userProfileApi.createProfile(data);

    if (resp.isSuccess) {
      return resp.user;
    }

    throw Error(t(resp.message));
  };

  const createAnonymousUser = async (data: { name: string }) => {
    const resp = await userProfileApi.createAnonymousUser(data);

    if (resp?.user) {
      return resp.user;
    }

    if (resp?.message) {
      throw Error(t(resp?.message));
    }
    return null;
  };

  const updateMyProfile = async (
    data: Partial<IUser> | Partial<IUserProfile>,
  ) => {
    const resp = await userProfileApi.updateMyProfile(data);

    if (resp.isSuccess) {
      return resp.user;
    }

    throw Error(t(resp.message));
  };
  const getUserByEmail = async (data: string) => {
    const resp = await userProfileApi.getUserByEmail(data);

    if (resp.isSuccess) {
      return resp.user;
    }

    throw Error(t(resp.message));
  };

  const getUserClaims = async (token: string) => {
    const resp = await userProfileApi.getUserClaims(token);

    if (resp.isSuccess) {
      return resp.user;
    }

    throw Error(t(resp.message));
  };

  const syncUseridInClaims = async () => {
    const resp = await userProfileApi.syncUseridInClaims();

    if (resp.isSuccess) {
      return resp.user;
    }

    throw Error(t(resp.message));
  };

  return {
    getMyProfile,
    createProfile,
    updateMyProfile,
    getUserByEmail,
    getUserClaims,
    syncUseridInClaims,
    createAnonymousUser,
  };
};

export default useUserProfile;
