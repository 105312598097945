import ActionTypes from "./constants";

type ActionType = {
  type: ActionTypes.SHOW_CAM_AND_MIC_POPOVER;
  value: boolean;
};

const reducer = (state = false, action: ActionType) => {
  switch (action.type) {
    case ActionTypes.SHOW_CAM_AND_MIC_POPOVER:
      return action.value;
    default:
      return state;
  }
};

export default reducer;
