import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "store";

const selectMapNavigationState = (state: RootState) => state.mapNavigation;
const selectCurrentMapNavigation = createSelector(
  selectMapNavigationState,
  (substate) => substate.current,
);
const selectTargetMapNavigation = createSelector(
  selectMapNavigationState,
  (substate) => substate.target,
);
const selectLocatingMapNavigation = createSelector(
  selectMapNavigationState,
  (substate) => substate.locating,
);
const selectIsInitiallyLocated = createSelector(
  selectMapNavigationState,
  (substate) => substate.isInitiallyLocated,
);

export {
  selectCurrentMapNavigation,
  selectTargetMapNavigation,
  selectLocatingMapNavigation,
  selectIsInitiallyLocated,
};
