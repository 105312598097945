import { TableData, SettingUser } from "../../types";

export interface AttendeeTableUpdatePayload {
  attendee: SettingUser;
  floorId: string;
  tableId: string;
}

export type AttendeeTableMovePayload = AttendeeTableUpdatePayload & {
  previousTableId: string;
};

interface AttendeeTableUpdate {
  tableData: TableData;
  assignedAttendees: SettingUser[];
}

export const addToTable = (
  tableData: TableData,
  assignedAttendees: SettingUser[],
  payload: AttendeeTableUpdatePayload,
): AttendeeTableUpdate => {
  const { attendee, floorId, tableId } = payload;
  const floor = tableData[floorId];
  const table = floor[tableId];
  const attendeesInTable = table.users ?? [];

  return {
    tableData: {
      ...tableData,
      [floorId]: {
        ...floor,
        [tableId]: {
          ...table,
          users: [...attendeesInTable, attendee],
        },
      },
    },
    assignedAttendees: [...assignedAttendees, attendee],
  };
};

export const removeFromTable = (
  tableData: TableData,
  assignedAttendees: SettingUser[],
  payload: AttendeeTableUpdatePayload,
): AttendeeTableUpdate => {
  const { attendee, floorId, tableId } = payload;
  const floor = tableData[floorId];
  const table = floor[tableId];
  const attendeesInTable = (table.users ?? []).filter(
    (user) => user.email !== attendee.email,
  );

  return {
    tableData: {
      ...tableData,
      [floorId]: {
        ...floor,
        [tableId]: {
          ...table,
          users: attendeesInTable,
        },
      },
    },
    assignedAttendees: assignedAttendees.filter(
      (user) => user.email !== attendee.email,
    ),
  };
};

export const moveToTable = (
  tableData: TableData,
  assignedAttendees: SettingUser[],
  payload: AttendeeTableMovePayload,
): AttendeeTableUpdate => {
  const { attendee, floorId, tableId, previousTableId } = payload;
  const floor = tableData[floorId];
  const newTable = floor[tableId];
  const attendeesInNewTable = newTable.users ?? [];

  const previousTable = floor[previousTableId];
  const attendeesInPreviousTable = (previousTable.users ?? []).filter(
    (user) => user.email !== attendee.email,
  );

  return {
    tableData: {
      ...tableData,
      [floorId]: {
        ...floor,
        [tableId]: {
          ...newTable,
          users: [...attendeesInNewTable, attendee],
        },
        [previousTableId]: {
          ...previousTable,
          users: attendeesInPreviousTable,
        },
      },
    },
    assignedAttendees,
  };
};

export const unassignTable = (
  tableData: TableData,
  assignedAttendees: SettingUser[],
  floorId: string,
  tableId: string,
): AttendeeTableUpdate => {
  const floor = tableData[floorId];
  const table = floor[tableId];
  const attendeesInTable = table.users ?? [];

  return {
    tableData: {
      ...tableData,
      [floorId]: {
        ...floor,
        [tableId]: {
          ...table,
          users: [],
        },
      },
    },
    assignedAttendees: assignedAttendees.filter(
      (user) =>
        !attendeesInTable.some(
          (userInTable) => userInTable.email === user.email,
        ),
    ),
  };
};

export const unassignFloor = (
  tableData: TableData,
  assignedAttendees: SettingUser[],
  floorId: string,
): AttendeeTableUpdate => {
  const floor = tableData[floorId];
  let attendeesInFloor: SettingUser[] = [];
  const updatedFloor = Object.entries(floor).reduce(
    (record, [tableId, table]) => {
      attendeesInFloor = [...attendeesInFloor, ...(table.users ?? [])];
      return { ...record, [tableId]: { ...table, users: [] } };
    },
    {},
  );

  return {
    tableData: {
      ...tableData,
      [floorId]: updatedFloor,
    },
    assignedAttendees: assignedAttendees.filter(
      (user) =>
        !attendeesInFloor.some(
          (userInFloor) => userInFloor.email === user.email,
        ),
    ),
  };
};

export const unassignAll = (tableData: TableData): AttendeeTableUpdate => ({
  tableData: Object.entries(tableData).reduce(
    (floors, [floorId, floor]) => ({
      ...floors,
      [floorId]: Object.entries(floor).reduce(
        (tables, [tableId, table]) => ({
          ...tables,
          [tableId]: { ...table, users: [] },
        }),
        {},
      ),
    }),
    {},
  ),
  assignedAttendees: [],
});
