import { SwitchInput } from "@remo-co/ui-core/src/components/SwitchInput";
import { useI18n } from "i18n";
import { MANAGE_EVENT_CONTEXT } from "modules/manageEvent";
import { ChangeEvent, useContext } from "react";
import { useStyles } from "./styles";

export const EventDiscoveryOptOutCheckbox = () => {
  const { state, updateEventData } = useContext(MANAGE_EVENT_CONTEXT);
  const { eventData } = state ?? {};
  const { t } = useI18n(["eventForm"]);
  const { isUnlimitedEvent } = eventData ?? {};

  const styles = useStyles();

  const isDiscoveryNotApplicable = eventData?.isPrivate;

  const handleDiscoveryOptOutChange = (
    _: ChangeEvent<HTMLInputElement>,
    discoveryEnabled: boolean,
  ) => {
    updateEventData({ isDiscoveryOptedOut: !discoveryEnabled });
  };

  return (
    <SwitchInput
      id="discovery-toggle"
      data-testid="discovery-toggle"
      className={styles.root}
      label={t("eventForm:event.discovery.checkbox")}
      checked={
        isDiscoveryNotApplicable || isUnlimitedEvent
          ? false
          : !eventData?.isDiscoveryOptedOut
      }
      disabled={isDiscoveryNotApplicable}
      onChange={handleDiscoveryOptOutChange}
    />
  );
};
