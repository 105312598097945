export const isValidEmailAddress = (email: string) => {
  if (!email || typeof email !== "string") {
    return false;
  }
  const emailPattern =
    /^[a-z0-9!#$%&'*+\-/=?^_`{|}~.]+@([a-z0-9%-]+\.){1,}([a-z0-9-]+)?$/i;
  const emailMatches = email.match(emailPattern);

  return !!emailMatches && emailMatches.length > 0;
};

export default isValidEmailAddress;
