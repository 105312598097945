import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export const initialState = {
  // from firebase
  rounds: 6,
  roundDuration: 10,
  breakDuration: 1,
  startedAt: -1,
  // local state
  setupActive: false,
};

export type TimerParams = typeof initialState;

const timerSlice = createSlice({
  initialState,
  name: "timer",
  reducers: {
    setupTimer: (
      state,
      action: PayloadAction<Omit<typeof initialState, "setupActive">>,
    ) => ({ ...state, ...action.payload }),
    resetTimer: (state) => {
      state.startedAt = -1;
    },
    toggleTimerSetupActive: (state) => {
      state.setupActive = !state.setupActive;
    },
    timerSetupActive: (state) => {
      state.setupActive = true;
    },
    timerSetupInactive: (state) => {
      state.setupActive = false;
    },
  },
});

export const {
  setupTimer,
  resetTimer,
  toggleTimerSetupActive,
  timerSetupActive,
  timerSetupInactive,
} = timerSlice.actions;

export default timerSlice.reducer;
