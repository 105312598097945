import { sendGetRequest } from "services/apiService";

type IGetTemplatesAPIResponse = {
  isSuccess: boolean;
  privateTemplateCodes: [];
};

const getTemplates = (companyId: string): Promise<IGetTemplatesAPIResponse> =>
  sendGetRequest<IGetTemplatesAPIResponse>(
    `/company-event/${companyId}/event-templates/all`,
  );

export default {
  getTemplates,
};
