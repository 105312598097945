import { Theme } from "@remo-co/ui-core/src/types";
import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";

export const useStyles = makeStyles<Theme>(() => ({
  networkingButtons: {
    display: "flex",
    gap: "5px",
    alignItems: "center",
  },
  requestContactIfo: {
    fontWeight: 800,
    marginLeft: "15px",
    cursor: "pointer",
  },
}));
