import { RootState } from "store";
import { createSelector } from "@reduxjs/toolkit";

const selectHelpChatEvent = (state: RootState) => state.helpChatEvent;

const selectIsLoading = createSelector(
  selectHelpChatEvent,
  (substate) => substate.isLoading,
);

export { selectHelpChatEvent, selectIsLoading };
