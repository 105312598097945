import { Switch } from "@remo-co/ui-core/src/components/Switch";
import { InfoRounded } from "@remo-co/ui-core/src/icons/InfoRounded";
import { Box } from "@remo-co/ui-core/src/components/Box";
import { Typography } from "@remo-co/ui-core/src/components/Typography";
import { Tooltip } from "@remo-co/ui-core/src/components/Tooltip";
import { Link } from "@remo-co/ui-core/src/components/Link";
import { ChangeEvent } from "react";
import { useI18n } from "i18n";
import { useStyles } from "./styles";

interface Props {
  handleNetworkRecommendationSwitch: (
    e: ChangeEvent<HTMLInputElement>,
    checked: boolean,
  ) => void;
  isNetworkingRecommendationsEnabled: boolean;
  disabled: boolean;
}

export const NetworkingRecommendationsSwitch = ({
  handleNetworkRecommendationSwitch,
  isNetworkingRecommendationsEnabled,
  disabled,
}: Props): JSX.Element => {
  const { t } = useI18n(["eventForm"]);

  const styles = useStyles();

  return (
    <Box className={styles.switchBox}>
      <Typography variant="body1">
        {t("eventForm:networking.recommendations")}
      </Typography>
      <Tooltip
        title={
          <>
            <Typography variant="body1" className={styles.tooltipText}>
              {t("eventForm:networking.recommendations.info.tooltip")}
            </Typography>
            <Typography variant="body1" className={styles.tooltipLinkText}>
              <Link href={t("url:networking.recommendation")} openInNewTab>
                {t("eventForm:networking.recommendations.info.linkText")}
              </Link>
            </Typography>
          </>
        }
        arrow
        placement="bottom"
        disableHoverListener={false}
        classes={{ tooltip: styles.tooltip }}
      >
        <InfoRounded color="primary" fontSize="small" data-testid="info-icon" />
      </Tooltip>
      <Switch
        onChange={handleNetworkRecommendationSwitch}
        checked={isNetworkingRecommendationsEnabled}
        data-testid="network-recommendations-switch"
        disabled={disabled}
      />
    </Box>
  );
};
