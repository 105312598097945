import { useContext } from "react";
import classnames from "classnames";
import { useI18n } from "i18n";
import { useHistory } from "react-router-dom";
import { Box } from "@remo-co/ui-core/src/components/Box";
import { Button } from "@remo-co/ui-core/src/components/Button";
import { Typography } from "@remo-co/ui-core/src/components/Typography";
import { MANAGE_EVENT_CONTEXT } from "modules/manageEvent";
import { EVENT_OUTCOMES } from "modules/eventForm/constants";
import withDialog from "modules/eventLanding/eventRegistration/withDialog";
import { EventTypeSelect } from "../../prepare";
import { useStyles } from "./styles";

// To avoid typescript string literal disallow issues
const FLEX_WRAP = "wrap";
const DART_EMOJI = "🎯";

const SetOutcome = (): JSX.Element | null => {
  const history = useHistory();
  const styles = useStyles();
  const { t } = useI18n(["common", "eventForm", "manageEvent"]);
  const { state, updateEventData, saveEvent } =
    useContext(MANAGE_EVENT_CONTEXT);

  const eventData = state?.eventData ?? undefined;

  const handleEventOutcomeTabClick = (evtOutcome: string) => () => {
    updateEventData({ eventOutcome: evtOutcome });
  };

  const handlePublishEventClick = async () => {
    const event = await saveEvent();

    if (event) {
      // TODO: Remove this setTimeout and Prompt logic in ManageEventContext after react-router v6 upgrade
      setTimeout(() => {
        history.push("/my-events");
      }, 100);
    }
  };

  const isPublishButtonDisabled = !(
    eventData?.eventType && eventData?.eventOutcome
  );

  return (
    <Box className={styles.container} data-testid="set-outcome">
      <Box mb={2}>
        <Typography variant="h2" className={styles.title}>
          <span role="img" aria-label="dart">{`${DART_EMOJI} `}</span>

          {t("eventForm:event.outcome.dialog.title")}
        </Typography>
        <Typography className={styles.description}>
          {t("eventForm:event.outcome.description")}
        </Typography>
      </Box>
      <Box mb={4} display="flex" flexWrap={FLEX_WRAP} alignItems="center">
        {EVENT_OUTCOMES.map(({ value, label }) => (
          <button
            role="switch"
            aria-checked={value === eventData?.eventOutcome}
            key={value}
            type="button"
            className={classnames(styles.clickableTab, {
              [styles.clickableTabSelected]: eventData?.eventOutcome === value,
            })}
            onClick={handleEventOutcomeTabClick(value)}
          >
            {t(label)}
          </button>
        ))}
      </Box>

      <EventTypeSelect />

      <Button
        data-testid="publish-button"
        variant="primary"
        color="blue"
        fullWidth
        size="md"
        onClick={handlePublishEventClick}
        disabled={isPublishButtonDisabled}
      >
        {t("manageEvent:save.publish.event")}
      </Button>
    </Box>
  );
};

export default withDialog(SetOutcome);
