import { createSlice } from "@reduxjs/toolkit";

interface UserProfileWindow {
  open: boolean;
}

export const initialState: UserProfileWindow = {
  open: false,
};

const userProfileWindowSlice = createSlice({
  name: "userProfileWindow",
  initialState,
  reducers: {
    openUserProfileWindow: (state) => {
      state.open = true;
    },
    closeUserProfileWindow: (state) => {
      state.open = false;
    },
  },
});

export const { openUserProfileWindow, closeUserProfileWindow } =
  userProfileWindowSlice.actions;

export default userProfileWindowSlice.reducer;
