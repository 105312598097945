import { selectCurrentEventId } from "modules/event/selectors";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";

export const useIsInEvent = (): boolean => {
  const {
    location: { pathname },
  } = useHistory();
  const eventId = useSelector(selectCurrentEventId);

  return pathname !== "/mic-cam-check" && !!eventId;
};
