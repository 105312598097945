import logger from "logging/logger";
import { DatabaseReference, DataSnapshot, get } from "firebase/database";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const asyncResult = (ref: DatabaseReference): Promise<any> =>
  new Promise((resolve, reject) => {
    get(ref)
      .then((result: DataSnapshot) => resolve(result.val()))
      .catch((error) => {
        logger.error(error);
        reject(error);
      });
  });
