import { useEffect, useRef } from "react";

const useClickOutside = (callback?: (e?: MouseEvent) => void) => {
  const clickOutsideRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (!callback) {
      return;
    }

    const onClickOutside = (e: MouseEvent) => {
      if (
        clickOutsideRef.current &&
        clickOutsideRef.current !== e.target &&
        !clickOutsideRef.current.contains(e.target as HTMLElement)
      ) {
        callback(e);
      }
    };

    document.addEventListener("click", onClickOutside);

    // eslint-disable-next-line consistent-return
    return () => document.removeEventListener("click", onClickOutside);
  }, [callback]);

  return clickOutsideRef;
};

export default useClickOutside;
