import { Theme } from "@remo-co/ui-core/src/types";
import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";

export const useStyles = makeStyles<Theme>((theme) => ({
  guestList: {
    height: "10rem",
    overflowY: "scroll",
  },
  guest: {
    marginBottom: theme.spacing(1),
    height: "2rem",
  },
}));
