import { QueryClient } from "@tanstack/react-query";
import { sendGetRequest } from ".";

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      // eslint-disable-next-line consistent-return
      queryFn: async ({ queryKey: [endpoint] }) => {
        if (typeof endpoint === "string") {
          const response = await sendGetRequest<IGenericAPIResponse>(endpoint);

          if (response.isSuccess) {
            return response;
          }

          throw new Error(response.error ?? response.message);
        }
      },
    },
  },
});
