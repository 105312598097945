import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles(() => ({
  container: {
    width: "100%",
    height: "100%",
  },
}));

export default useStyles;
