import { IUserRoomState } from "../../redux/roomSlice";

const getRoomId = (
  userId: string,
  userRoomStatus: IUserRoomState,
): string | null => {
  const foundUser = Object.entries(userRoomStatus).find(([_roomId, room]) =>
    Object.keys(room).includes(userId),
  );

  if (!foundUser) {
    return null;
  }

  const [roomId] = foundUser;

  return roomId;
};

export default getRoomId;
