enum ActionTypes {
  HIDE_INIT_SHUFFLE_BANNER = "app/shuffleAttendees/HIDE_INIT_SHUFFLE_BANNER",
  HIDE_SHUFFLE_ATTENDEES_CNTDWN_BANNER = "app/shuffleAttendees/HIDE_SHUFFLE_ATTENDEES_CNTDWN_BANNER",
  HIDE_SHUFFLE_ATTENDEES_CNTDWN_DIALOG = "app/shuffleAttendees/HIDE_SHUFFLE_ATTENDEES_CNTDWN_DIALOG",
  HIDE_SHUFFLE_ATTENDEES_DIALOG = "app/shuffleAttendees/HIDE_SHUFFLE_ATTENDEES_DIALOG",
  SET_SHUFFLE_START_TIME = "app/shuffleAttendees/SET_SHUFFLE_START_TIME",
  SHOW_INIT_SHUFFLE_BANNER = "app/shuffleAttendees/SHOW_INIT_SHUFFLE_BANNER",
  SHOW_SHUFFLE_ATTENDEES_CNTDWN_BANNER = "app/shuffleAttendees/SHOW_SHUFFLE_ATTENDEES_CNTDWN_BANNER",
  SHOW_SHUFFLE_ATTENDEES_CNTDWN_DIALOG = "app/shuffleAttendees/SHOW_SHUFFLE_ATTENDEES_CNTDWN_DIALOG",
  SHOW_SHUFFLE_ATTENDEES_DIALOG = "app/shuffleAttendees/SHOW_SHUFFLE_ATTENDEES_DIALOG",
}

export default ActionTypes;
