import { useContext } from "react";
import { Container } from "@remo-co/ui-core/src/components/Container";
import { Typography } from "@remo-co/ui-core/src/components/Typography";
import { useI18n } from "i18n";
import { ImageUploader } from "modules/uploader";
import {
  EVENT_CUSTOM_BRANDING,
  UPLOAD_LEVEL,
} from "services/firebaseService/storagePaths";
import { Info } from "@remo-co/ui-core/src/icons/Info";
import { Avatar } from "modules/avatar";
import useCompany from "modules/company/hooks/useCompany";
import { Actions, trackAction } from "modules/monitoring";
import { Events, TRACKING_CONTEXT } from "modules/tracking";
import useNotificationActions from "modules/notification/hooks/useNotificationActions";
import { IEvent } from "modules/event/types";
import { useStyles } from "./styles";

interface IBrandingSettingsProps {
  eventData: IEvent;
  onEventDataChange: (data: Partial<IEvent>) => void;
}

const EventCustomBrandingSettings = ({
  eventData,
  onEventDataChange,
}: IBrandingSettingsProps) => {
  const classes = useStyles();
  const { t } = useI18n(["common", "eventForm"]);
  const { company } = useCompany();
  const { addErrorNotification } = useNotificationActions();
  const { track } = useContext(TRACKING_CONTEXT);
  const isEnabled = company?.enabledWhiteLabel;
  const logoURL = eventData?.eventBrandingLogoURL || company?.logoURL;
  const buttonText = eventData?.eventBrandingLogoURL
    ? t("button.change.image")
    : t("button.upload.new.image");

  const onLogoFieldChange = (eventCustomBrandingURL: string) => {
    onEventDataChange({
      eventBrandingLogoURL: eventCustomBrandingURL,
    });

    const metadata = {
      logoURL: eventCustomBrandingURL,
      event: eventData.name, // may or may not be filled out by the time this event is sent
    };

    track(Events.EVENT_BRANDING_LOGO_CHANGE, metadata);
    trackAction(Actions.EVENT_BRANDING_LOGO_CHANGE_SUCCESS, metadata);
  };

  const onLogoFieldChangeError = (error: Error | null) => {
    trackAction(Actions.EVENT_BRANDING_LOGO_CHANGE_FAILED, {
      error,
    });
    addErrorNotification({
      message: t("eventForm:upload.image.error"),
    });
  };

  return (
    <Container>
      <Typography variant="h3">
        {t("eventForm:customize.event.branding.title")}
      </Typography>
      <Typography variant="body1" className={classes.description}>
        {t("eventForm:customize.event.branding.description")}
      </Typography>
      {!isEnabled && (
        <Container className={classes.warning}>
          <Info className={classes.icon} />
          <Typography className={classes.text}>
            {t("eventForm:customize.event.branding.warning")}
          </Typography>
        </Container>
      )}
      <Container className={classes.imageUploadCtn}>
        <Avatar
          pictureUrl={logoURL}
          className={classes.avatar}
          data-testid="event-custom-branding-avatar"
        />
        <ImageUploader
          name="eventCustomBranding"
          level={UPLOAD_LEVEL.EVENT}
          path={EVENT_CUSTOM_BRANDING}
          onImageUploaded={onLogoFieldChange}
          onError={onLogoFieldChangeError}
          disabled={!isEnabled}
          variant="secondary"
          color="blue"
          size="md"
          className={classes.imageUploadBtn}
          data-testid="event-custom-branding-upload-btn"
        >
          {buttonText}
        </ImageUploader>
      </Container>
      <Typography variant="body1" className={classes.format}>
        {t("image.upload.format", {
          key0: "",
          key1: "1MB",
          key2: "480x480",
        })}
      </Typography>
    </Container>
  );
};

export default EventCustomBrandingSettings;
