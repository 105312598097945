import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "store";

const selectEmbedContent = (state: RootState) => state.embedContent;

const selectContentInQueueConversationMode = createSelector(
  selectEmbedContent,
  (substate) =>
    substate.conversationModeContent
      .filter(
        (content) =>
          !substate.conversationModeViewedContentIDs.includes(content.id),
      )
      .sort((a, b) => a.createdAt.localeCompare(b.createdAt)),
);

const selectDisplayedContentConversationMode = createSelector(
  selectContentInQueueConversationMode,
  (contentInQueue) => (contentInQueue.length ? contentInQueue[0] : null),
);

const selectHasContentQueueConversationMode = createSelector(
  selectContentInQueueConversationMode,
  (contentInQueue) => contentInQueue.length > 1,
);

const selectPresentationModeContent = createSelector(
  selectEmbedContent,
  (substate) => substate.presentationModeContent,
);

export {
  selectDisplayedContentConversationMode,
  selectHasContentQueueConversationMode,
  selectPresentationModeContent,
};
