import { ContentMapping, ContentWithConsolidatedMapping } from "../../types";

// written with per-floor one-slot in mind, rewrite when we support per-floor per-slot granularity
export const getMappingLocation = (
  content: ContentWithConsolidatedMapping,
  floors: string[],
): ContentMapping => ({
  floors:
    // assume 'all floors' if mapping has same length as floors as a shortcut
    content.mappings.length === floors.length
      ? floors
      : content.mappings.map((mapping) => mapping.floor),
  slot: content.mappings[0].slot,
});
