import { useEffect } from "react";
import { pageAccessedByReload } from "helpers/pageAccessedByReload";
import { Actions } from "../../actions";
import { trackAction } from "../../helpers/trackActions";

const TRACKING_DELAY = 1000;

export const TrackRefreshes = () => {
  useEffect(() => {
    const isLoaded = sessionStorage.getItem("loaded");
    const isReloaded = pageAccessedByReload();

    if (isReloaded && isLoaded === "true") {
      setTimeout(() => {
        trackAction(Actions.REFRESHED);
      }, TRACKING_DELAY);
    } else if (!isLoaded) {
      sessionStorage.setItem("loaded", "true");
    }

    return () => {
      sessionStorage.removeItem("loaded");
    };
  }, []);

  return null;
};
