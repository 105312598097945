import { Theme } from "@mui/material/styles";

import makeStyles from "@mui/styles/makeStyles";

export const useStyles = makeStyles<Theme>((theme) => ({
  root: () => ({
    fontFamily: theme.typography.fontFamily,
    cursor: "pointer",
    color: theme.palette.blue.darkShade1,
  }),
}));
