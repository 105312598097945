import { QuestionType } from "modules/registration/constants";
import SingleLineSVG from "./assets/single-line.svg";
import ParagraphSVG from "./assets/paragraph.svg";
import SingleSelectSVG from "./assets/single-select.svg";
import MultiSelectSVG from "./assets/multi-select.svg";

export const getQuestionIcon = (type: QuestionType) => {
  switch (type) {
    case QuestionType.SINGLE_LINE_TEXT:
      return SingleLineSVG;
    case QuestionType.PARAGRAPH_TEXT:
      return ParagraphSVG;
    case QuestionType.SINGLE_SELECT:
      return SingleSelectSVG;
    case QuestionType.MULTI_SELECT:
    default:
      return MultiSelectSVG;
  }
};
