import { Link } from "@remo-co/ui-core/src/components/Link";
import { Theme } from "@remo-co/ui-core/src/types";
import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";
import { useI18n } from "i18n";

const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    color: theme.palette.green.main,
    textDecoration: "underline",
  },
}));

const RemoHelpLink = ({ endpoint }: { endpoint: string }) => {
  const { t } = useI18n(["common", "customFloorPlan"]);
  const classNames = useStyles();

  return (
    <>
      <Link
        href={`https://help.remo.co/en/support/solutions/articles/${endpoint}`}
        openInNewTab
        data-testid="cfp-help-link"
        className={classNames.root}
      >
        {t("click.here") as string}
      </Link>{" "}
      {t("to.refer")}
    </>
  );
};

export const RemoCFPLinkEndpoints = {
  CFP: "63000250123-how-to-apply-a-custom-floor-plan-",
  apply: "63000250123-how-to-apply-a-custom-floor-plan-",
  create: "63000252840-how-to-create-a-custom-floor-plan",
};

export default RemoHelpLink;
