import { StatefulDevice } from "@daily-co/daily-react";
import { SerializedDevice } from "modules/deviceInfo";

export interface StatefulSerializedDevice extends SerializedDevice {
  active: boolean;
}

export const toSerializedDevice = (
  device: StatefulDevice,
): StatefulSerializedDevice => ({
  deviceId: device.device.deviceId,
  label: device.device.label,
  active: device.selected,
});
