import { Map } from "immutable";
import { IUserStatus } from "types";
import ActionTypes from "./constants";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const updateUserStatus = (userStatus: IUserStatus | any) => ({
  payload: userStatus,
  type: ActionTypes.UPDATE_USER_STATUS,
});

const syncUserStatus = (userStatuses: Map<string, IUserStatus>) => ({
  payload: userStatuses,
  type: ActionTypes.SYNC_USER_STATUS,
});

const removeUserStatus = (userId: string) => ({
  payload: userId,
  type: ActionTypes.REMOVE_USER_STATUS,
});

const resetUserStatus = () => ({
  payload: {},
  type: ActionTypes.RESET,
});

export default {
  updateUserStatus,
  syncUserStatus,
  removeUserStatus,
  resetUserStatus,
};
