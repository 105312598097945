import {
  GetEventFloorContentQuery,
  OnEventFloorContentChangedSubscription,
} from "graphql/generated";

interface UpdateSubscriptionData {
  subscriptionData: {
    data: OnEventFloorContentChangedSubscription;
  };
}

export const updateEventFloorContentList = (
  prev: GetEventFloorContentQuery,
  { subscriptionData }: UpdateSubscriptionData,
): GetEventFloorContentQuery => {
  if (prev.eventFloorContent.__typename === "GQLError") {
    return prev;
  }

  const newOrUpdatedContent = subscriptionData.data.onEventFloorContentChanged;

  if (!newOrUpdatedContent) {
    return prev;
  }

  return {
    eventFloorContent: {
      ...prev.eventFloorContent,
      // if incoming content is from an update, filter out the existing version
      content: [
        ...prev.eventFloorContent.content.filter(
          (existingContent) =>
            !(
              existingContent.id === newOrUpdatedContent.id &&
              existingContent.slot === newOrUpdatedContent.slot
            ),
        ),
        newOrUpdatedContent,
      ],
    },
  };
};
