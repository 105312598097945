import { selectCurrentTheaterId } from "modules/theater/selectors";
import { useEffect } from "react";
import { useQueryClient } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import { off, onValue } from "firebase/database";
import { PRESENTERS_CACHE_KEY } from "./constants";
import { getPresentersDbRef } from "./dal";

export const useActivePresenterSubscription = () => {
  const theaterId = useSelector(selectCurrentTheaterId);
  const queryClient = useQueryClient();

  useEffect(() => {
    if (!theaterId) {
      return undefined;
    }

    const dbRef = getPresentersDbRef(theaterId);

    off(dbRef);
    onValue(dbRef, async (snap) => {
      const data = await snap.val();
      if (!data) return;
      queryClient.setQueryData([PRESENTERS_CACHE_KEY, theaterId], () =>
        Object.keys(data),
      );
    });

    return () => {
      off(dbRef);
    };
  }, [theaterId, queryClient]);
};
