import { Transcription } from "@daily-co/daily-react";
import moment from "moment";
import { useSelector } from "react-redux";
import { Typography } from "@remo-co/ui-core/src/components/Typography";
import { makeSelectUserById } from "modules/event/usersInEvent/selectors";
import Avatar from "modules/userAvatar/Avatar";
import useStyles from "./styles";

interface Props {
  transcript: Transcription;
}

export const TranscriptionSidebarMessage = ({ transcript }: Props) => {
  const styles = useStyles();
  // @ts-expect-error type issue with @daily-co/daily-react
  const { user_id: userId, user_name: userName, text, timestamp } = transcript;
  const user = useSelector(makeSelectUserById(userId));

  return (
    <div className={styles.root} data-testid="transcription-sidebar-message">
      <div className={styles.messageUser}>
        <Avatar user={user} className={styles.avatar} />
        <Typography variant="h6">{userName}</Typography>
      </div>
      <div className={styles.messageBody}>
        <Typography variant="caption" className={styles.messageTime}>
          {moment(timestamp).format("LLLL")}
        </Typography>
        <Typography variant="h6" className={styles.messageText}>
          {text}
        </Typography>
      </div>
    </div>
  );
};
