import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";

const useStyles = makeStyles(() => ({
  section: {
    marginBottom: "5px",
  },
  innerMenuCollapse: {
    paddingLeft: "36px",
  },
  innerMenuList: {
    padding: 0,
  },
}));

export default useStyles;
