import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";

const useStyles = makeStyles(() => ({
  searchInput: {
    fontSize: "15px",
    borderRadius: "18px",
  },
  searchInputAdornment: {
    paddingLeft: "10px",
    color: "var(--Grey)",
    marginRight: 0,
  },
}));

export default useStyles;
