export const styles = {
  noConnectionImage: {
    width: "133px",
    height: "120px",
    opacity: 0.5,
  },
  imageContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "20px",
  },
  noConnectionContainer: {
    padding: "16px",
    margin: "16px",
    textAlign: "center",
    gap: "20px",
    borderRadius: "10px",
    border: "1px solid #EAEEF2",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    color: "#556176",
    backgroundColor: "#F9FAFB",
  },
  noMatches: {
    marginBottom: "10px",
    fontWeight: "bold",
  },
  list: {
    textAlign: "left",
    paddingLeft: "20px",
    margin: 0,
  },
  showAllParticipants: {
    height: "36px",
    marginTop: "15px",
    color: "#FFFFFF",
    backgroundColor: "#3B82F6",
    borderRadius: "5px",
    fontWeight: "bold",
    "&:hover": {
      backgroundColor: "#2563EB",
    },
  },
};
