import { memo } from "react";
import { useSelector } from "react-redux";
import { selectUser } from "modules/auth/redux/selectors";
import { Box } from "@remo-co/ui-core/src/components/Box";
import classNames from "classnames";
import { selectCurrentEvent } from "modules/event/selectors";
import { selectOnlineUsers } from "modules/presence/presence.selectors";
import { ChatList, ChatMessagesView } from "../../components";
import { makeSelectActiveChannel } from "../../redux/selectors";
import useStyles from "./styles";

const ChatContainer = (): JSX.Element | null => {
  const styles = useStyles();
  const user = useSelector(selectUser);
  const event = useSelector(selectCurrentEvent);
  const activeChannel = useSelector(makeSelectActiveChannel);
  const onlineUsers = useSelector(selectOnlineUsers);

  if (user && event) {
    return (
      <Box className={classNames(styles.chatContainer)}>
        {activeChannel ? (
          <ChatMessagesView />
        ) : (
          <ChatList onlineUsers={onlineUsers} />
        )}
      </Box>
    );
  }

  return null;
};

export default memo(ChatContainer);
