import { validateURL } from "helpers/urlHelper";
import { ISponsor } from "modules/event/types";

export const isCtaLinkValid = (sponsor: ISponsor) =>
  sponsor.ctaLink && validateURL(sponsor.ctaLink);

export const isCtaLabelValid = (sponsor: ISponsor) =>
  sponsor.ctaLabel && sponsor.ctaLabel.length <= 30;

export const isCtaLinkAndLabelValid = (sponsor?: ISponsor) => {
  if (sponsor?.ctaLabel && sponsor?.ctaLink) {
    return isCtaLabelValid(sponsor) && isCtaLinkValid(sponsor);
  }
  if (sponsor?.ctaLink === "" && sponsor?.ctaLabel === "") {
    return true;
  }

  return false;
};
