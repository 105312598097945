import { useI18n } from "i18n";
import ModalWindow from "modules/common/modalWindow/ModalWindow";
import { Box } from "@remo-co/ui-core/src/components/Box";
import { Typography } from "@remo-co/ui-core/src/components/Typography";
import { Button } from "@remo-co/ui-core/src/components/Button";
import Icon from "../../assets/new-version.svg";
import { useStyles } from "./styles";

type Props = {
  open: boolean;
  onUpdate: () => void;
  onCancel: () => void;
};

const RollbackPrompt = ({ open, onUpdate, onCancel }: Props) => {
  const styles = useStyles();
  const { t } = useI18n(["common"]);

  return (
    <ModalWindow
      open={open}
      maxWidth="xs"
      className={styles.root}
      onClose={onCancel}
    >
      <Box p={3} width="100%" data-testid="rollback-prompt">
        <Box mb={3}>
          <Typography variant="h4" align="center">
            {t("rollback.title")}
          </Typography>
        </Box>

        <Box display="flex" justifyContent="center" mb={3}>
          <img src={Icon} className={styles.icon} alt="" />
        </Box>

        <Box mb={3}>
          <Typography color="textSecondary" align="center">
            {t("rollback.description")}
          </Typography>
        </Box>

        <Button
          variant="primary"
          color="blue"
          fullWidth
          onClick={onUpdate}
          data-testid="update-button"
        >
          {t("rollback.cta")}
        </Button>
      </Box>
    </ModalWindow>
  );
};

export default RollbackPrompt;
