import { useEffect, useState, useCallback, useContext } from "react";
import { useAppDispatch } from "store/hooks";
import useBroadcastActions from "modules/broadcast/hooks/useBroadcastActions";
import { useRecordingAsync } from "modules/recording";
import useRaiseHandActions from "modules/broadcastRaiseHand/hooks/useRaiseHandActions";
import { BroadcastConfirmationDialog } from "modules/broadcast/dialogs/BroadcastConfirmationDialog";
import { useBroadcastVideoActions } from "modules/broadcast/hooks/useBroadcastVideoActions";
import { useI18n } from "i18n";
import { IStartBroadcastParams } from "modules/broadcast/redux/actions";
import {
  selectIsBroadcasting,
  selectVideoURL,
} from "modules/broadcast/redux/selectors";
import { useSelector } from "react-redux";
import { selectIsProcessing } from "modules/recording/redux/selectors";
import { selectCurrentTheaterId } from "modules/theater/selectors";
import { closeMenu } from "modules/eventButtons/redux";
import { ActionButton } from "modules/actionButton";
import {
  selectCurrentEvent,
  selectCurrentEventId,
} from "modules/event/selectors";
import { usePresentContentActions } from "modules/eventEmbedContent/hooks";
import { usePermissions } from "modules/audioVideo/hooks/usePermissions";
import { useDevices } from "modules/audioVideo/hooks/useDevices";
import { useLiveStreamingAsync } from "modules/liveStream/hooks";
import { useScreenShare } from "@daily-co/daily-react";
import { useTranscriptionRequests } from "modules/transcription";
import { Events, TRACKING_CONTEXT } from "modules/tracking";
import { selectCurrentAudio } from "modules/audioReactions/redux/selectors";
import { addDialogNotification } from "modules/dialogNotification/redux/dialogNotificationSlice";
import {
  autoUpdate,
  offset,
  useFloating,
} from "@floating-ui/react-dom-interactions";
import { StartConversationBroadcastDialog } from "modules/conversationBroadcast/StartConversationBroadcastDialog";
import { selectConversationBroadcastSessionId } from "modules/conversationBroadcast/redux/selectors";
import { EventFeatures } from "services/splitService/features";
import useEventSplitTreatment from "modules/split/useEventSplitTreatment";
import { ReactComponent as PresentModeIcon } from "./icons/present-mode-icon.inline.svg";
import { PresentButtonPopover } from "./PresentButtonPopover";

const icon = <PresentModeIcon />;

const PresentButton = ({ darkMode = false }) => {
  const dispatch = useAppDispatch();
  const { t } = useI18n();
  const { track } = useContext(TRACKING_CONTEXT);
  const [showConfirmBroadcast, setShowConfirmBroadcast] = useState(false);
  const [shouldDisablePresentButton, setShouldDisablePresentButton] =
    useState(false);
  const conversationBroadcastSessionId = useSelector(
    selectConversationBroadcastSessionId,
  );
  const [showPresentButtonPopover, setShowPresentButtonPopover] =
    useState(false);
  const [showLiveBroadcastDialog, setShowLiveBroadcastDialog] = useState(false);
  const eventId = useSelector(selectCurrentEventId);
  const isBroadcasting = useSelector(selectIsBroadcasting);
  const isRecordingProcessing = useSelector(selectIsProcessing);
  const theaterId = useSelector(selectCurrentTheaterId);
  const currentEvent = useSelector(selectCurrentEvent);
  const currentAudio = useSelector(selectCurrentAudio);
  const videoURL = useSelector(selectVideoURL);
  const { featureEnabled: videoAnnouncementFeature } = useEventSplitTreatment(
    EventFeatures.VIDEO_ANNOUNCEMENT,
  );
  const { updateVideoURL } = useBroadcastVideoActions();
  const { unraiseAllHands } = useRaiseHandActions();
  const { stopScreenShare } = useScreenShare();
  const { startBroadcast, stopBroadcast } = useBroadcastActions();
  const { unShareContent } = usePresentContentActions(currentEvent);
  const { isRecording, stopRecording } = useRecordingAsync();
  const { clearAllTranscriptionRequests } = useTranscriptionRequests();
  const { setPresentationPermissions } = usePermissions();
  const { setCameraActive, setMicrophoneActive } = useDevices();

  const closePopover = () => {
    setShowPresentButtonPopover(false);
  };
  const openLiveBroadcastDialog = () => {
    setShowLiveBroadcastDialog(true);
    closePopover();
  };

  const closeLiveBroadcastDialog = () => {
    setShowLiveBroadcastDialog(false);
    closePopover();
  };

  const onLiveStreamStopped = useCallback(() => {
    track(Events.RTMP_OUT_STREAM_ENDED_SUCCESSFULLY, {
      eventId,
    });
  }, [eventId, track]);

  const { isLiveStreaming, stopStreaming } = useLiveStreamingAsync({
    onLiveStreamStopped,
  });

  useEffect(() => {
    if (!isBroadcasting) {
      setShowConfirmBroadcast(false);
    }
  }, [isBroadcasting]);

  useEffect(() => {
    let timer: ReturnType<typeof setTimeout> | null = null;

    if (isRecordingProcessing) {
      setShouldDisablePresentButton(true);
      timer = setTimeout(() => {
        // Enable the button again after 10 seconds for when the user
        // runs into network issues
        if (isRecordingProcessing) {
          // eslint-disable-next-line @typescript-eslint/no-use-before-define
          cancelRecording();
        }
      }, 10000);
    } else {
      setShouldDisablePresentButton(false);
    }

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRecordingProcessing]);

  const cancelRecording = useCallback(async () => {
    setShouldDisablePresentButton(false);
  }, []);

  const handleStopBroadcast = useCallback(async () => {
    if (!isBroadcasting || !theaterId) {
      return;
    }
    stopScreenShare();
    if (isRecording) {
      await stopRecording();
    }

    unShareContent();

    if (videoURL) {
      updateVideoURL(null);
    }

    if (isLiveStreaming) {
      stopStreaming();
    }

    unraiseAllHands(theaterId);
    clearAllTranscriptionRequests();
    stopBroadcast();
  }, [
    clearAllTranscriptionRequests,
    isBroadcasting,
    isLiveStreaming,
    isRecording,
    stopBroadcast,
    stopRecording,
    stopScreenShare,
    stopStreaming,
    theaterId,
    unShareContent,
    unraiseAllHands,
    updateVideoURL,
    videoURL,
  ]);

  const confirmCancelBroadcast = useCallback(() => {
    dispatch(
      addDialogNotification({
        message: t("close.broadcast.message"),
        confirmText: t("affirm.close.broadcast"),
        dismissText: t("deny.close.broadcast"),
        hideCloseButton: true,
        onConfirm: () => {
          handleStopBroadcast();
          setShowConfirmBroadcast(false);
        },
        className: "dark",
      }),
    );
  }, [dispatch, handleStopBroadcast, t]);

  const handleBroadcastButtonClick = useCallback(() => {
    if (isBroadcasting) {
      confirmCancelBroadcast();
      return;
    }
    if (videoAnnouncementFeature) {
      setShowPresentButtonPopover(true);
      return;
    }
    setShowConfirmBroadcast(true);
  }, [confirmCancelBroadcast, isBroadcasting, videoAnnouncementFeature]);

  const handleCancelBroadcast = useCallback(() => {
    setShowConfirmBroadcast(false);
  }, []);

  const handleShowConfirmBroadcast = () => {
    setShowConfirmBroadcast(true);
    closePopover();
  };

  const handleStartBroadcast = useCallback(
    async (data: IStartBroadcastParams) => {
      dispatch(closeMenu());
      if (!isBroadcasting) {
        stopScreenShare();
        await startBroadcast(data);
        if (data.delay === 0) {
          setPresentationPermissions({
            hostDevicesEnabled: data.isTurnOnMicCamWhenStart,
          });

          setMicrophoneActive(data.isTurnOnMicCamWhenStart);
          setCameraActive(data.isTurnOnMicCamWhenStart);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isBroadcasting, startBroadcast, stopScreenShare],
  );
  const { x, y, floating, strategy, reference } =
    useFloating<HTMLButtonElement>({
      whileElementsMounted: autoUpdate,
      placement: "top-start",
      middleware: [offset({ mainAxis: 15, crossAxis: 0 })],
    });

  return (
    <>
      <ActionButton
        title={isBroadcasting ? t("turn.off.presentation") : t("present")}
        onClick={handleBroadcastButtonClick}
        label={isBroadcasting ? t("present.on") : t("present")}
        isActive={isBroadcasting}
        disabled={
          shouldDisablePresentButton ||
          Boolean(currentAudio) ||
          !!conversationBroadcastSessionId
        }
        icon={icon}
        darkMode={darkMode}
        id="ctrl-present-view"
        ref={reference}
      />
      {showConfirmBroadcast && (
        <BroadcastConfirmationDialog
          onConfirm={handleStartBroadcast}
          onCancel={handleCancelBroadcast}
          setShowLiveBroadcastDialog={setShowLiveBroadcastDialog}
        />
      )}
      {showLiveBroadcastDialog && (
        <StartConversationBroadcastDialog
          handleClose={closeLiveBroadcastDialog}
        />
      )}
      {showPresentButtonPopover && (
        <PresentButtonPopover
          closePopover={closePopover}
          openLiveBroadcastDialog={openLiveBroadcastDialog}
          handleShowConfirmBroadcast={handleShowConfirmBroadcast}
          floating={floating}
          y={y}
          x={x}
          strategy={strategy}
        />
      )}
    </>
  );
};

export default PresentButton;
