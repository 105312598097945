import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type State = {
  key?: string;
  value?: string | number;
  skipUpdate: boolean;
  isLoading: boolean;
};

export const initialState: State = {
  key: undefined,
  value: undefined,
  skipUpdate: false, // do not update value if it is already set (ex: first attended event name, etc)
  isLoading: false,
};

const helpChatSlice = createSlice({
  initialState,
  name: "helpChatEvent",
  reducers: {
    trackHelpChatEvent: (
      state,
      action: PayloadAction<{
        key?: string;
        value?: string | number;
        skipUpdate: boolean;
      }>,
    ) => ({
      ...state,
      ...action.payload,
    }),
    setIsLoading: (state, action: PayloadAction<boolean>) => ({
      ...state,
      isLoading: action.payload,
    }),
  },
});

export const { trackHelpChatEvent, setIsLoading } = helpChatSlice.actions;

export default helpChatSlice.reducer;
