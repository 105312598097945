/**
 * create link for normal flow and in event event settings url
 * if in event, url ll have e/eventcode/ as well, so we have append that
 * @param linkId
 * @param eventId
 */

export const getEventLink = (linkId: string, eventId?: string) => {
  const parts = window.location.pathname.split("/event/");

  const path = `/event/${linkId}/${eventId || ""}${
    window.location.search || ""
  }`;

  return parts.length === 2 ? `${parts[0]}${path}` : path;
};
