import { Theme } from "@mui/material/styles";

import makeStyles from "@mui/styles/makeStyles";

export const useSwitchStyles = makeStyles<Theme>((theme) => ({
  darkMode: {
    "& .MuiSwitch-switchBase.Mui-checked": {
      color: `${theme.palette.darkModeBlue.main}`,
      "&:hover": {
        backgroundColor: `${theme.palette.darkModeBlue.main[600]}`,
      },
    },
    "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
      backgroundColor: theme.palette.gray.switchTrackDark,
    },
  },
}));
