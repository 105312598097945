import React from "react";
import { IconButton } from "@remo-co/ui-core/src/components/IconButton";
import { Popper } from "@remo-co/ui-core/src/components/Popper";
import { Typography } from "@remo-co/ui-core/src/components/Typography";
import { Close } from "@remo-co/ui-core/src/icons/Close";
import { useI18n } from "i18n";
import { useSelector } from "react-redux";
import { useAppDispatch } from "store/hooks";
import Actions from "modules/onboarding/redux/actions";
import { selectCurrentSpaceId } from "modules/space/redux/selectors";
import "./TryCameraAndMicPopover.scss";
import { RootState } from "store";
import cameraAndMicIcon from "modules/conversation/assets/cameraAndMicIcon.svg";

const TIMEOUT = 20 * 1000;

const TryCameraAndMicPopover = () => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement>();
  const showCamAndMicPopover = useSelector(
    (state: RootState) => state.showCamAndMicPopover,
  );
  const spaceId = useSelector(selectCurrentSpaceId);
  const dispatch = useAppDispatch();
  const [timeElapsed, setTimeElapsed] = React.useState(false);
  const { t } = useI18n(["conversation"]);

  React.useEffect(() => {
    if (!spaceId) {
      return;
    }
    const deviceSelectorButton = document.getElementById(
      "device-selector-button",
    );

    if (deviceSelectorButton) {
      setAnchorEl(deviceSelectorButton);
    }
    const timeoutId = window.setTimeout(() => setTimeElapsed(true), TIMEOUT);

    // eslint-disable-next-line consistent-return
    return () => {
      window.clearTimeout(timeoutId);
    };
  }, [spaceId]);

  const closePopover = () => dispatch(Actions.showCamAndMicPopover(false));

  return (
    <Popper
      className="try-camera-and-mic-popover"
      open={Boolean(anchorEl) && showCamAndMicPopover && timeElapsed}
      anchorEl={anchorEl}
      placement="top"
    >
      <div className="try-camera-and-mic-popover-content">
        <img src={cameraAndMicIcon} alt="" />
        <div className="try-camera-and-mic-popover-text">
          <Typography color="inherit">
            {t("conversation:switch.mic.message.1")}
          </Typography>
          <Typography color="inherit">
            {t("conversation:switch.mic.message.2")}
          </Typography>
        </div>
        <IconButton onClick={closePopover} size="large">
          <Close />
        </IconButton>
      </div>
    </Popper>
  );
};

export default TryCameraAndMicPopover;
