export const BACKGROUND_IMAGE_QUERY_LIMIT = 6;
export const VIRTUAL_BACKGROUND_CACHE_KEY = "virtualBackground";

export const presetVirtualBackgroundImages = [
  "VirtualCamera/VirtualBackground/preset-image-1.jpg",
  "VirtualCamera/VirtualBackground/preset-image-2.jpg",
  "VirtualCamera/VirtualBackground/preset-image-3.jpg",
  "VirtualCamera/VirtualBackground/preset-image-4.jpg",
  "VirtualCamera/VirtualBackground/preset-image-5.jpg",
  "VirtualCamera/VirtualBackground/preset-image-6.jpg",
];
