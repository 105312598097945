import { DailySingleParticipantReceiveSettingsUpdates } from "@daily-co/daily-js";
import { useDaily } from "@daily-co/daily-react";

interface UpdateParticipant {
  participantId: string;
  canSend: boolean;
  withMic?: boolean;
  withCam?: boolean;
}

export const useDailyApi = () => {
  const daily = useDaily();

  const updateDailyParticipant = ({
    participantId,
    canSend,
    withMic = false,
    withCam = false,
  }: UpdateParticipant) => {
    daily?.updateParticipant(participantId, {
      updatePermissions: {
        canSend: !!canSend,
      },
      setAudio: withMic,
      setVideo: withCam,
    });
  };

  const updateReceiveSettings = (
    settings: Record<string, DailySingleParticipantReceiveSettingsUpdates>,
  ) => {
    daily?.updateReceiveSettings(settings);
  };

  return {
    updateDailyParticipant,
    updateReceiveSettings,
  };
};
