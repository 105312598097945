import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";
import zIndexes from "zIndexes.module.scss";

export const useStyles = makeStyles((theme) => ({
  icon: {
    position: "absolute",
    right: "0.5rem",
    color: theme.palette.yellow.dark,
    cursor: "pointer",
  },
  popper: {
    zIndex: zIndexes.modalZIndex,
  },
  container: {
    maxWidth: "370px",
    borderRadius: "5px",
  },
}));

export default useStyles;
