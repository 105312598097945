import { useState, useEffect, useRef } from "react";
import classNames from "classnames";
import { Box } from "@remo-co/ui-core/src/components/Box";
import { Typography } from "@remo-co/ui-core/src/components/Typography";
import { CircularProgress } from "@remo-co/ui-core/src/components/CircularProgress";
import { Link } from "@remo-co/ui-core/src/components/Link";
import { Check } from "@remo-co/ui-core/src/icons/Check";
import { Error } from "@remo-co/ui-core/src/icons/Error";
import { useI18n } from "i18n";
import useStyles from "./styles";

interface Props {
  isLoading: boolean;
  status: string;
}

export const HELP_LINK_URL = "https://live.remo.co";
const AUTO_DISMISS_TIMEOUT = 3000;

const AutosaveStatus = ({ isLoading, status }: Props): JSX.Element | null => {
  const [showSuccessState, setShowSuccessState] = useState(false);
  const dismissTimerRef = useRef<number>();
  const { t } = useI18n("manageEvent");
  const styles = useStyles();

  useEffect(() => {
    if (!isLoading && status === "success") {
      setShowSuccessState(true);

      dismissTimerRef.current = window.setTimeout(() => {
        setShowSuccessState(false);
      }, AUTO_DISMISS_TIMEOUT);
    } else {
      setShowSuccessState(false);

      clearTimeout(dismissTimerRef.current);
    }
  }, [isLoading, status]);

  if (isLoading) {
    return (
      <Box
        className={styles.container}
        display="flex"
        flexDirection="row"
        justifyContent="flex-end"
      >
        <CircularProgress size={15} className={styles.progress} />
        <Typography variant="body2">{t("saving")}</Typography>
      </Box>
    );
  }

  if (status === "error") {
    return (
      <Box
        className={styles.container}
        display="flex"
        flexDirection="row"
        justifyContent="flex-end"
      >
        <Error className={classNames(styles.icons, styles.error)} />
        <Typography variant="body2">{t("save.changes.failed")}</Typography>
        <Link
          href={HELP_LINK_URL}
          openInNewTab
          isExternal
          className={styles.helpLink}
          data-testid="help-link"
        >
          <Typography variant="body2">{t("learn.more")}</Typography>
        </Link>
      </Box>
    );
  }

  if (showSuccessState) {
    return (
      <Box
        className={styles.container}
        display="flex"
        flexDirection="row"
        justifyContent="flex-end"
      >
        <Check className={styles.icons} />
        <Typography variant="body2">
          {t("changes.saved.automatically")}
        </Typography>
      </Box>
    );
  }

  return null;
};

export default AutosaveStatus;
