import { ComponentProps } from "react";
import { Theme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import Chip from "./Chip";

export const useStyles = makeStyles<Theme, ComponentProps<typeof Chip>>(
  ({ palette }) => ({
    root: ({ color, uppercase }) => ({
      color: color === "gray" ? palette.black : palette.white,
      backgroundColor: palette[color!].main,
      textTransform: uppercase ? "uppercase" : "none",
    }),
  }),
);
