import { ChangeEvent, useState } from "react";
import { Box } from "@remo-co/ui-core/src/components/Box";
import { Button } from "@remo-co/ui-core/src/components/Button";
import { IconButtonWithTooltip } from "@remo-co/ui-core/src/components/IconButtonWithTooltip";
import { Input } from "@remo-co/ui-core/src/components/Input";
import { Add } from "@remo-co/ui-core/src/icons/Add";
import { Delete } from "@remo-co/ui-core/src/icons/Delete";
import { useI18n } from "i18n";
import { useStyles } from "./styles";
import { isValidURL } from "../../utils";

interface Props {
  ctaText?: string | null;
  ctaLink?: string | null;
  handleChange: (event: ChangeEvent<HTMLInputElement>) => void;
  resetCTAConfig: () => void;
}

export const CTAButtonConfig = ({
  ctaText,
  ctaLink,
  handleChange,
  resetCTAConfig,
}: Props) => {
  const [showFields, setShowFields] = useState(Boolean(ctaText && ctaText));
  const { t } = useI18n(["eventForm", "event", "common"]);
  const styles = useStyles();

  const hideCTAConfig = () => {
    resetCTAConfig();
    setShowFields(false);
  };

  return !showFields ? (
    <Button
      variant="text"
      color="blue"
      size="sm"
      contentClassName={styles.addButtonContent}
      onClick={() => setShowFields(true)}
    >
      <Add /> {t("eventForm:add.cta.button")}
    </Button>
  ) : (
    <Box display="flex" flexDirection="column" className={styles.config}>
      <IconButtonWithTooltip
        id="hide-cta-config"
        title={t("common:remove")}
        onClick={hideCTAConfig}
        size="small"
        className={styles.deleteButton}
        data-testid="hide-fields-button"
      >
        <Delete className={styles.deleteIcon} />
      </IconButtonWithTooltip>
      <Input
        size="sm"
        fullWidth
        getRemainingCharsMessage={(key) =>
          t("common:character.remaining", { key })
        }
        label={t("eventForm:call.action.label")}
        name="ctaText"
        placeholder={t("eventForm:call.action.label.placeholder")}
        value={ctaText || undefined}
        onChange={handleChange}
        inputProps={{ maxLength: 30, "data-testid": "cta-text" }}
      />
      <Input
        size="sm"
        fullWidth
        label={t("eventForm:call.action.link")}
        name="ctaLink"
        placeholder={t("eventForm:call.action.link.placeholder")}
        value={ctaLink || undefined}
        onChange={handleChange}
        inputProps={{ "data-testid": "cta-link" }}
        error={
          !!ctaLink && !isValidURL(ctaLink)
            ? `${t("event:embed.content.invalid.url")}`
            : false
        }
      />
    </Box>
  );
};
