import { useCallback } from "react";
import { useSelector } from "react-redux";
import { useDailyEvent } from "@daily-co/daily-react";
import useNotificationActions from "modules/notification/hooks/useNotificationActions";
import { useI18n } from "i18n";
import logger from "logging/logger";
import { selectIsEventManager } from "modules/event/selectors";
import { Errors, trackError } from "modules/monitoring";

export const useRecordingSubscription = () => {
  const { t } = useI18n();

  const { addInfoNotification, addErrorNotification } =
    useNotificationActions();

  const isEventManager = useSelector(selectIsEventManager);

  useDailyEvent(
    "recording-error",
    useCallback(
      (event) => {
        if (!isEventManager) {
          return;
        }
        addErrorNotification({
          message: t("something.wrong"),
        });
        trackError(event.errorMsg, {
          label: Errors.RECORDING_ERROR,
        });
        logger.error(`[recording][onRecordingError] ${event?.errorMsg}`);
      },
      [isEventManager, addErrorNotification, t],
    ),
  );

  useDailyEvent(
    "recording-stopped",
    useCallback(() => {
      if (!isEventManager) {
        return;
      }
      addInfoNotification({
        message: t("event:download.saved.recording"),
        position: "tc",
        autoDismiss: 5,
      });
    }, [isEventManager, addInfoNotification, t]),
  );
};
