enum ActionTypes {
  BROADCAST_TRANSITION_COMPLETE = "app/broadcast/BROADCAST_TRANSITION_COMPLETE",
  BROADCAST_TRANSITION_STARTED = "app/broadcast/BROADCAST_TRANSITION_STARTED",
  ENTER_BROADCAST = "app/broadcast/ENTER_BROADCAST",
  LEAVE_BROADCAST = "app/broadcast/LEAVE_BROADCAST",
  RESET = "app/broadcast/RESET",
  SET_PANEL_STATE = "app/broadcast/SET_PANEL_STATE",
  SET_PANEL_TRANSITION_STATE = "app/broadcast/SET_PANEL_TRANSITION_STATE",
  SHOW_TAB = "app/broadcast/SHOW_TAB",
  START_BROADCAST = "app/broadcast/START_BROADCAST",
  STOP_BROADCAST = "app/broadcast/STOP_BROADCAST",
  UPDATE_BROADCAST_DELAY = "app/broadcast/UPDATE_BROADCAST_DELAY",
  UPDATE_BROADCAST_INFO = "app/broadcast/UPDATE_BROADCAST_INFO",
  UPDATE_BROADCAST_STATUS = "app/broadcast/UPDATE_BROADCAST_STATUS",
  UPDATE_IS_BROADCASTING = "app/broadcast/UPDATE_IS_BROADCASTING",
  UPDATE_IS_VIDEO_CONTROLS_ENABLED = "app/broadcast/UPDATE_IS_VIDEO_CONTROLS_ENABLED",
  UPDATE_STARTED_AT = "app/broadcast/UPDATE_STARTED_AT",
  UPDATE_STARTED_BY = "app/broadcast/UPDATE_STARTED_BY",
  UPDATE_VIDEO_SHARE_TIME = "app/broadcast/UPDATE_VIDEO_SHARE_TIME",
  UPDATE_VIDEO_URL = "app/broadcast/UPDATE_VIDEO_URL",
}

export const VIDEO_TRY_SYNC_EVERY_MS = 10000;

export const VIDEO_JUST_STARTED_BUFFER_SECONDS = 10;

export const VIDEO_ENDING_SOON_BUFFER_SECONDS = 20;

export const VIDEO_ALLOWED_DELAY_SECONDS = 5;

export const VIDEO_MAX_SYNC_COUNT_PER_MINUTE = 3;

export enum BroadcastStatus {
  IN_PROGRESS = 2,
  PREPARING = 1,
}

export default ActionTypes;
