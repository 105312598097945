import { TileDraft } from "../Layouts/types";
import { LayoutTile } from "./LayoutTile";

interface Props {
  tiles: TileDraft[];
  className?: string;
  onTileClick?: (streamId: string) => void;
}

export const TileGroup = ({ tiles, className, onTileClick }: Props) => (
  <>
    {tiles.map((tile) => (
      <LayoutTile
        tile={tile}
        className={className}
        key={tile.streamId}
        onTileClick={onTileClick}
      />
    ))}
  </>
);
