import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";

const useStyles = makeStyles((theme) => ({
  container: {
    marginRight: 20,
  },
  progress: {
    color: theme.palette.blue.dark,
    marginRight: 15,
    alignSelf: "center",
  },
  icons: {
    marginRight: 12,
    width: 20,
    height: 20,
  },
  error: {
    color: theme.palette.yellow.dark,
  },
  helpLink: {
    marginLeft: 10,
    color: theme.palette.blue.main,
    textDecoration: "underline",
  },
}));

export default useStyles;
